/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../common/linkcard/linkCard.component.ngfactory";
import * as i3 from "../../../common/linkcard/linkCard.component";
import * as i4 from "./linkTeaser.component";
var styles_LinkTeaserComponent = [];
var RenderType_LinkTeaserComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LinkTeaserComponent, data: {} });
export { RenderType_LinkTeaserComponent as RenderType_LinkTeaserComponent };
export function View_LinkTeaserComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "section", [["class", "linkTeaser"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(3, 2), (_l()(), i0.ɵeld(4, 0, null, null, 1, "linkcard", [], null, null, null, i2.View_LinkCardComponent_0, i2.RenderType_LinkCardComponent)), i0.ɵdid(5, 49152, null, 0, i3.LinkCardComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "linkTeaser"; var currVal_1 = _ck(_v, 3, 0, _co.linkCardModel.box.cssClass, _co.imgClass); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.linkCardModel; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_LinkTeaserComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "linkTeaser", [], null, null, null, View_LinkTeaserComponent_0, RenderType_LinkTeaserComponent)), i0.ɵdid(1, 49152, null, 0, i4.LinkTeaserComponent, [], null, null)], null, null); }
var LinkTeaserComponentNgFactory = i0.ɵccf("linkTeaser", i4.LinkTeaserComponent, View_LinkTeaserComponent_Host_0, {}, {}, []);
export { LinkTeaserComponentNgFactory as LinkTeaserComponentNgFactory };
