/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i2 from "../../../common/ui/linkBar/linkBar.component";
import * as i3 from "../../../common/teaser/teaser.directive";
import * as i4 from "../../../../shared/directive/oewaClickTrigger.directive";
import * as i5 from "../../../../shared/service/oewa.service";
import * as i6 from "../../../common/gtmTrigger.directive";
import * as i7 from "@angular/common";
import * as i8 from "./longlist.component";
import * as i9 from "../../../../shared/service/teasers.service";
import * as i10 from "../../../../shared/service/device-detection.service";
import * as i11 from "../../../../shared/service/portal.service";
var styles_LongListComponent = [];
var RenderType_LongListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LongListComponent, data: {} });
export { RenderType_LongListComponent as RenderType_LongListComponent };
function View_LongListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "linkbar", [], null, null, null, i1.View_LinkBarComponent_0, i1.RenderType_LinkBarComponent)), i0.ɵdid(1, 49152, null, 0, i2.LinkBarComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkBarModel; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LongListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(1, 540672, null, 0, i3.TeaserDirective, [i0.ViewContainerRef, i0.ComponentFactoryResolver], { model: [0, "model"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LongListComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "p", [["class", "longList-showMore"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "button"], ["href", "#"], ["oewaClickTrigger", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.fetchMoreEntries() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i4.OewaClickTriggerDirective, [i5.OewaService], { oewaPageId: [0, "oewaPageId"] }, null), i0.ɵdid(3, 81920, null, 0, i6.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null), (_l()(), i0.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.gtmId; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.model.cta; _ck(_v, 4, 0, currVal_2); }); }
function View_LongListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "longList"]], null, null, null, null, null)), i0.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(3, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LongListComponent_2)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LongListComponent_3)), i0.ɵdid(7, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LongListComponent_4)), i0.ɵdid(9, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "longList"; var currVal_1 = _ck(_v, 3, 0, _co.box.cssClass); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.linkBarModel; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.mediaModels; var currVal_4 = _co.trackMedia; _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_5 = _co.showMore; _ck(_v, 9, 0, currVal_5); }, null); }
export function View_LongListComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_LongListComponent_1)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.teasers$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LongListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "longlist", [], null, null, null, View_LongListComponent_0, RenderType_LongListComponent)), i0.ɵdid(1, 573440, null, 0, i8.LongListComponent, [i9.TeasersService, i10.DeviceDetectionService, i11.PortalService], null, null)], null, null); }
var LongListComponentNgFactory = i0.ɵccf("longlist", i8.LongListComponent, View_LongListComponent_Host_0, { model: "model" }, {}, []);
export { LongListComponentNgFactory as LongListComponentNgFactory };
