import { ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { AdMediumRectangleComponent } from '../../component/ads/mediumRectangle/adMediumRectangle.component';
import { AuthorHeaderComponent } from '../../component/home/search/authorHeader/authorHeader.component';
import { SingleSliderComponent } from '../../component/home/channel/singleSlider/singleSlider.component';
import { MultiSliderComponent } from '../../component/home/channel/multiSlider/multiSlider.component';
import { DepartmentBlockComponent } from '../../component/home/channel/departmentBlock/departmentblock.component';
import { LongListComponent } from '../../component/home/common/longList/longlist.component';
import { OutbrainComponent } from '../../component/ads/outbrain/outbrain.component';
import { TopStoriesComponent } from '../../component/home/channel/topStories/topStories.component';
import { ResultArticlesComponent } from '../../component/home/search/resultArticles/resultArticles.component';
import { ResultTopicsComponent } from '../../component/home/search/resultTopics/resultTopics.component';
import { SearchHeaderComponent } from '../../component/home/search/resultHeader/resultHeader.component';
import { TopTopicsComponent } from '../../component/home/channel/topTopics/toptopics.component';
import { AdInContentComponent } from '../../component/ads/adInContent/adInContent.component';
import { AdArticlebottomComponent } from '../../component/ads/adArticlebottom/adArticlebottom.component';
import { AdFullbannerComponent } from '../../component/ads/adFullbanner/adFullbanner.component';
import { TopicHeaderComponent } from '../../component/home/search/topicHeader/topicHeader.component';
import { isPlatformServer } from '@angular/common';
import { MultiTeaserComponent } from '../../component/home/common/multiTeaser/multi-teaser.component';
import { AdMobileBannerComponent } from '../../component/ads/adMobileBanner/admobilebanner.component';
import { LinkTeaserComponent } from '../../component/home/channel/linkTeaser/linkTeaser.component';
import { SingleTeaserComponent } from '../../component/home/channel/singleTeaser/singleTeaser.component';
import { GallerySliderComponent } from '../../component/home/channel/gallerySlider/gallerySlider.component';
import { TabbedListComponent } from '../../component/home/channel/tabbedList/tabbedlist.component';
import { ErrorComponent } from '../../component/home/error/error.component';
import { VideoPlaylistComponent } from '../../component/home/channel/videoPlaylist/videoPlaylist.component';
import { AdResponsiveComponent } from '../../component/ads/adResponsive/adresponsive.component';
import { EmptyComponent } from '../../component/home/empty.component';
import { DynamicType } from '../../model/enum/dynamic';
import { DeviceDetectionService } from './device-detection.service';
import { ContentGardenComponent } from '../../component/home/content/contentGarden/contentGarden.component';
import { JobBoxComponent } from '../../component/home/channel/jobBox/jobBox.component';
import { ImmoBoxComponent } from '../../component/home/channel/immoBox/immoBox.component';
import { TitleBoxComponent } from '../../component/home/channel/titleBox/titleBox.component';
import { ApaVideoComponent } from '../../component/home/content/apaVideo/apavideo.component';
import { ArticleComponent } from '../../component/home/content/article/article.component';
import { ChannelRelatedsComponent } from '../../component/home/content/channelRelateds/channelRelateds.component';
import { SectionRelatedsComponent } from '../../component/home/content/sectionRelateds/sectionRelateds.component';
import { ChannelLinkComponent } from '../../component/home/content/channelLink/channelLink.component';
import { DividerComponent } from '../../component/home/content/divider/divider.component';
import { FacebookComponent } from '../../component/home/content/facebook/facebook.component';
import { FilmAtProgramComponent } from '../../component/home/content/filmatProgram/filmatprogram.component';
import { FilmAtReviewComponent } from '../../component/home/content/filmatReview/filmatreview.component';
import { InlineSliderComponent } from '../../component/home/content/inlineSlider/inlineSlider.component';
import { GoogleMapsComponent } from '../../component/home/content/googleMaps/googlemaps.component';
import { IframeContainerComponent } from '../../component/home/channel/iframeContainer/iframecontainer.component';
import { InlineImgComponent } from '../../component/home/content/inlineImg/inlineImg.component';
import { InstagramComponent } from '../../component/home/content/instagram/instagram.component';
import { LinkFieldComponent } from '../../component/home/content/linkField/linkfield.component';
import { LiveblogComponent } from '../../component/home/content/liveblog/liveblog.component';
import { PinPollComponent } from '../../component/home/common/pinPoll/pinpoll.component';
import { PinterestBoardOrProfilComponent } from '../../component/home/content/pinterestProfileOrBoard/pinterestprofileorboard.component';
import { QuoteContainerComponent } from '../../component/home/content/quote/quoteContainer.component';
import { RiddleComponent } from '../../component/home/content/riddle/riddle.component';
import { SoundCloudComponent } from '../../component/home/content/soundcloud/soundcloud.component';
import { TeaserParagraphComponent } from '../../component/home/content/teaserParagraph/teaserParagraph.component';
import { TextParagraphComponent } from '../../component/home/content/textParagraph/textParagraph.component';
import { VimeoComponent } from '../../component/home/content/vimeo/vimeo.component';
import { YouTubeComponent } from '../../component/home/content/youtube/youtube.component';
import { ContentGardenTeaserComponent } from '../../component/home/channel/contentGarden/contentGardenTeaser.component';
import { MovieSerieDetailComponent } from '@component/home/content/movieSerieDetail/movieSerieDetail.component';
import { MovieListComponent } from '../../component/home/channel/movieList/movieList.component';
import { ScreeningsComponent } from '../../component/home/channel/screenings/screenings.component';
import { TheaterDetailComponent } from '../../component/home/content/theaterDetail/theaterDetail.component';
import { PosterSliderComponent } from '../../component/home/common/posterSlider/posterSlider.component';
import { RomyVotingComponent } from '../../component/home/channel/romyVoting/romyVoting.component';
import { InfoBoxComponent } from '../../component/home/content/infoBox/infoBox.component';
import { BeforeAfterSliderComponent } from '../../component/home/content/before-after-slider/before-after-slider.component';
import { TextBoxComponent } from '../../component/home/channel/text-box/text-box.component';
import { NewsletterSignUpComponent } from '../../component/home/content/newsletter-sign-up/newsletter-sign-up.component';
import { CleverpushButtonComponent } from '@component/home/content/cleverpushButton/cleverpush-button.component';
import { Degrees23Component } from '@component/home/content/degrees23/degrees23.component';
import { RedditComponent } from '@component/home/content/reddit/reddit.component';
import { TwitterComponent } from '@component/home/content/twitter/twitter.component';
import { PodigeeComponent } from '@component/home/content/podigee/podigee.component';
import { TikTokComponent } from '@component/home/content/tik-tok/tik-tok.component';
import { TwitterTimelineComponent } from '@component/home/content/twitter-timeline/twitter-timeline.component';
import { FlourishComponent } from '@component/home/content/flourish/flourish.component';
import { PaywallComponent } from '@component/home/content/paywall/paywall.component';
import { FootballTableLeagueComponent } from '@component/home/content/football-table-league/football-table-league.component';
import { FootballTableCupComponent } from '@component/home/content/football-table-cup/football-table-cup.component';
import { FootballLiveScoreWrapperComponent } from '@component/home/content/football-live-score-wrapper/football-live-score-wrapper.component';
import { FootballStagesWrapperComponent } from '@component/home/content/football-stages-wrapper/football-stages-wrapper.component';
import { PersonMovieListComponent } from '@component/home/content/person-movie-list/person-movie-list.component';
import { TypedEmbedComponent } from '@component/home/content/typed-embed/typed-embed.component';
import { AdPianoComponent } from '@component/home/content/ad-piano/ad-piano.component';
import { TextLinkComponent } from '@component/home/channel/text-link/text-link.component';
import * as i0 from "@angular/core";
import * as i1 from "./device-detection.service";
var DynamicComponentsService = /** @class */ (function () {
    function DynamicComponentsService(platformId, mobileDetection) {
        var _a;
        this.platformId = platformId;
        this.mobileDetection = mobileDetection;
        this.typeMap = (_a = {},
            _a[DynamicType.adArticlebottom] = AdArticlebottomComponent,
            _a[DynamicType.adFullbanner] = AdFullbannerComponent,
            _a[DynamicType.adInContent] = AdInContentComponent,
            _a[DynamicType.adMediumRectangle] = AdMediumRectangleComponent,
            _a[DynamicType.adMediumRectangleSticky] = AdMediumRectangleComponent,
            _a[DynamicType.adMobileBanner] = AdMobileBannerComponent,
            _a[DynamicType.adResponsive] = AdResponsiveComponent,
            _a[DynamicType.fakeAdArticlebottom] = AdArticlebottomComponent,
            _a[DynamicType.fakeAdFullbanner] = AdFullbannerComponent,
            _a[DynamicType.fakeAdInContent] = AdInContentComponent,
            _a[DynamicType.fakeAdMediumRectangle] = AdMediumRectangleComponent,
            _a[DynamicType.fakeAdMediumRectangleSticky] = AdMediumRectangleComponent,
            _a[DynamicType.fakeAdMobileBanner] = AdMobileBannerComponent,
            _a[DynamicType.fakeAdResponsive] = AdResponsiveComponent,
            _a[DynamicType.apa_video] = ApaVideoComponent,
            _a[DynamicType.article] = ArticleComponent,
            _a[DynamicType.articleChannelRelateds] = ChannelRelatedsComponent,
            _a[DynamicType.articleSectionRelateds] = SectionRelatedsComponent,
            _a[DynamicType.authorHeader] = AuthorHeaderComponent,
            _a[DynamicType.contentGardenArticle] = ContentGardenComponent,
            _a[DynamicType.contentGardenTeaser] = ContentGardenTeaserComponent,
            _a[DynamicType.doubleTeaser] = MultiTeaserComponent,
            _a[DynamicType.dividerLink] = ChannelLinkComponent,
            _a[DynamicType.departmentblock] = DepartmentBlockComponent,
            _a[DynamicType.divider] = DividerComponent,
            _a[DynamicType.error] = ErrorComponent,
            _a[DynamicType.facebook_post] = FacebookComponent,
            _a[DynamicType.facebook_video] = FacebookComponent,
            _a[DynamicType.facebook_page] = FacebookComponent,
            _a[DynamicType.filmatProgram] = FilmAtProgramComponent,
            _a[DynamicType.filmatReview] = FilmAtReviewComponent,
            _a[DynamicType.gallery] = InlineSliderComponent,
            _a[DynamicType.gallerySlider] = GallerySliderComponent,
            _a[DynamicType.google_map] = GoogleMapsComponent,
            _a[DynamicType.iframe] = IframeContainerComponent,
            _a[DynamicType.image] = InlineImgComponent,
            _a[DynamicType.immoSearch] = ImmoBoxComponent,
            _a[DynamicType.instagram] = InstagramComponent,
            _a[DynamicType.jobSearch] = JobBoxComponent,
            _a[DynamicType.link] = LinkFieldComponent,
            _a[DynamicType.linkTeaser] = LinkTeaserComponent,
            _a[DynamicType.link_teaser] = LinkTeaserComponent,
            _a[DynamicType.liveblog] = LiveblogComponent,
            _a[DynamicType.longList] = LongListComponent,
            _a[DynamicType.movieDetail] = MovieSerieDetailComponent,
            _a[DynamicType.movieList] = MovieListComponent,
            _a[DynamicType.multiSlider] = MultiSliderComponent,
            _a[DynamicType.outbrain] = OutbrainComponent,
            _a[DynamicType.pinpoll] = PinPollComponent,
            _a[DynamicType.pinterest_board] = PinterestBoardOrProfilComponent,
            _a[DynamicType.pinterest_profile] = PinterestBoardOrProfilComponent,
            _a[DynamicType.posterSlider] = PosterSliderComponent,
            _a[DynamicType.quote] = QuoteContainerComponent,
            _a[DynamicType.riddle] = RiddleComponent,
            _a[DynamicType.screeningsBlock] = ScreeningsComponent,
            _a[DynamicType.scrollList] = TabbedListComponent,
            _a[DynamicType.searchHeader] = SearchHeaderComponent,
            _a[DynamicType.searchResults] = ResultArticlesComponent,
            _a[DynamicType.searchResultTopics] = ResultTopicsComponent,
            _a[DynamicType.singleSlider] = SingleSliderComponent,
            _a[DynamicType.singleTeaser] = SingleTeaserComponent,
            _a[DynamicType.soundcloud] = SoundCloudComponent,
            _a[DynamicType.story] = ArticleComponent,
            _a[DynamicType.teaser] = TeaserParagraphComponent,
            _a[DynamicType.text] = TextParagraphComponent,
            _a[DynamicType.titleBox] = TitleBoxComponent,
            _a[DynamicType.theaterDetail] = TheaterDetailComponent,
            _a[DynamicType.topicPageHeader] = TopicHeaderComponent,
            _a[DynamicType.topStories] = TopStoriesComponent,
            _a[DynamicType.toptopics] = TopTopicsComponent,
            _a[DynamicType.tripleArticleBox] = MultiTeaserComponent,
            _a[DynamicType.twitter] = TwitterComponent,
            _a[DynamicType.twitterTimeline] = TwitterTimelineComponent,
            _a[DynamicType.videoPlaylist] = VideoPlaylistComponent,
            _a[DynamicType.vimeo] = VimeoComponent,
            _a[DynamicType.youtube] = YouTubeComponent,
            _a[DynamicType.romyVoting] = RomyVotingComponent,
            _a[DynamicType.beforeAfterSlider] = BeforeAfterSliderComponent,
            _a[DynamicType.infobox] = InfoBoxComponent,
            _a[DynamicType.textBox] = TextBoxComponent,
            _a[DynamicType.newsletterSignUp] = NewsletterSignUpComponent,
            _a[DynamicType.cleverpushbutton] = CleverpushButtonComponent,
            _a[DynamicType.degrees23] = Degrees23Component,
            _a[DynamicType.seriesDetail] = MovieSerieDetailComponent,
            _a[DynamicType.reddit] = RedditComponent,
            _a[DynamicType.podigee] = PodigeeComponent,
            _a[DynamicType.tikTok] = TikTokComponent,
            _a[DynamicType.flourish] = FlourishComponent,
            _a[DynamicType.footballTable] = FootballTableLeagueComponent,
            _a[DynamicType.paywall] = PaywallComponent,
            _a[DynamicType.footballLiveStanding] = FootballLiveScoreWrapperComponent,
            _a[DynamicType.footballStages] = FootballStagesWrapperComponent,
            _a[DynamicType.footballCupTable] = FootballTableCupComponent,
            _a[DynamicType.personMovieList] = PersonMovieListComponent,
            _a[DynamicType.typedEmbed] = TypedEmbedComponent,
            _a[DynamicType.adPiano] = AdPianoComponent,
            _a[DynamicType.textLink] = TextLinkComponent,
            _a[DynamicType.teaserSlider] = MultiSliderComponent,
            _a[DynamicType.singleSlider2] = SingleSliderComponent,
            _a[DynamicType.gallerySlider2] = GallerySliderComponent,
            _a[DynamicType.linkTeaser2] = LinkTeaserComponent,
            _a[DynamicType.title] = TitleBoxComponent,
            _a);
        this.browserOnlyTypes = [
            DynamicType.apa_video,
            DynamicType.contentGardenArticle,
            DynamicType.contentGardenTeaser,
            DynamicType.facebook_post,
            DynamicType.facebook_video,
            DynamicType.google_map,
            DynamicType.iframe,
            DynamicType.immoSearch,
            DynamicType.outbrain,
            DynamicType.pinpoll,
            DynamicType.pinterest_board,
            DynamicType.pinterest_pin,
            DynamicType.pinterest_profile,
            DynamicType.riddle,
            DynamicType.romyVoting,
            DynamicType.soundcloud,
            DynamicType.storify,
            DynamicType.vimeo,
            DynamicType.youtube,
            DynamicType.reddit,
            DynamicType.podigee,
            DynamicType.tikTok,
            DynamicType.twitter,
            DynamicType.flourish,
            DynamicType.footballLiveStanding,
            DynamicType.footballStages,
            DynamicType.footballCupTable,
            DynamicType.typedEmbed,
        ];
    }
    DynamicComponentsService.prototype.create = function (resolver, vcRef, model) {
        if (this.mobileDetection.shouldRender(model.mobileOnly, model.tabletDesktopOnly, model.desktopOnly)) {
            var component = this.resolveType(model.type);
            var factory = resolver.resolveComponentFactory(component);
            var compRef = vcRef.createComponent(factory);
            var instance = compRef.instance;
            if (compRef && typeof instance.initModel === 'function') {
                instance.initModel(model);
            }
            else {
                console.log('no initModel() on type ' + model.type);
            }
            return compRef;
        }
    };
    DynamicComponentsService.prototype.resolveType = function (type) {
        if (isPlatformServer(this.platformId)) {
            if (this.isBrowserOnlyType(type)) {
                return EmptyComponent;
            }
        }
        var mappedType = this.typeMap[type];
        if (!mappedType) {
            console.warn('No mapped type for ' + type);
            return EmptyComponent;
        }
        return mappedType;
    };
    DynamicComponentsService.prototype.isBrowserOnlyType = function (t) {
        return this.browserOnlyTypes.indexOf(t) > -1;
    };
    DynamicComponentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DynamicComponentsService_Factory() { return new DynamicComponentsService(i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i1.DeviceDetectionService)); }, token: DynamicComponentsService, providedIn: "root" });
    return DynamicComponentsService;
}());
export { DynamicComponentsService };
