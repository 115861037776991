import { Router } from '@angular/router';
import { PortalService } from '../../shared/service/portal.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/service/portal.service";
import * as i2 from "@angular/router";
var FilmatGuard = /** @class */ (function () {
    function FilmatGuard(portalService, router) {
        this.portalService = portalService;
        this.router = router;
    }
    FilmatGuard.prototype.canActivate = function () {
        if (this.portalService.hostname() === 'film.at') {
            return true;
        }
        else {
            this.router.navigate(['/']);
            return false;
        }
    };
    FilmatGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FilmatGuard_Factory() { return new FilmatGuard(i0.ɵɵinject(i1.PortalService), i0.ɵɵinject(i2.Router)); }, token: FilmatGuard, providedIn: "root" });
    return FilmatGuard;
}());
export { FilmatGuard };
