/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../gpt.ad.directive";
import * as i2 from "../../../shared/service/route.service";
import * as i3 from "../../../shared/ads/gpt.service";
import * as i4 from "../../../shared/service/portal.service";
import * as i5 from "../../../shared/service/didomi.service";
import * as i6 from "../../../shared/service/piano/piano.service";
import * as i7 from "../../../shared/service/device-detection.service";
import * as i8 from "./adMediumRectangle.component";
import * as i9 from "../../../shared/service/browser.ref";
import * as i10 from "../../../shared/service/sticky.service";
var styles_AdMediumRectangleComponent = [];
var RenderType_AdMediumRectangleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdMediumRectangleComponent, data: {} });
export { RenderType_AdMediumRectangleComponent as RenderType_AdMediumRectangleComponent };
export function View_AdMediumRectangleComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { elmRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 1, "div", [["class", "adContainer adMediumRectangle wb wb-mediumRect"]], null, null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.GptAdDirective, [i0.PLATFORM_ID, i2.RouteService, i0.ElementRef, i0.Renderer2, i3.GooglePublisherTagService, i4.PortalService, i5.DidomiService, i6.PianoService, i7.DeviceDetectionService], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AdMediumRectangleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "admediumrectangle", [], null, null, null, View_AdMediumRectangleComponent_0, RenderType_AdMediumRectangleComponent)), i0.ɵdid(1, 573440, null, 0, i8.AdMediumRectangleComponent, [i9.BrowserRef, i0.Renderer2, i10.StickyService], null, null)], null, null); }
var AdMediumRectangleComponentNgFactory = i0.ɵccf("admediumrectangle", i8.AdMediumRectangleComponent, View_AdMediumRectangleComponent_Host_0, { adId: "adId", isSticky: "isSticky" }, {}, []);
export { AdMediumRectangleComponentNgFactory as AdMediumRectangleComponentNgFactory };
