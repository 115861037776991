import {Inject, Injectable} from '@angular/core';
import {PortalService} from './portal.service';
import {ENDPOINT_OPTIONS} from './tokens';
import {EndpointOptions} from '../../model/environment';
import {StateProvider, StateService} from './state.service';
import {ImageSrc, ImageType} from '../../model/enum/image';
import {IMG_SRCSET_MAP, IMG_W_MAP} from '../../model/const/image';

@Injectable({
  providedIn: 'root',
})
export class ImageService {

  private readonly imageHost: string;

  constructor(@Inject(StateService) private stateService: StateProvider<EndpointOptions>,
              private portalService: PortalService) {
    const endpointOptions = stateService.get(ENDPOINT_OPTIONS);
    this.imageHost = this.createImageHost(endpointOptions);
  }

  createImageHost(endpointOptions: EndpointOptions): string {
    switch (endpointOptions.type) {
      case 'derived':
        return 'https://image.' + this.portalService.hostname();

      case 'fixed':
        return endpointOptions.endpoint;

      case 'staging':
        return 'https://image-stage.' + this.portalService.hostname();

      default:
        throw new Error('Unhandled endpointOptions.type ' + endpointOptions.type);
    }
  }

  srcset(url: string, size: ImageType): string {
    if (!url || !size) {
      return '';
    }
    let srcset = '';
    const map = IMG_SRCSET_MAP[size] as Array<ImageSrc>;
    map.forEach((src, i) => {
      srcset += `${this.url(url, src)} ${IMG_W_MAP[size][i]}, `;
    });


    return srcset;
  }

  videoUrl(url: string): string {
    return this.imageHost + url;
  }

  gifUrl(url: string): string {
    return this.imageHost + url;
  }

  url(url: string, src: ImageSrc): string {
    if (!url) {
      return '';
    }
    const teaserRegex = /^\/images\/(teaser|large|original)/;
    if (teaserRegex.test(url)) {
      const replaceValue = '/images/' + src;
      const urlN = url.replace(teaserRegex, replaceValue);
      return this.imageHost + urlN;
    }
    return url;
  }
}
