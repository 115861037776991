import {ScriptId} from '../enum/scriptId';

export const SCRIPT_MAP = {
  [ScriptId.twitter]: 'https://platform.twitter.com/widgets.js',
  [ScriptId.facebook]: 'https://connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v8.0',
  [ScriptId.gpt]: 'https://www.googletagservices.com/tag/js/gpt.js',
  [ScriptId.instagram]: 'https://platform.instagram.com/en_US/embeds.js',
  [ScriptId.outbrain]: 'https://widgets.outbrain.com/outbrain.js',
  [ScriptId.pinterest]: 'https://assets.pinterest.com/js/pinit_main.js',
  [ScriptId.pusher]: 'https://js.pusher.com/4.1/pusher.min.js',
  [ScriptId.riddle]: 'https://www.riddle.com/files/js/embed.js',
  [ScriptId.opta]: 'https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js',
  [ScriptId.opta2]: 'https://secure.widget.cloud.opta.net/2.0/js/widgets.opta.js',
  [ScriptId.contentGarden]: 'https://cdn.content-garden.com/base.min.js',
  [ScriptId.adition]: 'https://imagesrv.adition.com/js/adition.js',
  [ScriptId.mediakey]: '',
  [ScriptId.tinypass]: '',
  [ScriptId.justwatch]: 'https://widget.justwatch.com/justwatch_widget.js',
  [ScriptId.reddit]: 'https://embed.redditmedia.com/widgets/platform.js',
  [ScriptId.tiktok]: 'https://www.tiktok.com/embed.js',
  [ScriptId.playerjs]: '//cdn.embed.ly/player-0.0.12.min.js',
  [ScriptId.flourish]: 'https://public.flourish.studio/resources/embed.js',
  [ScriptId.sportmonkLivescore]: 'https://widgets.sportmonks.com/js/world-cup/livescore.js',
  [ScriptId.sportmonkStandings]: 'https://widgets.sportmonks.com/js/world-cup/standings.js',
  [ScriptId.sportmonkStandingsSquad]: 'https://widgets.sportmonks.com/js/world-cup/livescore.js',
  [ScriptId.sportmonkStandingsSlider]: 'https://widgets.sportmonks.com/js/world-cup/standingsSlider.js',
  [ScriptId.sportmonkLivestatus]: 'https://widgets.sportmonks.com/js/livescore/livescore.js',
  [ScriptId.oewa]: '',
  [ScriptId.oewaBundle]: '',
};

export const SCRIPT_ID_MAP = {
  [ScriptId.twitter]: 'twttr',
  [ScriptId.facebook]: 'FB',
  [ScriptId.gpt]: 'googletag',
  [ScriptId.instagram]: 'instgrm',
  [ScriptId.outbrain]: 'OBR',
  [ScriptId.pinterest]: '',
  [ScriptId.pusher]: 'Pusher',
  [ScriptId.riddle]: 'riddleAPI',
  [ScriptId.opta]: 'Opta',
  [ScriptId.opta2]: '$jqOpta',
  [ScriptId.contentGarden]: 'contentGardenContentDelivery',
  [ScriptId.mediakey]: 'MediakeyClient',
  [ScriptId.cleverpush]: 'CleverPush',
  [ScriptId.justwatch]: 'JustWatch',
  [ScriptId.reddit]: 'Reddit',
  [ScriptId.tiktok]: 'tikTok',
  [ScriptId.playerjs]: 'playerjs',
  [ScriptId.comments]: 'Coral',
  [ScriptId.oewa]: 'IOMm',
  [ScriptId.oewaBundle]: '',
};
