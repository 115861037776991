import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {ImageType} from '../../../../model/enum/image';
import {Author} from '../../../../model/author/author';
import {ImageModel} from '../../../../model/component/image';
import {ShareModel} from '../../../../model/component/share';
import {SHARE_CLASS_MAP} from '../../../../model/const/share';
import {IAuthorHeader} from '../../../../model/search/payload';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'authorheader',
  templateUrl: 'authorHeader.component.html'
})
export class AuthorHeaderComponent implements DynamicComponent<IAuthorHeader> {

  authorData: Author = new Author();
  imageModel: ImageModel;
  map = SHARE_CLASS_MAP;
  shareModel: ShareModel;

  initModel(model: IAuthorHeader): void {
    this.authorData = Author.deserializeItem(model, '');
    this.shareModel = ShareModel.fromAuthor(model);
    this.imageModel = new ImageModel(this.authorData.picture, ImageType.SQ, null, true);
  }
}
