/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../no-consent/no-consent.component.ngfactory";
import * as i2 from "../no-consent/no-consent.component";
import * as i3 from "../../../../shared/service/didomi.service";
import * as i4 from "@angular/common";
import * as i5 from "./newsletter-sign-up.component";
import * as i6 from "../../../../shared/service/portal.service";
var styles_NewsletterSignUpComponent = [];
var RenderType_NewsletterSignUpComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewsletterSignUpComponent, data: {} });
export { RenderType_NewsletterSignUpComponent as RenderType_NewsletterSignUpComponent };
function View_NewsletterSignUpComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_NewsletterSignUpComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i1.View_NoConsentComponent_0, i1.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i2.NoConsentComponent, [i3.DidomiService], { vendor: [0, "vendor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NewsletterSignUpComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pianoContainerSelector; _ck(_v, 0, 0, currVal_0); }); }
function View_NewsletterSignUpComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("_form_" + _co.acId); _ck(_v, 0, 0, currVal_0); }); }
export function View_NewsletterSignUpComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_NewsletterSignUpComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["noContent", 2]], null, 0, null, View_NewsletterSignUpComponent_2)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewsletterSignUpComponent_3)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewsletterSignUpComponent_4)), i0.ɵdid(7, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.showNewsletter$)); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.pianoContainerSelector; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.acId; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_NewsletterSignUpComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "newslettersignup", [], null, null, null, View_NewsletterSignUpComponent_0, RenderType_NewsletterSignUpComponent)), i0.ɵdid(1, 114688, null, 0, i5.NewsletterSignUpComponent, [i6.PortalService, i0.Renderer2, i0.ElementRef, i3.DidomiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewsletterSignUpComponentNgFactory = i0.ɵccf("newslettersignup", i5.NewsletterSignUpComponent, View_NewsletterSignUpComponent_Host_0, { acId: "acId" }, {}, []);
export { NewsletterSignUpComponentNgFactory as NewsletterSignUpComponentNgFactory };
