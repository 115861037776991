import {ElementRef, Injectable, Renderer2, RendererFactory2} from '@angular/core';

export interface IStickySubscriber {
  visibilityChanged(isVisible: boolean);
}

@Injectable({
  providedIn: 'root',
})
export class StickyService {

  private observer = new Map<string, IntersectionObserver>();
  private subscribers = new Map<string, IStickySubscriber[]>();
  private targets = new Map<string, ElementRef>();
  private isVisible = new Map<string, boolean>();
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  addTarget(id: string, elm: ElementRef) {

    this.targets.set(id, elm);
    this.isVisible.set(id, elm.nativeElement.offsetHeight > 0);

    const options = {
      rootMargin: '0px',
      threshold: 0
    };
    this.observer.set(id, new IntersectionObserver(
      entries => {
        entries.forEach((entry) => {
          // NOTE entry.intersectionRatio > 0 is for edge 15 who doesn't set entry.isIntersecting
          this.isVisible.set(id, entry.isIntersecting || entry.intersectionRatio > 0);
          if (this.subscribers.get(id)) {
            this.subscribers.get(id).forEach(s => {
              s.visibilityChanged(this.isVisible.get(id));
            });
          }
        });
      }, options));

    this.observer.get(id).observe(elm.nativeElement);

  }

  subscribe(id: string, s: IStickySubscriber) {
    let a = this.subscribers.get(id);
    if (!a) {
      a = [];
      this.subscribers.set(id, a);
    } else {
      if (a.indexOf(s) !== -1) {
        return;
      }
    }

    a.push(s);
    if (this.isVisible.get(id) === undefined) {
      this.isVisible.set(id, true);
    }
    s.visibilityChanged(this.isVisible.get(id));
  }

  unsubscribe(id: string, us: IStickySubscriber) {
    this.subscribers.set(id, this.subscribers.get(id).filter(s => s !== us));
  }
}
