/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../no-consent/no-consent.component.ngfactory";
import * as i3 from "../no-consent/no-consent.component";
import * as i4 from "../../../../shared/service/didomi.service";
import * as i5 from "./degrees23.component";
import * as i6 from "../../../../shared/service/api.service";
import * as i7 from "@angular/platform-browser";
var styles_Degrees23Component = [];
var RenderType_Degrees23Component = i0.ɵcrt({ encapsulation: 2, styles: styles_Degrees23Component, data: {} });
export { RenderType_Degrees23Component as RenderType_Degrees23Component };
function View_Degrees23Component_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 1, 0, currVal_0); }); }
function View_Degrees23Component_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_Degrees23Component_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.response$)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_Degrees23Component_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i2.View_NoConsentComponent_0, i2.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i3.NoConsentComponent, [i4.DidomiService], { vendor: [0, "vendor"], image: [1, "image"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; var currVal_1 = _co.imageUrl; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_Degrees23Component_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_Degrees23Component_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["noContent", 2]], null, 0, null, View_Degrees23Component_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.showDegrees23$)); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_Degrees23Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "degrees23", [], null, null, null, View_Degrees23Component_0, RenderType_Degrees23Component)), i0.ɵdid(1, 49152, null, 0, i5.Degrees23Component, [i6.ApiService, i4.DidomiService, i7.DomSanitizer, i0.Renderer2, i1.DOCUMENT], null, null)], null, null); }
var Degrees23ComponentNgFactory = i0.ɵccf("degrees23", i5.Degrees23Component, View_Degrees23Component_Host_0, {}, {}, []);
export { Degrees23ComponentNgFactory as Degrees23ComponentNgFactory };
