/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../no-consent/no-consent.component.ngfactory";
import * as i2 from "../no-consent/no-consent.component";
import * as i3 from "../../../../shared/service/didomi.service";
import * as i4 from "@angular/common";
import * as i5 from "./googlemaps.component";
import * as i6 from "@angular/platform-browser";
var styles_GoogleMapsComponent = [];
var RenderType_GoogleMapsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GoogleMapsComponent, data: {} });
export { RenderType_GoogleMapsComponent as RenderType_GoogleMapsComponent };
function View_GoogleMapsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "iframe", [["frameborder", "0"], ["height", "300"], ["style", "border:0"], ["width", "100%"]], [[1, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); }); }
function View_GoogleMapsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i1.View_NoConsentComponent_0, i1.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i2.NoConsentComponent, [i3.DidomiService], { vendor: [0, "vendor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GoogleMapsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_GoogleMapsComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["noContent", 2]], null, 0, null, View_GoogleMapsComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.showGoogleMaps$)); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_GoogleMapsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "googlemaps", [], null, null, null, View_GoogleMapsComponent_0, RenderType_GoogleMapsComponent)), i0.ɵdid(1, 49152, null, 0, i5.GoogleMapsComponent, [i6.DomSanitizer, i3.DidomiService], null, null)], null, null); }
var GoogleMapsComponentNgFactory = i0.ɵccf("googlemaps", i5.GoogleMapsComponent, View_GoogleMapsComponent_Host_0, {}, {}, []);
export { GoogleMapsComponentNgFactory as GoogleMapsComponentNgFactory };
