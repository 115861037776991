import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {PortalService} from '../../shared/service/portal.service';

@Injectable({
  providedIn: 'root',
})
export class KurieratGuard implements CanActivate {

  constructor(protected readonly portalService: PortalService,
              private router: Router) {
  }

  canActivate() {
    if (this.portalService.hostname() === 'kurier.at') {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
