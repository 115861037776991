import {Component, Input} from '@angular/core';
import {LinkCardModel} from '../../../model/component/linkCard';

@Component({
  selector: 'linkcard',
  templateUrl: './linkCard.component.html'
})
export class LinkCardComponent {

  @Input() model: LinkCardModel;

}
