import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {IImmoLocationSearch} from '../../model/channel/immoBox/immoBox';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ExternalApiService {

  constructor(private http: HttpClient) {
  }

  immoSearch(searchTerm: string): Observable<IImmoLocationSearch> {
    return this.getUncached('https://immo.kurier.at/geo_location_suggest?searchTerm=' + searchTerm);
  }

  jobSearch(term: string): Observable<any> {
    const host = 'job.kurier.at';
    const url = 'https://' + host + '/sapi_autocomplete.php?machine_name=search_api_views_job_search&search=' + term;
    return this.getUncached(url);
  }

  private getUncached(url: string, options?: any): Observable<any> {
    return this.http.get(url, options).pipe(
      catchError(err => {
        const detail = err.message || err.statusText;
        console.warn('API Error (' + url + '): ' + detail);
        return [];
      }),
    );
  }
}
