import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ExternalApiService = /** @class */ (function () {
    function ExternalApiService(http) {
        this.http = http;
    }
    ExternalApiService.prototype.immoSearch = function (searchTerm) {
        return this.getUncached('https://immo.kurier.at/geo_location_suggest?searchTerm=' + searchTerm);
    };
    ExternalApiService.prototype.jobSearch = function (term) {
        var host = 'job.kurier.at';
        var url = 'https://' + host + '/sapi_autocomplete.php?machine_name=search_api_views_job_search&search=' + term;
        return this.getUncached(url);
    };
    ExternalApiService.prototype.getUncached = function (url, options) {
        return this.http.get(url, options).pipe(catchError(function (err) {
            var detail = err.message || err.statusText;
            console.warn('API Error (' + url + '): ' + detail);
            return [];
        }));
    };
    ExternalApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExternalApiService_Factory() { return new ExternalApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ExternalApiService, providedIn: "root" });
    return ExternalApiService;
}());
export { ExternalApiService };
