import { catchError, finalize, take, tap } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { PortalService } from './portal.service';
import { of, throwError } from 'rxjs';
import { ENDPOINT_OPTIONS } from './tokens';
import * as buildUrl from 'build-url';
import { StateService } from './state.service';
import { HttpClient } from '@angular/common/http';
import { MovieListOrder } from '../../model/enum/movie';
import { LoadingService } from './loading.service';
import { SearchOrder } from '../../model/enum/search';
import * as i0 from "@angular/core";
import * as i1 from "./state.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./cache.service";
import * as i4 from "./loading.service";
import * as i5 from "./portal.service";
var ApiService = /** @class */ (function () {
    function ApiService(stateService, http, cache, loadingService, portalService) {
        this.stateService = stateService;
        this.http = http;
        this.cache = cache;
        this.loadingService = loadingService;
        this.portalService = portalService;
        var endpointOptions = stateService.get(ENDPOINT_OPTIONS);
        this.endpoint = ApiService.createEndpoint(endpointOptions, portalService);
    }
    ApiService.createEndpoint = function (endpointOptions, portalService) {
        switch (endpointOptions.type) {
            case 'fixed':
                return endpointOptions.endpoint + '/api/v1/cfs';
            case 'derived':
                return 'https://' + portalService.subdomain('efs') + '/api/v1/cfs';
            case 'staging':
                return 'https://' + portalService.subdomain('efs-stage') + '/api/v1/cfs';
            default:
                throw new Error('Unhandled endpointOptions.type ' + endpointOptions.type);
        }
    };
    ApiService.prototype.movieCollection = function (collection, start, limit, genre, term, order) {
        if (order === void 0) { order = MovieListOrder.releaseDate; }
        var url = this.endpoint + "/collection/" + encodeURI(collection) + "?start=" + start + "&limit=" + limit + "&order=" + order;
        if (genre) {
            url += "&genre=" + encodeURI(genre);
        }
        if (term && term !== '') {
            url += "&term=" + encodeURI(term);
        }
        return this.getCached(url, true);
    };
    ApiService.prototype.collectionTeasers = function (collection, start, limit) {
        return this.getUncached(this.endpoint + '/collection/' + encodeURI(collection) + '?start=' + start + '&limit=' + limit, true);
    };
    ApiService.prototype.collectionTeasersCached = function (collection, start, limit) {
        return this.getCached(this.endpoint + '/collection/' + encodeURI(collection) + '?start=' + start + '&limit=' + limit, true);
    };
    ApiService.prototype.singleHoroscope = function (sign) {
        return this.getCached(this.endpoint + '/zodiac/' + sign, true);
    };
    ApiService.prototype.singleTeaser = function (id) {
        return this.getCached(this.endpoint + '/teaser/' + id, true);
    };
    ApiService.prototype.articleRelateds = function (docId, limit) {
        return this.getCached(this.endpoint + '/relateds/article/' + docId + '?limit=' + limit, false);
    };
    ApiService.prototype.sectionRelateds = function (section, limit) {
        return this.getCached(this.endpoint + '/relateds/section/' + section + '?limit=' + limit, false);
    };
    ApiService.prototype.channelRelateds = function (channel, limit) {
        return this.getCached(this.endpoint + '/relateds/channel/' + channel + '?limit=' + limit, false);
    };
    ApiService.prototype.liveblogEntries = function (liveblogId, start, limit) {
        return this.getCached(this.endpoint + '/liveblog_posts/' + liveblogId + '?start=' + start + '&limit=' + limit, true);
    };
    ApiService.prototype.gallery = function (galleryId, limit, articleId) {
        var url = this.endpoint + '/gallery/' + galleryId + '?limit=' + limit + '&articleId=' + articleId;
        return this.getCached(url, true);
    };
    ApiService.prototype.searchByTerm = function (term, ordering, pageSize, page, portal) {
        var url = buildUrl(this.endpoint, {
            path: 'search',
            queryParams: {
                term: term,
                order: ordering,
                limit: pageSize.toString(),
                page: page.toString(),
                portal: portal,
            }
        });
        return this.getCached(url, false);
    };
    ApiService.prototype.searchByQuery = function (query, ordering, pageSize, page) {
        var url = buildUrl(this.endpoint, {
            path: 'search',
            queryParams: {
                query: encodeURI(query),
                order: ordering,
                limit: pageSize.toString(),
                page: page.toString(),
                portal: this.portalService.hostname(),
            }
        });
        return this.getCached(url, false);
    };
    ApiService.prototype.searchByTopic = function (topic, ordering, pageSize, page) {
        var url = buildUrl(this.endpoint, {
            path: 'search',
            queryParams: {
                topic: encodeURIComponent(topic),
                order: ordering,
                limit: pageSize.toString(),
                page: page.toString(),
            }
        });
        return this.getCached(url, false);
    };
    ApiService.prototype.searchByChannel = function (channel, pageSize, page) {
        var url = buildUrl(this.endpoint, {
            path: 'search',
            queryParams: {
                channel: encodeURIComponent(channel),
                limit: pageSize.toString(),
                order: SearchOrder.ORDER_RELEVANCE,
                page: page.toString(),
            }
        });
        return this.getCached(url, false);
    };
    ApiService.prototype.screenings = function (type, date, id, city, tags) {
        var q = [];
        if (city) {
            q.push("city=" + city);
        }
        if (tags) {
            q.push("tags=" + tags);
        }
        var query = q.length ? '?' + q.join('&') : '';
        if (type && id) {
            return this.getCached(this.endpoint + ("/filmat/screenings/flat/" + type + "/" + id + "/" + date + query), false);
        }
        return this.getCached(this.endpoint + ("/filmat/screenings/nested/" + type + "/" + date + query), false);
    };
    ApiService.prototype.route = function (uri) {
        return this.getCached(this.endpoint + '/route?uri=' + uri, true);
    };
    ApiService.prototype.menu = function (portal, name) {
        return this.getCached(this.endpoint + '/menu/' + portal + '-' + name, false);
    };
    ApiService.prototype.getCached = function (url, showLoading, options) {
        var _this = this;
        var key = url;
        // use cache to transmit state from server to client and prevent flickering
        if (this.cache.has(key)) {
            return of(this.cache.get(key)).pipe(take(1), tap(function () { return _this.loadingService.stopLoading(); }));
        }
        return this.getUncached(url, showLoading, options).pipe(tap(function (data) {
            _this.cache.set(key, data);
        }));
    };
    ApiService.prototype.getUncached = function (url, showLoading, options) {
        var _this = this;
        if (showLoading) {
            this.loadingService.startLoading(true);
        }
        var key = url;
        var obs$ = this.http.get(url, options);
        // NOTE sometimes angular will cancel requests if they're fired too quickly in succession
        // https://stackoverflow.com/questions/45844426/angular-4-a-series-of-http-requests-get-cancelled
        // unfortunately this will result in double requestsw
        // obs$.take(1).subscribe(res => this.loadingService.stopLoading());
        return obs$.pipe(catchError(function (err) {
            var detail = err.message || err.statusText;
            console.warn('API Error (' + url + '): ' + detail);
            _this.cache.set(key, []);
            _this.loadingService.stopLoading();
            return throwError(err);
        }), tap(function () {
            _this.loadingService.stopLoading();
        }), finalize(function () {
            _this.loadingService.stopLoading();
        }));
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.StateService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.CacheService), i0.ɵɵinject(i4.LoadingService), i0.ɵɵinject(i5.PortalService)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
