<div>filmat review</div>
<!--<section class="section other-plattforms filmat f-review">-->
  <!--<div class="box-headline">-->
    <!--<a class="isolating-link" href="#"></a>-->
  <!--</div>-->
  <!--<article class="article list-element-img">-->
    <!--<a-->
      <!--href="https://www.film.at/pia-hierzegger-ueber-josef-haders-wilde-maus-als-menschen-sind-wir-staendig-irgendwie-laecherlich"-->
      <!--title="Filmkritik Wilde Maus">-->
      <!--<figure class="figure">-->
        <!--<img class="middleImg" alt="Wilde Maus Film" title="Wilde Maus Film" src="assets/images/filmat.jpg">-->
      <!--</figure>-->
      <!--<div class="textParagraph-container-middle">-->
        <!--<header class="header">-->
          <!--<h2 class="title">Filmkritik</h2>-->
        <!--</header>-->
        <!--<p class="leadtext">-->
          <!--Pia Hierzegger über Haders "Wilde Maus": "Als Menschen ständig irgendwie lächerlich"-->
        <!--</p>-->
        <!--<span class="link">weiterlesen</span>-->
        <!--<div class="clear"></div>-->
      <!--</div>-->
      <!--<div class="clear"></div>-->
    <!--</a>-->
  <!--</article>-->
<!--</section>-->
