import { Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { StateService } from './state.service';
import * as i0 from "@angular/core";
import * as i1 from "./state.service";
import * as i2 from "@angular/common";
var InjectScriptsService = /** @class */ (function () {
    function InjectScriptsService(stateService, rendererFactory, document) {
        this.stateService = stateService;
        this.rendererFactory = rendererFactory;
        this.document = document;
        this.renderer = this.rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });
    }
    InjectScriptsService.prototype.appendToBodyOnce = function (def) {
        if (this.stateService.isFirstApp) {
            this.renderer.appendChild(this.document.body, this.createScript(def));
        }
    };
    InjectScriptsService.prototype.appendToBody = function (def) {
        this.renderer.appendChild(this.document.body, this.createScript(def));
    };
    InjectScriptsService.prototype.injectToHeadOnce = function (def) {
        if (this.stateService.isFirstApp) {
            this.renderer.appendChild(this.document.head, this.createScript(def));
        }
    };
    InjectScriptsService.prototype.createScript = function (def) {
        var _this = this;
        var script = this.renderer.createElement('script');
        if (def.attrs) {
            Object.keys(def.attrs).forEach(function (prop) {
                return _this.renderer.setAttribute(script, prop, def.attrs[prop]);
            });
        }
        if (def.innerHTML) {
            var text = this.renderer.createText(def.innerHTML);
            this.renderer.appendChild(script, text);
        }
        return script;
    };
    InjectScriptsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InjectScriptsService_Factory() { return new InjectScriptsService(i0.ɵɵinject(i1.StateService), i0.ɵɵinject(i0.RendererFactory2), i0.ɵɵinject(i2.DOCUMENT)); }, token: InjectScriptsService, providedIn: "root" });
    return InjectScriptsService;
}());
export { InjectScriptsService };
