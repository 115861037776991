import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';

export interface ILoadingNotification {
  shouldLoad: boolean;
  hasOverlay: boolean;
}


@Injectable({
  providedIn: 'root',
})
export class LoadingService {

  private readonly loadingInfo;

  private loadingCount = 0;
  private internalOverlayCount = 0;

  constructor(private router: Router) {
    this.loadingInfo = new BehaviorSubject<ILoadingNotification>({shouldLoad: false, hasOverlay: false});
  }

  get loadingObs$(): Observable<ILoadingNotification> {
    return this.loadingInfo.asObservable();
  }

  startLoading(hasOverlay: boolean) {
    this.loadingCount++;
    this.overlayCount = hasOverlay ? 1 : -1;
    if (this.loadingCount > 1) {
      return;
    }
    this.loadingInfo.next({shouldLoad: true, hasOverlay: this.internalOverlayCount > 0});
  }

  stopLoading(removeOverlay = false) {
    if (this.loadingCount === 0) {
      return;
    }
    this.loadingCount--;
    if (removeOverlay) {
      this.overlayCount = -1;
    }
    if (this.loadingCount === 0) {
      this.loadingInfo.next({shouldLoad: false, hasOverlay: false});
    } else {

      this.loadingInfo.next({shouldLoad: true, hasOverlay: this.internalOverlayCount > 0});
    }
  }

  private set overlayCount(value: number) {
    this.internalOverlayCount += value;
    if (this.internalOverlayCount < 0) {
      this.internalOverlayCount = 0;
    }
  }

}
