export enum GTMTriggerId {
  navbar = 'navbar-link',
  post_comment = 'post-comment',
  tab_change = 'tab',
  user_status = 'user-status',
  teaser_pretitle = 'teaser-pretitle',
  menu = 'menu',
  logo = 'logo',
  newsletter = 'newsletter',
  show_comments_button = 'show-comments-button',
  more_button = 'more-button',
  teaser_headline = 'teaser-headline',
  channel_relateds = 'article-channel-relateds',
  article_related = 'article-related',
  accordeon = 'accordeon',
  badge = 'genre',
  posterSlider = 'posterSlider',
}
