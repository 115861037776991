import {Filter} from '../../component/filter';
import {IScreenings, IScreeningsItem, IScreeningsParent, IScreeningsResponse, IScreeningsResult} from './payload';
import {FilterType} from '../../enum/filter';
import {TimeAgoUtil} from '../../../util/timeAgo';
import {PortalRoute} from '../../teaser/portalRoute';
import {LinkBarModel} from '../../component/linkBar';
import {ScreeningsType} from '../../enum/screenings';

export type FilterItem = IScreeningsParent | IScreeningsItem;

export class ScreeningsModel {

  filter: Filter<any, FilterItem>[] = [];
  accordeon: LinkBarModel[];
  list: { title: string, name: string, link: PortalRoute }[][];
  data: IScreeningsResponse;
  emptyMessage: string;

  static fromScreenings(data: IScreeningsResponse, model: IScreenings): ScreeningsModel {
    const f = new ScreeningsModel();
    f.data = data;

    if (data.filter.days) {
      const now = TimeAgoUtil.getYYYYmmdd(new Date());
      let today;
      const dF = new Filter(FilterType.select, 'day', 'Datum',
        data.filter.days.map((t, i) => {
          if (t === now) {
            today = i;
          }
          const d = new Date(t);
          return {name: d.toLocaleDateString('de-DE', {weekday: 'short', day: '2-digit', month: '2-digit'}), value: t};
        }),
        () => true
      );
      dF.valueSelected = today;
      f.filter.push(dF);
    }

    if (model.city || model.county || data.filter.counties) {
      const counties = [
        'Wien',
        'Niederösterreich',
        'Oberösterreich',
        'Steiermark',
        'Burgenland',
        'Kärnten',
        'Salzburg',
        'Tirol',
        'Vorarlberg',
      ];
      const countyObj = counties.map(t => {
        return {name: t, value: t};
      });
      countyObj.unshift({name: 'Alle', value: undefined});
      // Preselect county if api send it
      let current = 0;
      if (model.county) {
        current = countyObj.map(county => {
          return county.name;
        }).indexOf(model.county);
      }
      // Preselect end
      f.filter.push(new Filter<string, IScreeningsParent>(FilterType.select, 'county', 'Bundesland',
        countyObj,
        (sp: IScreeningsParent, filter: Filter<string, IScreeningsParent>) => {
          if (sp.type === undefined || filter.isUnset) {
            return true;
          }
          return sp.type === ScreeningsType.movie || sp.county === filter.selectedValue;
        }, current
      ));
    }

    f.filter.push(new Filter<boolean, IScreeningsItem>(FilterType.checkbox, 'ov', 'OV', null,
      (sp: IScreeningsItem, filter: Filter<boolean, IScreeningsItem>) => {
        if (sp.ov === undefined) {
          return true;
        }
        return !filter.valueSelected || sp.ov;
      }));
    f.filter.push(new Filter<boolean, IScreeningsItem>(FilterType.checkbox, '3d', '3D', null,
      (sp: IScreeningsItem, filter: Filter<boolean, IScreeningsItem>) => {
        if (!filter.valueSelected || sp['3d'] === undefined) {
          return true;
        }
        return sp['3d'];
      }));

    if (data.filter.tags && data.filter.tags.length > 0) {
      const tags = data.filter.tags.map(t => {
        return {name: t, value: t};
      });
      tags.push({name: 'Alle', value: undefined});
      f.filter.push(new Filter<string, IScreeningsItem>(FilterType.select, 'tag', 'Tags',
        tags,
        (sp: IScreeningsItem, filter: Filter<string, IScreeningsItem>) => {
          if (filter.isUnset || sp.tags === undefined) {
            return true;
          }
          return sp.tags.some(t => t === filter.selectedValue);
        }
      ));
    }

    f.buildList(model);

    return f;
  }

  buildList(model: IScreenings) {
    this.list = [];
    this.accordeon = [];
    this.data.result.forEach(r => {
      if (r.nestedResults) {
        const p = this.filter.every(filter => {
          return filter.filter(r.parent, filter);
        });
        if (!p) {
          return;
        }
        const l = r.nestedResults
          .map(nR => this.getListItem(nR))
          .filter(Boolean);
        if (l.length > 0) {
          this.accordeon.push(new LinkBarModel(r.parent.title, r.parent.uri, true));
          this.list.push(l);
        }
      } else if (r.screenings) {
        if (!this.list[0]) {
          this.list.push([]);
        }
        const li = this.getListItem(r);
        if (li) {
          this.list[0].push(li);
        }
      }
    });
    if (this.data.result.length === 0) {
      if (model.id) {
        if (model.category === ScreeningsType.movie) {
          this.emptyMessage = 'Für diesen Film gibt es leider keine Vorstellungen.';
        } else {
          this.emptyMessage = 'In diesem Kino gibt es zur Zeit keine Vorstellungen.';
        }
      }
    } else {
      this.list.forEach(r =>
        r.sort((s1, s2) => {
          if (s1.title < s2.title) {
            return -1;
          }
          if (s1.title > s2.title) {
            return 1;
          }
          return 0;
        })
      );
    }
  }

  private getListItem(item: IScreeningsResult) {
    const p = this.filter.every(filter => {
      return filter.filter(item.parent, filter);
    });
    if (!p) {
      return null;
    }
    const filtered = item.screenings.filter(si => {
      return this.filter.every(filter => {
        return filter.filter(si, filter);
      });
    });

    if (filtered.length === 0) {
      return null;
    }

    return {
      title: item.parent.title,
      name: filtered.map(s => {
        return TimeAgoUtil.getTime(s.time) + (s.info ? ` (${s.info.trim()})` : '');
      }).reduce((s1, s2) => s1 + ' <span class="seperator">|</span> ' + s2),
      link: PortalRoute.fromUri(item.parent.uri)
    };
  }
}
