<div class="footballLiveScores" *ngIf="liveStandings">
  <header>
    <h2 class="footballLiveScores-header">
      <span class="game-date" *ngIf="liveStandings.isLive">
        <svgicon [icon]="'circle'"></svgicon>
        Live
      </span>
      <span class="game-location">{{liveStandings.venue.name}}</span>
    </h2>
  </header>
  <div class="footballLiveScores-game-info">
    <div class="footballLiveScores-team local-team">
      <img [src]="liveStandings.localTeam.logo_path" class="lazyload team-logo" alt="Team Logo">
      <p class="team-name">{{ liveStandings.localTeam.name }}</p>
    </div>
    <div class="footballLiveScores-score">
      <ng-container *ngIf="liveStandings.scores || liveStandings.min; else vs">
        <p>{{liveStandings.scores}}</p>
        <p>{{liveStandings.min}}</p>
      </ng-container>
      <ng-template #vs>
        <p>vs</p>
      </ng-template>
    </div>
    <div class="footballLiveScores-team">
      <img [src]="liveStandings.visitorTeam.logo_path" class="lazyload team-logo" alt="Team Logo">
      <p class="team-name">{{ liveStandings.visitorTeam.name }}</p>
    </div>
  </div>

  <accordeon
    *ngIf="liveStandings.events.length"
    [title]="'Liveticker'"
    [isOpen]="accordeonOpen.getValue()">
    <div class="footballLiveScores-timeline">
      <ng-container *ngFor="let event of liveStandings.events; let i = index">
        <div class="footballLiveScores-timeline-event"
             [class.visitor]="liveStandings.visitorTeam.id == event.team_id">
          <div class="footballLiveScores-timeline-event-time">{{event.minute}} min</div>
          <div class="footballLiveScores-timeline-event-action">
            <div class="player">
              <span class="player-name">{{event.player_name}}</span>
              <div *ngIf="event.type" class="icons">
                <svgicon *ngFor="let icon of iconMap[event.type]"
                         [icon]="icon">
                </svgicon>
              </div>
            </div>

            <div class="player"
                 *ngIf="event.type=='substitution'">
              <span>{{event.related_player_name}}</span>
              <div>
                <svgicon [icon]="'football-angle-down'"></svgicon>
              </div>
            </div>

          </div>
        </div>
        <h3 class="pen_shootout"
            *ngIf="(event.type === 'pen_shootout_goal' || event.type === 'pen_shootout_miss') &&
          liveStandings.events[i + 1].type.includes('pen_shootout') === false">
          Elfmeterschießen</h3>
      </ng-container>
    </div>
  </accordeon>
</div>
