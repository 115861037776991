/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./animation.component";
import * as i3 from "../../../shared/service/loading.service";
var styles_AnimationComponent = [];
var RenderType_AnimationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AnimationComponent, data: {} });
export { RenderType_AnimationComponent as RenderType_AnimationComponent };
function View_AnimationComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "loadingBar-overlay"]], null, null, null, null, null))], null, null); }
function View_AnimationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "loadingBar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "loadingBar-bar"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AnimationComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.hasOverlay; _ck(_v, 4, 0, currVal_0); }, null); }
function View_AnimationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AnimationComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.ngIf.shouldLoad; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AnimationComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_AnimationComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.loading$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AnimationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "animation", [], null, null, null, View_AnimationComponent_0, RenderType_AnimationComponent)), i0.ɵdid(1, 49152, null, 0, i2.AnimationComponent, [i3.LoadingService], null, null)], null, null); }
var AnimationComponentNgFactory = i0.ɵccf("animation", i2.AnimationComponent, View_AnimationComponent_Host_0, {}, {}, []);
export { AnimationComponentNgFactory as AnimationComponentNgFactory };
