import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[gtm]'
})
export class GtmTriggerDirective implements OnInit {

  @Input('gtm') gtmId: string;

  constructor(private element: ElementRef,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
    if (!this.gtmId) {
      return;
    }
    this.renderer.setAttribute(this.element.nativeElement, 'id', this.gtmId + Math.floor(Math.random() * Math.pow(10, 15)));
  }

}
