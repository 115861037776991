/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../home/content/no-consent/no-consent.component.ngfactory";
import * as i2 from "../../home/content/no-consent/no-consent.component";
import * as i3 from "../../../shared/service/didomi.service";
import * as i4 from "@angular/common";
import * as i5 from "./outbrain.component";
import * as i6 from "../../../shared/service/loadExternalScript.service";
import * as i7 from "../../../shared/service/route.service";
import * as i8 from "../../../shared/service/piano/piano.service";
var styles_OutbrainComponent = [];
var RenderType_OutbrainComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OutbrainComponent, data: {} });
export { RenderType_OutbrainComponent as RenderType_OutbrainComponent };
function View_OutbrainComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["obHolder", 1]], null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "OUTBRAIN outbrain"]], [[1, "data-src", 0], [1, "data-widget-id", 0], [1, "data-ob-template", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modifiedModel.src; var currVal_1 = _co.modifiedModel.widget; var currVal_2 = _co.modifiedModel.template; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_OutbrainComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i1.View_NoConsentComponent_0, i1.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i2.NoConsentComponent, [i3.DidomiService], { vendor: [0, "vendor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OutbrainComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { obHolder: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OutbrainComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OutbrainComponent_2)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showConsentOverlay; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_OutbrainComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "outbrain", [], null, null, null, View_OutbrainComponent_0, RenderType_OutbrainComponent)), i0.ɵdid(1, 4374528, null, 0, i5.OutbrainComponent, [i0.NgZone, i6.LoadExternalScriptService, i3.DidomiService, i7.RouteService, i8.PianoService, i0.ChangeDetectorRef], null, null)], null, null); }
var OutbrainComponentNgFactory = i0.ɵccf("outbrain", i5.OutbrainComponent, View_OutbrainComponent_Host_0, { model: "model" }, {}, []);
export { OutbrainComponentNgFactory as OutbrainComponentNgFactory };
