import {Component, Input} from '@angular/core';
import {IFootballData, IFootballLiveScore, IFootballTeam} from '@model/content/footballTable/payload';
import {FootballService} from '@shared/service/football.service';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-football-live-games',
  templateUrl: './football-live-games.component.html',
})
export class FootballLiveGamesComponent {
  constructor(
    private footballService: FootballService,
  ) {
  }

  @Input() model: IFootballData<IFootballLiveScore[]>;

  getVenueById(id: number): Observable<string> {
    return this.footballService.getVenueById(id)
      .pipe(
        map(res => res.data.name),
      );
  }

  getTime(time: string) {
    const d = new Date();
    d.setUTCHours(+time.split(':')[0]);
    d.setUTCMinutes(+time.split(':')[1]);
    const m = d.getMinutes();
    const minutes = m < 10 ? '0' + m : m;
    return d.getHours() + ':' + minutes;
  }

  getTeamsById(localTeamId: number, visitorTeamId: number): Observable<[IFootballTeam, IFootballTeam]> {
    return forkJoin([
      this.footballService.getTeamById(localTeamId),
      this.footballService.getTeamById(visitorTeamId),
    ]).pipe(
      map(res => {
        return [res[0].data, res[1].data];
      }),
    );
  }
}
