import { BrowserRef } from './browser.ref';
import * as i0 from "@angular/core";
import * as i1 from "./browser.ref";
var DeviceDetectionService = /** @class */ (function () {
    function DeviceDetectionService(browserRef) {
        this.browserRef = browserRef;
    }
    Object.defineProperty(DeviceDetectionService.prototype, "isTablet", {
        get: function () {
            return this.browserRef.window.matchMedia('(max-width: 768px)').matches;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceDetectionService.prototype, "isTabletOnly", {
        get: function () {
            return this.browserRef.window.matchMedia('(max-width: 768px) and (min-width: 640px)').matches;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceDetectionService.prototype, "isMobile", {
        get: function () {
            return this.browserRef.window.matchMedia('(max-width: 640px)').matches;
        },
        enumerable: true,
        configurable: true
    });
    DeviceDetectionService.prototype.maxWidth = function (px) {
        return this.browserRef.window.matchMedia("(max-width: " + px + ")").matches;
    };
    Object.defineProperty(DeviceDetectionService.prototype, "isDesktop", {
        get: function () {
            return this.browserRef.window.matchMedia('(min-width: 1025px)').matches;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceDetectionService.prototype, "isTouch", {
        get: function () {
            return 'ontouchstart' in this.browserRef.window // works on most browsers
                // || window.DocumentTouch && document instanceof DocumentTouch seems phased out
                || this.browserRef.navigator.maxTouchPoints > 0; // works on IE10 with some false positives
        },
        enumerable: true,
        configurable: true
    });
    DeviceDetectionService.prototype.shouldRender = function (mobileOnly, tabletDesktopOnly, desktopOnly) {
        if (mobileOnly) {
            return this.isTablet;
        }
        if (tabletDesktopOnly || desktopOnly) {
            return !this.isTablet;
        }
        return true;
    };
    DeviceDetectionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeviceDetectionService_Factory() { return new DeviceDetectionService(i0.ɵɵinject(i1.BrowserRef)); }, token: DeviceDetectionService, providedIn: "root" });
    return DeviceDetectionService;
}());
export { DeviceDetectionService };
