import {ImageModel} from '../image';
import {IMovieSerie} from '../../movieSerie/payload';
import {ImageType} from '../../enum/image';
import {PortalRoute} from '../../teaser/portalRoute';
import {GTMTriggerId} from '../../enum/gtm';

export class PosterSliderItemModel {
  image: ImageModel;
  title: string;
  link: PortalRoute;
  gtmId: GTMTriggerId;

  static fromTeasers(items: IMovieSerie[]): PosterSliderItemModel[] {
    return items.map(t => {
      const m = new PosterSliderItemModel();
      if (t.poster) {
        m.image = new ImageModel(t.poster, ImageType.POSTER);
      }
      m.title = t.title;
      m.link = PortalRoute.fromTeaser(t);
      m.gtmId = GTMTriggerId.posterSlider;
      return m;
    });

  }
}
