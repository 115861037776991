import {FilterType} from '../enum/filter';

export class Filter<U, T> {
  constructor(public type: FilterType,
              public id: string,
              public label: string,
              public values: { name: string, value: U }[],
              public filter: (val: T, filter: Filter<U, T>) => boolean,
              public valueSelected?: any) {
  }

  get isUnset() {
    return this.valueSelected === undefined || !this.selectedValue;
  }

  get selectedValue(): U {
    if (typeof this.valueSelected === 'number') {
      return this.values[this.valueSelected].value;
    }
    return this.valueSelected;
  }
}
