import {Component} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {ITikTok, ITikTokResponse} from '@model/content/tikTok/payload';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {LoadExternalScriptService} from '@shared/service/loadExternalScript.service';
import {ScriptId} from '@model/enum/scriptId';
import {DidomiService} from '@shared/service/didomi.service';
import {VendorTypes} from '@model/enum/vendorTypes';

@Component({
  selector: 'app-tik-tok',
  templateUrl: './tik-tok.component.html',
})
export class TikTokComponent implements DynamicComponent<ITikTok> {

  embed$: Observable<string>;
  vendorType = VendorTypes.tikTok;

  constructor(
    private http: HttpClient,
    private loadScript: LoadExternalScriptService,
    private didomiService: DidomiService,
  ) {
  }

  initModel(model: ITikTok): void {
    this.embed$ = this.didomiService.checkVendor$(this.vendorType)
      .pipe(
        filter(Boolean),
        switchMap(() => {
          return this.http.get<ITikTokResponse>('https://www.tiktok.com/oembed?url=' + model.data.uri)
            .pipe(
              map(res => res.html.split('<script')[0]),
              tap(res => {
                this.loadScript.loadScript(ScriptId.tiktok).subscribe();
              }),
              catchError(err => of(' ')),
            );
        })
      );
  }

}
