import {ComponentFactoryResolver, ComponentRef, Inject, Injectable, PLATFORM_ID, ViewContainerRef} from '@angular/core';
import {AdMediumRectangleComponent} from '../../component/ads/mediumRectangle/adMediumRectangle.component';
import {AuthorHeaderComponent} from '../../component/home/search/authorHeader/authorHeader.component';
import {SingleSliderComponent} from '../../component/home/channel/singleSlider/singleSlider.component';
import {MultiSliderComponent} from '../../component/home/channel/multiSlider/multiSlider.component';
import {DepartmentBlockComponent} from '../../component/home/channel/departmentBlock/departmentblock.component';
import {LongListComponent} from '../../component/home/common/longList/longlist.component';
import {OutbrainComponent} from '../../component/ads/outbrain/outbrain.component';
import {TopStoriesComponent} from '../../component/home/channel/topStories/topStories.component';
import {ResultArticlesComponent} from '../../component/home/search/resultArticles/resultArticles.component';
import {ResultTopicsComponent} from '../../component/home/search/resultTopics/resultTopics.component';
import {SearchHeaderComponent} from '../../component/home/search/resultHeader/resultHeader.component';
import {TopTopicsComponent} from '../../component/home/channel/topTopics/toptopics.component';
import {AdInContentComponent} from '../../component/ads/adInContent/adInContent.component';
import {AdArticlebottomComponent} from '../../component/ads/adArticlebottom/adArticlebottom.component';
import {AdFullbannerComponent} from '../../component/ads/adFullbanner/adFullbanner.component';
import {TopicHeaderComponent} from '../../component/home/search/topicHeader/topicHeader.component';
import {isPlatformServer} from '@angular/common';
import {MultiTeaserComponent} from '../../component/home/common/multiTeaser/multi-teaser.component';
import {AdMobileBannerComponent} from '../../component/ads/adMobileBanner/admobilebanner.component';
import {LinkTeaserComponent} from '../../component/home/channel/linkTeaser/linkTeaser.component';
import {SingleTeaserComponent} from '../../component/home/channel/singleTeaser/singleTeaser.component';
import {GallerySliderComponent} from '../../component/home/channel/gallerySlider/gallerySlider.component';
import {TabbedListComponent} from '../../component/home/channel/tabbedList/tabbedlist.component';
import {ErrorComponent} from '../../component/home/error/error.component';
import {VideoPlaylistComponent} from '../../component/home/channel/videoPlaylist/videoPlaylist.component';
import {AdResponsiveComponent} from '../../component/ads/adResponsive/adresponsive.component';
import {EmptyComponent} from '../../component/home/empty.component';
import {DynamicComponent} from '../../component/common/dynamicComponent';
import {DynamicType} from '../../model/enum/dynamic';
import {DeviceDetectionService} from './device-detection.service';
import {ContentGardenComponent} from '../../component/home/content/contentGarden/contentGarden.component';
import {IDynamicComponent} from '../../model/payload';
import {JobBoxComponent} from '../../component/home/channel/jobBox/jobBox.component';
import {ImmoBoxComponent} from '../../component/home/channel/immoBox/immoBox.component';
import {TitleBoxComponent} from '../../component/home/channel/titleBox/titleBox.component';
import {ApaVideoComponent} from '../../component/home/content/apaVideo/apavideo.component';
import {ArticleComponent} from '../../component/home/content/article/article.component';
import {ChannelRelatedsComponent} from '../../component/home/content/channelRelateds/channelRelateds.component';
import {SectionRelatedsComponent} from '../../component/home/content/sectionRelateds/sectionRelateds.component';
import {ChannelLinkComponent} from '../../component/home/content/channelLink/channelLink.component';
import {DividerComponent} from '../../component/home/content/divider/divider.component';
import {FacebookComponent} from '../../component/home/content/facebook/facebook.component';
import {FilmAtProgramComponent} from '../../component/home/content/filmatProgram/filmatprogram.component';
import {FilmAtReviewComponent} from '../../component/home/content/filmatReview/filmatreview.component';
import {InlineSliderComponent} from '../../component/home/content/inlineSlider/inlineSlider.component';
import {GoogleMapsComponent} from '../../component/home/content/googleMaps/googlemaps.component';
import {IframeContainerComponent} from '../../component/home/channel/iframeContainer/iframecontainer.component';
import {InlineImgComponent} from '../../component/home/content/inlineImg/inlineImg.component';
import {InstagramComponent} from '../../component/home/content/instagram/instagram.component';
import {LinkFieldComponent} from '../../component/home/content/linkField/linkfield.component';
import {LiveblogComponent} from '../../component/home/content/liveblog/liveblog.component';
import {PinPollComponent} from '../../component/home/common/pinPoll/pinpoll.component';
import {
  PinterestBoardOrProfilComponent
} from '../../component/home/content/pinterestProfileOrBoard/pinterestprofileorboard.component';
import {QuoteContainerComponent} from '../../component/home/content/quote/quoteContainer.component';
import {RiddleComponent} from '../../component/home/content/riddle/riddle.component';
import {SoundCloudComponent} from '../../component/home/content/soundcloud/soundcloud.component';
import {TeaserParagraphComponent} from '../../component/home/content/teaserParagraph/teaserParagraph.component';
import {TextParagraphComponent} from '../../component/home/content/textParagraph/textParagraph.component';
import {VimeoComponent} from '../../component/home/content/vimeo/vimeo.component';
import {YouTubeComponent} from '../../component/home/content/youtube/youtube.component';
import {ContentGardenTeaserComponent} from '../../component/home/channel/contentGarden/contentGardenTeaser.component';
import {MovieSerieDetailComponent} from '@component/home/content/movieSerieDetail/movieSerieDetail.component';
import {MovieListComponent} from '../../component/home/channel/movieList/movieList.component';
import {ScreeningsComponent} from '../../component/home/channel/screenings/screenings.component';
import {TheaterDetailComponent} from '../../component/home/content/theaterDetail/theaterDetail.component';
import {PosterSliderComponent} from '../../component/home/common/posterSlider/posterSlider.component';
import {RomyVotingComponent} from '../../component/home/channel/romyVoting/romyVoting.component';
import {InfoBoxComponent} from '../../component/home/content/infoBox/infoBox.component';
import {
  BeforeAfterSliderComponent
} from '../../component/home/content/before-after-slider/before-after-slider.component';
import {TextBoxComponent} from '../../component/home/channel/text-box/text-box.component';
import {NewsletterSignUpComponent} from '../../component/home/content/newsletter-sign-up/newsletter-sign-up.component';
import {CleverpushButtonComponent} from '@component/home/content/cleverpushButton/cleverpush-button.component';
import {Degrees23Component} from '@component/home/content/degrees23/degrees23.component';
import {RedditComponent} from '@component/home/content/reddit/reddit.component';
import {TwitterComponent} from '@component/home/content/twitter/twitter.component';
import {PodigeeComponent} from '@component/home/content/podigee/podigee.component';
import {TikTokComponent} from '@component/home/content/tik-tok/tik-tok.component';
import {TwitterTimelineComponent} from '@component/home/content/twitter-timeline/twitter-timeline.component';
import {FlourishComponent} from '@component/home/content/flourish/flourish.component';
import {PaywallComponent} from '@component/home/content/paywall/paywall.component';
import {
  FootballTableLeagueComponent
} from '@component/home/content/football-table-league/football-table-league.component';
import {FootballTableCupComponent} from '@component/home/content/football-table-cup/football-table-cup.component';
import {
  FootballLiveScoreWrapperComponent
} from '@component/home/content/football-live-score-wrapper/football-live-score-wrapper.component';
import {
  FootballStagesWrapperComponent
} from '@component/home/content/football-stages-wrapper/football-stages-wrapper.component';
import {PersonMovieListComponent} from '@component/home/content/person-movie-list/person-movie-list.component';
import {TypedEmbedComponent} from '@component/home/content/typed-embed/typed-embed.component';
import {AdPianoComponent} from '@component/home/content/ad-piano/ad-piano.component';
import {TextLinkComponent} from '@component/home/channel/text-link/text-link.component';

@Injectable({
  providedIn: 'root',
})
export class DynamicComponentsService {

  private typeMap = {
    [DynamicType.adArticlebottom]: AdArticlebottomComponent,
    [DynamicType.adFullbanner]: AdFullbannerComponent,
    [DynamicType.adInContent]: AdInContentComponent,
    [DynamicType.adMediumRectangle]: AdMediumRectangleComponent,
    [DynamicType.adMediumRectangleSticky]: AdMediumRectangleComponent,
    [DynamicType.adMobileBanner]: AdMobileBannerComponent,
    [DynamicType.adResponsive]: AdResponsiveComponent,
    [DynamicType.fakeAdArticlebottom]: AdArticlebottomComponent,
    [DynamicType.fakeAdFullbanner]: AdFullbannerComponent,
    [DynamicType.fakeAdInContent]: AdInContentComponent,
    [DynamicType.fakeAdMediumRectangle]: AdMediumRectangleComponent,
    [DynamicType.fakeAdMediumRectangleSticky]: AdMediumRectangleComponent,
    [DynamicType.fakeAdMobileBanner]: AdMobileBannerComponent,
    [DynamicType.fakeAdResponsive]: AdResponsiveComponent,
    [DynamicType.apa_video]: ApaVideoComponent,
    [DynamicType.article]: ArticleComponent,
    [DynamicType.articleChannelRelateds]: ChannelRelatedsComponent,
    [DynamicType.articleSectionRelateds]: SectionRelatedsComponent,
    [DynamicType.authorHeader]: AuthorHeaderComponent,
    [DynamicType.contentGardenArticle]: ContentGardenComponent,
    [DynamicType.contentGardenTeaser]: ContentGardenTeaserComponent,
    [DynamicType.doubleTeaser]: MultiTeaserComponent,
    [DynamicType.dividerLink]: ChannelLinkComponent,
    [DynamicType.departmentblock]: DepartmentBlockComponent,
    [DynamicType.divider]: DividerComponent,
    [DynamicType.error]: ErrorComponent,
    [DynamicType.facebook_post]: FacebookComponent,
    [DynamicType.facebook_video]: FacebookComponent,
    [DynamicType.facebook_page]: FacebookComponent,
    [DynamicType.filmatProgram]: FilmAtProgramComponent,
    [DynamicType.filmatReview]: FilmAtReviewComponent,
    [DynamicType.gallery]: InlineSliderComponent,
    [DynamicType.gallerySlider]: GallerySliderComponent,
    [DynamicType.google_map]: GoogleMapsComponent,
    [DynamicType.iframe]: IframeContainerComponent,
    [DynamicType.image]: InlineImgComponent,
    [DynamicType.immoSearch]: ImmoBoxComponent,
    [DynamicType.instagram]: InstagramComponent,
    [DynamicType.jobSearch]: JobBoxComponent,
    [DynamicType.link]: LinkFieldComponent,
    [DynamicType.linkTeaser]: LinkTeaserComponent,
    [DynamicType.link_teaser]: LinkTeaserComponent,
    [DynamicType.liveblog]: LiveblogComponent,
    [DynamicType.longList]: LongListComponent,
    [DynamicType.movieDetail]: MovieSerieDetailComponent,
    [DynamicType.movieList]: MovieListComponent,
    [DynamicType.multiSlider]: MultiSliderComponent,
    [DynamicType.outbrain]: OutbrainComponent,
    [DynamicType.pinpoll]: PinPollComponent,
    [DynamicType.pinterest_board]: PinterestBoardOrProfilComponent,
    [DynamicType.pinterest_profile]: PinterestBoardOrProfilComponent,
    [DynamicType.posterSlider]: PosterSliderComponent,
    [DynamicType.quote]: QuoteContainerComponent,
    [DynamicType.riddle]: RiddleComponent,
    [DynamicType.screeningsBlock]: ScreeningsComponent,
    [DynamicType.scrollList]: TabbedListComponent,
    [DynamicType.searchHeader]: SearchHeaderComponent,
    [DynamicType.searchResults]: ResultArticlesComponent,
    [DynamicType.searchResultTopics]: ResultTopicsComponent,
    [DynamicType.singleSlider]: SingleSliderComponent,
    [DynamicType.singleTeaser]: SingleTeaserComponent,
    [DynamicType.soundcloud]: SoundCloudComponent,
    [DynamicType.story]: ArticleComponent,
    [DynamicType.teaser]: TeaserParagraphComponent,
    [DynamicType.text]: TextParagraphComponent,
    [DynamicType.titleBox]: TitleBoxComponent,
    [DynamicType.theaterDetail]: TheaterDetailComponent,
    [DynamicType.topicPageHeader]: TopicHeaderComponent,
    [DynamicType.topStories]: TopStoriesComponent,
    [DynamicType.toptopics]: TopTopicsComponent,
    [DynamicType.tripleArticleBox]: MultiTeaserComponent,
    [DynamicType.twitter]: TwitterComponent,
    [DynamicType.twitterTimeline]: TwitterTimelineComponent,
    [DynamicType.videoPlaylist]: VideoPlaylistComponent,
    [DynamicType.vimeo]: VimeoComponent,
    [DynamicType.youtube]: YouTubeComponent,
    [DynamicType.romyVoting]: RomyVotingComponent,
    [DynamicType.beforeAfterSlider]: BeforeAfterSliderComponent,
    [DynamicType.infobox]: InfoBoxComponent,
    [DynamicType.textBox]: TextBoxComponent,
    [DynamicType.newsletterSignUp]: NewsletterSignUpComponent,
    [DynamicType.cleverpushbutton]: CleverpushButtonComponent,
    [DynamicType.degrees23]: Degrees23Component,
    [DynamicType.seriesDetail]: MovieSerieDetailComponent,
    [DynamicType.reddit]: RedditComponent,
    [DynamicType.podigee]: PodigeeComponent,
    [DynamicType.tikTok]: TikTokComponent,
    [DynamicType.flourish]: FlourishComponent,
    [DynamicType.footballTable]: FootballTableLeagueComponent,
    [DynamicType.paywall]: PaywallComponent,
    [DynamicType.footballLiveStanding]: FootballLiveScoreWrapperComponent,
    [DynamicType.footballStages]: FootballStagesWrapperComponent,
    [DynamicType.footballCupTable]: FootballTableCupComponent,
    [DynamicType.personMovieList]: PersonMovieListComponent,
    [DynamicType.typedEmbed]: TypedEmbedComponent,
    [DynamicType.adPiano]: AdPianoComponent,
    [DynamicType.textLink]: TextLinkComponent,
    [DynamicType.teaserSlider]: MultiSliderComponent,
    [DynamicType.singleSlider2]: SingleSliderComponent,
    [DynamicType.gallerySlider2]: GallerySliderComponent,
    [DynamicType.linkTeaser2]: LinkTeaserComponent,
    [DynamicType.title]: TitleBoxComponent,
  };

  private browserOnlyTypes: string[] = [
    DynamicType.apa_video,
    DynamicType.contentGardenArticle,
    DynamicType.contentGardenTeaser,
    DynamicType.facebook_post,
    DynamicType.facebook_video,
    DynamicType.google_map,
    DynamicType.iframe,
    DynamicType.immoSearch,
    DynamicType.outbrain,
    DynamicType.pinpoll,
    DynamicType.pinterest_board,
    DynamicType.pinterest_pin,
    DynamicType.pinterest_profile,
    DynamicType.riddle,
    DynamicType.romyVoting,
    DynamicType.soundcloud,
    DynamicType.storify,
    DynamicType.vimeo,
    DynamicType.youtube,
    DynamicType.reddit,
    DynamicType.podigee,
    DynamicType.tikTok,
    DynamicType.twitter,
    DynamicType.flourish,
    DynamicType.footballLiveStanding,
    DynamicType.footballStages,
    DynamicType.footballCupTable,
    DynamicType.typedEmbed,
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private mobileDetection: DeviceDetectionService,
  ) {
  }

  create<C extends DynamicComponent<U>, U extends IDynamicComponent>(resolver: ComponentFactoryResolver,
                                                                     vcRef: ViewContainerRef,
                                                                     model: U): ComponentRef<C> {

    if (this.mobileDetection.shouldRender(model.mobileOnly, model.tabletDesktopOnly, model.desktopOnly)) {
      const component = this.resolveType(model.type);
      const factory = resolver.resolveComponentFactory<C>(component);
      const compRef: ComponentRef<C> = vcRef.createComponent(factory);
      const instance: C = compRef.instance;
      if (compRef && typeof instance.initModel === 'function') {
        instance.initModel(model);
      } else {
        console.log('no initModel() on type ' + model.type);
      }
      return compRef;
    }
  }

  private resolveType(type: string) {
    if (isPlatformServer(this.platformId)) {
      if (this.isBrowserOnlyType(type)) {
        return EmptyComponent;
      }
    }
    const mappedType = this.typeMap[type];
    if (!mappedType) {
      console.warn('No mapped type for ' + type);
      return EmptyComponent;
    }
    return mappedType;
  }

  private isBrowserOnlyType(t: string) {
    return this.browserOnlyTypes.indexOf(t) > -1;
  }
}
