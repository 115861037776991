import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import {StateService} from '@shared/service/state.service';
import {InjectScriptsService, IScriptDefinition} from '@shared/service/injectScripts.service';
import {MenuService} from '@shared/service/menu.service';
import {ImageService} from '@shared/service/image.service';
import {ImageSrc} from '@model/enum/image';
import {PortalId} from '@model/enum/portal';
import {IArticle} from '@model/content/article/payload';
import {IMovieDetail} from '@model/content/movieSerieDetail/payload';
import {IRoute} from '@model/payload';
import {ROUTE_INFO} from '@shared/service/tokens';

@Injectable({
  providedIn: 'root',
})
export class LdJsonService {

  private readonly LOGO_DIM_MAP = {
    [PortalId.kurierat]: 158,
    [PortalId.motorat]: 196,
    [PortalId.kuriertv]: 241,
    [PortalId.filmat]: 191,
  };

  constructor(private menuService: MenuService,
              private injectScripts: InjectScriptsService,
              private imageService: ImageService,
              private stateService: StateService<IRoute>,
  ) {
  }

  protected static personMap(persons) {
    return persons.map(person => {
      return {
        '@type': 'Person',
        name: person.name
      };
    });
  }

  injectArticleTags(article: IArticle) {
    if (this.stateService.isFirstApp) {
      const routeInfo: IRoute = this.stateService.get(ROUTE_INFO, null);
      this.menuService.get('social').pipe(take(1)).subscribe(socialMenu => {

        const url = 'https://' + (article.portal === 'lustaufoesterreich.at' ? 'lust-auf-oesterreich.at' : article.portal);
        const authors = article.authors && article.authors.length > 0 ? article.authors.map(a => {
          return {
            '@type': 'Person',
            name: a.firstname + ' ' + a.lastname,
            url: article.portal + '/author/' + a.username,
          };
        }) : {
          '@type': 'Organization',
          name: article.portal,
        };

        const timeZoneOffset = new Date(article.published_date).getTimezoneOffset();
        const hours = Math.floor(timeZoneOffset / 60);
        const minutes = timeZoneOffset % 60;
        const timeZone = '+' + (hours > 9 ? hours : '0' + (hours * -1)) + ':' + (minutes > 9 ? minutes : '0' + minutes);

        const date = (d) => {
          return new Date(d).toISOString().split('.')[0];
        };
        const tags: any = {
          '@context': 'http://schema.org',
          '@type': 'NewsArticle',
          headline: article.title,
          mainEntityOfPage: url + article.url,
          datePublished: date(article.published_date) + timeZone,
          dateModified: date(article.updated_date) + timeZone,
          description: article.teaser_text,
          author: authors,
          keywords: routeInfo && routeInfo.dataLayer.Artikeltags ? routeInfo.dataLayer.Artikeltags : '',
          publisher: {
            '@type': 'Organization',
            name: article.portal,
            url,
            sameAs: socialMenu.map(m => {
              return m.link;
            }),
            logo: {
              '@type': 'ImageObject',
              url: url + '/assets/' + article.portal.replace('.', '') + '/logos/logo.png',
              width: article.portal === 'lustaufoesterreich.at' ? 165 : this.LOGO_DIM_MAP[article.portal],
              height: 40
            }
          },
        };

        if (article.teaser_img) {
          const imgUrl16to9 = this.imageService.url(article.teaser_img.url, ImageSrc.LS_1232);
          const imgUrlSQ = this.imageService.url(article.teaser_img.url, ImageSrc.SQ_1232);
          tags.image = [
            imgUrl16to9,
            imgUrlSQ,
          ];
        }
        tags.isAccessibleForFree = true;
        if (article.paid_content) {
          tags.isAccessibleForFree = false;
          tags.hasPart = {
            '@type': 'WebPageElement',
            isAccessibleForFree: false,
            cssSelector: '.plusContent',
          };
        }

        const scriptDef: IScriptDefinition = {
          attrs: {
            type: 'application/ld+json'
          },
          innerHTML: JSON.stringify(tags),
        };
        this.injectScripts.injectToHeadOnce(scriptDef);
      });
    }
  }

  injectFilmTags(film: IMovieDetail) {
    const portal = 'https://film.at';
    if (this.stateService.isFirstApp) {

      this.menuService.get('social').pipe(take(1)).subscribe(socialMenu => {

        const url = portal;

        const directors = film.directors ? LdJsonService.personMap(film.directors) : '';
        const music = film.music ? LdJsonService.personMap(film.music) : '';
        const genre = film.genres.map((g) => {
          return g.name;
        });

        const tags = {
          '@context': 'http://schema.org',
          '@type': 'Movie',
          headline: film.title,
          duration: 'PT' + film.playtime + 'M',
          mainEntityOfPage: this.imageService.url(film.poster.url, ImageSrc.V_1864),
          description: film.teaser_text,
          directors,
          musicBy: music,
          genre,
          dateCreated: film.release_date,
          publisher: {
            '@type': 'Organization',
            name: portal,
            url,
            sameAs: socialMenu.map(m => {
              return m.link;
            }),
            logo: {
              '@type': 'ImageObject',
              url: url + '/assets/filmat/logos/logo.png',
              width: this.LOGO_DIM_MAP[PortalId.filmat],
              height: 40
            }
          },
        };

        if (film.poster.url) {
          const imgUrl16to9 = this.imageService.url(film.poster.url, ImageSrc.LS_1232);
          const imgUrlSQ = this.imageService.url(film.poster.url, ImageSrc.SQ_1232);
          // tslint:disable-next-line:no-string-literal
          tags['image'] = [
            imgUrl16to9,
            imgUrlSQ,
          ];
        }

        const scriptDef: IScriptDefinition = {
          attrs: {
            type: 'application/ld+json'
          },
          innerHTML: JSON.stringify(tags),
        };
        this.injectScripts.injectToHeadOnce(scriptDef);
      });
    }
  }

  injectTvTags(jsonLd: any) {
    if (this.stateService.isFirstApp) {
      const scriptDef: IScriptDefinition = {
        attrs: {
          type: 'application/ld+json'
        },
        innerHTML: JSON.stringify(jsonLd),
      };
      this.injectScripts.injectToHeadOnce(scriptDef);
    }
  }

}
