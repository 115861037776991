<div class="beforeAfterSlider">
  <div
      #slideContainer
      class="beforeAfterSlider-inner">
    <div class="spacer" [ngStyle]="{'padding-bottom': model.aspectRatio + '%'}">
    </div>
    <picimage [model]="model.img1"></picimage>
    <div
      [ngStyle]="{'left': pos + '%'}"
      class="beforeAfterSlider-overlay">
      <picimage (loaded)="imgLoaded($event)" [model]="model.img2"></picimage>
    </div>
    <div
        #dragger
        [ngStyle]="{'left': pos + '%'}"
        class="dragger">
      <iconbutton [icon]="'angle-left'"></iconbutton>
      <iconbutton [icon]="'angle-right'"></iconbutton>
    </div>
  </div>
  <accordeon [title]="'info'">
    <div
      class="caption"
      [innerHTML]="model.caption"></div>
  </accordeon>
</div>
<h2 class="beforeAfterSlider-title" *ngIf="title" [innerHTML]="title"></h2>
<div class="beforeAfterSlider-content" *ngIf="html" [innerHTML]="html"></div>

