/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i2 from "../../../common/ui/linkBar/linkBar.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../common/ui/tabBar/tabBar.component.ngfactory";
import * as i5 from "../../../common/ui/tabBar/tabBar.component";
import * as i6 from "../../../common/searchInput/searchInput.component.ngfactory";
import * as i7 from "../../../common/searchInput/searchInput.component";
import * as i8 from "./resultHeader.component";
import * as i9 from "../../../../shared/service/portal.service";
import * as i10 from "../../../../shared/service/channelSearch.service";
import * as i11 from "../../../../shared/service/title.service";
var styles_SearchHeaderComponent = [];
var RenderType_SearchHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SearchHeaderComponent, data: {} });
export { RenderType_SearchHeaderComponent as RenderType_SearchHeaderComponent };
function View_SearchHeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "linkbar", [], null, null, null, i1.View_LinkBarComponent_0, i1.RenderType_LinkBarComponent)), i0.ɵdid(2, 49152, null, 0, i2.LinkBarComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SearchHeaderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "searchHeader"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SearchHeaderComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "searchHeader-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "tabbar", [["class", "searchHeader-tabBar"]], null, [[null, "tabChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tabChanged" === en)) {
        var pd_0 = (_co.switchTab($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_TabBarComponent_0, i4.RenderType_TabBarComponent)), i0.ɵdid(6, 114688, null, 0, i5.TabBarComponent, [], { model: [0, "model"] }, { tabChanged: "tabChanged" }), (_l()(), i0.ɵeld(7, 0, null, null, 1, "searchinput", [["class", "searchHeader-searchField"], ["placeholder", "Suchbegriff\u2026"]], null, [[null, "onSearch"], ["document", "click"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).clickedOutside() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i0.ɵnov(_v, 8).clickedInside($event) !== false);
        ad = (pd_1 && ad);
    } if (("onSearch" === en)) {
        var pd_2 = (_co.onSearch($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i6.View_SearchInputComponent_0, i6.RenderType_SearchInputComponent)), i0.ɵdid(8, 114688, null, 0, i7.SearchInputComponent, [i0.Renderer2], { placeholder: [0, "placeholder"], elmClass: [1, "elmClass"], searchInput: [2, "searchInput"] }, { onSearch: "onSearch" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.linkBarModel$)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.tabModels; _ck(_v, 6, 0, currVal_1); var currVal_2 = "Suchbegriff\u2026"; var currVal_3 = "search-result-item"; var currVal_4 = _co.term; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4); }, null); }
export function View_SearchHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "searchheader", [], null, null, null, View_SearchHeaderComponent_0, RenderType_SearchHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i8.SearchHeaderComponent, [i9.PortalService, i10.ChannelSearchService, i11.TitleService, i3.Location], null, null)], null, null); }
var SearchHeaderComponentNgFactory = i0.ɵccf("searchheader", i8.SearchHeaderComponent, View_SearchHeaderComponent_Host_0, {}, {}, []);
export { SearchHeaderComponentNgFactory as SearchHeaderComponentNgFactory };
