import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {VideoModel} from '../../../../model/component/video';
import {IVimeo} from '../../../../model/content/vimeo/payload';
import {DidomiService} from '@shared/service/didomi.service';
import {VendorTypes} from '@model/enum/vendorTypes';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'vimeo',
  templateUrl: 'vimeo.component.html'
})
export class VimeoComponent implements DynamicComponent<IVimeo> {

  videoModel: VideoModel;
  vendorType = VendorTypes.vimeo;
  showVimeo$: Observable<boolean>;

  constructor(
    private didomiService: DidomiService,
  ) {
  }

  initModel(model: IVimeo): void {
    this.showVimeo$ = this.didomiService.checkVendor$(this.vendorType)
      .pipe(
        tap(consent => {
          if (consent) {
            this.videoModel = new VideoModel('https://player.vimeo.com/video/' + model.data.videoid, model.data.autoplay);
          }
        })
      );
  }
}
