<ng-container *ngIf="(data$ | async) as data">
  <slider [sliderConfigModel]="sliderConfigModel" [ngClass]="boxModel.cssClass">
    <div
      #sliderItem
      class="slider-item"
      *ngFor="let card of data">
      <a [gtm]="card.gtmId" [portalRouterLink]="card.link">
        <picimage *ngIf="card.image" [model]="card.image"></picimage>
        <span *ngIf="!card.image" class="image"></span>
        <h2 title="{{card.title}}" class="slider-title">{{card.title}}</h2>
      </a>
    </div>
  </slider>
</ng-container>
