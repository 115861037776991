import {Inject, Injectable} from '@angular/core';
import {CURRENT_ROUTE_URI} from './tokens';
import {StateProvider, StateService} from './state.service';
import {PortalRoute} from '../../model/teaser/portalRoute';

export interface IPortalService {
  hostname(): string;

  hostnameOf(targetRoute: PortalRoute): string;

  currentPortal(): string;

  currentRoute(): PortalRoute;
}

@Injectable({
  providedIn: 'root',
})
export class PortalService implements IPortalService {

  protected static HOSTS = new Map<string, string>()
    .set('kat', 'k.at')
    .set('kurierat', 'kurier.at')
    .set('filmat', 'film.at')
    .set('futurezoneat', 'futurezone.at')
    .set('freizeitat', 'freizeit.at')
    .set('motorat', 'motor.at')
    .set('eventsat', 'events.at')
    .set('profilat', 'profil.at')
    .set('lustaufoesterreichat', 'lust-auf-oesterreich.at')
    .set('kuriertv', 'kurier.tv');
  private readonly currentPortalRoute: PortalRoute;

  constructor(@Inject(StateService) private stateService: StateProvider<string>) {
    const uri = stateService.get(CURRENT_ROUTE_URI);
    this.currentPortalRoute = PortalRoute.fromUri(uri);
    if (uri.startsWith('/lust-auf-oesterreichat')) {
      this.currentPortalRoute = PortalRoute.fromUri('/lustaufoesterreichat');
    }
  }

  hostname(): string {
    return this.hostnameOf(this.currentPortalRoute);
  }

  hostnameOf(targetRoute: PortalRoute): string {
    return PortalService.HOSTS.get(targetRoute.portal) || targetRoute.portal;
  }

  currentPortal(): string {
    return this.currentPortalRoute.portal;
  }

  currentRoute(): PortalRoute {
    return this.currentPortalRoute;
  }

  samePortal(target: PortalRoute): boolean {
    return this.currentPortalRoute.portal === target.portal;
  }

  subdomain(sub: string): string {
    if (sub !== 'ng') {
      sub += '-varnish';
    }
    return sub + '.' + this.hostnameOf(this.currentPortalRoute);
  }

  //noinspection JSMethodCanBeStatic
  canonical(targetRoute: PortalRoute) {
    const targetHostname = this.hostnameOf(targetRoute);
    return targetRoute.protocol + '://' + targetHostname + targetRoute.path;
  }

  absoluteUrlOf(target: PortalRoute): string {
    const hostname = this.hostnameOf(target);
    let path = target.path;
    if (target.hash) {
      path += target.hash;
    }
    if (target.protocol === 'mailto') {
      return target.protocol + ':' + hostname + path;
    }
    return target.protocol + '://' + hostname + path;
  }

  getAmpUrlOf(target: PortalRoute, appPrefix): string {
    if (this.samePortal(target)) {
      return '/' + appPrefix + target.path;
    }
    const hostname = this.hostnameOf(target);
    return this.urlOf(target.protocol + '://' + hostname + '/' + appPrefix + target.path);
  }

  urlOf(targetUri: string): string {
    const target = PortalRoute.fromUri(targetUri);
    return this.urlOfRoute(target);
  }

  urlOfRoute(target: PortalRoute): string {
    if (this.samePortal(target)) {
      return target.path;
    }
    return this.absoluteUrlOf(target);
  }

  uri(path: string): string {
    const uriPath = path === '/' ? '' : path;
    return '/' + this.currentPortalRoute.portal + uriPath;
  }
}
