/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../image/image.component.ngfactory";
import * as i2 from "../../image/image.component";
import * as i3 from "../../../../shared/service/image.service";
import * as i4 from "../../../../shared/service/overlay.service";
import * as i5 from "../../ui/tag/tag.component.ngfactory";
import * as i6 from "../../ui/tag/tag.component";
import * as i7 from "@angular/common";
import * as i8 from "../../../../shared/directive/portalRoute.directive";
import * as i9 from "@angular/router";
import * as i10 from "../../../../shared/service/portal.service";
import * as i11 from "../../../../shared/service/browser.ref";
import * as i12 from "./hero.component";
var styles_HeroComponent = [];
var RenderType_HeroComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeroComponent, data: {} });
export { RenderType_HeroComponent as RenderType_HeroComponent };
function View_HeroComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "picimage", [], null, null, null, i1.View_ImageComponent_0, i1.RenderType_ImageComponent)), i0.ɵdid(1, 638976, null, 0, i2.ImageComponent, [i3.ImageService, i4.OverlayService, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.image; _ck(_v, 1, 0, currVal_0); }, null); }
function View_HeroComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tag", [], null, null, null, i5.View_TagComponent_0, i5.RenderType_TagComponent)), i0.ɵdid(1, 49152, null, 0, i6.TagComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_HeroComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "p", [["class", "teaser-tagLine"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_3)), i0.ɵdid(2, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.tags; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HeroComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_HeroComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "hero"]], null, null, null, null, null)), i0.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(3, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_1)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["class", "heroCont"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_2)), i0.ɵdid(8, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 3, "a", [["class", "teaser-title"]], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 540672, null, 0, i8.PortalRouteDirective, [i9.Router, i0.ElementRef, i10.PortalService, i11.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_4)), i0.ɵdid(12, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "hero"; var currVal_1 = _ck(_v, 3, 0, _co.model.classes); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.model.image; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.model.tags; _ck(_v, 8, 0, currVal_3); var currVal_6 = _co.model.link; _ck(_v, 10, 0, currVal_6); var currVal_7 = _co.model.title; _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var currVal_4 = i0.ɵnov(_v, 10).target; var currVal_5 = i0.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_4, currVal_5); }); }
export function View_HeroComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-hero", [], null, null, null, View_HeroComponent_0, RenderType_HeroComponent)), i0.ɵdid(1, 49152, null, 0, i12.HeroComponent, [], null, null)], null, null); }
var HeroComponentNgFactory = i0.ɵccf("app-hero", i12.HeroComponent, View_HeroComponent_Host_0, {}, {}, []);
export { HeroComponentNgFactory as HeroComponentNgFactory };
