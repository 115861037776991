/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./football-stages.component";
var styles_FootballStagesComponent = [];
var RenderType_FootballStagesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FootballStagesComponent, data: {} });
export { RenderType_FootballStagesComponent as RenderType_FootballStagesComponent };
function View_FootballStagesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "footballStages-day-game"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "footballStages-day-game-team local"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["class", "team-logo"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [["class", "team-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "footballStages-day-game-score"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "p", [["class", "score"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "footballStages-day-game-team visitor"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "img", [["class", "team-logo"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "p", [["class", "team-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.team1.logo; var currVal_1 = (_v.context.$implicit.team1.name + " Logo"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.team1.name; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.results; _ck(_v, 7, 0, currVal_3); var currVal_4 = _v.context.$implicit.team2.logo; var currVal_5 = (_v.context.$implicit.team2.name + " Logo"); _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_6 = _v.context.$implicit.team2.name; _ck(_v, 11, 0, currVal_6); }); }
function View_FootballStagesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "section", [["class", "footballStages-day"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "header", [["class", "footballStages-day-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h2", [["class", "footballStages-day-header-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FootballStagesComponent_2)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.games; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.heading; _ck(_v, 4, 0, currVal_0); }); }
export function View_FootballStagesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "footballStages"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FootballStagesComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FootballStagesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-football-stages", [], null, null, null, View_FootballStagesComponent_0, RenderType_FootballStagesComponent)), i0.ɵdid(1, 49152, null, 0, i2.FootballStagesComponent, [], null, null)], null, null); }
var FootballStagesComponentNgFactory = i0.ɵccf("app-football-stages", i2.FootballStagesComponent, View_FootballStagesComponent_Host_0, { model: "model" }, {}, []);
export { FootballStagesComponentNgFactory as FootballStagesComponentNgFactory };
