<div class="searchHeader">
  <ng-container *ngIf="linkBarModel$ | async as linkBarModel">
    <linkbar [model]="linkBarModel"></linkbar>
  </ng-container>
  <div class="searchHeader-main">
    <tabbar
      class="searchHeader-tabBar"
      [model]="tabModels"
      (tabChanged)="switchTab($event)"></tabbar>
    <searchinput
      class="searchHeader-searchField"
      (onSearch)="onSearch($event)"
      placeholder="Suchbegriff…"
      [searchInput]="term"
      [elmClass]="'search-result-item'"></searchinput>
  </div>
</div>
