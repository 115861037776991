export function setToolTip(event: MouseEvent, msg: string, duration: number = 1000): void {
  const toolTip = document.createElement('p');
  toolTip.textContent = msg;
  toolTip.style.position = 'absolute';
  toolTip.classList.add('toolTip');
  toolTip.style.top = (event.pageY - 40) + 'px';
  toolTip.style.left = (event.pageX) + 'px';
  document.body.appendChild(toolTip);
  setTimeout(() => {
    toolTip.remove();
  }, duration);
}
