/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i3 from "../../../common/ui/linkBar/linkBar.component";
import * as i4 from "../../../common/teaser/teaser.directive";
import * as i5 from "../../common/longList/longlist.component.ngfactory";
import * as i6 from "../../common/longList/longlist.component";
import * as i7 from "../../../../shared/service/teasers.service";
import * as i8 from "../../../../shared/service/device-detection.service";
import * as i9 from "../../../../shared/service/portal.service";
import * as i10 from "./departmentblock.component";
var styles_DepartmentBlockComponent = [];
var RenderType_DepartmentBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DepartmentBlockComponent, data: {} });
export { RenderType_DepartmentBlockComponent as RenderType_DepartmentBlockComponent };
function View_DepartmentBlockComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "section", [["class", "departmentBlock"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "linkbar", [], null, null, null, i2.View_LinkBarComponent_0, i2.RenderType_LinkBarComponent)), i0.ɵdid(4, 49152, null, 0, i3.LinkBarComponent, [], { model: [0, "model"] }, null), (_l()(), i0.ɵeld(5, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(6, 540672, null, 0, i4.TeaserDirective, [i0.ViewContainerRef, i0.ComponentFactoryResolver], { model: [0, "model"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "longlist", [], null, null, null, i5.View_LongListComponent_0, i5.RenderType_LongListComponent)), i0.ɵdid(8, 573440, null, 0, i6.LongListComponent, [i7.TeasersService, i8.DeviceDetectionService, i9.PortalService], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "departmentBlock"; var currVal_1 = _co.cardModel.box.cssClass; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.linkbarModel; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.cardModel; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.longListModel; _ck(_v, 8, 0, currVal_4); }, null); }
export function View_DepartmentBlockComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_DepartmentBlockComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.teasers$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DepartmentBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "departmentblock", [], null, null, null, View_DepartmentBlockComponent_0, RenderType_DepartmentBlockComponent)), i0.ɵdid(1, 49152, null, 0, i10.DepartmentBlockComponent, [i7.TeasersService], null, null)], null, null); }
var DepartmentBlockComponentNgFactory = i0.ɵccf("departmentblock", i10.DepartmentBlockComponent, View_DepartmentBlockComponent_Host_0, {}, {}, []);
export { DepartmentBlockComponentNgFactory as DepartmentBlockComponentNgFactory };
