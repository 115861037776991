import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {CalendarConfigModel} from '../../../../model/component/calendarConfig';
import {animate, style, transition, trigger} from '@angular/animations';

interface CalendarDay {
  day: string | number;
  isSelected: boolean;
  isDisabled: boolean;
}

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger(
      'overlayAnimation', [
        transition(':enter', [
          style({transform: 'translateY(-36px)', opacity: 0}),
          animate('200ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('200ms', style({transform: 'translateY(-36px)', opacity: 0}))
        ])
      ]
    )
  ],
})
export class CalendarComponent implements OnInit {

  @Output() dateSelected = new EventEmitter<Date>();
  @Input() config: CalendarConfigModel;

  isOpen = false;
  weekdays = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
  months = ['Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
  monthDays: CalendarDay [][] = [];
  selectedDay = 0;
  selectedMonth = 0;
  displayedMonth = 0;
  selectedYear = 0;
  displayedYear = 0;
  buttonText = '';
  selectedActive = false;
  private currentDay: CalendarDay;
  private allDays: { [key: number]: CalendarDay } = {};

  constructor() {
  }

  reset() {
    this.setButtonText();
    this.selectedActive = false;
  }

  ngOnInit() {
    if (!this.config.startDate) {
      this.config.startDate = new Date(Date.now());
    }
    this.selectedDay = this.config.startDate.getDate();
    this.selectedYear = this.displayedYear = this.config.startDate.getFullYear();
    this.selectedMonth = this.config.startDate.getMonth();
    this.setButtonText();
    this.selectMonth(this.selectedMonth);
  }

  onToggleSelect() {
    this.isOpen = !this.isOpen;
  }

  setButtonText() {
    if (this.config.initialValue) {
      return this.buttonText = this.config.initialValue;
    } else {
      return this.buttonText = this.selectedDay + '.' + (this.selectedMonth + 1) + '.' + this.selectedYear;
    }
  }

  selectDate(date: Date) {
    if (date.getMonth() === this.displayedMonth && date.getFullYear() === this.displayedYear) {
      this.selectDay(this.allDays[date.getDate()], false);
    } else {
      this.selectedYear = this.displayedYear = date.getFullYear();
      this.selectedMonth = date.getMonth();
      this.selectMonth(this.selectedMonth);
    }
  }

  selectDay(day: CalendarDay, isSelectedActive = true) {
    if (day.day && !day.isDisabled) {
      this.selectedDay = day.day as number;
      this.selectedMonth = this.displayedMonth;
      this.selectedYear = this.displayedYear;
      this.isOpen = false;
      this.dateSelected.emit(new Date(this.selectedYear, this.selectedMonth, this.selectedDay));
      this.buttonText = this.selectedDay + '.' + (this.selectedMonth + 1) + '.' + this.selectedYear;
      this.selectedActive = isSelectedActive;
      this.currentDay.isSelected = false;
      this.currentDay = day;
      day.isSelected = true;
    }
  }

  selectMonth(month: number) {
    if (month < 0) {
      this.displayedYear -= 1;
      month = 11;
    } else if (month > 11) {
      this.displayedYear += 1;
      month = 0;
    }
    this.displayedMonth = month;

    const firstDay = new Date(this.displayedYear, this.displayedMonth, 1);
    const lastDay = new Date(this.displayedYear, this.displayedMonth + 1, 0);
    // NOTE: we do this because 0 === Sunday instead of Monday
    let firstDayOfTheWeek = firstDay.getDay();
    if (firstDayOfTheWeek === 0) {
      firstDayOfTheWeek = 7;
    }
    const lastDayNum = lastDay.getDate();
    this.monthDays = [];
    let isLastDayReached = false;
    let currentDay = 0;
    while (!isLastDayReached) {
      const row = [];
      this.monthDays.push(row);
      const j = this.monthDays.length;
      for (let i = 1; i < 8; i++) {
        if (j === 1) {
          if (i < firstDayOfTheWeek) {
            row.push({day: '', isDisabled: true, isSelected: false});
          } else {
            currentDay++;
            row.push(this.getDay(currentDay));
          }
        } else {
          if (currentDay === lastDayNum) {
            if (i === 7) {
              isLastDayReached = true;
            }
          } else {
            currentDay++;
            row.push(this.getDay(currentDay));
          }
        }
      }
    }
  }

  private getDay(currentDay: number) {
    const day = {day: currentDay, isDisabled: this.isDisabled(currentDay), isSelected: this.isSelected(currentDay)};
    if (day.isSelected) {
      this.currentDay = day;
    }
    this.allDays[currentDay] = day;
    return day;
  }

  private isSelected(day: number): boolean {
    if (this.displayedMonth !== this.selectedMonth || this.selectedYear !== this.displayedYear) {
      return false;
    }

    return this.selectedDay === day;
  }

  private isDisabled(day: number): boolean {
    if (!this.config.minDate) {
      return false;
    }

    const maxYear = this.config.maxDate.getFullYear();
    const minYear = this.config.minDate.getFullYear();

    if (this.displayedYear > maxYear || this.displayedYear < minYear) {
      return true;
    }

    const maxMonth = this.config.maxDate.getMonth();
    const minMonth = this.config.minDate.getMonth();
    if (maxYear !== minYear) {
      if (this.displayedYear === maxYear) {
        if (this.displayedMonth > maxMonth) {
          return true;
        }
        if (this.displayedMonth === maxMonth) {
          return day > this.config.maxDate.getDate();
        }
        return false;
      }

      if (this.displayedYear === minYear) {
        if (this.displayedMonth < minMonth) {
          return true;
        }
        if (this.displayedMonth === minMonth) {
          return day < this.config.minDate.getDate();
        }
        return false;
      }
      return false;
    }

    const diff = maxMonth - minMonth;
    if (diff === 0 && this.displayedMonth === maxMonth) {
      return day > this.config.maxDate.getDate() || day < this.config.minDate.getDate();
    }

    if (this.displayedMonth === maxMonth) {
      return day > this.config.maxDate.getDate();
    }

    if (this.displayedMonth === minMonth) {
      return day < this.config.minDate.getDate();
    }

    return true;
  }

  onClose() {
    this.isOpen = false;
  }

}
