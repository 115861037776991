<div
  [ngStyle]="{display: show ? 'flex' : 'none'}"
  class="adFreeOfferComponent">
  <div class="adFreeOfferComponent-inner">
    <button class="close" (click)="openConsentOverlay()">
      <iconbutton icon="times"></iconbutton>
    </button>
    <div [class.visible]="screenOffer" id="offer">
    </div>
    <div [class.visible]="!screenOffer" id="login">
    </div>
    <ng-container *ngIf="{user: piano.user$ | async} as data">
      <p *ngIf="screenOffer && !data.user.valid" class="loginParagraph">Sie haben bereits ein Abo? <a (click)="showLogin()">Hier
        Anmelden</a>
      </p>
    </ng-container>
  </div>
</div>
