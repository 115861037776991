import {PortalRoute} from './portalRoute';
import {ImageModel} from '../component/image';

export default class TagModel {
  constructor(public title: string,
              public link?: PortalRoute,
              public className?: string,
              public image?: ImageModel,
  ) {
  }
}
