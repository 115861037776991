<div *ngIf="!link" class="accordeon-toggle-wrapper isSingle">
  <a
    [gtm]="gtmId"
    (click)="toggleAccordeon()"
    class="accordeon-toggle"
    [class.active]="isOpen">
    <div class="accordeon-title">
      <p>{{title}}</p>
    </div>
    <iconbutton [icon]="'angle-right'"></iconbutton>
  </a>
</div>

<div *ngIf="link"
     class="accordeon-toggle-wrapper isMulti">
  <div class="accordeon-title">
    <a [gtm]="gtmId"
       [portalRouterLink]="link">
      {{title}}
    </a>
  </div>

  <a [gtm]="gtmId"
     class="accordeon-toggle"
     oewaClickTrigger
     (click)="toggleAccordeon()"
     [class.active]="isOpen">
    <iconbutton [icon]="'angle-right'"></iconbutton>
  </a>
</div>


<div [ngClass]="['accordeon-content', contentClass]" [@accordeonState]="isOpen ? stateActive : stateInactive">
  <div class="accordeon-content-inner">
    <ng-content></ng-content>
  </div>
</div>
