/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/teaser/teaser.directive";
import * as i2 from "../../../../shared/directive/oewaClickTrigger.directive";
import * as i3 from "../../../../shared/service/oewa.service";
import * as i4 from "../../../common/gtmTrigger.directive";
import * as i5 from "@angular/common";
import * as i6 from "../../../common/ui/tabBar/tabBar.component.ngfactory";
import * as i7 from "../../../common/ui/tabBar/tabBar.component";
import * as i8 from "./tabbedlist.component";
import * as i9 from "../../../../shared/service/teasers.service";
import * as i10 from "../../../../shared/service/device-detection.service";
var styles_TabbedListComponent = [];
var RenderType_TabbedListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TabbedListComponent, data: {} });
export { RenderType_TabbedListComponent as RenderType_TabbedListComponent };
function View_TabbedListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(1, 540672, null, 0, i1.TeaserDirective, [i0.ViewContainerRef, i0.ComponentFactoryResolver], { model: [0, "model"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TabbedListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "tabbedList-button-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "button"], ["oewaClickTrigger", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.fetchMoreEntries() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i2.OewaClickTriggerDirective, [i3.OewaService], { oewaPageId: [0, "oewaPageId"] }, null), i0.ɵdid(3, 81920, null, 0, i4.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null), (_l()(), i0.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.gtmId; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.model.cta; _ck(_v, 4, 0, currVal_2); }); }
function View_TabbedListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TabbedListComponent_2)), i0.ɵdid(2, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TabbedListComponent_3)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mediaModel; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.hasMoreEntries; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TabbedListComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "tabbedList"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "tabbar", [], null, [[null, "tabChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tabChanged" === en)) {
        var pd_0 = (_co.switchTab($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_TabBarComponent_0, i6.RenderType_TabBarComponent)), i0.ɵdid(2, 114688, null, 0, i7.TabBarComponent, [], { model: [0, "model"] }, { tabChanged: "tabChanged" }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TabbedListComponent_1)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabModels; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.teasers$)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TabbedListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tabbedlist", [], null, null, null, View_TabbedListComponent_0, RenderType_TabbedListComponent)), i0.ɵdid(1, 49152, null, 0, i8.TabbedListComponent, [i9.TeasersService, i10.DeviceDetectionService], null, null)], null, null); }
var TabbedListComponentNgFactory = i0.ɵccf("tabbedlist", i8.TabbedListComponent, View_TabbedListComponent_Host_0, {}, {}, []);
export { TabbedListComponentNgFactory as TabbedListComponentNgFactory };
