import {AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {IMovieDetail, ISeason} from '@model/content/movieSerieDetail/payload';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {MovieSerieDetailModel} from '@model/content/movieSerieDetail/movieSerieDetail';
import {BrowserRef} from '@shared/service/browser.ref';
import {TeasersService} from '@shared/service/teasers.service';
import {ITeaser} from '@model/teaser/payload';
import {TeaserModel} from '@model/teaser/teaser';
import {TeaserView} from '@model/teaser/teaserView';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {LdJsonService} from '@shared/service/ldJson.service';
import {DeviceDetectionService} from '../../../../shared/service/device-detection.service';
import {LoadExternalScriptService} from '@shared/service/loadExternalScript.service';
import {ScriptId} from '@model/enum/scriptId';
import {SCRIPT_ID_MAP} from '@model/const/script';
import {TabModel} from '@model/component/tab';
import {IOutBrain} from '@component/ads/outbrain/outbrain.component';
import {isPlatformBrowser} from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'moviedetail',
  templateUrl: 'movieSerieDetail.component.html'
})

export class MovieSerieDetailComponent implements DynamicComponent<IMovieDetail>, OnDestroy, AfterViewInit {

  model: MovieSerieDetailModel;
  reviews$: Observable<TeaserModel[]>;
  relatedArticles$: Observable<TeaserModel[]>;
  justWatchSubscription: Subscription;
  currentSeason: ISeason;
  outbrainModel: IOutBrain;

  constructor(
    private browserRef: BrowserRef,
    private teaserService: TeasersService,
    private ldJsonService: LdJsonService,
    private mobileDetectionService: DeviceDetectionService,
    private loadScript: LoadExternalScriptService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.outbrainModel = {
        type: 'outbrain',
        src: window.location.href,
        template: 'AT_film.at',
        widget: 'AR_2'
      };
    }
    this.justWatchSubscription = this.loadScript.loadScript(ScriptId.justwatch)
      .pipe(
        map(res => {
          setTimeout(() => {
            if (res.loadedBefore) {
              this.browserRef.window[SCRIPT_ID_MAP[ScriptId.justwatch]].reloadWidgets();
            }
          }, 1000);
        })
      )
      .subscribe();
  }

  initModel(model: IMovieDetail): void {
    this.ldJsonService.injectFilmTags(model);
    this.model = MovieSerieDetailModel.deserialize(model, this.browserRef.document.location);
    if (model.reviews) {
      this.reviews$ = this.getTeasers(model.reviews);
    }

    if (model.related_articles) {
      this.relatedArticles$ = this.getTeasers(model.related_articles);
    }
  }

  ngOnDestroy(): void {
    if (this.justWatchSubscription) {
      this.justWatchSubscription.unsubscribe();
    }
  }

  private getTeasers(ids: string[]) {
    return forkJoin(ids.map(id => {
      return this.teaserService.getTeaser(id);
    })).pipe(
      map((teaser: ITeaser[]) => {
        return TeaserModel.fromMediaTeasers(teaser, new TeaserView(), this.mobileDetectionService.isMobile);
      })
    );
  }

  changeSeason($event: TabModel<ISeason>) {
    this.currentSeason = $event.info;
  }
}
