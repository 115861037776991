import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {Observable} from 'rxjs';
import {ITeaser, ITeaserView} from '@model/teaser/payload';
import {TeasersService} from '@shared/service/teasers.service';
import {tap} from 'rxjs/operators';
import {ICollection, ITitleComponent} from '@model/payload';
import {TeaserModel} from '@model/teaser/teaser';
import {TeaserView} from '@model/teaser/teaserView';
import {LinkBarModel} from '@model/component/linkBar';
import {ImageType} from '@model/enum/image';
import {DeviceDetectionService} from '../../../../shared/service/device-detection.service';
import {TimeAgoUtil} from '@util/timeAgo';

export interface ITopTopics extends ITitleComponent, ICollection {
  numbered: boolean;
  teaserView: ITeaserView;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'toptopics',
  templateUrl: 'toptopics.component.html'
})
export class TopTopicsComponent implements DynamicComponent<ITopTopics> {
  teasers$: Observable<ITeaser[]>;
  teasers: TeaserModel[];
  linkBar: LinkBarModel;
  numbered: boolean;
  dates: string[];
  datesTemplate: boolean;

  constructor(
    private teaserService: TeasersService,
    private mobileDetectionService: DeviceDetectionService,
  ) {
  }

  initModel(model: ITopTopics): void {
    this.numbered = model.numbered;
    this.linkBar = new LinkBarModel(model.title, '', true);
    this.teasers$ = this.teaserService.getCollection(model.collectionName, +model.numberOfTeasers)
      .pipe(
        tap((teasers: ITeaser[]) => {
          // remove badges
          const t = teasers.map(teaser => {
            teaser.badges = [];
            return teaser;
          });
          // set Dates
          this.datesTemplate = model.teaserView.headlines;
          this.dates = teasers.map(teaser => teaser.updated_date);
          this.teasers = TeaserModel
            .fromMediaTeasers(
              t.slice(1, +model.numberOfTeasers),
              new TeaserView(false, true, false, false),
              this.mobileDetectionService.isMobile,
            );
          if (!model.teaserView.img && !model.teaserView.hero) {
            this.teasers.unshift(TeaserModel.fromMediaTeaser(t[0], model.teaserView, this.mobileDetectionService.isMobile, model));
          } else {
            this.teasers.unshift(TeaserModel.fromCardTeaser(t[0], model.teaserView, ImageType.LS_16_9));
          }
        }),
      );
  }

  private time(index: number) {
    return TimeAgoUtil.topTopicsTime(new Date(this.dates[index]).toUTCString());
  }
}
