import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {ImageType} from '@model/enum/image';
import {LinkCardModel} from '@model/component/linkCard';
import {IInlineSlider} from '@model/content/inlineSlider/payload';
import {BaseSliderComponent} from '@component/common/base.slider.component';
import {SliderConfigModel} from '@model/component/sliderConfig';
import {BehaviorSubject, combineLatest, Observable, of, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {IRoute} from '@model/payload';
import {PianoService} from '@shared/service/piano/piano.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'inlineSlider',
  templateUrl: 'inlineSlider.component.html'
})
export class InlineSliderComponent extends BaseSliderComponent<IInlineSlider> {
  sliderConfigModel: SliderConfigModel;
  sliderItems$: Observable<(LinkCardModel | string)[]>;
  adLoadingIndex = new BehaviorSubject<number>(null);
  subscription = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private piano: PianoService,
  ) {
    super();
  }

  initModel(model: IInlineSlider): void {
    this.sliderItems$ = this.activatedRoute.data.pipe(
      map((data: { route: IRoute }) => {
        const channelName = data.route.channel.name;
        if (channelName) {
          return !(channelName.startsWith('cm/') || channelName === 'cm');
        }
        return false;
      }),
      switchMap(showAds => {
        return combineLatest([
          of(showAds),
          this.piano.checkAdFree(),
          this.piano.checkAdReduced(),
        ]);
      }),
      map(res => {
        if (model.data.hasOwnProperty('images')) {
          this.sliderConfigModel = new SliderConfigModel(true, 'inline-slider', true);
          const sliderItems: (LinkCardModel | string)[] = LinkCardModel.fromTeaserImages(model.data.images, ImageType.VARIABLE);
          if (res[0] && !(res[1] || res[2])) {
            let count = 0;
            sliderItems.forEach((item, i) => {
              if (i % 3 === 0 && i !== 0) {
                sliderItems.splice(i + count, 0, 'adSlide').join();
                count++;
              }
            });
          }
          return sliderItems;
        }
      })
    );
  }

  loadAd(slide: number) {
    if (slide !== this.adLoadingIndex.getValue()) {
      this.adLoadingIndex.next(slide);
    }
  }

  isActiveSlide(i: number): boolean {
    return i - 1 < this.adLoadingIndex.getValue() &&
      this.adLoadingIndex.getValue() < i + 3;
  }
}
