import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {TeasersService} from '@shared/service/teasers.service';
import {TeaserView} from '@model/teaser/teaserView';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {BoxModel} from '@model/component/box';
import {ITeaser} from '@model/teaser/payload';
import {ISlider} from '@model/channel/slider/payload';
import {TeaserModel} from '@model/teaser/teaser';
import {SliderConfigModel} from '@model/component/sliderConfig';
import {ImageType} from '@model/enum/image';
import {PortalRoute} from '@model/teaser/portalRoute';
import {LinkCardModel} from '@model/component/linkCard';
import {PortalService} from '@shared/service/portal.service';
import {ImageModel} from '@model/component/image';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'cardslider',
  templateUrl: './cardSlider.component.html',
})
export class CardSliderComponent implements OnInit {

  @Input() model: ISlider;

  data$: Observable<ITeaser[]>;
  cardModels: TeaserModel[];
  linkCardModels: LinkCardModel[];
  boxModel: BoxModel;
  sliderConfigModel: SliderConfigModel;

  constructor(
    private teasers: TeasersService,
    private portalService: PortalService,
  ) {
  }

  ngOnInit(): void {
    this.data$ = this.teasers.getCollection(this.model.collectionName, +this.model.numberOfTeasers).pipe(tap(teasers => {
      if (this.model.style === 'poweredby') {
        this.linkCardModels = LinkCardModel.fromTeasers(teasers, this.model.teaserView || new TeaserView(), ImageType.LS_16_9, this.model);
        this.boxModel = this.linkCardModels[0].box;
        return;
      }
      this.cardModels = TeaserModel.fromCardTeasers(
        teasers,
        this.model.teaserView || new TeaserView(),
        ImageType.LS_16_9,
        new BoxModel({color: this.model.color})
      );
      this.boxModel = this.cardModels.length ? this.cardModels[0].box : new BoxModel();
    }));

    let adaptiveHeight = true;
    let className = '';
    if (this.model.type === 'multiSlider' || this.model.type === 'teaserSlider') {
      adaptiveHeight = false;
      className = 'multi-slider';
    } else {
      adaptiveHeight = true;
      className = 'single-slider';
    }
    let image: ImageModel;
    if (this.model.image) {
      image = new ImageModel(this.model.image, ImageType.LS_logo);
    }
    if (this.portalService.currentPortal() !== 'lustaufoesterreichat') {
      this.sliderConfigModel = new SliderConfigModel(
        adaptiveHeight,
        className,
        true,
        this.model.title,
        PortalRoute.fromUri(this.model.titleLink),
        undefined,
        undefined,
        undefined,
        image,
      );
    } else {
      this.sliderConfigModel = new SliderConfigModel(
        true,
        className,
        false,
        this.model.title,
        PortalRoute.fromUri(this.model.titleLink),
        undefined,
        undefined,
        undefined,
        image,
      );
    }
  }
}
