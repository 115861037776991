/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/directive/oewaClickTrigger.directive";
import * as i2 from "../../../../shared/service/oewa.service";
import * as i3 from "../../gtmTrigger.directive";
import * as i4 from "@angular/common";
import * as i5 from "./tabBar.component";
var styles_TabBarComponent = [];
var RenderType_TabBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TabBarComponent, data: {} });
export { RenderType_TabBarComponent as RenderType_TabBarComponent };
function View_TabBarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [["class", "tabBar-list-item"]], [[2, "isActive", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "button", [["oewaClickTrigger", ""]], [[8, "name", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.selectTab(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.OewaClickTriggerDirective, [i2.OewaService], { oewaPageId: [0, "oewaPageId"] }, null), i0.ɵdid(3, 81920, null, 0, i3.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null), (_l()(), i0.ɵted(4, null, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = ""; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.gtmId; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit === _co.activeTab); _ck(_v, 0, 0, currVal_0); var currVal_1 = ("wechsle tab zu " + _v.context.$implicit.name); _ck(_v, 1, 0, currVal_1); var currVal_4 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_4); }); }
export function View_TabBarComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "tabBar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "ul", [["class", "tabBar-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TabBarComponent_1)), i0.ɵdid(3, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_TabBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tabbar", [], null, null, null, View_TabBarComponent_0, RenderType_TabBarComponent)), i0.ɵdid(1, 114688, null, 0, i5.TabBarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabBarComponentNgFactory = i0.ɵccf("tabbar", i5.TabBarComponent, View_TabBarComponent_Host_0, { model: "model" }, { tabChanged: "tabChanged" }, []);
export { TabBarComponentNgFactory as TabBarComponentNgFactory };
