import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {Observable} from 'rxjs';
import {TogglePopupComponent} from '@component/common/togglePopup.component';
import {LoginModel} from '@model/user/login';
import {GTMTriggerId} from '@model/enum/gtm';
import {StateService} from '@shared/service/state.service';
import {PianoService} from '@shared/service/piano/piano.service';
import {IPianoUser} from '@model/ITinyPass';
import {filter, take, tap} from 'rxjs/operators';
import {OverlayService} from '@shared/service/overlay.service';
import {DeactivatedAdBlockComponent} from '@component/header/deactivate-ad-block/deactivated-ad-block.component';
import {DidomiService} from '@shared/service/didomi.service';
import {VendorTypes} from '@model/enum/vendorTypes';
import {NoConsentPianoComponent} from '@component/header/no-consent-piano/no-consent-piano.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'login',
  templateUrl: 'login.component.html'
})
export class LoginComponent extends TogglePopupComponent implements OnInit {

  readonly model = new LoginModel();

  readonly gtmId = GTMTriggerId.user_status;
  user$: Observable<IPianoUser>;
  buttonText: string;

  constructor(
    private stateService: StateService<string>,
    private piano: PianoService,
    private cd: ChangeDetectorRef,
    private overlayService: OverlayService,
    private resolver: ComponentFactoryResolver,
    private didomiService: DidomiService,
  ) {
    super();
  }

  ngOnInit() {
    this.user$ = this.piano.user$.pipe(
      tap(u => {
        u.valid ?
          u.firstName && u.lastName ? this.buttonText = u.firstName + ' ' + u.lastName : this.buttonText = 'Mein Profil'
          : this.buttonText = 'Anmelden';
        this.cd.detectChanges();
      }),
    );
  }

  togglePopUp(): boolean {
    this.didomiService.checkVendor$(VendorTypes.piano).pipe(
      take(1),
      tap(consent => {
        if (consent) {
          this.piano.tp$.pipe(
            take(1),
            tap(tp => {
              if (tp) {
                if (tp.pianoId.isUserValid()) {
                  super.togglePopUp();
                  setTimeout(this.showMyAccount.bind(this));
                } else {
                  this.piano.showLogin();
                }
              } else {
                this.overlayService.addComponent(this.resolver, DeactivatedAdBlockComponent);
              }
            })
          ).subscribe();
        } else {
          this.overlayService.addComponent(this.resolver, NoConsentPianoComponent);
        }
      }),
    ).subscribe();
    return false;
  }

  logout() {
    this.showPopUp = false;
    this.piano.doLogout();
    return false;
  }

  showMyAccount() {
    this.piano.tp$.pipe(
      filter(Boolean),
    ).subscribe(tp => {
      tp.myaccount.show({
        displayMode: 'inline',
        containerSelector: '#piano-myaccount-container'
      });
    });
    return false;
  }
}
