import {Component, Input} from '@angular/core';
import {FootballStageModel} from '@model/content/footballStages/footballStage';

@Component({
  selector: 'app-football-stages',
  templateUrl: './football-stages.component.html',
})
export class FootballStagesComponent {
  @Input() model: FootballStageModel[];
}
