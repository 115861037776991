import { CURRENT_ROUTE_URI } from './tokens';
import { StateProvider } from './state.service';
import { PortalRoute } from '../../model/teaser/portalRoute';
import * as i0 from "@angular/core";
import * as i1 from "./state.service";
var PortalService = /** @class */ (function () {
    function PortalService(stateService) {
        this.stateService = stateService;
        var uri = stateService.get(CURRENT_ROUTE_URI);
        this.currentPortalRoute = PortalRoute.fromUri(uri);
        if (uri.startsWith('/lust-auf-oesterreichat')) {
            this.currentPortalRoute = PortalRoute.fromUri('/lustaufoesterreichat');
        }
    }
    PortalService.prototype.hostname = function () {
        return this.hostnameOf(this.currentPortalRoute);
    };
    PortalService.prototype.hostnameOf = function (targetRoute) {
        return PortalService.HOSTS.get(targetRoute.portal) || targetRoute.portal;
    };
    PortalService.prototype.currentPortal = function () {
        return this.currentPortalRoute.portal;
    };
    PortalService.prototype.currentRoute = function () {
        return this.currentPortalRoute;
    };
    PortalService.prototype.samePortal = function (target) {
        return this.currentPortalRoute.portal === target.portal;
    };
    PortalService.prototype.subdomain = function (sub) {
        if (sub !== 'ng') {
            sub += '-varnish';
        }
        return sub + '.' + this.hostnameOf(this.currentPortalRoute);
    };
    //noinspection JSMethodCanBeStatic
    PortalService.prototype.canonical = function (targetRoute) {
        var targetHostname = this.hostnameOf(targetRoute);
        return targetRoute.protocol + '://' + targetHostname + targetRoute.path;
    };
    PortalService.prototype.absoluteUrlOf = function (target) {
        var hostname = this.hostnameOf(target);
        var path = target.path;
        if (target.hash) {
            path += target.hash;
        }
        if (target.protocol === 'mailto') {
            return target.protocol + ':' + hostname + path;
        }
        return target.protocol + '://' + hostname + path;
    };
    PortalService.prototype.getAmpUrlOf = function (target, appPrefix) {
        if (this.samePortal(target)) {
            return '/' + appPrefix + target.path;
        }
        var hostname = this.hostnameOf(target);
        return this.urlOf(target.protocol + '://' + hostname + '/' + appPrefix + target.path);
    };
    PortalService.prototype.urlOf = function (targetUri) {
        var target = PortalRoute.fromUri(targetUri);
        return this.urlOfRoute(target);
    };
    PortalService.prototype.urlOfRoute = function (target) {
        if (this.samePortal(target)) {
            return target.path;
        }
        return this.absoluteUrlOf(target);
    };
    PortalService.prototype.uri = function (path) {
        var uriPath = path === '/' ? '' : path;
        return '/' + this.currentPortalRoute.portal + uriPath;
    };
    PortalService.HOSTS = new Map()
        .set('kat', 'k.at')
        .set('kurierat', 'kurier.at')
        .set('filmat', 'film.at')
        .set('futurezoneat', 'futurezone.at')
        .set('freizeitat', 'freizeit.at')
        .set('motorat', 'motor.at')
        .set('eventsat', 'events.at')
        .set('profilat', 'profil.at')
        .set('lustaufoesterreichat', 'lust-auf-oesterreich.at')
        .set('kuriertv', 'kurier.tv');
    PortalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PortalService_Factory() { return new PortalService(i0.ɵɵinject(i1.StateService)); }, token: PortalService, providedIn: "root" });
    return PortalService;
}());
export { PortalService };
