import {IMovieSerie} from './payload';
import {ImageModel} from '../component/image';
import {TimeAgoUtil} from '../../util/timeAgo';
import {ImageType} from '../enum/image';
import {PortalRoute} from '../teaser/portalRoute';
import {GTMTriggerId} from '../enum/gtm';

export class MovieSerieModel {
  title: string;
  ovTitle: string;
  synopsis: string;
  playTime: number;
  releaseDate: string;
  poster: ImageModel;
  classification: number;
  badges: { link: PortalRoute, name: string, gtmId: GTMTriggerId }[] = [];
  link: PortalRoute;
  seasonEpisodes?: string;
  classNames?: string[] = [];

  get typeBadge(): string {
    return this.seasonEpisodes ? 'Serie' : 'Film';
  }

  get classes(): string {
    return this.classNames.join(' ');
  }

  static deserialize(data: IMovieSerie): MovieSerieModel {
    const m = new MovieSerieModel();
    m.title = data.title;
    m.ovTitle = data.ov_title;
    if (data.poster) {
      m.poster = new ImageModel(data.poster, ImageType.POSTER);
    }
    m.playTime = data.playtime;
    m.releaseDate = TimeAgoUtil.getDate(data.release_date);
    m.classification = data.classification;
    m.synopsis = data.teaser_text;
    m.badges = data.genres && data.genres.map(g => {
      return {name: g.name, link: PortalRoute.fromUri(g.uri), gtmId: GTMTriggerId.badge};
    });
    m.link = PortalRoute.fromTeaser(data);
    if (data.seasons && data.seasons.length) {
      let episodeCount = 0;
      data.seasons.forEach(s => {
        if (s.episodes) {
          episodeCount += s.episodes.length;
        }
      });
      m.seasonEpisodes = data.seasons.length + ' / ' + episodeCount;
      m.classNames.push('serie');
    } else {
      m.classNames.push('film');
    }
    return m;
  }
}
