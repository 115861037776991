/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./teaser-video.component";
import * as i2 from "../../../../shared/service/image.service";
var styles_TeaserVideoComponent = [];
var RenderType_TeaserVideoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TeaserVideoComponent, data: {} });
export { RenderType_TeaserVideoComponent as RenderType_TeaserVideoComponent };
export function View_TeaserVideoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "video", [["controls", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hostUrl; _ck(_v, 0, 0, currVal_0); }); }
export function View_TeaserVideoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "teaser-video", [], null, null, null, View_TeaserVideoComponent_0, RenderType_TeaserVideoComponent)), i0.ɵdid(1, 114688, null, 0, i1.TeaserVideoComponent, [i2.ImageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TeaserVideoComponentNgFactory = i0.ɵccf("teaser-video", i1.TeaserVideoComponent, View_TeaserVideoComponent_Host_0, { url: "url" }, {}, []);
export { TeaserVideoComponentNgFactory as TeaserVideoComponentNgFactory };
