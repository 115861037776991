import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FootballTable} from '@model/content/footballTable/footballTable';

@Component({
  selector: 'app-football-table',
  templateUrl: './football-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FootballTableComponent {
  @Input() model: FootballTable;
}
