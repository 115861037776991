import {NgModule} from '@angular/core';
import {StripHtmlPipe} from './stripHtml.pipe';
import {MaxLengthPipe} from './maxLength.pipe';
import {SafeHtmlPipe} from './safeHtml.pipe';
import {ObjectKeysPipe} from './objectKey.pipe';


const PIPES = [
  MaxLengthPipe,
  SafeHtmlPipe,
  ObjectKeysPipe,
  StripHtmlPipe,
];

@NgModule({
  declarations: [
    ...PIPES,
  ],
  exports: [
    ...PIPES,
  ]
})
export class PipeModule {
}
