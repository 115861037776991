/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./no-consent.component";
import * as i3 from "../../../../shared/service/didomi.service";
var styles_NoConsentComponent = [];
var RenderType_NoConsentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NoConsentComponent, data: {} });
export { RenderType_NoConsentComponent as RenderType_NoConsentComponent };
function View_NoConsentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [], [[2, "isBlurred", null], [8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; var currVal_1 = (_co.image ? _co.image : ("/assets/images/placeholder_img/paragraphs/" + _co.img)); var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.embed, " Platzhalter."); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_NoConsentComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" Wir w\u00FCrden hier gerne ", " zeigen. Leider haben Sie uns hierf\u00FCr keine Zustimmung gegeben. Wenn Sie diesen anzeigen wollen, stimmen sie bitte ", " zu. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.embed; var currVal_1 = _co.vendorName; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_NoConsentComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "p", [], [[8, "innerText", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 0, 0, currVal_0); }); }
export function View_NoConsentComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "noConsent"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NoConsentComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "noConsent-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NoConsentComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NoConsentComponent_3)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "button", [["class", "button hasBackground"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptVendor() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(9, null, [" ", " akzeptieren "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.image || _co.img); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.text; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.text; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.vendorName; _ck(_v, 9, 0, currVal_3); }); }
export function View_NoConsentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, View_NoConsentComponent_0, RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i2.NoConsentComponent, [i3.DidomiService], null, null)], null, null); }
var NoConsentComponentNgFactory = i0.ɵccf("app-no-consent", i2.NoConsentComponent, View_NoConsentComponent_Host_0, { vendor: "vendor", image: "image", text: "text" }, {}, []);
export { NoConsentComponentNgFactory as NoConsentComponentNgFactory };
