<ng-container *ngIf="user$ | async as user">
  <a href="#" class="headerContainer-toggle headerContainer-toggle-isLogin" [class.isActive]="showPopUp" (click)="togglePopUp()">
    <iconbutton [icon]="'user-alt'"></iconbutton>
    <span>{{buttonText}}</span>
  </a>
  <div class="login isLoggedIn" *ngIf="showPopUp" (click)="clickedInside($event)"><!-- todo isLoggedIn class -->

    <ng-container *ngIf="user.valid">
      <div id="piano-myaccount-container"></div>
      <a [gtm]="gtmId" href="#" class="button" title="Abmelden" oewaClickTrigger (click)="logout()">Abmelden</a>
    </ng-container>

    <iconbutton [icon]="'times'" class="login-close" (click)="togglePopUp()"></iconbutton>
  </div>
</ng-container>
