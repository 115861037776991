import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'filmatprogram',
  templateUrl: 'filmatprogram.component.html'
})
export class FilmAtProgramComponent {
  @Input() cssClass;
}
