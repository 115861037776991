<a
  [class.portrait]="model.lightbox"
  class="image"
  *ngIf="model.link"
  [portalRouterLink]="model.link"
  [videoTeaser]="model.video"
  [ngClass]="[model.imgClass]"
  [class.isRound]="model.isRounded">
  <ng-container *ngTemplateOutlet="img"></ng-container>
</a>

<div
  [class.portrait]="model.lightbox"
  class="image"
  *ngIf="!model.link"
  [videoTeaser]="model.video"
  [ngClass]="[model.imgClass]"
  [class.isRound]="model.isRounded">
  <ng-container *ngTemplateOutlet="img"></ng-container>
</div>

<ng-template #img>
  <img
    (load)="sayHello()"
    [ngClass]="{'lazyload': true}"
    [attr.data-src]="model.src"
    [attr.data-srcset]="model.srcset"
    data-sizes="auto"
    [attr.width]="model.width"
    [alt]="model.alt | stripHtml"
    [title]="model.title | stripHtml"/>
  <button (click)="openImageInModal()" class="expand" *ngIf="model.lightbox">
    <svgicon icon="expand"></svgicon>
  </button>
</ng-template>

<div class="caption" *ngIf="model.hasCaption">
  <div class="copyright">
    <div *ngIf="model.description" [innerHTML]="model.description"></div>
    <ng-container *ngIf="model.copyright">&copy; Bild: {{model.copyright}}</ng-container>
  </div>
</div>

<ng-template #overlay>
  <div
    (click)="close()"
    [ngStyle]="{width: width + 'px', left: left + 'px'}"
    class="image-modal">
    <button class="image-modal-close">
      <svgicon icon="times"></svgicon>
    </button>
    <img
      (load)="sayHello()"
      class="lazyload"
      [attr.data-src]="model.src"
      [attr.data-srcset]="model.srcset"
      data-sizes="auto"
      [attr.width]="model.width"
      [alt]="model.alt | stripHtml"
      [title]="model.title | stripHtml"/>
  </div>
</ng-template>
