import * as tslib_1 from "tslib";
import { isDevMode } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/platform-browser";
var STATE_KEY = makeStateKey('CACHE');
var CacheService = /** @class */ (function () {
    function CacheService(platformId, router, transferState) {
        var _this = this;
        this.platformId = platformId;
        this.router = router;
        this.transferState = transferState;
        this.onSerializeMapCallbacks = {};
        this.cache = this.warmup();
        if (isPlatformBrowser(this.platformId)) {
            this.router.events.subscribe(function (event) {
                if (event instanceof NavigationStart && event.id > 1) {
                    _this.cache = new Map();
                }
            });
        }
    }
    CacheService.prototype.has = function (key) {
        var normKey = this.normalizeKey(key);
        return this.cache.has(normKey);
    };
    CacheService.prototype.set = function (key, value) {
        var normKey = this.normalizeKey(key);
        this.cache.set(normKey, value);
    };
    CacheService.prototype.get = function (key) {
        var normKey = this.normalizeKey(key);
        return this.cache.get(normKey);
    };
    CacheService.prototype.warmup = function () {
        var _this = this;
        var cache = new Map();
        if (isPlatformBrowser(this.platformId)) {
            if (this.transferState.hasKey(STATE_KEY)) {
                var cachedState_1 = this.transferState.get(STATE_KEY, new Map());
                Object.keys(cachedState_1).forEach(function (key) {
                    var normKey = _this.normalizeKey(key);
                    var value = cachedState_1[normKey];
                    cache.set(normKey, value);
                });
            }
        }
        else if (isPlatformServer(this.platformId)) {
            this.transferState.onSerialize(STATE_KEY, function () {
                var _loop_1 = function (key) {
                    if (_this.onSerializeMapCallbacks.hasOwnProperty(key)) {
                        var callback = _this.onSerializeMapCallbacks[key];
                        var map = callback();
                        var cachedMap_1 = {};
                        map.forEach(function (v, k) { return cachedMap_1[k] = v; });
                        _this.cache.set(key, cachedMap_1);
                    }
                };
                for (var key in _this.onSerializeMapCallbacks) {
                    _loop_1(key);
                }
                var cachedState = {};
                _this.cache.forEach(function (value, key) { return cachedState[key] = value; });
                return cachedState;
            });
        }
        return cache;
    };
    CacheService.prototype.getMap = function (key) {
        var e_1, _a;
        var map = new Map();
        if (this.has(key)) {
            var cached = this.get(key);
            try {
                for (var _b = tslib_1.__values(Object.keys(cached)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var k = _c.value;
                    map.set(k, cached[k]);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return map;
    };
    CacheService.prototype.onSerializeMap = function (key, callback) {
        this.onSerializeMapCallbacks[key] = callback;
    };
    /**
     * convert numbers into strings
     */
    CacheService.prototype.normalizeKey = function (key) {
        if (isDevMode() && this._isInvalidValue(key)) {
            throw new Error('Please provide a valid key to save in the CacheService');
        }
        return key + '';
    };
    CacheService.prototype._isInvalidValue = function (key) {
        return key === null ||
            key === undefined ||
            key === 0 ||
            key === '' ||
            typeof key === 'boolean' ||
            Number.isNaN(key);
    };
    CacheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CacheService_Factory() { return new CacheService(i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.TransferState)); }, token: CacheService, providedIn: "root" });
    return CacheService;
}());
export { CacheService };
