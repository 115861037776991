import {Injectable} from '@angular/core';
import {ActivationEnd, Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {StateService} from './state.service';
import {ROUTE_INFO} from './tokens';
import {IRoute} from '../../model/payload';
import {distinctUntilChanged, filter, map, shareReplay, skip} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouteService {

  protected routeInfo$: Observable<IRoute>;

  constructor(protected router: Router,
              protected stateService: StateService<IRoute>) {
    const routeInfo: IRoute = stateService.get(ROUTE_INFO, null);
    const routeInfo$ = new BehaviorSubject(routeInfo);
    this.routeInfo$ = routeInfo$.pipe(
      filter(Boolean),
      shareReplay(1),
    );
    router.events.pipe(
      filter(e => e instanceof ActivationEnd),
      skip(routeInfo ? 1 : 0),
      map((e: ActivationEnd) => e.snapshot.data.route),
      distinctUntilChanged(),
    ).subscribe(routeInfo$);
  }

  get routeInfo(): Observable<IRoute> {
    return this.routeInfo$;
  }

}
