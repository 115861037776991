import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {BaseFormControlComponent} from '../../baseFormControl.component';

@Component({
  selector: 'formselect',
  templateUrl: './select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent<T> extends BaseFormControlComponent {

  @Input() values: { value: T, name: string }[];
  @Input() valueSelected = 0;
  @Output() valueSelectedChange = new EventEmitter<number>();

  constructor() {
    super();
  }

  onChange() {
    this.valueSelectedChange.emit(this.valueSelected);
  }

}
