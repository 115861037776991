import { Routes } from '@angular/router';
import { HomeComponent } from '@component/home/home.component';
import { KurieratGuard } from '@routing/guard/kurierat.guard';
import { RouteResolverService } from '@shared/service/route-resolver.service';
import { FilmatGuard } from '@routing/guard/filmat.guard';
var ɵ0 = function () { return import("./../component/horoscope/horoscopeHome.module.ngfactory").then(function (m) { return m.HoroscopeHomeModuleNgFactory; }); }, ɵ1 = function () { return import("./../component/tv/kurier/tv.module.ngfactory").then(function (m) { return m.TvModuleNgFactory; }); }, ɵ2 = function () { return import("./../component/tv/film/tv.module.ngfactory").then(function (m) { return m.TvModuleNgFactory; }); }, ɵ3 = function () { return import("./../component/pammesberger/pammesberger.module.ngfactory").then(function (m) { return m.PammesbergerModuleNgFactory; }); }, ɵ4 = function () { return import("./../component/edition/edition.module.ngfactory").then(function (m) { return m.EditionModuleNgFactory; }); };
var routes = [
    {
        path: 'horoskop',
        loadChildren: ɵ0,
        canActivate: [KurieratGuard],
        resolve: {
            route: RouteResolverService
        },
    },
    {
        path: 'tv',
        loadChildren: ɵ1,
        canActivate: [KurieratGuard],
        resolve: {
            route: RouteResolverService
        },
    },
    {
        path: 'tv-programm',
        loadChildren: ɵ2,
        canActivate: [FilmatGuard],
        resolve: {
            route: RouteResolverService
        },
    },
    {
        path: 'pammesberger',
        loadChildren: ɵ3,
        canActivate: [KurieratGuard],
        resolve: {
            route: RouteResolverService
        },
    },
    {
        path: 'edition',
        loadChildren: ɵ4,
        canActivate: [KurieratGuard],
        resolve: {
            route: RouteResolverService
        },
    },
    {
        path: '**',
        component: HomeComponent,
        pathMatch: 'full',
        resolve: {
            route: RouteResolverService
        }
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
