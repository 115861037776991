import {Component} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {FootballService} from '@shared/service/football.service';
import {BehaviorSubject, interval, Observable, Subject} from 'rxjs';
import {IFootballData, IFootballEvent, IFootballLiveScore, IFootballTeam, IFootballVenue} from '@model/content/footballTable/payload';
import {TabModel} from '@model/component/tab';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {IFootballLiveScores} from '@model/content/footballLiveScores/footballLiveScores';

@Component({
  selector: 'app-football-live-score-wrapper',
  templateUrl: './football-live-score-wrapper.component.html',
})
export class FootballLiveScoreWrapperComponent implements DynamicComponent<IFootballLiveScores> {

  liveStandings$: Observable<{
    isLive: boolean,
    events: IFootballEvent[],
    localTeam: IFootballTeam,
    visitorTeam: IFootballTeam,
    scores: string;
    min: string;
    venue: IFootballVenue
  }>;
  liveGames$: Observable<IFootballData<IFootballLiveScore[]>>;
  tabModels = [
    new TabModel(1, 'Liveticker', undefined, true),
    new TabModel(2, 'weitere Spiele', undefined, false),
  ];
  private activeTab = new BehaviorSubject<number>(1);
  gameOver = new Subject<boolean>();

  constructor(
    private footballService: FootballService,
  ) {
  }

  get activeTab$() {
    return this.activeTab.asObservable();
  }

  initModel(model: IFootballLiveScores): void {
    this.liveStandings$ = interval(5000)
      .pipe(
        takeUntil(this.gameOver),
        switchMap(() => {
          return this.footballService.getFixture(+model.data.value)
            .pipe(
              tap(res => {
                if (!res.isLive) {
                  this.gameOver.next(true);
                } else {
                  const t = [...this.tabModels];
                  t[0].name = 'Jetzt Live';
                  this.tabModels = t;
                }
              })
            );
        })
      );
    this.liveGames$ = this.footballService.getDailyLiveScores();
  }

  switchTab(tab: TabModel<undefined>) {
    this.activeTab.next(tab.id);
  }
}
