import { ApiService } from './api.service';
import { Results } from '../../model/search/results';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { PortalService } from './portal.service';
import { PortalRoute } from '../../model/teaser/portalRoute';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./portal.service";
var ChannelSearchService = /** @class */ (function () {
    function ChannelSearchService(api, portalService) {
        this.api = api;
        this.portalService = portalService;
        this.searchResult$ = new BehaviorSubject(new Results());
        this.internalTerm = '';
        this.internalPage = 1;
        this.internalPageSize = 10;
    }
    Object.defineProperty(ChannelSearchService.prototype, "page_size", {
        set: function (value) {
            this.internalPageSize = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChannelSearchService.prototype, "page", {
        set: function (value) {
            this.internalPage = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChannelSearchService.prototype, "term", {
        get: function () {
            return this.internalTerm;
        },
        set: function (value) {
            this.internalTerm = value.toLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChannelSearchService.prototype, "ordering", {
        set: function (value) {
            this.internalOrdering = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChannelSearchService.prototype, "baseUrl", {
        set: function (value) {
            this.internalBaseUrl = PortalRoute.fromUri(value).path;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChannelSearchService.prototype, "searchResult", {
        get: function () {
            return this.searchResult$.asObservable().pipe(filter(Boolean));
        },
        enumerable: true,
        configurable: true
    });
    ChannelSearchService.prototype.searchByTerm = function (term, ordering, portal) {
        var p = portal;
        if (this.portalService.currentPortal() === 'lustaufoesterreichat') {
            p = 'lustaufoesterreich.at';
        }
        this.term = term || this.internalTerm;
        this.internalOrdering = ordering || this.internalOrdering;
        this.publish(this.api.searchByTerm(this.internalTerm, this.internalOrdering, this.internalPageSize, this.internalPage, p));
    };
    ChannelSearchService.prototype.searchByQuery = function (query, ordering) {
        this.internalTerm = '';
        this.internalOrdering = ordering || this.internalOrdering;
        this.publish(this.api.searchByQuery(query, ordering, this.internalPageSize, this.internalPage));
    };
    ChannelSearchService.prototype.searchByTopic = function (topic, ordering) {
        this.internalTerm = '';
        this.internalOrdering = ordering || this.internalOrdering;
        this.publish(this.api.searchByTopic(topic, ordering, this.internalPageSize, this.internalPage));
    };
    ChannelSearchService.prototype.searchByChannel = function (channel) {
        this.internalTerm = '';
        this.publish(this.api.searchByChannel(channel, this.internalPageSize, this.internalPage));
    };
    ChannelSearchService.prototype.publish = function (obs$) {
        var _this = this;
        obs$.pipe(map(Results.deserialize), take(1)).subscribe(function (result) {
            _this.searchResult$.next(result);
        });
    };
    ChannelSearchService.prototype.getLink = function () {
        var term = this.internalTerm ? this.internalTerm + '/' : '';
        var newLink = this.internalBaseUrl + "/" + term + this.internalOrdering + "/" + this.internalPageSize + "/" + this.internalPage;
        return this.portalService.currentRoute().newLink(newLink);
    };
    ChannelSearchService.prototype.clearSubject = function () {
        this.searchResult$.next(null);
    };
    ChannelSearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChannelSearchService_Factory() { return new ChannelSearchService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.PortalService)); }, token: ChannelSearchService, providedIn: "root" });
    return ChannelSearchService;
}());
export { ChannelSearchService };
