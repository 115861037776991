/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../home/common/movieSerieTeaser/movie-serie-teaser.component.ngfactory";
import * as i2 from "../../../home/common/movieSerieTeaser/movie-serie-teaser.component";
import * as i3 from "./movie-serie.component";
var styles_MovieSerieComponent = [];
var RenderType_MovieSerieComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MovieSerieComponent, data: {} });
export { RenderType_MovieSerieComponent as RenderType_MovieSerieComponent };
export function View_MovieSerieComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "movieteaser", [], null, null, null, i1.View_MovieSerieTeaserComponent_0, i1.RenderType_MovieSerieTeaserComponent)), i0.ɵdid(1, 573440, null, 0, i2.MovieSerieTeaserComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.movieSerie; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MovieSerieComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-card", [], null, null, null, View_MovieSerieComponent_0, RenderType_MovieSerieComponent)), i0.ɵdid(1, 49152, null, 0, i3.MovieSerieComponent, [], null, null)], null, null); }
var MovieSerieComponentNgFactory = i0.ɵccf("app-card", i3.MovieSerieComponent, View_MovieSerieComponent_Host_0, {}, {}, []);
export { MovieSerieComponentNgFactory as MovieSerieComponentNgFactory };
