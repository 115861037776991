import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject, Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation
} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {RelatedService} from '@shared/service/related.service';
import {Observable, Subject} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {IDynamicComponent, IRoute} from '@model/payload';
import {TeaserModel} from '@model/teaser/teaser';
import {ShareModel} from '@model/component/share';
import {PortalService} from '@shared/service/portal.service';
import {LdJsonService} from '@shared/service/ldJson.service';
import {ArticleModel} from '@model/content/article/article';
import {IArticle} from '@model/content/article/payload';
import {TeasersService} from '@shared/service/teasers.service';
import {MovieSerieModel} from '@model/movieSerie/movieSerie';
import {IMovieSerie} from '@model/movieSerie/payload';
import {ApiService} from '@shared/service/api.service';
import {IMovieDetail} from '@model/content/movieSerieDetail/payload';
import {TeaserView} from '@model/teaser/teaserView';
import {PianoService} from '@shared/service/piano/piano.service';
import {DeviceDetectionService} from '@shared/service/device-detection.service';
import {VendorTypes} from '@model/enum/vendorTypes';
import {DidomiService} from '@shared/service/didomi.service';
import {LinkDefinition, LinkService} from '@shared/service/link.service';
import {IMG_SRC} from '@model/const/image';
import {ImageService} from '@shared/service/image.service';
import {ITeaser} from '@model/teaser/payload';
import {ImageSrc} from '@model/enum/image';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'articlecomp',
  templateUrl: 'article.component.html'
})
export class ArticleComponent implements DynamicComponent<IArticle>, OnDestroy, OnInit {

  @Input() article: IArticle;
  articleModel: ArticleModel;
  paragraphs: IDynamicComponent[] = [];
  relatedArticles$: Observable<TeaserModel[]>;
  shareModel: ShareModel;
  shareModelBottom: ShareModel;
  relatedMovie$: Observable<MovieSerieModel>;
  articleType: string;
  showOffer: boolean;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  paidVendor = VendorTypes.piano;
  paidVendorText = 'Um diesen Artikel lesen zu können, würden wir Ihnen gerne die Anmeldung für unser Plus Abo zeigen. Leider haben Sie' +
    ' uns hierfür keine Zustimmung gegeben. Wenn Sie diese anzeigen wollen, stimmen sie bitte Piano Software Inc. zu.';
  pianoConsent$: Observable<boolean>;
  pianoOffer$ = this.didomiService.checkVendor$(VendorTypes.piano).pipe(
    map(consent => !consent && this.portalService.currentPortal() === 'kurierat'),
  );
  showComments = true;
  romyNavigation$: Observable<ITeaser[]>;

  get hasOutbain() {
    return !!this.paragraphs.find(p => p.type === 'outbrain');
  }

  constructor(@Inject(PLATFORM_ID) private platformId: string,
              private cd: ChangeDetectorRef,
              public portalService: PortalService,
              private ldJsonService: LdJsonService,
              private piano: PianoService,
              private teaserService: TeasersService,
              private relatedService: RelatedService,
              private apiService: ApiService,
              private mobileDetectionService: DeviceDetectionService,
              private didomiService: DidomiService,
              private link: LinkService,
              private imageService: ImageService,
  ) {
  }

  ngOnInit() {
    if (this.article) {
      this.initModel(this.article);
      this.articleType = 'story';
    }
  }

  initModel(model: IArticle): void {
    if (model.channel.url === '/kurierat/romy') {
      this.romyNavigation$ = this.apiService.collectionTeasersCached('romy_2023_nominierte_static', 0, 50)
        .pipe(
          takeUntil(this.destroy$),
          map(collection => collection.items.map(teaser => teaser).filter(teaser => teaser.url !== undefined)),
          map(teasers => {
            const index = teasers.findIndex(teaser => teaser.url === model.url);
            const nav: ITeaser[] = [];
            const pushToArr = (i) => {
              const navItem = teasers[i];
              if (navItem.title.includes('2023:')) {
                navItem.title = navItem.title.split('2023:')[1].split(',')[0];
              }
              nav.push(navItem);
            };
            if (index > 0) {
              pushToArr(index - 1);
            } else {
              pushToArr(teasers.length - 1);
            }
            if (index < teasers.length - 1) {
              pushToArr(index + 1);
            } else {
              pushToArr(0);
            }
            return nav;
          }),
        );
    }
    this.pianoConsent$ = this.didomiService.checkVendor$(this.paidVendor).pipe(map(consent => !consent));
    this.shareModel = ShareModel.fromArticle(model, 'https://' + this.portalService.hostname() + model.url);
    this.shareModelBottom = ShareModel.fromArticle(model, 'https://' + this.portalService.hostname() + model.url);
    this.articleModel = ArticleModel.fromTeaser(model, model);
    if (this.articleModel.imageModel && this.articleModel.imageModel.name.endsWith('.gif')) {
      this.articleModel.imageModel.src = this.imageService.gifUrl(model.teaser_img.url);
    } else if (this.articleModel.imageModel) {
      this.articleModel.imageModel.src = this.imageService
        .url(this.articleModel.imageModel.url, IMG_SRC[this.articleModel.imageModel.size]);
      this.articleModel.imageModel.srcset = this.imageService.srcset(this.articleModel.imageModel.url, this.articleModel.imageModel.size);
      if (model.teaser_img && model.teaser_img.url) {
        const linkTag: LinkDefinition = {
          imagesizes: model.type === 'article' ?
            '(min-width: 769px) calc(100vw - 332px), (min-width: 965px) 616px, calc(100vw - 32px)' :
            '(min-width: 965px) 932px, calc(100vw - 32px)',
          imagesrcset: this.articleModel.imageModel.srcset,
          href: this.imageService.url(model.teaser_img.url, ImageSrc.V_1864),
          as: 'image',
          rel: 'preload',
        };
        this.link.addTag(linkTag);
      }
    }
    this.ldJsonService.injectArticleTags(model);
    this.articleType = model.type;
    if (model.sponsored || model.hide_comments) {
      this.showComments = false;
    }
    if (model.paid_content) {
      this.piano.checkPaywallAccess().pipe(
        takeUntil(this.destroy$),
        tap(access => {
          this.articleModel.paid = !access;
          const googleNews = navigator.userAgent === 'Googlebot-News';
          if (googleNews && this.articleModel.paid) {
            this.paragraphs = this.paragraphs.slice(0, 3);
          } else {
            const paywallParagraphPosition = model.paragraphs.findIndex(p => p.type === 'paywall');
            this.paragraphs = [...model.paragraphs.map((p, i) => {
              p.displayNone = i > paywallParagraphPosition
                && !access && p.type !== 'newsletter_signup'
                && p.type !== 'adInContent'
                && p.type !== 'outbrain';
              return p;
            })];
          }
          this.cd.markForCheck();
        }),
      ).subscribe();
    } else {
      this.paragraphs = model.paragraphs;
    }

    if (model.filmat) {
      if (model.filmat.movie) {
        this.relatedMovie$ = this.teaserService.getTeaser(model.filmat.movie).pipe(
          map((teaser: IMovieSerie) => {
            return MovieSerieModel.deserialize(teaser);
          }),
          tap((teaser: MovieSerieModel) => {
            const uri = '/' + teaser.link.portal + teaser.link.path;
            this.apiService.route(uri).pipe(
              map((res: IRoute) => {
                return res.layout.center[0];
              }),
              tap((res: IMovieDetail) => {
                this.ldJsonService.injectFilmTags(res);
              })
            ).subscribe();
          })
        );
      }
      if (model.filmat.editor_review) {
        this.articleModel.setRating(model.filmat.editor_review);
        this.articleModel.editorRating = model.filmat.editor_review;
      }
    }

    if (model.show_relateds) {
      this.relatedArticles$ = this.relatedService.forArticle(model.id, 3).pipe(
        map(teasers => TeaserModel
          .fromMediaTeasers(
            teasers,
            new TeaserView(true, true, false, false),
            this.mobileDetectionService.isMobile,
          )
        ),
      );
    }

    if (model.id === '400573673') {
      this.showOffer = true;
    }
  }

  get hasCoral() {
    return (this.portalService.currentPortal() === 'filmat' ||
      this.portalService.currentPortal() === 'motorat' ||
      this.portalService.currentPortal() === 'kurierat') && this.showComments;
  }

  showAdFree(): void {
    this.piano.showAdFree();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  trackByFn(index, paragraph: IDynamicComponent) {
    return JSON.stringify(paragraph);
  }
}
