import { ApiService } from './api.service';
import { PortalService } from './portal.service';
import { MenuModel } from '../../model/menu/menu';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./portal.service";
var MenuService = /** @class */ (function () {
    function MenuService(api, portalService) {
        this.api = api;
        this.portalService = portalService;
    }
    MenuService.prototype.get = function (name) {
        var currentPortal = this.portalService.currentPortal();
        return this.api.menu(currentPortal, name).pipe(map(MenuModel.deserializeJson));
    };
    MenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuService_Factory() { return new MenuService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.PortalService)); }, token: MenuService, providedIn: "root" });
    return MenuService;
}());
export { MenuService };
