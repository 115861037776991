/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../common/longList/longlist.component.ngfactory";
import * as i2 from "../../common/longList/longlist.component";
import * as i3 from "../../../../shared/service/teasers.service";
import * as i4 from "../../../../shared/service/device-detection.service";
import * as i5 from "../../../../shared/service/portal.service";
import * as i6 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i7 from "../../../common/ui/linkBar/linkBar.component";
import * as i8 from "../../common/multiTeaser/multi-teaser.component.ngfactory";
import * as i9 from "../../common/multiTeaser/multi-teaser.component";
import * as i10 from "@angular/common";
import * as i11 from "./channelRelateds.component";
import * as i12 from "../../../../shared/service/related.service";
var styles_ChannelRelatedsComponent = [];
var RenderType_ChannelRelatedsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChannelRelatedsComponent, data: {} });
export { RenderType_ChannelRelatedsComponent as RenderType_ChannelRelatedsComponent };
function View_ChannelRelatedsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "longlist", [], null, null, null, i1.View_LongListComponent_0, i1.RenderType_LongListComponent)), i0.ɵdid(1, 573440, null, 0, i2.LongListComponent, [i3.TeasersService, i4.DeviceDetectionService, i5.PortalService], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.longlistModel; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ChannelRelatedsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "linkbar", [], null, null, null, i6.View_LinkBarComponent_0, i6.RenderType_LinkBarComponent)), i0.ɵdid(2, 49152, null, 0, i7.LinkBarComponent, [], { model: [0, "model"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "multiteaser", [], null, null, null, i8.View_MultiTeaserComponent_0, i8.RenderType_MultiTeaserComponent)), i0.ɵdid(4, 180224, null, 0, i9.MultiTeaserComponent, [i5.PortalService, i3.TeasersService, i0.ChangeDetectorRef], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkBarModel; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.multiTeasers; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ChannelRelatedsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "channelRelateds"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChannelRelatedsComponent_2)), i0.ɵdid(2, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChannelRelatedsComponent_3)), i0.ɵdid(4, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.longlistModel; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.multiTeasers; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ChannelRelatedsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_ChannelRelatedsComponent_1)), i0.ɵdid(1, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i10.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.teasers$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ChannelRelatedsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "channelRelateds", [], null, null, null, View_ChannelRelatedsComponent_0, RenderType_ChannelRelatedsComponent)), i0.ɵdid(1, 49152, null, 0, i11.ChannelRelatedsComponent, [i12.RelatedService], null, null)], null, null); }
var ChannelRelatedsComponentNgFactory = i0.ɵccf("channelRelateds", i11.ChannelRelatedsComponent, View_ChannelRelatedsComponent_Host_0, {}, {}, []);
export { ChannelRelatedsComponentNgFactory as ChannelRelatedsComponentNgFactory };
