import * as tslib_1 from "tslib";
import { ImageType } from '@model/enum/image';
import { LinkCardModel } from '@model/component/linkCard';
import { BaseSliderComponent } from '@component/common/base.slider.component';
import { SliderConfigModel } from '@model/component/sliderConfig';
import { BehaviorSubject, combineLatest, of, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { PianoService } from '@shared/service/piano/piano.service';
var InlineSliderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InlineSliderComponent, _super);
    function InlineSliderComponent(activatedRoute, piano) {
        var _this = _super.call(this) || this;
        _this.activatedRoute = activatedRoute;
        _this.piano = piano;
        _this.adLoadingIndex = new BehaviorSubject(null);
        _this.subscription = new Subscription();
        return _this;
    }
    InlineSliderComponent.prototype.initModel = function (model) {
        var _this = this;
        this.sliderItems$ = this.activatedRoute.data.pipe(map(function (data) {
            var channelName = data.route.channel.name;
            if (channelName) {
                return !(channelName.startsWith('cm/') || channelName === 'cm');
            }
            return false;
        }), switchMap(function (showAds) {
            return combineLatest([
                of(showAds),
                _this.piano.checkAdFree(),
                _this.piano.checkAdReduced(),
            ]);
        }), map(function (res) {
            if (model.data.hasOwnProperty('images')) {
                _this.sliderConfigModel = new SliderConfigModel(true, 'inline-slider', true);
                var sliderItems_1 = LinkCardModel.fromTeaserImages(model.data.images, ImageType.VARIABLE);
                if (res[0] && !(res[1] || res[2])) {
                    var count_1 = 0;
                    sliderItems_1.forEach(function (item, i) {
                        if (i % 3 === 0 && i !== 0) {
                            sliderItems_1.splice(i + count_1, 0, 'adSlide').join();
                            count_1++;
                        }
                    });
                }
                return sliderItems_1;
            }
        }));
    };
    InlineSliderComponent.prototype.loadAd = function (slide) {
        if (slide !== this.adLoadingIndex.getValue()) {
            this.adLoadingIndex.next(slide);
        }
    };
    InlineSliderComponent.prototype.isActiveSlide = function (i) {
        return i - 1 < this.adLoadingIndex.getValue() &&
            this.adLoadingIndex.getValue() < i + 3;
    };
    return InlineSliderComponent;
}(BaseSliderComponent));
export { InlineSliderComponent };
