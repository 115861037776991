import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { LoadExternalScriptService } from '@shared/service/loadExternalScript.service';
import { filter, map, take, tap } from 'rxjs/operators';
import { ScriptId } from '@model/enum/scriptId';
import { combineLatest, Subscription } from 'rxjs';
import { VendorTypes } from '@model/enum/vendorTypes';
import { DidomiService } from '@shared/service/didomi.service';
import { RouteService } from '@shared/service/route.service';
import { PianoService } from '@shared/service/piano/piano.service';
var OutbrainComponent = /** @class */ (function () {
    function OutbrainComponent(zone, loadScript, didomiService, routeService, piano, cd) {
        this.zone = zone;
        this.loadScript = loadScript;
        this.didomiService = didomiService;
        this.routeService = routeService;
        this.piano = piano;
        this.cd = cd;
        this.vendorType = VendorTypes.outbrain;
        this.outbrainConsent$ = this.didomiService.checkVendor$(this.vendorType);
        this.show = false;
    }
    Object.defineProperty(OutbrainComponent.prototype, "model", {
        get: function () {
            return this._model;
        },
        set: function (model) {
            this._model = model;
        },
        enumerable: true,
        configurable: true
    });
    OutbrainComponent.prototype.initModel = function (model) {
        this.model = model;
    };
    OutbrainComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.subscription = combineLatest([
            this.routeService.routeInfo.pipe(map(function (route) { return route.dataLayer; })),
            this.outbrainConsent$,
            this.piano.user$.pipe(filter(Boolean)),
            this.piano.checkAdFree(),
        ])
            .pipe(tap(function (_a) {
            var _b = tslib_1.__read(_a, 4), dataLayer = _b[0], outbrainConsent = _b[1], user = _b[2], adFree = _b[3];
            _this.clear();
            if (dataLayer.AdsEnabled && outbrainConsent && !adFree) {
                _this.loadScript.loadScript(ScriptId.outbrain).pipe(take(1)).subscribe(function (obr) {
                    _this.modifiedModel = tslib_1.__assign({}, _this.model);
                    _this.piano.checkPaywallAccess().pipe(take(1)).subscribe(function (access) {
                        if (!access || user.valid === false) {
                            _this.modifiedModel.widget = 'AR_2';
                        }
                        if (dataLayer.Seitentyp !== 'Artikelseite') {
                            _this.modifiedModel.widget = _this.model.widget;
                        }
                        _this.show = true;
                        _this.cd.detectChanges();
                        obr.scriptVar.extern.researchWidget();
                    });
                });
            }
            _this.showConsentOverlay = !outbrainConsent;
            _this.cd.markForCheck();
        }))
            .subscribe();
    };
    OutbrainComponent.prototype.clear = function () {
        if (this.obHolder) {
            this.obHolder.nativeElement.innerHTML = '';
        }
        this.show = false;
        this.cd.detectChanges();
    };
    OutbrainComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    return OutbrainComponent;
}());
export { OutbrainComponent };
