import {Inject, Injectable, Renderer2, RendererFactory2, ViewEncapsulation} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {StateService} from './state.service';

export interface IScriptAtttributes {
  src?: string;
  defer?: 'defer';
  onload?: string;
  type?: string;
}

export interface IScriptDefinition {
  attrs?: IScriptAtttributes;
  innerHTML?: string;
}

@Injectable({
  providedIn: 'root',
})
export class InjectScriptsService {

  private renderer: Renderer2;

  constructor(private stateService: StateService<string>,
              private rendererFactory: RendererFactory2,
              @Inject(DOCUMENT) private document) {

    this.renderer = this.rendererFactory.createRenderer(this.document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });
  }

  appendToBodyOnce(def: IScriptDefinition) {
    if (this.stateService.isFirstApp) {
      this.renderer.appendChild(this.document.body, this.createScript(def));
    }
  }

  appendToBody(def: IScriptDefinition) {
    this.renderer.appendChild(this.document.body, this.createScript(def));
  }

  injectToHeadOnce(def: IScriptDefinition) {
    if (this.stateService.isFirstApp) {
      this.renderer.appendChild(this.document.head, this.createScript(def));
    }
  }

  createScript(def: IScriptDefinition): any {
    const script = this.renderer.createElement('script');

    if (def.attrs) {
      Object.keys(def.attrs).forEach((prop: string) => {
        return this.renderer.setAttribute(script, prop, def.attrs[prop]);
      });
    }

    if (def.innerHTML) {
      const text = this.renderer.createText(def.innerHTML);
      this.renderer.appendChild(script, text);
    }

    return script;
  }

}
