import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../common/dynamicComponent';
import {IDynamicComponent} from '../../../model/payload';
import {AdModel} from '../../../model/ad/ad-model';
import {AdDimensions, AdSize} from '../../../model/enum/ad';
import {AD_DIMENSIONS} from '../../../model/const/ad';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'adFullbanner',
  templateUrl: 'adFullbanner.component.html'
})
export class AdFullbannerComponent implements DynamicComponent<IDynamicComponent> {

  model: AdModel;

  initModel(model: IDynamicComponent): void {
    this.model = new AdModel(
      'adFullBanner',
      AdSize.ad_fullbanner,
      [
        AD_DIMENSIONS[AdDimensions.dim_400x300],
        AD_DIMENSIONS[AdDimensions.dim_300x250],
        AD_DIMENSIONS[AdDimensions.dim_300x169],
        AD_DIMENSIONS[AdDimensions.dim_480x270],
        AD_DIMENSIONS[AdDimensions.dim_fluid],
      ],
      false,
      (d: number[]) => d[0] <= window.innerWidth,
      false,
    );
  }
}
