import {ChangeDetectionStrategy, Component, Inject, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../common/dynamicComponent';
import {IDynamicComponent} from '@model/payload';
import {AdDimensions, AdSize} from '@model/enum/ad';
import {AdModel} from '@model/ad/ad-model';
import {AD_DIMENSIONS} from '@model/const/ad';
import {isPlatformBrowser} from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'adResponsive',
  templateUrl: 'adresponsive.component.html'
})
export class AdResponsiveComponent implements DynamicComponent<IDynamicComponent> {

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
  }

  model: AdModel;

  initModel(model: IDynamicComponent): void {
    if (isPlatformBrowser(this.platformId)) {
      this.model = new AdModel(
        'adResponsive',
        AdSize.ad_responsive,
        [AD_DIMENSIONS[AdDimensions.dim_1x2]],
        false,
        () => window.innerWidth >= 767,
        false,
      );
    }
  }

}
