import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from '@component/home/home.component';
import {KurieratGuard} from '@routing/guard/kurierat.guard';
import {RouteResolverService} from '@shared/service/route-resolver.service';
import {FilmatGuard} from '@routing/guard/filmat.guard';

const routes: Routes = [
  {
    path: 'horoskop',
    loadChildren: () => import('../component/horoscope/horoscopeHome.module').then(m => m.HoroscopeHomeModule),
    canActivate: [KurieratGuard],
    resolve: {
      route: RouteResolverService
    },
  },
  {
    path: 'tv',
    loadChildren: () => import('../component/tv/kurier/tv.module').then(m => m.TvModule),
    canActivate: [KurieratGuard],
    resolve: {
      route: RouteResolverService
    },
  },
  {
    path: 'tv-programm',
    loadChildren: () => import('../component/tv/film/tv.module').then(m => m.TvModule),
    canActivate: [FilmatGuard],
    resolve: {
      route: RouteResolverService
    },
  },
  {
    path: 'pammesberger',
    loadChildren: () => import('../component/pammesberger/pammesberger.module').then(m => m.PammesbergerModule),
    canActivate: [KurieratGuard],
    resolve: {
      route: RouteResolverService
    },
  },
  {
    path: 'edition',
    loadChildren: () => import('../component/edition/edition.module').then(m => m.EditionModule),
    canActivate: [KurieratGuard],
    resolve: {
      route: RouteResolverService
    },
  },
  {
    path: '**',
    component: HomeComponent,
    pathMatch: 'full',
    resolve: {
      route: RouteResolverService
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {initialNavigation: 'enabled', onSameUrlNavigation: 'reload'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
