import {ITextBox} from './payload';

export default class TextBoxModel {
  title: string;
  html: string;

  public static deserialize(data: ITextBox): TextBoxModel {
    const t = new TextBoxModel();
    t.title = data.title;
    t.html = data.text.value;
    return t;
  }
}
