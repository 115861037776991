/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../image/image.component.ngfactory";
import * as i2 from "../../image/image.component";
import * as i3 from "../../../../shared/service/image.service";
import * as i4 from "../../../../shared/service/overlay.service";
import * as i5 from "@angular/common";
import * as i6 from "./image-only.component";
var styles_ImageOnlyComponent = [];
var RenderType_ImageOnlyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImageOnlyComponent, data: {} });
export { RenderType_ImageOnlyComponent as RenderType_ImageOnlyComponent };
function View_ImageOnlyComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "picimage", [["class", "teaser-imageContainer"]], null, null, null, i1.View_ImageComponent_0, i1.RenderType_ImageComponent)), i0.ɵdid(1, 638976, null, 0, i2.ImageComponent, [i3.ImageService, i4.OverlayService, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.image; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ImageOnlyComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageOnlyComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.image; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ImageOnlyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-image-only", [], null, null, null, View_ImageOnlyComponent_0, RenderType_ImageOnlyComponent)), i0.ɵdid(1, 49152, null, 0, i6.ImageOnlyComponent, [], null, null)], null, null); }
var ImageOnlyComponentNgFactory = i0.ɵccf("app-image-only", i6.ImageOnlyComponent, View_ImageOnlyComponent_Host_0, {}, {}, []);
export { ImageOnlyComponentNgFactory as ImageOnlyComponentNgFactory };
