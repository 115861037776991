import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {PaginatorModel} from '../../../../model/component/paginator';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'paginator',
  templateUrl: 'paginator.component.html'
})
export class PaginatorComponent implements OnInit, OnChanges {

  @Input() model: PaginatorModel;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onPageChanged = new EventEmitter<number>();
  @ViewChild('paginator', { static: true }) paginator;
  pages: number[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.calcPages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calcPages();
  }

  onChangePage(page: number) {
    if (page < 1 || page > this.model.numberOfPages || page === this.model.currentPage) {
      return;
    }
    this.model.currentPage = page;
    this.calcPages();
    this.onPageChanged.emit(page);
    // scroll top
    const top = this.paginator.nativeElement.offsetTop - 96;
    window.scrollTo(0, top);
  }

  calcPages() {
    this.pages.length = 0;
    const end = Math.min(this.model.currentPage + 4, this.model.numberOfPages);
    const start = Math.max(1, end - 4);

    for (let i = start; i <= end; i++) {
      this.pages.push(i);
    }
  }

  // TODO hide / display content
}
