import {Component, ChangeDetectionStrategy, Input, ViewEncapsulation} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'filmatreview',
  templateUrl: 'filmatreview.component.html'
})
export class FilmAtReviewComponent {
  @Input() cssClass;
}
