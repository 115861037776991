import {ITheaterDetail} from '@model/content/theaterDetail/payload';
import {ImageModel} from '@model/component/image';
import {ImageType} from '@model/enum/image';
import {IDynamicComponent} from '@model/payload';
import {IScreenings} from '@model/channel/screenings/payload';
import {DynamicType} from '@model/enum/dynamic';
import {SafeResourceUrl} from '@angular/platform-browser';

export class TheaterDetailModel {
  name: string;
  tags: string[];
  address: string;
  phone: string;
  website: string;
  email: string;
  description: string;
  gmapsUrl: string | SafeResourceUrl;
  image: ImageModel;
  paragraphs: IDynamicComponent[];
  screenings: IScreenings;

  static deserialize(model: ITheaterDetail): TheaterDetailModel {
    const t = new TheaterDetailModel();

    t.name = model.name;
    t.tags = model.tags ? model.tags.map(tag => tag.name) : [];
    t.address = model.address.street + ', ' + model.address.zip + ' ' + model.address.city;
    t.phone = model.phone;
    t.website = model.website;
    t.email = model.email;
    t.description = model.description;
    if (model.teaser_img) {
      t.image = new ImageModel(model.teaser_img, ImageType.POSTER);
    }
    if (model.geolocation) {
      t.gmapsUrl = `https://maps.google.com/maps?q=${model.geolocation.lat},${model.geolocation.lon}&output=embed`;
    }
    t.paragraphs = model.paragraphs;
    t.screenings = {title: model.name, nested: false, id: model.id, type: DynamicType.screeningsBlock, category: 'cinema'};
    return t;
  }
}
