/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./filmatprogram.component";
var styles_FilmAtProgramComponent = [];
var RenderType_FilmAtProgramComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FilmAtProgramComponent, data: {} });
export { RenderType_FilmAtProgramComponent as RenderType_FilmAtProgramComponent };
export function View_FilmAtProgramComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["filmat program"]))], null, null); }
export function View_FilmAtProgramComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "filmatprogram", [], null, null, null, View_FilmAtProgramComponent_0, RenderType_FilmAtProgramComponent)), i0.ɵdid(1, 49152, null, 0, i1.FilmAtProgramComponent, [], null, null)], null, null); }
var FilmAtProgramComponentNgFactory = i0.ɵccf("filmatprogram", i1.FilmAtProgramComponent, View_FilmAtProgramComponent_Host_0, { cssClass: "cssClass" }, {}, []);
export { FilmAtProgramComponentNgFactory as FilmAtProgramComponentNgFactory };
