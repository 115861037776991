import { OnDestroy, OnInit } from '@angular/core';
import { TeasersService } from '@shared/service/teasers.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { DeviceDetectionService } from '@shared/service/device-detection.service';
import { TitleService } from '@shared/service/title.service';
import { ActivatedRoute } from '@angular/router';
import { PortalService } from '@shared/service/portal.service';
import { DynamicType } from '@model/enum/dynamic';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(platformId, activatedRoute, titleService, deviceDetectionService, teaserService, portalService) {
        this.platformId = platformId;
        this.activatedRoute = activatedRoute;
        this.titleService = titleService;
        this.deviceDetectionService = deviceDetectionService;
        this.teaserService = teaserService;
        this.portalService = portalService;
        this.leftComponents = [];
        this.centerComponents = [];
        this.topSouthComponents = [];
        this.topWestComponents = [];
        this.topEastComponents = [];
        this.topNorthComponents = [];
        this.story = false;
        this.destroy$ = new Subject();
    }
    HomeComponent.prototype.onResize = function () {
        var _this = this;
        if (this.route && this.route.type === 'article' && isPlatformBrowser(this.platformId)) {
            if (!this.ad) {
                this.ad = this.leftComponents.find(function (c) { return c.type === DynamicType.adMediumRectangleSticky; });
            }
            var isTablet = this.deviceDetectionService.isTablet;
            if (isTablet) {
                this.leftComponents = this.leftComponents.filter(function (c) { return c !== _this.ad; });
            }
            else if (!this.leftComponents.find(function (c) { return c.type === DynamicType.adMediumRectangleSticky; })) {
                this.leftComponents.push(this.ad);
            }
        }
    };
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route$ = this.activatedRoute.data.pipe(takeUntil(this.destroy$), map(function (data) { return data.route; }), tap(function (route) {
            _this.route = route;
            _this.titleService.setDocumentTitle(route.title);
            _this.setContent(route);
        }));
    };
    HomeComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
    };
    HomeComponent.prototype.hasAnyTopComponent = function (data) {
        if (!data) {
            return false;
        }
        var sum = Object.keys(data).filter(function (k) {
            k.startsWith('top');
        }).map(function (k) {
            return data[k].length;
        }).reduce(function (a, b) { return a + b; }, 0);
        return sum > 0;
    };
    HomeComponent.prototype.setContent = function (data) {
        if (data.collections) {
            for (var collection in data.collections) {
                if (data.collections.hasOwnProperty(collection)) {
                    var numberOfTeasers = +data.collections[collection];
                    this.teaserService.prewarmCollection(collection, numberOfTeasers);
                }
            }
        }
        if (!data.layout) {
            return;
        }
        this.topNorthComponents = data.layout.top_north || null;
        this.topWestComponents = data.layout.top_west || null;
        this.topEastComponents = data.layout.top_east || null;
        this.topSouthComponents = data.layout.top_south || null;
        if (!data.layout.left) {
            // new layout empty
            this.leftComponents = [];
        }
        else if (isPlatformBrowser(this.platformId) && this.deviceDetectionService.isTablet) {
            this.leftComponents = [];
        }
        else {
            this.leftComponents = this.getLeftComponents(data);
        }
        this.centerComponents = this.getCenterComponents(data);
    };
    HomeComponent.prototype.getLeftComponents = function (data) {
        var leftComponents = data.layout.left || [];
        if (data.layout.center && data.layout.center[0] && data.layout.center[0].type === 'story') {
            leftComponents = [];
            this.story = true;
        }
        else {
            this.story = false;
        }
        if (isPlatformServer(this.platformId) && data.type === 'channel') {
            return this.hasAnyTopComponent(data) ? [] : leftComponents.slice(0, 3);
        }
        return leftComponents;
    };
    HomeComponent.prototype.getCenterComponents = function (data) {
        var centerComponents = data.layout.center || [];
        if (isPlatformServer(this.platformId) && data.type === 'channel') {
            return this.hasAnyTopComponent(data) ? [] : centerComponents.slice(0, 3);
        }
        return centerComponents;
    };
    Object.defineProperty(HomeComponent.prototype, "isLaoe", {
        get: function () {
            return this.portalService.currentPortal() === 'lustaufoesterreichat';
        },
        enumerable: true,
        configurable: true
    });
    return HomeComponent;
}());
export { HomeComponent };
