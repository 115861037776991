import {Component} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {IFootballComponent} from '@model/content/footballTable/payload';
import {BehaviorSubject, Observable} from 'rxjs';
import {FootballTable} from '@model/content/footballTable/footballTable';
import {TabModel} from '@model/component/tab';
import {FootballService} from '@shared/service/football.service';
import {switchMap} from 'rxjs/operators';
import {FootballTableWrapper} from '@model/content/footballTable/footballTableWrapper';

@Component({
  selector: 'app-football-table-league',
  templateUrl: './football-table-league.component.html',
})
export class FootballTableLeagueComponent implements DynamicComponent<IFootballComponent> {
  footballStandingTables$: Observable<FootballTableWrapper>;
  tabModel: TabModel<FootballTable[]>[];
  private activeTab = new BehaviorSubject<number>(1);

  constructor(private footballService: FootballService) {
  }

  initModel(model: IFootballComponent): void {
    const firstSeasonFragment = +(model.data.footballSeason.split('/')[0]);
    const season = firstSeasonFragment + '/' + (firstSeasonFragment + 1);
    this.tabModel = [
      new TabModel(1, 'Tabelle', [], true),
      new TabModel(2, 'Heim / Auswärts', [], false),
    ];
    if (model.data.footballLeague !== 'at-league' && model.data.footballLeague !== 'at-league-playoff') {
      this.tabModel.push(new TabModel(3, 'Hin- / Rückrunde', [], false));
    }
    this.footballStandingTables$ = this.activeTab.asObservable()
      .pipe(
        switchMap(tab => {
          if (tab === 1) {
            if (
              model.data.footballLeague === 'at-league' ||
              model.data.footballLeague === 'at-league-playoff'
            ) {
              return this.footballService.fromLeagueAustrianStandings$(model.data.footballLeague, season);
            }
            return this.footballService.fromLeagueStandings$(model.data.footballLeague, season);
          } else if (tab === 2) {
            if (
              model.data.footballLeague === 'at-league' ||
              model.data.footballLeague === 'at-league-playoff'
            ) {
              return this.footballService.fromLeagueAustrianAwayHome$(model.data.footballLeague, season);
            }
            return this.footballService.forAwayHome$(model.data.footballLeague, season);
          } else if (tab === 3) {
            return this.footballService.forFirstSecondRound$(model.data.footballLeague, season);
          }
        }),
      );
  }

  changeTab(tab: TabModel<FootballTable[]>) {
    this.activeTab.next(tab.id);
  }
}
