export enum ScriptId {
  playerjs,
  mediakey,
  adition,
  cleverpush,
  twitter,
  facebook,
  gpt,
  instagram,
  outbrain,
  pinterest,
  pinpoll,
  pusher,
  riddle,
  opta,
  opta2,
  contentGarden,
  tinypass,
  justwatch,
  reddit,
  tiktok,
  comments,
  flourish,
  sportmonkLivescore,
  sportmonkStandingsSlider,
  sportmonkStandingsSquad,
  sportmonkStandings,
  sportmonkLivestatus,
  oewa,
  oewaBundle,
}
