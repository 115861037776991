import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
var ConsentFreeService = /** @class */ (function () {
    function ConsentFreeService(platformId) {
        this.platformId = platformId;
        this.userAgents = [
            // Google Bots
            'APIs-Google',
            'Mediapartners-Google',
            'AdsBot-Google-Mobile',
            'AdsBot-Google-Mobile',
            'AdsBot-Google',
            'Googlebot-Image',
            'Googlebot-News',
            'Googlebot',
            'Googlebot-Video',
            'Mediapartners-Google',
            'AdsBot-Google-Mobile-Apps',
            'FeedFetcher-Google',
            'Google-Read-Aloud',
            'DuplexWeb-Google',
            'Google Favicon',
            'googleweblight',
            'Storebot-Google',
            'cXensebot',
            'bottalk/extractor.1.1.',
        ];
    }
    Object.defineProperty(ConsentFreeService.prototype, "containsUa", {
        get: function () {
            var value = 0;
            this.userAgents.forEach(function (ua) {
                value = value + (+navigator.userAgent.includes(ua));
            });
            return (value > 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConsentFreeService.prototype, "isTrackingFree", {
        get: function () {
            if (isPlatformBrowser(this.platformId)) {
                return window.location.search.includes('trackingAllowed');
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConsentFreeService.prototype, "isConsentFree", {
        get: function () {
            if (isPlatformBrowser(this.platformId)) {
                // check if site opens in an iframe important for editor preview
                if (this.containsUa || this.isTrackingFree) {
                    return true;
                }
                try {
                    return window.self !== window.top;
                }
                catch (e) {
                    return true;
                }
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    ConsentFreeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConsentFreeService_Factory() { return new ConsentFreeService(i0.ɵɵinject(i0.PLATFORM_ID)); }, token: ConsentFreeService, providedIn: "root" });
    return ConsentFreeService;
}());
export { ConsentFreeService };
