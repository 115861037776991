import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Results} from '../../model/search/results';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {IResult} from '../../model/search/payload';
import {PortalService} from './portal.service';
import {SearchOrder} from '../../model/enum/search';
import {PortalRoute} from '../../model/teaser/portalRoute';

@Injectable({
  providedIn: 'root',
})
export class ChannelSearchService {

  private searchResult$ = new BehaviorSubject<Results>(new Results());
  private internalPage: number;
  private internalPageSize: number;
  private internalTerm = '';
  private internalOrdering: SearchOrder;
  private internalBaseUrl: string;

  constructor(private api: ApiService, private portalService: PortalService) {
    this.internalPage = 1;
    this.internalPageSize = 10;
  }

  set page_size(value: number) {
    this.internalPageSize = value;
  }

  set page(value: number) {
    this.internalPage = value;
  }

  get term(): string {
    return this.internalTerm;
  }

  set term(value: string) {
    this.internalTerm = value.toLowerCase();
  }

  set ordering(value: SearchOrder) {
    this.internalOrdering = value;
  }

  set baseUrl(value: string) {
    this.internalBaseUrl = PortalRoute.fromUri(value).path;
  }

  get searchResult(): Observable<Results> {
    return this.searchResult$.asObservable().pipe(filter(Boolean));
  }

  searchByTerm(term?: string, ordering?: SearchOrder, portal?: string) {
    let p = portal;
    if (this.portalService.currentPortal() === 'lustaufoesterreichat') {
      p = 'lustaufoesterreich.at';
    }
    this.term = term || this.internalTerm;
    this.internalOrdering = ordering || this.internalOrdering;
    this.publish(this.api.searchByTerm(this.internalTerm, this.internalOrdering, this.internalPageSize, this.internalPage, p));
  }

  searchByQuery(query: string, ordering?: SearchOrder) {
    this.internalTerm = '';
    this.internalOrdering = ordering || this.internalOrdering;
    this.publish(this.api.searchByQuery(query, ordering, this.internalPageSize, this.internalPage));
  }

  searchByTopic(topic: string, ordering?: SearchOrder) {
    this.internalTerm = '';
    this.internalOrdering = ordering || this.internalOrdering;
    this.publish(this.api.searchByTopic(topic, ordering, this.internalPageSize, this.internalPage));
  }

  searchByChannel(channel: string) {
    this.internalTerm = '';
    this.publish(this.api.searchByChannel(channel, this.internalPageSize, this.internalPage));
  }

  publish(obs$: Observable<IResult>) {
    obs$.pipe(
      map(Results.deserialize),
      take(1),
    ).subscribe(result => {
      this.searchResult$.next(result);
    });
  }

  getLink(): PortalRoute {
    const term = this.internalTerm ? this.internalTerm + '/' : '';
    const newLink = `${this.internalBaseUrl}/${term}${this.internalOrdering}/${this.internalPageSize}/${this.internalPage}`;
    return this.portalService.currentRoute().newLink(newLink);
  }

  clearSubject() {
    this.searchResult$.next(null);
  }
}
