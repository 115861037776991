/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/teaser/teaser.directive";
import * as i2 from "@angular/common";
import * as i3 from "./teaserParagraph.component";
import * as i4 from "../../../../shared/service/teasers.service";
import * as i5 from "../../../../shared/service/device-detection.service";
var styles_TeaserParagraphComponent = [];
var RenderType_TeaserParagraphComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TeaserParagraphComponent, data: {} });
export { RenderType_TeaserParagraphComponent as RenderType_TeaserParagraphComponent };
function View_TeaserParagraphComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(2, 540672, null, 0, i1.TeaserDirective, [i0.ViewContainerRef, i0.ComponentFactoryResolver], { model: [0, "model"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.teaserModel; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TeaserParagraphComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_TeaserParagraphComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.teaser$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TeaserParagraphComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "teaserParagraph", [], null, null, null, View_TeaserParagraphComponent_0, RenderType_TeaserParagraphComponent)), i0.ɵdid(1, 49152, null, 0, i3.TeaserParagraphComponent, [i4.TeasersService, i5.DeviceDetectionService], null, null)], null, null); }
var TeaserParagraphComponentNgFactory = i0.ɵccf("teaserParagraph", i3.TeaserParagraphComponent, View_TeaserParagraphComponent_Host_0, {}, {}, []);
export { TeaserParagraphComponentNgFactory as TeaserParagraphComponentNgFactory };
