import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {LoadExternalScriptService} from '@shared/service/loadExternalScript.service';
import {ScriptId} from '@model/enum/scriptId';
import {ITwitter} from '@model/content/twitter/payload';
import {DidomiService} from '@shared/service/didomi.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {VendorTypes} from '@model/enum/vendorTypes';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'twitter',
  templateUrl: 'twitter.component.html'
})
export class TwitterComponent implements DynamicComponent<ITwitter> {

  url: string;
  includeMedia = '';
  showTwitter$: Observable<boolean>;
  vendorType = VendorTypes.twitter;
  conversion = '';

  constructor(
    @Inject(LoadExternalScriptService) private loadScript: LoadExternalScriptService,
    private didomiService: DidomiService,
  ) {
  }

  initModel(model: ITwitter): void {
    this.showTwitter$ = this.didomiService.checkVendor$(this.vendorType)
      .pipe(
        tap(consent => {
          if (consent) {
            this.url = model.data.url;
            if (model.data.hide_thread) {
              this.conversion = 'none';
            }
            if (!model.data.include_media) {
              this.includeMedia = 'hidden';
            }
            this.loadScript.loadScript(ScriptId.twitter).subscribe(tw => {
              setTimeout(() => {
                tw.scriptVar.widgets.load();
              }, 1000);
            });
          }
        })
      );
  }
}
