import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '@shared/service/api.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ImageModel} from '@model/component/image';
import {IGallery} from '@model/payload';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {ImageType} from '@model/enum/image';
import {PortalRoute} from '@model/teaser/portalRoute';
import {IGallerySlider} from '@model/channel/gallerySlider/payload';
import {SliderConfigModel} from '@model/component/sliderConfig';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'gallerySlider',
  templateUrl: 'gallerySlider.component.html'
})
export class GallerySliderComponent implements DynamicComponent<IGallerySlider> {

  data$: Observable<IGallery>;
  cardModels: {
    image: ImageModel;
    caption: string;
  }[];
  sliderConfigModel: SliderConfigModel;
  private model: IGallerySlider;

  constructor(private apiService: ApiService) {
  }

  initModel(model: IGallerySlider): void {
    this.model = model;
    const limit = model.limit || 10;
    const size = ImageType.VARIABLE;
    this.data$ = this.apiService.gallery(model.galleryId, limit, model.articleId).pipe(
      tap((gallery: IGallery) => {
        const imageModels = gallery.images.map(image => new ImageModel({url: image.url}, size, PortalRoute.fromUri(model.titleLink)));
        this.cardModels = imageModels.map((image, i) => {
          return {
            image,
            caption: gallery.images[i].description,
          };
        });
        this.sliderConfigModel = new SliderConfigModel(true, 'gallery-slider', true, model.title, PortalRoute.fromUri(model.titleLink));
      })
    );
  }

}
