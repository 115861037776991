import {Component} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {PersonMovieList} from '@model/content/personMovieList/payload';
import {IMovieSerie} from '@model/movieSerie/payload';
import {MovieSerieModel} from '@model/movieSerie/movieSerie';
import {LinkBarModel} from '@model/component/linkBar';

@Component({
  selector: 'app-person-movie-list',
  templateUrl: './person-movie-list.component.html',
})
export class PersonMovieListComponent implements DynamicComponent<PersonMovieList> {

  private translation = {
    camera: 'Kameramann',
    music: 'Musiker',
    cast: 'Schauspieler',
    directors: 'Regisseur',
    authors: 'Autor',
  };
  data: {
    linkBarMovies: LinkBarModel;
    linkBarSeries: LinkBarModel;
    movies: MovieSerieModel[],
    series: MovieSerieModel[],
  }[];
  title: string;
  description: string;

  constructor() {
  }

  initModel(model: PersonMovieList) {
    this.title = model.person;
    this.description = model.description;
    this.data = Object.keys(model.list).map(key => {
      const value: {
        movie: IMovieSerie[];
        series: IMovieSerie[];
      } = model.list[key];
      if (value.movie || value.series) {
        return {
          linkBarMovies: new LinkBarModel('In folgenden Filmen als ' + this.translation[key], undefined, true),
          linkBarSeries: new LinkBarModel('In folgenden Serien als ' + this.translation[key], undefined, true),
          movies: value.movie && value.movie.length ? value.movie.map(m => MovieSerieModel.deserialize(m)) : [],
          series: value.series && value.series.length ? value.series.map(m => MovieSerieModel.deserialize(m)) : [],
        };
      }
    }).filter(items => items.movies.length || items.series.length);
  }
}
