import {Directive, HostListener, Input} from '@angular/core';
import {OewaService} from '../service/oewa.service';

@Directive({
  selector: '[oewaClickTrigger]'
})
export class OewaClickTriggerDirective {

  @Input('oewaClickTrigger') oewaPageId = 'ui';

  constructor(protected oewaService: OewaService) {
  }

  @HostListener('click', ['$event'])
  onClick($event) {
    this.oewaService.pushCp(this.oewaPageId);
  }

}
