import * as tslib_1 from "tslib";
import { TeaserComponent } from '@component/common/teaser/teaser.component';
var MediaComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MediaComponent, _super);
    function MediaComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MediaComponent;
}(TeaserComponent));
export { MediaComponent };
