import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { BaseFormControlComponent } from '../../baseFormControl.component';
var CheckboxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CheckboxComponent, _super);
    function CheckboxComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.valueChange = new EventEmitter();
        return _this;
    }
    CheckboxComponent.prototype.onChange = function () {
        this.value = !this.value;
        this.valueChange.emit(this.value);
    };
    return CheckboxComponent;
}(BaseFormControlComponent));
export { CheckboxComponent };
