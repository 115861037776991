/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./video.component";
import * as i2 from "@angular/platform-browser";
var styles_VideoComponent = [];
var RenderType_VideoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VideoComponent, data: {} });
export { RenderType_VideoComponent as RenderType_VideoComponent };
export function View_VideoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "sw-responsiveMedia sw-responsiveMedia_ratio16-9"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "iframe", [["allowfullscreen", ""], ["class", "sw-responsiveMedia_item"], ["frameborder", "0"], ["height", "270"], ["width", "480"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.safeUrl; _ck(_v, 1, 0, currVal_0); }); }
export function View_VideoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "videoComp", [], null, null, null, View_VideoComponent_0, RenderType_VideoComponent)), i0.ɵdid(1, 114688, null, 0, i1.VideoComponent, [i2.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoComponentNgFactory = i0.ɵccf("videoComp", i1.VideoComponent, View_VideoComponent_Host_0, { model: "model" }, {}, []);
export { VideoComponentNgFactory as VideoComponentNgFactory };
