import {PortalRoute} from '../teaser/portalRoute';
import {ITeaserImage} from '../teaser/payload';
import {IAuthor} from './payload';

export class Author {
  picture: ITeaserImage;
  profileTexts: string[];
  portalRoute: PortalRoute;
  name: string;

  static deserializeJson(json: IAuthor[], portal: string): Author[] {
    if (!json) {
      return [];
    }

    return json.map(item => {
      return Author.deserializeItem(item, portal);
    });
  }

  static deserializeItem(json: IAuthor, portal: string): Author {
    const a = new Author();
    a.profileTexts = !json.profiletext ? [] : json.profiletext.split('\n');
    if (json.lastname && json.firstname) {
      a.name = json.firstname.replace(/\s/g, '') + ' ' + json.lastname.replace(/\s/g, '');
    }

    a.picture = {
      url: json.picture || '',
      alt: a.name,
    };

    a.portalRoute = PortalRoute.fromHostname(portal, '/author/' + json.username);
    return a;
  }
}
