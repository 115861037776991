import { parse } from 'url';
var PortalRoute = /** @class */ (function () {
    function PortalRoute(internalPortal, internalPath, internalProtocol, internalHash) {
        if (internalProtocol === void 0) { internalProtocol = 'https'; }
        this.internalPortal = internalPortal;
        this.internalPath = internalPath;
        this.internalProtocol = internalProtocol;
        this.internalHash = internalHash;
    }
    PortalRoute.fromTeaser = function (teaser) {
        if (teaser.portal) {
            return PortalRoute.fromHostname(teaser.portal, teaser.url);
        }
        else {
            return PortalRoute.fromUri(teaser.url);
        }
    };
    Object.defineProperty(PortalRoute.prototype, "portal", {
        get: function () {
            return this.internalPortal;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PortalRoute.prototype, "path", {
        get: function () {
            return this.internalPath;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PortalRoute.prototype, "protocol", {
        get: function () {
            return this.internalProtocol;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PortalRoute.prototype, "hash", {
        get: function () {
            return this.internalHash;
        },
        enumerable: true,
        configurable: true
    });
    PortalRoute.fromHostname = function (hostname, path) {
        if (hostname === null || hostname === 'null' || path === null || path === 'null') {
            return PortalRoute.fromHostname('kurier.at', '/');
        }
        var tld = hostname.split('.').slice(-2);
        var portal = tld.join('');
        if (portal === 'platformsh') {
            portal = hostname.split('.').slice(0, 2).join('');
        }
        var realPath = path || '/';
        return new PortalRoute(portal.toLowerCase(), realPath);
    };
    PortalRoute.fromUri = function (uri) {
        if (!uri) {
            return null;
        }
        var matches = /^\/([^\/]+)(.*)/.exec(uri);
        if (matches !== null) {
            var portal = matches[1];
            var path = matches[2] || '/';
            return new PortalRoute(portal, path);
        }
        var url = parse(uri);
        var protocol = url.protocol ? url.protocol.replace(/:$/, '') : null;
        var mailtoStr = 'mailto:';
        if (url.protocol === mailtoStr) {
            var mailtoLink = void 0;
            mailtoLink = uri.substring(mailtoStr.length);
            return new PortalRoute(mailtoLink, '', protocol);
        }
        return new PortalRoute(url.hostname, url.path, protocol, url.hash);
    };
    PortalRoute.prototype.uri = function () {
        var path = this.internalPath === '/' ? '' : this.internalPath;
        return '/' + this.internalPortal + path;
    };
    PortalRoute.prototype.newLink = function (link) {
        return new PortalRoute(this.internalPortal, link);
    };
    return PortalRoute;
}());
export { PortalRoute };
