import {TeaserView} from '../../teaser/teaserView';
import {ITeaser} from '../../teaser/payload';
import {ILongList} from './payload';
import {ISectionRelateds} from '../../content/sectionRelateds/payload';
import {IChannelRelateds} from '../../content/channelRelateds/payload';
import {GTMTriggerId} from '../../enum/gtm';
import {BoxColor} from '../../enum/box';

export class LonglistModel implements ILongList {
  cta = 'Mehr Schlagzeilen';
  items: ITeaser[];
  color = BoxColor.standard;
  titleLink: string;
  teaserView = new TeaserView();
  additionalTeaserStep: string | number = 0;
  mobileOnly: boolean;
  type: string;
  numberOfTeasers: string | number;
  collectionName: string;
  title: string;
  gtmId?: GTMTriggerId;

  static fromSectionRelateds(model: ISectionRelateds, t: ITeaser[]): LonglistModel {
    const l = new LonglistModel();
    l.items = t;
    l.teaserView = new TeaserView();
    l.title = model.title;
    l.numberOfTeasers = t.length.toString();
    return l;
  }

  static fromChannelRelateds(model: IChannelRelateds, t: ITeaser[]) {
    const l = new LonglistModel();
    l.items = t;
    l.teaserView = new TeaserView();
    l.title = model.title;
    l.titleLink = model.channelLink;
    l.numberOfTeasers = t.length.toString();
    l.gtmId = GTMTriggerId.channel_relateds;
    return l;
  }
}
