import {AdSize} from '../enum/ad';
import {IRoute} from '../payload';
import {ISlot} from '../../shared/ads/islot';
import {GPT_ID} from '../const/ad';

export class AdModel {

  slot: ISlot;

  constructor(public readonly baseId: string,
              public readonly size: AdSize,
              /**
               * @link https://developers.google.com/doubleclick-gpt/reference#googletag.GeneralSize
               */
              private readonly internalDimensions: Array<number | string>[],
              public readonly refresh: boolean,
              public readonly filter: (d: number[]) => boolean,
              public readonly setForceSafeFrame: boolean) {
  }

  private _id: string;

  get id(): string {
    return this._id;
  }

  private _adUnitPath: string;

  get adUnitPath(): string {
    return this._adUnitPath;
  }

  set adUnitPath(path: string) {
    this._adUnitPath = path;
  }

  get dimensions() {
    return this.internalDimensions.filter(this.filter);
  }

  assignId() {
    this._id = this.baseId + Math.floor(Math.random() * Math.pow(10, 15));
  }

  setAdUnitPath(route: IRoute) {
    const portal = route.dataLayer.Portal.replace(/\./, '');
    const isSpecial = route.dataLayer.Kampagne !== null;
    this._adUnitPath = isSpecial ?
      GPT_ID + portal + '_special_' + this.size :
      GPT_ID + portal + '_' + this.size;
    return this._adUnitPath;
  }

}
