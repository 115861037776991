<div class="headerSearch">

  <iconbutton [icon]="'times'" class="headerSearch-close" (click)="searchSelected()"></iconbutton>

  <searchinput (onSearch)="onSearch($event)" (onSelected)="onSelect($event)" [clearOnClick]="true" [setFocus]="true"
               [placeholder]="'Suchbegriff ...'" [label]="'Suchbegriff ...'" [onSearchButton]="clickSearchLink" [elmClass]="'search-result-item'">

    <ng-container *ngIf="(searchResult$ | async) as searchResult">
      <ng-container *ngIf="movies?.length">
        <h2 class="search-title">Filme & Serien</h2>
        <ul class="search-result-movie">
          <li *ngFor="let movie of movies" class="search-result-item">
            <picimage *ngIf="movie.image" [model]="movie.image"></picimage>
            <span *ngIf="!movie.image" class="plhimg" aria-hidden="true"></span>
            <a [portalRouterLink]="movie.portalRoute" (click)="searchSelected()">
              <span class="search-item-title">{{movie.title}}</span>
            </a>
          </li>
        </ul>
        <p class="search-results-more">
          <a class="button hasBackground isRounded" (click)="navigateToMovies()">
            Alle Filme zu "{{searchQuery}}"
          </a>
        </p>
      </ng-container>
      <ng-container *ngIf="theaters?.length">
        <h2 class="search-title">Kinos</h2>
        <ul>
          <li *ngFor="let theater of theaters" class="search-result-item">
            <a [portalRouterLink]="theater.portalRoute" (click)="searchSelected()">
              <span class="search-item-title">{{theater.title}}</span>
            </a>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="searchResult.hasResults">
        <ng-container *ngIf="searchResult.hasTopics">
          <h2 class="search-title">Thema</h2>
          <ul>
            <li *ngFor="let topic of topics" class="search-result-item">
              <a [portalRouterLink]="topic.portalRoute" (click)="searchSelected()">
                <span class="search-item-title">{{topic.title}}</span>
              </a>
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="searchResult.hasArticles">
          <h2 class="search-title">Artikel</h2>
          <ul>
            <li
              *ngFor="let article of articles"
              class="search-result-item"
              [ngClass]="' pB-' + article.portalRoute.portal">
              <a [portalRouterLink]="article.portalRoute" (click)="searchSelected()">
                <div class="search-tagLine">
                  <span class="search-category">
                    {{article.preTitle}}
                  </span>
                </div>
                <span class="search-item-title">{{article.title}}</span>
              </a>
            </li>
          </ul>
        </ng-container>
        <p class="search-results-more">
          <a class="button hasBackground isRounded" [portalRouterLink]="searchLink" (click)="searchSelected()">
            Alle Suchergebnisse
          </a>
        </p>
      </ng-container>
    </ng-container>
  </searchinput>
</div>
