import {parse} from 'url';
import {EMPTY, Observable, of} from 'rxjs';
import {first, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {IRoute} from '../../model/payload';
import {PortalRoute} from '../../model/teaser/portalRoute';
import {PortalService} from './portal.service';
import {ApiService} from './api.service';
import {BrowserRef} from './browser.ref';
import {StateService} from '@shared/service/state.service';
import {EndpointOptions} from '@model/environment';
import {Meta} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class RouteResolverService implements Resolve<IRoute> {

  constructor(protected router: Router,
              private browserRef: BrowserRef,
              protected portalService: PortalService,
              protected api: ApiService,
              private stateService: StateService<EndpointOptions>,
              private meta: Meta,
  ) {
  }

  deleteAuthorsFromObject(obj: IRoute) {
    Object.keys(obj).forEach(k => {
      const value = obj[k];
      if (['author', 'Autor', 'authors', 'agency'].includes(k)) {
        obj[k] = undefined;
      }
      if (value && typeof value === 'object') {
        this.deleteAuthorsFromObject(value);
      }
    });
  }

  resolve(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IRoute> | IRoute {
    const requestUrl = parse(state.url);
    let uri = this.portalService.uri(requestUrl.pathname);
    if (uri.includes('kurierat/edition/')) {
      uri = uri.replace('/edition', '');
    }
    return this.api.route(uri).pipe(
      first(),
      mergeMap(route => {
        if (route.type === 'redirect') {
          const target = PortalRoute.fromUri(route.location);
          if (this.portalService.samePortal(target)) {
            this.router.navigate([target.path]);
          } else {
            const canonical = this.portalService.canonical(target);
            this.browserRef.window.location.assign(canonical);
            return EMPTY;
          }
          return EMPTY;
        }
        if (this.stateService.isBrowser) {
          if (
            navigator.userAgent.toLowerCase().indexOf('cxensebot') >= 0 &&
            this.portalService.currentPortal() === 'kurierat'
          ) {
            const authorMetas = route.head.metas.filter(tag => tag.property === 'article:author' || tag.name === 'author');
            authorMetas.forEach(meta => {
              if (meta.name) {
                this.meta.removeTag(`name="${meta.name}"`);
              }
              if (meta.property) {
                this.meta.removeTag(`property="${meta.property}"`);
              }
            });
            this.deleteAuthorsFromObject(route);
          }
        }
        return of(route);
      }),
    );
  }

}
