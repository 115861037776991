var TextBoxModel = /** @class */ (function () {
    function TextBoxModel() {
    }
    TextBoxModel.deserialize = function (data) {
        var t = new TextBoxModel();
        t.title = data.title;
        t.html = data.text.value;
        return t;
    };
    return TextBoxModel;
}());
export default TextBoxModel;
