/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../common/movieSerieTeaser/movie-serie-teaser.component.ngfactory";
import * as i2 from "../../common/movieSerieTeaser/movie-serie-teaser.component";
import * as i3 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i4 from "../../../common/ui/linkBar/linkBar.component";
import * as i5 from "@angular/common";
import * as i6 from "../../../../shared/directive/oewaClickTrigger.directive";
import * as i7 from "../../../../shared/service/oewa.service";
import * as i8 from "../../../common/gtmTrigger.directive";
import * as i9 from "../../../common/teaser/teaser.directive";
import * as i10 from "../../../common/ui/paginator/paginator.component.ngfactory";
import * as i11 from "../../../common/ui/paginator/paginator.component";
import * as i12 from "./resultArticles.component";
import * as i13 from "../../../../shared/service/channelSearch.service";
import * as i14 from "@angular/router";
import * as i15 from "../../../../shared/service/portal.service";
import * as i16 from "../../../../shared/service/device-detection.service";
var styles_ResultArticlesComponent = [];
var RenderType_ResultArticlesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResultArticlesComponent, data: {} });
export { RenderType_ResultArticlesComponent as RenderType_ResultArticlesComponent };
function View_ResultArticlesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "movieteaser", [], null, null, null, i1.View_MovieSerieTeaserComponent_0, i1.RenderType_MovieSerieTeaserComponent)), i0.ɵdid(1, 573440, null, 0, i2.MovieSerieTeaserComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ResultArticlesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Alle Filme & Serien "]))], null, null); }
function View_ResultArticlesComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" Alle Filme & Serien zu \"", "\" "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.termName; _ck(_v, 1, 0, currVal_0); }); }
function View_ResultArticlesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "searchResults-movies"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "linkbar", [], null, null, null, i3.View_LinkBarComponent_0, i3.RenderType_LinkBarComponent)), i0.ɵdid(2, 49152, null, 0, i4.LinkBarComponent, [], { model: [0, "model"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResultArticlesComponent_3)), i0.ɵdid(4, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 6, "a", [["class", "button"], ["oewaClickTrigger", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.navigateToMovies() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, null, 0, i6.OewaClickTriggerDirective, [i7.OewaService], { oewaPageId: [0, "oewaPageId"] }, null), i0.ɵdid(7, 81920, null, 0, i8.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResultArticlesComponent_4)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResultArticlesComponent_5)), i0.ɵdid(11, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.movieTitle; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.movies; _ck(_v, 4, 0, currVal_1); var currVal_2 = ""; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.gtmId; _ck(_v, 7, 0, currVal_3); var currVal_4 = !_co.termName; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.termName; _ck(_v, 11, 0, currVal_5); }, null); }
function View_ResultArticlesComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "linkbar", [], null, null, null, i3.View_LinkBarComponent_0, i3.RenderType_LinkBarComponent)), i0.ɵdid(1, 49152, null, 0, i4.LinkBarComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ResultArticlesComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "searchResults-theater"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "linkbar", [], null, null, null, i3.View_LinkBarComponent_0, i3.RenderType_LinkBarComponent)), i0.ɵdid(2, 49152, null, 0, i4.LinkBarComponent, [], { model: [0, "model"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResultArticlesComponent_7)), i0.ɵdid(4, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.theaterTitle; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.theaters; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ResultArticlesComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(1, 540672, null, 0, i9.TeaserDirective, [i0.ViewContainerRef, i0.ComponentFactoryResolver], { model: [0, "model"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ResultArticlesComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "paginator", [["class", "searchResults-paginator"]], null, [[null, "onPageChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onPageChanged" === en)) {
        var pd_0 = (_co.selectPage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_PaginatorComponent_0, i10.RenderType_PaginatorComponent)), i0.ɵdid(1, 638976, null, 0, i11.PaginatorComponent, [], { model: [0, "model"] }, { onPageChanged: "onPageChanged" }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ResultArticlesComponent_9)), i0.ɵdid(3, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paginatorModel; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.mediaModels; _ck(_v, 3, 0, currVal_1); }, null); }
function View_ResultArticlesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResultArticlesComponent_2)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResultArticlesComponent_6)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResultArticlesComponent_8)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.movies; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.theaters; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.paginatorModel; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ResultArticlesComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "searchResults"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ResultArticlesComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.searchResult$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ResultArticlesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "searchresults", [], null, null, null, View_ResultArticlesComponent_0, RenderType_ResultArticlesComponent)), i0.ɵdid(1, 49152, null, 0, i12.ResultArticlesComponent, [i13.ChannelSearchService, i14.Router, i15.PortalService, i5.Location, i16.DeviceDetectionService], null, null)], null, null); }
var ResultArticlesComponentNgFactory = i0.ɵccf("searchresults", i12.ResultArticlesComponent, View_ResultArticlesComponent_Host_0, {}, {}, []);
export { ResultArticlesComponentNgFactory as ResultArticlesComponentNgFactory };
