import {ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'svgicon',
  templateUrl: './svgIcon.component.html'
})

export class SvgIconComponent {
  @Input() icon: string;

  @HostBinding('attr.class') class = 'svgIcon';
}
