import {PortalRoute} from '../teaser/portalRoute';
import {ITeaser} from '../teaser/payload';
import {ITitleComponent} from '../payload';
import {BoxModel} from './box';
import {BoxStyle} from '../enum/box';
import {ImageModel} from './image';
import {TeaserModel} from '../teaser/teaser';
import {ITopic} from '../search/payload';
import {PortalService} from '../../shared/service/portal.service';
import {GTMTriggerId} from '../enum/gtm';
import {ITitleBox} from '../channel/titleBox/payload';
import {ImageType} from '../enum/image';
import {ISingleTeaser} from '../channel/singleTeaser/payload';

export class LinkBarModel {

  link: PortalRoute;
  logo: ImageModel;
  image: ImageModel;

  static titleFromTeaser(comp: ITitleComponent, teaser: ITeaser, box: BoxModel): LinkBarModel {
    if (box.boxStyle === BoxStyle.poweredby || box.boxStyle === BoxStyle.sponsored) {
      const l = new LinkBarModel(comp ? comp.title : null, null, true);
      if (teaser.powered_by) {
        l.logo = new ImageModel(TeaserModel.poweredByLogo(teaser), ImageType.LS_logo);
        l.link = PortalRoute.fromUri(teaser.powered_by.link);
      }
      return l;
    }
    return new LinkBarModel(comp.title, PortalRoute.fromTeaser(teaser), true, GTMTriggerId.teaser_headline);
  }

  static fromSingleTeaser(comp: ISingleTeaser, teaser: ITeaser, box: BoxModel): LinkBarModel {
    let link;
    comp.titleLink ? link = comp.titleLink : link = PortalRoute.fromTeaser(teaser);
    const linkBar = new LinkBarModel(comp.title, link, true, GTMTriggerId.teaser_headline);
    if (comp.image) {
      linkBar.image = new ImageModel(comp.image, ImageType.LS_logo);
    }
    return linkBar;
  }

  static fromTopics(topics: ITopic[], portalService: PortalService): LinkBarModel[] {
    return topics ? topics.map(t => {
      // TODO this is copy and paste from SearchItemModel
      const topicRoute = PortalRoute.fromUri(t.uri);
      const topicRoutePath = topicRoute.path;
      const localTopicUri = portalService.uri(topicRoutePath);
      return new LinkBarModel(t.name, localTopicUri, false);
    }) : [];
  }

  static fromTitleBox(model: ITitleBox) {
    const l = new LinkBarModel(model.title, null, true);
    l.logo = model.teaserImage ? new ImageModel(model.teaserImage, ImageType.LS_logo) : null;
    l.link = PortalRoute.fromUri(model.teaserLink);
    return l;
  }

  constructor(public text: string,
              link: string | PortalRoute,
              public isTitle: boolean,
              public gtmId?: GTMTriggerId) {
    this.link = link ? (link instanceof PortalRoute ? link : PortalRoute.fromUri(link)) : null;
  }

  get showLink(): boolean {
    return this.link !== null;
  }
}
