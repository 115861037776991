import { InjectionToken, Injector } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
var StateService = /** @class */ (function () {
    function StateService(platformId, injector, transferState) {
        this.platformId = platformId;
        this.injector = injector;
        this.transferState = transferState;
        this.HAD_UNIVERSAL = makeStateKey('HAD_UNIVERSAL');
        if (isPlatformServer(this.platformId)) {
            this.transferState.onSerialize(this.HAD_UNIVERSAL, function () { return true; });
        }
    }
    StateService.prototype.get = function (token, notFoundValue) {
        if (isPlatformServer(this.platformId)) {
            return this.serverInjection(token, notFoundValue);
        }
        if (isPlatformBrowser(this.platformId)) {
            if (this.hadUniversal) {
                return this.transferedState(token, notFoundValue);
            }
            else {
                return this.browserInjection(token, notFoundValue);
            }
        }
        throw new Error('Invalid platform ' + this.platformId);
    };
    Object.defineProperty(StateService.prototype, "isServer", {
        get: function () {
            return isPlatformServer(this.platformId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "isBrowser", {
        get: function () {
            return isPlatformBrowser(this.platformId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "hadUniversal", {
        get: function () {
            return this.transferState.get(this.HAD_UNIVERSAL, false);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateService.prototype, "isFirstApp", {
        get: function () {
            return (isPlatformServer(this.platformId) || (isPlatformBrowser(this.platformId) && !this.hadUniversal));
        },
        enumerable: true,
        configurable: true
    });
    StateService.prototype.transferedState = function (token, notFoundValue) {
        var stateKey = makeStateKey(token);
        return this.transferState.get(stateKey, notFoundValue);
    };
    StateService.prototype.browserInjection = function (token, notFoundValue) {
        return this.injector.get(token, notFoundValue);
    };
    StateService.prototype.serverInjection = function (token, notFoundValue) {
        var stateKey = makeStateKey(token);
        var serverValue = this.injector.get(token, notFoundValue);
        this.transferState.onSerialize(stateKey, function () { return serverValue; });
        return serverValue;
    };
    StateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StateService_Factory() { return new StateService(i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i1.TransferState)); }, token: StateService, providedIn: "root" });
    return StateService;
}());
export { StateService };
