/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../no-consent/no-consent.component.ngfactory";
import * as i2 from "../no-consent/no-consent.component";
import * as i3 from "../../../../shared/service/didomi.service";
import * as i4 from "../../../../shared/pipe/safeHtml.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common";
import * as i7 from "./tik-tok.component";
import * as i8 from "@angular/common/http";
import * as i9 from "../../../../shared/service/loadExternalScript.service";
var styles_TikTokComponent = [];
var RenderType_TikTokComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TikTokComponent, data: {} });
export { RenderType_TikTokComponent as RenderType_TikTokComponent };
function View_TikTokComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "tikTok"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(1, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent, 0), _v.context.ngIf)); _ck(_v, 0, 0, currVal_0); }); }
function View_TikTokComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i1.View_NoConsentComponent_0, i1.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i2.NoConsentComponent, [i3.DidomiService], { vendor: [0, "vendor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TikTokComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i4.SafeHtmlPipe, [i5.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TikTokComponent_1)), i0.ɵdid(2, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i6.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["noContent", 2]], null, 0, null, View_TikTokComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.embed$)); var currVal_1 = i0.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_TikTokComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-tik-tok", [], null, null, null, View_TikTokComponent_0, RenderType_TikTokComponent)), i0.ɵdid(1, 49152, null, 0, i7.TikTokComponent, [i8.HttpClient, i9.LoadExternalScriptService, i3.DidomiService], null, null)], null, null); }
var TikTokComponentNgFactory = i0.ɵccf("app-tik-tok", i7.TikTokComponent, View_TikTokComponent_Host_0, {}, {}, []);
export { TikTokComponentNgFactory as TikTokComponentNgFactory };
