import {IAuthor} from '../author/payload';
import {ShareType} from '../enum/share';
import {MetaDefinition} from '@angular/platform-browser';
import {IMovieDetail} from '../content/movieSerieDetail/payload';
import {IArticle} from '../content/article/payload';

export class ShareModel {
  facebook?: string;
  facebookMessenger?: string;
  twitter?: string;
  pinterest?: string;
  whatsapp?: string;
  instagram?: string;
  googleplus?: string;
  mailto?: string;
  xing?: string;
  linkedin?: string;
  flipboard?: string;
  pocket?: string;
  url: string;

  static fromArticle(article: IArticle, url: string): ShareModel {
    const s = ShareModel.fromUrl(url);
    s.url = url;
    s.twitter = `https://twitter.com/share?size=large&url=${s.url}&text=${article.title}`;
    s.mailto = `mailto:?subject=${article.title}-${article.portal}&body=Ihr Link auf ${article.portal}: ${s.url}`;
    s.facebook = `http://www.facebook.com/share.php?u=${s.url}&title=${article.title}`;
    s.facebookMessenger = `fb-messenger://share/?link=${s.url}&app_id=416108785115054`;
    // s.flipboard = `https://share.flipboard.com/bookmarklet/popout?v=2&title=${article.title}&url=${s.url}`;
    s.pocket = `https://getpocket.com/edit?url=${s.url}`;
    return s;
  }

  static fromMovie(movie: IMovieDetail, url: string): ShareModel {
    const s = ShareModel.fromUrl(url);
    s.twitter = `https://twitter.com/share?size=large&url=${s.url}&text=${movie.title}`;
    s.mailto = `mailto:?subject=${movie.title}-filmat&body=Ihr Link auf Film.at: ${s.url}`;
    s.facebook = `http://www.facebook.com/share.php?u=${s.url}&title=${movie.title}`;
    return s;
  }

  static fromUrl(url: string): ShareModel {
    const s = new ShareModel();
    s.url = url;
    s.xing = `https://www.xing.com/spi/shares/new?url=${s.url}`;
    s.pinterest = `http://pinterest.com/pin/create/button/?url=${s.url}`;
    s.linkedin = `http://www.linkedin.com/shareArticle?mini=true&url=${s.url}`;
    s.whatsapp = `http://api.whatsapp.com/send?text=${s.url}`;
    s.googleplus = `https://plus.google.com/share?url=${s.url}`;
    s.flipboard = `https://share.flipboard.com/bookmarklet/popout?v=2&url=${s.url}`;
    s.pocket = `https://getpocket.com/edit?url=${s.url}`;
    return s;
  }

  static fromAuthor(a: IAuthor): ShareModel {
    const s = new ShareModel();
    if (a.twitter) {
      s.twitter = 'https://twitter.com/' + a.twitter;
    }
    if (a.facebook) {
      s.facebook = 'https://www.facebook.com/' + a.facebook;
    }
    return s;
  }

  addMetaInfo(metas: MetaDefinition[]) {
    if (this.twitter) {
      const sitemetas = metas.filter(meta => meta.name === 'twitter:site');
      if (sitemetas.length) {
        this.twitter += '&via=' + sitemetas[0].content.replace(/^@/, '');
      }
    }

    if (this.pinterest) {
      const imgMeta = metas.filter(meta => meta.property === 'og:image');
      const imageUrl = imgMeta && imgMeta.length > 0 ? '&media=' + imgMeta[0].content : '';
      this.pinterest += imageUrl;
    }
  }

  get links(): string[] {
    return Object.keys(this).filter(prop => prop !== undefined && prop !== 'url');
  }

  get allLinks(): string[] {
    return [
      ShareType.facebook,
      // ShareType.facebookMessenger,
      ShareType.twitter,
      ShareType.whatsapp,
      ShareType.mailto,
      // ShareType.xing,
      // ShareType.pinterest,
      // ShareType.linkedin,
      // ShareType.googleplus,
      // ShareType.flipboard,
      // ShareType.pocket
    ];
  }
}
