/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i2 from "../../../common/ui/linkBar/linkBar.component";
import * as i3 from "@angular/common";
import * as i4 from "../../content/no-consent/no-consent.component.ngfactory";
import * as i5 from "../../content/no-consent/no-consent.component";
import * as i6 from "../../../../shared/service/didomi.service";
import * as i7 from "./iframecontainer.component";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../../shared/service/device-detection.service";
import * as i10 from "../../../../shared/service/portal.service";
var styles_IframeContainerComponent = [];
var RenderType_IframeContainerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IframeContainerComponent, data: {} });
export { RenderType_IframeContainerComponent as RenderType_IframeContainerComponent };
function View_IframeContainerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "linkbar", [], null, null, null, i1.View_LinkBarComponent_0, i1.RenderType_LinkBarComponent)), i0.ɵdid(1, 49152, null, 0, i2.LinkBarComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkBarModel; _ck(_v, 1, 0, currVal_0); }, null); }
function View_IframeContainerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "iFrame"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IframeContainerComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [], [[2, "iFrame-main", null], [4, "paddingBottom", "%"]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "iframe", [], [[8, "src", 5], [8, "width", 0], [8, "height", 0], [8, "scrolling", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkBarModel; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.model.data.iframe_type !== "APA Grafiken"); var currVal_2 = _co.iframeRatio; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _co.safeUrl; var currVal_4 = _co.iframeWidth; var currVal_5 = _co.iframeHeight; var currVal_6 = _co.model.data.scrolling; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_IframeContainerComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i4.View_NoConsentComponent_0, i4.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i5.NoConsentComponent, [i6.DidomiService], { vendor: [0, "vendor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
function View_IframeContainerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_IframeContainerComponent_4)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_IframeContainerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_IframeContainerComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["noContent", 2]], null, 0, null, View_IframeContainerComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.showIframe$)); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_IframeContainerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "iframecontainer", [], null, null, null, View_IframeContainerComponent_0, RenderType_IframeContainerComponent)), i0.ɵdid(1, 180224, null, 0, i7.IframeContainerComponent, [i8.DomSanitizer, i9.DeviceDetectionService, i10.PortalService, i0.ChangeDetectorRef, i6.DidomiService], null, null)], null, null); }
var IframeContainerComponentNgFactory = i0.ɵccf("iframecontainer", i7.IframeContainerComponent, View_IframeContainerComponent_Host_0, {}, {}, []);
export { IframeContainerComponentNgFactory as IframeContainerComponentNgFactory };
