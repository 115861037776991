import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { BaseFormControlComponent } from '../../baseFormControl.component';
var SelectComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SelectComponent, _super);
    function SelectComponent() {
        var _this = _super.call(this) || this;
        _this.valueSelected = 0;
        _this.valueSelectedChange = new EventEmitter();
        return _this;
    }
    SelectComponent.prototype.onChange = function () {
        this.valueSelectedChange.emit(this.valueSelected);
    };
    return SelectComponent;
}(BaseFormControlComponent));
export { SelectComponent };
