import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {ITeaser} from '../../../../model/teaser/payload';
import {RelatedService} from '../../../../shared/service/related.service';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {LonglistModel} from '../../../../model/component/longlist/longlist';
import {ISectionRelateds} from '../../../../model/content/sectionRelateds/payload';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'sectionRelateds',
  templateUrl: 'sectionRelateds.component.html'
})
export class SectionRelatedsComponent implements DynamicComponent<ISectionRelateds> {

  teasers$: Observable<ITeaser[]>;
  longlistModel: LonglistModel;

  constructor(protected readonly relatedService: RelatedService) {
  }

  initModel(model: ISectionRelateds): void {
    this.teasers$ = this.relatedService.forSection(model.section, +model.numberOfTeasers).pipe(
      map(teasers => {
        return teasers.filter(teaser => teaser.id !== model.articleId);
      }),
      tap(t => {
        this.longlistModel = LonglistModel.fromSectionRelateds(model, t);
      }),
    );
  }
}
