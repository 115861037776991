import {Inject, Injectable, NgZone, PLATFORM_ID} from '@angular/core';
import {AdModel} from '@model/ad/ad-model';
import {IRoute} from '@model/payload';
import {BrowserRef} from '../service/browser.ref';
import {filter, first, map, share, switchMap} from 'rxjs/operators';
import {DataLayerType} from '@model/enum/datalayer';
import {IGPTManager} from './igptmanager';
import {LoadExternalScriptService} from '../service/loadExternalScript.service';
import {PROD_MODE} from '../service/tokens';
import {RouteService} from '../service/route.service';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {PortalService} from '@shared/service/portal.service';
import {BehaviorSubject} from 'rxjs';
import createRandomId from '@util/randomId';
import {VendorTypes} from '@model/enum/vendorTypes';
import {DidomiService} from '@shared/service/didomi.service';

@Injectable({
  providedIn: 'root',
})
export class GooglePublisherTagService {

  static readonly DFP_GDPR_KEY = 'GDPR';
  static readonly DFP_GDPR_CLEAN = 'cleanAd';
  static readonly DFP_GDPR_TRACK = 'trackingAd';
  static readonly PPID = 'PPUID';
  documentVisible = new BehaviorSubject<boolean>(true);

  private definedSlots: Map<string, AdModel> = new Map();

  constructor(@Inject(PROD_MODE) private prodmode: boolean,
              @Inject(PLATFORM_ID) platformId: string,
              private routeService: RouteService,
              private loadScript: LoadExternalScriptService,
              private browserRef: BrowserRef,
              private zone: NgZone,
              private didomiService: DidomiService,
              private portalService: PortalService,
  ) {
    if (isPlatformBrowser(platformId)) {
      document.addEventListener('visibilitychange', () => {
        this.documentVisible.next(!document.hidden);
      });
      window.onfocus = () => {
        this.documentVisible.next(true);
      };
      window.onblur = () => {
        this.documentVisible.next(false);
      };
    }
    if (!isPlatformServer(platformId)) {
      this.googletag = {
        cmd: [() => {
          this.googletag.pubads().enableSingleRequest();
          this.googletag.enableServices();
          this.googletag.pubads().enableLazyLoad({
            fetchMarginPercent: 50,
            renderMarginPercent: 25,
            mobileScaling: 0.5,
          });

          if (this.portalService.currentPortal() === 'kurierat') {
            this.googletag.pubads().setPublisherProvidedId(this.getPublisherProvidedId());
          }
        }]
      };

      didomiService.checkVendor$(VendorTypes.googleAds).subscribe(consent => {
        const gdprTargeting = consent ?
          [GooglePublisherTagService.DFP_GDPR_CLEAN] :
          [GooglePublisherTagService.DFP_GDPR_CLEAN, GooglePublisherTagService.DFP_GDPR_TRACK];
        this.googletag.cmd.push(() => {
          this.log('gpt setTargeting', GooglePublisherTagService.DFP_GDPR_KEY, gdprTargeting);
          this.googletag.pubads().setTargeting(GooglePublisherTagService.DFP_GDPR_KEY, gdprTargeting);
        });
      });

      this.routeService.routeInfo.subscribe(() => this.clearKAMVideo());

      this.routeService.routeInfo.pipe(
        filter(route => route.dataLayer.AdsEnabled),
      ).subscribe(route => {
        this.setTargeting(route);
      });

      const onStable$ = this.routeService.routeInfo.pipe(
        switchMap((route: IRoute) => this.zone.onStable.asObservable().pipe(
          first(),
          map(() => route),
        )),
        share(),
      );

      onStable$.pipe(
        filter(route => route.dataLayer.AdsEnabled),
      ).subscribe(() => {
        this.displayAds();
      });
    }
  }

  private get googletag(): IGPTManager {
    return this.browserRef.window.googletag;
  }

  private set googletag(googletag: IGPTManager) {
    this.browserRef.window.googletag = googletag;
  }

  public addSlot(model: AdModel) {
    if (model.dimensions.length === 0) {
      return;
    }
    this.definedSlots.set(model.id, model);

    this.routeService.routeInfo.pipe(
      first(),
    ).subscribe(() => {
      this.googletag.cmd.push(() => {
        this.log('gpt defineSlot', model.adUnitPath, model.id);
        model.slot = this.googletag.defineSlot(model.adUnitPath, model.dimensions, model.id);
        if (!model.slot) {
          return;
        }
        model.slot.setCollapseEmptyDiv(true, true);
        model.slot.setForceSafeFrame(model.setForceSafeFrame);
        model.slot.addService(this.googletag.pubads());
      });
    });
  }

  public clearSlot(model: AdModel) {
    this.googletag.cmd.push(() => {
      this.log('gpt clearSlot', model.adUnitPath, model.id);
      this.googletag.pubads().clear([model.slot]);
    });
  }

  public destroySlot(model: AdModel) {
    this.definedSlots.delete(model.id);
    this.googletag.cmd.push(() => {
      this.log('gpt destroySlot', model.adUnitPath, model.id);
      this.googletag.destroySlots([model.slot]);
    });
  }

  public refreshSlot(model: AdModel) {
    this.googletag.cmd.push(() => {
      this.log('gpt refreshSlot', model.adUnitPath, model.id);
      this.googletag.pubads().refresh([model.slot], {changeCorrelator: false});
    });
  }

  clearKAMVideo() {
    // tslint:disable-next-line:no-string-literal
    const fsvReset = this.browserRef.window['fsvReset'];
    if (fsvReset) {
      fsvReset();
    }
  }

  private setTargeting(route: IRoute) {
    const targetingKeys = [
      DataLayerType.AdSection,
      DataLayerType.Channel,
      DataLayerType.Seitentyp,
      DataLayerType.Portal,
      DataLayerType.Section,
      DataLayerType.Kampagne,
      DataLayerType.ArtikelId,
      DataLayerType.Artikeltags,
    ];
    this.googletag.cmd.push(() => {
      this.log('gpt setTargeting', route.dataLayer);
      const neuwo = route.dataLayer.Neuwo;
      if (neuwo) {
        if (neuwo.tags) {
          this.googletag.pubads().setTargeting('ct_topic', neuwo.tags);
        }
        if (neuwo.brand_safety) {
          this.googletag.pubads().setTargeting('ct_bs', neuwo.brand_safety);
        }
        if (neuwo.marketing_categories.iab_tier_1) {
          this.googletag.pubads().setTargeting('ct_iab1', neuwo.marketing_categories.iab_tier_1);
        }
        if (neuwo.marketing_categories.iab_tier_2) {
          this.googletag.pubads().setTargeting('ct_iab2', neuwo.marketing_categories.iab_tier_2);
        }
      } else {
        this.googletag.pubads().clearTargeting('ct_topic');
        this.googletag.pubads().clearTargeting('ct_bs');
        this.googletag.pubads().clearTargeting('ct_iab1');
        this.googletag.pubads().clearTargeting('ct_iab2');
      }
      targetingKeys.forEach((dfp) => {
        if ((typeof route.dataLayer[dfp]) !== 'undefined' && route.dataLayer[dfp] !== null) {
          this.googletag.pubads().setTargeting(dfp, route.dataLayer[dfp]);
        } else {
          if (this.googletag.pubads().getTargeting(dfp).length) {
            this.googletag.pubads().clearTargeting(dfp);
          }
        }
      });
      const aw = 'availWidth';
      if (this.browserRef.window.innerWidth - 985 >= 300) {
        this.googletag.pubads().setTargeting(aw, 'larger_300');
      } else {
        if (this.googletag.pubads().getTargeting(aw).length) {
          this.googletag.pubads().clearTargeting(aw);
        }
      }
    });
  }

  private displayAds() {
    if (this.definedSlots.size > 0) {
      const nextAd = this.definedSlots.values().next().value;
      this.googletag.cmd.push(() => {
        this.log('gpt displayAds', nextAd.id);
        this.googletag.display(nextAd.id);
      });
    }
  }

  private getPublisherProvidedId() {
    let id: string = localStorage.getItem(GooglePublisherTagService.PPID);
    if (!id) {
      id = createRandomId(32);
      localStorage.setItem(GooglePublisherTagService.PPID, id);
    }
    return id;
  }

  private log(message?: any, ...optionalParams: any[]): void {
    if (!this.prodmode) {
      console.log(message, ...optionalParams);
    }
  }
}
