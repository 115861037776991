import {ITeaser, ITeaserImage, ITeaserView} from '../teaser/payload';
import {ImageModel} from './image';
import {BoxModel} from './box';
import {TeaserModel} from '../teaser/teaser';
import {ImageType} from '../enum/image';
import {LinkBarModel} from './linkBar';
import {IBoxComponent, ITitleComponent} from '../payload';
import {PortalRoute} from '@model/teaser/portalRoute';

export class LinkCardModel extends TeaserModel {

  linkbar: LinkBarModel;
  pretitle: string;

  static fromTeasers(teasers: ITeaser[], teaserView: ITeaserView, imageSize = ImageType.LS_16_9, box?: IBoxComponent): LinkCardModel[] {
    return teasers.map(t => LinkCardModel.fromTeaser(t, teaserView, imageSize, box));
  }

  static fromTeaser(teaser: ITeaser,
                    teaserView: ITeaserView,
                    imageSize = ImageType.LS_16_9,
                    box?: IBoxComponent,
                    comp?: ITitleComponent): LinkCardModel {
    const lc = new LinkCardModel();
    lc.title = teaserView.title ? teaser.title : null;
    lc.content = teaserView.teaser_text ? teaser.teaser_text : null;
    lc.link = PortalRoute.fromTeaser(teaser);
    if (teaserView.img && teaser.teaser_img) {
      lc.image = new ImageModel(teaser.teaser_img, imageSize, null, false, false, 'link-image');
    }

    lc.box = box ? new BoxModel(box) : new BoxModel();

    lc.linkbar = LinkBarModel.titleFromTeaser(comp, teaser, lc.box);

    return lc;
  }

  static fromTeaserImages(images: ITeaserImage[], imageSize: ImageType) {
    return images.map(image => {
      const lc = new LinkCardModel();
      lc.title = image.title;
      lc.content = image.description;
      lc.image = new ImageModel(image, imageSize, lc.link, true, false, 'link-image');
      lc.image.hasLightbox = true;
      lc.image.description = '';
      return lc;
    });
  }
}
