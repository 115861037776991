/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i3 from "../../../common/ui/linkBar/linkBar.component";
import * as i4 from "../../../common/teaser/teaser.directive";
import * as i5 from "./singleTeaser.component";
import * as i6 from "../../../../shared/service/teasers.service";
import * as i7 from "../../../../shared/service/device-detection.service";
import * as i8 from "../../../../shared/service/portal.service";
var styles_SingleTeaserComponent = [];
var RenderType_SingleTeaserComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SingleTeaserComponent, data: {} });
export { RenderType_SingleTeaserComponent as RenderType_SingleTeaserComponent };
function View_SingleTeaserComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "section", [["class", "singleTeaser"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 1, "linkbar", [], null, null, null, i2.View_LinkBarComponent_0, i2.RenderType_LinkBarComponent)), i0.ɵdid(6, 49152, null, 0, i3.LinkBarComponent, [], { model: [0, "model"] }, null), (_l()(), i0.ɵeld(7, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(8, 540672, null, 0, i4.TeaserDirective, [i0.ViewContainerRef, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "singleTeaser"; var currVal_1 = _ck(_v, 4, 0, _co.box.cssClass); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.linkbarModel; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.cmModel; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_SingleTeaserComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_SingleTeaserComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.teaser$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SingleTeaserComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "singleteaser", [], null, null, null, View_SingleTeaserComponent_0, RenderType_SingleTeaserComponent)), i0.ɵdid(1, 49152, null, 0, i5.SingleTeaserComponent, [i6.TeasersService, i7.DeviceDetectionService, i8.PortalService, i0.ViewContainerRef, i0.PLATFORM_ID], null, null)], null, null); }
var SingleTeaserComponentNgFactory = i0.ɵccf("singleteaser", i5.SingleTeaserComponent, View_SingleTeaserComponent_Host_0, {}, {}, []);
export { SingleTeaserComponentNgFactory as SingleTeaserComponentNgFactory };
