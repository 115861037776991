import {Inject, Injectable} from '@angular/core';
import {BrowserRef} from '../browser.ref';
import {DOCUMENT} from '@angular/common';
import {IWindow} from './window';
import {IDocument} from './document';
import {INavigator} from './navigator';

@Injectable()
export class BrowserRefBrowser extends BrowserRef {

  constructor(@Inject(DOCUMENT) private internalDocument) {
    super();
  }

  get document(): IDocument {
    return this.internalDocument;
  }

  get navigator(): INavigator {
    return navigator;
  }

  get window(): IWindow {
    return window;
  }


}
