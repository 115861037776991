import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {isPlatformServer} from '@angular/common';
import {StateService} from '@shared/service/state.service';
import {TV_API} from '@shared/service/tokens';

@Injectable()
export class TvInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) protected platformId: string,
    @Optional() @Inject(REQUEST) private request: any,
    private stateService: StateService<string>,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (error.status === 404 && req.url.startsWith(this.stateService.get(TV_API))) {
          if (isPlatformServer(this.platformId)) {
            if (this.request.res) {
              this.request.res.status(404);
            }
          }
          return EMPTY;
        }
        return throwError(error);
      })
    );
  }
}
