import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {BehaviorSubject, merge, Observable, Subject} from 'rxjs';
import {filter, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {LiveblogEntry} from '../../../../model/content/liveblog/liveblogentry';
import {ApiService} from '../../../../shared/service/api.service';
import {PusherService} from '../../../../shared/service/pusher.service';
import {ILiveblog, ILiveblogEntry} from '../../../../model/content/liveblog/payload';
import {LiveblogEvent} from '../../../../model/enum/liveblog';
import {setToolTip} from '@util/toolTip';
import {copyToClipboard} from '@util/copyToClipboard';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'liveblog',
  templateUrl: 'liveblog.component.html'
})
export class LiveblogComponent implements DynamicComponent<ILiveblog>, AfterViewInit, OnDestroy {

  showMoreEntriesButton: boolean;
  entries$: Observable<LiveblogEntry[]>;
  model: ILiveblog;

  private fetchEntries$ = new BehaviorSubject(null);
  private addEntry$ = new BehaviorSubject<{ event: string, entry: LiveblogEntry }>(null);
  private cachedEntries: LiveblogEntry[] = [];
  private destroy$: Subject<boolean> = new Subject<boolean>();

  private liveblogStart = 0;
  private liveblogLimit = 20;
  private channel: any;
  private totalEntries: number;


  constructor(private api: ApiService,
              private pusherService: PusherService) {
  }

  initModel(model: ILiveblog): void {
    this.model = model;
    this.entries$ = merge(this.initFetchEntries(), this.initAddEntry());
  }

  ngAfterViewInit() {
    this.pusherService.init().pipe(take(1)).subscribe(() => {
      const channelName = 'liveblog-cfs-' + this.model.data.liveblog_id;
      this.channel = this.pusherService.onChannel(channelName, (event: string, data: ILiveblogEntry) => {
        const mergedEntry = {event, entry: LiveblogEntry.deserializeItem(data)};
        this.addEntry$.next(mergedEntry);
      });
    });
  }

  private initFetchEntries(): Observable<LiveblogEntry[]> {
    return this.fetchEntries$.pipe(
      takeUntil(this.destroy$),
      switchMap(() => {
        return this.api.liveblogEntries(this.model.data.liveblog_id, this.liveblogStart, this.liveblogLimit).pipe(
          map(data => {
            this.liveblogStart += this.liveblogLimit;
            this.showMoreEntriesButton = data.count >= this.liveblogLimit;
            this.totalEntries = data.total_count;
            this.cachedEntries = this.cachedEntries.concat(LiveblogEntry.deserializeJson(data.items));
            return this.cachedEntries;
          }),
        );
      })
    );
  }

  fetchLiveBlogEntries() {
    this.fetchEntries$.next(true);
  }

  private initAddEntry(): Observable<LiveblogEntry[]> {
    return this.addEntry$.pipe(
      filter(Boolean)).pipe(
      takeUntil(this.destroy$),
      map(newentry => {
        if (newentry.event === LiveblogEvent.add) {
          this.liveblogStart++;
          this.cachedEntries.unshift(newentry.entry);
        }

        if (newentry.event === LiveblogEvent.edit) {
          this.cachedEntries = this.cachedEntries.map(entry => {
            return entry.id === newentry.entry.id ? newentry.entry : entry;
          });
        }

        if (newentry.event === LiveblogEvent.delete) {
          this.cachedEntries = this.cachedEntries.filter(entry => {
            return entry.id !== newentry.entry.id;
          });
        }

        return this.cachedEntries;
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.channel) {
      this.pusherService.unsubscribe(this.channel);
    }
  }

  copyToClipboard(event: MouseEvent, id: string) {
    const url = window.location.href.split('#')[0];
    const str = url + '#' + id;
    copyToClipboard(str);
    setToolTip(event, 'link kopiert!');
  }
}
