import {catchError, finalize, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CacheService} from './cache.service';
import {PortalService} from './portal.service';
import {Observable, of, throwError} from 'rxjs';
import {ENDPOINT_OPTIONS} from './tokens';
import * as buildUrl from 'build-url';
import {EndpointOptions} from '../../model/environment';
import {StateService} from './state.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ITeaser, ITeaserCollection} from '../../model/teaser/payload';
import {IHoroscope} from '../../model/horoscope/payload';
import {IGallery, IRoute} from '../../model/payload';
import {IResult} from '../../model/search/payload';
import {ILiveblogItems} from '../../model/content/liveblog/payload';
import {IScreeningsResponse} from '../../model/channel/screenings/payload';
import {IMovieCollection} from '../../model/movieSerie/payload';
import {MovieListOrder} from '../../model/enum/movie';
import {LoadingService} from './loading.service';
import {IMenu} from '../../model/menu/payload';
import {SearchOrder} from '../../model/enum/search';

export interface IHttpOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  private readonly endpoint: string;

  constructor(private stateService: StateService<EndpointOptions>,
              private http: HttpClient,
              private cache: CacheService,
              private loadingService: LoadingService,
              private portalService: PortalService) {
    const endpointOptions: EndpointOptions = stateService.get(ENDPOINT_OPTIONS);
    this.endpoint = ApiService.createEndpoint(endpointOptions, portalService);
  }

  public static createEndpoint(endpointOptions: EndpointOptions, portalService: PortalService) {
    switch (endpointOptions.type) {
      case 'fixed':
        return endpointOptions.endpoint + '/api/v1/cfs';

      case 'derived':
        return 'https://' + portalService.subdomain('efs') + '/api/v1/cfs';

      case 'staging':
        return 'https://' + portalService.subdomain('efs-stage') + '/api/v1/cfs';

      default:
        throw new Error('Unhandled endpointOptions.type ' + endpointOptions.type);
    }
  }

  movieCollection(collection: string, start: number, limit: number, genre?: string, term?: string,
                  order = MovieListOrder.releaseDate): Observable<IMovieCollection> {
    let url = `${this.endpoint}/collection/${encodeURI(collection)}?start=${start}&limit=${limit}&order=${order}`;
    if (genre) {
      url += `&genre=${encodeURI(genre)}`;
    }
    if (term && term !== '') {
      url += `&term=${encodeURI(term)}`;
    }
    return this.getCached(url, true);
  }

  collectionTeasers(collection: string, start: number, limit: number): Observable<ITeaserCollection> {
    return this.getUncached(this.endpoint + '/collection/' + encodeURI(collection) + '?start=' + start + '&limit=' + limit, true);
  }

  collectionTeasersCached(collection: string, start: number, limit: number): Observable<ITeaserCollection> {
    return this.getCached(this.endpoint + '/collection/' + encodeURI(collection) + '?start=' + start + '&limit=' + limit, true);
  }

  singleHoroscope(sign: string): Observable<IHoroscope> {
    return this.getCached(this.endpoint + '/zodiac/' + sign, true);
  }

  singleTeaser(id: string): Observable<ITeaser> {
    return this.getCached(this.endpoint + '/teaser/' + id, true);
  }

  articleRelateds(docId: string, limit: number): Observable<ITeaserCollection> {
    return this.getCached(this.endpoint + '/relateds/article/' + docId + '?limit=' + limit, false);
  }

  sectionRelateds(section: string, limit: number): Observable<ITeaserCollection> {
    return this.getCached(this.endpoint + '/relateds/section/' + section + '?limit=' + limit, false);
  }

  channelRelateds(channel: string, limit: number): Observable<ITeaserCollection> {
    return this.getCached(this.endpoint + '/relateds/channel/' + channel + '?limit=' + limit, false);
  }

  liveblogEntries(liveblogId: number, start: number, limit: number): Observable<ILiveblogItems> {
    return this.getCached(this.endpoint + '/liveblog_posts/' + liveblogId + '?start=' + start + '&limit=' + limit, true);
  }

  gallery(galleryId: string, limit: number, articleId: string): Observable<IGallery> {
    const url = this.endpoint + '/gallery/' + galleryId + '?limit=' + limit + '&articleId=' + articleId;
    return this.getCached(url, true);
  }

  searchByTerm(term: string, ordering: string, pageSize: number, page: number, portal?: string): Observable<IResult> {

    const url = buildUrl(this.endpoint, {
      path: 'search',
      queryParams: {
        term,
        order: ordering,
        limit: pageSize.toString(),
        page: page.toString(),
        portal,
      }
    });
    return this.getCached(url, false);
  }

  searchByQuery(query: string, ordering: string, pageSize: number, page: number): Observable<IResult> {

    const url = buildUrl(this.endpoint, {
      path: 'search',
      queryParams: {
        query: encodeURI(query),
        order: ordering,
        limit: pageSize.toString(),
        page: page.toString(),
        portal: this.portalService.hostname(),
      }
    });

    return this.getCached(url, false);
  }

  searchByTopic(topic: string, ordering: string, pageSize: number, page: number): Observable<IResult> {

    const url = buildUrl(this.endpoint, {
      path: 'search',
      queryParams: {
        topic: encodeURIComponent(topic),
        order: ordering,
        limit: pageSize.toString(),
        page: page.toString(),
      }
    });

    return this.getCached(url, false);
  }

  searchByChannel(channel: string, pageSize: number, page: number): Observable<IResult> {
    const url = buildUrl(this.endpoint, {
      path: 'search',
      queryParams: {
        channel: encodeURIComponent(channel),
        limit: pageSize.toString(),
        order: SearchOrder.ORDER_RELEVANCE,
        page: page.toString(),
      }
    });

    return this.getCached(url, false);
  }

  screenings(type: string, date: string, id?: number, city?: string, tags?: string): Observable<IScreeningsResponse> {
    const q = [];
    if (city) {
      q.push(`city=${city}`);
    }
    if (tags) {
      q.push(`tags=${tags}`);
    }
    const query = q.length ? '?' + q.join('&') : '';
    if (type && id) {
      return this.getCached(this.endpoint + `/filmat/screenings/flat/${type}/${id}/${date}${query}`, false);
    }
    return this.getCached(this.endpoint + `/filmat/screenings/nested/${type}/${date}${query}`, false);
  }

  route(uri: string): Observable<IRoute> {
    return this.getCached(this.endpoint + '/route?uri=' + uri, true);
  }

  menu(portal: string, name: string): Observable<IMenu[]> {
    return this.getCached(this.endpoint + '/menu/' + portal + '-' + name, false);
  }

  public getCached<T>(url: string, showLoading: boolean, options?: IHttpOptions): Observable<T> {
    const key = url;

    // use cache to transmit state from server to client and prevent flickering
    if (this.cache.has(key)) {
      return of(this.cache.get(key)).pipe(
        take(1),
        tap(() => this.loadingService.stopLoading()),
      );
    }


    return this.getUncached<T>(url, showLoading, options).pipe(
      tap(data => {
        this.cache.set(key, data);
      }),
    );
  }

  protected getUncached<T>(url: string, showLoading: boolean, options?: IHttpOptions): Observable<T> {
    if (showLoading) {
      this.loadingService.startLoading(true);
    }
    const key = url;
    const obs$ = this.http.get<T>(url, options);
    // NOTE sometimes angular will cancel requests if they're fired too quickly in succession
    // https://stackoverflow.com/questions/45844426/angular-4-a-series-of-http-requests-get-cancelled
    // unfortunately this will result in double requestsw
    // obs$.take(1).subscribe(res => this.loadingService.stopLoading());
    return obs$.pipe(
      catchError(err => {
        const detail = err.message || err.statusText;
        console.warn('API Error (' + url + '): ' + detail);
        this.cache.set(key, []);
        this.loadingService.stopLoading();
        return throwError(err);
      }),
      tap(() => {
        this.loadingService.stopLoading();
      }),
      finalize(() => {
        this.loadingService.stopLoading();
      }),
    );
  }
}
