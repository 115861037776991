import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {LoadExternalScriptService} from '../../../../shared/service/loadExternalScript.service';
import {IPinterest} from '../../../../model/content/payload';
import {ScriptId} from '../../../../model/enum/scriptId';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'pinterestprofileorboard',
  templateUrl: 'pinterestprofileorboard.component.html'
})
export class PinterestBoardOrProfilComponent implements DynamicComponent<IPinterest> {

  size: string;
  url: string;
  dataPinDo: string;

  constructor(private loadScript: LoadExternalScriptService) {
  }

  initModel(model: IPinterest): void {
    this.size = model.data.size;
    this.url = model.data.url;
    if (model.type === 'pinterest_board') {
      this.dataPinDo = 'embedBoard';
    } else if (model.type === 'pinterest_profile') {
      this.dataPinDo = 'embedUser';
    }

    this.loadScript.loadScript(ScriptId.pinterest);
  }
}
