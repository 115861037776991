import {PortalService} from '../../shared/service/portal.service';
import {PortalRoute} from '../teaser/portalRoute';

export class PortalMenuItem {

  constructor(
    public link: string,
    public title: string,
    public slug?: string,
    public target: string = '_self',
  ) {
  }

  static deserialize(pS: PortalService, portal: string): PortalMenuItem {
    const pR = PortalRoute.fromHostname(portal, '/');
    const link = pS.absoluteUrlOf(pR);
    const title = pS.hostnameOf(pR);
    let target = '';
    if (!link.startsWith('/')) {
      target = '_blank';
    }
    return new PortalMenuItem(link, title, portal, target);
  }

  static portalNav(pS: PortalService): PortalMenuItem[] {
    const portalSlugs = [
      'kurierat',
      'eventsat',
      'motorat',
      'futurezoneat',
      'filmat',
      'kat',
      'kuriertv',
      'profilat',
      'lustaufoesterreichat'
    ];
    let portalItems: PortalMenuItem[];
    portalItems = portalSlugs.map((portal: string): PortalMenuItem => {
      return PortalMenuItem.deserialize(pS, portal);
    });
    if (pS.currentPortal() === 'kurierat') {
      portalItems.unshift(
        new PortalMenuItem(
          'https://freizeit.at/',
          'freizeit.at',
          'freizeit',
          '_blank')
      );
      portalItems.push(
        new PortalMenuItem(
          'https://phase6.kurier.at/',
          'phase6',
          'phase6',
          '_blank'),
        new PortalMenuItem(
          '/lernhaus',
          'Lernhaus',
          'lernhaus'),
        new PortalMenuItem(
          'https://www.bazar.at/?utm_source=kurier&utm_medium=referral&utm_campaign=kurier-link',
          'BAZAR',
          'bazar',
          '_blank'),
        new PortalMenuItem(
          'https://www.immmo.at/?utm_source=kurier&utm_medium=referral&utm_campaign=kurier-link',
          'IMMMO',
          'immmo',
          '_blank'),
        new PortalMenuItem(
          'https://partnersuche.kurier.at/',
          'Partnersuche',
          'partnersuche',
          '_blank'),
        new PortalMenuItem(
          'https://spiele.kurier.at/',
          'Spiele',
          'spiele',
          '_blank'),
      );
    }
    if (pS.currentPortal() === 'lustaufoesterreichat') {
      portalItems.unshift(
        new PortalMenuItem(
          'https://kurier.at/reise',
          'Kurier Reise',
          'kurierat',
          '_blank'),
      );
    }
    return portalItems;
  }
}
