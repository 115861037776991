import {Inject, Injectable, isDevMode, PLATFORM_ID} from '@angular/core';
import {makeStateKey, StateKey, TransferState} from '@angular/platform-browser';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {NavigationStart, Router} from '@angular/router';

const STATE_KEY: StateKey<Map<string, any>> = makeStateKey('CACHE');

@Injectable({
  providedIn: 'root',
})
export class CacheService {

  protected onSerializeMapCallbacks = {};

  protected cache: Map<string, any>;

  constructor(@Inject(PLATFORM_ID) protected platformId: string,
              protected router: Router,
              protected transferState: TransferState) {
    this.cache = this.warmup();

    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationStart && event.id > 1) {
          this.cache = new Map();
        }
      });
    }
  }

  has(key: string | number): boolean {
    const normKey = this.normalizeKey(key);
    return this.cache.has(normKey);
  }

  set(key: string | number, value: any): void {
    const normKey = this.normalizeKey(key);
    this.cache.set(normKey, value);
  }

  get(key: string | number): any {
    const normKey = this.normalizeKey(key);
    return this.cache.get(normKey);
  }

  protected warmup(): Map<string, any> {
    const cache = new Map();
    if (isPlatformBrowser(this.platformId)) {
      if (this.transferState.hasKey(STATE_KEY)) {
        const cachedState = this.transferState.get(STATE_KEY, new Map());
        Object.keys(cachedState).forEach((key: string) => {
          const normKey = this.normalizeKey(key);
          const value = cachedState[normKey];
          cache.set(normKey, value);
        });
      }
    } else if (isPlatformServer(this.platformId)) {
      this.transferState.onSerialize(STATE_KEY, () => {
        for (const key in this.onSerializeMapCallbacks) {
          if (this.onSerializeMapCallbacks.hasOwnProperty(key)) {
            const callback = this.onSerializeMapCallbacks[key];
            const map = callback();
            const cachedMap = {};
            map.forEach((v, k) => cachedMap[k] = v);
            this.cache.set(key, cachedMap);
          }
        }

        const cachedState = {};
        this.cache.forEach((value: any, key: string) => cachedState[key] = value);
        return cachedState;
      });
    }
    return cache;
  }

  getMap<K, V>(key: string | number): Map<K, V> {
    const map = new Map();
    if (this.has(key)) {
      const cached = this.get(key);
      for (const k of Object.keys(cached)) {
        map.set(k, cached[k]);
      }
    }
    return map;
  }

  onSerializeMap<K, V>(key: string, callback: () => Map<K, V>): void {
    this.onSerializeMapCallbacks[key] = callback;
  }

  /**
   * convert numbers into strings
   */
  protected normalizeKey(key: string | number): string {
    if (isDevMode() && this._isInvalidValue(key)) {
      throw new Error('Please provide a valid key to save in the CacheService');
    }

    return key + '';
  }

  protected _isInvalidValue(key: string | number): boolean {
    return key === null ||
      key === undefined ||
      key === 0 ||
      key === '' ||
      typeof key === 'boolean' ||
      Number.isNaN(key as number);
  }
}
