import {Component, Input} from '@angular/core';
import TagModel from '../../../../model/teaser/tag';

@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
})
export class TagComponent {
  @Input() public model: TagModel;
}
