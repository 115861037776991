<ng-container *ngIf="model && model.data">
  <div class="footballLiveScores" *ngFor="let item of model.data">
    <header>
      <h2 class="footballLiveScores-header">
        <span class="game-date">Today, {{getTime(item.time.starting_at.time)}}</span>
        <span class="game-location">{{ getVenueById(item.venue_id) | async }}</span>
      </h2>
    </header>

    <div class="footballLiveScores-game-info"
         *ngIf="getTeamsById(item.localteam_id, item.visitorteam_id) | async as teams">
      <div class="footballLiveScores-team local-team">
        <img class="team-logo" src="{{teams[0].logo_path}}" alt="{{teams[0].name}} logo">
        <p class="team-name">{{teams[0].name}}</p>
      </div>
      <div class="footballLiveScores-versus">
        <p>vs</p>
      </div>
      <div class="footballLiveScores-team">
        <img  class="team-logo" src="{{teams[1].logo_path}}" alt="{{teams[1].name}} logo">
        <p class="team-name">{{teams[1].name}}</p>
      </div>
    </div>
  </div>
</ng-container>
