import {ITeaser, ITeaserImage, ITeaserView} from './payload';
import {TimeAgoUtil} from '@util/timeAgo';
import {PortalRoute} from './portalRoute';
import {Author} from '../author/author';
import {Channel} from './channel';
import {PathTree} from './pathTree';
import {BoxModel} from '../component/box';
import {ImageModel} from '../component/image';
import {BoxColor, BoxStyle} from '../enum/box';
import {GTMTriggerId} from '../enum/gtm';
import {ImageType} from '../enum/image';
import {IDynamicComponent} from '../payload';
import {TeaserView} from './teaserView';
import TagModel from './tag';
import {TeaserType} from '@model/teaser/type';
import {MovieSerieModel} from '@model/movieSerie/movieSerie';
import {IMovieSerie} from '@model/movieSerie/payload';

export class TeaserModel {

  title: string;
  link: PortalRoute;
  image: ImageModel;
  gtmId: GTMTriggerId;
  pretitleGtmId = GTMTriggerId.teaser_pretitle;
  classNames: string[] = ['teaser'];
  tags: TagModel[] = [];
  tagLine?: PathTree[] = [];
  author: Author;
  type: TeaserType;
  box = new BoxModel();
  content?: string;
  movieSerie?: MovieSerieModel;


  get classes(): string {
    return this.classNames.join(' ');
  }

  static fromCardTeasers(teasers: ITeaser[],
                         teaserView: ITeaserView,
                         imageSize?: ImageType,
                         box?: BoxModel): TeaserModel[] {
    return teasers.map(teaser => this.fromCardTeaser(teaser, teaserView, imageSize, box));
  }

  static fromCardTeaser(teaser: ITeaser,
                        teaserView: ITeaserView,
                        imageSize?: ImageType,
                        box?: BoxModel): TeaserModel {
    if (teaser && teaser.channel && teaser.channel.url === '/kurierat/meinung') {
      return TeaserModel.opinion(teaser);
    }
    if (teaserView.hero) {
      return TeaserModel.hero(teaser, imageSize);
    }
    if (teaser.type === 'movie' || teaser.type === 'series' || teaserView.small_poster_view || teaserView.big_poster_view) {
      return TeaserModel.movie(teaser as IMovieSerie, teaserView);
    }
    let imageOnly = true;
    Object.keys(teaserView).forEach(key => {
      if (key !== 'img') {
        if (teaserView[key]) {
          imageOnly = false;
        }
      }
    });
    if (imageOnly) {
      return TeaserModel.imageOnlyTeaser(teaser, imageSize);
    }
    return TeaserModel.card(teaser, teaserView, imageSize, box);
  }

  static fromMediaTeasers(teasers: ITeaser[],
                          teaserView: ITeaserView,
                          mobile: boolean,
                          model?: IDynamicComponent): TeaserModel[] {
    return teasers.map(teaser => this.fromMediaTeaser(teaser, teaserView, mobile, model));
  }

  static movie(movie: IMovieSerie, teaserView: ITeaserView): TeaserModel {
    const t = new TeaserModel();
    t.type = TeaserType.movie;
    t.movieSerie = MovieSerieModel.deserialize(movie);
    if (teaserView.big_poster_view) {
      t.movieSerie.classNames.push('bigPoster');
    }
    if (teaserView.small_poster_view) {
      t.movieSerie.classNames.push('smallPoster');
    }
    return t;
  }

  static imageOnlyTeaser(teaser: ITeaser, imageSize?: ImageType): TeaserModel {
    const t = new TeaserModel();
    t.type = TeaserType.imageOnly;
    t.link = PortalRoute.fromTeaser(teaser);
    if (teaser.teaser_img) {
      t.image = new ImageModel(teaser.teaser_img, imageSize, t.link, false, false, 'teaser-image');
      t.image.setVideo(teaser);
    }
    if (teaser.teaser_video && teaser.teaser_video.fallback_image) {
      t.image = new ImageModel(teaser.teaser_video.fallback_image, imageSize, t.link, false, false, 'teaser-image');
    }
    return t;
  }

  static card(
    teaser: ITeaser,
    teaserView: ITeaserView,
    imageSize?: ImageType,
    box?: BoxModel,
  ): TeaserModel {
    const t = TeaserModel.baseFromTeaser(teaser, teaserView, box);
    t.type = TeaserType.card;
    if (teaser.teaser_img) {
      t.image = new ImageModel(teaser.teaser_img, imageSize, t.link, false, false, 'teaser-image');
      t.image.setVideo(teaser);
    }
    if (teaser.teaser_video && teaser.teaser_video.fallback_image) {
      t.image = new ImageModel(teaser.teaser_video.fallback_image, imageSize, t.link, false, false, 'teaser-image');
    }
    t.type = TeaserType.card;
    TeaserModel.setBadge(t, teaser);
    return t;
  }

  static hero(teaser: ITeaser,
              imageSize?: ImageType,
  ): TeaserModel {
    const t = new TeaserModel();
    if (teaser.portal === 'freizeit.at') {
      t.classNames.push('pB-freizeitat-circle');
    } else if (teaser.portal) {
      t.classNames.push('pB-' + teaser.portal.replace('.', ''));
    }
    TeaserModel.setPaid(t, teaser);
    TeaserModel.setBadge(t, teaser);
    t.type = TeaserType.hero;
    t.title = teaser.title;
    t.link = PortalRoute.fromTeaser(teaser);
    if (teaser.pretitle) {
      t.tags.push(new TagModel(teaser.pretitle));
    }
    if (teaser.teaser_img) {
      t.image = new ImageModel(teaser.teaser_img, imageSize, t.link, false, false, 'teaser-image');
    }
    if (teaser.teaser_video && teaser.teaser_video.fallback_image) {
      t.image = new ImageModel(teaser.teaser_video.fallback_image, imageSize, t.link, false, false, 'teaser-image');
    }
    return t;
  }

  static fromTextTeaser(teaser: ITeaser): TeaserModel {
    const m = new TeaserModel();
    m.title = teaser.title;
    m.link = PortalRoute.fromTeaser(teaser);
    m.type = TeaserType.text;
    return m;
  }

  static fromMediaTeaser(teaser: ITeaser,
                         teaserView: ITeaserView,
                         mobile: boolean,
                         model?: IDynamicComponent): TeaserModel {
    const t = TeaserModel.baseFromTeaser(teaser, teaserView);
    t.type = TeaserType.media;
    teaserView.teaser_text ? t.classNames.push('hasContent') : t.classNames.push('noContent');
    if (teaser.display_authors) {
      t.classNames.push('smallImage isAuthor');
      t.author = TeaserModel.author(teaser);
    }
    if (teaserView.img && (teaser.teaser_img || teaser.display_authors || teaser.teaser_video)) {
      const image = teaser.display_authors ? t.author.picture : (teaser.teaser_img || teaser.teaser_video.fallback_image);
      let imageSize: ImageType;
      if (teaser.display_authors || (model && model.position === 'left') || mobile) {
        imageSize = ImageType.SQ;
      } else {
        imageSize = ImageType.LS_16_9;
      }
      if (image) {
        t.image = new ImageModel(image, imageSize, t.link, false, teaser.display_authors, '');
      }
      t.classNames.push('hasImage');
    } else {
      t.classNames.push('noImage');
    }
    return t;
  }

  static fromChannelRelateds(t: ITeaser[]) {
    const tV = new TeaserView(true, true, true, false);
    const teaser = t.slice(0, 4);
    return TeaserModel.fromCardTeasers(teaser, tV, ImageType.LS_16_9);
  }

  static authors(teaser: ITeaser): Author[] {
    if (teaser.authors) {
      return Author.deserializeJson(teaser.authors, teaser.portal);
    }
  }

  static author(teaser: ITeaser): Author {
    if (teaser.authors && teaser.authors.length) {
      return Author.deserializeItem(teaser.authors[0], teaser.portal);
    }
  }

  static channel(teaser: ITeaser): Channel {
    if (teaser.channel) {
      return Channel.deserializeJson(teaser.channel);
    }
  }

  static poweredByLogo(teaser: ITeaser): ITeaserImage {
    return {
      url: teaser.powered_by.logo,
    };
  }

  static date(teaser: ITeaser): string {
    return TimeAgoUtil.getDate(teaser.published_date) || '';
  }

  private static setBadge(teaser: TeaserModel, payload: ITeaser) {
    if (payload.badges && !payload.teaser_video && !payload.paid_content) {
      let pH = 'picimage-badge ';
      const badgesArr = ['live', 'video'];
      const diff = badgesArr.filter(badge => payload.badges.indexOf(badge) >= 0);
      pH += diff[0] + '-badge';
      teaser.classNames.push(pH);
    }
  }

  private static setPaid(m: TeaserModel, teaser: ITeaser) {
    if (teaser.paid_content && teaser.portal === 'kurier.at') {
      m.tags.push(new TagModel(null, PortalRoute.fromUri('/kurierat/plus'), 'paid'));
    }
  }

  private static baseFromTeaser(teaser: ITeaser,
                                teaserView: ITeaserView,
                                box?: BoxModel): TeaserModel {
    const m = new TeaserModel();
    m.title = teaser.title;
    if (teaserView.teaser_text && teaser.teaser_text) {
      m.content = teaser.teaser_text;
    }
    m.link = PortalRoute.fromTeaser(teaser);
    const channel = TeaserModel.channel(teaser);
    m.box = box ? box : BoxModel.fromChannel(channel);

    if (teaser.portal) {
      m.classNames.push('pB-' + teaser.portal.replace('.', ''));
    }

    TeaserModel.setPaid(m, teaser);

    if (teaser.sponsored) {
      m.box = new BoxModel({style: BoxStyle.sponsored, color: BoxColor.standard});
    }

    if (teaser.pretitle) {
      m.tagLine = [new PathTree(teaser.url, teaser.pretitle, PortalRoute.fromTeaser(teaser))];
    } else {
      if (channel) {
        m.tagLine = channel.pathtree;
      }
    }

    return m;
  }

  private static opinion(teaser: ITeaser) {
    const t = new TeaserModel();
    t.type = TeaserType.opinion;
    t.title = teaser.title;
    t.link = PortalRoute.fromTeaser(teaser);
    TeaserModel.setPaid(t, teaser);
    if (teaser.pretitle) {
      t.tags.push(new TagModel(teaser.pretitle));
    } else {
      const channel = TeaserModel.channel(teaser);
      if (channel) {
        t.tagLine = channel.pathtree;
      }
    }
    if (teaser.authors && teaser.authors.length) {
      t.author = TeaserModel.author(teaser);
    }
    if (t.author && t.author.picture) {
      t.image = new ImageModel(t.author.picture, ImageType.SQ, t.link, false, true);
    }
    return t;
  }
}
