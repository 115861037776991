import { ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { combineLatest, interval, Subject } from 'rxjs';
import { distinctUntilChanged, filter, share, skip, skipWhile, take, takeUntil, tap } from 'rxjs/operators';
import { GooglePublisherTagService } from '@shared/ads/gpt.service';
import { AdModel } from '@model/ad/ad-model';
import { RouteService } from '@shared/service/route.service';
import { PortalService } from '@shared/service/portal.service';
import { DidomiService } from '@shared/service/didomi.service';
import { VendorTypes } from '@model/enum/vendorTypes';
import { PianoService } from '@shared/service/piano/piano.service';
import { DeviceDetectionService } from '@shared/service/device-detection.service';
var GptAdDirective = /** @class */ (function () {
    function GptAdDirective(platformId, routeService, element, renderer, gpt, portalService, didomiService, piano, deviceDetectionService) {
        this.platformId = platformId;
        this.element = element;
        this.renderer = renderer;
        this.gpt = gpt;
        this.portalService = portalService;
        this.didomiService = didomiService;
        this.piano = piano;
        this.deviceDetectionService = deviceDetectionService;
        this.destroy$ = new Subject();
        this.initialized = false;
        this.route$ = routeService.routeInfo.pipe(filter(Boolean), takeUntil(this.destroy$), skipWhile(function (route) { return !route.dataLayer.AdsEnabled; }), share());
    }
    Object.defineProperty(GptAdDirective.prototype, "isFixedCampaign", {
        get: function () {
            return this.element.nativeElement.querySelectorAll('[data-kam-is-fix-campaign]').length !== 0;
        },
        enumerable: true,
        configurable: true
    });
    GptAdDirective.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest([
            this.didomiService.checkVendor$(VendorTypes.googleAds),
            this.piano.checkAdFree(),
        ])
            .pipe(takeUntil(this.destroy$), tap(function (res) {
            var adConsent = res[0];
            var adFree = res[1];
            if (adConsent && !adFree) {
                _this.initialize();
            }
            else {
                _this.gpt.destroySlot(_this.model);
                _this.initialized = false;
            }
        })).subscribe();
        this.piano.checkAdReduced().pipe(takeUntil(this.destroy$), tap(function (access) {
            if (access) {
                _this.gpt.destroySlot(_this.model);
                var allowedAds = ['adMobileBanner', 'adInContent', 'adFullBanner'];
                if (_this.deviceDetectionService.maxWidth('1123px')) {
                    allowedAds.push('adMediumRectangle');
                }
                else {
                    allowedAds.push('adSkyscraper');
                }
                if ((allowedAds.includes(_this.model.baseId) && _this.model.adUnitPath) ||
                    (_this.model.adUnitPath && _this.model.adUnitPath.includes('kurierat_mediumrectangle1'))) {
                    if (!_this.model.adUnitPath.includes('_abo_')) {
                        _this.model.adUnitPath = _this.model.adUnitPath.replace('kurierat', 'kurierat_abo');
                        _this.setId();
                        _this.gpt.addSlot(_this.model);
                        _this.gpt.refreshSlot(_this.model);
                    }
                }
            }
        })).subscribe();
    };
    GptAdDirective.prototype.initialize = function () {
        if (!this.initialized) {
            this.initAddSlot();
            this.initToggleAdsEnabled();
            this.initToggleSpecial();
            this.initRefreshTimeout();
            this.gpt.refreshSlot(this.model);
            this.initialized = true;
        }
    };
    GptAdDirective.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.gpt.destroySlot(this.model);
    };
    /**
     * Once ads enabled, add slot
     */
    GptAdDirective.prototype.initAddSlot = function () {
        var _this = this;
        this.route$.pipe(take(1)).subscribe(function (route) {
            _this.model.setAdUnitPath(route);
            _this.setId();
            _this.gpt.addSlot(_this.model);
        });
    };
    /**
     * Toggle AdsEnabled
     */
    GptAdDirective.prototype.initToggleAdsEnabled = function () {
        var _this = this;
        // disable ads
        this.route$.pipe(distinctUntilChanged(null, function (route) { return route.dataLayer.AdsEnabled; }), filter(function (route) { return !route.dataLayer.AdsEnabled; })).subscribe(function () {
            _this.gpt.clearSlot(_this.model);
        });
        // re-enable regular ads
        this.route$.pipe(skipWhile(function (route) { return route.dataLayer.AdsEnabled; }), distinctUntilChanged(null, function (route) { return route.dataLayer.AdsEnabled; }), filter(function (route) { return route.dataLayer.AdsEnabled && !route.dataLayer.Kampagne; })).subscribe(function () {
            _this.gpt.refreshSlot(_this.model);
        });
    };
    /**
     * Toggle special ad unit paths
     */
    GptAdDirective.prototype.initToggleSpecial = function () {
        var _this = this;
        this.route$.pipe(filter(function (route) { return route.dataLayer.AdsEnabled; }), distinctUntilChanged(null, function (route) { return route.dataLayer.Kampagne; }), skip(1)).subscribe(function (route) {
            _this.gpt.destroySlot(_this.model);
            _this.model.setAdUnitPath(route);
            _this.setId();
            _this.gpt.addSlot(_this.model);
        });
    };
    /**
     * Refresh ads on navigation
     */
    GptAdDirective.prototype.initRefreshTimeout = function () {
        var _this = this;
        if (this.model.refresh) {
            this.route$.pipe(takeUntil(this.destroy$)).subscribe(function () {
                _this.gpt.refreshSlot(_this.model);
            });
            interval(30 * 1000)
                .pipe(takeUntil(this.destroy$))
                .subscribe(function () {
                var documentVisible = _this.gpt.documentVisible.getValue();
                if (!_this.isFixedCampaign && documentVisible) {
                    _this.gpt.refreshSlot(_this.model);
                }
            });
        }
    };
    GptAdDirective.prototype.setId = function () {
        this.model.assignId();
        this.renderer.setAttribute(this.element.nativeElement, 'id', this.model.id);
    };
    return GptAdDirective;
}());
export { GptAdDirective };
