import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {RelatedService} from '../../../../shared/service/related.service';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ITeaser} from '../../../../model/teaser/payload';
import {LonglistModel} from '../../../../model/component/longlist/longlist';
import {IChannelRelateds} from '../../../../model/content/channelRelateds/payload';
import {TeaserModel} from '../../../../model/teaser/teaser';
import {LinkBarModel} from '../../../../model/component/linkBar';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'channelRelateds',
  templateUrl: 'channelRelateds.component.html'
})
export class ChannelRelatedsComponent implements DynamicComponent<IChannelRelateds> {

  teasers$: Observable<ITeaser[]>;
  longlistModel: LonglistModel;
  multiTeasers: TeaserModel[];
  linkBarModel: LinkBarModel;

  constructor(protected readonly relatedService: RelatedService) {
  }

  initModel(model: IChannelRelateds): void {
    this.teasers$ = this.relatedService.forChannel(model.channelId, +model.numberOfTeasers + 1).pipe(
      map(teasers => {
        return teasers.filter(teaser => teaser.id !== model.articleId);
      }),
      tap(t => {
        if (model.displayType === 'doubleTeaser') {
          this.linkBarModel = new LinkBarModel(model.title, '', true);
          this.multiTeasers = TeaserModel.fromChannelRelateds(t);
        } else {
          this.longlistModel = LonglistModel.fromChannelRelateds(model, t);
        }
      }),
    );
  }
}
