import {channelIdfromMenu} from '../../util/channelUtil';
import {PortalRoute} from '../teaser/portalRoute';
import {IMenu} from './payload';

export class MenuModel {

  title: string;
  id: string;
  link: string;
  children: MenuModel[];
  portalRoute: PortalRoute;
  isParent: boolean;
  cssClass = '';

  static deserializeJson(json: IMenu[]): MenuModel[] {
    if (!json) {
      return [];
    }
    return json.map(item => {
      const m = new MenuModel();
      m.title = item.title;
      m.link = item.link;
      const css = channelIdfromMenu(m.link);
      if (css) {
        m.cssClass = 'bs-c-' + css;
      }
      m.portalRoute = PortalRoute.fromUri(item.link);
      if (item.children && item.children.length > 0) {
        m.children = MenuModel.deserializeJson(item.children);
      }
      m.isParent = m.children && m.children.length > 0;
      m.id = item.title.toLowerCase().replace(' ', '');
      return m;
    });
  }

  isActive(currentUri: string): boolean {
    return currentUri && currentUri.indexOf(this.link) >= 0;
  }
}

