import { ElementRef, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserRef } from '../service/browser.ref';
import { PortalRoute } from '../../model/teaser/portalRoute';
import { PortalService } from '../service/portal.service';
/**
 * see https://github.com/angular/angular/blob/2.2.x/modules/@angular/router/src/directives/router_link.ts
 */
var PortalRouteDirective = /** @class */ (function () {
    function PortalRouteDirective(router, el, portalService, browserRef) {
        this.router = router;
        this.el = el;
        this.portalService = portalService;
        this.browserRef = browserRef;
    }
    Object.defineProperty(PortalRouteDirective.prototype, "portalRouterLink", {
        set: function (targetRoute) {
            this.targetRoute = targetRoute;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PortalRouteDirective.prototype, "urlTree", {
        get: function () {
            return this.router.createUrlTree([this.targetRoute.path], { fragment: this.targetRoute.hash });
        },
        enumerable: true,
        configurable: true
    });
    PortalRouteDirective.prototype.ngOnChanges = function (changes) {
        this.updateTargetUrlAndHref();
    };
    PortalRouteDirective.prototype.onClick = function (button, ctrlKey, metaKey) {
        if (this.el.nativeElement.preventPortalRouterLink === true) {
            return false;
        }
        if (button !== 0 || ctrlKey || metaKey) {
            return true;
        }
        if (typeof this.target === 'string' && this.target !== '_self') {
            return true;
        }
        if (!this.targetRoute) {
            return true;
        }
        this.router.navigate([this.targetRoute.path], {
            preserveQueryParams: true
        });
        this.browserRef.window.scrollTo(0, 0);
        return false;
    };
    PortalRouteDirective.prototype.updateTargetUrlAndHref = function () {
        if (!this.targetRoute) {
            this.href = '#';
            return;
        }
        if (this.portalService.samePortal(this.targetRoute)) {
            this.href = this.router.serializeUrl(this.urlTree);
            this.target = '_self';
        }
        else {
            this.href = this.portalService.urlOfRoute(this.targetRoute);
            this.target = '_blank';
        }
    };
    return PortalRouteDirective;
}());
export { PortalRouteDirective };
