import {
  ChangeDetectionStrategy,
  Component, ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, TemplateRef, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ImageService} from '@shared/service/image.service';
import {ImageModel} from '@model/component/image';
import {IMG_SRC} from '@model/const/image';
import {OverlayService} from '@shared/service/overlay.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'picimage',
  templateUrl: './image.component.html',
})
export class ImageComponent implements OnInit, OnChanges {

  @Input() model: ImageModel;
  @Output() loaded: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('overlay', {read: TemplateRef, static: true}) overlay: TemplateRef<any>;

  width: number;
  left: number;

  constructor(
    private imageService: ImageService,
    private overlayService: OverlayService,
    private resolver: ComponentFactoryResolver,
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.model.src = this.imageService.url(this.model.url, IMG_SRC[this.model.size]);
    this.model.srcset = this.imageService.srcset(this.model.url, this.model.size);
  }

  sayHello() {
    this.loaded.emit(true);
  }

  openImageInModal() {
    const coordinates = document.getElementsByClassName('article').item(0).getBoundingClientRect();
    this.width = coordinates.width;
    this.left = coordinates.left;
    this.overlayService.addTemplate(this.resolver, this.overlay);
  }

  close() {
    this.overlayService.clearComponent();
  }
}
