/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./filmatreview.component";
var styles_FilmAtReviewComponent = [];
var RenderType_FilmAtReviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FilmAtReviewComponent, data: {} });
export { RenderType_FilmAtReviewComponent as RenderType_FilmAtReviewComponent };
export function View_FilmAtReviewComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["filmat review"]))], null, null); }
export function View_FilmAtReviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "filmatreview", [], null, null, null, View_FilmAtReviewComponent_0, RenderType_FilmAtReviewComponent)), i0.ɵdid(1, 49152, null, 0, i1.FilmAtReviewComponent, [], null, null)], null, null); }
var FilmAtReviewComponentNgFactory = i0.ɵccf("filmatreview", i1.FilmAtReviewComponent, View_FilmAtReviewComponent_Host_0, { cssClass: "cssClass" }, {}, []);
export { FilmAtReviewComponentNgFactory as FilmAtReviewComponentNgFactory };
