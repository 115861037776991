import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var CookieService = /** @class */ (function () {
    function CookieService(document) {
        this.document = document;
    }
    CookieService.prototype.getCookie = function (cname) {
        var re = new RegExp('[; ]' + cname + '=([^\\s;]*)');
        var sMatch = (' ' + this.document.cookie).match(re);
        if (cname && sMatch) {
            return decodeURIComponent(sMatch[1]);
        }
        return '';
    };
    CookieService.prototype.setCookie = function (cname, value, days) {
        var date = new Date();
        date.setHours(date.getHours() + (24 * days));
        // tslint:disable-next-line:no-string-literal
        var gmt = date['toGMTString']();
        this.document.cookie = cname + '=' + value + '; expires=' + gmt + '; path=/';
    };
    CookieService.prototype.deleteCookie = function (cname) {
        document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    };
    CookieService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CookieService_Factory() { return new CookieService(i0.ɵɵinject(i1.DOCUMENT)); }, token: CookieService, providedIn: "root" });
    return CookieService;
}());
export { CookieService };
