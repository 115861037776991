import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {AdModel} from '../../../model/ad/ad-model';
import {AdDimensions, AdSize} from '../../../model/enum/ad';
import {AD_DIMENSIONS} from '../../../model/const/ad';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'ad1x1',
  templateUrl: 'ad1x1.component.html'
})
export class Ad1x1Component {

  model: AdModel;

  constructor() {
    this.model = new AdModel(
      'ad1x1',
      AdSize.ad_1x1,
      [AD_DIMENSIONS[AdDimensions.dim_1x1], AD_DIMENSIONS[AdDimensions.dim_1x10]],
      false,
      (d: number[]) => d[1] === 1 || window.innerWidth > 1200,
      false,
    );
  }
}
