import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TogglePopupComponent } from '../../common/togglePopup.component';
import { CssClassService } from '../../../shared/service/cssClass.service';
import { SHARE_CLASS_MAP } from '../../../model/const/share';
import { GTMTriggerId } from '../../../model/enum/gtm';
import { PortalService } from '../../../shared/service/portal.service';
var MenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MenuComponent, _super);
    function MenuComponent(cd, cssService, router, portalService) {
        var _this = _super.call(this) || this;
        _this.cd = cd;
        _this.cssService = cssService;
        _this.router = router;
        _this.portalService = portalService;
        _this.search = new EventEmitter();
        _this.shareMap = SHARE_CLASS_MAP;
        _this.gtmId = GTMTriggerId.menu;
        _this.isKurier = _this.portalService.currentPortal() === 'kurierat';
        return _this;
    }
    MenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function () {
            if (_this.showPopUp) {
                _this.toggleScrolling(false);
                _this.showPopUp = false;
                _this.cd.markForCheck();
            }
        });
    };
    MenuComponent.prototype.toggleScrolling = function (show) {
        this.cssService.setBodyClass('no-scroll', show);
    };
    MenuComponent.prototype.togglePopUp = function () {
        this.toggleScrolling(!this.showPopUp);
        return _super.prototype.togglePopUp.call(this);
    };
    MenuComponent.prototype.clickedInside = function ($event) {
        if ($event.target.classList.contains('navigation-bg')) {
            return;
        }
        _super.prototype.clickedInside.call(this, $event);
    };
    MenuComponent.prototype.toggleSearch = function () {
        this.search.emit();
        this.togglePopUp();
    };
    return MenuComponent;
}(TogglePopupComponent));
export { MenuComponent };
