/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./typed-embed.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../shared/service/link.service";
import * as i5 from "../../../../shared/service/loadExternalScript.service";
var styles_TypedEmbedComponent = [];
var RenderType_TypedEmbedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TypedEmbedComponent, data: {} });
export { RenderType_TypedEmbedComponent as RenderType_TypedEmbedComponent };
function View_TypedEmbedComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.embed; _ck(_v, 0, 0, currVal_0); }); }
export function View_TypedEmbedComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TypedEmbedComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.embed; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TypedEmbedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-typed-embed", [], null, null, null, View_TypedEmbedComponent_0, RenderType_TypedEmbedComponent)), i0.ɵdid(1, 49152, null, 0, i2.TypedEmbedComponent, [i3.DomSanitizer, i4.LinkService, i5.LoadExternalScriptService], null, null)], null, null); }
var TypedEmbedComponentNgFactory = i0.ɵccf("app-typed-embed", i2.TypedEmbedComponent, View_TypedEmbedComponent_Host_0, {}, {}, []);
export { TypedEmbedComponentNgFactory as TypedEmbedComponentNgFactory };
