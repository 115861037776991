/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./romyVoting.component";
import * as i2 from "@angular/platform-browser";
var styles_RomyVotingComponent = [];
var RenderType_RomyVotingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RomyVotingComponent, data: {} });
export { RenderType_RomyVotingComponent as RenderType_RomyVotingComponent };
export function View_RomyVotingComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "romyVoting"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "iframe", [["height", "100%"], ["scrolling", "auto"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); }); }
export function View_RomyVotingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "romyvoting", [], null, null, null, View_RomyVotingComponent_0, RenderType_RomyVotingComponent)), i0.ɵdid(1, 49152, null, 0, i1.RomyVotingComponent, [i2.DomSanitizer], null, null)], null, null); }
var RomyVotingComponentNgFactory = i0.ɵccf("romyvoting", i1.RomyVotingComponent, View_RomyVotingComponent_Host_0, {}, {}, []);
export { RomyVotingComponentNgFactory as RomyVotingComponentNgFactory };
