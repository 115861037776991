/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../iconButton/iconButton.component.ngfactory";
import * as i2 from "../iconButton/iconButton.component";
import * as i3 from "../../../../shared/directive/portalRoute.directive";
import * as i4 from "@angular/router";
import * as i5 from "../../../../shared/service/portal.service";
import * as i6 from "../../../../shared/service/browser.ref";
import * as i7 from "../../gtmTrigger.directive";
import * as i8 from "@angular/common";
import * as i9 from "../../image/image.component.ngfactory";
import * as i10 from "../../image/image.component";
import * as i11 from "../../../../shared/service/image.service";
import * as i12 from "../../../../shared/service/overlay.service";
import * as i13 from "./linkBar.component";
var styles_LinkBarComponent = [];
var RenderType_LinkBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LinkBarComponent, data: {} });
export { RenderType_LinkBarComponent as RenderType_LinkBarComponent };
function View_LinkBarComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LinkBarComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "linkBar-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "iconbutton", [], null, null, null, i1.View_IconButtonComponent_0, i1.RenderType_IconButtonComponent)), i0.ɵdid(2, 114688, null, 0, i2.IconButtonComponent, [], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "angle-right"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LinkBarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "a", [["class", "linkBarLink"]], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 540672, null, 0, i3.PortalRouteDirective, [i4.Router, i0.ElementRef, i5.PortalService, i6.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), i0.ɵdid(3, 81920, null, 0, i7.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkBarComponent_3)), i0.ɵdid(5, 540672, null, 0, i8.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkBarComponent_4)), i0.ɵdid(7, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.model.link; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.model.gtmId; _ck(_v, 3, 0, currVal_3); var currVal_4 = i0.ɵnov(_v.parent, 3); _ck(_v, 5, 0, currVal_4); var currVal_5 = !_co.model.logo; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_LinkBarComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "picimage", [["class", "linkBar-image"]], null, null, null, i9.View_ImageComponent_0, i9.RenderType_ImageComponent)), i0.ɵdid(1, 638976, null, 0, i10.ImageComponent, [i11.ImageService, i12.OverlayService, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.image; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LinkBarComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "linkBar-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [["class", "linkBar-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.text; _ck(_v, 2, 0, currVal_0); }); }
function View_LinkBarComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "picimage", [["class", "linkBar-logo"]], null, null, null, i9.View_ImageComponent_0, i9.RenderType_ImageComponent)), i0.ɵdid(1, 638976, null, 0, i10.ImageComponent, [i11.ImageService, i12.OverlayService, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.logo; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LinkBarComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkBarComponent_6)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkBarComponent_7)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkBarComponent_8)), i0.ɵdid(5, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.image; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.model.text; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.model.logo; _ck(_v, 5, 0, currVal_2); }, null); }
function View_LinkBarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "linkBar"]], [[2, "isTitle", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkBarComponent_2)), i0.ɵdid(2, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["content", 2]], null, 0, null, View_LinkBarComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.model.showLink; var currVal_2 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.isTitle; _ck(_v, 0, 0, currVal_0); }); }
export function View_LinkBarComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkBarComponent_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.model && ((_co.model.text || _co.model.logo) || _co.model.image)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LinkBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "linkbar", [], null, null, null, View_LinkBarComponent_0, RenderType_LinkBarComponent)), i0.ɵdid(1, 49152, null, 0, i13.LinkBarComponent, [], null, null)], null, null); }
var LinkBarComponentNgFactory = i0.ɵccf("linkbar", i13.LinkBarComponent, View_LinkBarComponent_Host_0, { model: "model" }, {}, []);
export { LinkBarComponentNgFactory as LinkBarComponentNgFactory };
