import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { BaseFormControlComponent } from '../../baseFormControl.component';
var InputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputComponent, _super);
    function InputComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.valueChange = new EventEmitter();
        _this.inputChanged = new EventEmitter();
        return _this;
    }
    InputComponent.prototype.ngOnInit = function () {
        if (!this.placeholder) {
            this.placeholder = this.label;
        }
    };
    InputComponent.prototype.onChange = function ($event) {
        this.valueChange.emit($event.target.value);
    };
    InputComponent.prototype.onKeyUp = function ($event) {
        this.valueChange.emit($event.target.value);
        this.inputChanged.emit($event.target.value);
    };
    return InputComponent;
}(BaseFormControlComponent));
export { InputComponent };
