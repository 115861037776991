import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {ShareModel} from '@model/component/share';
import {GoogleTagManagerService} from '@shared/service/gtm.service';
import {BrowserRef} from '@shared/service/browser.ref';
import {ActivatedRoute} from '@angular/router';
import {MetaDefinition} from '@angular/platform-browser';
import {DeviceDetectionService} from '@shared/service/device-detection.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'interaction-bar',
  templateUrl: './interaction-bar.component.html',
})
export class InteractionBarComponent implements OnInit {

  @Input() model: ShareModel;
  @Input() hasCoral: boolean;
  open = false;

  private readonly metas: MetaDefinition[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private gtm: GoogleTagManagerService,
    private browserRef: BrowserRef,
    private deviceDetectionService: DeviceDetectionService,
  ) {
    this.metas = activatedRoute.snapshot.data.route.head.metas || [];
  }

  ngOnInit() {
    this.model.addMetaInfo(this.metas);
  }

  openPopup(type: string) {
    this.open = false;
    const data = {
      url: this.model.url,
      sharing_type: type,
    };
    this.gtm.pushEvent('DA - SharingTeaser', data);
    this.gtm.pushDataLayerOnBrowser({
      event: 'share',
      sharePlatform: type,
    });

    if (type === 'mail') {
      this.browserRef.window.location.href = this.model.mailto;
      return false;
    }

    this.browserRef.window.open(this.model[type], 'popup',
      'left=20,top=20,width=500,height=500,toolbar=1,resizable=0');
    return false;
  }

  scrollToComments() {
    const cleverPush = document.getElementById('coral_thread');
    cleverPush.scrollIntoView();
  }

  triggerCleverPush() {
    // @ts-ignore
    const cleverPush = window.CleverPush || [];
    cleverPush.bell.onClick();
  }

  openSharePopup() {
    // @ts-ignore
    if (navigator.share && this.deviceDetectionService.isTouch) {
      // @ts-ignore
      navigator.share(
        {
          url: window.location.href,
          title: document.title,
        }
      );
    } else {
      this.open = !this.open;
    }
  }
}
