import { PortalRoute } from '../../model/teaser/portalRoute';
import { BrowserRef } from '@shared/service/browser.ref';
import { PortalService } from '@shared/service/portal.service';
import { InjectScriptsService } from '@shared/service/injectScripts.service';
import { RouteService } from './route.service';
import { StateService } from './state.service';
import { filter, first, map, skip } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./browser.ref";
import * as i2 from "./portal.service";
import * as i3 from "./injectScripts.service";
import * as i4 from "./route.service";
import * as i5 from "./state.service";
export var GTM_HAS_USER_KEY = 'hasCommunityUser';
export var GTM_SUBSCRIPTION_LEVEL_ANONYMOUS = 'anonymous';
export var GTM_SUBSCRIPTION_LEVEL_REGISTERED = 'registered';
export var GTM_SUBSCRIPTION_LEVEL_PAID = 'paid';
export var GTM_SUBSCRIPTION_LEVEL_TRIAL = 'trial';
var GoogleTagManagerService = /** @class */ (function () {
    function GoogleTagManagerService(browserRef, portalService, injectScripts, routeService, stateService) {
        this.browserRef = browserRef;
        this.portalService = portalService;
        this.injectScripts = injectScripts;
        this.routeService = routeService;
        this.stateService = stateService;
    }
    GoogleTagManagerService.mapPageTitle = function (route) {
        if (route.dataLayer && !route.dataLayer.Seitentitel) {
            route.dataLayer.Seitentitel = route.title;
        }
        return route;
    };
    GoogleTagManagerService.prototype.pushEvent = function (name, data) {
        var base = {
            event: name,
        };
        var dataLayer = Object.assign(base, data);
        this.pushDataLayerOnBrowser(dataLayer);
    };
    GoogleTagManagerService.prototype.init = function () {
        var _this = this;
        if (this.stateService.isFirstApp) {
            this.routeService.routeInfo.pipe(first(Boolean), map(GoogleTagManagerService.mapPageTitle)).subscribe(function (route) {
                _this.appendDataLayerToBody(route);
                _this.appendContainersToBody(route);
            });
        }
        this.routeService.routeInfo
            .pipe(filter(Boolean), skip(1), map(GoogleTagManagerService.mapPageTitle)).subscribe(function (route) {
            if (route.dataLayer) {
                var dataLayer = route.dataLayer;
                _this.pushDataLayerOnBrowser(dataLayer);
            }
            var virtualPageview = _this.createVirtualPageViewEvent(route);
            _this.pushDataLayerOnBrowser(virtualPageview);
            _this.pushEvent('CFS - dataLayer', {});
        });
    };
    GoogleTagManagerService.prototype.pushDataLayerOnBrowser = function (data) {
        if (typeof window === 'object') {
            // tslint:disable-next-line:no-string-literal
            var dl = this.browserRef.window['dataLayer'] = this.browserRef.window['dataLayer'] || [];
            dl.push(data);
        }
    };
    GoogleTagManagerService.prototype.appendContainersToBody = function (route) {
        var _this = this;
        if (route.analytics && route.analytics.gtm) {
            route.analytics.gtm
                // filtering out the ad container
                .forEach(function (containerId) {
                var tag = {
                    innerHTML: "\n              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer='defer';j.src=\n              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n              })(window,document,'script','dataLayer','" + containerId + "');\n            "
                };
                _this.injectScripts.appendToBodyOnce(tag);
            });
        }
    };
    GoogleTagManagerService.prototype.appendDataLayerToBody = function (route) {
        var dataLayer = route.dataLayer || {};
        if (route.analytics && route.analytics.ga360) {
            dataLayer['CFS UA-ID'] = route.analytics.ga360;
        }
        if (route.analytics && route.analytics.ga4_measurement) {
            dataLayer['GA4 Tracking ID'] = route.analytics.ga4_measurement;
        }
        var virtualPageview = this.createVirtualPageViewEvent(route);
        var kamDataLayerEvent = { event: 'CFS - dataLayer' };
        var dataLayerTag = {
            innerHTML: "\n              // init\n              window.dataLayer = window.dataLayer || [];\n              dataLayer.push(" + JSON.stringify(dataLayer) + ");\n              // gdprMode\n              var vaToken = (function(cname) {\n                var re = new RegExp('[; ]' + cname + '=([^\\\\s;]*)');\n                var sMatch = (' ' + document.cookie).match(re);\n                if (cname && sMatch) return decodeURIComponent(sMatch[1]);\n                return '';})('va_token');\n              var hasConsented = localStorage.getItem('gdprCookieConsent') === 'true' ||\n                sessionStorage.getItem('gdprCookieConsent') === 'true';\n              var gdprMode = (vaToken === 'true') ? 'paid' : (hasConsented ? 'consented' : 'new');\n              dataLayer.push({'gdprMode': gdprMode});\n              // SubscriptionLevel\n              var subscriptionLevel = (function(key){\n                var storedItem = localStorage.getItem(key) || sessionStorage.getItem(key);\n                return storedItem && storedItem === 'yes' ? '" + GTM_SUBSCRIPTION_LEVEL_REGISTERED + "' : '" + GTM_SUBSCRIPTION_LEVEL_ANONYMOUS + "';\n              })('" + GTM_HAS_USER_KEY + "');\n              dataLayer.push({'SubscriptionLevel': subscriptionLevel});\n              // virtualPageview\n              dataLayer.push(" + JSON.stringify(virtualPageview) + ");\n              // KAM kamDataLayerEvent\n              dataLayer.push(" + JSON.stringify(kamDataLayerEvent) + ");",
        };
        this.injectScripts.appendToBodyOnce(dataLayerTag);
    };
    GoogleTagManagerService.prototype.createVirtualPageViewEvent = function (route) {
        var path = route.uri ? PortalRoute.fromUri(route.uri).path : this.portalService.currentPortal();
        return { event: 'virtualPageview', Pfad: path, Artikelname: route.title };
    };
    GoogleTagManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleTagManagerService_Factory() { return new GoogleTagManagerService(i0.ɵɵinject(i1.BrowserRef), i0.ɵɵinject(i2.PortalService), i0.ɵɵinject(i3.InjectScriptsService), i0.ɵɵinject(i4.RouteService), i0.ɵɵinject(i5.StateService)); }, token: GoogleTagManagerService, providedIn: "root" });
    return GoogleTagManagerService;
}());
export { GoogleTagManagerService };
