/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../content/no-consent/no-consent.component.ngfactory";
import * as i2 from "../../content/no-consent/no-consent.component";
import * as i3 from "../../../../shared/service/didomi.service";
import * as i4 from "@angular/common";
import * as i5 from "./contentGardenTeaser.component";
import * as i6 from "../../../../shared/service/loadExternalScript.service";
import * as i7 from "../../../../shared/service/browser.ref";
var styles_ContentGardenTeaserComponent = [];
var RenderType_ContentGardenTeaserComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContentGardenTeaserComponent, data: {} });
export { RenderType_ContentGardenTeaserComponent as RenderType_ContentGardenTeaserComponent };
function View_ContentGardenTeaserComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["contentRef", 1]], null, 0, "iframe", [["class", "contentGarden-teaser"], ["height", "0"], ["scrolling", "no"], ["width", "100%"]], null, null, null, null, null))], null, null); }
function View_ContentGardenTeaserComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i1.View_NoConsentComponent_0, i1.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i2.NoConsentComponent, [i3.DidomiService], { vendor: [0, "vendor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ContentGardenTeaserComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { contentRef: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ContentGardenTeaserComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["noContent", 2]], null, 0, null, View_ContentGardenTeaserComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.showTeaser$)); var currVal_1 = i0.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ContentGardenTeaserComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "contentgardenteaser", [], null, null, null, View_ContentGardenTeaserComponent_0, RenderType_ContentGardenTeaserComponent)), i0.ɵdid(1, 180224, null, 0, i5.ContentGardenTeaserComponent, [i6.LoadExternalScriptService, i7.BrowserRef, i3.DidomiService], null, null)], null, null); }
var ContentGardenTeaserComponentNgFactory = i0.ɵccf("contentgardenteaser", i5.ContentGardenTeaserComponent, View_ContentGardenTeaserComponent_Host_0, {}, {}, []);
export { ContentGardenTeaserComponentNgFactory as ContentGardenTeaserComponentNgFactory };
