/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./svgIcon.component";
var styles_SvgIconComponent = [];
var RenderType_SvgIconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SvgIconComponent, data: {} });
export { RenderType_SvgIconComponent as RenderType_SvgIconComponent };
export function View_SvgIconComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0], [1, "href", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("/assets/images/icons/default.svg#" + _co.icon); var currVal_1 = ("/assets/images/icons/default.svg#" + _co.icon); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_SvgIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svgicon", [], [[1, "class", 0]], null, null, View_SvgIconComponent_0, RenderType_SvgIconComponent)), i0.ɵdid(1, 49152, null, 0, i1.SvgIconComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var SvgIconComponentNgFactory = i0.ɵccf("svgicon", i1.SvgIconComponent, View_SvgIconComponent_Host_0, { icon: "icon" }, {}, []);
export { SvgIconComponentNgFactory as SvgIconComponentNgFactory };
