export default function(length: number) {

  let id = (new Date()).getTime().toString();

  // id should only be alphanumeric (no hyphens)
  // to easily split the date from the rest of the id we add an "x" after the date-milliseconds
  // => split by first "x" in ID to extract the date

  id += 'x';

  while (id.length < length) {
    id += Math.random().toString(36).slice(2);
  }

  return id.slice(0, length);
}
