import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { NavigationStart, Router } from '@angular/router';
import { of, ReplaySubject } from 'rxjs';
import { bufferCount, map, skip, take, tap } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./api.service";
import * as i3 from "./cache.service";
var TeasersService = /** @class */ (function () {
    function TeasersService(platformId, router, api, cacheService) {
        var _this = this;
        this.platformId = platformId;
        this.router = router;
        this.api = api;
        this.cacheService = cacheService;
        this.KEY_TEASERS = 'TEASERS';
        this.clearTeasers();
        if (isPlatformBrowser(this.platformId)) {
            this.cacheService.getMap(this.KEY_TEASERS).forEach(function (rawTeasers, collection) {
                _this.requested.set(collection, rawTeasers.length);
                var teaserSubject = new ReplaySubject();
                _this.teasers$.set(collection, teaserSubject);
                rawTeasers
                    .forEach(function (teaser) { return teaserSubject.next(teaser); });
            });
            router.events.subscribe(function (event) {
                if (event instanceof NavigationStart && event.id > 1) {
                    _this.consumed = new Map();
                }
            });
        }
        else if (isPlatformServer(this.platformId)) {
            this.cacheService.onSerializeMap(this.KEY_TEASERS, function () { return _this.rawTeaserCache; });
        }
    }
    TeasersService.prototype.getCollection = function (collection, length) {
        if (!collection || length === 0) {
            return of([]);
        }
        var start = this.consumed.get(collection) || 0;
        var consumed = start + (+length);
        this.consumed.set(collection, consumed);
        var requested = this.requested.get(collection) || 0;
        if (consumed > requested) {
            var missing = consumed - requested;
            this.fetchCollection(collection, requested, missing);
        }
        return this.teasers$.get(collection).pipe(skip(start), bufferCount(length), map(function (teasers) { return teasers.filter(function (t) { return t.type !== 'empty'; }); }), take(1));
    };
    TeasersService.prototype.clearTeasers = function () {
        this.consumed = new Map();
        this.requested = new Map();
        this.teasers$ = new Map();
        this.rawTeaserCache = new Map();
    };
    TeasersService.prototype.prewarmCollection = function (collection, length) {
        if (!collection) {
            return;
        }
        var requested = this.requested.has(collection) ? this.requested.get(collection) : 0;
        if (length > requested) {
            var missing = length - requested;
            this.fetchCollection(collection, requested, missing);
        }
    };
    TeasersService.prototype.getTeaser = function (id) {
        return this.api.singleTeaser(id);
    };
    TeasersService.prototype.fetchCollection = function (collection, start, length) {
        var _this = this;
        var requested = start + (+length);
        this.requested.set(collection, requested);
        if (!this.teasers$.has(collection)) {
            this.teasers$.set(collection, new ReplaySubject());
        }
        if (!this.rawTeaserCache.has(collection)) {
            this.rawTeaserCache.set(collection, []);
        }
        this.api.collectionTeasers(collection, start, length).pipe(tap(function (json) {
            var _a;
            if (isPlatformServer(_this.platformId) && json && json.items) {
                (_a = _this.rawTeaserCache.get(collection)).push.apply(_a, tslib_1.__spread(json.items));
            }
        }), map(function (json) { return json.items; })).subscribe(function (teasers) {
            teasers.forEach(function (teaser) { return _this.teasers$.get(collection).next(teaser); });
        });
    };
    TeasersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TeasersService_Factory() { return new TeasersService(i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ApiService), i0.ɵɵinject(i3.CacheService)); }, token: TeasersService, providedIn: "root" });
    return TeasersService;
}());
export { TeasersService };
