/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/linkcard/linkCard.component.ngfactory";
import * as i2 from "../../../common/linkcard/linkCard.component";
import * as i3 from "../../../ads/mediumRectangle/adMediumRectangle.component.ngfactory";
import * as i4 from "../../../ads/mediumRectangle/adMediumRectangle.component";
import * as i5 from "../../../../shared/service/browser.ref";
import * as i6 from "../../../../shared/service/sticky.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../common/slider/slider.component.ngfactory";
import * as i9 from "../../../common/slider/slider.component";
import * as i10 from "../../../../shared/service/tokens";
import * as i11 from "../../../../shared/service/portal.service";
import * as i12 from "../../../../shared/service/slider.service";
import * as i13 from "./inlineSlider.component";
import * as i14 from "@angular/router";
import * as i15 from "../../../../shared/service/piano/piano.service";
var styles_InlineSliderComponent = [];
var RenderType_InlineSliderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InlineSliderComponent, data: {} });
export { RenderType_InlineSliderComponent as RenderType_InlineSliderComponent };
function View_InlineSliderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "linkcard", [], null, null, null, i1.View_LinkCardComponent_0, i1.RenderType_LinkCardComponent)), i0.ɵdid(1, 49152, null, 0, i2.LinkCardComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_InlineSliderComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "admediumrectangle", [], null, null, null, i3.View_AdMediumRectangleComponent_0, i3.RenderType_AdMediumRectangleComponent)), i0.ɵdid(1, 573440, null, 0, i4.AdMediumRectangleComponent, [i5.BrowserRef, i0.Renderer2, i6.StickyService], { adId: [0, "adId"], isSticky: [1, "isSticky"] }, null)], function (_ck, _v) { var currVal_0 = "slider"; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_InlineSliderComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "slider-item-ad"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InlineSliderComponent_5)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isActiveSlide(_v.parent.context.index); _ck(_v, 2, 0, currVal_0); }, null); }
function View_InlineSliderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["sliderItem", 1]], null, 4, "div", [["class", "slider-item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InlineSliderComponent_3)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InlineSliderComponent_4)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit !== "adSlide"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit === "adSlide"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_InlineSliderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "slider", [], null, [[null, "currSlideNumber"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("currSlideNumber" === en)) {
        var pd_0 = (_co.loadAd($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_SliderComponent_0, i8.RenderType_SliderComponent)), i0.ɵdid(1, 3391488, null, 1, i9.SliderComponent, [i10.GLOBAL_STYLES, i0.PLATFORM_ID, i0.ChangeDetectorRef, i11.PortalService, i12.SliderService], { sliderConfigModel: [0, "sliderConfigModel"] }, { currSlideNumber: "currSlideNumber" }), i0.ɵqud(603979776, 1, { sliderItems: 1 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_InlineSliderComponent_2)), i0.ɵdid(4, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sliderConfigModel; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.ngIf; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_InlineSliderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_InlineSliderComponent_1)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.sliderItems$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InlineSliderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "inlineSlider", [], null, null, null, View_InlineSliderComponent_0, RenderType_InlineSliderComponent)), i0.ɵdid(1, 49152, null, 0, i13.InlineSliderComponent, [i14.ActivatedRoute, i15.PianoService], null, null)], null, null); }
var InlineSliderComponentNgFactory = i0.ɵccf("inlineSlider", i13.InlineSliderComponent, View_InlineSliderComponent_Host_0, {}, {}, []);
export { InlineSliderComponentNgFactory as InlineSliderComponentNgFactory };
