import {ChangeDetectionStrategy, Component} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {ITextParagraph} from '../../../../model/content/textParagraph/payload';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'paragraph',
  templateUrl: 'textParagraph.component.html'
})
export class TextParagraphComponent implements DynamicComponent<ITextParagraph> {

  html: string;

  initModel(model: ITextParagraph): void {
    this.html = model.data.html;
  }
}
