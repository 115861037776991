import {Component} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {ITypedEmbed} from '@model/content/typedEmbed/payload';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {LinkService} from '@shared/service/link.service';
import {LoadExternalScriptService} from '@shared/service/loadExternalScript.service';
import {ScriptId} from '@model/enum/scriptId';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-typed-embed',
  templateUrl: './typed-embed.component.html',
})
export class TypedEmbedComponent implements DynamicComponent<ITypedEmbed> {
  embed: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    private linkService: LinkService,
    private loadExternalScriptService: LoadExternalScriptService,
  ) {
  }

  initModel(model: ITypedEmbed) {
    const type = model.data && model.data.type ? model.data.type : model.embedType;
    const code = model.data && model.data.embed_code ? model.data.embed_code : model.embedCode;
    this.embed = this.sanitizer.bypassSecurityTrustHtml(code);
    switch (type) {
      case 'sportmonks_standings':
        this.setLinkTag();
        this.loadExternalScriptService.loadScript(ScriptId.sportmonkStandings).pipe(take(1)).subscribe();
        break;
      case 'sportmonks_livestatus':
        this.setLinkTag();
        this.loadExternalScriptService.loadScript(ScriptId.sportmonkLivestatus).pipe(take(1)).subscribe();
        break;
      case 'sportmonks_standings_squad':
        this.setLinkTag();
        this.loadExternalScriptService.loadScript(ScriptId.sportmonkStandingsSquad).pipe(take(1)).subscribe();
        break;
      case 'sportmonks_standings_slider':
        this.setLinkTag();
        this.loadExternalScriptService.loadScript(ScriptId.sportmonkStandingsSlider).pipe(take(1)).subscribe();
        break;
      case 'sportmonks_livescore':
        this.setLinkTag();
        this.loadExternalScriptService.loadScript(ScriptId.sportmonkLivescore).pipe(take(1)).subscribe();
        break;
    }
  }

  private setLinkTag() {
    this.linkService.addTag({
      rel: 'stylesheet',
      href: 'https://widgets.sportmonks.com/css/app.css',
    });
  }

}
