<div class="immoJobBox">
  <h2 class="immoJobBox-title">
    <a href="https://job.kurier.at/" target="_blank">
      <iconbutton class="immoJobBox-title-first" [icon]="'briefcase'"></iconbutton>
      <img src="/assets/images/immoJobBox/job.svg" height="48" alt="job.kurier.at Logo">
      <iconbutton class="immoJobBox-title-last" [icon]="'angle-right'"></iconbutton>
    </a>
  </h2>
  <figure class="immoJobBox-image">
    <img [src]="'/assets/images/immoJobBox/job-header-image.jpg'"
         alt="Der schnellste Weg sich auf die nächste Stufe zu heben."/>
    <h3>Der schnellste Weg sich auf die nächste Stufe zu heben.</h3>
  </figure>
  <form action="#" (ngSubmit)="onSubmit()" class="immoJobBox-form" autocomplete="off">
    <div class="immoJobBox-form-item">
      <forminput [(value)]="profession" [label]="'Was?'" [type]="'text'" (inputChanged)="onInputChanged($event)"
                 [placeholder]="'Was? z.B. Projektmanagement'">
        <ul *ngIf="searchActive && searchResult$ | async as results" class="immoJobBox-search-results">
          <li (click)="selectInput(result)" *ngFor="let result of results" class="immoJobBox-search-item">{{result}}
          </li>
        </ul>
      </forminput>
    </div>
    <div class="immoJobBox-form-item">
      <forminput [(value)]="location" [label]="'Wo?'" [type]="'text'" [placeholder]="'Wo? z.B. Wien'"></forminput>
    </div>
    <div class="immoJobBox-form-item">
      <formselect [label]="'Umkreis?'" [values]="radius" [(valueSelected)]="radiusSelected"></formselect>
    </div>
    <button type="submit" class="immoJobBox-form-button button">
      <iconbutton [icon]="'search'"></iconbutton>
      <span>Suche starten</span>
    </button>
  </form>
</div>
