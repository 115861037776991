import {ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {LoadExternalScriptService} from '../../../../shared/service/loadExternalScript.service';
import {BrowserRef} from '../../../../shared/service/browser.ref';
import {IContentGarden} from '../../../../model/channel/payload';
import {ScriptId} from '../../../../model/enum/scriptId';
import {DidomiService} from '@shared/service/didomi.service';
import {VendorTypes} from '@model/enum/vendorTypes';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'contentgardenteaser',
  templateUrl: './contentGardenTeaser.component.html'
})
export class ContentGardenTeaserComponent implements DynamicComponent<IContentGarden>, OnDestroy {

  @ViewChild('contentRef', {static: false}) contentRef: ElementRef;
  private src = 'https://ad1.adfarm1.adition.com/js?wp_id=';
  private iframeObserver: MutationObserver;
  vendorType = VendorTypes.adition;
  showTeaser$: Observable<boolean>;

  constructor(
    private loadExternal: LoadExternalScriptService,
    private browserRef: BrowserRef,
    private didomiService: DidomiService,
  ) {
  }

  initModel(model: IContentGarden) {
    this.showTeaser$ = this.didomiService.checkVendor$(this.vendorType)
      .pipe(
        tap(consent => {
          if (consent) {
            const id = model.id || '4251826';
            this.loadExternal.loadScript(ScriptId.adition).subscribe(s => {
              const content = `<div><script type="text/javascript" src="${this.src}${id}"></script></div>`;
              const doc = this.contentRef.nativeElement.contentDocument || this.contentRef.nativeElement.contentWindow;
              if (!doc) {
                return;
              }
              doc.open();
              doc.write(content);
              doc.close();
              // tslint:disable-next-line:no-string-literal
              if (this.browserRef.window['MutationObserver']) {
                this.iframeObserver = new MutationObserver((ev) => {
                  this.contentRef.nativeElement.height = this.contentRef.nativeElement.contentWindow.document.body.scrollHeight;
                });

                this.iframeObserver.observe(this.contentRef.nativeElement.contentDocument, {
                  attributes: true,
                  subtree: true,
                  childList: true,
                  characterData: true
                });
              }
            });
          }
        })
      );
  }

  ngOnDestroy(): void {
    if (this.iframeObserver) {
      this.iframeObserver.disconnect();
    }
  }

}
