/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i2 from "../../../common/ui/linkBar/linkBar.component";
import * as i3 from "../../../common/teaser/teaser.directive";
import * as i4 from "@angular/common";
import * as i5 from "../../../../shared/directive/portalRoute.directive";
import * as i6 from "@angular/router";
import * as i7 from "../../../../shared/service/portal.service";
import * as i8 from "../../../../shared/service/browser.ref";
import * as i9 from "./toptopics.component";
import * as i10 from "../../../../shared/service/teasers.service";
import * as i11 from "../../../../shared/service/device-detection.service";
var styles_TopTopicsComponent = [];
var RenderType_TopTopicsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TopTopicsComponent, data: {} });
export { RenderType_TopTopicsComponent as RenderType_TopTopicsComponent };
function View_TopTopicsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "linkbar", [], null, null, null, i1.View_LinkBarComponent_0, i1.RenderType_LinkBarComponent)), i0.ɵdid(1, 49152, null, 0, i2.LinkBarComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkBar; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TopTopicsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(1, 540672, null, 0, i3.TeaserDirective, [i0.ViewContainerRef, i0.ComponentFactoryResolver], { model: [0, "model"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TopTopicsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TopTopicsComponent_4)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.teasers; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TopTopicsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "section", [["class", "topTopics"]], [[2, "numbered", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TopTopicsComponent_2)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TopTopicsComponent_3)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.linkBar.text; _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.datesTemplate; var currVal_3 = i0.ɵnov(_v.parent, 3); _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.numbered; _ck(_v, 0, 0, currVal_0); }); }
function View_TopTopicsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "topTopics-item topTopics-item-date"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "topTopics-date"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "a", [], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 540672, null, 0, i5.PortalRouteDirective, [i6.Router, i0.ElementRef, i7.PortalService, i8.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.link; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.time(_v.context.index); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 4).target; var currVal_2 = i0.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.title; _ck(_v, 6, 0, currVal_4); }); }
function View_TopTopicsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TopTopicsComponent_6)), i0.ɵdid(1, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.teasers; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TopTopicsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_TopTopicsComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["date", 2]], null, 0, null, View_TopTopicsComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.teasers$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TopTopicsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toptopics", [], null, null, null, View_TopTopicsComponent_0, RenderType_TopTopicsComponent)), i0.ɵdid(1, 49152, null, 0, i9.TopTopicsComponent, [i10.TeasersService, i11.DeviceDetectionService], null, null)], null, null); }
var TopTopicsComponentNgFactory = i0.ɵccf("toptopics", i9.TopTopicsComponent, View_TopTopicsComponent_Host_0, {}, {}, []);
export { TopTopicsComponentNgFactory as TopTopicsComponentNgFactory };
