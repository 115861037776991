import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ApiService} from './api.service';
import {NavigationStart, Router} from '@angular/router';
import {Observable, of, ReplaySubject} from 'rxjs';
import {bufferCount, map, skip, take, tap} from 'rxjs/operators';
import {ITeaser} from '../../model/teaser/payload';
import {CacheService} from './cache.service';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class TeasersService {

  private readonly KEY_TEASERS = 'TEASERS';

  private consumed: Map<string, number>;
  private requested: Map<string, number>;
  private teasers$: Map<string, ReplaySubject<ITeaser>>;
  private rawTeaserCache: Map<string, ITeaser[]>;

  constructor(@Inject(PLATFORM_ID) protected platformId: string,
              protected router: Router,
              protected api: ApiService,
              protected cacheService: CacheService) {

    this.clearTeasers();

    if (isPlatformBrowser(this.platformId)) {
      this.cacheService.getMap(this.KEY_TEASERS).forEach((rawTeasers: ITeaser[], collection: string) => {
        this.requested.set(collection, rawTeasers.length);
        const teaserSubject = new ReplaySubject<ITeaser>();
        this.teasers$.set(collection, teaserSubject);
        rawTeasers
          .forEach(teaser => teaserSubject.next(teaser));
      });
      router.events.subscribe((event: any) => {
        if (event instanceof NavigationStart && event.id > 1) {
          this.consumed = new Map();
        }
      });
    } else if (isPlatformServer(this.platformId)) {
      this.cacheService.onSerializeMap(this.KEY_TEASERS, () => this.rawTeaserCache);
    }
  }

  getCollection(collection: string, length: number): Observable<ITeaser[]> {
    if (!collection || length === 0) {
      return of([]);
    }
    const start: number = this.consumed.get(collection) || 0;
    const consumed = start + (+length);
    this.consumed.set(collection, consumed);

    const requested = this.requested.get(collection) || 0;
    if (consumed > requested) {
      const missing = consumed - requested;
      this.fetchCollection(collection, requested, missing);
    }

    return this.teasers$.get(collection).pipe(
      skip(start),
      bufferCount(length),
      map(teasers => teasers.filter(t => t.type !== 'empty')),
      take(1),
    );
  }

  clearTeasers() {
    this.consumed = new Map();
    this.requested = new Map();
    this.teasers$ = new Map();
    this.rawTeaserCache = new Map();
  }

  prewarmCollection(collection: string, length: number) {
    if (!collection) {
      return;
    }
    const requested = this.requested.has(collection) ? this.requested.get(collection) : 0;
    if (length > requested) {
      const missing = length - requested;
      this.fetchCollection(collection, requested, missing);
    }
  }

  getTeaser(id: string): Observable<ITeaser> {
    return this.api.singleTeaser(id);
  }

  protected fetchCollection(collection: string, start: number, length: number) {
    const requested = start + (+length);
    this.requested.set(collection, requested);

    if (!this.teasers$.has(collection)) {
      this.teasers$.set(collection, new ReplaySubject());
    }
    if (!this.rawTeaserCache.has(collection)) {
      this.rawTeaserCache.set(collection, []);
    }

    this.api.collectionTeasers(collection, start, length).pipe(
      tap(json => {
        if (isPlatformServer(this.platformId) && json && json.items) {
          this.rawTeaserCache.get(collection).push(...json.items);
        }
      }),
      map(json => json.items),
    ).subscribe(teasers => {
      teasers.forEach(teaser => this.teasers$.get(collection).next(teaser));
    });
  }
}
