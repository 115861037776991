import {Injectable} from '@angular/core';
import {CLEVERPUSH_CHANNELS} from './tokens';
import {PortalService} from './portal.service';
import {LoadExternalScriptService} from './loadExternalScript.service';
import {ScriptId} from '../../model/enum/scriptId';
import {SCRIPT_MAP} from '../../model/const/script';
import {filter} from 'rxjs/operators';
import {StateService} from './state.service';
import {PianoService} from '@shared/service/piano/piano.service';

interface Cleverpush {
  push: (payload: string[]) => void;
}

@Injectable({
  providedIn: 'root',
})
export class CleverpushService {

  protected projectId: string;

  protected cleverpush: Cleverpush;

  constructor(portalService: PortalService,
              private loadScript: LoadExternalScriptService,
              private piano: PianoService,
              stateService: StateService<any>) {
    const cleverpushChannels = stateService.get(CLEVERPUSH_CHANNELS);
    const currentPortal = portalService.currentPortal();
    this.projectId = cleverpushChannels[currentPortal] || null;
  }

  init() {
    if (!this.projectId) {
      return;
    }
    SCRIPT_MAP[ScriptId.cleverpush] = `https://static.cleverpush.com/channel/loader/${this.projectId}.js`;

    this.loadScript.loadScript(ScriptId.cleverpush).subscribe(value => {
      this.cleverpush = value.scriptVar;
      this.piano.user$.pipe(
        filter(user => user.valid),
      ).subscribe(user => {
        this.setAttribute('user_id', user.uid);
        this.setAttribute('name', user.firstName + '' + user.lastName);
      });
    });
  }

  protected setAttribute(id: string, value: string) {
    this.cleverpush.push(['setAttribute', id, value]);
  }

}
