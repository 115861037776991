<div class="hero" [ngClass]="[model.classes]">

  <picimage *ngIf="model.image"
            [model]="model.image">
  </picimage>

  <div class="heroCont">
    <p *ngIf="model.tags" class="teaser-tagLine">
      <tag
        [model]="tag"
        *ngFor="let tag of model.tags"></tag>
    </p>

    <a class="teaser-title"
      [portalRouterLink]="model.link">
      <h2 *ngIf="model.title"><span>{{model.title}}</span></h2>
    </a>
  </div>

</div>
