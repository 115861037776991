/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../no-consent/no-consent.component.ngfactory";
import * as i3 from "../no-consent/no-consent.component";
import * as i4 from "../../../../shared/service/didomi.service";
import * as i5 from "./cleverpush-button.component";
var styles_CleverpushButtonComponent = [];
var RenderType_CleverpushButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CleverpushButtonComponent, data: {} });
export { RenderType_CleverpushButtonComponent as RenderType_CleverpushButtonComponent };
function View_CleverpushButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "cleverpush-content-button"], ["data-button-background-color", "#000000"], ["data-button-color", "#ffffff"], ["data-text", "Indem Sie unsere Benachrichtigungen zulassen, erkl\u00E4ren Sie sich damit einverstanden, dass wir Ihre Browser ID nutzen, um Ihnen Push-Benachrichtigungen zukommen zu lassen. Sie k\u00F6nnen Ihre Einwilligung jederzeit mit Wirkung f\u00FCr die Zukunft widerrufen. Weitere Informationen finden Sie in unserer Datenschutzerkl\u00E4rung."], ["data-type", "box-simple"]], [[1, "data-button-text", 0], [1, "data-button-text-subscribed", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonText; var currVal_1 = _co.confirmedText; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_CleverpushButtonComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "cleverpush-content-button"], ["data-button-background-color", "#000000"], ["data-button-color", "#ffffff"], ["data-type", "button-simple"]], [[1, "data-button-text", 0], [1, "data-button-text-subscribed", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonText; var currVal_1 = _co.confirmedText; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_CleverpushButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CleverpushButtonComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CleverpushButtonComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isBox; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isBox; _ck(_v, 4, 0, currVal_1); }, null); }
function View_CleverpushButtonComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i2.View_NoConsentComponent_0, i2.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i3.NoConsentComponent, [i4.DidomiService], { vendor: [0, "vendor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CleverpushButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_CleverpushButtonComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["noContent", 2]], null, 0, null, View_CleverpushButtonComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.showCleverPush$)); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CleverpushButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cleverpushbutton", [], null, null, null, View_CleverpushButtonComponent_0, RenderType_CleverpushButtonComponent)), i0.ɵdid(1, 49152, null, 0, i5.CleverpushButtonComponent, [i4.DidomiService], null, null)], null, null); }
var CleverpushButtonComponentNgFactory = i0.ɵccf("cleverpushbutton", i5.CleverpushButtonComponent, View_CleverpushButtonComponent_Host_0, {}, {}, []);
export { CleverpushButtonComponentNgFactory as CleverpushButtonComponentNgFactory };
