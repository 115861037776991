<a href="#" class="headerContainer-toggle" [class.isActive]="showPopUp" (click)="togglePopUp()" [gtm]="gtmId">
  <iconbutton [icon]="'bars'"></iconbutton>
  <span>Menu</span>
</a>
<div class="navigation" [class.isHidden]="!showPopUp" (click)="clickedInside($event)" swipe
     (swipedRight)="togglePopUp()">
  <div class="navigation-inner">
    <div class="navigation-top">
      <iconbutton [icon]="'times'" class="navigation-close" (click)="togglePopUp()"></iconbutton>
    </div>
    <button class="navigation-search" (click)="toggleSearch()" *ngIf="!isKurier">
      <iconbutton [icon]="'search'"></iconbutton>
      Suche
    </button>
    <div *ngFor="let m of model.main" accordeonCtrl>
      <ul>
        <li *ngIf="m.children" [ngClass]="['navigation-menu-item', m.cssClass]"
            [class.isActive]="m.isActive(model.currentUri)">
          <accordeon [title]="m.title" [link]="m.portalRoute" [gtmId]="gtmId">
            <ul class="navigation-submenu">
              <li *ngFor="let submenu of m.children" [class.isActive]="submenu.isActive(model.currentUri)">
                <a [gtm]="gtmId" [portalRouterLink]="submenu.portalRoute">{{submenu.title}}</a>
              </li>
            </ul>
          </accordeon>
        </li>
        <li *ngIf="!m.children" [ngClass]="['navigation-menu-item', m.cssClass]"
            [class.isActive]="m.isActive(model.currentUri)">
          <a [gtm]="gtmId" [portalRouterLink]="m.portalRoute">{{m.title}}</a>
        </li>
      </ul>
    </div>
    <portals [gtmId]="gtmId"></portals>
    <div class="navigation-secondaryMenu">
      <ul>
        <li *ngFor="let sub of model.subMenu"><a [portalRouterLink]="sub.portalRoute">{{sub.title}}</a></li>
        <li *ngIf="isKurier">
          <button class="navigation-search" (click)="toggleSearch()">
            <iconbutton [icon]="'search'"></iconbutton>
            Suchen
          </button>
        </li>
      </ul>
    </div>
    <div *ngIf="model.social.length > 0" class="navigation-social">
      <iconbutton
        *ngFor="let social of model.social"
        [icon]="shareMap[social.id]"
        [link]="social.portalRoute"></iconbutton>
    </div>
  </div>
  <span class="navigation-bg"></span>
</div>
