import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {PortalService} from '../../shared/service/portal.service';
import {MenuService} from '../../shared/service/menu.service';
import {MenuModel} from '../../model/menu/menu';
import {Observable} from 'rxjs';
import {PortalRoute} from '../../model/teaser/portalRoute';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'footerContainer',
  templateUrl: 'footerContainer.component.html'
})
export class FooterContainerComponent {
  currentPortal = '';
  homeLink: PortalRoute;
  footerMenu$: Observable<MenuModel[]>;

  constructor(protected readonly portalService: PortalService,
              protected menuService: MenuService) {
    this.currentPortal = portalService.currentPortal();
    this.homeLink = PortalRoute.fromHostname(this.currentPortal, '/');
    this.footerMenu$ = this.menuService.get('footer');
  }
}
