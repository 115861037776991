import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { BrowserRef } from '../service/browser.ref';
import { filter, first, map, share, switchMap } from 'rxjs/operators';
import { DataLayerType } from '@model/enum/datalayer';
import { LoadExternalScriptService } from '../service/loadExternalScript.service';
import { RouteService } from '../service/route.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { PortalService } from '@shared/service/portal.service';
import { BehaviorSubject } from 'rxjs';
import createRandomId from '@util/randomId';
import { VendorTypes } from '@model/enum/vendorTypes';
import { DidomiService } from '@shared/service/didomi.service';
import * as i0 from "@angular/core";
import * as i1 from "../service/route.service";
import * as i2 from "../service/loadExternalScript.service";
import * as i3 from "../service/browser.ref";
import * as i4 from "../service/didomi.service";
import * as i5 from "../service/portal.service";
var GooglePublisherTagService = /** @class */ (function () {
    function GooglePublisherTagService(prodmode, platformId, routeService, loadScript, browserRef, zone, didomiService, portalService) {
        var _this = this;
        this.prodmode = prodmode;
        this.routeService = routeService;
        this.loadScript = loadScript;
        this.browserRef = browserRef;
        this.zone = zone;
        this.didomiService = didomiService;
        this.portalService = portalService;
        this.documentVisible = new BehaviorSubject(true);
        this.definedSlots = new Map();
        if (isPlatformBrowser(platformId)) {
            document.addEventListener('visibilitychange', function () {
                _this.documentVisible.next(!document.hidden);
            });
            window.onfocus = function () {
                _this.documentVisible.next(true);
            };
            window.onblur = function () {
                _this.documentVisible.next(false);
            };
        }
        if (!isPlatformServer(platformId)) {
            this.googletag = {
                cmd: [function () {
                        _this.googletag.pubads().enableSingleRequest();
                        _this.googletag.enableServices();
                        _this.googletag.pubads().enableLazyLoad({
                            fetchMarginPercent: 50,
                            renderMarginPercent: 25,
                            mobileScaling: 0.5,
                        });
                        if (_this.portalService.currentPortal() === 'kurierat') {
                            _this.googletag.pubads().setPublisherProvidedId(_this.getPublisherProvidedId());
                        }
                    }]
            };
            didomiService.checkVendor$(VendorTypes.googleAds).subscribe(function (consent) {
                var gdprTargeting = consent ?
                    [GooglePublisherTagService.DFP_GDPR_CLEAN] :
                    [GooglePublisherTagService.DFP_GDPR_CLEAN, GooglePublisherTagService.DFP_GDPR_TRACK];
                _this.googletag.cmd.push(function () {
                    _this.log('gpt setTargeting', GooglePublisherTagService.DFP_GDPR_KEY, gdprTargeting);
                    _this.googletag.pubads().setTargeting(GooglePublisherTagService.DFP_GDPR_KEY, gdprTargeting);
                });
            });
            this.routeService.routeInfo.subscribe(function () { return _this.clearKAMVideo(); });
            this.routeService.routeInfo.pipe(filter(function (route) { return route.dataLayer.AdsEnabled; })).subscribe(function (route) {
                _this.setTargeting(route);
            });
            var onStable$ = this.routeService.routeInfo.pipe(switchMap(function (route) { return _this.zone.onStable.asObservable().pipe(first(), map(function () { return route; })); }), share());
            onStable$.pipe(filter(function (route) { return route.dataLayer.AdsEnabled; })).subscribe(function () {
                _this.displayAds();
            });
        }
    }
    Object.defineProperty(GooglePublisherTagService.prototype, "googletag", {
        get: function () {
            return this.browserRef.window.googletag;
        },
        set: function (googletag) {
            this.browserRef.window.googletag = googletag;
        },
        enumerable: true,
        configurable: true
    });
    GooglePublisherTagService.prototype.addSlot = function (model) {
        var _this = this;
        if (model.dimensions.length === 0) {
            return;
        }
        this.definedSlots.set(model.id, model);
        this.routeService.routeInfo.pipe(first()).subscribe(function () {
            _this.googletag.cmd.push(function () {
                _this.log('gpt defineSlot', model.adUnitPath, model.id);
                model.slot = _this.googletag.defineSlot(model.adUnitPath, model.dimensions, model.id);
                if (!model.slot) {
                    return;
                }
                model.slot.setCollapseEmptyDiv(true, true);
                model.slot.setForceSafeFrame(model.setForceSafeFrame);
                model.slot.addService(_this.googletag.pubads());
            });
        });
    };
    GooglePublisherTagService.prototype.clearSlot = function (model) {
        var _this = this;
        this.googletag.cmd.push(function () {
            _this.log('gpt clearSlot', model.adUnitPath, model.id);
            _this.googletag.pubads().clear([model.slot]);
        });
    };
    GooglePublisherTagService.prototype.destroySlot = function (model) {
        var _this = this;
        this.definedSlots.delete(model.id);
        this.googletag.cmd.push(function () {
            _this.log('gpt destroySlot', model.adUnitPath, model.id);
            _this.googletag.destroySlots([model.slot]);
        });
    };
    GooglePublisherTagService.prototype.refreshSlot = function (model) {
        var _this = this;
        this.googletag.cmd.push(function () {
            _this.log('gpt refreshSlot', model.adUnitPath, model.id);
            _this.googletag.pubads().refresh([model.slot], { changeCorrelator: false });
        });
    };
    GooglePublisherTagService.prototype.clearKAMVideo = function () {
        // tslint:disable-next-line:no-string-literal
        var fsvReset = this.browserRef.window['fsvReset'];
        if (fsvReset) {
            fsvReset();
        }
    };
    GooglePublisherTagService.prototype.setTargeting = function (route) {
        var _this = this;
        var targetingKeys = [
            DataLayerType.AdSection,
            DataLayerType.Channel,
            DataLayerType.Seitentyp,
            DataLayerType.Portal,
            DataLayerType.Section,
            DataLayerType.Kampagne,
            DataLayerType.ArtikelId,
            DataLayerType.Artikeltags,
        ];
        this.googletag.cmd.push(function () {
            _this.log('gpt setTargeting', route.dataLayer);
            var neuwo = route.dataLayer.Neuwo;
            if (neuwo) {
                if (neuwo.tags) {
                    _this.googletag.pubads().setTargeting('ct_topic', neuwo.tags);
                }
                if (neuwo.brand_safety) {
                    _this.googletag.pubads().setTargeting('ct_bs', neuwo.brand_safety);
                }
                if (neuwo.marketing_categories.iab_tier_1) {
                    _this.googletag.pubads().setTargeting('ct_iab1', neuwo.marketing_categories.iab_tier_1);
                }
                if (neuwo.marketing_categories.iab_tier_2) {
                    _this.googletag.pubads().setTargeting('ct_iab2', neuwo.marketing_categories.iab_tier_2);
                }
            }
            else {
                _this.googletag.pubads().clearTargeting('ct_topic');
                _this.googletag.pubads().clearTargeting('ct_bs');
                _this.googletag.pubads().clearTargeting('ct_iab1');
                _this.googletag.pubads().clearTargeting('ct_iab2');
            }
            targetingKeys.forEach(function (dfp) {
                if ((typeof route.dataLayer[dfp]) !== 'undefined' && route.dataLayer[dfp] !== null) {
                    _this.googletag.pubads().setTargeting(dfp, route.dataLayer[dfp]);
                }
                else {
                    if (_this.googletag.pubads().getTargeting(dfp).length) {
                        _this.googletag.pubads().clearTargeting(dfp);
                    }
                }
            });
            var aw = 'availWidth';
            if (_this.browserRef.window.innerWidth - 985 >= 300) {
                _this.googletag.pubads().setTargeting(aw, 'larger_300');
            }
            else {
                if (_this.googletag.pubads().getTargeting(aw).length) {
                    _this.googletag.pubads().clearTargeting(aw);
                }
            }
        });
    };
    GooglePublisherTagService.prototype.displayAds = function () {
        var _this = this;
        if (this.definedSlots.size > 0) {
            var nextAd_1 = this.definedSlots.values().next().value;
            this.googletag.cmd.push(function () {
                _this.log('gpt displayAds', nextAd_1.id);
                _this.googletag.display(nextAd_1.id);
            });
        }
    };
    GooglePublisherTagService.prototype.getPublisherProvidedId = function () {
        var id = localStorage.getItem(GooglePublisherTagService.PPID);
        if (!id) {
            id = createRandomId(32);
            localStorage.setItem(GooglePublisherTagService.PPID, id);
        }
        return id;
    };
    GooglePublisherTagService.prototype.log = function (message) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        if (!this.prodmode) {
            console.log.apply(console, tslib_1.__spread([message], optionalParams));
        }
    };
    GooglePublisherTagService.DFP_GDPR_KEY = 'GDPR';
    GooglePublisherTagService.DFP_GDPR_CLEAN = 'cleanAd';
    GooglePublisherTagService.DFP_GDPR_TRACK = 'trackingAd';
    GooglePublisherTagService.PPID = 'PPUID';
    GooglePublisherTagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GooglePublisherTagService_Factory() { return new GooglePublisherTagService(i0.ɵɵinject("prod_mode"), i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i1.RouteService), i0.ɵɵinject(i2.LoadExternalScriptService), i0.ɵɵinject(i3.BrowserRef), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i4.DidomiService), i0.ɵɵinject(i5.PortalService)); }, token: GooglePublisherTagService, providedIn: "root" });
    return GooglePublisherTagService;
}());
export { GooglePublisherTagService };
