/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../football-table/football-table.component.ngfactory";
import * as i2 from "../football-table/football-table.component";
import * as i3 from "../../../common/ui/tabBar/tabBar.component.ngfactory";
import * as i4 from "../../../common/ui/tabBar/tabBar.component";
import * as i5 from "@angular/common";
import * as i6 from "./football-table-league.component";
import * as i7 from "../../../../shared/service/football.service";
var styles_FootballTableLeagueComponent = [];
var RenderType_FootballTableLeagueComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FootballTableLeagueComponent, data: {} });
export { RenderType_FootballTableLeagueComponent as RenderType_FootballTableLeagueComponent };
function View_FootballTableLeagueComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-football-table", [], null, null, null, i1.View_FootballTableComponent_0, i1.RenderType_FootballTableComponent)), i0.ɵdid(2, 49152, null, 0, i2.FootballTableComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FootballTableLeagueComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [["class", "footballTableLeague-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "tabbar", [], null, [[null, "tabChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tabChanged" === en)) {
        var pd_0 = (_co.changeTab($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_TabBarComponent_0, i3.RenderType_TabBarComponent)), i0.ɵdid(4, 114688, null, 0, i4.TabBarComponent, [], { model: [0, "model"] }, { tabChanged: "tabChanged" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FootballTableLeagueComponent_2)), i0.ɵdid(6, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tabModel; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.ngIf.tables; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.ngIf.heading; _ck(_v, 2, 0, currVal_0); }); }
export function View_FootballTableLeagueComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "footballTableLeague"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FootballTableLeagueComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.footballStandingTables$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FootballTableLeagueComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-football-table-league", [], null, null, null, View_FootballTableLeagueComponent_0, RenderType_FootballTableLeagueComponent)), i0.ɵdid(1, 49152, null, 0, i6.FootballTableLeagueComponent, [i7.FootballService], null, null)], null, null); }
var FootballTableLeagueComponentNgFactory = i0.ɵccf("app-football-table-league", i6.FootballTableLeagueComponent, View_FootballTableLeagueComponent_Host_0, {}, {}, []);
export { FootballTableLeagueComponentNgFactory as FootballTableLeagueComponentNgFactory };
