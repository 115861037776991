export const SHARE_CLASS_MAP = {
  facebook: 'facebook-f',
  twitter: 'twitter',
  pinterest: 'pinterest-p',
  googleplus: 'google-plus-g',
  whatsapp: 'whatsapp',
  instagram: 'instagram',
  mail: 'envelope',
  xing: 'xing',
  linkedin: 'linkedin-in',
  flipboard: 'flipboard',
  pocket: 'pocket',
  youtube: 'youtube'
};
