import {VendorTypes} from '@model/enum/vendorTypes';

export const VENDOR_PARAGRAPH_IMAGE_MAP = {
  [VendorTypes.PLACEHOLDER]: '',
  [VendorTypes.facebook]: 'facebook.jpg',
  [VendorTypes.instagram]: 'instagram.jpg',
  [VendorTypes.degrees23]: 'degrees23.jpg',
  [VendorTypes.pinPoll]: 'pinPoll.jpg',
  [VendorTypes.podigee]: 'podigee.png',
  [VendorTypes.simplecast]: 'simplecast.png',
  [VendorTypes.infonline]: '',
  [VendorTypes.cleverPush]: 'cleverPush.jpg',
  [VendorTypes.riddle]: 'riddle.jpg',
  [VendorTypes.pinterest]: 'pinterest.jpg',
  [VendorTypes.tikTok]: 'tikTok.jpg',
  [VendorTypes.twitter]: 'twitter.jpg',
  [VendorTypes.youTube]: 'youTube.jpg',
  [VendorTypes.newsletter]: 'newsletter.jpg',
  [VendorTypes.competition]: 'competition.jpg',
  [VendorTypes.apaVideo]: 'apaVideo.jpg',
  [VendorTypes.vimeo]: 'vimeo.jpg',
  [VendorTypes.soundCloud]: 'soundCloud.jpg',
  [VendorTypes.outbrain]: 'outbrain.jpg',
  [VendorTypes.apaGrafiken]: 'apaGrafik.jpg',
  [VendorTypes.adition]: 'contentgarden-teaser.jpg',
  [VendorTypes.infogram]: 'degrees23.jpg',
  [VendorTypes.datawrapper]: 'degrees23.jpg',
  [VendorTypes.googleMaps]: 'googleMaps.jpg',
  [VendorTypes.googleAds]: 'googleAds.jpg',
  [VendorTypes.googleAnalytics]: '',
  [VendorTypes.googleTagManager]: '',
  [VendorTypes.piano]: '',
  [VendorTypes.flourish]: 'degrees23.jpg',
  [VendorTypes.spotify]: 'spotify.png',
  [VendorTypes.activeCampaign]: 'newsletter.jpg',
  [VendorTypes.paypal]: 'paypal.png',
  [VendorTypes.amazon]: 'amazon.jpg',
  [VendorTypes.art19]: 'art19.jpg',
  [VendorTypes.linkedIn]: 'linkedin.jpg',
  [VendorTypes.acast]: 'acast.jpg',
  [VendorTypes.snowplow]: '',
  [VendorTypes.kDigital]: '',
  [VendorTypes.assertiveYield]: '',
};

export const VENDOR_NAME_MAP = {
  [VendorTypes.PLACEHOLDER]: '',
  [VendorTypes.facebook]: 'Facebook',
  [VendorTypes.instagram]: 'Instagram',
  [VendorTypes.degrees23]: '23Degree',
  [VendorTypes.pinPoll]: 'Pinpoll GmbH (Private Limited)',
  [VendorTypes.podigee]: 'Podigee GmbH',
  [VendorTypes.simplecast]: 'Simplecast Audios Ventures, Inc',
  [VendorTypes.infonline]: 'INFOnline GmbH',
  [VendorTypes.cleverPush]: 'CleverPush GmbH',
  [VendorTypes.riddle]: 'Riddle Technologies AG',
  [VendorTypes.pinterest]: 'Pinterest',
  [VendorTypes.tikTok]: 'TikTok',
  [VendorTypes.twitter]: 'Twitter',
  [VendorTypes.youTube]: 'Youtube',
  [VendorTypes.piano]: 'Piano Software Inc.',
  [VendorTypes.newsletter]: 'Piano Software Inc.',
  [VendorTypes.competition]: 'Piano Software Inc.',
  [VendorTypes.apaVideo]: 'APA-Videoplayer',
  [VendorTypes.vimeo]: 'Vimeo',
  [VendorTypes.soundCloud]: 'Soundcloud',
  [VendorTypes.outbrain]: 'Outbrain UK Ltd',
  [VendorTypes.apaGrafiken]: 'APA-Wahltool',
  [VendorTypes.adition]: 'ADITION technologies AG',
  [VendorTypes.infogram]: 'Infogram',
  [VendorTypes.datawrapper]: 'Datawrapper',
  [VendorTypes.googleAds]: 'Google Advertising Products',
  [VendorTypes.googleMaps]: 'Google Maps',
  [VendorTypes.googleAnalytics]: 'Google Analytics',
  [VendorTypes.googleTagManager]: 'Google Tag Manager',
  [VendorTypes.flourish]: 'Flourish(Kiln Enterprises Ltd)',
  [VendorTypes.spotify]: 'Spotify',
  [VendorTypes.activeCampaign]: 'ActiveCampaign',
  [VendorTypes.paypal]: 'PayPal Holdings, Inc',
  [VendorTypes.amazon]: 'Amazon',
  [VendorTypes.art19]: 'Art19',
  [VendorTypes.linkedIn]: 'LinkedIn',
  [VendorTypes.acast]: 'Acast',
  [VendorTypes.snowplow]: 'snowplow.io',
  [VendorTypes.kDigital]: 'k-digital Medien GmbH & Co KG',
  [VendorTypes.assertiveYield]: 'Assertive Yield B.V',
};

export const VENDOR_PARAGRAPH_MAP = {
  [VendorTypes.PLACEHOLDER]: '',
  [VendorTypes.facebook]: 'einen Facebook Post',
  [VendorTypes.instagram]: 'einen Instagram Post',
  [VendorTypes.degrees23]: 'eine 23degrees Grafik',
  [VendorTypes.pinPoll]: 'eine PinPoll Umfrage',
  [VendorTypes.podigee]: 'einen Podigee Podcast-Player',
  [VendorTypes.simplecast]: 'einen Simplecast Podcast-Player',
  [VendorTypes.infonline]: 'ein INFOnline widget',
  [VendorTypes.cleverPush]: 'einen CleverPush Button',
  [VendorTypes.riddle]: 'eine Riddle Umfrage',
  [VendorTypes.pinterest]: 'einen Pinterest Pin',
  [VendorTypes.tikTok]: 'ein TikTok Video',
  [VendorTypes.twitter]: 'einen Twitter Post',
  [VendorTypes.youTube]: 'ein Youtube Video',
  [VendorTypes.piano]: 'ein Login',
  [VendorTypes.newsletter]: 'eine Newsletter Anmeldung',
  [VendorTypes.competition]: 'ein Quiz',
  [VendorTypes.apaVideo]: 'ein APA Video',
  [VendorTypes.vimeo]: 'ein Vimeo Video',
  [VendorTypes.soundCloud]: 'einen Soundcloud Player',
  [VendorTypes.outbrain]: 'einen Outbrain Feed',
  [VendorTypes.apaGrafiken]: 'eine APA Grafik',
  [VendorTypes.adition]: 'eine Adition Werbung',
  [VendorTypes.infogram]: 'eine Infogram Grafik',
  [VendorTypes.datawrapper]: 'eine datawrapper Grafik',
  [VendorTypes.googleAds]: 'eine Google Werbung',
  [VendorTypes.googleMaps]: 'eine Google Map',
  [VendorTypes.googleAnalytics]: 'Google Analytics',
  [VendorTypes.googleTagManager]: 'Google Tag Manager',
  [VendorTypes.flourish]: 'eine Flourish Grafik',
  [VendorTypes.spotify]: 'einen Spotify Player',
  [VendorTypes.activeCampaign]: 'ein ActiveCampaign Widget',
  [VendorTypes.paypal]: 'ein PayPal Widget',
  [VendorTypes.amazon]: 'ein Amazon widget',
  [VendorTypes.art19]: 'einen Art19 Player',
  [VendorTypes.linkedIn]: 'ein LinkedIn embed',
  [VendorTypes.acast]: 'ein Acast embed',
  [VendorTypes.snowplow]: '',
  [VendorTypes.kDigital]: '',
  [VendorTypes.assertiveYield]: '',
};

export const VENDOR_ID_MAP: { [key: number]: number | string } = {
  [VendorTypes.facebook]: 'facebook',
  [VendorTypes.instagram]: 'c:instagram',
  [VendorTypes.degrees23]: 'c:23degree-TH2WmEkR',
  [VendorTypes.pinPoll]: 727,
  [VendorTypes.podigee]: 'c:podigeegm-ckk8hi2w',
  [VendorTypes.simplecast]: 'c:simplecast-PfFtJKkL',
  [VendorTypes.infonline]: 730,
  [VendorTypes.cleverPush]: 1139,
  [VendorTypes.riddle]: 'c:riddle-afygamYN',
  [VendorTypes.pinterest]: 'c:pinterest',
  [VendorTypes.tikTok]: 'c:tiktok-KZAUQLZ9',
  [VendorTypes.twitter]: 'twitter',
  [VendorTypes.youTube]: 'c:youtube',
  [VendorTypes.piano]: 412,
  [VendorTypes.newsletter]: 412,
  [VendorTypes.competition]: 412,
  [VendorTypes.apaVideo]: 'c:apa-GB764ZD7',
  [VendorTypes.vimeo]: 'c:vimeo',
  [VendorTypes.soundCloud]: 'c:soundclou-CGaYMFFa',
  [VendorTypes.outbrain]: 164,
  [VendorTypes.apaGrafiken]: 'c:apa-GB764ZD7',
  [VendorTypes.adition]: 39,
  [VendorTypes.infogram]: 'c:infogram-Gqd4C9Gj',
  [VendorTypes.datawrapper]: 'c:datawrappe-NztaFDbw',
  [VendorTypes.googleAds]: 'google',
  [VendorTypes.googleMaps]: 'c:googlemap-cRJEW8Pp',
  [VendorTypes.googleAnalytics]: 'c:googleana-4TXnJigR',
  [VendorTypes.googleTagManager]: 'c:googletag-Lpt6GgbT',
  [VendorTypes.flourish]: 'c:flourish-Z3fFJ4mK',
  [VendorTypes.spotify]: 'c:spotify-embed',
  [VendorTypes.activeCampaign]: 'c:activecampaign',
  [VendorTypes.paypal]: 'c:paypal-shopping',
  [VendorTypes.amazon]: 'amazon',
  [VendorTypes.art19]: 20007,
  [VendorTypes.linkedIn]: 804,
  [VendorTypes.acast]: 'c:acast-4nKTYZPH',
  [VendorTypes.snowplow]: 'c:snowplowi-Hdg3gRm7',
  [VendorTypes.assertiveYield]: 'c:assertive-aNjYQjhG',
  [VendorTypes.kDigital]: 'c:kdigital-zkLW9ybx',
};
