/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/formControls/select/select.component.ngfactory";
import * as i2 from "../../../common/ui/formControls/select/select.component";
import * as i3 from "../../../common/ui/formControls/checkbox/checkbox.component.ngfactory";
import * as i4 from "../../../common/ui/formControls/checkbox/checkbox.component";
import * as i5 from "@angular/common";
import * as i6 from "../../../../shared/directive/portalRoute.directive";
import * as i7 from "@angular/router";
import * as i8 from "../../../../shared/service/portal.service";
import * as i9 from "../../../../shared/service/browser.ref";
import * as i10 from "../../../common/ui/accordeon/accordeon.component.ngfactory";
import * as i11 from "../../../common/ui/accordeon/accordeon.component";
import * as i12 from "../../../../shared/service/oewa.service";
import * as i13 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i14 from "../../../common/ui/linkBar/linkBar.component";
import * as i15 from "./screenings.component";
import * as i16 from "../../../../shared/service/api.service";
import * as i17 from "../../../../shared/service/gtm.service";
var styles_ScreeningsComponent = [];
var RenderType_ScreeningsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScreeningsComponent, data: {} });
export { RenderType_ScreeningsComponent as RenderType_ScreeningsComponent };
function View_ScreeningsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.screeningsModel.emptyMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_ScreeningsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "formselect", [], null, [[null, "valueSelectedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueSelectedChange" === en)) {
        var pd_0 = ((_v.parent.context.$implicit.valueSelected = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueSelectedChange" === en)) {
        var pd_1 = (_co.onChangeSelect(_v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SelectComponent_0, i1.RenderType_SelectComponent)), i0.ɵdid(2, 49152, null, 0, i2.SelectComponent, [], { label: [0, "label"], values: [1, "values"], valueSelected: [2, "valueSelected"] }, { valueSelectedChange: "valueSelectedChange" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; var currVal_1 = _v.parent.context.$implicit.values; var currVal_2 = _v.parent.context.$implicit.valueSelected; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ScreeningsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "p", [["class", "checkbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "formcheckbox", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_v.parent.context.$implicit.valueSelected = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = (_co.onChangeCheckbox(_v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_CheckboxComponent_0, i3.RenderType_CheckboxComponent)), i0.ɵdid(2, 49152, null, 0, i4.CheckboxComponent, [], { label: [0, "label"], value: [1, "value"] }, { valueChange: "valueChange" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; var currVal_1 = _v.parent.context.$implicit.valueSelected; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ScreeningsComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ScreeningsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "filterList-filter"]], null, null, null, null, null)), i0.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(3, 2), i0.ɵdid(4, 16384, null, 0, i5.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScreeningsComponent_5)), i0.ɵdid(6, 278528, null, 0, i5.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScreeningsComponent_6)), i0.ɵdid(8, 278528, null, 0, i5.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScreeningsComponent_7)), i0.ɵdid(10, 278528, null, 0, i5.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var currVal_0 = "filterList-filter"; var currVal_1 = _ck(_v, 3, 0, ("type-" + _v.context.$implicit.type), ("filter-" + _v.context.$implicit.label)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.type; _ck(_v, 4, 0, currVal_2); var currVal_3 = "select"; _ck(_v, 6, 0, currVal_3); var currVal_4 = "checkbox"; _ck(_v, 8, 0, currVal_4); var currVal_5 = "toggle"; _ck(_v, 10, 0, currVal_5); }, null); }
function View_ScreeningsComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "filterList-results-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "filterList-results-key"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "a", [], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 540672, null, 0, i6.PortalRouteDirective, [i7.Router, i0.ElementRef, i8.PortalService, i9.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "filterList-results-value"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.link; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).target; var currVal_1 = i0.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_3); var currVal_4 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_4); }); }
function View_ScreeningsComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "accordeon", [], [[1, "class", 0]], null, null, i10.View_AccordeonComponent_0, i10.RenderType_AccordeonComponent)), i0.ɵdid(1, 49152, null, 0, i11.AccordeonComponent, [i0.ChangeDetectorRef, i12.OewaService], { title: [0, "title"], link: [1, "link"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "div", [["class", "filterList-results"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScreeningsComponent_10)), i0.ɵdid(4, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit.text; var currVal_2 = _v.context.$implicit.link; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co.screeningsModel.list[_v.context.index]; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
function View_ScreeningsComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScreeningsComponent_9)), i0.ɵdid(2, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.screeningsModel.accordeon; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ScreeningsComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "filterList-results-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "filterList-results-key"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "a", [], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 540672, null, 0, i6.PortalRouteDirective, [i7.Router, i0.ElementRef, i8.PortalService, i9.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "filterList-results-value"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.link; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).target; var currVal_1 = i0.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_3); var currVal_4 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_4); }); }
function View_ScreeningsComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "filterList-results"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScreeningsComponent_12)), i0.ɵdid(2, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.screeningsModel.list[0]; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ScreeningsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "filterList"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "linkbar", [], null, null, null, i13.View_LinkBarComponent_0, i13.RenderType_LinkBarComponent)), i0.ɵdid(2, 49152, null, 0, i14.LinkBarComponent, [], { model: [0, "model"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "filterList-filters"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScreeningsComponent_4)), i0.ɵdid(5, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScreeningsComponent_8)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["simple", 2]], null, 0, null, View_ScreeningsComponent_11))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkBarModel; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.screeningsModel.filter; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.screeningsModel.accordeon.length > 0); var currVal_3 = i0.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_2, currVal_3); }, null); }
function View_ScreeningsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScreeningsComponent_2)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScreeningsComponent_3)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.screeningsModel.emptyMessage; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.screeningsModel.emptyMessage; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ScreeningsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_ScreeningsComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.model$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ScreeningsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "screenings", [], null, null, null, View_ScreeningsComponent_0, RenderType_ScreeningsComponent)), i0.ɵdid(1, 49152, null, 0, i15.ScreeningsComponent, [i16.ApiService, i17.GoogleTagManagerService, i12.OewaService], null, null)], null, null); }
var ScreeningsComponentNgFactory = i0.ɵccf("screenings", i15.ScreeningsComponent, View_ScreeningsComponent_Host_0, {}, {}, []);
export { ScreeningsComponentNgFactory as ScreeningsComponentNgFactory };
