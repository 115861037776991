/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../image/image.component.ngfactory";
import * as i2 from "../../image/image.component";
import * as i3 from "../../../../shared/service/image.service";
import * as i4 from "../../../../shared/service/overlay.service";
import * as i5 from "../../ui/svgIcon/svgIcon.component.ngfactory";
import * as i6 from "../../ui/svgIcon/svgIcon.component";
import * as i7 from "../../ui/tag/tag.component.ngfactory";
import * as i8 from "../../ui/tag/tag.component";
import * as i9 from "../../../../shared/directive/portalRoute.directive";
import * as i10 from "@angular/router";
import * as i11 from "../../../../shared/service/portal.service";
import * as i12 from "../../../../shared/service/browser.ref";
import * as i13 from "../../gtmTrigger.directive";
import * as i14 from "@angular/common";
import * as i15 from "./opinion.component";
var styles_OpinionComponent = [];
var RenderType_OpinionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OpinionComponent, data: {} });
export { RenderType_OpinionComponent as RenderType_OpinionComponent };
function View_OpinionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "picimage", [], null, null, null, i1.View_ImageComponent_0, i1.RenderType_ImageComponent)), i0.ɵdid(1, 638976, null, 0, i2.ImageComponent, [i3.ImageService, i4.OverlayService, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.image; _ck(_v, 1, 0, currVal_0); }, null); }
function View_OpinionComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "comments-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "svgicon", [], [[1, "class", 0]], null, null, i5.View_SvgIconComponent_0, i5.RenderType_SvgIconComponent)), i0.ɵdid(2, 49152, null, 0, i6.SvgIconComponent, [], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_1 = "comments"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).class; _ck(_v, 1, 0, currVal_0); }); }
function View_OpinionComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tag", [], null, null, null, i7.View_TagComponent_0, i7.RenderType_TagComponent)), i0.ɵdid(1, 49152, null, 0, i8.TagComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_OpinionComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "teaser-tagLineLink"]], [[8, "innerHTML", 1], [8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 540672, null, 0, i9.PortalRouteDirective, [i10.Router, i0.ElementRef, i11.PortalService, i12.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), i0.ɵdid(2, 81920, null, 0, i13.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _v.context.$implicit.portalRoute; _ck(_v, 1, 0, currVal_3); var currVal_4 = _co.model.pretitleGtmId; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; var currVal_1 = i0.ɵnov(_v, 1).target; var currVal_2 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_OpinionComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "p", [["class", "opinionCard-author"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["von "])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "a", [["class", "opinionCard-authorLink"]], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 540672, null, 0, i9.PortalRouteDirective, [i10.Router, i0.ElementRef, i11.PortalService, i12.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.model.author.portalRoute; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.model.author.name; _ck(_v, 4, 0, currVal_3); }); }
export function View_OpinionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "div", [["class", "opinionCard"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 18, "div", [["class", "opinionCard-card"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OpinionComponent_1)), i0.ɵdid(3, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["elseBlock", 2]], null, 0, null, View_OpinionComponent_2)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "opinionCard-titleAndQuote"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "h1", [["class", "opinionCard-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "a", [], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 540672, null, 0, i9.PortalRouteDirective, [i10.Router, i0.ElementRef, i11.PortalService, i12.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵeld(10, 0, null, null, 7, "div", [["class", "opinionCard-tagAndAuthor"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 4, "p", [["class", "teaser-tagLine"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OpinionComponent_3)), i0.ɵdid(13, 278528, null, 0, i14.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OpinionComponent_4)), i0.ɵdid(15, 278528, null, 0, i14.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OpinionComponent_5)), i0.ɵdid(17, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(18, 0, null, null, 1, "a", [["class", "opinionCard-link"], ["title", "Artikel lesen"]], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 19).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(19, 540672, null, 0, i9.PortalRouteDirective, [i10.Router, i0.ElementRef, i11.PortalService, i12.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.image; var currVal_1 = i0.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = _co.model.link; _ck(_v, 8, 0, currVal_4); var currVal_6 = _co.model.tags; _ck(_v, 13, 0, currVal_6); var currVal_7 = _co.model.tagLine; _ck(_v, 15, 0, currVal_7); var currVal_8 = _co.model.author; _ck(_v, 17, 0, currVal_8); var currVal_11 = _co.model.link; _ck(_v, 19, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵnov(_v, 8).target; var currVal_3 = i0.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_5 = _co.model.title; _ck(_v, 9, 0, currVal_5); var currVal_9 = i0.ɵnov(_v, 19).target; var currVal_10 = i0.ɵnov(_v, 19).href; _ck(_v, 18, 0, currVal_9, currVal_10); }); }
export function View_OpinionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-opinion", [], null, null, null, View_OpinionComponent_0, RenderType_OpinionComponent)), i0.ɵdid(1, 49152, null, 0, i15.OpinionComponent, [], null, null)], null, null); }
var OpinionComponentNgFactory = i0.ɵccf("app-opinion", i15.OpinionComponent, View_OpinionComponent_Host_0, {}, {}, []);
export { OpinionComponentNgFactory as OpinionComponentNgFactory };
