import {ChangeDetectionStrategy, Component} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {IFootballComponent, IFootballRound} from '@model/content/footballTable/payload';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {FootballService} from '@shared/service/football.service';
import {FootballTableWrapper} from '@model/content/footballTable/footballTableWrapper';
import {map, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-football-table-cup',
  templateUrl: './football-table-cup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FootballTableCupComponent implements DynamicComponent<IFootballComponent> {

  rounds$: Observable<{ value: IFootballRound, name: string }[]>;
  table$: Observable<FootballTableWrapper>;
  protected currentRound = new Subject<IFootballRound>();
  heading = new BehaviorSubject<string>('');

  constructor(
    private footballService: FootballService,
  ) {
  }

  get currentRound$() {
    return this.currentRound.asObservable();
  }

  initModel(model: IFootballComponent): void {
    const y = +model.data.footballSeason.split('/')[0];
    let year = y + '/' + (y + 1);
    if (
      model.data.footballLeague === 'world-cup' ||
      model.data.footballLeague === 'wc-qualification-europe' ||
      model.data.footballLeague === 'european-championship' ||
      model.data.footballLeague === 'uefa-women-euro'
    ) {
      year = year.split('/')[0];
    }

    if (model.data.footballLeague === 'world-cup') {
      this.table$ = this.footballService
        .fromWorldCupStandings$(year, model.data.footballGroup ? 'Group ' + model.data.footballGroup.toLocaleUpperCase() : undefined);
    } else if (model.data.footballCup || model.data.footballLeague === 'em_quali') {
      if (model.title) {
        this.heading.next(model.title);
      }
      this.table$ = this.footballService.getEmQualification(model.data.footballSeason, model.data.footballGroup);
    } else {
      this.rounds$ = this.footballService.getRoundsForSelection(model.data.footballLeague, year)
        .pipe(
          map(rounds => {
            if (model.data.footballLeague === 'uefa-nations-league') {
              const firstStageId = rounds[0].value.stage_id;
              return rounds.filter(r => r.value.stage_id === firstStageId).reverse();
            }
            if (model.data.footballLeague === 'uefa-women-euro') {
              const firstStageId = rounds[0].value.stage_id;
              return rounds.filter(r => r.value.stage_id === firstStageId);
            }
            return rounds;
          }),
          tap(rounds => this.currentRound.next(rounds[0].value))
        );
      this.table$ = this.currentRound$.pipe(
        switchMap(round => {
          if (model.data.footballLeague === 'uefa-nations-league') {
            return this.footballService.fromNationsLeagueStandings$(year, round);
          }
          if (model.data.footballLeague === 'uefa-women-euro' && model.data.footballGroup) {
            return this.footballService.fromWomanCupStandings$(model.data.footballGroup, round, year);
          }
          return this.footballService.fromCupStandings$(model.data.footballLeague, round, year);
        }),
        tap(table => this.heading.next(table.heading)),
        map(table => {
          if (model.data.footballGroup) {
            if (model.data.footballLeague === 'uefa-nations-league') {
              table.tables = table.tables.filter(t => {
                const league = t.heading.split(' ')[1].toLocaleLowerCase();
                const group = t.heading.split(' ')[3];
                return model.data.footballGroup === league + group;
              });
            } else {
              if (model.data.footballLeague !== 'uefa-women-euro') {
                table.tables = table.tables.filter(t => {
                  const group = t.heading.split(' ')[1];
                  return model.data.footballGroup.includes(group.toLocaleLowerCase());
                });
              }
            }
          }
          return table;
        }),
      );
    }
  }

  changeRound(round: { value: IFootballRound; name: string }) {
    this.currentRound.next(round.value);
  }
}
