import {NgModule} from '@angular/core';
import {HomeModule} from '@component/home/home.module';
import {AppRoutingModule} from '@routing/app.routing.module';
import {HeaderContainerModule} from '@component/header/headerContainer.module';
import {AdModule} from '@component/ads/ad.module';
import {AppComponent} from '@component/app.component';
import {FooterContainerComponent} from '@component/footer/footerContainer.component';
import {SharedModule} from '@shared/shared.module';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RequiredConsentOverlayComponent} from '@component/required-consent-overlay/required-consent-overlay.component';
import {TvInterceptor} from '@shared/interceptors/tv';
import { OverlayTemplateComponent } from './common/overlay-template/overlay-template.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterContainerComponent,
    RequiredConsentOverlayComponent,
    OverlayTemplateComponent,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TvInterceptor, multi: true},
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'hermes-cfs'}),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    AdModule,
    HeaderContainerModule,
    HomeModule,
  ],
})
export class AppModule {
}
