import * as tslib_1 from "tslib";
import { ImageModel } from '../../component/image';
import { TimeAgoUtil } from '../../../util/timeAgo';
import { ShareModel } from '../../component/share';
import { DynamicType } from '../../enum/dynamic';
import { ImageType } from '../../enum/image';
import { BoxColor } from '../../enum/box';
import { PortalRoute } from '../../teaser/portalRoute';
import { GTMTriggerId } from '../../enum/gtm';
import { TabModel } from '@model/component/tab';
var MovieSerieDetailModel = /** @class */ (function () {
    function MovieSerieDetailModel() {
        this.releaseCountry = '';
        this.badges = [];
        this.details = [];
    }
    Object.defineProperty(MovieSerieDetailModel.prototype, "typeBadge", {
        get: function () {
            return this.seasons && this.seasons.length > 0 ? 'Serie' : 'Film';
        },
        enumerable: true,
        configurable: true
    });
    MovieSerieDetailModel.deserialize = function (data, url) {
        var m = new MovieSerieDetailModel();
        m.title = data.title;
        m.ovTitle = data.ov_title;
        m.releaseYear = data.release_year;
        if (data.release_countries) {
            data.release_countries.map(function (country) {
                m.releaseCountry += country.name + ', ';
            });
        }
        m.badges = data.genres ? data.genres.map(function (g) {
            return { name: g.name, link: PortalRoute.fromUri(g.uri), gtmId: GTMTriggerId.badge };
        }) : [];
        m.teaserImage = data.trailer && data.trailer.img && ImageModel.fromMovie(data);
        m.poster = data.poster && new ImageModel(data.poster, ImageType.POSTER);
        m.playTime = data.playtime;
        m.releaseDate = TimeAgoUtil.getDate(data.release_date);
        m.classification = data.classification;
        m.shareModel = ShareModel.fromMovie(data, url);
        m.text = data.teaser_text;
        m.addDetail('Schauspieler', m.reducePerson(data.cast));
        m.addDetail('Regie', m.reducePerson(data.directors));
        m.addDetail('Kamera', m.reducePerson(data.camera));
        m.addDetail('Autor', m.reducePerson(data.authors));
        m.addDetail('Musik', m.reducePerson(data.music));
        m.addDetail('Verleih', m.reducePerson(data.distributor));
        m.website = data.website;
        m.paragraphs = data.paragraphs;
        if (data.gallery) {
            m.gallery = { data: data.gallery, type: DynamicType.gallery };
        }
        m.screenings = {
            title: data.title,
            nested: false,
            id: data.id,
            type: DynamicType.screeningsBlock,
            category: 'movie',
            county: 'Alle'
        };
        m.imdbRating = data.imdb_rating;
        m.predicate = data.predicate;
        if (data.related_movies) {
            m.movieSlider = {
                type: DynamicType.posterSlider,
                collectionName: '',
                numberOfTeasers: 0,
                title: 'Ähnliche Filme',
                items: data.related_movies,
                color: BoxColor.standard
            };
        }
        // if Serie
        if (data.seasons && data.seasons.length) {
            m.seasons = data.seasons.map(function (e, i) {
                var selected = i === 0;
                return new TabModel(i + 1, 'Staffel ' + (i + 1), e, selected);
            });
            var totalEpisodes_1 = 0;
            data.seasons.forEach(function (s) {
                s.episodes.forEach(function (e) {
                    totalEpisodes_1++;
                });
            });
            m.seasonEpisodes = data.seasons.length + ' / ' + totalEpisodes_1;
        }
        // if providers
        if (data.providers) {
            var logoMap_1 = {
                Netflix: 'netflix.svg',
                'Google Play': 'google-play.svg',
                'Disney+': 'disney-plus.svg',
                'Apple TV+': 'apple-tv.svg',
                'Apple iTunes': 'apple-itunes.svg',
                'Amazon Prime': 'amazon-prime.svg',
                'Amazon Video': 'amazon-prime.svg',
                'Sky X': 'skyx.png',
                Flimmit: 'flimmit.svg',
            };
            m.providers = Object.keys(data.providers).map(function (key) {
                return tslib_1.__assign({ name: key, logo: '/assets/images/filmat-providers-logos/' + (logoMap_1[key] ? logoMap_1[key] : 'placeholder.svg') }, data.providers[key], { class: logoMap_1[key] ? logoMap_1[key].split('.')[0] : '' });
            });
        }
        return m;
    };
    MovieSerieDetailModel.prototype.reducePerson = function (data) {
        if (data) {
            return data.map(function (p) {
                return {
                    name: p.name,
                    link: PortalRoute.fromUri(p.uri),
                };
            });
        }
        return undefined;
    };
    MovieSerieDetailModel.prototype.addDetail = function (key, value) {
        if (!value) {
            return;
        }
        this.details.push({
            key: key,
            value: value,
        });
    };
    return MovieSerieDetailModel;
}());
export { MovieSerieDetailModel };
