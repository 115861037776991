/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../shared/directive/oewaClickTrigger.directive";
import * as i3 from "../../../shared/service/oewa.service";
import * as i4 from "./socialBar.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../shared/service/gtm.service";
import * as i7 from "../../../shared/service/browser.ref";
var styles_SocialBarComponent = [];
var RenderType_SocialBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SocialBarComponent, data: {} });
export { RenderType_SocialBarComponent as RenderType_SocialBarComponent };
function View_SocialBarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "button", [["oewaClickTrigger", ""]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.openPopup(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(3, 16384, null, 0, i2.OewaClickTriggerDirective, [i3.OewaService], { oewaPageId: [0, "oewaPageId"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_1 = ("socialBar-item socialBar-" + _v.context.$implicit); _ck(_v, 2, 0, currVal_1); var currVal_2 = ""; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "Teilen per ", _v.context.$implicit, ""); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit; _ck(_v, 5, 0, currVal_3); }); }
export function View_SocialBarComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "socialBar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "socialBar-main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SocialBarComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.allLinks; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SocialBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "socialbar", [], null, null, null, View_SocialBarComponent_0, RenderType_SocialBarComponent)), i0.ɵdid(1, 114688, null, 0, i4.SocialBarComponent, [i5.ActivatedRoute, i6.GoogleTagManagerService, i7.BrowserRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SocialBarComponentNgFactory = i0.ɵccf("socialbar", i4.SocialBarComponent, View_SocialBarComponent_Host_0, { model: "model" }, {}, []);
export { SocialBarComponentNgFactory as SocialBarComponentNgFactory };
