<ng-container *ngIf="(teasers$ | async) as teasers">
  <section class="videoPlaylist-outer" [ngClass]="cssClass">
    <linkbar [model]="linkBar"></linkbar>
    <div class="videoPlaylist">

      <div class="videoPlaylist-video">
        <picimage [model]="activeVideo.image"></picimage>
      </div>

      <div class="videoPlaylist-slider">
        <div class="videoPlaylist-list">
          <div
            class="videoPlaylist-item"
            [class.active]="teaser.teaser.id === activeVideo.teaser.id"
            (click)="setVideo(teaser)"
            *ngFor="let teaser of teasers">
            <div class="videoPlaylist-item-image">
              <picimage [model]="teaser.image"></picimage>
            </div>
            <div class="videoPlaylist-item-content">
              <div>
                <h3>
                  {{teaser.title}}
                </h3>
              </div>
              <p>
                <a [portalRouterLink]="teaser.link">
                  Mehr Infos →
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</ng-container>
