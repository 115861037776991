import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {PortalService} from '@shared/service/portal.service';
import {DeviceDetectionService} from '../../shared/service/device-detection.service';
import {RouteService} from '@shared/service/route.service';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {MenuService} from '@shared/service/menu.service';
import {IStickySubscriber, StickyService} from '@shared/service/sticky.service';
import {BrowserRef} from '@shared/service/browser.ref';
import {STICKY_SUPERBANNER} from '@model/const/sticky';
import {GTMTriggerId} from '@model/enum/gtm';
import {NavigationEnd, Router} from '@angular/router';
import {TeasersService} from '@shared/service/teasers.service';
import {OverlayService} from '@shared/service/overlay.service';
import {SearchMenuComponent} from './searchMenu/searchMenu.component';
import {MenuModel} from '@model/menu/menu';
import {tag} from 'rxjs-spy/operators';
import {PortalRoute} from '@model/teaser/portalRoute';

export declare interface HeaderContainerModel {
  currentUri: string;
  homeLink: string;
  main: MenuModel[];
  social: MenuModel[];
  subMenu: MenuModel[];
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'headerContainer',
  templateUrl: 'headerContainer.component.html'
})
export class HeaderContainerComponent implements OnInit {
  currentPortal: string;
  showSearchField = false;
  isTouch: boolean;

  model$: Observable<HeaderContainerModel>;
  alt: string;
  logoGtmId = GTMTriggerId.logo;
  isKurier = false;
  isLustaufoesterreich = false;
  gtmId = GTMTriggerId.navbar;
  private edition = new BehaviorSubject<boolean>(false);

  constructor(private portalService: PortalService,
              private teasersService: TeasersService,
              private router: Router,
              private browserRef: BrowserRef,
              private renderer: Renderer2,
              private mobileDetection: DeviceDetectionService,
              private menuService: MenuService,
              private overlayService: OverlayService,
              private routeService: RouteService,
              private resolver: ComponentFactoryResolver,
  ) {
    this.isTouch = mobileDetection.isTouch;
    this.currentPortal = this.portalService.currentPortal();
    this.alt = this.portalService.hostname() + '';
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      tap(() => {
        if (this.router.url.startsWith('/edition')) {
          this.edition.next(true);
        } else {
          this.edition.next(false);
        }
      })
    ).subscribe();
  }

  get showNavBar$() {
    return this.edition.asObservable().pipe(
      map(edition => !edition),
    );
  }

  ngOnInit(): void {
    // set Plus link if current portal === kurier.at
    if (this.portalService.currentPortal() === 'kurierat') {
      this.isKurier = true;
    }
    if (this.portalService.currentPortal() === 'lustaufoesterreichat') {
      this.isLustaufoesterreich = true;
    }
    this.model$ = combineLatest([
      this.routeService.routeInfo
        .pipe(
          filter(Boolean),
          map(route => route.channel.url as string),
        ),
      this.menuService.get('main'),
      this.menuService.get('social'),
      this.menuService.get('sub'),
    ]).pipe(
      tag('headerContainer::model$'),
      map(results => {
        return {
          currentUri: results[0],
          homeLink: '//' + this.portalService.hostname(),
          main: results[1],
          social: results[2],
          subMenu: results[3],
        };
      }),
    );
  }

  closeSearchField() {
    if (this.showSearchField) {
      this.toggleSearchField();
    }
  }

  toggleSearchField() {
    this.showSearchField = !this.showSearchField;
    if (this.showSearchField) {
      const comp = this.overlayService.addComponent(this.resolver, SearchMenuComponent);
      (comp.instance as SearchMenuComponent).onSelectSearch.pipe(
        take(1),
      ).subscribe(() => {
        this.closeSearchField();
      });
    } else {
      this.overlayService.clearComponent();
    }
    return false;
  }
}
