import {AdModule} from '../../ads/ad.module';
import {NgModule} from '@angular/core';
import {ApaVideoComponent} from './apaVideo/apavideo.component';
import {DividerComponent} from './divider/divider.component';
import {ChannelLinkComponent} from './channelLink/channelLink.component';
import {FacebookComponent} from './facebook/facebook.component';
import {FilmAtProgramComponent} from './filmatProgram/filmatprogram.component';
import {FilmAtReviewComponent} from './filmatReview/filmatreview.component';
import {GoogleMapsComponent} from './googleMaps/googlemaps.component';
import {IframeContainerComponent} from '../channel/iframeContainer/iframecontainer.component';
import {InlineImgComponent} from './inlineImg/inlineImg.component';
import {InlineSliderComponent} from './inlineSlider/inlineSlider.component';
import {InstagramComponent} from './instagram/instagram.component';
import {LinkFieldComponent} from './linkField/linkfield.component';
import {LiveblogComponent} from './liveblog/liveblog.component';
import {PinterestBoardOrProfilComponent} from './pinterestProfileOrBoard/pinterestprofileorboard.component';
import {QuoteContainerComponent} from './quote/quoteContainer.component';
import {RiddleComponent} from './riddle/riddle.component';
import {SoundCloudComponent} from './soundcloud/soundcloud.component';
import {TeaserParagraphComponent} from './teaserParagraph/teaserParagraph.component';
import {TextParagraphComponent} from './textParagraph/textParagraph.component';
import {TwitterComponent} from './twitter/twitter.component';
import {VimeoComponent} from './vimeo/vimeo.component';
import {YouTubeComponent} from './youtube/youtube.component';
import {ChannelRelatedsComponent} from './channelRelateds/channelRelateds.component';
import {SectionRelatedsComponent} from './sectionRelateds/sectionRelateds.component';
import {SharedModule} from '@shared/shared.module';
import {HomeCommonModule} from '../common/homeCommon.module';
import {ArticleComponent} from './article/article.component';
import {TheaterDetailComponent} from './theaterDetail/theaterDetail.component';
import {MovieSerieDetailComponent} from './movieSerieDetail/movieSerieDetail.component';
import {InfoBoxComponent} from './infoBox/infoBox.component';
import {BeforeAfterSliderComponent} from './before-after-slider/before-after-slider.component';
import {NewsletterSignUpComponent} from './newsletter-sign-up/newsletter-sign-up.component';
import {CleverpushButtonComponent} from './cleverpushButton/cleverpush-button.component';
import {Degrees23Component} from './degrees23/degrees23.component';
import {TeaserVideoComponent} from '@component/home/content/teaserVideo/teaser-video.component';
import {RedditComponent} from './reddit/reddit.component';
import {PodigeeComponent} from './podigee/podigee.component';
import {TikTokComponent} from './tik-tok/tik-tok.component';
import {TwitterTimelineComponent} from '@component/home/content/twitter-timeline/twitter-timeline.component';
import {FlourishComponent} from './flourish/flourish.component';
import {FootballTableComponent} from './football-table/football-table.component';
import {FootballTableLeagueComponent} from './football-table-league/football-table-league.component';
import {PaywallComponent} from './paywall/paywall.component';
import {FootballTableCupComponent} from './football-table-cup/football-table-cup.component';
import {FootballStagesWrapperComponent} from './football-stages-wrapper/football-stages-wrapper.component';
import {FootballStagesComponent} from './football-stages-wrapper/football-stages/football-stages.component';
import {FootballLiveScoreWrapperComponent} from './football-live-score-wrapper/football-live-score-wrapper.component';
import {FootballLiveScoresComponent} from './football-live-score-wrapper/football-live-scores/football-live-scores.component';
import {FootballLiveGamesComponent} from './football-live-score-wrapper/football-live-games/football-live-games.component';
import {CoralCommentsComponent} from './coral-comments/coral-comments.component';
import {PersonMovieListComponent} from './person-movie-list/person-movie-list.component';
import {TypedEmbedComponent} from './typed-embed/typed-embed.component';
import {AdPianoComponent} from './ad-piano/ad-piano.component';

export const ARTICLE_COMPS = [
  ArticleComponent,
  TeaserVideoComponent,
  ApaVideoComponent,
  ChannelRelatedsComponent,
  SectionRelatedsComponent,
  DividerComponent,
  ChannelLinkComponent,
  FacebookComponent,
  FilmAtProgramComponent,
  FilmAtReviewComponent,
  GoogleMapsComponent,
  IframeContainerComponent,
  InlineImgComponent,
  InlineSliderComponent,
  InstagramComponent,
  LinkFieldComponent,
  LiveblogComponent,
  PinterestBoardOrProfilComponent,
  QuoteContainerComponent,
  RiddleComponent,
  SoundCloudComponent,
  TeaserParagraphComponent,
  TextParagraphComponent,
  TwitterComponent,
  TwitterTimelineComponent,
  VimeoComponent,
  YouTubeComponent,
  TheaterDetailComponent,
  MovieSerieDetailComponent,
  InfoBoxComponent,
  BeforeAfterSliderComponent,
  NewsletterSignUpComponent,
  CleverpushButtonComponent,
  Degrees23Component,
  RedditComponent,
  PodigeeComponent,
  TikTokComponent,
  FlourishComponent,
  PaywallComponent,
  FootballTableComponent,
  FootballTableLeagueComponent,
  FootballTableCupComponent,
  FootballLiveScoreWrapperComponent,
  FootballLiveScoresComponent,
  FootballLiveGamesComponent,
  CoralCommentsComponent,
  FootballStagesWrapperComponent,
  FootballStagesComponent,
  PersonMovieListComponent,
  TypedEmbedComponent,
  AdPianoComponent,
];

@NgModule({
  imports: [
    AdModule,
    SharedModule,
    HomeCommonModule,
  ],
  declarations: [
    ...ARTICLE_COMPS,
  ],
  exports: [
    ...ARTICLE_COMPS,
  ]
})
export class ArticleModule {
}
