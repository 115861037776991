/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./paywall.component";
import * as i3 from "../../../../shared/service/browser.ref";
import * as i4 from "../../../../shared/service/piano/piano.service";
import * as i5 from "../../../../shared/service/gtm.service";
var styles_PaywallComponent = [];
var RenderType_PaywallComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PaywallComponent, data: {} });
export { RenderType_PaywallComponent as RenderType_PaywallComponent };
function View_PaywallComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["paywallDiv", 1]], null, 0, "div", [["id", "cfs-paywall-container"]], null, null, null, null, null))], null, null); }
export function View_PaywallComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { paywallDiv: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_PaywallComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.showOffer$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PaywallComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-paywall", [], null, null, null, View_PaywallComponent_0, RenderType_PaywallComponent)), i0.ɵdid(1, 4243456, null, 0, i2.PaywallComponent, [i0.PLATFORM_ID, i3.BrowserRef, i4.PianoService, i5.GoogleTagManagerService], null, null)], null, null); }
var PaywallComponentNgFactory = i0.ɵccf("app-paywall", i2.PaywallComponent, View_PaywallComponent_Host_0, {}, {}, []);
export { PaywallComponentNgFactory as PaywallComponentNgFactory };
