/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../teaser/teaser.directive";
import * as i2 from "@angular/common";
import * as i3 from "../linkcard/linkCard.component.ngfactory";
import * as i4 from "../linkcard/linkCard.component";
import * as i5 from "../slider/slider.component.ngfactory";
import * as i6 from "../../../shared/directive/ad-block-block.directive";
import * as i7 from "../slider/slider.component";
import * as i8 from "../../../shared/service/tokens";
import * as i9 from "../../../shared/service/portal.service";
import * as i10 from "../../../shared/service/slider.service";
import * as i11 from "./cardSlider.component";
import * as i12 from "../../../shared/service/teasers.service";
var styles_CardSliderComponent = [];
var RenderType_CardSliderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CardSliderComponent, data: {} });
export { RenderType_CardSliderComponent as RenderType_CardSliderComponent };
function View_CardSliderComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["sliderItem", 1]], null, 2, "div", [["class", "slider-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(2, 540672, null, 0, i1.TeaserDirective, [i0.ViewContainerRef, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CardSliderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CardSliderComponent_4)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardModels; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CardSliderComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["sliderItem", 1]], null, 2, "div", [["class", "slider-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "linkcard", [], null, null, null, i3.View_LinkCardComponent_0, i3.RenderType_LinkCardComponent)), i0.ɵdid(2, 49152, null, 0, i4.LinkCardComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CardSliderComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CardSliderComponent_6)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkCardModels; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CardSliderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "slider", [["appAdBlockBlock", ""]], null, null, null, i5.View_SliderComponent_0, i5.RenderType_SliderComponent)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(3, 81920, null, 0, i6.AdBlockBlockDirective, [i0.ElementRef, i0.PLATFORM_ID], null, null), i0.ɵdid(4, 3391488, null, 1, i7.SliderComponent, [i8.GLOBAL_STYLES, i0.PLATFORM_ID, i0.ChangeDetectorRef, i9.PortalService, i10.SliderService], { sliderConfigModel: [0, "sliderConfigModel"] }, null), i0.ɵqud(603979776, 1, { sliderItems: 1 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_CardSliderComponent_3)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_CardSliderComponent_5)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.boxModel.cssClass; _ck(_v, 2, 0, currVal_0); _ck(_v, 3, 0); var currVal_1 = _co.sliderConfigModel; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.cardModels; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.linkCardModels; _ck(_v, 9, 0, currVal_3); }, null); }
function View_CardSliderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CardSliderComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.cardModels && _co.cardModels.length) || (_co.linkCardModels && _co.linkCardModels.length)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CardSliderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_CardSliderComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.data$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CardSliderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cardslider", [], null, null, null, View_CardSliderComponent_0, RenderType_CardSliderComponent)), i0.ɵdid(1, 114688, null, 0, i11.CardSliderComponent, [i12.TeasersService, i9.PortalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardSliderComponentNgFactory = i0.ɵccf("cardslider", i11.CardSliderComponent, View_CardSliderComponent_Host_0, { model: "model" }, {}, []);
export { CardSliderComponentNgFactory as CardSliderComponentNgFactory };
