import {Component} from '@angular/core';
import {IInfoBox} from '../../../../model/content/infoBox/payload';

@Component({
  selector: 'infobox',
  templateUrl: './infoBox.component.html',
})
export class InfoBoxComponent {

  title: string;
  content: string;
  open: boolean;

  constructor() {
  }

  initModel(model: IInfoBox) {
    const data = model.data;
    this.title = data.title;
    this.content = data.html;
    if (data.displaytype === 'closed') {
      this.open = false;
    } else if (data.displaytype === 'open') {
      this.open = true;
    }
  }

}
