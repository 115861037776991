import {ImageType} from '../../enum/image';
import {ImageModel} from '../../component/image';
import {ITeaser} from '../../teaser/payload';
import {TeaserModel} from '../../teaser/teaser';
import {PortalRoute} from '../../teaser/portalRoute';
import {BoxModel} from '../../component/box';
import {IArticle} from './payload';
import TagModel from '../../teaser/tag';
import {VideoModel} from '@model/component/video';
import {TimeAgoUtil} from '@util/timeAgo';

export class ArticleModel {
  publishedDate: string;
  updatedDate: string;
  title: string;
  imageModel: ImageModel;
  videoUrl: string;
  leadText: string;
  authors: TagModel[] = [];
  agencies: string[];
  box: BoxModel;
  rating: { cssClass: string, icon: string }[];
  editorRating: number;
  badge: string;
  tags: TagModel[] = [];
  paid: boolean;
  videoModel: VideoModel;
  hidePublishedDate: boolean;

  static fromTeaser(teaser: ITeaser, model: IArticle): ArticleModel {
    const a = new ArticleModel();
    a.hidePublishedDate = model.hide_published_date;
    const channel = TeaserModel.channel(teaser);
    // Paid Badge for kurier Articles only
    if (teaser.paid_content) {
      a.paid = true;
      if (teaser.portal === 'kurier.at') {
        a.tags.push(new TagModel('', PortalRoute.fromUri('/kurierat/plus'), 'paid'));
      }
    }
    if (teaser.pretitle) {
      a.tags.push(new TagModel(teaser.pretitle));
    } else {
      channel.pathtree.forEach(c => {
        a.tags.push(new TagModel(c.title.replace(' <span>|</span> ', ''), c.portalRoute));
      });
    }
    a.publishedDate = TimeAgoUtil.timeDifferencePublishedDate(model.published_date);
    if (model.displayed_updated_date && model.published_date !== model.displayed_updated_date) {
      a.updatedDate = TimeAgoUtil.timeDifferenceUpdatedDate(model.displayed_updated_date);
    }

    a.title = model.title;
    if (teaser.teaser_img) {
      a.imageModel = new ImageModel(teaser.teaser_img, ImageType.LS_16_9, PortalRoute.fromTeaser(teaser), false);
      a.imageModel.setVideo(teaser);
    }

    if (teaser.teaser_video && teaser.teaser_video.fallback_image) {
      a.imageModel = new ImageModel(teaser.teaser_video.fallback_image, ImageType.LS_16_9, PortalRoute.fromTeaser(teaser), false);
      a.imageModel.setVideo(teaser);
    }

    if (teaser.teaser_video && !teaser.teaser_img) {
      a.videoUrl = teaser.teaser_video.video_url;
    }

    if (teaser.teaser_video && teaser.teaser_video.bundle === 'apa_video') {
      a.videoModel = new VideoModel('https://uvp-kurier.sf.apa.at/embed/' + teaser.teaser_video.guid, false);
    }

    a.box = BoxModel.fromChannel(channel);

    a.leadText = teaser.teaser_text;
    a.agencies = teaser.agency ? teaser.agency.split(', ') : null;
    // TODO refactor
    if (teaser.authors) {
      a.authors = TeaserModel.authors(teaser).map(author => {
        let img: ImageModel | null = null;
        if (author.picture.url) {
          img = new ImageModel({url: author.picture.url, alt: author.picture.alt}, ImageType.SQ, null, false);
        }
        return new TagModel(author.name, author.portalRoute, img ? 'image author' : 'author', img);
      });
    }
    if (teaser.badges) {
      const there = !!teaser.badges.find(item => item === 'live');
      if (there) {
        a.badge = 'picimage-badge live-badge';
      }
    }
    return a;
  }

  setRating(rating: number) {
    this.rating = [];
    const isHalf = rating % 1 === 0.5;
    rating = Math.floor(rating);
    for (let i = 0; i < 5; i++) {
      if (i < rating || (i < rating && !isHalf)) {
        this.rating.push({
          cssClass: 'rating-full',
          icon: 'star-solid'
        });
      } else if (i === rating && isHalf) {
        this.rating.push({
          cssClass: 'rating-half',
          icon: 'star-half'
        });
      } else {
        this.rating.push({
          cssClass: 'rating-empty',
          icon: 'star-regular'
        });
      }
    }
  }

}
