/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/formControls/select/select.component.ngfactory";
import * as i2 from "../../../common/ui/formControls/select/select.component";
import * as i3 from "../football-table/football-table.component.ngfactory";
import * as i4 from "../football-table/football-table.component";
import * as i5 from "@angular/common";
import * as i6 from "./football-table-cup.component";
import * as i7 from "../../../../shared/service/football.service";
var styles_FootballTableCupComponent = [];
var RenderType_FootballTableCupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FootballTableCupComponent, data: {} });
export { RenderType_FootballTableCupComponent as RenderType_FootballTableCupComponent };
function View_FootballTableCupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h1", [["class", "footballTableCup-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heading.getValue(); _ck(_v, 1, 0, currVal_0); }); }
function View_FootballTableCupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formselect", [], null, [[null, "valueSelectedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueSelectedChange" === en)) {
        var pd_0 = (_co.changeRound(_v.context.ngIf[$event]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SelectComponent_0, i1.RenderType_SelectComponent)), i0.ɵdid(1, 49152, null, 0, i2.SelectComponent, [], { label: [0, "label"], values: [1, "values"] }, { valueSelectedChange: "valueSelectedChange" })], function (_ck, _v) { var currVal_0 = "Runden"; var currVal_1 = _v.context.ngIf; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_FootballTableCupComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-football-table", [], null, null, null, i3.View_FootballTableComponent_0, i3.RenderType_FootballTableComponent)), i0.ɵdid(2, 49152, null, 0, i4.FootballTableComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FootballTableCupComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FootballTableCupComponent_4)), i0.ɵdid(2, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.ngIf.tables; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FootballTableCupComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "footballTableCup"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FootballTableCupComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FootballTableCupComponent_2)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FootballTableCupComponent_3)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heading.getValue(); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.rounds$)); _ck(_v, 4, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.table$)); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_FootballTableCupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-football-table-cup", [], null, null, null, View_FootballTableCupComponent_0, RenderType_FootballTableCupComponent)), i0.ɵdid(1, 49152, null, 0, i6.FootballTableCupComponent, [i7.FootballService], null, null)], null, null); }
var FootballTableCupComponentNgFactory = i0.ɵccf("app-football-table-cup", i6.FootballTableCupComponent, View_FootballTableCupComponent_Host_0, {}, {}, []);
export { FootballTableCupComponentNgFactory as FootballTableCupComponentNgFactory };
