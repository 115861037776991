<div class="search-authorHeader">
  <div class="search-authorHeader-main">
    <div class="search-authorHeader-left">
      <ng-container
        *ngIf="imageModel.image['url'].length; then image else no_image">
      </ng-container>
    </div>
    <div class="search-authorHeader-right">
      <h1 class="search-authorHeader-heading">{{authorData.name}}</h1>
      <div class="search-authorHeader-text">
        <p *ngFor="let text of authorData.profileTexts">{{text}}</p>
      </div>
    </div>
  </div>
  <div *ngIf="shareModel.links.length > 0" class="search-authorHeader-footer">
    <p class="search-authorHeader-socialMedia">
      <span class="search-authorHeader-shareName">Folge {{authorData.name}}: </span>
      <iconbutton *ngFor="let link of shareModel.links" [icon]="map[link]" [blank]="true" [href]="shareModel[link]">
      </iconbutton>
    </p>
  </div>
</div>


<ng-template #image>
  <picimage [model]="imageModel"></picimage>
</ng-template>

<ng-template #no_image>
  <div class="defaultUser">
    <svgicon [icon]="'user'"></svgicon>
  </div>
</ng-template>
