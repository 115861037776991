/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./text-box.component";
var styles_TextBoxComponent = [];
var RenderType_TextBoxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextBoxComponent, data: {} });
export { RenderType_TextBoxComponent as RenderType_TextBoxComponent };
export function View_TextBoxComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "section", [["class", "textBox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [["class", "textBox-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "textBox-body"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.model.html; _ck(_v, 3, 0, currVal_1); }); }
export function View_TextBoxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-text-box", [], null, null, null, View_TextBoxComponent_0, RenderType_TextBoxComponent)), i0.ɵdid(1, 49152, null, 0, i1.TextBoxComponent, [], null, null)], null, null); }
var TextBoxComponentNgFactory = i0.ɵccf("app-text-box", i1.TextBoxComponent, View_TextBoxComponent_Host_0, {}, {}, []);
export { TextBoxComponentNgFactory as TextBoxComponentNgFactory };
