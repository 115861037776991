import {Inject, Injectable, InjectionToken, Injector, PLATFORM_ID} from '@angular/core';
import {makeStateKey, StateKey, TransferState} from '@angular/platform-browser';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';

export interface StateProvider<T> {
  get(token, notFoundValue?: T): T;
}

@Injectable({
  providedIn: 'root',
})
export class StateService<T> implements StateProvider<T> {

  private readonly HAD_UNIVERSAL: StateKey<boolean> = makeStateKey('HAD_UNIVERSAL');

  constructor(@Inject(PLATFORM_ID) private platformId: string,
              private injector: Injector,
              private transferState: TransferState) {
    if (isPlatformServer(this.platformId)) {
      this.transferState.onSerialize(this.HAD_UNIVERSAL, () => true);
    }
  }

  public get(token, notFoundValue?: T): T {
    if (isPlatformServer(this.platformId)) {
      return this.serverInjection(token, notFoundValue);
    }
    if (isPlatformBrowser(this.platformId)) {
      if (this.hadUniversal) {
        return this.transferedState(token, notFoundValue);
      } else {
        return this.browserInjection(token, notFoundValue);
      }
    }
    throw new Error('Invalid platform ' + this.platformId);
  }

  public get isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  public get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public get hadUniversal(): boolean {
    return this.transferState.get(this.HAD_UNIVERSAL, false);
  }

  public get isFirstApp(): boolean {
    return (isPlatformServer(this.platformId) || (isPlatformBrowser(this.platformId) && !this.hadUniversal));
  }

  private transferedState(token, notFoundValue?: T): T {
    const stateKey: StateKey<T> = makeStateKey(token);
    return this.transferState.get(stateKey, notFoundValue);
  }

  private browserInjection(token, notFoundValue?: T): T {
    return this.injector.get(token as InjectionToken<T>, notFoundValue);
  }

  private serverInjection(token, notFoundValue?: T): T {
    const stateKey: StateKey<T> = makeStateKey(token);
    const serverValue = this.injector.get(token as InjectionToken<T>, notFoundValue);
    this.transferState.onSerialize(stateKey, () => serverValue);
    return serverValue;
  }
}
