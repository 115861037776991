import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {PortalRoute} from '../../../../model/teaser/portalRoute';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'iconbutton',
  templateUrl: './iconButton.component.html',
})
export class IconButtonComponent implements OnInit {

  @Input() href: string;
  @Input() link: PortalRoute;
  @Input() icon: string;
  @Input() blank: boolean;
  @Input() gtmId: string;
  target: string;

  constructor() {
  }

  ngOnInit() {
    if (this.blank === true) {
      this.target = '_blank';
    }
  }
  linkTo() {
    this.blank ? window.open(this.href, '_blank') : window.location.href = this.href;
  }

}
