import {NgModule} from '@angular/core';
import {AuthorHeaderComponent} from './authorHeader/authorHeader.component';
import {SearchHeaderComponent} from './resultHeader/resultHeader.component';
import {TopicHeaderComponent} from './topicHeader/topicHeader.component';
import {ResultTopicsComponent} from './resultTopics/resultTopics.component';
import {AdModule} from '../../ads/ad.module';
import {SharedModule} from '@shared/shared.module';
import {ResultArticlesComponent} from './resultArticles/resultArticles.component';
import {HomeCommonModule} from '../common/homeCommon.module';

export const SEARCH_COMPS = [
  AuthorHeaderComponent,
  SearchHeaderComponent,
  ResultArticlesComponent,
  ResultTopicsComponent,
  TopicHeaderComponent,
];

@NgModule({
  imports: [
    AdModule,
    SharedModule,
    HomeCommonModule,
  ],
  declarations: [
    ...SEARCH_COMPS,
  ],
  exports: [
    ...SEARCH_COMPS
  ]
})
export class SearchModule {
}
