<div class="media" [ngClass]="[model.box.cssClass, model.classes]">

  <div
    *ngIf="model.image"
    class="teaser-imageContainer">
    <picimage
      [model]="model.image">
    </picimage>
  </div>

  <p *ngIf="model.tagLine || model.tags" class="teaser-tagLine">
    <tag
      [model]="tag"
      *ngFor="let tag of model.tags"></tag>
    <a
      *ngFor="let tag of model.tagLine"
      class="teaser-tagLineLink"
      [portalRouterLink]="tag.portalRoute"
      [gtm]="model.pretitleGtmId" [innerHTML]="tag.title"></a>
  </p>

  <a
    class="teaser-title"
    [portalRouterLink]="model.link">
    <h2 *ngIf="model.title">
            <span>
                {{model.title}}
            </span>
    </h2>
  </a>

  <a
    *ngIf="model.content"
    class="teaser-text"
    [portalRouterLink]="model.link">
    <p [innerHTML]="model.content | stripHtml:['a']"></p>
  </a>

  <footer *ngIf="model.author" class="authorFooter">
    <p>von <a [portalRouterLink]="model.author.portalRoute">{{model.author.name}}</a></p>
  </footer>

</div>
