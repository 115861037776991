import {PortalRoute} from './portalRoute';
import {Channel} from './channel';

export class PathTree {

  static fromChannel(channel: Channel): PathTree[] {
    let searchPathShift: number;
    let searchStart: string;
    let pathSplit: string[];
    let newUrl: string;
    let firstSlash: number;
    let shift: number;
    pathSplit = channel.name.split('/');
    searchPathShift = channel.path.indexOf('/', 1) + 1;
    searchStart = channel.path.substr(searchPathShift);
    return pathSplit.map((item, index) => {
      if (index !== pathSplit.length - 1) {
        firstSlash = searchStart.indexOf('/');
        shift = searchPathShift + firstSlash + 1; // the / char
        newUrl = channel.path.substr(0, shift);
        searchStart = searchStart.substr(firstSlash + 1); // the lenght of /
        searchPathShift = shift;
        item += ' <span>|</span> ';
      } else {
        newUrl = channel.path;
      }
      if (newUrl !== '') {
        return new PathTree(newUrl, item, PortalRoute.fromUri(newUrl));
      } else {
        const msg = 'Channel without portalRoute: ' + JSON.stringify(channel);
        console.error(msg);
      }
    });
  }

  constructor(public path: string,
              public title: string,
              public portalRoute: PortalRoute) {
  }
}
