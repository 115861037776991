import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {RouteService} from './route.service';
import {filter, switchMap} from 'rxjs/operators';
import {StateService} from './state.service';
import {BrowserRef} from './browser.ref';
import {OewaData} from '@model/oewa';
import {SCRIPT_MAP} from '@model/const/script';
import {ScriptId} from '@model/enum/scriptId';
import {PortalService} from '@shared/service/portal.service';
import {LoadExternalScriptService} from '@shared/service/loadExternalScript.service';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class OewaService {

  private static METHOD_APPEND_CHILD = 1;
  private oewaData: OewaData;

  constructor(private loadExternalScriptService: LoadExternalScriptService,
              private stateService: StateService<string>,
              private portalService: PortalService,
              private routeService: RouteService,
              @Inject(PLATFORM_ID) private platformId: string,
              private browserRef: BrowserRef) {
  }

  get isOewaApp() {
    return window && window.location.search.includes('trackingAllowed');
  }

  public init() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.isOewaApp) {
        this.routeService.routeInfo.pipe(
          filter(Boolean),
          filter(route => route.analytics && route.analytics.oewa),
          switchMap(route => {
            let dn: string;
            switch (this.portalService.currentPortal()) {
              case 'kurierat':
                dn = 'data-e0cdc680c2.kurier.at';
                break;
              case 'motorat':
                dn = 'data-d9c82db6b2.motor.at';
                break;
              case 'filmat':
                dn = 'data-366a248b14.film.at';
                break;
            }
            SCRIPT_MAP[ScriptId.oewa] = '//' + dn + '/iomm/latest/bootstrap/stub.js';
            SCRIPT_MAP[ScriptId.oewaBundle] = '//' + dn + '/iomm/latest/manager/base/es6/bundle.js';
            this.oewaData = {
              cn: route.analytics.oewa.cn,
              dn,
              cp: route.analytics.oewa.cp,
              st: route.analytics.oewa.st,
              mh: 5,
            };
            return this.loadExternalScriptService.loadScript(ScriptId.oewa);
          }),
          switchMap(s => {
            s.scriptVar('configure', {st: this.oewaData.st, dn: this.oewaData.dn, cn: this.oewaData.cn, mh: 5});
            s.scriptVar('pageview', {cp: this.oewaData.cp});
            return this.loadExternalScriptService.loadScript(ScriptId.oewaBundle);
          }),
        ).subscribe();
      }
    }
  }

  /**
   * Push new categorypath, reusing other current values.
   */
  public pushCp(oewaPageId?: string) {
    // if (!!this.isOewaApp) {
    //   if (this.oewaData) {
    //     const oewaData: OewaData = Object.assign({}, this.oewaData);
    //
    //     const isMobile = this.browserRef.window.innerWidth < 960;
    //     oewaData.sv = isMobile ? 'mo' : 'in';
    //
    //     if (isMobile) {
    //       oewaData.cp += '/moewa';
    //     }
    //     if (oewaPageId) {
    //       oewaData.cp += '/' + oewaPageId;
    //     }
    //     this.pushOewa(oewaData);
    //   }
    // }
  }

  private pushOewa(oewaData: OewaData) {
    if (!this.isOewaApp) {
      // tslint:disable-next-line:no-string-literal
      const iom = this.browserRef.window['iom'];
      if (iom) {
        iom.c(oewaData, OewaService.METHOD_APPEND_CHILD);
      }
    }
  }
}
