import {Injectable} from '@angular/core';
import {ApiService} from '@shared/service/api.service';
import {forkJoin, Observable, of} from 'rxjs';
import {
  IFootallSeason,
  IFootballData,
  IFootballDateStanding,
  IFootballFixture, IFootballGroupStanding,
  IFootballLeague,
  IFootballLeagueStanding,
  IFootballLiveScore,
  IFootballRound,
  IFootballSeasonUpcomingInclude,
  IFootballStage,
  IFootballStageResultInclude,
  IFootballTeam,
  IFootballVenue,
} from '@model/content/footballTable/payload';
import {map, switchMap, tap} from 'rxjs/operators';
import {FootballStandingRow, GameType} from '@model/content/footballTable/footballStandingRow';
import {FootballTable} from '@model/content/footballTable/footballTable';
import {FootballTableWrapper} from '@model/content/footballTable/footballTableWrapper';
import {HttpClient} from '@angular/common/http';
import {TabModel} from '@model/component/tab';
import {FootballStageModel} from '@model/content/footballStages/footballStage';

@Injectable({
  providedIn: 'root'
})
export class FootballService {
  protected footballTableApiBase = 'https://sportmonks.telekurier.at/soccer/api/v2.0/';
  protected footBallLeagueIdMap: { [key: string]: number } = {
    'at-league': 181,
    'at-league-playoff': 181,
    'at-league-1': 184,
    'de-league': 82,
    'uefa-ec': 1326,
    'uefa-cl': 2,
    'uefa-el': 5,
    'en-league': 8,
    'es-league': 564,
    'it-league': 384,
    'wc-qualification-europe': 720,
    'european-championship': 1389,
    'uefa-women-euro': 1389,
    'uefa-nations-league': 1538,
    'europa-league': 5,
    'champions-league': 2,
    'world-cup': 732,
    'austrian-cup': 187,
    em_quali: 1325,
  };
  protected footBallLeagueNameMap: { [key: string]: string } = {
    'at-league-1': 'Admiral 2. Liga',
    'at-league': 'Ö. Bundesliga Grunddurchgang',
  };

  get footBallLeagueIds() {
    return this.footBallLeagueIdMap;
  }

  private austrianLeagueMap = {
    'Regular Season': 'Grunddurchgang',
    'Relegation Round': 'Qualifikationsgruppe',
    'Championship Round': 'Meistergruppe',
  };

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
  ) {
  }

  private static get currentYear() {
    const currYear = new Date().getFullYear();
    return (currYear - 1) + '/' + (currYear);
  }

  private getAllSeasons(page: number = 1): Observable<IFootallSeason[]> {
    return this.apiService.getCached<IFootballData<IFootallSeason[]>>(
      `${this.footballTableApiBase}seasons?page=${page}`,
      false,
    ).pipe(
      switchMap(res => {
        if (res.meta.pagination.links.next) {
          return this.getAllSeasons(res.meta.pagination.current_page + 1)
            .pipe(
              map(nextSeasonData => {
                return [...res.data, ...nextSeasonData];
              })
            );
        }
        return of(res.data);
      })
    );
  }

  getTeamById(id: number) {
    return this.apiService.getCached<IFootballData<IFootballTeam>>(
      `${this.footballTableApiBase}teams/${id}`,
      false,
    );
  }

  private getCurrentLiveScores() {
    return this.http.get<IFootballData<IFootballLiveScore[]>>(
      `${this.footballTableApiBase}livescores/now`,
    );
  }

  getDailyLiveScores() {
    return this.apiService.getCached<IFootballData<IFootballLiveScore[]>>(
      `${this.footballTableApiBase}livescores`,
      false,
    );
  }

  private getFixtureById(id: number) {
    return this.http.get<IFootballData<IFootballFixture>>(
      `${this.footballTableApiBase}fixtures/${id}?include=events`
    );
  }

  getVenueById(id: number) {
    return this.apiService.getCached<IFootballData<IFootballVenue>>(
      `${this.footballTableApiBase}venues/${id}`,
      false,
    );
  }

  getFixture(id: number) {
    return this.getCurrentLiveScores().pipe(
      switchMap(liveScores => {
        const isLive = !!liveScores.data.find(ls => ls.id === id);
        return this.getFixtureById(id).pipe(
          switchMap(fixture => {
            const venueId = fixture.data.venue_id;
            const localTeamId = fixture.data.localteam_id;
            const visitorTeamId = fixture.data.visitorteam_id;
            return forkJoin([
              this.getTeamById(localTeamId),
              this.getTeamById(visitorTeamId),
              this.getVenueById(venueId),
            ]).pipe(
              map(teams => {
                const localTeam = teams[0];
                const visitorTeam = teams[1];
                const venue = teams[2];
                const penaltyShootout = fixture.data.events.data.filter(e => e.type.includes('pen_shootout')).reverse();
                const nonPenaltyShootout = fixture.data.events.data.filter(e => !e.type.includes('pen_shootout')).reverse();
                const events = [...penaltyShootout, ...nonPenaltyShootout];
                let min = 0;
                if (fixture.data.time.minute) {
                  min += +fixture.data.time.minute;
                }
                if (fixture.data.time.extra_minute) {
                  min += +fixture.data.time.extra_minute;
                }
                let lts = fixture.data.scores.localteam_score;
                let vts = fixture.data.scores.visitorteam_score;
                if (fixture.data.scores.localteam_pen_score) {
                  lts += +fixture.data.scores.localteam_pen_score;
                }
                if (fixture.data.scores.visitorteam_pen_score) {
                  vts += +fixture.data.scores.visitorteam_pen_score;
                }
                return {
                  isLive,
                  events,
                  localTeam: localTeam.data,
                  visitorTeam: visitorTeam.data,
                  scores: lts + ' - ' + vts,
                  min: min + '’ min',
                  venue: venue.data
                };
              })
            );
          }),
        );
      }),
    );
  }

  private getSeasonByLeagueYear$(leagueId: number, year: string): Observable<IFootallSeason> {
    return this.getAllSeasons().pipe(
      map(seasons => {
        return seasons.find(s => s.league_id === leagueId && s.name === year);
      }),
    );
  }

  fromCupStandings$(league: string, round: IFootballRound, year: string = FootballService.currentYear)
    : Observable<FootballTableWrapper> {
    const roundId = round.id;
    const id = this.footBallLeagueIdMap[league];
    return this.leagueFromId$(id)
      .pipe(
        switchMap(leagueRes => {
          return this.getSeasonByLeagueYear$(id, year).pipe(
            switchMap((season) => {
              const seasonId = season.id;
              return forkJoin([
                this.getFootBallLeagueStandingBySeasonId(seasonId),
                this.getFootballTeamsBySeasonId(seasonId),
                this.getRoundsFromSeasonId$(seasonId),
              ]).pipe(
                switchMap(res => {
                  const currRoundIndex = res[2].data.findIndex(r => r.id === roundId);
                  return forkJoin(res[0].data.map(leagueStanding => {
                    if (currRoundIndex > 0) {
                      return forkJoin([
                        this.getRoundStandings(seasonId, roundId, leagueStanding.id),
                        this.getRoundStandings(seasonId, res[2].data[currRoundIndex - 1].id, leagueStanding.id)
                      ]).pipe(
                        map(standings => new FootballTable(
                          FootballStandingRow.fromDateStanding(standings[0], undefined, standings[1]), leagueStanding.name))
                      );
                    } else {
                      return this.getRoundStandings(seasonId, roundId, leagueStanding.id).pipe(
                        map(standings => new FootballTable(FootballStandingRow.fromDateStanding(standings), leagueStanding.name))
                      );
                    }
                  }));
                }),
                map(res => {
                  const tables = res.sort((a, b) => {
                    if (a.heading > b.heading) {
                      return 1;
                    }
                    if (a.heading < b.heading) {
                      return -1;
                    }
                    return 0;
                  });
                  return new FootballTableWrapper(leagueRes.data.name + ' ' + year, tables);
                }),
              );
            }),
          );
        }),
      );
  }

  fromWomanCupStandings$(group: string, round: IFootballRound, year: string = FootballService.currentYear)
    : Observable<FootballTableWrapper> {
    const groupName = 'Group ' + group.toUpperCase();
    const roundId = round.id;
    const id = 1389;
    return this.leagueFromId$(id)
      .pipe(
        switchMap(leagueRes => {
          return this.getSeasonByLeagueYear$(id, year).pipe(
            switchMap((season) => {
              const seasonId = season.id;
              return forkJoin([
                this.getFootBallLeagueStandingBySeasonId<IFootballLeagueStanding<IFootballGroupStanding[]>[]>(seasonId),
                this.getFootballTeamsBySeasonId(seasonId),
                this.getRoundsFromSeasonId$(seasonId),
              ]).pipe(
                switchMap(res => {
                  const currRoundIndex = res[2].data.findIndex(r => r.id === roundId);
                  const leagueStanding = res[0].data[1];
                  const t = leagueStanding.standings.data.find(g => g.name === groupName).standings.data;
                  const teamNames = t.map(st => st.team_name);
                  if (currRoundIndex > 0) {
                    return forkJoin([
                      this.getRoundStandings(seasonId, roundId, leagueStanding.id),
                      this.getRoundStandings(seasonId, res[2].data[currRoundIndex - 1].id, leagueStanding.id)
                    ]).pipe(
                      map(standings => {
                        const newStandings = {...standings[0]};
                        newStandings.data = standings[0].data.filter(st => teamNames.includes(st.team_name));
                        const oldStandings = {...standings[1]};
                        oldStandings.data = standings[1].data.filter(st => teamNames.includes(st.team_name));
                        return new FootballTable(
                          FootballStandingRow.fromDateStanding(newStandings, undefined, oldStandings), groupName);
                      })
                    );
                  } else {
                    return this.getRoundStandings(seasonId, roundId, leagueStanding.id).pipe(
                      map(standings => {
                        const newStandings = {...standings};
                        newStandings.data = standings.data.filter(st => teamNames.includes(st.team_name));
                        return new FootballTable(FootballStandingRow.fromDateStanding(newStandings), groupName);
                      })
                    );
                  }
                }),
                map(res => {
                  return new FootballTableWrapper(leagueRes.data.name + ' ' + year, [res]);
                }),
              );
            }),
          );
        }),
      );
  }

  fromWorldCupStandings$(year: string = FootballService.currentYear, group?: string)
    : Observable<FootballTableWrapper> {
    return this.getSeasonByLeagueYear$(732, year).pipe(
      switchMap((season) => {
        const seasonId = season.id;
        return forkJoin([
          this.getFootBallLeagueStandingBySeasonId<IFootballGroupStanding[]>(seasonId),
          this.getFootballTeamsBySeasonId(seasonId),
        ]).pipe(
          map(res => {
            const t = res[0].data
              .filter(st => {
                if (group) {
                  return st.name === group;
                }
                return true;
              })
              .map(st => new FootballTable(FootballStandingRow.fromStandings(st, res[1]), st.name));
            return new FootballTableWrapper('', t);
          }),
        );
      }),
    );
  }

  private getFootBallLeagueStandingBySeasonId<T>(seasonId: number): Observable<IFootballData<IFootballLeagueStanding<T>[]>> {
    return this.apiService.getCached<IFootballData<IFootballLeagueStanding<T>[]>>(
      `${this.footballTableApiBase}standings/season/${seasonId}`,
      false,
    );
  }

  private getFootballTeamsBySeasonId(seasonId: number, page: number = 1): Observable<IFootballData<IFootballTeam[]>> {
    return this.apiService.getCached<IFootballData<IFootballTeam[]>>(
      `${this.footballTableApiBase}teams/season/${seasonId}?page=${page}`,
      false,
    ).pipe(
      switchMap(res => {
        if (res.meta.pagination.links.next) {
          return this.getFootballTeamsBySeasonId(seasonId, res.meta.pagination.current_page + 1)
            .pipe(
              map(nextTeamData => {
                res.data = [...res.data, ...nextTeamData.data];
                return res;
              })
            );
        }
        return of(res);
      })
    );
  }

  fromLeagueStandings$(league: string, year: string = FootballService.currentYear): Observable<FootballTableWrapper> {
    const id = this.footBallLeagueIdMap[league];
    return this.leagueFromId$(id)
      .pipe(
        switchMap(leagueRes => {
          return this.getSeasonByLeagueYear$(id, year).pipe(
            switchMap((season) => {
              const seasonId = season.id;
              return forkJoin([
                this.getFootBallLeagueStandingBySeasonId<IFootballGroupStanding[]>(seasonId),
                this.getFootballTeamsBySeasonId(seasonId),
                this.getRoundsFromSeasonId$(seasonId),
              ]).pipe(
                switchMap(res => {
                  return forkJoin(res[0].data.map(leagueStanding => {
                    const stageId = leagueStanding.stage_id;
                    const rounds = res[2];
                    // this weird code is to get round by date since some matches are postponed and in that case
                    // leagueStanding.round_id would be wrong
                    const now = +(new Date().toISOString().split('T')[0].split('-').join(''));
                    const reverseRounds = [...rounds.data.filter(r => r.stage_id === stageId)].reverse();
                    const currentRoundIndex = reverseRounds.findIndex(r => {
                      const startDate = +r.end.split('-').join('');
                      return startDate < now;
                    });
                    if (currentRoundIndex >= 0) {
                      const previousRound = reverseRounds[currentRoundIndex + 1];
                      return this.getRoundStandings(seasonId, previousRound.id, leagueStanding.standings.data[0].group_id)
                        .pipe(
                          map(previousStanding => {
                            const currRows = FootballStandingRow.fromStandings(leagueStanding, res[1], previousStanding.data);
                            const h = leagueStanding.standings.data[0].group_name;
                            return new FootballTable(currRows, h);
                          }),
                        );
                    }
                    const rows = FootballStandingRow.fromStandings(leagueStanding, res[1]);
                    const heading = leagueStanding.standings.data[0].group_name;
                    return of(new FootballTable(rows, heading));
                  }));
                }),
                map(res => {
                  const heading = this.footBallLeagueNameMap[league] ?
                    this.footBallLeagueNameMap[league] + ' ' + year : leagueRes.data.name + ' ' + year;
                  return new FootballTableWrapper(heading, res);
                }),
              );
            }),
          );
        }),
      );
  }

  fromLeagueAustrianStandings$(league: string, year: string = FootballService.currentYear): Observable<FootballTableWrapper> {
    const id = this.footBallLeagueIdMap[league];
    return this.getSeasonByLeagueYear$(id, year).pipe(
      switchMap((season) => {
        const seasonId = season.id;
        return forkJoin([
          this.getFootBallLeagueStandingBySeasonId<IFootballGroupStanding[]>(seasonId),
          this.getFootballTeamsBySeasonId(seasonId),
        ]).pipe(
          map(res => {
            const footBallTables = res[0].data.map((leagueStanding, i) => {
              let rows: FootballStandingRow[];
              const regularSeason = res[0].data.find(lst => lst.name === 'Regular Season');
              if (regularSeason === leagueStanding) {
                rows = FootballStandingRow.fromAustrianStandings(leagueStanding, res[1]);
              } else {
                rows = FootballStandingRow.fromAustrianStandings(leagueStanding, res[1], regularSeason);
              }
              return new FootballTable(rows, this.austrianLeagueMap[leagueStanding.stage_name]);
            });
            return new FootballTableWrapper(this.footBallLeagueNameMap[league], [...footBallTables].reverse());
          }),
        );
      }),
    );
  }

  fromLeagueAustrianAwayHome$(league: string, year: string = FootballService.currentYear): Observable<FootballTableWrapper> {
    const id = this.footBallLeagueIdMap[league];
    return this.getSeasonByLeagueYear$(id, year).pipe(
      switchMap((season) => {
        const seasonId = season.id;
        return forkJoin([
          this.getFootBallLeagueStandingBySeasonId<IFootballGroupStanding[]>(seasonId),
          this.getFootballTeamsBySeasonId(seasonId),
        ]).pipe(
          map(res => {
            return res[0].data.map(leagueStanding => {
              const defaultAwayRows = FootballStandingRow.fromStandings(
                leagueStanding,
                res[1],
                undefined,
                GameType.away
              );
              const defaultHomeRows = FootballStandingRow.fromStandings(
                leagueStanding,
                res[1],
                undefined,
                GameType.home
              );
              return {
                homeTable: new FootballTable(defaultHomeRows,
                  'Heimtabelle ' + this.austrianLeagueMap[leagueStanding.stage_name], true),
                awayTable: new FootballTable(defaultAwayRows,
                  'Auswärtstabelle ' + this.austrianLeagueMap[leagueStanding.stage_name], true),
              };
            });
          }),
          map(res => {
            const tables = res
              .map(t => [t.awayTable, t.homeTable])
              .reduce((a, b) => a.concat(b));
            return new FootballTableWrapper(this.footBallLeagueNameMap[league], [...tables].reverse());
          }),
        );
      }),
    );
  }

  private getRoundsFromSeasonId$(seasonId: number): Observable<IFootballData<IFootballRound[]>> {
    return this.apiService.getCached<IFootballData<IFootballRound[]>>(
      `${this.footballTableApiBase}rounds/season/${seasonId}`,
      false,
    );
  }

  getEmQualification(year: string, group?: string): Observable<FootballTableWrapper> {
    const seasonName = '20' + year.split('/')[0];
    return this.getAllSeasons().pipe(
      switchMap(seasons => {
        let season = seasons.find(s => (s.league_id === 1325 && s.name === seasonName));
        if (!season) {
          season = seasons.find(s => s.league_id === 1325 && s.is_current_season === true);
        }
        return forkJoin([
          this.getFootBallLeagueStandingBySeasonId<IFootballGroupStanding[]>(season.id),
          this.getFootballTeamsBySeasonId(season.id)]
        );
      }),
      map(data => {
        const standings = data[0];
        const teams = data[1];
        const tables = standings.data
          .filter(d => {
            if (group) {
              const groupName = 'Group ' + group.toLocaleUpperCase();
              return d.name === groupName;
            }
            return true;
          })
          .map(d => {
            const rows = FootballStandingRow.fromStandings(d, teams);
            return new FootballTable(rows, d.name.replace('Group', 'Gruppe'));
          });
        return new FootballTableWrapper('hallo', tables);
      })
    );
    // return this.getFootBallLeagueStandingBySeasonId<IFootballGroupStanding[]>(seasonId);
  }

  getRoundsForSelection(league: string, year: string = FootballService.currentYear): Observable<{ value: IFootballRound, name: string }[]> {
    const id = this.footBallLeagueIdMap[league];
    return this.getSeasonByLeagueYear$(id, year)
      .pipe(
        switchMap(season => {
          return this.getRoundsFromSeasonId$(season.id);
        }),
        map(rounds => {
          return rounds.data.map(round => {
            const text = round.name + '. Spieltag (' + (round.start.split('-').reverse().join('.')) + ' - ' +
              (round.end.split('-').reverse().join('.')) + ')';
            if (id === 1538) {
              return {value: round, name: text};
            }
            return {
              value: round,
              name: 'Vorrunde ' + text
            };
          });
        }),
      );
  }

  private getRoundStandings(seasonId: number, roundId: number, groupId?: number): Observable<IFootballData<IFootballDateStanding[]>> {
    const groupParam = groupId ? `?group_id=${groupId}` : '';
    return this.apiService.getCached<IFootballData<IFootballDateStanding[]>>(
      `${this.footballTableApiBase}standings/season/${seasonId}/round/${roundId}${groupParam}`,
      false,
    );
  }

  leagueFromId$(leagueId: number): Observable<IFootballData<IFootballLeague>> {
    return this.apiService
      .getCached<IFootballData<IFootballLeague>>(
        `${this.footballTableApiBase}leagues/${leagueId}?include=season`,
        false,
      );
  }

  forAwayHome$(league: string, year: string = FootballService.currentYear): Observable<FootballTableWrapper> {
    const id = this.footBallLeagueIdMap[league];
    return this.leagueFromId$(id)
      .pipe(
        switchMap(leagueRes => {
          return this.getSeasonByLeagueYear$(id, year)
            .pipe(
              switchMap((season) => {
                const seasonId = season.id;
                return forkJoin([
                  this.getFootBallLeagueStandingBySeasonId<IFootballGroupStanding[]>(seasonId),
                  this.getFootballTeamsBySeasonId(seasonId),
                ]).pipe(
                  map(res => {
                    const leagueStandings = res[0].data;
                    return leagueStandings.map(ls => {
                      const homeRows = FootballStandingRow.fromStandings(ls, res[1], undefined, GameType.home);
                      const awayRows = FootballStandingRow.fromStandings(ls, res[1], undefined, GameType.away);
                      const heading = ls.standings.data[0].group_name ? ' ' + ls.standings.data[0].group_name : '';
                      return [
                        new FootballTable(homeRows, 'Heimtabelle' + heading, true),
                        new FootballTable(awayRows, 'Auswärtstabelle' + heading, true)
                      ];
                    });
                  }),
                  map(res => {
                    const heading = this.footBallLeagueNameMap[league] ?
                      this.footBallLeagueNameMap[league] + ' ' + year : leagueRes.data.name + ' ' + year;
                    return new FootballTableWrapper(heading, res.reduce((a, b) => [...a, ...b]));
                  }),
                );
              }),
            );
        }),
      );
  }

  forFirstSecondRound$(leagueSlug: string, year: string = FootballService.currentYear): Observable<FootballTableWrapper> {
    const leagueId = this.footBallLeagueIdMap[leagueSlug];
    return this.leagueFromId$(leagueId).pipe(
      switchMap(league => {
        return this.getSeasonByLeagueYear$(leagueId, year).pipe(
          switchMap(season => {
            const seasonId = season.id;
            let tables: Observable<FootballTable[]>;
            tables = this.getRoundsFromSeasonId$(seasonId).pipe(
              switchMap(rounds => {
                // this weird code is to get round by date since some matches are postponed and in that case
                // leagueStanding.round_id would be wrong
                const now = +(new Date().toISOString().split('T')[0].split('-').join(''));
                const reverseRounds = [...rounds.data].reverse();
                const currentRoundIndex = reverseRounds.findIndex(r => {
                  const startDate = +r.end.split('-').join('');
                  return startDate < now;
                });
                if (currentRoundIndex < reverseRounds.length / 2 - 1) {
                  const firstRound = rounds.data[rounds.data.length / 2 - 1];
                  const firstRoundPrevious = rounds.data[rounds.data.length / 2 - 2];
                  const secondRound = reverseRounds[currentRoundIndex];
                  const secondRoundPrevious = reverseRounds[currentRoundIndex + 1];
                  return forkJoin([
                    this.getRoundStandings(seasonId, firstRound.id),
                    this.getRoundStandings(seasonId, firstRoundPrevious.id),
                    this.getRoundStandings(seasonId, secondRound.id),
                    this.getRoundStandings(seasonId, secondRoundPrevious.id),
                  ]).pipe(
                    map(standings => {
                      const firstRoundRows = FootballStandingRow.fromDateStanding(standings[0], undefined, standings[1]);
                      const firstRoundTable = new FootballTable(firstRoundRows, 'Hinrunde');
                      const secondRoundRowsPrevious = FootballStandingRow.fromDateStanding(standings[3]);
                      const secondRoundRowsCurrent = FootballStandingRow.fromDateStanding(standings[2]);
                      const secondRoundPreviousTable = FootballTable
                        .subtractTable(new FootballTable(secondRoundRowsPrevious), firstRoundTable);
                      const secondRoundCurrentTable = FootballTable
                        .subtractTable(new FootballTable(secondRoundRowsCurrent), firstRoundTable);
                      secondRoundCurrentTable.heading = 'Rückrunde';
                      secondRoundCurrentTable.rows.map((r, i) => {
                        const lastPosition = secondRoundPreviousTable.rows.findIndex(row => row.name === r.name);
                        if (i > lastPosition) {
                          r.status = 'down';
                        }
                        if (i < lastPosition) {
                          r.status = 'up';
                        }
                        return r;
                      });
                      return [firstRoundTable, secondRoundCurrentTable];
                    }),
                  );
                }
                return this.getRoundStandings(seasonId, rounds.data[rounds.data.length - 1].id)
                  .pipe(
                    map(standings => {
                      const rows = FootballStandingRow.fromDateStanding(standings);
                      return [new FootballTable(rows, 'No Rounds Available')];
                    })
                  );
              }),
            );
            return tables.pipe(
              map(t => {
                const heading = this.footBallLeagueNameMap[leagueSlug] ?
                  this.footBallLeagueNameMap[leagueSlug] + ' ' + year : league.data.name + ' ' + year;
                return new FootballTableWrapper(heading, t);
              })
            );
          }),
        );
      }),
    );
  }

  private stagesBySeasonId(seasonId: number): Observable<IFootballData<IFootballStage[]>> {
    return this.apiService.getCached<IFootballData<IFootballStage[]>>(
      `${this.footballTableApiBase}stages/season/${seasonId}`,
      false,
    );
  }

  private stageById(stageId: number): Observable<IFootballData<IFootballStageResultInclude>> {
    return this.apiService.getCached<IFootballData<IFootballStageResultInclude>>(
      `${this.footballTableApiBase}stages/${stageId}?include=results`,
      false,
    );
  }

  private upcomingFixturesBySeason(seasonId: number) {
    return this.apiService.getCached<IFootballData<IFootballSeasonUpcomingInclude>>(
      `${this.footballTableApiBase}seasons/${seasonId}?include=upcoming`,
      false,
    );
  }

  getFootballStageModel(footBallStage: IFootballStage): Observable<FootballStageModel[]> {
    return forkJoin([
      this.stageById(footBallStage.id),
      this.getFootballTeamsBySeasonId(footBallStage.season_id),
      this.upcomingFixturesBySeason(footBallStage.season_id),
    ]).pipe(
      map(res => {
        const stage = res[0];
        const teams = res[1];
        const upcomingFixtures = res[2].data.upcoming.data.filter(f => f.stage_id === footBallStage.id);
        return FootballStageModel.fromFootBallResult([...stage.data.results.data, ...upcomingFixtures], teams);
      }),
    );
  }

  getStageTabs(league: string, year: string = FootballService.currentYear): Observable<TabModel<IFootballStage>[]> {
    const id = this.footBallLeagueIdMap[league];
    return this.getSeasonByLeagueYear$(id, year)
      .pipe(
        switchMap(season => {
          return this.stagesBySeasonId(season.id).pipe(
            map(stages => {
              let tabs = stages.data
                .sort((a, b) => a.sort_order - b.sort_order)
                .map((stage, i) => {
                  return new TabModel(stage.id, stage.name, stage, false);
                });
              if (id === 2) {
                const start = tabs.findIndex(t => t.name === '8th Finals');
                tabs = tabs.slice(start, tabs.length);
              }
              if (id === 5) {
                const start = tabs.findIndex(t => t.name === '16th Finals');
                tabs = tabs.slice(start, tabs.length);
              }
              tabs[0].isSelected = true;
              return tabs;
            }),
          );
        })
      );
  }

  private nationLeagueTabelsByStageRound(data: IFootballLeagueStanding<IFootballGroupStanding[]>[], round: IFootballRound) {
    const groups: IFootballLeagueStanding<IFootballGroupStanding[]>[] = [];
    data.forEach(standings => {
      // @ts-ignore
      standings.standings.data.forEach((st: IFootballLeagueStanding) => {
        groups.push(st);
      });
    });
    return forkJoin(groups.filter(group => group.stage_id === round.stage_id).map(group => {
      return this.getRoundStandings(data[0].season_id, round.id, group.id)
        .pipe(
          map(response => {
            const rows = FootballStandingRow.fromDateStanding(response);
            return new FootballTable(rows, group.stage_name + ' ' + group.name);
          }),
        );
    }));
  }

  public fromNationsLeagueStandings$(year: string, round: IFootballRound): Observable<FootballTableWrapper> {
    const id = 1538;
    return this.leagueFromId$(id)
      .pipe(
        switchMap(leagueRes => {
          return this.getSeasonByLeagueYear$(id, year).pipe(
            switchMap((season) => {
              const seasonId = season.id;
              return this.getFootBallLeagueStandingBySeasonId<IFootballGroupStanding[]>(seasonId).pipe(
                switchMap(res => {
                  return this.getRoundsFromSeasonId$(seasonId).pipe(
                    switchMap(rounds => {
                      const nameRounds = rounds.data.filter(r => r.name === round.name);
                      return forkJoin(nameRounds.map(r => this.nationLeagueTabelsByStageRound(res.data, r)));
                    }),
                  );
                }),
                map(res => {
                  const t: FootballTable[] = [];
                  res.forEach(r => t.push(...r));
                  const tables = t.sort((a, b) => {
                    if (a.heading > b.heading) {
                      return 1;
                    }
                    if (a.heading < b.heading) {
                      return -1;
                    }
                    return 0;
                  });
                  return new FootballTableWrapper(leagueRes.data.name + ' ' + year, tables);
                }),
              );
            }),
          );
        }),
      );
  }
}
