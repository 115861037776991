import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {IDynamicComponent} from '@model/payload';
import {isPlatformBrowser} from '@angular/common';
import {BrowserRef} from '@shared/service/browser.ref';
import {PianoService} from '@shared/service/piano/piano.service';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {GoogleTagManagerService} from '@shared/service/gtm.service';

@Component({
  selector: 'app-paywall',
  templateUrl: './paywall.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaywallComponent implements AfterViewInit, DynamicComponent<IDynamicComponent> {

  showOffer$: Observable<boolean>;
  observer: IntersectionObserver;
  @ViewChild('paywallDiv', {static: false}) paywallDiv: ElementRef;
  private payWallShown = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private browserRef: BrowserRef,
    private piano: PianoService,
    private gtm: GoogleTagManagerService,
  ) {
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId) && this.paywallDiv && this.paywallDiv.nativeElement) {
      this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        const isIntersecting = !!entries.find(el => el.isIntersecting === true);
        if (isIntersecting && !this.payWallShown) {
          this.gtm.pushDataLayerOnBrowser({event: 'trackPaywallShown'});
          this.payWallShown = true;
        }
      }, {
        threshold: 1.0,
      });
      this.observer.observe(this.paywallDiv.nativeElement);
    }
  }

  initModel(model: IDynamicComponent): void {
    if (isPlatformBrowser(this.platformId)) {
      const collection: HTMLCollection = this.browserRef.document.getElementsByClassName('article-paragraphs').item(0).children;
      const paragraphs = Array.from(collection);
      const cutOffIndex = paragraphs.findIndex(el => el.tagName === 'APP-PAYWALL');
      const visibleParagraphs = paragraphs.slice(0, cutOffIndex);
      visibleParagraphs.forEach(p => p.classList.add('visible'));
    }
    this.showOffer$ = this.piano.checkPaywallAccess().pipe(
      map(access => !access),
    );
  }

}
