var _a, _b;
import { ScriptId } from '../enum/scriptId';
export var SCRIPT_MAP = (_a = {},
    _a[ScriptId.twitter] = 'https://platform.twitter.com/widgets.js',
    _a[ScriptId.facebook] = 'https://connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v8.0',
    _a[ScriptId.gpt] = 'https://www.googletagservices.com/tag/js/gpt.js',
    _a[ScriptId.instagram] = 'https://platform.instagram.com/en_US/embeds.js',
    _a[ScriptId.outbrain] = 'https://widgets.outbrain.com/outbrain.js',
    _a[ScriptId.pinterest] = 'https://assets.pinterest.com/js/pinit_main.js',
    _a[ScriptId.pusher] = 'https://js.pusher.com/4.1/pusher.min.js',
    _a[ScriptId.riddle] = 'https://www.riddle.com/files/js/embed.js',
    _a[ScriptId.opta] = 'https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js',
    _a[ScriptId.opta2] = 'https://secure.widget.cloud.opta.net/2.0/js/widgets.opta.js',
    _a[ScriptId.contentGarden] = 'https://cdn.content-garden.com/base.min.js',
    _a[ScriptId.adition] = 'https://imagesrv.adition.com/js/adition.js',
    _a[ScriptId.mediakey] = '',
    _a[ScriptId.tinypass] = '',
    _a[ScriptId.justwatch] = 'https://widget.justwatch.com/justwatch_widget.js',
    _a[ScriptId.reddit] = 'https://embed.redditmedia.com/widgets/platform.js',
    _a[ScriptId.tiktok] = 'https://www.tiktok.com/embed.js',
    _a[ScriptId.playerjs] = '//cdn.embed.ly/player-0.0.12.min.js',
    _a[ScriptId.flourish] = 'https://public.flourish.studio/resources/embed.js',
    _a[ScriptId.sportmonkLivescore] = 'https://widgets.sportmonks.com/js/world-cup/livescore.js',
    _a[ScriptId.sportmonkStandings] = 'https://widgets.sportmonks.com/js/world-cup/standings.js',
    _a[ScriptId.sportmonkStandingsSquad] = 'https://widgets.sportmonks.com/js/world-cup/livescore.js',
    _a[ScriptId.sportmonkStandingsSlider] = 'https://widgets.sportmonks.com/js/world-cup/standingsSlider.js',
    _a[ScriptId.sportmonkLivestatus] = 'https://widgets.sportmonks.com/js/livescore/livescore.js',
    _a[ScriptId.oewa] = '',
    _a[ScriptId.oewaBundle] = '',
    _a);
export var SCRIPT_ID_MAP = (_b = {},
    _b[ScriptId.twitter] = 'twttr',
    _b[ScriptId.facebook] = 'FB',
    _b[ScriptId.gpt] = 'googletag',
    _b[ScriptId.instagram] = 'instgrm',
    _b[ScriptId.outbrain] = 'OBR',
    _b[ScriptId.pinterest] = '',
    _b[ScriptId.pusher] = 'Pusher',
    _b[ScriptId.riddle] = 'riddleAPI',
    _b[ScriptId.opta] = 'Opta',
    _b[ScriptId.opta2] = '$jqOpta',
    _b[ScriptId.contentGarden] = 'contentGardenContentDelivery',
    _b[ScriptId.mediakey] = 'MediakeyClient',
    _b[ScriptId.cleverpush] = 'CleverPush',
    _b[ScriptId.justwatch] = 'JustWatch',
    _b[ScriptId.reddit] = 'Reddit',
    _b[ScriptId.tiktok] = 'tikTok',
    _b[ScriptId.playerjs] = 'playerjs',
    _b[ScriptId.comments] = 'Coral',
    _b[ScriptId.oewa] = 'IOMm',
    _b[ScriptId.oewaBundle] = '',
    _b);
