<div class="footballStages">

  <section
    *ngFor="let m of model"
    class="footballStages-day">
    <header class="footballStages-day-header">
      <h2 class="footballStages-day-header-title">
        <span>{{m.heading}}</span>
      </h2>
    </header>

    <div
      *ngFor="let game of m.games"
      class="footballStages-day-game">
      <div class="footballStages-day-game-team local">
        <img class="team-logo" [src]="game.team1.logo" [alt]="game.team1.name + ' Logo'">
        <p class="team-name">{{game.team1.name}}</p>
      </div>
      <div class="footballStages-day-game-score">
        <p class="score">{{game.results}}</p>
      </div>
      <div class="footballStages-day-game-team visitor">
        <img class="team-logo" [src]="game.team2.logo" [alt]="game.team2.name + ' Logo'">
        <p class="team-name">{{game.team2.name}}</p>
      </div>
    </div>
  </section>
</div>
