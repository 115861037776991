/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/directive/portalRoute.directive";
import * as i2 from "@angular/router";
import * as i3 from "../../../../shared/service/portal.service";
import * as i4 from "../../../../shared/service/browser.ref";
import * as i5 from "../../gtmTrigger.directive";
import * as i6 from "@angular/common";
import * as i7 from "../svgIcon/svgIcon.component.ngfactory";
import * as i8 from "../svgIcon/svgIcon.component";
import * as i9 from "./iconButton.component";
var styles_IconButtonComponent = [];
var RenderType_IconButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IconButtonComponent, data: {} });
export { RenderType_IconButtonComponent as RenderType_IconButtonComponent };
function View_IconButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_IconButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 540672, null, 0, i1.PortalRouteDirective, [i2.Router, i0.ElementRef, i3.PortalService, i4.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), i0.ɵdid(2, 81920, null, 0, i5.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconButtonComponent_2)), i0.ɵdid(4, 540672, null, 0, i6.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.link; _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.gtmId; _ck(_v, 2, 0, currVal_3); var currVal_4 = i0.ɵnov(_v.parent, 7); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_IconButtonComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_IconButtonComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.linkTo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 81920, null, 0, i5.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconButtonComponent_4)), i0.ɵdid(3, 540672, null, 0, i6.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gtmId; _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v.parent, 7); _ck(_v, 3, 0, currVal_1); }, null); }
function View_IconButtonComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_IconButtonComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconButtonComponent_6)), i0.ɵdid(2, 540672, null, 0, i6.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 7); _ck(_v, 2, 0, currVal_0); }, null); }
function View_IconButtonComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svgicon", [], [[1, "class", 0]], null, null, i7.View_SvgIconComponent_0, i7.RenderType_SvgIconComponent)), i0.ɵdid(1, 49152, null, 0, i8.SvgIconComponent, [], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.icon; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
export function View_IconButtonComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "iconButton"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconButtonComponent_1)), i0.ɵdid(2, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconButtonComponent_3)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconButtonComponent_5)), i0.ɵdid(6, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, [["templateIcon", 2]], null, 0, null, View_IconButtonComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.href; _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.link && !_co.href); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_IconButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "iconbutton", [], null, null, null, View_IconButtonComponent_0, RenderType_IconButtonComponent)), i0.ɵdid(1, 114688, null, 0, i9.IconButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconButtonComponentNgFactory = i0.ɵccf("iconbutton", i9.IconButtonComponent, View_IconButtonComponent_Host_0, { href: "href", link: "link", icon: "icon", blank: "blank", gtmId: "gtmId" }, {}, []);
export { IconButtonComponentNgFactory as IconButtonComponentNgFactory };
