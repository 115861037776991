import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ComponentFactoryResolver, OnInit } from '@angular/core';
import { TogglePopupComponent } from '@component/common/togglePopup.component';
import { LoginModel } from '@model/user/login';
import { GTMTriggerId } from '@model/enum/gtm';
import { StateService } from '@shared/service/state.service';
import { PianoService } from '@shared/service/piano/piano.service';
import { filter, take, tap } from 'rxjs/operators';
import { OverlayService } from '@shared/service/overlay.service';
import { DeactivatedAdBlockComponent } from '@component/header/deactivate-ad-block/deactivated-ad-block.component';
import { DidomiService } from '@shared/service/didomi.service';
import { VendorTypes } from '@model/enum/vendorTypes';
import { NoConsentPianoComponent } from '@component/header/no-consent-piano/no-consent-piano.component';
var LoginComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LoginComponent, _super);
    function LoginComponent(stateService, piano, cd, overlayService, resolver, didomiService) {
        var _this = _super.call(this) || this;
        _this.stateService = stateService;
        _this.piano = piano;
        _this.cd = cd;
        _this.overlayService = overlayService;
        _this.resolver = resolver;
        _this.didomiService = didomiService;
        _this.model = new LoginModel();
        _this.gtmId = GTMTriggerId.user_status;
        return _this;
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user$ = this.piano.user$.pipe(tap(function (u) {
            u.valid ?
                u.firstName && u.lastName ? _this.buttonText = u.firstName + ' ' + u.lastName : _this.buttonText = 'Mein Profil'
                : _this.buttonText = 'Anmelden';
            _this.cd.detectChanges();
        }));
    };
    LoginComponent.prototype.togglePopUp = function () {
        var _this = this;
        this.didomiService.checkVendor$(VendorTypes.piano).pipe(take(1), tap(function (consent) {
            if (consent) {
                _this.piano.tp$.pipe(take(1), tap(function (tp) {
                    if (tp) {
                        if (tp.pianoId.isUserValid()) {
                            _super.prototype.togglePopUp.call(_this);
                            setTimeout(_this.showMyAccount.bind(_this));
                        }
                        else {
                            _this.piano.showLogin();
                        }
                    }
                    else {
                        _this.overlayService.addComponent(_this.resolver, DeactivatedAdBlockComponent);
                    }
                })).subscribe();
            }
            else {
                _this.overlayService.addComponent(_this.resolver, NoConsentPianoComponent);
            }
        })).subscribe();
        return false;
    };
    LoginComponent.prototype.logout = function () {
        this.showPopUp = false;
        this.piano.doLogout();
        return false;
    };
    LoginComponent.prototype.showMyAccount = function () {
        this.piano.tp$.pipe(filter(Boolean)).subscribe(function (tp) {
            tp.myaccount.show({
                displayMode: 'inline',
                containerSelector: '#piano-myaccount-container'
            });
        });
        return false;
    };
    return LoginComponent;
}(TogglePopupComponent));
export { LoginComponent };
