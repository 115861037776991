import {Component} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {ITitleComponent} from '../../../../model/payload';
import {Observable, ReplaySubject} from 'rxjs';
import {BrowserRef} from '../../../../shared/service/browser.ref';
import {ExternalApiService} from '../../../../shared/service/externalApi.service';
import {IImmoLocationSearch} from '../../../../model/channel/immoBox/immoBox';
import {distinctUntilChanged, switchMap} from 'rxjs/operators';

@Component({
  selector: 'immobox',
  templateUrl: './immoBox.component.html',
})
export class ImmoBoxComponent implements DynamicComponent<ITitleComponent> {

  rentType = [
    {value: 'sale', name: 'Kaufen'},
    {value: 'rental', name: 'Mieten'}];
  rentTypeSelected = 0;

  immoType = [
    {value: 'all', name: 'Alle Wohnobjekte'},
    {value: 'house', name: 'Haus'},
    {value: 'apartment', name: 'Wohnung'}];
  immoTypeSelected = 0;

  location = '';
  searchActive = true;
  model: ITitleComponent;
  searchResult$: Observable<any>;
  private searchTerm$ = new ReplaySubject<string>();

  constructor(private browserRef: BrowserRef, private apiService: ExternalApiService) {
  }

  onSubmit() {
    let link;
    if (this.location !== '') {
      link = `https://immo.kurier.at/suche?utm_source=immo-search-box&
        utm_medium=web&
        utm_content=form&
        utm_campaign=classified-search-boxes&
        l=${this.location}&
        t=${this.immoType[this.immoTypeSelected].value}%3A${this.rentType[this.rentTypeSelected].value}`;
    } else {
      link = 'https://immo.kurier.at';
    }
    this.browserRef.window.open(link, '_blank');
    return false;
  }

  initModel(model: ITitleComponent): void {
    this.model = model;
    this.searchResult$ = this.searchTerm$.pipe(
      distinctUntilChanged(),
      switchMap(term => {
        return this.apiService.immoSearch(term);
      }),
    );
  }

  onInputChanged(value: string) {
    this.searchTerm$.next(value);
    this.searchActive = this.location !== '';
  }

  selectInput(result: IImmoLocationSearch) {
    this.location = result.name;
    this.searchActive = false;
  }
}
