import * as tslib_1 from "tslib";
import { ApiService } from '@shared/service/api.service';
import { forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FootballStandingRow, GameType } from '@model/content/footballTable/footballStandingRow';
import { FootballTable } from '@model/content/footballTable/footballTable';
import { FootballTableWrapper } from '@model/content/footballTable/footballTableWrapper';
import { HttpClient } from '@angular/common/http';
import { TabModel } from '@model/component/tab';
import { FootballStageModel } from '@model/content/footballStages/footballStage';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "@angular/common/http";
var FootballService = /** @class */ (function () {
    function FootballService(apiService, http) {
        this.apiService = apiService;
        this.http = http;
        this.footballTableApiBase = 'https://sportmonks.telekurier.at/soccer/api/v2.0/';
        this.footBallLeagueIdMap = {
            'at-league': 181,
            'at-league-playoff': 181,
            'at-league-1': 184,
            'de-league': 82,
            'uefa-ec': 1326,
            'uefa-cl': 2,
            'uefa-el': 5,
            'en-league': 8,
            'es-league': 564,
            'it-league': 384,
            'wc-qualification-europe': 720,
            'european-championship': 1389,
            'uefa-women-euro': 1389,
            'uefa-nations-league': 1538,
            'europa-league': 5,
            'champions-league': 2,
            'world-cup': 732,
            'austrian-cup': 187,
            em_quali: 1325,
        };
        this.footBallLeagueNameMap = {
            'at-league-1': 'Admiral 2. Liga',
            'at-league': 'Ö. Bundesliga Grunddurchgang',
        };
        this.austrianLeagueMap = {
            'Regular Season': 'Grunddurchgang',
            'Relegation Round': 'Qualifikationsgruppe',
            'Championship Round': 'Meistergruppe',
        };
    }
    Object.defineProperty(FootballService.prototype, "footBallLeagueIds", {
        get: function () {
            return this.footBallLeagueIdMap;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FootballService, "currentYear", {
        get: function () {
            var currYear = new Date().getFullYear();
            return (currYear - 1) + '/' + (currYear);
        },
        enumerable: true,
        configurable: true
    });
    FootballService.prototype.getAllSeasons = function (page) {
        var _this = this;
        if (page === void 0) { page = 1; }
        return this.apiService.getCached(this.footballTableApiBase + "seasons?page=" + page, false).pipe(switchMap(function (res) {
            if (res.meta.pagination.links.next) {
                return _this.getAllSeasons(res.meta.pagination.current_page + 1)
                    .pipe(map(function (nextSeasonData) {
                    return tslib_1.__spread(res.data, nextSeasonData);
                }));
            }
            return of(res.data);
        }));
    };
    FootballService.prototype.getTeamById = function (id) {
        return this.apiService.getCached(this.footballTableApiBase + "teams/" + id, false);
    };
    FootballService.prototype.getCurrentLiveScores = function () {
        return this.http.get(this.footballTableApiBase + "livescores/now");
    };
    FootballService.prototype.getDailyLiveScores = function () {
        return this.apiService.getCached(this.footballTableApiBase + "livescores", false);
    };
    FootballService.prototype.getFixtureById = function (id) {
        return this.http.get(this.footballTableApiBase + "fixtures/" + id + "?include=events");
    };
    FootballService.prototype.getVenueById = function (id) {
        return this.apiService.getCached(this.footballTableApiBase + "venues/" + id, false);
    };
    FootballService.prototype.getFixture = function (id) {
        var _this = this;
        return this.getCurrentLiveScores().pipe(switchMap(function (liveScores) {
            var isLive = !!liveScores.data.find(function (ls) { return ls.id === id; });
            return _this.getFixtureById(id).pipe(switchMap(function (fixture) {
                var venueId = fixture.data.venue_id;
                var localTeamId = fixture.data.localteam_id;
                var visitorTeamId = fixture.data.visitorteam_id;
                return forkJoin([
                    _this.getTeamById(localTeamId),
                    _this.getTeamById(visitorTeamId),
                    _this.getVenueById(venueId),
                ]).pipe(map(function (teams) {
                    var localTeam = teams[0];
                    var visitorTeam = teams[1];
                    var venue = teams[2];
                    var penaltyShootout = fixture.data.events.data.filter(function (e) { return e.type.includes('pen_shootout'); }).reverse();
                    var nonPenaltyShootout = fixture.data.events.data.filter(function (e) { return !e.type.includes('pen_shootout'); }).reverse();
                    var events = tslib_1.__spread(penaltyShootout, nonPenaltyShootout);
                    var min = 0;
                    if (fixture.data.time.minute) {
                        min += +fixture.data.time.minute;
                    }
                    if (fixture.data.time.extra_minute) {
                        min += +fixture.data.time.extra_minute;
                    }
                    var lts = fixture.data.scores.localteam_score;
                    var vts = fixture.data.scores.visitorteam_score;
                    if (fixture.data.scores.localteam_pen_score) {
                        lts += +fixture.data.scores.localteam_pen_score;
                    }
                    if (fixture.data.scores.visitorteam_pen_score) {
                        vts += +fixture.data.scores.visitorteam_pen_score;
                    }
                    return {
                        isLive: isLive,
                        events: events,
                        localTeam: localTeam.data,
                        visitorTeam: visitorTeam.data,
                        scores: lts + ' - ' + vts,
                        min: min + '’ min',
                        venue: venue.data
                    };
                }));
            }));
        }));
    };
    FootballService.prototype.getSeasonByLeagueYear$ = function (leagueId, year) {
        return this.getAllSeasons().pipe(map(function (seasons) {
            return seasons.find(function (s) { return s.league_id === leagueId && s.name === year; });
        }));
    };
    FootballService.prototype.fromCupStandings$ = function (league, round, year) {
        var _this = this;
        if (year === void 0) { year = FootballService.currentYear; }
        var roundId = round.id;
        var id = this.footBallLeagueIdMap[league];
        return this.leagueFromId$(id)
            .pipe(switchMap(function (leagueRes) {
            return _this.getSeasonByLeagueYear$(id, year).pipe(switchMap(function (season) {
                var seasonId = season.id;
                return forkJoin([
                    _this.getFootBallLeagueStandingBySeasonId(seasonId),
                    _this.getFootballTeamsBySeasonId(seasonId),
                    _this.getRoundsFromSeasonId$(seasonId),
                ]).pipe(switchMap(function (res) {
                    var currRoundIndex = res[2].data.findIndex(function (r) { return r.id === roundId; });
                    return forkJoin(res[0].data.map(function (leagueStanding) {
                        if (currRoundIndex > 0) {
                            return forkJoin([
                                _this.getRoundStandings(seasonId, roundId, leagueStanding.id),
                                _this.getRoundStandings(seasonId, res[2].data[currRoundIndex - 1].id, leagueStanding.id)
                            ]).pipe(map(function (standings) { return new FootballTable(FootballStandingRow.fromDateStanding(standings[0], undefined, standings[1]), leagueStanding.name); }));
                        }
                        else {
                            return _this.getRoundStandings(seasonId, roundId, leagueStanding.id).pipe(map(function (standings) { return new FootballTable(FootballStandingRow.fromDateStanding(standings), leagueStanding.name); }));
                        }
                    }));
                }), map(function (res) {
                    var tables = res.sort(function (a, b) {
                        if (a.heading > b.heading) {
                            return 1;
                        }
                        if (a.heading < b.heading) {
                            return -1;
                        }
                        return 0;
                    });
                    return new FootballTableWrapper(leagueRes.data.name + ' ' + year, tables);
                }));
            }));
        }));
    };
    FootballService.prototype.fromWomanCupStandings$ = function (group, round, year) {
        var _this = this;
        if (year === void 0) { year = FootballService.currentYear; }
        var groupName = 'Group ' + group.toUpperCase();
        var roundId = round.id;
        var id = 1389;
        return this.leagueFromId$(id)
            .pipe(switchMap(function (leagueRes) {
            return _this.getSeasonByLeagueYear$(id, year).pipe(switchMap(function (season) {
                var seasonId = season.id;
                return forkJoin([
                    _this.getFootBallLeagueStandingBySeasonId(seasonId),
                    _this.getFootballTeamsBySeasonId(seasonId),
                    _this.getRoundsFromSeasonId$(seasonId),
                ]).pipe(switchMap(function (res) {
                    var currRoundIndex = res[2].data.findIndex(function (r) { return r.id === roundId; });
                    var leagueStanding = res[0].data[1];
                    var t = leagueStanding.standings.data.find(function (g) { return g.name === groupName; }).standings.data;
                    var teamNames = t.map(function (st) { return st.team_name; });
                    if (currRoundIndex > 0) {
                        return forkJoin([
                            _this.getRoundStandings(seasonId, roundId, leagueStanding.id),
                            _this.getRoundStandings(seasonId, res[2].data[currRoundIndex - 1].id, leagueStanding.id)
                        ]).pipe(map(function (standings) {
                            var newStandings = tslib_1.__assign({}, standings[0]);
                            newStandings.data = standings[0].data.filter(function (st) { return teamNames.includes(st.team_name); });
                            var oldStandings = tslib_1.__assign({}, standings[1]);
                            oldStandings.data = standings[1].data.filter(function (st) { return teamNames.includes(st.team_name); });
                            return new FootballTable(FootballStandingRow.fromDateStanding(newStandings, undefined, oldStandings), groupName);
                        }));
                    }
                    else {
                        return _this.getRoundStandings(seasonId, roundId, leagueStanding.id).pipe(map(function (standings) {
                            var newStandings = tslib_1.__assign({}, standings);
                            newStandings.data = standings.data.filter(function (st) { return teamNames.includes(st.team_name); });
                            return new FootballTable(FootballStandingRow.fromDateStanding(newStandings), groupName);
                        }));
                    }
                }), map(function (res) {
                    return new FootballTableWrapper(leagueRes.data.name + ' ' + year, [res]);
                }));
            }));
        }));
    };
    FootballService.prototype.fromWorldCupStandings$ = function (year, group) {
        var _this = this;
        if (year === void 0) { year = FootballService.currentYear; }
        return this.getSeasonByLeagueYear$(732, year).pipe(switchMap(function (season) {
            var seasonId = season.id;
            return forkJoin([
                _this.getFootBallLeagueStandingBySeasonId(seasonId),
                _this.getFootballTeamsBySeasonId(seasonId),
            ]).pipe(map(function (res) {
                var t = res[0].data
                    .filter(function (st) {
                    if (group) {
                        return st.name === group;
                    }
                    return true;
                })
                    .map(function (st) { return new FootballTable(FootballStandingRow.fromStandings(st, res[1]), st.name); });
                return new FootballTableWrapper('', t);
            }));
        }));
    };
    FootballService.prototype.getFootBallLeagueStandingBySeasonId = function (seasonId) {
        return this.apiService.getCached(this.footballTableApiBase + "standings/season/" + seasonId, false);
    };
    FootballService.prototype.getFootballTeamsBySeasonId = function (seasonId, page) {
        var _this = this;
        if (page === void 0) { page = 1; }
        return this.apiService.getCached(this.footballTableApiBase + "teams/season/" + seasonId + "?page=" + page, false).pipe(switchMap(function (res) {
            if (res.meta.pagination.links.next) {
                return _this.getFootballTeamsBySeasonId(seasonId, res.meta.pagination.current_page + 1)
                    .pipe(map(function (nextTeamData) {
                    res.data = tslib_1.__spread(res.data, nextTeamData.data);
                    return res;
                }));
            }
            return of(res);
        }));
    };
    FootballService.prototype.fromLeagueStandings$ = function (league, year) {
        var _this = this;
        if (year === void 0) { year = FootballService.currentYear; }
        var id = this.footBallLeagueIdMap[league];
        return this.leagueFromId$(id)
            .pipe(switchMap(function (leagueRes) {
            return _this.getSeasonByLeagueYear$(id, year).pipe(switchMap(function (season) {
                var seasonId = season.id;
                return forkJoin([
                    _this.getFootBallLeagueStandingBySeasonId(seasonId),
                    _this.getFootballTeamsBySeasonId(seasonId),
                    _this.getRoundsFromSeasonId$(seasonId),
                ]).pipe(switchMap(function (res) {
                    return forkJoin(res[0].data.map(function (leagueStanding) {
                        var stageId = leagueStanding.stage_id;
                        var rounds = res[2];
                        // this weird code is to get round by date since some matches are postponed and in that case
                        // leagueStanding.round_id would be wrong
                        var now = +(new Date().toISOString().split('T')[0].split('-').join(''));
                        var reverseRounds = tslib_1.__spread(rounds.data.filter(function (r) { return r.stage_id === stageId; })).reverse();
                        var currentRoundIndex = reverseRounds.findIndex(function (r) {
                            var startDate = +r.end.split('-').join('');
                            return startDate < now;
                        });
                        if (currentRoundIndex >= 0) {
                            var previousRound = reverseRounds[currentRoundIndex + 1];
                            return _this.getRoundStandings(seasonId, previousRound.id, leagueStanding.standings.data[0].group_id)
                                .pipe(map(function (previousStanding) {
                                var currRows = FootballStandingRow.fromStandings(leagueStanding, res[1], previousStanding.data);
                                var h = leagueStanding.standings.data[0].group_name;
                                return new FootballTable(currRows, h);
                            }));
                        }
                        var rows = FootballStandingRow.fromStandings(leagueStanding, res[1]);
                        var heading = leagueStanding.standings.data[0].group_name;
                        return of(new FootballTable(rows, heading));
                    }));
                }), map(function (res) {
                    var heading = _this.footBallLeagueNameMap[league] ?
                        _this.footBallLeagueNameMap[league] + ' ' + year : leagueRes.data.name + ' ' + year;
                    return new FootballTableWrapper(heading, res);
                }));
            }));
        }));
    };
    FootballService.prototype.fromLeagueAustrianStandings$ = function (league, year) {
        var _this = this;
        if (year === void 0) { year = FootballService.currentYear; }
        var id = this.footBallLeagueIdMap[league];
        return this.getSeasonByLeagueYear$(id, year).pipe(switchMap(function (season) {
            var seasonId = season.id;
            return forkJoin([
                _this.getFootBallLeagueStandingBySeasonId(seasonId),
                _this.getFootballTeamsBySeasonId(seasonId),
            ]).pipe(map(function (res) {
                var footBallTables = res[0].data.map(function (leagueStanding, i) {
                    var rows;
                    var regularSeason = res[0].data.find(function (lst) { return lst.name === 'Regular Season'; });
                    if (regularSeason === leagueStanding) {
                        rows = FootballStandingRow.fromAustrianStandings(leagueStanding, res[1]);
                    }
                    else {
                        rows = FootballStandingRow.fromAustrianStandings(leagueStanding, res[1], regularSeason);
                    }
                    return new FootballTable(rows, _this.austrianLeagueMap[leagueStanding.stage_name]);
                });
                return new FootballTableWrapper(_this.footBallLeagueNameMap[league], tslib_1.__spread(footBallTables).reverse());
            }));
        }));
    };
    FootballService.prototype.fromLeagueAustrianAwayHome$ = function (league, year) {
        var _this = this;
        if (year === void 0) { year = FootballService.currentYear; }
        var id = this.footBallLeagueIdMap[league];
        return this.getSeasonByLeagueYear$(id, year).pipe(switchMap(function (season) {
            var seasonId = season.id;
            return forkJoin([
                _this.getFootBallLeagueStandingBySeasonId(seasonId),
                _this.getFootballTeamsBySeasonId(seasonId),
            ]).pipe(map(function (res) {
                return res[0].data.map(function (leagueStanding) {
                    var defaultAwayRows = FootballStandingRow.fromStandings(leagueStanding, res[1], undefined, GameType.away);
                    var defaultHomeRows = FootballStandingRow.fromStandings(leagueStanding, res[1], undefined, GameType.home);
                    return {
                        homeTable: new FootballTable(defaultHomeRows, 'Heimtabelle ' + _this.austrianLeagueMap[leagueStanding.stage_name], true),
                        awayTable: new FootballTable(defaultAwayRows, 'Auswärtstabelle ' + _this.austrianLeagueMap[leagueStanding.stage_name], true),
                    };
                });
            }), map(function (res) {
                var tables = res
                    .map(function (t) { return [t.awayTable, t.homeTable]; })
                    .reduce(function (a, b) { return a.concat(b); });
                return new FootballTableWrapper(_this.footBallLeagueNameMap[league], tslib_1.__spread(tables).reverse());
            }));
        }));
    };
    FootballService.prototype.getRoundsFromSeasonId$ = function (seasonId) {
        return this.apiService.getCached(this.footballTableApiBase + "rounds/season/" + seasonId, false);
    };
    FootballService.prototype.getEmQualification = function (year, group) {
        var _this = this;
        var seasonName = '20' + year.split('/')[0];
        return this.getAllSeasons().pipe(switchMap(function (seasons) {
            var season = seasons.find(function (s) { return (s.league_id === 1325 && s.name === seasonName); });
            if (!season) {
                season = seasons.find(function (s) { return s.league_id === 1325 && s.is_current_season === true; });
            }
            return forkJoin([
                _this.getFootBallLeagueStandingBySeasonId(season.id),
                _this.getFootballTeamsBySeasonId(season.id)
            ]);
        }), map(function (data) {
            var standings = data[0];
            var teams = data[1];
            var tables = standings.data
                .filter(function (d) {
                if (group) {
                    var groupName = 'Group ' + group.toLocaleUpperCase();
                    return d.name === groupName;
                }
                return true;
            })
                .map(function (d) {
                var rows = FootballStandingRow.fromStandings(d, teams);
                return new FootballTable(rows, d.name.replace('Group', 'Gruppe'));
            });
            return new FootballTableWrapper('hallo', tables);
        }));
        // return this.getFootBallLeagueStandingBySeasonId<IFootballGroupStanding[]>(seasonId);
    };
    FootballService.prototype.getRoundsForSelection = function (league, year) {
        var _this = this;
        if (year === void 0) { year = FootballService.currentYear; }
        var id = this.footBallLeagueIdMap[league];
        return this.getSeasonByLeagueYear$(id, year)
            .pipe(switchMap(function (season) {
            return _this.getRoundsFromSeasonId$(season.id);
        }), map(function (rounds) {
            return rounds.data.map(function (round) {
                var text = round.name + '. Spieltag (' + (round.start.split('-').reverse().join('.')) + ' - ' +
                    (round.end.split('-').reverse().join('.')) + ')';
                if (id === 1538) {
                    return { value: round, name: text };
                }
                return {
                    value: round,
                    name: 'Vorrunde ' + text
                };
            });
        }));
    };
    FootballService.prototype.getRoundStandings = function (seasonId, roundId, groupId) {
        var groupParam = groupId ? "?group_id=" + groupId : '';
        return this.apiService.getCached(this.footballTableApiBase + "standings/season/" + seasonId + "/round/" + roundId + groupParam, false);
    };
    FootballService.prototype.leagueFromId$ = function (leagueId) {
        return this.apiService
            .getCached(this.footballTableApiBase + "leagues/" + leagueId + "?include=season", false);
    };
    FootballService.prototype.forAwayHome$ = function (league, year) {
        var _this = this;
        if (year === void 0) { year = FootballService.currentYear; }
        var id = this.footBallLeagueIdMap[league];
        return this.leagueFromId$(id)
            .pipe(switchMap(function (leagueRes) {
            return _this.getSeasonByLeagueYear$(id, year)
                .pipe(switchMap(function (season) {
                var seasonId = season.id;
                return forkJoin([
                    _this.getFootBallLeagueStandingBySeasonId(seasonId),
                    _this.getFootballTeamsBySeasonId(seasonId),
                ]).pipe(map(function (res) {
                    var leagueStandings = res[0].data;
                    return leagueStandings.map(function (ls) {
                        var homeRows = FootballStandingRow.fromStandings(ls, res[1], undefined, GameType.home);
                        var awayRows = FootballStandingRow.fromStandings(ls, res[1], undefined, GameType.away);
                        var heading = ls.standings.data[0].group_name ? ' ' + ls.standings.data[0].group_name : '';
                        return [
                            new FootballTable(homeRows, 'Heimtabelle' + heading, true),
                            new FootballTable(awayRows, 'Auswärtstabelle' + heading, true)
                        ];
                    });
                }), map(function (res) {
                    var heading = _this.footBallLeagueNameMap[league] ?
                        _this.footBallLeagueNameMap[league] + ' ' + year : leagueRes.data.name + ' ' + year;
                    return new FootballTableWrapper(heading, res.reduce(function (a, b) { return tslib_1.__spread(a, b); }));
                }));
            }));
        }));
    };
    FootballService.prototype.forFirstSecondRound$ = function (leagueSlug, year) {
        var _this = this;
        if (year === void 0) { year = FootballService.currentYear; }
        var leagueId = this.footBallLeagueIdMap[leagueSlug];
        return this.leagueFromId$(leagueId).pipe(switchMap(function (league) {
            return _this.getSeasonByLeagueYear$(leagueId, year).pipe(switchMap(function (season) {
                var seasonId = season.id;
                var tables;
                tables = _this.getRoundsFromSeasonId$(seasonId).pipe(switchMap(function (rounds) {
                    // this weird code is to get round by date since some matches are postponed and in that case
                    // leagueStanding.round_id would be wrong
                    var now = +(new Date().toISOString().split('T')[0].split('-').join(''));
                    var reverseRounds = tslib_1.__spread(rounds.data).reverse();
                    var currentRoundIndex = reverseRounds.findIndex(function (r) {
                        var startDate = +r.end.split('-').join('');
                        return startDate < now;
                    });
                    if (currentRoundIndex < reverseRounds.length / 2 - 1) {
                        var firstRound = rounds.data[rounds.data.length / 2 - 1];
                        var firstRoundPrevious = rounds.data[rounds.data.length / 2 - 2];
                        var secondRound = reverseRounds[currentRoundIndex];
                        var secondRoundPrevious = reverseRounds[currentRoundIndex + 1];
                        return forkJoin([
                            _this.getRoundStandings(seasonId, firstRound.id),
                            _this.getRoundStandings(seasonId, firstRoundPrevious.id),
                            _this.getRoundStandings(seasonId, secondRound.id),
                            _this.getRoundStandings(seasonId, secondRoundPrevious.id),
                        ]).pipe(map(function (standings) {
                            var firstRoundRows = FootballStandingRow.fromDateStanding(standings[0], undefined, standings[1]);
                            var firstRoundTable = new FootballTable(firstRoundRows, 'Hinrunde');
                            var secondRoundRowsPrevious = FootballStandingRow.fromDateStanding(standings[3]);
                            var secondRoundRowsCurrent = FootballStandingRow.fromDateStanding(standings[2]);
                            var secondRoundPreviousTable = FootballTable
                                .subtractTable(new FootballTable(secondRoundRowsPrevious), firstRoundTable);
                            var secondRoundCurrentTable = FootballTable
                                .subtractTable(new FootballTable(secondRoundRowsCurrent), firstRoundTable);
                            secondRoundCurrentTable.heading = 'Rückrunde';
                            secondRoundCurrentTable.rows.map(function (r, i) {
                                var lastPosition = secondRoundPreviousTable.rows.findIndex(function (row) { return row.name === r.name; });
                                if (i > lastPosition) {
                                    r.status = 'down';
                                }
                                if (i < lastPosition) {
                                    r.status = 'up';
                                }
                                return r;
                            });
                            return [firstRoundTable, secondRoundCurrentTable];
                        }));
                    }
                    return _this.getRoundStandings(seasonId, rounds.data[rounds.data.length - 1].id)
                        .pipe(map(function (standings) {
                        var rows = FootballStandingRow.fromDateStanding(standings);
                        return [new FootballTable(rows, 'No Rounds Available')];
                    }));
                }));
                return tables.pipe(map(function (t) {
                    var heading = _this.footBallLeagueNameMap[leagueSlug] ?
                        _this.footBallLeagueNameMap[leagueSlug] + ' ' + year : league.data.name + ' ' + year;
                    return new FootballTableWrapper(heading, t);
                }));
            }));
        }));
    };
    FootballService.prototype.stagesBySeasonId = function (seasonId) {
        return this.apiService.getCached(this.footballTableApiBase + "stages/season/" + seasonId, false);
    };
    FootballService.prototype.stageById = function (stageId) {
        return this.apiService.getCached(this.footballTableApiBase + "stages/" + stageId + "?include=results", false);
    };
    FootballService.prototype.upcomingFixturesBySeason = function (seasonId) {
        return this.apiService.getCached(this.footballTableApiBase + "seasons/" + seasonId + "?include=upcoming", false);
    };
    FootballService.prototype.getFootballStageModel = function (footBallStage) {
        return forkJoin([
            this.stageById(footBallStage.id),
            this.getFootballTeamsBySeasonId(footBallStage.season_id),
            this.upcomingFixturesBySeason(footBallStage.season_id),
        ]).pipe(map(function (res) {
            var stage = res[0];
            var teams = res[1];
            var upcomingFixtures = res[2].data.upcoming.data.filter(function (f) { return f.stage_id === footBallStage.id; });
            return FootballStageModel.fromFootBallResult(tslib_1.__spread(stage.data.results.data, upcomingFixtures), teams);
        }));
    };
    FootballService.prototype.getStageTabs = function (league, year) {
        var _this = this;
        if (year === void 0) { year = FootballService.currentYear; }
        var id = this.footBallLeagueIdMap[league];
        return this.getSeasonByLeagueYear$(id, year)
            .pipe(switchMap(function (season) {
            return _this.stagesBySeasonId(season.id).pipe(map(function (stages) {
                var tabs = stages.data
                    .sort(function (a, b) { return a.sort_order - b.sort_order; })
                    .map(function (stage, i) {
                    return new TabModel(stage.id, stage.name, stage, false);
                });
                if (id === 2) {
                    var start = tabs.findIndex(function (t) { return t.name === '8th Finals'; });
                    tabs = tabs.slice(start, tabs.length);
                }
                if (id === 5) {
                    var start = tabs.findIndex(function (t) { return t.name === '16th Finals'; });
                    tabs = tabs.slice(start, tabs.length);
                }
                tabs[0].isSelected = true;
                return tabs;
            }));
        }));
    };
    FootballService.prototype.nationLeagueTabelsByStageRound = function (data, round) {
        var _this = this;
        var groups = [];
        data.forEach(function (standings) {
            // @ts-ignore
            standings.standings.data.forEach(function (st) {
                groups.push(st);
            });
        });
        return forkJoin(groups.filter(function (group) { return group.stage_id === round.stage_id; }).map(function (group) {
            return _this.getRoundStandings(data[0].season_id, round.id, group.id)
                .pipe(map(function (response) {
                var rows = FootballStandingRow.fromDateStanding(response);
                return new FootballTable(rows, group.stage_name + ' ' + group.name);
            }));
        }));
    };
    FootballService.prototype.fromNationsLeagueStandings$ = function (year, round) {
        var _this = this;
        var id = 1538;
        return this.leagueFromId$(id)
            .pipe(switchMap(function (leagueRes) {
            return _this.getSeasonByLeagueYear$(id, year).pipe(switchMap(function (season) {
                var seasonId = season.id;
                return _this.getFootBallLeagueStandingBySeasonId(seasonId).pipe(switchMap(function (res) {
                    return _this.getRoundsFromSeasonId$(seasonId).pipe(switchMap(function (rounds) {
                        var nameRounds = rounds.data.filter(function (r) { return r.name === round.name; });
                        return forkJoin(nameRounds.map(function (r) { return _this.nationLeagueTabelsByStageRound(res.data, r); }));
                    }));
                }), map(function (res) {
                    var t = [];
                    res.forEach(function (r) { return t.push.apply(t, tslib_1.__spread(r)); });
                    var tables = t.sort(function (a, b) {
                        if (a.heading > b.heading) {
                            return 1;
                        }
                        if (a.heading < b.heading) {
                            return -1;
                        }
                        return 0;
                    });
                    return new FootballTableWrapper(leagueRes.data.name + ' ' + year, tables);
                }));
            }));
        }));
    };
    FootballService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FootballService_Factory() { return new FootballService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.HttpClient)); }, token: FootballService, providedIn: "root" });
    return FootballService;
}());
export { FootballService };
