import {NgModule} from '@angular/core';
import {HeaderContainerComponent} from './headerContainer.component';
import {AnimationComponent} from './animation/animation.component';
import {MenuComponent} from './menu/menu.component';
import {NavBarComponent} from './navBar/navBar.component';
import {LoginComponent} from './login/login.component';
import {SearchMenuComponent} from './searchMenu/searchMenu.component';
import {SharedModule} from '../../shared/shared.module';
import {OverlayComponent} from './overlay/overlay.component';
import {PortalsComponent} from './portals/portals.component';
import {DeactivatedAdBlockComponent} from './deactivate-ad-block/deactivated-ad-block.component';
import {NoConsentPianoComponent} from './no-consent-piano/no-consent-piano.component';
import {OverlayTemplateComponent} from '@component/common/overlay-template/overlay-template.component';

const HEADER_COMPS = [
  DeactivatedAdBlockComponent,
  HeaderContainerComponent,
  AnimationComponent,
  MenuComponent,
  NavBarComponent,
  LoginComponent,
  SearchMenuComponent,
  OverlayComponent,
  PortalsComponent,
  NoConsentPianoComponent,
];

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    ...HEADER_COMPS,
  ],
  entryComponents: [
    SearchMenuComponent,
    DeactivatedAdBlockComponent,
    NoConsentPianoComponent,
    OverlayTemplateComponent,
  ],
  exports: [
    HeaderContainerComponent,
  ]
})
export class HeaderContainerModule {
}
