/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/svgIcon/svgIcon.component.ngfactory";
import * as i2 from "../../../common/ui/svgIcon/svgIcon.component";
import * as i3 from "./coral-comments.component";
import * as i4 from "../../../../shared/service/coral.service";
import * as i5 from "../../../../shared/service/piano/piano.service";
import * as i6 from "@angular/router";
var styles_CoralCommentsComponent = [];
var RenderType_CoralCommentsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CoralCommentsComponent, data: {} });
export { RenderType_CoralCommentsComponent as RenderType_CoralCommentsComponent };
export function View_CoralCommentsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "header", [["class", "coralComments-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "svgicon", [], [[1, "class", 0]], null, null, i1.View_SvgIconComponent_0, i1.RenderType_SvgIconComponent)), i0.ɵdid(2, 49152, null, 0, i2.SvgIconComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Kommentare"])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["id", "coral_thread"]], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = "message"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).class; _ck(_v, 1, 0, currVal_0); }); }
export function View_CoralCommentsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-coral-comments", [], null, null, null, View_CoralCommentsComponent_0, RenderType_CoralCommentsComponent)), i0.ɵdid(1, 245760, null, 0, i3.CoralCommentsComponent, [i4.CoralService, i5.PianoService, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CoralCommentsComponentNgFactory = i0.ɵccf("app-coral-comments", i3.CoralCommentsComponent, View_CoralCommentsComponent_Host_0, {}, {}, []);
export { CoralCommentsComponentNgFactory as CoralCommentsComponentNgFactory };
