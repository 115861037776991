import {NgModule} from '@angular/core';
import {AdMediumRectangleComponent} from './mediumRectangle/adMediumRectangle.component';
import {AdSuperbannerComponent} from './adsuperbanner/adsuperbanner.component';
import {OutbrainComponent} from './outbrain/outbrain.component';
import {AdInContentComponent} from './adInContent/adInContent.component';
import {AdFullbannerComponent} from './adFullbanner/adFullbanner.component';
import {AdArticlebottomComponent} from './adArticlebottom/adArticlebottom.component';
import {Ad1x1Component} from './ad1x1/ad1x1.component';
import {AdMobileBannerComponent} from './adMobileBanner/admobilebanner.component';
import {AdResponsiveComponent} from './adResponsive/adresponsive.component';
import {SharedModule} from '../../shared/shared.module';
import {GptAdDirective} from './gpt.ad.directive';
import {AdSkyscraperComponent} from './adSkyscraper/adSkyscraper.component';
import {AdSidebarComponent} from './adSidebar/adSidebar.component';
import {AdTickerComponent} from './ad-ticker/ad-ticker.component';

export const AD_COMPS = [
  Ad1x1Component,
  AdArticlebottomComponent,
  AdFullbannerComponent,
  AdInContentComponent,
  AdMediumRectangleComponent,
  AdMobileBannerComponent,
  AdResponsiveComponent,
  AdSkyscraperComponent,
  AdSuperbannerComponent,
  AdSidebarComponent,
  OutbrainComponent,
  AdTickerComponent,
];

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    GptAdDirective,
    ...AD_COMPS,
  ],
  exports: [
    GptAdDirective,
    ...AD_COMPS
  ]
})
export class AdModule {
}
