import {Component} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {ICleverpushButton} from '@model/content/cleverpushButton/payload';
import {Observable} from 'rxjs';
import {DidomiService} from '@shared/service/didomi.service';
import {VendorTypes} from '@model/enum/vendorTypes';

@Component({
  selector: 'cleverpushbutton',
  templateUrl: './cleverpush-button.component.html'
})
export class CleverpushButtonComponent implements DynamicComponent<ICleverpushButton> {

  buttonText: string;
  confirmedText: string;
  type: string;
  isBox: boolean;
  showCleverPush$: Observable<boolean>;
  vendorType = VendorTypes.cleverPush;

  constructor(
    private didomiService: DidomiService,
  ) {
  }

  initModel(model: ICleverpushButton): void {
    this.showCleverPush$ = this.didomiService.checkVendor$(VendorTypes.cleverPush);
    this.buttonText = model.data.button_text;
    this.confirmedText = model.data.confirmed_text;
    this.type = model.data.type;
    this.isBox = this.type === 'box-simple';
  }
}
