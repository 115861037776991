import {NgModule} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {MovieSerieTeaserComponent} from './movieSerieTeaser/movie-serie-teaser.component';
import {LongListComponent} from './longList/longlist.component';
import {TopStoriesComponent} from '../channel/topStories/topStories.component';
import {PosterSliderComponent} from './posterSlider/posterSlider.component';
import {PinPollComponent} from './pinPoll/pinpoll.component';
import {MultiTeaserComponent} from './multiTeaser/multi-teaser.component';
import {HeroComponent} from '@component/common/teaser/hero/hero.component';
import {MediaComponent} from '@component/common/teaser/media/media.component';
import {CardComponent} from '@component/common/teaser/card/card.component';
import {MovieSerieComponent} from '@component/common/teaser/movieSerie/movie-serie.component';
import {OpinionComponent} from '@component/common/teaser/opinion/opinion.component';
import {ImageOnlyComponent} from '@component/common/teaser/image-only/image-only.component';
import {TextTeaserComponent} from '@component/common/teaser/text-teaser/text-teaser.component';


export const HOME_COMMON_COMPS = [
  LongListComponent,
  MovieSerieTeaserComponent,
  TopStoriesComponent,
  PosterSliderComponent,
  PinPollComponent,
  MultiTeaserComponent,
  HeroComponent,
  MediaComponent,
  CardComponent,
  OpinionComponent,
  MovieSerieComponent,
  ImageOnlyComponent,
  TextTeaserComponent,
];

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    ...HOME_COMMON_COMPS,
  ],
  exports: [
    ...HOME_COMMON_COMPS,
  ]
})
export class HomeCommonModule {
}
