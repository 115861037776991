<div [ngClass]="model.classNames" class="linkCard">
  <linkbar class="linkCard-linkBar" *ngIf="model.linkbar" [model]="model.linkbar"></linkbar>
  <a *ngIf="model.link; else content" [portalRouterLink]="model.link" class="linkCard-link">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
  <ng-template #content>
    <picimage class="linkCard-image" *ngIf="model.image" [model]="model.image"></picimage>
    <div *ngIf="model.title || model.content" class="linkCard-content">
      <p
        class="linkCard-tagLine"
        *ngIf="model.pretitle">{{model.pretitle}}</p>
      <h2 *ngIf="model.title" class="linkCard-title">
        {{model.title}}
      </h2>
      <div class="linkCard-text" *ngIf="model.content" [innerHTML]="model.content">
      </div>
    </div>
  </ng-template>
</div>
