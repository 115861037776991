<div *ngIf="showIframe$ | async; else noContent" class="iFrame">
  <linkbar *ngIf="linkBarModel" [model]="linkBarModel"></linkbar>
  <div [class.iFrame-main]="model.data.iframe_type !== 'APA Grafiken'" [style.paddingBottom.%]="iframeRatio">
    <iframe [src]="safeUrl" [width]="iframeWidth" [height]="iframeHeight"
            [scrolling]="model.data.scrolling"></iframe>
  </div>
</div>

<ng-template #noContent>
  <app-no-consent *ngIf="vendorType" [vendor]="vendorType"></app-no-consent>
</ng-template>
