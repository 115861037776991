/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/directive/portalRoute.directive";
import * as i2 from "@angular/router";
import * as i3 from "../../../../shared/service/portal.service";
import * as i4 from "../../../../shared/service/browser.ref";
import * as i5 from "./text-link.component";
var styles_TextLinkComponent = [];
var RenderType_TextLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextLinkComponent, data: {} });
export { RenderType_TextLinkComponent as RenderType_TextLinkComponent };
export function View_TextLinkComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "textLink"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["class", "button"]], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 540672, null, 0, i1.PortalRouteDirective, [i2.Router, i0.ElementRef, i3.PortalService, i4.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.link; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.model.title; _ck(_v, 3, 0, currVal_3); }); }
export function View_TextLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-text-link", [], null, null, null, View_TextLinkComponent_0, RenderType_TextLinkComponent)), i0.ɵdid(1, 49152, null, 0, i5.TextLinkComponent, [], null, null)], null, null); }
var TextLinkComponentNgFactory = i0.ɵccf("app-text-link", i5.TextLinkComponent, View_TextLinkComponent_Host_0, {}, {}, []);
export { TextLinkComponentNgFactory as TextLinkComponentNgFactory };
