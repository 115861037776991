<div class="twitterTimeline">
  <a
    *ngIf="showTwitter$ | async; else noContent"
    class="twitter-timeline"
    data-theme="light"
    [attr.data-width]="width"
    [attr.data-height]="height"
    [href]="href"></a>
</div>

<ng-template #noContent>
  <app-no-consent [vendor]="vendorType"></app-no-consent>
</ng-template>
