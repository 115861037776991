/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../common/ui/iconButton/iconButton.component.ngfactory";
import * as i4 from "../../common/ui/iconButton/iconButton.component";
import * as i5 from "./deactivated-ad-block.component";
import * as i6 from "../../../shared/service/overlay.service";
var styles_DeactivatedAdBlockComponent = [];
var RenderType_DeactivatedAdBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DeactivatedAdBlockComponent, data: {} });
export { RenderType_DeactivatedAdBlockComponent as RenderType_DeactivatedAdBlockComponent };
export function View_DeactivatedAdBlockComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 24, "section", [["class", "deactivatedAdBlock"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Liebe Leserin, lieber Leser,"])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["damit wir Ihnen das volle Community-Angebot des "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["KURIER"])), (_l()(), i0.ɵted(-1, null, [" Medienhauses zur Verf\u00FCgung stellen k\u00F6nnen, bitten wir Sie, den Ad-Blocker zu deaktivieren."])), (_l()(), i0.ɵeld(8, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Weiterf\u00FChrende Informationen zu diesem Thema finden Sie "])), (_l()(), i0.ɵeld(10, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["hier"])), (_l()(), i0.ɵted(-1, null, ["."])), (_l()(), i0.ɵeld(15, 0, null, null, 7, "footer", [], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Vielen Dank,"])), (_l()(), i0.ɵeld(18, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Ihr "])), (_l()(), i0.ɵeld(20, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["KURIER"])), (_l()(), i0.ɵted(-1, null, [" Digital Team"])), (_l()(), i0.ɵeld(23, 0, null, null, 1, "iconbutton", [["class", "login-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IconButtonComponent_0, i3.RenderType_IconButtonComponent)), i0.ɵdid(24, 114688, null, 0, i4.IconButtonComponent, [], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_2 = "/info/information-zur-kurier-community/400542821"; _ck(_v, 11, 0, currVal_2); var currVal_3 = "times"; _ck(_v, 24, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 11).target; var currVal_1 = i0.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_0, currVal_1); }); }
export function View_DeactivatedAdBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-deactivated-ad-block", [], null, null, null, View_DeactivatedAdBlockComponent_0, RenderType_DeactivatedAdBlockComponent)), i0.ɵdid(1, 114688, null, 0, i5.DeactivatedAdBlockComponent, [i6.OverlayService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeactivatedAdBlockComponentNgFactory = i0.ɵccf("app-deactivated-ad-block", i5.DeactivatedAdBlockComponent, View_DeactivatedAdBlockComponent_Host_0, {}, {}, []);
export { DeactivatedAdBlockComponentNgFactory as DeactivatedAdBlockComponentNgFactory };
