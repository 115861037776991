<div class="tabbedList">
  <tabbar [model]="tabModels" (tabChanged)="switchTab($event)"></tabbar>
  <ng-container *ngIf="teasers$ | async">
    <ng-container
      teaser
      *ngFor="let model of mediaModel"
      [model]="model"></ng-container>
    <!--TODO show more button-->
    <div *ngIf="hasMoreEntries" class="tabbedList-button-container">
      <a class="button" (click)="fetchMoreEntries()" oewaClickTrigger [gtm]="gtmId">
        {{model.cta}}
      </a>
    </div>
  </ng-container>
</div>
