import {Results} from '../search/results';

export class PaginatorModel {

  static fromResult(results: Results): PaginatorModel {
    const p = new PaginatorModel(results.page, results.numberOfPages, results.numberOfPages !== 0);
    if (!results.hasArticles) {
      p.numberOfPages = 0;
    }
    return p;
  }

  constructor(public currentPage: number,
              public numberOfPages = 0,
              public showPagination = true) {
  }
}
