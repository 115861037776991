import { OnDestroy } from '@angular/core';
import { RouteService } from './route.service';
import { Meta } from '@angular/platform-browser';
import { LinkService } from './link.service';
import { Subject } from 'rxjs';
import { filter, skip, takeUntil } from 'rxjs/operators';
import { StateService } from './state.service';
import * as i0 from "@angular/core";
import * as i1 from "./route.service";
import * as i2 from "./state.service";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./link.service";
var AppHeadService = /** @class */ (function () {
    function AppHeadService(routeService, stateService, meta, linkService) {
        this.routeService = routeService;
        this.stateService = stateService;
        this.meta = meta;
        this.linkService = linkService;
        this.destroy$ = new Subject();
    }
    AppHeadService.prototype.init = function () {
        var _this = this;
        this.routeService.routeInfo
            .pipe(filter(Boolean), skip(1), takeUntil(this.destroy$)).subscribe(function (route) {
            _this.cleanHead(route);
        });
        // if not firstApp, skip first (as it was already set in firstApp)
        var skipSetHead = this.stateService.isFirstApp ? 0 : 1;
        this.routeService.routeInfo
            .pipe(filter(Boolean), skip(skipSetHead), takeUntil(this.destroy$)).subscribe(function (route) {
            _this.setHead(route);
        });
    };
    AppHeadService.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
    };
    AppHeadService.prototype.setHead = function (route) {
        if (!route.head) {
            return;
        }
        if (route.head.links) {
            this.linkService.addTags(route.head.links);
        }
        if (route.head.metas && route.head.metas.length > 0) {
            this.meta.addTags(route.head.metas);
        }
    };
    AppHeadService.prototype.cleanHead = function (route) {
        var _this = this;
        var uniqEs6 = function (arrArg) { return arrArg.filter(function (elem, pos, arr) { return arr.indexOf(elem) === pos; }); };
        var fixedMetaToRemove = {
            name: [
                'author',
                'description',
                'twitter:creator',
                'twitter:card',
                'twitter:site',
            ],
            property: [
                'og:url',
                'og:title',
                'og:description',
                'og:image',
                'og:image:alt',
                'og:image:width',
                'og:image:height',
                'og:type',
            ],
        };
        var routeHeadMetas = route.head && route.head.metas ? route.head.metas : [];
        Object.getOwnPropertyNames(fixedMetaToRemove).forEach(function (key) {
            var fixedMetas = fixedMetaToRemove[key];
            var newMetas = routeHeadMetas.filter(function (tag) { return tag.hasOwnProperty(key); }).map(function (tag) { return tag[key]; });
            var allMetas = [].concat(fixedMetas, newMetas);
            var allMetasUnique = uniqEs6(allMetas);
            allMetasUnique.forEach(function (value) {
                var attrSelector = key + "=\"" + value + "\"";
                return _this.meta.removeTag(attrSelector);
            });
        });
    };
    AppHeadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppHeadService_Factory() { return new AppHeadService(i0.ɵɵinject(i1.RouteService), i0.ɵɵinject(i2.StateService), i0.ɵɵinject(i3.Meta), i0.ɵɵinject(i4.LinkService)); }, token: AppHeadService, providedIn: "root" });
    return AppHeadService;
}());
export { AppHeadService };
