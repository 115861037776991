import {Component} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {IReddit} from '@model/content/reddit/payload';
import {LoadExternalScriptService} from '@shared/service/loadExternalScript.service';
import {ScriptId} from '@model/enum/scriptId';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-reddit',
  templateUrl: './reddit.component.html',
})
export class RedditComponent implements DynamicComponent<IReddit> {

  url$: Observable<string>;

  constructor(
    private loadExternalScriptService: LoadExternalScriptService,
  ) {
  }

  initModel(model: IReddit): void {
    this.url$ = this.loadExternalScriptService.loadScript(ScriptId.reddit)
      .pipe(
        map(() => model.data.uri)
      );
  }

}
