import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs';
import {ScriptId} from '../../model/enum/scriptId';
import {BrowserRef} from './browser.ref';
import {SCRIPT_ID_MAP, SCRIPT_MAP} from '../../model/const/script';
import {filter, take} from 'rxjs/operators';

// TODO possiblity of using <T> ?
export interface IScriptValue {
  loadedBefore: boolean;
  scriptVar: any;
}

@Injectable({
  providedIn: 'root',
})
export class LoadExternalScriptService {

  private readonly TYPE: string = 'text/javascript';
  private readonly LANG: string = 'Javascript';

  private observers: Map<string, BehaviorSubject<IScriptValue>> = new Map();
  private haveLoaded: Map<string, boolean> = new Map();

  constructor(@Inject(PLATFORM_ID) protected platformId: string, private browserRef: BrowserRef) {
  }

  loadScript(sdk: ScriptId): Observable<IScriptValue> {
    if (isPlatformBrowser(this.platformId)) {
      if (sdk === ScriptId.pinterest) {
        return this.getPinterest();
      } else {
        return this.getScript(sdk);
      }
    }
    return of(null).pipe(filter(Boolean));
  }

  protected getScript(id: ScriptId): Observable<IScriptValue> {
    const document = this.browserRef.document;
    const s = 'script';
    if (this.observers[id]) {

      if (this.haveLoaded[id]) {
        this.nextScriptLoaded(id, true);
      }
      return this.observers[id].asObservable().pipe(
        filter(Boolean),
        take(1),
      );
    }

    this.observers[id] = new BehaviorSubject(null);

    const fjs = document.getElementsByTagName(s)[0];
    const js = document.createElement(s);
    js.id = id;
    js.src = SCRIPT_MAP[id];
    js.type = this.TYPE;
    js.language = this.LANG;
    js.defer = 'defer';
    js.onload = () => {
      this.haveLoaded[id] = true;
      this.nextScriptLoaded(id, false);
    };
    js.error = (message) => {
      this.observers[id].error(message);
    };

    fjs.parentNode.insertBefore(js, fjs);

    // TODO macht das irgendeinen Unterschied?
    if (id === ScriptId.twitter) {
      const t = this.browserRef.window[SCRIPT_ID_MAP[id]] || {};
      t._e = [];
      t.ready = (f) => {
        t._e.push(f);
      };
      this.browserRef.window[SCRIPT_ID_MAP[id]] = t;
    }

    return this.observers[id].asObservable().pipe(filter(Boolean), take(1));
  }

  nextScriptLoaded(id: ScriptId, hasLoadedBefore: boolean): void {
    if (!hasLoadedBefore && id === ScriptId.facebook) {
      // tslint:disable-next-line:no-string-literal
      this.browserRef.window['fbAsyncInit'] = () => {
        this.observers[id].next({loadedBefore: false, scriptVar: this.browserRef.window[SCRIPT_ID_MAP[id]]});
      };
      return;
    }
    const nextScript = {
      loadedBefore: hasLoadedBefore,
      scriptVar: this.browserRef.window[SCRIPT_ID_MAP[id]] || this.browserRef.window
    };
    this.observers[id].next(nextScript);
  }

  getPinterest(): Observable<any> {
    const window = this.browserRef.window;
    const doc = this.browserRef.document;
    const p = ScriptId.pinterest.toString();

    if (this.observers[p]) {
      return this.observers[p].asObservable().filter(Boolean).take(1);
    }
    this.observers[p] = new ReplaySubject();
    // tslint:disable-next-line:one-variable-per-declaration
    let id, scriptsElms, script;
    //noinspection CommaExpressionJS
    id = 'PIN_' + Math.floor((new Date()).getTime() / 864e5);
    if (window[id]) {
      window[id] += 1;
    } else {
      window[id] = 1;
      scriptsElms = doc.getElementsByTagName('SCRIPT')[0];
      script = doc.createElement('SCRIPT');
      script.type = this.TYPE;
      script.language = this.LANG;
      //noinspection CommaExpressionJS
      script.defer = 'defer';
      script.src = SCRIPT_MAP[p] + '?' + Math.random();
      script.onload = () => {
        this.observers[id].next(window);
      };
      scriptsElms.parentNode.insertBefore(script, scriptsElms);
    }

    return this.observers[p].asObservable().filter(Boolean).take(1);
  }

}
