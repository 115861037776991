import { Component, OnInit } from '@angular/core';
import {OverlayService} from '../../../shared/service/overlay.service';

@Component({
  selector: 'app-deactivated-ad-block',
  templateUrl: './deactivated-ad-block.component.html',
})
export class DeactivatedAdBlockComponent implements OnInit {

  constructor(private overlayService: OverlayService) { }

  ngOnInit() {
  }

  close() {
    this.overlayService.clearComponent();
  }

}
