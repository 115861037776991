/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../no-consent/no-consent.component.ngfactory";
import * as i3 from "../no-consent/no-consent.component";
import * as i4 from "../../../../shared/service/didomi.service";
import * as i5 from "./facebook.component";
import * as i6 from "../../../../shared/service/loadExternalScript.service";
var styles_FacebookComponent = [];
var RenderType_FacebookComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FacebookComponent, data: {} });
export { RenderType_FacebookComponent as RenderType_FacebookComponent };
function View_FacebookComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["data-adapt-container-width", "true"]], [[1, "data-width", 0], [1, "data-href", 0], [1, "data-tabs", 0], [1, "data-height", 0], [1, "data-small-header", 0], [1, "data-hide-cover", 0], [1, "data-show-facepile", 0], [1, "data-show-textParagraph", 0]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "blockquote", [["class", "fb-xfbml-parse-ignore"]], [[1, "cite", 4]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.divClass; _ck(_v, 2, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = _co.fbLink; var currVal_2 = _co.tabs; var currVal_3 = _co.height; var currVal_4 = _co.header; var currVal_5 = _co.cover; var currVal_6 = _co.facepile; var currVal_7 = _co.showText; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = _co.fbLink; _ck(_v, 3, 0, currVal_9); }); }
function View_FacebookComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i2.View_NoConsentComponent_0, i2.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i3.NoConsentComponent, [i4.DidomiService], { vendor: [0, "vendor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FacebookComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_FacebookComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["noContent", 2]], null, 0, null, View_FacebookComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.loadFacebook$)); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_FacebookComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "facebook", [], null, null, null, View_FacebookComponent_0, RenderType_FacebookComponent)), i0.ɵdid(1, 49152, null, 0, i5.FacebookComponent, [i6.LoadExternalScriptService, i4.DidomiService], null, null)], null, null); }
var FacebookComponentNgFactory = i0.ɵccf("facebook", i5.FacebookComponent, View_FacebookComponent_Host_0, {}, {}, []);
export { FacebookComponentNgFactory as FacebookComponentNgFactory };
