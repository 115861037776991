var TagModel = /** @class */ (function () {
    function TagModel(title, link, className, image) {
        this.title = title;
        this.link = link;
        this.className = className;
        this.image = image;
    }
    return TagModel;
}());
export default TagModel;
