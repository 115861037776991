import * as tslib_1 from "tslib";
import { ImageModel } from './image';
import { BoxModel } from './box';
import { TeaserModel } from '../teaser/teaser';
import { ImageType } from '../enum/image';
import { LinkBarModel } from './linkBar';
import { PortalRoute } from '@model/teaser/portalRoute';
var LinkCardModel = /** @class */ (function (_super) {
    tslib_1.__extends(LinkCardModel, _super);
    function LinkCardModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LinkCardModel.fromTeasers = function (teasers, teaserView, imageSize, box) {
        if (imageSize === void 0) { imageSize = ImageType.LS_16_9; }
        return teasers.map(function (t) { return LinkCardModel.fromTeaser(t, teaserView, imageSize, box); });
    };
    LinkCardModel.fromTeaser = function (teaser, teaserView, imageSize, box, comp) {
        if (imageSize === void 0) { imageSize = ImageType.LS_16_9; }
        var lc = new LinkCardModel();
        lc.title = teaserView.title ? teaser.title : null;
        lc.content = teaserView.teaser_text ? teaser.teaser_text : null;
        lc.link = PortalRoute.fromTeaser(teaser);
        if (teaserView.img && teaser.teaser_img) {
            lc.image = new ImageModel(teaser.teaser_img, imageSize, null, false, false, 'link-image');
        }
        lc.box = box ? new BoxModel(box) : new BoxModel();
        lc.linkbar = LinkBarModel.titleFromTeaser(comp, teaser, lc.box);
        return lc;
    };
    LinkCardModel.fromTeaserImages = function (images, imageSize) {
        return images.map(function (image) {
            var lc = new LinkCardModel();
            lc.title = image.title;
            lc.content = image.description;
            lc.image = new ImageModel(image, imageSize, lc.link, true, false, 'link-image');
            lc.image.hasLightbox = true;
            lc.image.description = '';
            return lc;
        });
    };
    return LinkCardModel;
}(TeaserModel));
export { LinkCardModel };
