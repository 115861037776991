import {
  ComponentFactoryResolver,
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {IDynamicComponent} from '../../model/payload';
import {PositionType} from '../../model/enum/position';
import {DynamicComponentsService} from '../service/dynamicComponents.service';


@Directive({
  selector: '[dynamicComponent]'
})
export class DynamicComponentDirective implements OnChanges, OnDestroy {
  @Input() model: IDynamicComponent;
  @Input() position: PositionType;
  componentRef;

  constructor(private vcRef: ViewContainerRef,
              private resolver: ComponentFactoryResolver,
              private dynamic: DynamicComponentsService,
              private renderer: Renderer2,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.model) {
      return;
    }
    const model = Object.assign({}, this.model);
    model.position = PositionType[this.position];

    const componentRef = this.dynamic.create(this.resolver, this.vcRef, model);
    if (!componentRef) {
      return;
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    this.componentRef = componentRef;
    if (model.displayNone) {
      this.renderer.setStyle(this.componentRef.location.nativeElement, 'display', 'none');
    }
  }

  public ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
