export enum ChannelId {
  none = '',
  // kurier
  lifestyle = 'lifestyle',
  sport = 'sport',
  // fuzo
  b2b = 'b2b',
  netzpolitik = 'netzpolitik',
  produkte = 'produkte',
  'digital-life' = 'digital-life',
  science = 'science',
  meinung = 'meinung',
  games = 'games',
  apps = 'apps',
  'start-ups' = 'start-ups',
  myfuzo = 'myfuzo',
  community = 'community'
}
