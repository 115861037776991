/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../gtmTrigger.directive";
import * as i2 from "../iconButton/iconButton.component.ngfactory";
import * as i3 from "../iconButton/iconButton.component";
import * as i4 from "../../../../shared/directive/portalRoute.directive";
import * as i5 from "@angular/router";
import * as i6 from "../../../../shared/service/portal.service";
import * as i7 from "../../../../shared/service/browser.ref";
import * as i8 from "../../../../shared/directive/oewaClickTrigger.directive";
import * as i9 from "../../../../shared/service/oewa.service";
import * as i10 from "@angular/common";
import * as i11 from "./accordeon.component";
var styles_AccordeonComponent = [];
var RenderType_AccordeonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AccordeonComponent, data: { "animation": [{ type: 7, name: "accordeonState", definitions: [{ type: 0, name: "inactive", styles: { type: 6, styles: { height: "0px" }, offset: null }, options: undefined }, { type: 0, name: "active", styles: { type: 6, styles: { height: "*" }, offset: null }, options: undefined }, { type: 1, expr: "inactive => active", animation: { type: 4, styles: null, timings: "300ms ease-in" }, options: null }, { type: 1, expr: "active => inactive", animation: { type: 4, styles: null, timings: "300ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_AccordeonComponent as RenderType_AccordeonComponent };
function View_AccordeonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "accordeon-toggle-wrapper isSingle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "a", [["class", "accordeon-toggle"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleAccordeon() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 81920, null, 0, i1.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "accordeon-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "iconbutton", [], null, null, null, i2.View_IconButtonComponent_0, i2.RenderType_IconButtonComponent)), i0.ɵdid(7, 114688, null, 0, i3.IconButtonComponent, [], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.gtmId; _ck(_v, 2, 0, currVal_1); var currVal_3 = "angle-right"; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isOpen; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.title; _ck(_v, 5, 0, currVal_2); }); }
function View_AccordeonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "accordeon-toggle-wrapper isMulti"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "accordeon-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "a", [], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 540672, null, 0, i4.PortalRouteDirective, [i5.Router, i0.ElementRef, i6.PortalService, i7.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), i0.ɵdid(4, 81920, null, 0, i1.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null), (_l()(), i0.ɵted(5, null, [" ", " "])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "a", [["class", "accordeon-toggle"], ["oewaClickTrigger", ""]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.toggleAccordeon() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i8.OewaClickTriggerDirective, [i9.OewaService], { oewaPageId: [0, "oewaPageId"] }, null), i0.ɵdid(8, 81920, null, 0, i1.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "iconbutton", [], null, null, null, i2.View_IconButtonComponent_0, i2.RenderType_IconButtonComponent)), i0.ɵdid(10, 114688, null, 0, i3.IconButtonComponent, [], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.link; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.gtmId; _ck(_v, 4, 0, currVal_3); var currVal_6 = ""; _ck(_v, 7, 0, currVal_6); var currVal_7 = _co.gtmId; _ck(_v, 8, 0, currVal_7); var currVal_8 = "angle-right"; _ck(_v, 10, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _co.title; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.isOpen; _ck(_v, 6, 0, currVal_5); }); }
export function View_AccordeonComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AccordeonComponent_1)), i0.ɵdid(1, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AccordeonComponent_2)), i0.ɵdid(3, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [], [[24, "@accordeonState", 0]], null, null, null, null)), i0.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(6, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(7, 2), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "accordeon-content-inner"]], null, null, null, null, null)), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.link; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.link; _ck(_v, 3, 0, currVal_1); var currVal_3 = _ck(_v, 7, 0, "accordeon-content", _co.contentClass); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.isOpen ? _co.stateActive : _co.stateInactive); _ck(_v, 4, 0, currVal_2); }); }
export function View_AccordeonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "accordeon", [], [[1, "class", 0]], null, null, View_AccordeonComponent_0, RenderType_AccordeonComponent)), i0.ɵdid(1, 49152, null, 0, i11.AccordeonComponent, [i0.ChangeDetectorRef, i9.OewaService], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var AccordeonComponentNgFactory = i0.ɵccf("accordeon", i11.AccordeonComponent, View_AccordeonComponent_Host_0, { title: "title", contentClass: "contentClass", link: "link", isOpen: "isOpen", gtmId: "gtmId" }, { selectedChanged: "selectedChanged" }, ["*"]);
export { AccordeonComponentNgFactory as AccordeonComponentNgFactory };
