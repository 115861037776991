import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {PortalRoute} from '../../../../model/teaser/portalRoute';
import {OewaService} from '../../../../shared/service/oewa.service';
import {GTMTriggerId} from '../../../../model/enum/gtm';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'accordeon',
  templateUrl: './accordeon.component.html',
  animations: [
    trigger('accordeonState', [
      state('inactive', style({
        height: '0px'
      })),
      state('active', style({
        height: '*'
      })),
      transition('inactive => active', animate('300ms ease-in')),
      transition('active => inactive', animate('300ms ease-out'))
    ])
  ],
})
export class AccordeonComponent {

  @HostBinding('attr.class') class = 'accordeon';

  @Input() title: string;
  @Input() contentClass = '';
  @Input() link: PortalRoute;
  @Input() isOpen = false;
  @Input() gtmId: string;
  @Output() selectedChanged = new EventEmitter<AccordeonComponent>();

  readonly stateInactive = 'inactive';
  readonly stateActive = 'active';


  constructor(private cd: ChangeDetectorRef,
              protected oewaService: OewaService) {
    this.gtmId = GTMTriggerId.accordeon;
  }

  toggleAccordeon() {
    this.isOpen = !this.isOpen;
    this.selectedChanged.emit(this);
    this.oewaService.pushCp('accordeon');
    return false;
  }

  open() {
    this.isOpen = true;
    this.cd.markForCheck();
  }

  close() {
    this.isOpen = false;
    this.cd.markForCheck();
  }
}
