<div class="opinionCard">
  <div class="opinionCard-card">
    <picimage
      *ngIf="model.image; else elseBlock"
      [model]="model.image">
    </picimage>
    <ng-template #elseBlock>
    <span class="comments-icon">
      <svgicon [icon]="'comments'"></svgicon>
    </span>
    </ng-template>

    <div class="opinionCard-titleAndQuote">
      <h1 class="opinionCard-title"><a [portalRouterLink]="model.link">{{model.title}}</a></h1>
    </div>

    <div class="opinionCard-tagAndAuthor">
      <p class="teaser-tagLine">
        <tag
          [model]="tag"
          *ngFor="let tag of model.tags"></tag>
        <a
          *ngFor="let tag of model.tagLine"
          class="teaser-tagLineLink"
          [portalRouterLink]="tag.portalRoute"
          [gtm]="model.pretitleGtmId" [innerHTML]="tag.title"></a>
      </p>
      <p
        *ngIf="model.author"
        class="opinionCard-author">von <a [portalRouterLink]="model.author.portalRoute" class="opinionCard-authorLink">{{model.author.name}}</a></p>
    </div>

    <a [portalRouterLink]="model.link" class="opinionCard-link" title="Artikel lesen"></a>
  </div>
</div>




