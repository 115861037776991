import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ElementRef,
  Input,
  NgZone, OnDestroy, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {DynamicComponent} from '../../common/dynamicComponent';
import {LoadExternalScriptService} from '@shared/service/loadExternalScript.service';
import {filter, first, map, switchMap, take, tap} from 'rxjs/operators';
import {ScriptId} from '@model/enum/scriptId';
import {IDynamicComponent} from '@model/payload';
import {combineLatest, fromEvent, of, Subscription} from 'rxjs';
import {VendorTypes} from '@model/enum/vendorTypes';
import {DidomiService} from '@shared/service/didomi.service';
import {RouteService} from '@shared/service/route.service';
import {PianoService} from '@shared/service/piano/piano.service';

export interface IOutBrain extends IDynamicComponent {
  src: string;
  template: string;
  widget: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'outbrain',
  templateUrl: 'outbrain.component.html'
})
export class OutbrainComponent implements DynamicComponent<IOutBrain>, AfterViewInit, OnDestroy {
  vendorType = VendorTypes.outbrain;
  outbrainConsent$ = this.didomiService.checkVendor$(this.vendorType);
  showConsentOverlay: boolean;
  @ViewChild('obHolder', {static: false}) obHolder: ElementRef;

  @Input() set model(model: IOutBrain) {
    this._model = model;
  }

  get model() {
    return this._model;
  }

  modifiedModel: IOutBrain;

  private _model: IOutBrain;
  private subscription: Subscription;
  show = false;

  constructor(
    private zone: NgZone,
    private loadScript: LoadExternalScriptService,
    private didomiService: DidomiService,
    private routeService: RouteService,
    private piano: PianoService,
    private cd: ChangeDetectorRef,
  ) {
  }

  initModel(model: IOutBrain): void {
    this.model = model;
  }

  ngAfterViewInit() {
    this.subscription = combineLatest([
      this.routeService.routeInfo.pipe(map(route => route.dataLayer)),
      this.outbrainConsent$,
      this.piano.user$.pipe(filter(Boolean)),
      this.piano.checkAdFree(),
    ])
      .pipe(
        tap(([dataLayer, outbrainConsent, user, adFree]) => {
          this.clear();
          if (dataLayer.AdsEnabled && outbrainConsent && !adFree) {
            this.loadScript.loadScript(ScriptId.outbrain).pipe(take(1)).subscribe(obr => {
              this.modifiedModel = {...this.model};
              this.piano.checkPaywallAccess().pipe(take(1)).subscribe(access => {
                if (!access || user.valid === false) {
                  this.modifiedModel.widget = 'AR_2';
                }
                if (dataLayer.Seitentyp !== 'Artikelseite') {
                  this.modifiedModel.widget = this.model.widget;
                }
                this.show = true;
                this.cd.detectChanges();
                obr.scriptVar.extern.researchWidget();
              });
            });
          }
          this.showConsentOverlay = !outbrainConsent;
          this.cd.markForCheck();
        }),
      )
      .subscribe();
  }

  clear() {
    if (this.obHolder) {
      this.obHolder.nativeElement.innerHTML = '';
    }
    this.show = false;
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
