import {Component, ElementRef, OnInit, Renderer2, ViewChild, ViewContainerRef} from '@angular/core';
import {filter} from 'rxjs/operators';
import {OverlayService} from '../../../shared/service/overlay.service';
import {CssClassService} from '../../../shared/service/cssClass.service';
import {BrowserRef} from '../../../shared/service/browser.ref';
import {NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'overlay',
  templateUrl: './overlay.component.html',
})
export class OverlayComponent implements OnInit {

  @ViewChild('entryPoint', { read: ViewContainerRef, static: true }) entryPoint: ViewContainerRef;

  constructor(private router: Router,
              private overlayService: OverlayService,
              private cssService: CssClassService,
              private elmRef: ElementRef,
              private renderer: Renderer2,
              private browserRef: BrowserRef) {
  }

  ngOnInit() {

    this.overlayService.init(this.entryPoint);

    this.overlayService.overlayComp$.subscribe(comp => {
      this.cssService.setBodyClass('no-scroll', comp !== null);
      if (comp) {
        this.renderer.addClass(this.elmRef.nativeElement, 'overlay');
        this.addHeight();
      } else {
        this.renderer.removeClass(this.elmRef.nativeElement, 'overlay');
      }
    });

    this.overlayService.overlayOffset$.subscribe(offset => {
      this.addHeight();
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => {
        this.overlayService.clearComponent();
      });
  }

  private addHeight() {
    const element = this.elmRef.nativeElement;
    const top = element.getBoundingClientRect().top || 0;
    this.renderer.setStyle(this.elmRef.nativeElement, 'height', this.browserRef.window.innerHeight - top + 'px');
  }

}
