<ng-container *ngIf="route$ | async">
  <ng-container *ngIf="topNorthComponents != null">
    <section class="regionTopNorth">
      <ng-container dynamicComponent *ngFor="let childTopNorth of topNorthComponents"
                    [model]="childTopNorth" [position]="'north'">
      </ng-container>
    </section>
  </ng-container>
  <ng-container *ngIf="topWestComponents != null || topEastComponents != null">
    <section class="regionTopWestEast">
      <section class="regionTopWestEast-west">
        <ng-container dynamicComponent *ngFor="let childTopWest of topWestComponents"
                      [model]="childTopWest" [position]="'west'">
        </ng-container>
      </section>
      <section class="regionTopWestEast-east">
        <ng-container dynamicComponent *ngFor="let childTopEast of topEastComponents"
                      [model]="childTopEast" [position]="'east'">
        </ng-container>
      </section>
    </section>

  </ng-container>
  <ng-container *ngIf="topSouthComponents != null">
    <section class="regionTopSouth">
      <ng-container dynamicComponent *ngFor="let childTopSouth of topSouthComponents"
                    [model]="childTopSouth" [position]="'south'">
      </ng-container>
    </section>
  </ng-container>
  <section class="regionCenterLeft" [class.storyContainer]="story">
    <section class="regionCenterLeft-center">
      <ng-container dynamicComponent *ngFor="let childCenter of centerComponents" [model]="childCenter"
                    [position]="'center'"></ng-container>
    </section>
    <section class="regionCenterLeft-left">
      <ng-container dynamicComponent *ngFor="let childLeft of leftComponents" [model]="childLeft"
                    [position]="'left'"></ng-container>
    </section>
  </section>
  <newslettersignup *ngIf="isLaoe" [acId]="95"></newslettersignup>
</ng-container>

