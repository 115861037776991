<div class="searchResults">
  <ng-container *ngIf="(searchResult$ | async) as searchResult">
    <div class="searchResults-movies" *ngIf="movies">
      <linkbar [model]="movieTitle"></linkbar>
      <movieteaser *ngFor="let movie of movies" [model]="movie"></movieteaser>
      <a
        class="button"
        (click)="navigateToMovies()"
        oewaClickTrigger
        [gtm]="gtmId">
        <ng-container *ngIf="!termName">
          Alle Filme & Serien
        </ng-container>
        <ng-container *ngIf="termName">
          Alle Filme & Serien zu "{{ termName }}"
        </ng-container>
      </a>
    </div>
    <div class="searchResults-theater" *ngIf="theaters">
      <linkbar [model]="theaterTitle"></linkbar>
      <linkbar *ngFor="let t of theaters" [model]="t"></linkbar>
    </div>
    <paginator class="searchResults-paginator" *ngIf="paginatorModel" [model]="paginatorModel"
               (onPageChanged)="selectPage($event)">
      <ng-container teaser
                    *ngFor="let model of mediaModels"
                    [model]="model"></ng-container>
    </paginator>
  </ng-container>
</div>

