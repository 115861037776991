<section class="personMovieList">
  <header>
    <h1 *ngIf="title">{{title}}</h1>
    <p *ngIf="description">{{description}}</p>
  </header>
  <div *ngFor="let item of data">
    <linkbar *ngIf="item.movies && item.movies.length" [model]="item.linkBarMovies"></linkbar>
    <movieteaser *ngFor="let teaser of item.movies"
                 [model]="teaser"></movieteaser>
    <linkbar *ngIf="item.series && item.series.length" [model]="item.linkBarSeries"></linkbar>
    <movieteaser *ngFor="let teaser of item.series"
                 [model]="teaser"></movieteaser>
  </div>
</section>
