import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {ISlider} from '../../../../model/channel/slider/payload';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'singleslider',
  templateUrl: 'singleSlider.component.html'
})
export class SingleSliderComponent implements DynamicComponent<ISlider> {

  model: ISlider;

  initModel(model: ISlider): void {
    this.model = model;
  }
}
