import { parse } from 'url';
import { EMPTY, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PortalRoute } from '../../model/teaser/portalRoute';
import { PortalService } from './portal.service';
import { ApiService } from './api.service';
import { BrowserRef } from './browser.ref';
import { StateService } from '@shared/service/state.service';
import { Meta } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./browser.ref";
import * as i3 from "./portal.service";
import * as i4 from "./api.service";
import * as i5 from "./state.service";
import * as i6 from "@angular/platform-browser";
var RouteResolverService = /** @class */ (function () {
    function RouteResolverService(router, browserRef, portalService, api, stateService, meta) {
        this.router = router;
        this.browserRef = browserRef;
        this.portalService = portalService;
        this.api = api;
        this.stateService = stateService;
        this.meta = meta;
    }
    RouteResolverService.prototype.deleteAuthorsFromObject = function (obj) {
        var _this = this;
        Object.keys(obj).forEach(function (k) {
            var value = obj[k];
            if (['author', 'Autor', 'authors', 'agency'].includes(k)) {
                obj[k] = undefined;
            }
            if (value && typeof value === 'object') {
                _this.deleteAuthorsFromObject(value);
            }
        });
    };
    RouteResolverService.prototype.resolve = function (activatedRoute, state) {
        var _this = this;
        var requestUrl = parse(state.url);
        var uri = this.portalService.uri(requestUrl.pathname);
        if (uri.includes('kurierat/edition/')) {
            uri = uri.replace('/edition', '');
        }
        return this.api.route(uri).pipe(first(), mergeMap(function (route) {
            if (route.type === 'redirect') {
                var target = PortalRoute.fromUri(route.location);
                if (_this.portalService.samePortal(target)) {
                    _this.router.navigate([target.path]);
                }
                else {
                    var canonical = _this.portalService.canonical(target);
                    _this.browserRef.window.location.assign(canonical);
                    return EMPTY;
                }
                return EMPTY;
            }
            if (_this.stateService.isBrowser) {
                if (navigator.userAgent.toLowerCase().indexOf('cxensebot') >= 0 &&
                    _this.portalService.currentPortal() === 'kurierat') {
                    var authorMetas = route.head.metas.filter(function (tag) { return tag.property === 'article:author' || tag.name === 'author'; });
                    authorMetas.forEach(function (meta) {
                        if (meta.name) {
                            _this.meta.removeTag("name=\"" + meta.name + "\"");
                        }
                        if (meta.property) {
                            _this.meta.removeTag("property=\"" + meta.property + "\"");
                        }
                    });
                    _this.deleteAuthorsFromObject(route);
                }
            }
            return of(route);
        }));
    };
    RouteResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RouteResolverService_Factory() { return new RouteResolverService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.BrowserRef), i0.ɵɵinject(i3.PortalService), i0.ɵɵinject(i4.ApiService), i0.ɵɵinject(i5.StateService), i0.ɵɵinject(i6.Meta)); }, token: RouteResolverService, providedIn: "root" });
    return RouteResolverService;
}());
export { RouteResolverService };
