import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MovieSerieModel} from '@model/movieSerie/movieSerie';
import {GTMTriggerId} from '@model/enum/gtm';

@Component({
  selector: 'movieteaser',
  templateUrl: './movie-serie-teaser.component.html',
})
export class MovieSerieTeaserComponent implements OnChanges {
  @Input() model: MovieSerieModel;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.model.badges && this.model.badges.length > 2) {
      const bubble = {link: this.model.link, name: '…', gtmId: GTMTriggerId.badge};
      this.model.badges = this.model.badges.slice(0, 2);
      this.model.badges.push(bubble);
    }
  }
}
