import {ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {AdModel} from '@model/ad/ad-model';
import {AD_DIMENSIONS} from '@model/const/ad';
import {AdDimensions, AdSize} from '@model/enum/ad';
import {isPlatformBrowser} from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'adSkyscraper',
  templateUrl: 'adSkyscraper.component.html'
})
export class AdSkyscraperComponent implements OnInit {
  model: AdModel;

  constructor(
    @Inject(PLATFORM_ID) protected platformId: string,
  ) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const adDimensions = [];
      const sizes = {
        120: AdDimensions.dim_120x600,
        160: AdDimensions.dim_160x600,
        300: AdDimensions.dim_300x600,
        400: AdDimensions.dim_400x800,
        500: AdDimensions.dim_500x1000,
      };
      Object.keys(sizes).forEach(key => {
        if (window.innerWidth - (+key) >= 964) {
          adDimensions.push(AD_DIMENSIONS[sizes[key]]);
        }
      });
      this.model = new AdModel(
        'adSkyscraper',
        AdSize.ad_skyscraper,
        adDimensions,
        true,
        () => window.innerWidth >= 1084,
        false,
      );
    }
  }
}
