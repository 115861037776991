import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {AdDimensions, AdSize} from '../../../model/enum/ad';
import {AdModel} from '../../../model/ad/ad-model';
import {AD_DIMENSIONS} from '../../../model/const/ad';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {IDynamicComponent} from '@model/payload';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'adarticlebottom',
  templateUrl: 'adArticlebottom.component.html'
})
export class AdArticlebottomComponent implements DynamicComponent<IDynamicComponent> {

  model: AdModel;

  constructor() {
    this.model = new AdModel(
      'adArticleBottom',
      AdSize.ad_articlebottom,
      [AD_DIMENSIONS[AdDimensions.dim_620x100]],
      false,
      () => true,
      false,
    );
  }

  initModel(model: IDynamicComponent): void {
  }

}
