import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ShareModel} from '@model/component/share';
import {SHARE_CLASS_MAP} from '@model/const/share';
import {GoogleTagManagerService} from '@shared/service/gtm.service';
import {BrowserRef} from '@shared/service/browser.ref';
import {ActivatedRoute} from '@angular/router';
import {MetaDefinition} from '@angular/platform-browser';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'socialbar',
  templateUrl: './socialBar.component.html',
})
export class SocialBarComponent implements OnInit {

  @Input() model: ShareModel;
  shareMap = SHARE_CLASS_MAP;

  private readonly metas: MetaDefinition[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private gtm: GoogleTagManagerService,
    private browserRef: BrowserRef,
  ) {
    this.metas = activatedRoute.snapshot.data.route.head.metas || [];
  }

  ngOnInit() {
    this.model.addMetaInfo(this.metas);
  }

  openPopup(type: string) {

    const data = {
      url: this.model.url,
      sharing_type: type,
    };
    this.gtm.pushEvent('DA - SharingTeaser', data);
    this.gtm.pushDataLayerOnBrowser({
      event: 'share',
      sharePlatform: type,
    });

    if (type === 'mail') {
      this.browserRef.window.location.href = this.model.mailto;
      return false;
    }

    this.browserRef.window.open(this.model[type], 'popup',
      'left=20,top=20,width=500,height=500,toolbar=1,resizable=0');
    return false;
  }

}
