<ng-container *ngIf="movies$ | async as movies">
  <section class="movieList">
    <header class="movieList-header">
      <h2 class="movieList-title">{{model.title}}</h2>
      <div class="movieList-filter">
        <forminput [(value)]="term" [label]="'Suche'" [type]="'text'" (inputChanged)="onTermChanged($event)"
                   [placeholder]="'Suchbegriff'">
        </forminput>
        <formselect *ngIf="!model.genre" [label]="'Genre'" [values]="genres" [(valueSelected)]="genreSelected"
                    (valueSelectedChange)="onGenreChange()"></formselect>
      </div>
      <div class="movieList-viewSwitcher">
        <button
          [class.active]="listViewList.getValue()"
          (click)="toggleListView(true)">
          <iconbutton icon="th-list"></iconbutton>
          <span>Listen Ansicht</span>
        </button>
        <button
          [class.active]="!listViewList.getValue()"
          (click)="toggleListView(false)">
          <iconbutton icon="th-tiles"></iconbutton>
          <span>Kachel Ansicht</span>
        </button>
      </div>
    </header>
    <paginator [model]="paginatorModel"
               (onPageChanged)="onPageChanged()">
      <div class="movieList-list" [ngClass]="listViewList.getValue() ? 'list' : 'columns'">
        <ng-container *ngIf="listViewList.getValue()">
          <movieteaser
            *ngFor="let m of movies"
            [model]="m"></movieteaser>
        </ng-container>
        <ng-container *ngIf="!listViewList.getValue()">
          <a
            *ngFor="let m of movies"
            [ngClass]="m.classes"
            class="movieList-list-poster"
            [portalRouterLink]="m.link">
            <picimage *ngIf="m.poster" [model]="m.poster"></picimage>
            <div *ngIf="!m.poster" class="no-img"></div>
          </a>
        </ng-container>
      </div>
    </paginator>
  </section>
</ng-container>


