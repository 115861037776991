export function copyToClipboard(str: string): void {
  let textArea;

  function isOS() {
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  function createTextArea() {
    textArea = document.createElement('textArea');
    textArea.value = str;
    document.body.appendChild(textArea);
  }

  function selectText() {
    let range;
    let selection;

    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function ctcb() {
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }

  createTextArea();
  selectText();
  ctcb();
}
