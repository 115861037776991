import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {VideoModel} from '../../../../model/component/video';
import {IApaVideo} from '../../../../model/content/apaVideo/payload';
import {VendorTypes} from '@model/enum/vendorTypes';
import {DidomiService} from '@shared/service/didomi.service';
import {Observable, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'apavideo',
  templateUrl: 'apavideo.component.html'
})
export class ApaVideoComponent implements OnInit, OnDestroy, DynamicComponent<IApaVideo> {

  @Input() videoModel: VideoModel;
  title = '';
  vendorType = VendorTypes.apaVideo;
  showApaVideo$: Observable<boolean>;
  subscription: Subscription;

  constructor(
    private didomiService: DidomiService,
  ) {
  }

  ngOnInit() {
    this.showApaVideo$ = this.didomiService.checkVendor$(this.vendorType);
  }

  initModel(model: IApaVideo): void {
    this.subscription = this.didomiService.tcString$.pipe(
      tap(tcString => {
        let url = 'https://uvp-kurier.sf.apa.at/embed/' + model.data.guid;
        if (url.indexOf('?') >= 0) {
          url = url + '&';
        } else {
          url = url + '?';
        }
        const newUrl = url + 'ad_gdpr=1&ad_gdpr_consent=' + tcString;
        this.videoModel = new VideoModel(newUrl, model.data.autoplay);
        model.data.title ? this.title = model.data.title : this.title = '';
      }),
    ).subscribe();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
