import {AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewContainerRef} from '@angular/core';

@Component({
  selector: 'app-overlay-template',
  template: '',
})
export class OverlayTemplateComponent implements AfterViewInit {

  templateRef: TemplateRef<any>;

  constructor(
    private vref: ViewContainerRef,
  ) {
  }

  ngAfterViewInit() {
    const t = this.vref.createEmbeddedView(this.templateRef);
    t.detectChanges();
  }
}
