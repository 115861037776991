var _a, _b, _c;
import { ImageSrc, ImageType, ImageW } from '../enum/image';
export var IMG_SRCSET_MAP = (_a = {},
    _a[ImageType.SQ] = [ImageSrc.SQ_24, ImageSrc.SQ_48, ImageSrc.SQ_80, ImageSrc.SQ_160,
        ImageSrc.SQ_300, ImageSrc.SQ_616, ImageSrc.SQ_932, ImageSrc.SQ_1232, ImageSrc.SQ_1864],
    _a[ImageType.SQX] = [ImageSrc.SQ_616, ImageSrc.SQ_932, ImageSrc.SQ_1232, ImageSrc.SQ_1864],
    _a[ImageType.POSTER] = [ImageSrc.PO_198, ImageSrc.PO_396, ImageSrc.PO_339, ImageSrc.PO_678],
    _a[ImageType.LS_logo] = [ImageSrc.PR_32, ImageSrc.PR_64],
    _a[ImageType.LS_16_9] = [ImageSrc.LS_300, ImageSrc.LS_616, ImageSrc.LS_932, ImageSrc.LS_1232, ImageSrc.LS_1864],
    _a[ImageType.LS_16_9_X] = [ImageSrc.LS_616, ImageSrc.LS_932, ImageSrc.LS_1232, ImageSrc.LS_1864],
    _a[ImageType.VARIABLE] = [ImageSrc.V_300, ImageSrc.V_616, ImageSrc.V_932, ImageSrc.V_1232, ImageSrc.V_1864],
    _a[ImageType.FULLWIDTH] = [ImageSrc.V_300, ImageSrc.V_616, ImageSrc.V_932, ImageSrc.WH_1232_410, ImageSrc.WH_1440_480, ImageSrc.WH_1920_640],
    _a);
export var IMG_W_MAP = (_b = {},
    _b[ImageType.SQ] = [ImageW.w_24, ImageW.w_48, ImageW.w_80, ImageW.w_160,
        ImageW.w_300, ImageW.w_616, ImageW.w_932, ImageW.w_1232, ImageW.w_1864],
    _b[ImageType.SQX] = [ImageW.x1, ImageW.x1_5, ImageW.x2, ImageW.x3],
    _b[ImageType.POSTER] = [ImageW.p_198, ImageW.p_396, ImageW.p_339, ImageW.p_678],
    _b[ImageType.LS_logo] = [ImageW.w_80, ImageW.w_160],
    _b[ImageType.LS_16_9] = [ImageW.w_300, ImageW.w_616, ImageW.w_932, ImageW.w_1232, ImageW.w_1864],
    _b[ImageType.LS_16_9_X] = [ImageW.x1, ImageW.x1_5, ImageW.x2, ImageW.x3],
    _b[ImageType.VARIABLE] = [ImageW.w_300, ImageW.w_616, ImageW.w_932, ImageW.w_1232, ImageW.w_1864],
    _b[ImageType.FULLWIDTH] = [ImageW.w_300, ImageW.w_616, ImageW.w_932, ImageW.w_1232, ImageW.w_1440, ImageW.w_1920],
    _b);
export var IMG_SRC = (_c = {},
    _c[ImageType.SQ] = ImageSrc.SQ_616,
    _c[ImageType.SQX] = ImageSrc.SQ_616,
    _c[ImageType.POSTER] = ImageSrc.PO_198,
    _c[ImageType.LS_logo] = ImageSrc.PR_32,
    _c[ImageType.LS_16_9] = ImageSrc.LS_616,
    _c[ImageType.LS_16_9_X] = ImageSrc.LS_616,
    _c[ImageType.VARIABLE] = ImageSrc.V_616,
    _c[ImageType.FULLWIDTH] = ImageSrc.WH_1920_640,
    _c);
