import {ITeaser} from '../teaser/payload';
import {Channel} from '../teaser/channel';
import {ITopic} from './payload';
import {PortalRoute} from '../teaser/portalRoute';
import {PortalService} from '../../shared/service/portal.service';
import {ImageModel} from '../component/image';
import {IMovieSerie, ITheater} from '../movieSerie/payload';
import {ImageType} from '../enum/image';

export class SearchItemModel {
  title: string;
  portalRoute: PortalRoute;
  preTitle: string;
  image: ImageModel;

  static fromTeasers(teasers: ITeaser[]): SearchItemModel[] {
    return teasers ? teasers.map(t => {
      const m = new SearchItemModel();
      m.title = t.title;
      m.portalRoute = PortalRoute.fromHostname(t.portal, t.url);
      if (t.channel) {
        m.preTitle = Channel.deserializeJson(t.channel).pretitle();
      }
      return m;
    }) : [];
  }

  static fromTopics(topics: ITopic[], portalService: PortalService): SearchItemModel[] {
    return topics ? topics.map(t => {
      const m = new SearchItemModel();
      m.title = t.name;
      const topicRoute = PortalRoute.fromUri(t.uri);
      const topicRoutePath = topicRoute.path;
      const localTopicUri = portalService.uri(topicRoutePath);
      m.portalRoute = PortalRoute.fromUri(localTopicUri);
      return m;
    }) : [];
  }

  static fromMovies(movies: IMovieSerie[]): SearchItemModel[] {
    return movies ? movies.map(t => {
      const m = new SearchItemModel();
      m.title = t.title;
      m.portalRoute = PortalRoute.fromHostname(t.portal, t.url);
      if (t.poster) {
        m.image = new ImageModel(t.poster, ImageType.POSTER);
      }
      return m;
    }) : [];
  }

  static fromTheaters(theaters: ITheater[]): SearchItemModel[] {
    return theaters ? theaters.map(t => {
      const m = new SearchItemModel();
      m.title = t.title;
      m.portalRoute = PortalRoute.fromHostname(t.portal, t.url);
      return m;
    }) : [];
  }
}
