/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../svgIcon/svgIcon.component.ngfactory";
import * as i2 from "../../svgIcon/svgIcon.component";
import * as i3 from "./checkbox.component";
var styles_CheckboxComponent = [];
var RenderType_CheckboxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CheckboxComponent, data: {} });
export { RenderType_CheckboxComponent as RenderType_CheckboxComponent };
export function View_CheckboxComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "p", [["class", "checkbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0], [8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", " "])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [["class", "checkbox-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "svgicon", [], [[1, "class", 0]], null, null, i1.View_SvgIconComponent_0, i1.RenderType_SvgIconComponent)), i0.ɵdid(6, 49152, null, 0, i2.SvgIconComponent, [], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_5 = "check"; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; var currVal_1 = _co.id; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.id; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.label; _ck(_v, 3, 0, currVal_3); var currVal_4 = i0.ɵnov(_v, 6).class; _ck(_v, 5, 0, currVal_4); }); }
export function View_CheckboxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formcheckbox", [], null, null, null, View_CheckboxComponent_0, RenderType_CheckboxComponent)), i0.ɵdid(1, 49152, null, 0, i3.CheckboxComponent, [], null, null)], null, null); }
var CheckboxComponentNgFactory = i0.ɵccf("formcheckbox", i3.CheckboxComponent, View_CheckboxComponent_Host_0, { label: "label", value: "value" }, { valueChange: "valueChange" }, []);
export { CheckboxComponentNgFactory as CheckboxComponentNgFactory };
