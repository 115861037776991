export class FootballTableHeading {
  constructor(
    public hideMobile?: boolean,
    public name?: string,
    public sortBy?: string,
  ) {
  }

  static forFootballTable(): FootballTableHeading[] {
    return [
      new FootballTableHeading(false, 'PL', 'pl'),
      new FootballTableHeading(true),
      new FootballTableHeading(true),
      new FootballTableHeading(false, 'VEREIN'),
      new FootballTableHeading(false, 'SP', 'sp'),
      new FootballTableHeading(true, 'S', 's'),
      new FootballTableHeading(true, 'U', 'u'),
      new FootballTableHeading(true, 'N', 'n'),
      new FootballTableHeading(true, 'TORE', 'diff'),
      new FootballTableHeading(true, 'Diff.', 'diff'),
      new FootballTableHeading(false, 'PUNKTE', 'points'),
    ];
  }
}
