import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation
} from '@angular/core';
import {IDynamicComponent, IRoute} from '@model/payload';
import {TeasersService} from '@shared/service/teasers.service';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {DeviceDetectionService} from '@shared/service/device-detection.service';
import {TitleService} from '@shared/service/title.service';
import {ActivatedRoute} from '@angular/router';
import {PortalService} from '@shared/service/portal.service';
import {DynamicType} from '@model/enum/dynamic';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {

  leftComponents: IDynamicComponent[] = [];
  centerComponents: IDynamicComponent[] = [];
  topSouthComponents: IDynamicComponent[] = [];
  topWestComponents: IDynamicComponent[] = [];
  topEastComponents: IDynamicComponent[] = [];
  topNorthComponents: IDynamicComponent[] = [];
  story = false;
  route$: Observable<IRoute>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private route: IRoute;
  private ad: IDynamicComponent;

  @HostListener('window:resize')
  onResize() {
    if (this.route && this.route.type === 'article' && isPlatformBrowser(this.platformId)) {
      if (!this.ad) {
        this.ad = this.leftComponents.find(c => c.type === DynamicType.adMediumRectangleSticky);
      }
      const isTablet = this.deviceDetectionService.isTablet;
      if (isTablet) {
        this.leftComponents = this.leftComponents.filter(c => c !== this.ad);
      } else if (!this.leftComponents.find(c => c.type === DynamicType.adMediumRectangleSticky)) {
        this.leftComponents.push(this.ad);
      }
    }
  }

  constructor(@Inject(PLATFORM_ID) protected platformId: string,
              private activatedRoute: ActivatedRoute,
              private titleService: TitleService,
              private deviceDetectionService: DeviceDetectionService,
              private teaserService: TeasersService,
              private portalService: PortalService,
  ) {
  }

  ngOnInit(): void {
    this.route$ = this.activatedRoute.data.pipe(
      takeUntil(this.destroy$),
      map((data: { route: IRoute }) => data.route as IRoute),
      tap(route => {
        this.route = route;
        this.titleService.setDocumentTitle(route.title);
        this.setContent(route);
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  protected hasAnyTopComponent(data: IRoute): boolean {
    if (!data) {
      return false;
    }
    const sum = Object.keys(data).filter((k) => {
      k.startsWith('top');
    }).map((k) => {
      return data[k].length;
    }).reduce((a, b) => a + b, 0);
    return sum > 0;
  }

  private setContent(data: IRoute) {
    if (data.collections) {
      for (const collection in data.collections) {
        if (data.collections.hasOwnProperty(collection)) {
          const numberOfTeasers = +data.collections[collection];
          this.teaserService.prewarmCollection(collection, numberOfTeasers);
        }
      }
    }
    if (!data.layout) {
      return;
    }
    this.topNorthComponents = data.layout.top_north || null;
    this.topWestComponents = data.layout.top_west || null;
    this.topEastComponents = data.layout.top_east || null;
    this.topSouthComponents = data.layout.top_south || null;

    if (!data.layout.left) {
      // new layout empty
      this.leftComponents = [];
    } else if (isPlatformBrowser(this.platformId) && this.deviceDetectionService.isTablet) {
      this.leftComponents = [];
    } else {
      this.leftComponents = this.getLeftComponents(data);
    }
    this.centerComponents = this.getCenterComponents(data);
  }

  private getLeftComponents(data: IRoute): IDynamicComponent[] {
    let leftComponents = data.layout.left || [];
    if (data.layout.center && data.layout.center[0] && data.layout.center[0].type === 'story') {
      leftComponents = [];
      this.story = true;
    } else {
      this.story = false;
    }
    if (isPlatformServer(this.platformId) && data.type === 'channel') {
      return this.hasAnyTopComponent(data) ? [] : leftComponents.slice(0, 3);
    }
    return leftComponents;
  }

  private getCenterComponents(data: IRoute): IDynamicComponent[] {
    const centerComponents = data.layout.center || [];
    if (isPlatformServer(this.platformId) && data.type === 'channel') {
      return this.hasAnyTopComponent(data) ? [] : centerComponents.slice(0, 3);
    }
    return centerComponents;
  }

  get isLaoe() {
    return this.portalService.currentPortal() === 'lustaufoesterreichat';
  }
}
