import * as tslib_1 from "tslib";
import { TeaserComponent } from '@component/common/teaser/teaser.component';
var HeroComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HeroComponent, _super);
    function HeroComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return HeroComponent;
}(TeaserComponent));
export { HeroComponent };
