import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var LoadingService = /** @class */ (function () {
    function LoadingService(router) {
        this.router = router;
        this.loadingCount = 0;
        this.internalOverlayCount = 0;
        this.loadingInfo = new BehaviorSubject({ shouldLoad: false, hasOverlay: false });
    }
    Object.defineProperty(LoadingService.prototype, "loadingObs$", {
        get: function () {
            return this.loadingInfo.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    LoadingService.prototype.startLoading = function (hasOverlay) {
        this.loadingCount++;
        this.overlayCount = hasOverlay ? 1 : -1;
        if (this.loadingCount > 1) {
            return;
        }
        this.loadingInfo.next({ shouldLoad: true, hasOverlay: this.internalOverlayCount > 0 });
    };
    LoadingService.prototype.stopLoading = function (removeOverlay) {
        if (removeOverlay === void 0) { removeOverlay = false; }
        if (this.loadingCount === 0) {
            return;
        }
        this.loadingCount--;
        if (removeOverlay) {
            this.overlayCount = -1;
        }
        if (this.loadingCount === 0) {
            this.loadingInfo.next({ shouldLoad: false, hasOverlay: false });
        }
        else {
            this.loadingInfo.next({ shouldLoad: true, hasOverlay: this.internalOverlayCount > 0 });
        }
    };
    Object.defineProperty(LoadingService.prototype, "overlayCount", {
        set: function (value) {
            this.internalOverlayCount += value;
            if (this.internalOverlayCount < 0) {
                this.internalOverlayCount = 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    LoadingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingService_Factory() { return new LoadingService(i0.ɵɵinject(i1.Router)); }, token: LoadingService, providedIn: "root" });
    return LoadingService;
}());
export { LoadingService };
