/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i2 from "../../../common/ui/linkBar/linkBar.component";
import * as i3 from "@angular/common";
import * as i4 from "../../content/no-consent/no-consent.component.ngfactory";
import * as i5 from "../../content/no-consent/no-consent.component";
import * as i6 from "../../../../shared/service/didomi.service";
import * as i7 from "./pinpoll.component";
import * as i8 from "../../../../shared/service/browser.ref";
var styles_PinPollComponent = [];
var RenderType_PinPollComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PinPollComponent, data: {} });
export { RenderType_PinPollComponent as RenderType_PinPollComponent };
function View_PinPollComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "linkbar", [], null, null, null, i1.View_LinkBarComponent_0, i1.RenderType_LinkBarComponent)), i0.ɵdid(1, 49152, null, 0, i2.LinkBarComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkBar; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PinPollComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "pinpoll"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PinPollComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, [[1, 0], ["pinpollDiv", 1]], null, 0, "div", [], [[1, "data-pinpoll-id", 0], [1, "data-type", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkBar; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.pinpollId; var currVal_2 = _co.pinpollType; _ck(_v, 3, 0, currVal_1, currVal_2); }); }
function View_PinPollComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i4.View_NoConsentComponent_0, i4.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i5.NoConsentComponent, [i6.DidomiService], { vendor: [0, "vendor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PinPollComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { pinpollDiv: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_PinPollComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["noContent", 2]], null, 0, null, View_PinPollComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.showPinPoll$)); var currVal_1 = i0.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_PinPollComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pinpoll", [], null, null, null, View_PinPollComponent_0, RenderType_PinPollComponent)), i0.ɵdid(1, 4243456, null, 0, i7.PinPollComponent, [i8.BrowserRef, i6.DidomiService], null, null)], null, null); }
var PinPollComponentNgFactory = i0.ɵccf("pinpoll", i7.PinPollComponent, View_PinPollComponent_Host_0, {}, {}, []);
export { PinPollComponentNgFactory as PinPollComponentNgFactory };
