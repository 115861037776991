import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {ImageType} from '@model/enum/image';
import {ImageModel} from '@model/component/image';
import {IInlineImage} from '@model/content/inlineImg/payload';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'inlineImg',
  templateUrl: 'inlineImg.component.html'
})
export class InlineImgComponent implements DynamicComponent<IInlineImage> {

  imageModel: ImageModel;

  initModel(model: IInlineImage): void {
    const img = new ImageModel(model.data, ImageType.VARIABLE, null, true);
    img.width = model.data.width;
    img.hasLightbox = true;
    this.imageModel = img;
  }
}
