<div class="interactionBar">
  <a (click)="scrollToComments()"
     oewaClickTrigger
     *ngIf="hasCoral"
     [ngClass]="'interactionBar-item'"
     title="Zu den Kommentaren">
    <svgicon icon="message"></svgicon>
    Kommentare
  </a>
  <a oewaClickTrigger
     [ngClass]="'interactionBar-item'">
    <div class="interactionBar-item-trigger" (click)="openSharePopup()">
      <svgicon [icon]="open ? 'times' : 'share'"></svgicon>
      Teilen
    </div>
    <div *ngIf="open" class="interactionBar-share">
      <button (click)="openPopup(link)"
              class="interactionBar-share-button"
              oewaClickTrigger
              *ngFor="let link of model.allLinks">
        <span [ngClass]="'interactionBar-share-icon interactionBar-'+link"
              title="Teilen per {{link}}"></span>
        {{link}}
      </button>
    </div>
  </a>
  <!--  <a oewaClickTrigger-->
  <!--     (click)="triggerCleverPush()"-->
  <!--     [ngClass]="'interactionBar-item'"-->
  <!--     title="Benachrichtigungen">-->
  <!--    <svgicon icon="bell"></svgicon>-->
  <!--    Mitteilungen-->
  <!--  </a>-->
</div>
