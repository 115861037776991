import {
  IFootballData,
  IFootballDateStanding,
  IFootballGroupStanding,
  IFootballLeagueStanding,
  IFootballTeam
} from '@model/content/footballTable/payload';

export enum GameType {
  overall = 'overall',
  home = 'home',
  away = 'away',
}

export class FootballStandingRow {
  pl: number;
  status: 'up' | 'down' | 'equal';
  logo: string;
  name: string;
  sp: number;
  s: number;
  u: number;
  n: number;
  goalsAgainst: number;
  goalsScored: number;
  manualPoints: number;

  get diff() {
    return this.goalsScored - this.goalsAgainst;
  }

  get goals() {
    return this.goalsScored + ':' + this.goalsAgainst;
  }

  get points() {
    if (this.manualPoints) {
      return this.manualPoints;
    }
    return this.s * 3 + this.u;
  }

  static fromStandings(
    standings: IFootballLeagueStanding<IFootballGroupStanding[]>,
    teams: IFootballData<IFootballTeam[]>,
    previousStandings?: IFootballDateStanding[],
    gameType: GameType = GameType.overall,
  ): FootballStandingRow[] {
    return standings.standings.data.map(st => {
      const standing = new FootballStandingRow();
      standing.pl = st.position;
      standing.status = 'equal';
      if (previousStandings) {
        const prevStanding = previousStandings.find(prevSt => prevSt.team_name === st.team_name);
        if (prevStanding && prevStanding.position < st.position) {
          standing.status = 'down';
        }
        if (prevStanding && prevStanding.position > st.position) {
          standing.status = 'up';
        }
      }
      const team = teams.data.find(t => t.id === st.team_id);
      if (team && team.logo_path) {
        FootballStandingRow.setLogo(standing, team.logo_path);
      }
      FootballStandingRow.setName(standing, st.team_name);
      standing.sp = st[gameType].games_played;
      standing.s = st[gameType].won;
      standing.u = st[gameType].draw;
      standing.n = st[gameType].lost;
      standing.goalsAgainst = st[gameType].goals_against;
      standing.goalsScored = st[gameType].goals_scored;
      return standing;
    });
  }

  static fromAustrianStandings(
    standings: IFootballLeagueStanding<IFootballGroupStanding[]>,
    teams: IFootballData<IFootballTeam[]>,
    regularSeason?: IFootballLeagueStanding<IFootballGroupStanding[]>,
    gameType: GameType = GameType.overall,
  ): FootballStandingRow[] {
    let regularSeasonStandings: IFootballGroupStanding[];
    if (regularSeason) {
      regularSeasonStandings = regularSeason.standings.data;
    }
    const rows = standings.standings.data.map(st => {
      const standing = new FootballStandingRow();
      standing.pl = st.position;
      standing.status = 'equal';
      const team = teams.data.find(t => t.id === st.team_id);
      if (team && team.logo_path) {
        FootballStandingRow.setLogo(standing, team.logo_path);
      }
      FootballStandingRow.setName(standing, st.team_name);
      standing.sp = st[gameType].games_played;
      standing.s = st[gameType].won;
      standing.u = st[gameType].draw;
      standing.n = st[gameType].lost;
      standing.goalsAgainst = st[gameType].goals_against;
      standing.goalsScored = st[gameType].goals_scored;
      standing.manualPoints = st[gameType].points;
      // if (regularSeasonStandings) {
      //   const currRow = regularSeasonStandings.find(gst => gst.team_name === st.team_name);
      //   standing.sp = currRow[gameType].games_played + st[gameType].games_played;
      //   standing.s = currRow[gameType].won + st[gameType].won;
      //   standing.u = currRow[gameType].draw + st[gameType].draw;
      //   standing.n = currRow[gameType].lost + st[gameType].lost;
      //   standing.goalsAgainst = currRow[gameType].goals_against + st[gameType].goals_against;
      //   standing.goalsScored = currRow[gameType].goals_scored + st[gameType].goals_scored;
      // }
      return standing;
    });
    rows.map((row, i) => {
      row.pl = i + 1;
    });
    return rows;
  }

  static fromDateStanding(
    standings: IFootballData<IFootballDateStanding[]>,
    gameType: GameType = GameType.overall,
    previousStandings?: IFootballData<IFootballDateStanding[]>,
  ): FootballStandingRow[] {
    return standings.data.map(st => {
      const standing = new FootballStandingRow();
      standing.status = 'equal';
      if (previousStandings) {
        const lastStanding = previousStandings.data.find(s => s.team_name === st.team_name);
        if (lastStanding && st.position < lastStanding.position) {
          standing.status = 'up';
        }
        if (lastStanding && st.position > lastStanding.position) {
          standing.status = 'down';
        }
      }
      standing.pl = st.position;
      FootballStandingRow.setLogo(standing, st.team_logo);
      FootballStandingRow.setName(standing, st.team_name);
      standing.sp = st[gameType].games_played;
      standing.s = st[gameType].won;
      standing.u = st[gameType].draw;
      standing.n = st[gameType].lost;
      standing.goalsAgainst = st[gameType].goals_against;
      standing.goalsScored = st[gameType].goals_scored;
      return standing;
    });
  }

  static fromGroupStanding(
    standings: IFootballGroupStanding[],
    teams: IFootballData<IFootballTeam[]>,
    gameType: GameType = GameType.overall,
    previousStandings?: IFootballData<IFootballDateStanding[]>,
  ): FootballStandingRow[] {
    return standings.map(st => {
      const standing = new FootballStandingRow();
      standing.status = 'equal';
      if (previousStandings) {
        const lastStanding = previousStandings.data.find(s => s.team_name === st.team_name);
        if (st.position < lastStanding.position) {
          standing.status = 'up';
        }
        if (st.position > lastStanding.position) {
          standing.status = 'down';
        }
      }
      standing.pl = st.position;
      const team = teams.data.find(t => t.id === st.team_id);
      if (team && team.logo_path) {
        standing.logo = team.logo_path;
      }
      standing.name = st.team_name;
      standing.sp = st[gameType].games_played;
      standing.s = st[gameType].won;
      standing.u = st[gameType].draw;
      standing.n = st[gameType].lost;
      standing.goalsAgainst = st[gameType].goals_against;
      standing.goalsScored = st[gameType].goals_scored;
      return standing;
    });
  }

  private static setName(standing: FootballStandingRow, teamName: string) {
    if (teamName === 'Rapid Vienna') {
      standing.name = 'Rapid Wien';
    } else if (teamName === 'LASK Linz') {
      standing.name = 'LASK';
    } else {
      standing.name = teamName;
    }
  }

  private static setLogo(standing: FootballStandingRow, teamLogo: string) {
    standing.logo = teamLogo === 'https://cdn.sportmonks.com/images/soccer/teams/9/3369.png' ?
      '/assets/images/football/lask.png' : teamLogo;
  }
}
