import {ImageModel} from '../../component/image';
import {TimeAgoUtil} from '../../../util/timeAgo';
import {ShareModel} from '../../component/share';
import {IDynamicComponent} from '../../payload';
import {IInlineSlider} from '../inlineSlider/payload';
import {DynamicType} from '../../enum/dynamic';
import {IScreenings} from '../../channel/screenings/payload';
import {IMovieDetail, IMovieGenericItem, IMovieProvider, ISeason} from './payload';
import {ImageType} from '../../enum/image';
import {IPosterSlider} from '../../component/posterSlider/payload';
import {BoxColor} from '../../enum/box';
import {PortalRoute} from '../../teaser/portalRoute';
import {GTMTriggerId} from '../../enum/gtm';
import {TabModel} from '@model/component/tab';

interface IMovieProviderExtd extends IMovieProvider {
  name: string;
  logo: string;
  class: string;
}

export class MovieSerieDetailModel {
  title: string;
  ovTitle: string;
  releaseYear: number;
  releaseCountry = '';
  badges: { link: PortalRoute, name: string, gtmId: GTMTriggerId }[] = [];
  playTime: number;
  releaseDate: string;
  classification: number;
  shareModel: ShareModel;
  predicate: string;
  teaserImage: ImageModel;
  poster: ImageModel;
  text: string;
  details: { key: string; value: { name: string, link: PortalRoute }[] }[] = [];
  paragraphs: IDynamicComponent[];
  gallery: IInlineSlider;
  movieSlider: IPosterSlider;
  screenings: IScreenings;
  imdbRating: number;
  seasons: TabModel<ISeason>[];
  seasonEpisodes: string;
  providers: IMovieProviderExtd[];
  website: string;

  get typeBadge(): string {
    return this.seasons && this.seasons.length > 0 ? 'Serie' : 'Film';
  }

  static deserialize(data: IMovieDetail, url): MovieSerieDetailModel {
    const m = new MovieSerieDetailModel();
    m.title = data.title;
    m.ovTitle = data.ov_title;
    m.releaseYear = data.release_year;
    if (data.release_countries) {
      data.release_countries.map(country => {
        m.releaseCountry += country.name + ', ';
      });
    }
    m.badges = data.genres ? data.genres.map(g => {
      return {name: g.name, link: PortalRoute.fromUri(g.uri), gtmId: GTMTriggerId.badge};
    }) : [];
    m.teaserImage = data.trailer && data.trailer.img && ImageModel.fromMovie(data);
    m.poster = data.poster && new ImageModel(data.poster, ImageType.POSTER);
    m.playTime = data.playtime;
    m.releaseDate = TimeAgoUtil.getDate(data.release_date);
    m.classification = data.classification;
    m.shareModel = ShareModel.fromMovie(data, url);
    m.text = data.teaser_text;
    m.addDetail('Schauspieler', m.reducePerson(data.cast));
    m.addDetail('Regie', m.reducePerson(data.directors));
    m.addDetail('Kamera', m.reducePerson(data.camera));
    m.addDetail('Autor', m.reducePerson(data.authors));
    m.addDetail('Musik', m.reducePerson(data.music));
    m.addDetail('Verleih', m.reducePerson(data.distributor));
    m.website = data.website;
    m.paragraphs = data.paragraphs;
    if (data.gallery) {
      m.gallery = {data: data.gallery, type: DynamicType.gallery};
    }
    m.screenings = {
      title: data.title,
      nested: false,
      id: data.id,
      type: DynamicType.screeningsBlock,
      category: 'movie',
      county: 'Alle'
    };
    m.imdbRating = data.imdb_rating;
    m.predicate = data.predicate;
    if (data.related_movies) {
      m.movieSlider = {
        type: DynamicType.posterSlider,
        collectionName: '',
        numberOfTeasers: 0,
        title: 'Ähnliche Filme',
        items: data.related_movies,
        color: BoxColor.standard
      };
    }
    // if Serie
    if (data.seasons && data.seasons.length) {
      m.seasons = data.seasons.map((e, i) => {
        const selected = i === 0;
        return new TabModel<ISeason>(i + 1, 'Staffel ' + (i + 1), e, selected);
      });
      let totalEpisodes = 0;
      data.seasons.forEach(s => {
        s.episodes.forEach(e => {
          totalEpisodes++;
        });
      });
      m.seasonEpisodes = data.seasons.length + ' / ' + totalEpisodes;
    }
    // if providers
    if (data.providers) {
      const logoMap = {
        Netflix: 'netflix.svg',
        'Google Play': 'google-play.svg',
        'Disney+': 'disney-plus.svg',
        'Apple TV+': 'apple-tv.svg',
        'Apple iTunes': 'apple-itunes.svg',
        'Amazon Prime': 'amazon-prime.svg',
        'Amazon Video': 'amazon-prime.svg',
        'Sky X': 'skyx.png',
        Flimmit: 'flimmit.svg',
      };
      m.providers = Object.keys(data.providers).map(key => {
        return {
          name: key,
          logo: '/assets/images/filmat-providers-logos/' + (logoMap[key] ? logoMap[key] : 'placeholder.svg'),
          ...data.providers[key],
          class: logoMap[key] ? logoMap[key].split('.')[0] : '',
        };
      });
    }
    return m;
  }

  reducePerson(data: IMovieGenericItem[]): { name: string, link: PortalRoute }[] {
    if (data) {
      return data.map(p => {
        return {
          name: p.name,
          link: PortalRoute.fromUri(p.uri),
        };
      });
    }
    return undefined;
  }

  addDetail(key: string, value: { name: string, link: PortalRoute }[]) {
    if (!value) {
      return;
    }
    this.details.push({
      key,
      value,
    });
  }
}
