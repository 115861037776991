import {ITeaserView} from './payload';

export class TeaserView implements ITeaserView {

  static get heroView(): TeaserView {
    return new TeaserView(true, true, false, true);
  }

  constructor(
    public img = true,
    public title = true,
    // tslint:disable-next-line:variable-name
    public teaser_text = true,
    public hero = false,
    // tslint:disable-next-line:variable-name
    public big_poster_view = false,
    // tslint:disable-next-line:variable-name
    public small_poster_view = false,
    public headlines = false,
  ) {
  }
}
