/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./reddit.component";
import * as i3 from "../../../../shared/service/loadExternalScript.service";
var styles_RedditComponent = [];
var RenderType_RedditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RedditComponent, data: {} });
export { RenderType_RedditComponent as RenderType_RedditComponent };
function View_RedditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "a", [], [[8, "href", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 0, 0, currVal_0); }); }
export function View_RedditComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "blockquote", [["class", "reddit-card"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_RedditComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.url$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RedditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-reddit", [], null, null, null, View_RedditComponent_0, RenderType_RedditComponent)), i0.ɵdid(1, 49152, null, 0, i2.RedditComponent, [i3.LoadExternalScriptService], null, null)], null, null); }
var RedditComponentNgFactory = i0.ɵccf("app-reddit", i2.RedditComponent, View_RedditComponent_Host_0, {}, {}, []);
export { RedditComponentNgFactory as RedditComponentNgFactory };
