import {PortalRoute} from './portalRoute';
import {PathTree} from './pathTree';
import {IChannel} from './payload';

export class Channel {
  public id: number;
  public path: string;
  public name: string;
  public section: string;
  public portalRoute: PortalRoute;
  public pathtree: PathTree[];

  static deserializeJson(json: IChannel) {
    const channel = new Channel();

    channel.id = json.id || 0;
    channel.name = json.name || '';
    channel.section = json.section || '';
    channel.path = json.url || '';
    channel.pathtree = PathTree.fromChannel(channel);

    if (channel.path !== '') {
      channel.portalRoute = PortalRoute.fromUri(channel.path);
    } else {
      const msg = 'Channel without portalRoute: ' + JSON.stringify(json);
      console.error(msg);
    }
    return channel;
  }

  pretitle(): string {
    return this.name.replace(/\//g, ' | ');
  }
}
