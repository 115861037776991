var _a, _b, _c, _d;
import { VendorTypes } from '@model/enum/vendorTypes';
export var VENDOR_PARAGRAPH_IMAGE_MAP = (_a = {},
    _a[VendorTypes.PLACEHOLDER] = '',
    _a[VendorTypes.facebook] = 'facebook.jpg',
    _a[VendorTypes.instagram] = 'instagram.jpg',
    _a[VendorTypes.degrees23] = 'degrees23.jpg',
    _a[VendorTypes.pinPoll] = 'pinPoll.jpg',
    _a[VendorTypes.podigee] = 'podigee.png',
    _a[VendorTypes.simplecast] = 'simplecast.png',
    _a[VendorTypes.infonline] = '',
    _a[VendorTypes.cleverPush] = 'cleverPush.jpg',
    _a[VendorTypes.riddle] = 'riddle.jpg',
    _a[VendorTypes.pinterest] = 'pinterest.jpg',
    _a[VendorTypes.tikTok] = 'tikTok.jpg',
    _a[VendorTypes.twitter] = 'twitter.jpg',
    _a[VendorTypes.youTube] = 'youTube.jpg',
    _a[VendorTypes.newsletter] = 'newsletter.jpg',
    _a[VendorTypes.competition] = 'competition.jpg',
    _a[VendorTypes.apaVideo] = 'apaVideo.jpg',
    _a[VendorTypes.vimeo] = 'vimeo.jpg',
    _a[VendorTypes.soundCloud] = 'soundCloud.jpg',
    _a[VendorTypes.outbrain] = 'outbrain.jpg',
    _a[VendorTypes.apaGrafiken] = 'apaGrafik.jpg',
    _a[VendorTypes.adition] = 'contentgarden-teaser.jpg',
    _a[VendorTypes.infogram] = 'degrees23.jpg',
    _a[VendorTypes.datawrapper] = 'degrees23.jpg',
    _a[VendorTypes.googleMaps] = 'googleMaps.jpg',
    _a[VendorTypes.googleAds] = 'googleAds.jpg',
    _a[VendorTypes.googleAnalytics] = '',
    _a[VendorTypes.googleTagManager] = '',
    _a[VendorTypes.piano] = '',
    _a[VendorTypes.flourish] = 'degrees23.jpg',
    _a[VendorTypes.spotify] = 'spotify.png',
    _a[VendorTypes.activeCampaign] = 'newsletter.jpg',
    _a[VendorTypes.paypal] = 'paypal.png',
    _a[VendorTypes.amazon] = 'amazon.jpg',
    _a[VendorTypes.art19] = 'art19.jpg',
    _a[VendorTypes.linkedIn] = 'linkedin.jpg',
    _a[VendorTypes.acast] = 'acast.jpg',
    _a[VendorTypes.snowplow] = '',
    _a[VendorTypes.kDigital] = '',
    _a[VendorTypes.assertiveYield] = '',
    _a);
export var VENDOR_NAME_MAP = (_b = {},
    _b[VendorTypes.PLACEHOLDER] = '',
    _b[VendorTypes.facebook] = 'Facebook',
    _b[VendorTypes.instagram] = 'Instagram',
    _b[VendorTypes.degrees23] = '23Degree',
    _b[VendorTypes.pinPoll] = 'Pinpoll GmbH (Private Limited)',
    _b[VendorTypes.podigee] = 'Podigee GmbH',
    _b[VendorTypes.simplecast] = 'Simplecast Audios Ventures, Inc',
    _b[VendorTypes.infonline] = 'INFOnline GmbH',
    _b[VendorTypes.cleverPush] = 'CleverPush GmbH',
    _b[VendorTypes.riddle] = 'Riddle Technologies AG',
    _b[VendorTypes.pinterest] = 'Pinterest',
    _b[VendorTypes.tikTok] = 'TikTok',
    _b[VendorTypes.twitter] = 'Twitter',
    _b[VendorTypes.youTube] = 'Youtube',
    _b[VendorTypes.piano] = 'Piano Software Inc.',
    _b[VendorTypes.newsletter] = 'Piano Software Inc.',
    _b[VendorTypes.competition] = 'Piano Software Inc.',
    _b[VendorTypes.apaVideo] = 'APA-Videoplayer',
    _b[VendorTypes.vimeo] = 'Vimeo',
    _b[VendorTypes.soundCloud] = 'Soundcloud',
    _b[VendorTypes.outbrain] = 'Outbrain UK Ltd',
    _b[VendorTypes.apaGrafiken] = 'APA-Wahltool',
    _b[VendorTypes.adition] = 'ADITION technologies AG',
    _b[VendorTypes.infogram] = 'Infogram',
    _b[VendorTypes.datawrapper] = 'Datawrapper',
    _b[VendorTypes.googleAds] = 'Google Advertising Products',
    _b[VendorTypes.googleMaps] = 'Google Maps',
    _b[VendorTypes.googleAnalytics] = 'Google Analytics',
    _b[VendorTypes.googleTagManager] = 'Google Tag Manager',
    _b[VendorTypes.flourish] = 'Flourish(Kiln Enterprises Ltd)',
    _b[VendorTypes.spotify] = 'Spotify',
    _b[VendorTypes.activeCampaign] = 'ActiveCampaign',
    _b[VendorTypes.paypal] = 'PayPal Holdings, Inc',
    _b[VendorTypes.amazon] = 'Amazon',
    _b[VendorTypes.art19] = 'Art19',
    _b[VendorTypes.linkedIn] = 'LinkedIn',
    _b[VendorTypes.acast] = 'Acast',
    _b[VendorTypes.snowplow] = 'snowplow.io',
    _b[VendorTypes.kDigital] = 'k-digital Medien GmbH & Co KG',
    _b[VendorTypes.assertiveYield] = 'Assertive Yield B.V',
    _b);
export var VENDOR_PARAGRAPH_MAP = (_c = {},
    _c[VendorTypes.PLACEHOLDER] = '',
    _c[VendorTypes.facebook] = 'einen Facebook Post',
    _c[VendorTypes.instagram] = 'einen Instagram Post',
    _c[VendorTypes.degrees23] = 'eine 23degrees Grafik',
    _c[VendorTypes.pinPoll] = 'eine PinPoll Umfrage',
    _c[VendorTypes.podigee] = 'einen Podigee Podcast-Player',
    _c[VendorTypes.simplecast] = 'einen Simplecast Podcast-Player',
    _c[VendorTypes.infonline] = 'ein INFOnline widget',
    _c[VendorTypes.cleverPush] = 'einen CleverPush Button',
    _c[VendorTypes.riddle] = 'eine Riddle Umfrage',
    _c[VendorTypes.pinterest] = 'einen Pinterest Pin',
    _c[VendorTypes.tikTok] = 'ein TikTok Video',
    _c[VendorTypes.twitter] = 'einen Twitter Post',
    _c[VendorTypes.youTube] = 'ein Youtube Video',
    _c[VendorTypes.piano] = 'ein Login',
    _c[VendorTypes.newsletter] = 'eine Newsletter Anmeldung',
    _c[VendorTypes.competition] = 'ein Quiz',
    _c[VendorTypes.apaVideo] = 'ein APA Video',
    _c[VendorTypes.vimeo] = 'ein Vimeo Video',
    _c[VendorTypes.soundCloud] = 'einen Soundcloud Player',
    _c[VendorTypes.outbrain] = 'einen Outbrain Feed',
    _c[VendorTypes.apaGrafiken] = 'eine APA Grafik',
    _c[VendorTypes.adition] = 'eine Adition Werbung',
    _c[VendorTypes.infogram] = 'eine Infogram Grafik',
    _c[VendorTypes.datawrapper] = 'eine datawrapper Grafik',
    _c[VendorTypes.googleAds] = 'eine Google Werbung',
    _c[VendorTypes.googleMaps] = 'eine Google Map',
    _c[VendorTypes.googleAnalytics] = 'Google Analytics',
    _c[VendorTypes.googleTagManager] = 'Google Tag Manager',
    _c[VendorTypes.flourish] = 'eine Flourish Grafik',
    _c[VendorTypes.spotify] = 'einen Spotify Player',
    _c[VendorTypes.activeCampaign] = 'ein ActiveCampaign Widget',
    _c[VendorTypes.paypal] = 'ein PayPal Widget',
    _c[VendorTypes.amazon] = 'ein Amazon widget',
    _c[VendorTypes.art19] = 'einen Art19 Player',
    _c[VendorTypes.linkedIn] = 'ein LinkedIn embed',
    _c[VendorTypes.acast] = 'ein Acast embed',
    _c[VendorTypes.snowplow] = '',
    _c[VendorTypes.kDigital] = '',
    _c[VendorTypes.assertiveYield] = '',
    _c);
export var VENDOR_ID_MAP = (_d = {},
    _d[VendorTypes.facebook] = 'facebook',
    _d[VendorTypes.instagram] = 'c:instagram',
    _d[VendorTypes.degrees23] = 'c:23degree-TH2WmEkR',
    _d[VendorTypes.pinPoll] = 727,
    _d[VendorTypes.podigee] = 'c:podigeegm-ckk8hi2w',
    _d[VendorTypes.simplecast] = 'c:simplecast-PfFtJKkL',
    _d[VendorTypes.infonline] = 730,
    _d[VendorTypes.cleverPush] = 1139,
    _d[VendorTypes.riddle] = 'c:riddle-afygamYN',
    _d[VendorTypes.pinterest] = 'c:pinterest',
    _d[VendorTypes.tikTok] = 'c:tiktok-KZAUQLZ9',
    _d[VendorTypes.twitter] = 'twitter',
    _d[VendorTypes.youTube] = 'c:youtube',
    _d[VendorTypes.piano] = 412,
    _d[VendorTypes.newsletter] = 412,
    _d[VendorTypes.competition] = 412,
    _d[VendorTypes.apaVideo] = 'c:apa-GB764ZD7',
    _d[VendorTypes.vimeo] = 'c:vimeo',
    _d[VendorTypes.soundCloud] = 'c:soundclou-CGaYMFFa',
    _d[VendorTypes.outbrain] = 164,
    _d[VendorTypes.apaGrafiken] = 'c:apa-GB764ZD7',
    _d[VendorTypes.adition] = 39,
    _d[VendorTypes.infogram] = 'c:infogram-Gqd4C9Gj',
    _d[VendorTypes.datawrapper] = 'c:datawrappe-NztaFDbw',
    _d[VendorTypes.googleAds] = 'google',
    _d[VendorTypes.googleMaps] = 'c:googlemap-cRJEW8Pp',
    _d[VendorTypes.googleAnalytics] = 'c:googleana-4TXnJigR',
    _d[VendorTypes.googleTagManager] = 'c:googletag-Lpt6GgbT',
    _d[VendorTypes.flourish] = 'c:flourish-Z3fFJ4mK',
    _d[VendorTypes.spotify] = 'c:spotify-embed',
    _d[VendorTypes.activeCampaign] = 'c:activecampaign',
    _d[VendorTypes.paypal] = 'c:paypal-shopping',
    _d[VendorTypes.amazon] = 'amazon',
    _d[VendorTypes.art19] = 20007,
    _d[VendorTypes.linkedIn] = 804,
    _d[VendorTypes.acast] = 'c:acast-4nKTYZPH',
    _d[VendorTypes.snowplow] = 'c:snowplowi-Hdg3gRm7',
    _d[VendorTypes.assertiveYield] = 'c:assertive-aNjYQjhG',
    _d[VendorTypes.kDigital] = 'c:kdigital-zkLW9ybx',
    _d);
