import {ImageType} from '../enum/image';
import {PortalRoute} from '../teaser/portalRoute';
import {ITeaser, ITeaserImage, ITeaserVideo} from '../teaser/payload';
import {IMovieDetail} from '../content/movieSerieDetail/payload';

export class ImageModel {

  alt: string;
  copyright: string;
  description: string;
  name: string;
  source: string;
  title: string;
  url: string;
  video?: ITeaserVideo;
  hasLightbox: boolean;
  src: string;
  srcset: string;
  width: string;

  static fromMovie(movie: IMovieDetail): ImageModel {
    const i = new ImageModel(movie.trailer.img, ImageType.LS_16_9);
    i.video = movie.trailer.video;
    i.imgClass = 'video-play picimage-badge';
    return i;
  }

  constructor(public image: ITeaserImage,
              public size: ImageType = null,
              public link: PortalRoute = null,
              public hasCaption = false,
              public isRounded = false,
              public imgClass = '') {
    this.alt = image.alt;
    this.url = image.url || image.uri;
    this.copyright = image.copyright || '';
    this.description = image.description || '';
    this.name = image.name || '';
    this.source = image.source || '';
    this.title = image.title || '';
  }

  get lightbox() {
    if (this.hasLightbox && this.image.width && this.image.height) {
      return this.image.width < this.image.height;
    }
    return false;
  }

  get focalY() {
    return this.image.focal_point && this.image.focal_point.y ? this.image.focal_point.y * 100 + '%' : '50%';
  }

  setVideo(teaser: ITeaser) {
    if (teaser.teaser_video) {
      this.imgClass += ' picimage-badge video-play';
      this.video = teaser.teaser_video;
    }
  }

  get caption() {
    let caption = '';
    if (this.description) {
      caption += this.description;
    }
    if (this.copyright) {
      caption += '<p>© ' + this.copyright + '</p>';
    }
    return caption;
  }
}
