<div class="footballTableCup">
  <h1 class="footballTableCup-title" *ngIf="heading.getValue()">{{heading.getValue()}}</h1>

  <formselect
    *ngIf="rounds$ | async as rounds"
    [label]="'Runden'"
    (valueSelectedChange)="changeRound(rounds[$event])"
    [values]="rounds"></formselect>

  <ng-container *ngIf="table$ | async as wrapper">
    <div *ngFor="let table of wrapper.tables">
      <app-football-table [model]="table"></app-football-table>
    </div>
  </ng-container>
</div>
