export const ANONYMOUS_USER: IPianoUser = {
  valid: false
};


export interface IPianoUser {
  valid: boolean;
  confirmed?: boolean;
  aud?: string;
  email?: string;
  exp?: number;
  family_name?: string;
  firstName?: string;
  given_name?: string;
  iat?: number;
  iss?: string;
  jti?: string;
  lastName?: string;
  sub?: string;
  uid?: string;
  user_token?: string;
}

export interface IPianoLoginResponse {
  token: string;
  user: IPianoUser;
}

export interface IExperience {
  execute: () => void;
}

export interface IOfferShowParams {
  offerId: string;
  templateId?: string;
  displayMode?: string;
  containerSelector?: string;
  termId?: string;
  termIds?: string[];
  loginRequired?: () => void;
  close?: () => void;
  /*
   *Callback function which will be invoked when user has converted on one of the terms presented in the offer
   */
  complete?: () => void;
  customEvent?: () => void;
}

export interface IOffer {
  /**
   * @see https://docs.piano.io/javascript-offer/
   */
  show: (params: IOfferShowParams) => void;
}

export interface IMyAccount {
  /**
   * @see https://docs.piano.io/my-account-component/
   */
  show: (e: any) => void;
}

export interface IPiano {
  init: (settings?: any) => void;
  show: (settings?: any) => void;
  getUser: () => IPianoUser;
  isUserValid: () => boolean;
  logout: () => void;
}

export interface ITinyPassApi {
  callApi: (e, t, n, r?, i?) => void;
}

export interface ITinyPass {
  aid: string;
  api: ITinyPassApi;
  experience: IExperience;
  myaccount: IMyAccount;
  offer: IOffer;
  pianoId: IPiano;
  enableGACrossDomainLinking: (gaTrackingId?) => void;
  credit: {
    check: (e) => Promise<{
      cid: string;
      itemId: string;
      redeemed: boolean;
      uid: string;
    }>,
    redeem: (e) => Promise<void>,
    registerCallback: (e, n) => void,
  };

  push(data: any[]): void;
}


export interface IResource {
  aid: string;
  description: string;
  image_url: string;
  name: string;
  rid: string;
}

export interface ITerm {
  aid: string;
  create_date: number;
  description: string;
  name: string;
  resource: IResource;
  term_id: string;
  type: string;
}

export interface IUserAccess {
  granted: boolean;
  resource: IResource;
}

export interface IUserSubscription {
  auto_renew: boolean;
  cancelable: boolean;
  cancelable_and_refundadle: boolean;
  next_bill_date: number;
  payment_billing_plan_description: string;
  start_date: number;
  status: string;
  subscription_id: string;
  user_access: IUserAccess;
  term: ITerm;
}

export interface ISubscriptionListResponse {
  subscriptions: IUserSubscription[];
}

export interface IPianoConversion {
  chargeAmount: string;
  chargeCurrency: string;
  cookie_domain: string;
  email: string;
  expires: number;
  offerId: string;
  promotionId: number;
  rid: string;
  sharedAccounts: any[];
  startedAt: string;
  termConversionId: string;
  termId: string;
  token_list: string;
  uid: string;
  user_token: string;
}


export interface StartCheckoutResponse {
  aid: string;
  debug: string;
  displayMode: string;
  experienceId: string;
  formNameByTermId: string;
  hasLoginRequiredCallback: string;
  lang: string;
  offerId: string;
  showCloseButton: string;
  templateId: string;
  termId: string;
  type: string;
  upgradeSubscriptionId: string;
  url: string;
  userProvider: string;
  userToken: string;
}
