import * as tslib_1 from "tslib";
import { FootballService } from '@shared/service/football.service';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { TabModel } from '@model/component/tab';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
var FootballLiveScoreWrapperComponent = /** @class */ (function () {
    function FootballLiveScoreWrapperComponent(footballService) {
        this.footballService = footballService;
        this.tabModels = [
            new TabModel(1, 'Liveticker', undefined, true),
            new TabModel(2, 'weitere Spiele', undefined, false),
        ];
        this.activeTab = new BehaviorSubject(1);
        this.gameOver = new Subject();
    }
    Object.defineProperty(FootballLiveScoreWrapperComponent.prototype, "activeTab$", {
        get: function () {
            return this.activeTab.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    FootballLiveScoreWrapperComponent.prototype.initModel = function (model) {
        var _this = this;
        this.liveStandings$ = interval(5000)
            .pipe(takeUntil(this.gameOver), switchMap(function () {
            return _this.footballService.getFixture(+model.data.value)
                .pipe(tap(function (res) {
                if (!res.isLive) {
                    _this.gameOver.next(true);
                }
                else {
                    var t = tslib_1.__spread(_this.tabModels);
                    t[0].name = 'Jetzt Live';
                    _this.tabModels = t;
                }
            }));
        }));
        this.liveGames$ = this.footballService.getDailyLiveScores();
    };
    FootballLiveScoreWrapperComponent.prototype.switchTab = function (tab) {
        this.activeTab.next(tab.id);
    };
    return FootballLiveScoreWrapperComponent;
}());
export { FootballLiveScoreWrapperComponent };
