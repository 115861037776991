import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {IDynamicComponent} from '../../../../model/payload';
import {ChannelSearchService} from '../../../../shared/service/channelSearch.service';
import {LinkBarModel} from '../../../../model/component/linkBar';
import {PortalService} from '../../../../shared/service/portal.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'searchResultTopics',
  templateUrl: 'resultTopics.component.html'
})
export class ResultTopicsComponent implements DynamicComponent<IDynamicComponent> {

  topics$: Observable<LinkBarModel[]>;
  linkBarModel: LinkBarModel;

  constructor(private searchService: ChannelSearchService, private portalService: PortalService) {
  }

  initModel(model: IDynamicComponent): void {
    this.topics$ = this.searchService.searchResult.pipe(map(results => {
      return LinkBarModel.fromTopics(results.topics, this.portalService);
    }));
    this.linkBarModel = new LinkBarModel('Zum Thema', '', true);
  }
}
