/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/cardSlider/cardSlider.component.ngfactory";
import * as i2 from "../../../common/cardSlider/cardSlider.component";
import * as i3 from "../../../../shared/service/teasers.service";
import * as i4 from "../../../../shared/service/portal.service";
import * as i5 from "./singleSlider.component";
var styles_SingleSliderComponent = [];
var RenderType_SingleSliderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SingleSliderComponent, data: {} });
export { RenderType_SingleSliderComponent as RenderType_SingleSliderComponent };
export function View_SingleSliderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cardslider", [], null, null, null, i1.View_CardSliderComponent_0, i1.RenderType_CardSliderComponent)), i0.ɵdid(1, 114688, null, 0, i2.CardSliderComponent, [i3.TeasersService, i4.PortalService], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SingleSliderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "singleslider", [], null, null, null, View_SingleSliderComponent_0, RenderType_SingleSliderComponent)), i0.ɵdid(1, 49152, null, 0, i5.SingleSliderComponent, [], null, null)], null, null); }
var SingleSliderComponentNgFactory = i0.ɵccf("singleslider", i5.SingleSliderComponent, View_SingleSliderComponent_Host_0, {}, {}, []);
export { SingleSliderComponentNgFactory as SingleSliderComponentNgFactory };
