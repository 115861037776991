import {Component} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {ITitleBox} from '../../../../model/channel/titleBox/payload';
import {LinkBarModel} from '../../../../model/component/linkBar';
import {BoxModel} from '../../../../model/component/box';

@Component({
  selector: 'channelbranding',
  templateUrl: './titleBox.component.html',
})
export class TitleBoxComponent implements DynamicComponent<ITitleBox> {

  linkbarModel: LinkBarModel;
  box: BoxModel;

  initModel(model: ITitleBox): void {
    this.linkbarModel = LinkBarModel.fromTitleBox(model);
    this.box = new BoxModel(model);
  }

}
