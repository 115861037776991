import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {BaseFormControlComponent} from '../../baseFormControl.component';

@Component({
  selector: 'formcheckbox',
  templateUrl: './checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxComponent extends BaseFormControlComponent {

  @Input() value: boolean;
  @Output() valueChange = new EventEmitter<boolean>();

  onChange() {
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }

}
