/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./football-live-scores/football-live-scores.component.ngfactory";
import * as i2 from "./football-live-scores/football-live-scores.component";
import * as i3 from "@angular/common";
import * as i4 from "./football-live-games/football-live-games.component.ngfactory";
import * as i5 from "./football-live-games/football-live-games.component";
import * as i6 from "../../../../shared/service/football.service";
import * as i7 from "../../../common/ui/svgIcon/svgIcon.component.ngfactory";
import * as i8 from "../../../common/ui/svgIcon/svgIcon.component";
import * as i9 from "../../../common/ui/tabBar/tabBar.component.ngfactory";
import * as i10 from "../../../common/ui/tabBar/tabBar.component";
import * as i11 from "./football-live-score-wrapper.component";
var styles_FootballLiveScoreWrapperComponent = [];
var RenderType_FootballLiveScoreWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FootballLiveScoreWrapperComponent, data: {} });
export { RenderType_FootballLiveScoreWrapperComponent as RenderType_FootballLiveScoreWrapperComponent };
function View_FootballLiveScoreWrapperComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-football-live-scores", [], null, null, null, i1.View_FootballLiveScoresComponent_0, i1.RenderType_FootballLiveScoresComponent)), i0.ɵdid(1, 49152, null, 0, i2.FootballLiveScoresComponent, [], { liveStandings: [0, "liveStandings"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FootballLiveScoreWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FootballLiveScoreWrapperComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.liveStandings$)); var currVal_1 = i0.ɵnov(_v.parent, 10); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_FootballLiveScoreWrapperComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-football-live-games", [], null, null, null, i4.View_FootballLiveGamesComponent_0, i4.RenderType_FootballLiveGamesComponent)), i0.ɵdid(1, 49152, null, 0, i5.FootballLiveGamesComponent, [i6.FootballService], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FootballLiveScoreWrapperComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FootballLiveScoreWrapperComponent_4)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.liveGames$)); var currVal_1 = i0.ɵnov(_v.parent, 10); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_FootballLiveScoreWrapperComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "loading-indicator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "svgicon", [], [[1, "class", 0]], null, null, i7.View_SvgIconComponent_0, i7.RenderType_SvgIconComponent)), i0.ɵdid(2, 49152, null, 0, i8.SvgIconComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Loading"]))], function (_ck, _v) { var currVal_1 = "spinner"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).class; _ck(_v, 1, 0, currVal_0); }); }
export function View_FootballLiveScoreWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "footballLiveScoresWrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "tabbar", [], null, [[null, "tabChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tabChanged" === en)) {
        var pd_0 = (_co.switchTab($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_TabBarComponent_0, i9.RenderType_TabBarComponent)), i0.ɵdid(2, 114688, null, 0, i10.TabBarComponent, [], { model: [0, "model"] }, { tabChanged: "tabChanged" }), (_l()(), i0.ɵeld(3, 0, null, null, 6, null, null, null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i3.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FootballLiveScoreWrapperComponent_1)), i0.ɵdid(7, 278528, null, 0, i3.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FootballLiveScoreWrapperComponent_3)), i0.ɵdid(9, 278528, null, 0, i3.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(0, [["loading", 2]], null, 0, null, View_FootballLiveScoreWrapperComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabModels; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.activeTab$)); _ck(_v, 4, 0, currVal_1); var currVal_2 = 1; _ck(_v, 7, 0, currVal_2); var currVal_3 = 2; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_FootballLiveScoreWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-football-live-score-wrapper", [], null, null, null, View_FootballLiveScoreWrapperComponent_0, RenderType_FootballLiveScoreWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i11.FootballLiveScoreWrapperComponent, [i6.FootballService], null, null)], null, null); }
var FootballLiveScoreWrapperComponentNgFactory = i0.ɵccf("app-football-live-score-wrapper", i11.FootballLiveScoreWrapperComponent, View_FootballLiveScoreWrapperComponent_Host_0, {}, {}, []);
export { FootballLiveScoreWrapperComponentNgFactory as FootballLiveScoreWrapperComponentNgFactory };
