import {DynamicComponent} from '../../common/dynamicComponent';
import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {IDynamicComponent} from '../../../model/payload';

export interface IError extends IDynamicComponent {
  code: number;
  message: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'error',
  templateUrl: 'error.component.html'
})
export class ErrorComponent implements DynamicComponent<IError> {
  code: number;
  message: string;

  initModel(model: IError): void {
    this.code = model.code;
    this.message = model.message;
  }

}
