import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../common/dynamicComponent';
import {IDynamicComponent} from '../../model/payload';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'empty',
  template: '',
})
export class EmptyComponent implements DynamicComponent<IDynamicComponent> {
  initModel(model: IDynamicComponent): void {
  }
}
