import {ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from './component/app.component';
import {AppModule} from './component/app.module';
import {BrowserTransferStateModule} from '@angular/platform-browser';
import {PortalRoute} from './model/teaser/portalRoute';
import {
  CLEVERPUSH_CHANNELS,
  CORAL_PROD,
  CURRENT_ROUTE_URI,
  ENDPOINT_OPTIONS,
  GLOBAL_STYLES,
  PIANO_OPTIONS,
  PROD_MODE,
  PUSHER_APP_KEY,
  ROUTE_INFO,
  TV_API,
} from './shared/service/tokens';
import {environment} from '../environments/environment';
import {StylesBrowserService} from './shared/service/styles/styles.browser.service';
import {TrackJsErrorHandler} from './shared/service/trackjs.handler';
import {BrowserRefBrowser} from './shared/service/window/browserRef.browser';
import {BrowserRef} from './shared/service/browser.ref';

export function getCurrentRouteUri(): string {
  const hostname = window.location.hostname;
  const path = window.location.pathname;
  const currentRoute = PortalRoute.fromHostname(hostname, path);
  return currentRoute.uri();
}

@NgModule({
  imports: [
    AppModule,
    BrowserTransferStateModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: CURRENT_ROUTE_URI, useFactory: getCurrentRouteUri},
    {provide: ROUTE_INFO, useValue: null},
    {provide: CLEVERPUSH_CHANNELS, useValue: environment.cleverpush_channels},
    {provide: ENDPOINT_OPTIONS, useValue: environment.endpoint_options},
    {provide: PIANO_OPTIONS, useValue: environment.piano_options},
    {provide: PROD_MODE, useValue: environment.production},
    {provide: PUSHER_APP_KEY, useValue: environment.pusher_app_key},
    {provide: BrowserRef, useClass: BrowserRefBrowser},
    {provide: GLOBAL_STYLES, useClass: StylesBrowserService},
    {provide: ErrorHandler, useClass: TrackJsErrorHandler},
    {provide: TV_API, useValue: environment.tv_api},
    {provide: CORAL_PROD, useValue: environment.coral_prod},
  ]
})
export class AppBrowserModule {
}
