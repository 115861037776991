import {PortalRoute} from '../../model/teaser/portalRoute';
import {IRoute} from '../../model/payload';
import {Injectable} from '@angular/core';
import {BrowserRef} from '@shared/service/browser.ref';
import {PortalService} from '@shared/service/portal.service';
import {InjectScriptsService} from '@shared/service/injectScripts.service';
import {RouteService} from './route.service';
import {StateService} from './state.service';
import {filter, first, map, skip} from 'rxjs/operators';

export const GTM_HAS_USER_KEY = 'hasCommunityUser';
export const GTM_SUBSCRIPTION_LEVEL_ANONYMOUS = 'anonymous';
export const GTM_SUBSCRIPTION_LEVEL_REGISTERED = 'registered';
export const GTM_SUBSCRIPTION_LEVEL_PAID = 'paid';
export const GTM_SUBSCRIPTION_LEVEL_TRIAL = 'trial';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {

  protected static mapPageTitle(route: IRoute): IRoute {
    if (route.dataLayer && !route.dataLayer.Seitentitel) {
      route.dataLayer.Seitentitel = route.title;
    }
    return route;
  }

  constructor(private browserRef: BrowserRef,
              private portalService: PortalService,
              private injectScripts: InjectScriptsService,
              private routeService: RouteService,
              private stateService: StateService<string>) {
  }

  public pushEvent(name, data) {

    const base = {
      event: name,
    };
    const dataLayer = Object.assign(base, data);

    this.pushDataLayerOnBrowser(dataLayer);
  }

  public init() {
    if (this.stateService.isFirstApp) {
      this.routeService.routeInfo.pipe(
        first(Boolean),
        map(GoogleTagManagerService.mapPageTitle),
      ).subscribe(route => {
        this.appendDataLayerToBody(route);
        this.appendContainersToBody(route);
      });
    }

    this.routeService.routeInfo
      .pipe(
        filter(Boolean),
        skip(1),
        map(GoogleTagManagerService.mapPageTitle),
      ).subscribe(route => {
      if (route.dataLayer) {
        const dataLayer = route.dataLayer;
        this.pushDataLayerOnBrowser(dataLayer);
      }
      const virtualPageview = this.createVirtualPageViewEvent(route);
      this.pushDataLayerOnBrowser(virtualPageview);
      this.pushEvent('CFS - dataLayer', {});
    });
  }

  public pushDataLayerOnBrowser(data) {
    if (typeof window === 'object') {
      // tslint:disable-next-line:no-string-literal
      const dl = this.browserRef.window['dataLayer'] = this.browserRef.window['dataLayer'] || [];
      dl.push(data);
    }
  }

  protected appendContainersToBody(route: IRoute) {
    if (route.analytics && route.analytics.gtm) {
      route.analytics.gtm
        // filtering out the ad container
        .forEach(containerId => {
          const tag = {
            innerHTML: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer='defer';j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${containerId}');
            `
          };
          this.injectScripts.appendToBodyOnce(tag);
        });
    }
  }

  protected appendDataLayerToBody(route: IRoute) {
    const dataLayer = route.dataLayer || {};
    if (route.analytics && route.analytics.ga360) {
      dataLayer['CFS UA-ID'] = route.analytics.ga360;
    }
    if (route.analytics && route.analytics.ga4_measurement) {
      dataLayer['GA4 Tracking ID'] = route.analytics.ga4_measurement;
    }
    const virtualPageview = this.createVirtualPageViewEvent(route);
    const kamDataLayerEvent = {event: 'CFS - dataLayer'};
    const dataLayerTag = {
      innerHTML: `
              // init
              window.dataLayer = window.dataLayer || [];
              dataLayer.push(${JSON.stringify(dataLayer)});
              // gdprMode
              var vaToken = (function(cname) {
                var re = new RegExp('[; ]' + cname + '=([^\\\\s;]*)');
                var sMatch = (' ' + document.cookie).match(re);
                if (cname && sMatch) return decodeURIComponent(sMatch[1]);
                return '';})('va_token');
              var hasConsented = localStorage.getItem('gdprCookieConsent') === 'true' ||
                sessionStorage.getItem('gdprCookieConsent') === 'true';
              var gdprMode = (vaToken === 'true') ? 'paid' : (hasConsented ? 'consented' : 'new');
              dataLayer.push({'gdprMode': gdprMode});
              // SubscriptionLevel
              var subscriptionLevel = (function(key){
                var storedItem = localStorage.getItem(key) || sessionStorage.getItem(key);
                return storedItem && storedItem === 'yes' ? '${GTM_SUBSCRIPTION_LEVEL_REGISTERED}' : '${GTM_SUBSCRIPTION_LEVEL_ANONYMOUS}';
              })('${GTM_HAS_USER_KEY}');
              dataLayer.push({'SubscriptionLevel': subscriptionLevel});
              // virtualPageview
              dataLayer.push(${JSON.stringify(virtualPageview)});
              // KAM kamDataLayerEvent
              dataLayer.push(${JSON.stringify(kamDataLayerEvent)});`,
    };
    this.injectScripts.appendToBodyOnce(dataLayerTag);
  }

  protected createVirtualPageViewEvent(route: IRoute) {
    const path = route.uri ? PortalRoute.fromUri(route.uri).path : this.portalService.currentPortal();
    return {event: 'virtualPageview', Pfad: path, Artikelname: route.title};
  }

}
