import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {TeasersService} from '@shared/service/teasers.service';
import {ITeaser} from '@model/teaser/payload';
import {BehaviorSubject, merge, Observable, of} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {LinkBarModel} from '@model/component/linkBar';
import {TeaserView} from '@model/teaser/teaserView';
import {BoxModel} from '@model/component/box';
import {ILongList} from '@model/component/longlist/payload';
import {GTMTriggerId} from '@model/enum/gtm';
import {TeaserModel} from '@model/teaser/teaser';
import {DeviceDetectionService} from '@shared/service/device-detection.service';
import {PortalService} from '@shared/service/portal.service';
import {ImageType} from '@model/enum/image';
import {ImageModel} from '@model/component/image';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'longlist',
  templateUrl: 'longlist.component.html'
})
export class LongListComponent implements DynamicComponent<ILongList>, OnChanges {

  @Input() model: ILongList;

  teasers$: Observable<ITeaser[]>;
  mediaModels: TeaserModel[] = [];
  linkBarModel: LinkBarModel;
  box: BoxModel;
  gtmId = GTMTriggerId.more_button;

  private fetchTeasers$ = new BehaviorSubject<boolean>(false);
  private teasers: ITeaser[] = [];
  private collectionEmpty = false;

  constructor(
    protected teaserService: TeasersService,
    private mobileDetectionService: DeviceDetectionService,
    private portalService: PortalService,
  ) {
  }

  get showMore(): boolean {
    return !this.collectionEmpty && this.teasers.length < +this.model.numberOfTeasers + +this.model.additionalTeaserStep;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initModel(this.model);
  }

  initModel(model: ILongList): void {
    this.model = model;
    this.model.cta = this.model.cta || 'Mehr Schlagzeilen';
    if (model.items) {
      this.teasers = model.items;
      this.initFetchTeaser(of(this.teasers));
    } else if (this.model.collectionName && +this.model.numberOfTeasers) {
      this.initFetchTeaser(this.getCollection(+this.model.numberOfTeasers));
    }
  }

  initFetchTeaser(initial: Observable<ITeaser[]>) {
    this.teasers$ = merge(
      initial,
      this.fetchTeasers$.pipe(
        filter(Boolean),
        switchMap(() => this.getCollection(+this.model.additionalTeaserStep)),
      ),
    ).pipe(
      tap(this.setModels.bind(this)),
    );
  }

  getCollection(numberOfTeasers: number): Observable<ITeaser[]> {
    return this.teaserService.getCollection(this.model.collectionName, numberOfTeasers).pipe(
      map(teasers => {
        this.collectionEmpty = teasers.length < numberOfTeasers;
        this.teasers = this.teasers.concat(teasers);
        return this.teasers;
      }),
    );
  }

  fetchMoreEntries() {
    this.fetchTeasers$.next(true);
    return false;
  }

  setModels(teasers) {
    this.mediaModels = TeaserModel.fromMediaTeasers(
      teasers,
      this.model.teaserView || new TeaserView(),
      this.mobileDetectionService.isMobile,
      this.model,
    )
      .map(m => {
        m.gtmId = this.model.gtmId;
        return m;
      });

    this.linkBarModel = this.model.title || this.model.image ?
      new LinkBarModel(this.model.title, this.model.titleLink, true, GTMTriggerId.teaser_headline) :
      null;
    if (this.linkBarModel && this.model.image) {
      this.linkBarModel.image = new ImageModel(this.model.image, ImageType.LS_logo);
    }

    const stBox = new BoxModel();
    if (this.mediaModels.length === 0) {
      this.box = stBox;
      return;
    }
    const box = this.mediaModels[0].box;
    if (this.mediaModels.every(m => m.box.channelId === box.channelId)) {
      this.box = box;
    } else {
      this.box = stBox;
    }
  }

  trackMedia(index: number, item: TeaserModel) {
    return index;
  }
}
