import {Injectable, OnDestroy} from '@angular/core';
import {RouteService} from './route.service';
import {Meta} from '@angular/platform-browser';
import {LinkService} from './link.service';
import {IRoute} from '../../model/payload';
import {Subject} from 'rxjs';
import {filter, skip, takeUntil} from 'rxjs/operators';
import {StateService} from './state.service';

@Injectable({
  providedIn: 'root',
})
export class AppHeadService implements OnDestroy {

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private routeService: RouteService,
              private stateService: StateService<string>,
              private meta: Meta,
              private linkService: LinkService) {
  }

  public init() {
    this.routeService.routeInfo
      .pipe(
        filter(Boolean),
        skip(1),
        takeUntil(this.destroy$)
      ).subscribe(route => {
      this.cleanHead(route);
    });

    // if not firstApp, skip first (as it was already set in firstApp)
    const skipSetHead = this.stateService.isFirstApp ? 0 : 1;
    this.routeService.routeInfo
      .pipe(
        filter(Boolean),
        skip(skipSetHead),
        takeUntil(this.destroy$)
      ).subscribe(route => {
      this.setHead(route);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  protected setHead(route: IRoute) {
    if (!route.head) {
      return;
    }

    if (route.head.links) {
      this.linkService.addTags(route.head.links);
    }

    if (route.head.metas && route.head.metas.length > 0) {
      this.meta.addTags(route.head.metas);
    }
  }

  protected cleanHead(route: IRoute) {
    const uniqEs6 = (arrArg) => arrArg.filter((elem, pos, arr) => arr.indexOf(elem) === pos);

    const fixedMetaToRemove = {
      name: [
        'author',
        'description',
        'twitter:creator',
        'twitter:card',
        'twitter:site',
      ],
      property: [
        'og:url',
        'og:title',
        'og:description',
        'og:image',
        'og:image:alt',
        'og:image:width',
        'og:image:height',
        'og:type',
      ],
    };

    const routeHeadMetas = route.head && route.head.metas ? route.head.metas : [];

    Object.getOwnPropertyNames(fixedMetaToRemove).forEach((key: string) => {
      const fixedMetas = fixedMetaToRemove[key];
      const newMetas = routeHeadMetas.filter(tag => tag.hasOwnProperty(key)).map(tag => tag[key]);
      const allMetas = [].concat(fixedMetas, newMetas);
      const allMetasUnique = uniqEs6(allMetas);

      allMetasUnique.forEach(value => {
        const attrSelector = `${key}="${value}"`;
        return this.meta.removeTag(attrSelector);
      });
    });
  }

}
