import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'searchinput',
  templateUrl: './searchInput.component.html',
})
export class SearchInputComponent implements OnInit {

  @ViewChild('search', { static: true }) searchInputRef: ElementRef;
  @ViewChild('searchResult', { static: true }) searchResultRef: ElementRef;

  @Input() placeholder: string;
  @Input() label: string;
  @Input() elmClass: string;
  @Input() clearOnClick = false;
  @Input() setFocus = false;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSelected = new EventEmitter<number>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSearch = new EventEmitter<string>();
  @Input() onSearchButton: () => void;

  @Input() searchInput = '';

  private selectedResultIndex = -1;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit() {
    if (this.setFocus && this.searchInputRef.nativeElement.focus) {
      this.searchInputRef.nativeElement.focus();
    }
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onSelected.emit(this.selectedResultIndex);
      if (this.selectedResultIndex !== -1) {
        this.clearInput();
      }
    }
  }

  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.clearInput();
      return;
    }

    if (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Down' || event.key === 'Up') {
      const elms = this.searchResultRef.nativeElement.getElementsByClassName(this.elmClass);
      let elm: HTMLElement;
      if (this.selectedResultIndex >= 0) {
        elm = elms[this.selectedResultIndex];
        elm.blur();
        this.renderer.removeClass(elm, 'item-selected');
      }
      const dir = event.key === 'ArrowDown' || event.key === 'Down' ? 1 : -1;
      this.selectedResultIndex += dir;
      if (this.selectedResultIndex < 0) {
        this.selectedResultIndex = -1;
        return;
      } else if (this.selectedResultIndex >= elms.length) {
        this.selectedResultIndex = elms.length - 1;
      }
      elm = elms[this.selectedResultIndex];
      elm.focus();
      this.renderer.addClass(elm, 'item-selected');

      // TODO this should be in a directive so we can reuse the behavior
      const h = this.searchResultRef.nativeElement.offsetHeight;
      if (dir === 1) {
        if (h < (this.selectedResultIndex + 1) * elm.offsetHeight) {
          this.searchResultRef.nativeElement.scrollTop += elm.offsetHeight;
        }
      } else {
        if (this.searchResultRef.nativeElement.scrollTop >= (this.selectedResultIndex + 1) * elm.offsetHeight) {
          this.searchResultRef.nativeElement.scrollTop -= elm.offsetHeight;
        }
      }
      return;
    }
    this.termChanged((event.target as HTMLInputElement).value);
  }

  clearInput(resetFocus = true) {
    if (resetFocus) {
      this.searchInputRef.nativeElement.blur();
    }
    this.searchInput = '';
    this.selectedResultIndex = -1;
    this.termChanged('');
  }

  termChanged(term: string) {
    if (term.length >= 2 || term === '') {
      this.onSearch.emit(term);
    }
    this.selectedResultIndex = -1;
  }

  @HostListener('document:click')
  clickedOutside() {
    if (!this.clearOnClick) {
      return;
    }
    this.clearInput(false);
  }

  @HostListener('click', ['$event'])
  clickedInside($event) {
    $event.stopPropagation();
  }
}
