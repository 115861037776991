/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/video/video.component.ngfactory";
import * as i2 from "../../../common/video/video.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../no-consent/no-consent.component.ngfactory";
import * as i5 from "../no-consent/no-consent.component";
import * as i6 from "../../../../shared/service/didomi.service";
import * as i7 from "@angular/common";
import * as i8 from "./youtube.component";
var styles_YouTubeComponent = [];
var RenderType_YouTubeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_YouTubeComponent, data: {} });
export { RenderType_YouTubeComponent as RenderType_YouTubeComponent };
function View_YouTubeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "article-videoContainer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "videoComp", [], null, null, null, i1.View_VideoComponent_0, i1.RenderType_VideoComponent)), i0.ɵdid(2, 114688, null, 0, i2.VideoComponent, [i3.DomSanitizer], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoModel; _ck(_v, 2, 0, currVal_0); }, null); }
function View_YouTubeComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i4.View_NoConsentComponent_0, i4.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i5.NoConsentComponent, [i6.DidomiService], { vendor: [0, "vendor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_YouTubeComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_YouTubeComponent_1)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["noContent", 2]], null, 0, null, View_YouTubeComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.showYouTube$)); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_YouTubeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "youtube", [], null, null, null, View_YouTubeComponent_0, RenderType_YouTubeComponent)), i0.ɵdid(1, 49152, null, 0, i8.YouTubeComponent, [i6.DidomiService], null, null)], null, null); }
var YouTubeComponentNgFactory = i0.ɵccf("youtube", i8.YouTubeComponent, View_YouTubeComponent_Host_0, {}, {}, []);
export { YouTubeComponentNgFactory as YouTubeComponentNgFactory };
