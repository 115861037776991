import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BrowserRef} from '@shared/service/browser.ref';
import {PianoService} from '@shared/service/piano/piano.service';
import {ReplaySubject} from 'rxjs';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {StateService} from '@shared/service/state.service';
import {CORAL_PROD} from '@shared/service/tokens';
import {LoadExternalScriptService} from '@shared/service/loadExternalScript.service';
import {SCRIPT_MAP} from '@model/const/script';
import {ScriptId} from '@model/enum/scriptId';
import {take, tap} from 'rxjs/operators';
import {IRoute} from '@model/payload';
import {
  ICreateCommentReactionSuccess,
  ICreateCommentReplySuccess,
  ICreateCommentSuccess,
  ISetCommentsOrderBy,
  IShowReplyForm
} from '@model/coral/payload';
import {PortalRoute} from '@model/teaser/portalRoute';
import {PortalService} from '@shared/service/portal.service';

@Injectable({
  providedIn: 'root'
})
export class CoralService {
  private coral = new ReplaySubject<{
    login: (token: string) => void;
    logout: () => void;
    on: (t: string, cb: () => void) => void;
  }>();
  private readonly rootURL: string;

  constructor(
    private browserRef: BrowserRef,
    private piano: PianoService,
    private loadScript: LoadExternalScriptService,
    @Inject(DOCUMENT) private document,
    stateService: StateService<string>,
    private portalService: PortalService,
    @Inject(PLATFORM_ID) protected platformId: string,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('message', (event) => {
        if (event.data && event.data.event === 'reportContent') {
          const url = window.location.href.split('#')[0] + '#comment-' + event.data.commentId;
          window.location.href = 'https://kurier.at/inhalt-melden?report_url=' + url;
        }
      });
    }
    const isProd = stateService.get(CORAL_PROD);
    const arr = this.portalService.hostname().split('.');
    if (!isProd) {
      arr.splice(1, 0, 'telekurier');
    }
    arr.unshift('https://community');
    this.rootURL = arr.join('.');
    SCRIPT_MAP[ScriptId.comments] = `${this.rootURL}/assets/js/embed.js?qwertzuiop`;
  }

  get coral$() {
    return this.coral.asObservable();
  }

  private sendToGa(gaId: string, eventType: string, event?: any) {
    // @ts-ignore
    window.ga('create', gaId, 'auto');
    // @ts-ignore
    window.ga('send', 'event', 'Coral', eventType, 'test');
  }

  init(route: IRoute) {
    this.loadScript.loadScript(ScriptId.comments)
      .pipe(
        take(1),
        tap(script => {
          const commentsCssSrc = this.document.querySelector('[commentsCss]').getAttribute('commentsCss') as string;
          let storyUrl = this.portalService.absoluteUrlOf(PortalRoute.fromUri(route.uri));
          if (this.portalService.currentPortal() === 'filmat') {
            storyUrl = storyUrl.replace('https://', 'https://www.');
          }
          const coral = script.scriptVar.createStreamEmbed({
            id: 'coral_thread',
            autoRender: true,
            rootURL: this.rootURL,
            bodyClassName: 'coralComments-kurier', // add class so we can style events
            // Uncomment these lines and replace with the ID of the
            // story's ID and URL from your CMS to provide the
            // tightest integration. Refer to our documentation at
            // https://docs.coralproject.net for all the configuration
            // options.
            // storyID: route.dataLayer.ArtikelId -> this leads to duplicate story id error,
            // storyID: '${storyID}',
            storyURL: storyUrl,
            customCSSURL: window.location.protocol + '//' + window.location.host + '/' + commentsCssSrc,
            enableDeprecatedEvents: true,
          });
          coral.on('showAuthPopup', () => {
            this.piano.showLogin();
          });
          coral.on('mutation.signOut', () => {
            this.piano.doLogout();
          });
          coral.on('createComment.success', (e: ICreateCommentSuccess) => {
            this.sendToGa(route.analytics.ga360, 'createComment', e.success.id);
          });
          coral.on('createCommentReply.success', (e: ICreateCommentReplySuccess) => {
            this.sendToGa(route.analytics.ga360, 'createCommentReply', e.success.id);
          });
          coral.on('createCommentReaction.success', (e: ICreateCommentReactionSuccess) => {
            this.sendToGa(route.analytics.ga360, 'createCommentReaction', e.commentID);
          });
          coral.on('setCommentsOrderBy', (e: ISetCommentsOrderBy) => {
            this.sendToGa(route.analytics.ga360, 'setCommentsOrderBy', e.orderBy);
          });
          coral.on('showReplyForm', (e: IShowReplyForm) => {
            this.sendToGa(route.analytics.ga360, 'showReplyForm', e.commentID);
          });
          this.coral.next(coral);
        }),
      ).subscribe();
  }
}
