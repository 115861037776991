<div class="search">
  <form (keypress)="onKeyPress($event)" (keyup)="onKeyUp($event)" class="search-form">
    <input #search [(ngModel)]="searchInput" type="text" name="suche" autocomplete="off" placeholder="{{placeholder}}">
    <span *ngIf="label" class="label">{{label}}</span>
    <button *ngIf="onSearchButton" type="submit" (click)="onSearchButton()">
      <iconbutton [icon]="'search'"></iconbutton>
    </button>
  </form>

  <div class="search-results" #searchResult>
    <ng-content></ng-content>
  </div>
</div>
