<div *ngIf="model && (model.text || model.logo || model.image)" [class.isTitle]="model.isTitle" class="linkBar">
  <ng-container *ngIf="model.showLink; else content">
    <a [portalRouterLink]="model.link" class="linkBarLink" [gtm]="model.gtmId">
      <ng-container *ngTemplateOutlet="content"></ng-container>
      <div *ngIf="!model.logo" class="linkBar-right">
        <iconbutton [icon]="'angle-right'"></iconbutton>
      </div>
    </a>
  </ng-container>

  <ng-template #content>
    <picimage class="linkBar-image" *ngIf="model.image" [model]="model.image"></picimage>
    <div *ngIf="model.text" class="linkBar-left">
      <h2 class="linkBar-title">
        {{model.text}}
      </h2>
    </div>
    <picimage class="linkBar-logo" *ngIf="model.logo" [model]="model.logo"></picimage>
  </ng-template>
</div>
