import {ChannelId} from '../model/enum/channelId';
import {Channel} from '../model/teaser/channel';

export function channelIdFromChannel(channel: Channel): ChannelId {
  if (!channel.name || channel.name.startsWith('http')) {
    return null;
  }
  const split = channel.name.split('/');
  const returnChannel = split[0];
  const channelId = ChannelId[returnChannel.toLowerCase().replace(/\s+/g, '-')];
  return channelId || ChannelId.none;
}

export function channelIdfromMenu(link: string): ChannelId {
  if (link) {
    if (link.startsWith('http')) {
      return ChannelId.none;
    }
    const mainChannel = link.split('/');
    return ChannelId[mainChannel[2]] || ChannelId.none;
  } else {
    return ChannelId.none;
  }
}
