/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/formControls/select/select.component.ngfactory";
import * as i2 from "../../../common/ui/formControls/select/select.component";
import * as i3 from "../../common/movieSerieTeaser/movie-serie-teaser.component.ngfactory";
import * as i4 from "../../common/movieSerieTeaser/movie-serie-teaser.component";
import * as i5 from "@angular/common";
import * as i6 from "../../../common/image/image.component.ngfactory";
import * as i7 from "../../../common/image/image.component";
import * as i8 from "../../../../shared/service/image.service";
import * as i9 from "../../../../shared/service/overlay.service";
import * as i10 from "../../../../shared/directive/portalRoute.directive";
import * as i11 from "@angular/router";
import * as i12 from "../../../../shared/service/portal.service";
import * as i13 from "../../../../shared/service/browser.ref";
import * as i14 from "../../../common/ui/formControls/input/input.component.ngfactory";
import * as i15 from "../../../common/ui/formControls/input/input.component";
import * as i16 from "../../../common/ui/iconButton/iconButton.component.ngfactory";
import * as i17 from "../../../common/ui/iconButton/iconButton.component";
import * as i18 from "../../../common/ui/paginator/paginator.component.ngfactory";
import * as i19 from "../../../common/ui/paginator/paginator.component";
import * as i20 from "./movieList.component";
import * as i21 from "../../../../shared/service/api.service";
import * as i22 from "../../../../shared/service/gtm.service";
import * as i23 from "../../../../shared/service/oewa.service";
var styles_MovieListComponent = [];
var RenderType_MovieListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MovieListComponent, data: {} });
export { RenderType_MovieListComponent as RenderType_MovieListComponent };
function View_MovieListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formselect", [], null, [[null, "valueSelectedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueSelectedChange" === en)) {
        var pd_0 = ((_co.genreSelected = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueSelectedChange" === en)) {
        var pd_1 = (_co.onGenreChange() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SelectComponent_0, i1.RenderType_SelectComponent)), i0.ɵdid(1, 49152, null, 0, i2.SelectComponent, [], { label: [0, "label"], values: [1, "values"], valueSelected: [2, "valueSelected"] }, { valueSelectedChange: "valueSelectedChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Genre"; var currVal_1 = _co.genres; var currVal_2 = _co.genreSelected; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MovieListComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "movieteaser", [], null, null, null, i3.View_MovieSerieTeaserComponent_0, i3.RenderType_MovieSerieTeaserComponent)), i0.ɵdid(1, 573440, null, 0, i4.MovieSerieTeaserComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_MovieListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MovieListComponent_4)), i0.ɵdid(2, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MovieListComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "picimage", [], null, null, null, i6.View_ImageComponent_0, i6.RenderType_ImageComponent)), i0.ɵdid(1, 638976, null, 0, i7.ImageComponent, [i8.ImageService, i9.OverlayService, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.poster; _ck(_v, 1, 0, currVal_0); }, null); }
function View_MovieListComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "no-img"]], null, null, null, null, null))], null, null); }
function View_MovieListComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "a", [["class", "movieList-list-poster"]], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(3, 540672, null, 0, i10.PortalRouteDirective, [i11.Router, i0.ElementRef, i12.PortalService, i13.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MovieListComponent_7)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MovieListComponent_8)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = "movieList-list-poster"; var currVal_3 = _v.context.$implicit.classes; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.link; _ck(_v, 3, 0, currVal_4); var currVal_5 = _v.context.$implicit.poster; _ck(_v, 5, 0, currVal_5); var currVal_6 = !_v.context.$implicit.poster; _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_MovieListComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MovieListComponent_6)), i0.ɵdid(2, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MovieListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 29, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 28, "section", [["class", "movieList"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 18, "header", [["class", "movieList-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [["class", "movieList-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "movieList-filter"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "forminput", [], null, [[null, "valueChange"], [null, "inputChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.term = $event) !== false);
        ad = (pd_0 && ad);
    } if (("inputChanged" === en)) {
        var pd_1 = (_co.onTermChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i14.View_InputComponent_0, i14.RenderType_InputComponent)), i0.ɵdid(7, 114688, null, 0, i15.InputComponent, [], { label: [0, "label"], type: [1, "type"], value: [2, "value"], placeholder: [3, "placeholder"] }, { valueChange: "valueChange", inputChanged: "inputChanged" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MovieListComponent_2)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 10, "div", [["class", "movieList-viewSwitcher"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 4, "button", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleListView(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "iconbutton", [["icon", "th-list"]], null, null, null, i16.View_IconButtonComponent_0, i16.RenderType_IconButtonComponent)), i0.ɵdid(13, 114688, null, 0, i17.IconButtonComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Listen Ansicht"])), (_l()(), i0.ɵeld(16, 0, null, null, 4, "button", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleListView(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, "iconbutton", [["icon", "th-tiles"]], null, null, null, i16.View_IconButtonComponent_0, i16.RenderType_IconButtonComponent)), i0.ɵdid(18, 114688, null, 0, i17.IconButtonComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(19, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Kachel Ansicht"])), (_l()(), i0.ɵeld(21, 0, null, null, 8, "paginator", [], null, [[null, "onPageChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onPageChanged" === en)) {
        var pd_0 = (_co.onPageChanged() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i18.View_PaginatorComponent_0, i18.RenderType_PaginatorComponent)), i0.ɵdid(22, 638976, null, 0, i19.PaginatorComponent, [], { model: [0, "model"] }, { onPageChanged: "onPageChanged" }), (_l()(), i0.ɵeld(23, 0, null, 0, 6, "div", [["class", "movieList-list"]], null, null, null, null, null)), i0.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(25, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MovieListComponent_3)), i0.ɵdid(27, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MovieListComponent_5)), i0.ɵdid(29, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "Suche"; var currVal_2 = "text"; var currVal_3 = _co.term; var currVal_4 = "Suchbegriff"; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = !_co.model.genre; _ck(_v, 9, 0, currVal_5); var currVal_7 = "th-list"; _ck(_v, 13, 0, currVal_7); var currVal_9 = "th-tiles"; _ck(_v, 18, 0, currVal_9); var currVal_10 = _co.paginatorModel; _ck(_v, 22, 0, currVal_10); var currVal_11 = "movieList-list"; var currVal_12 = (_co.listViewList.getValue() ? "list" : "columns"); _ck(_v, 25, 0, currVal_11, currVal_12); var currVal_13 = _co.listViewList.getValue(); _ck(_v, 27, 0, currVal_13); var currVal_14 = !_co.listViewList.getValue(); _ck(_v, 29, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.title; _ck(_v, 4, 0, currVal_0); var currVal_6 = _co.listViewList.getValue(); _ck(_v, 11, 0, currVal_6); var currVal_8 = !_co.listViewList.getValue(); _ck(_v, 16, 0, currVal_8); }); }
export function View_MovieListComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_MovieListComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.movies$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MovieListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "movielist", [], null, null, null, View_MovieListComponent_0, RenderType_MovieListComponent)), i0.ɵdid(1, 49152, null, 0, i20.MovieListComponent, [i11.ActivatedRoute, i21.ApiService, i22.GoogleTagManagerService, i23.OewaService], null, null)], null, null); }
var MovieListComponentNgFactory = i0.ɵccf("movielist", i20.MovieListComponent, View_MovieListComponent_Host_0, {}, {}, []);
export { MovieListComponentNgFactory as MovieListComponentNgFactory };
