/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/image/image.component.ngfactory";
import * as i2 from "../../../common/image/image.component";
import * as i3 from "../../../../shared/service/image.service";
import * as i4 from "../../../../shared/service/overlay.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../common/slider/slider.component.ngfactory";
import * as i7 from "../../../common/slider/slider.component";
import * as i8 from "../../../../shared/service/tokens";
import * as i9 from "../../../../shared/service/portal.service";
import * as i10 from "../../../../shared/service/slider.service";
import * as i11 from "./gallerySlider.component";
import * as i12 from "../../../../shared/service/api.service";
var styles_GallerySliderComponent = [];
var RenderType_GallerySliderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GallerySliderComponent, data: {} });
export { RenderType_GallerySliderComponent as RenderType_GallerySliderComponent };
function View_GallerySliderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "caption"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.caption; _ck(_v, 0, 0, currVal_0); }); }
function View_GallerySliderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["sliderItem", 1]], null, 4, "div", [["class", "slider-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "picimage", [], null, null, null, i1.View_ImageComponent_0, i1.RenderType_ImageComponent)), i0.ɵdid(2, 638976, null, 0, i2.ImageComponent, [i3.ImageService, i4.OverlayService, i0.ComponentFactoryResolver], { model: [0, "model"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GallerySliderComponent_3)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.image; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.caption; _ck(_v, 4, 0, currVal_1); }, null); }
function View_GallerySliderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "slider", [], null, null, null, i6.View_SliderComponent_0, i6.RenderType_SliderComponent)), i0.ɵdid(2, 3391488, null, 1, i7.SliderComponent, [i8.GLOBAL_STYLES, i0.PLATFORM_ID, i0.ChangeDetectorRef, i9.PortalService, i10.SliderService], { sliderConfigModel: [0, "sliderConfigModel"] }, null), i0.ɵqud(603979776, 1, { sliderItems: 1 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_GallerySliderComponent_2)), i0.ɵdid(5, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sliderConfigModel; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.cardModels; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_GallerySliderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_GallerySliderComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.data$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GallerySliderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "gallerySlider", [], null, null, null, View_GallerySliderComponent_0, RenderType_GallerySliderComponent)), i0.ɵdid(1, 49152, null, 0, i11.GallerySliderComponent, [i12.ApiService], null, null)], null, null); }
var GallerySliderComponentNgFactory = i0.ɵccf("gallerySlider", i11.GallerySliderComponent, View_GallerySliderComponent_Host_0, {}, {}, []);
export { GallerySliderComponentNgFactory as GallerySliderComponentNgFactory };
