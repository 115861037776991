import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {CoralService} from '@shared/service/coral.service';
import {PianoService} from '@shared/service/piano/piano.service';
import {tap} from 'rxjs/operators';
import {combineLatest, Subscription} from 'rxjs';
import {IRoute} from '@model/payload';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-coral-comments',
  templateUrl: './coral-comments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoralCommentsComponent implements OnInit, OnDestroy {

  private userSubscription = new Subscription();

  constructor(
    private coralService: CoralService,
    private piano: PianoService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.userSubscription.add(
      this.activatedRoute.data.pipe(
        tap((data: { route: IRoute }) => {
          this.coralService.init(data.route);
        }),
      ).subscribe(),
    );
    this.userSubscription.add(
      combineLatest([
        this.piano.user$,
        this.coralService.coral$,
      ]).pipe(
        tap(res => {
          const user = res[0];
          const coral = res[1];
          if (user.user_token && coral) {
            coral.login(user.user_token);
          }
        }),
      ).subscribe(),
    );
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

}
