<ad1x1></ad1x1>
<div id="fb-root"></div>
<div id="ad-headerbanner" class="wb wb-header" #adSuperbanner>
  <adSuperbanner></adSuperbanner>
</div>
<headerContainer></headerContainer>
<ng-container>
  <main class="main">
    <router-outlet></router-outlet>
  </main>
  <adSkyscraper></adSkyscraper>
</ng-container>

<div [id]="mobileDetec.isTablet ? 'sticky_mobile' : 'sticky_desktop'" class="sticky_banner"></div>
<footerContainer></footerContainer>
<app-required-consent-overlay></app-required-consent-overlay>


<svg [ngStyle]="{position: 'absolute', height: '0px', width: '0px'}">
  <symbol id="kuriertv" viewBox="0 0 391 391">
    <title>Kurier.tv</title>
    <circle cx="195.5" cy="195.5" r="195.5" fill="#B60017"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M234.368 261.468L249.314 222.367L210.08 108.534L209.939 108.561L209.946 108.534H125.205L114.529 136.158V283.487H142.02V136.291H191.815L234.368 261.468ZM279.741 283.354L347 108H318.575L251.049 283.354H279.741ZM100.25 108.534H43V136.291H89.5741L100.25 108.534Z"
          fill="white"/>
  </symbol>
  <symbol id="motorat" viewBox="0 0 66.36 66.36">
    <title>Motor.at</title>
    <circle fill="#f29405" cx="33.18" cy="33.18" r="33.18"/>
    <path fill="#fff"
          d="M15.57,20H21.7l.78,2.74a18.49,18.49,0,0,1,4.18-2.22,13.47,13.47,0,0,1,5.09-.78,8.23,8.23,0,0,1,3.91.91,7,7,0,0,1,2.48,3,14.66,14.66,0,0,1,4.7-2.87,16.84,16.84,0,0,1,5.09-1c4.69,0,7,2.09,7,6.39a19.94,19.94,0,0,1-.39,3.91L52,46.63H44.66l2.61-16.44c0-.39.13-.65.13-1,0-2-1-2.87-3.26-2.87a10.08,10.08,0,0,0-6,2.35A8.58,8.58,0,0,1,38,30.45L35.4,46.63H28.1l2.6-16.31a5.56,5.56,0,0,0,.14-1.3c0-2-1.05-2.87-3.27-2.87a7.68,7.68,0,0,0-3,.52,20.86,20.86,0,0,0-2.87,1.7L18.83,46.63H11.4Z"/>
  </symbol>
  <symbol id="kurierat" viewBox="0 0 42.6 42.6">
    <title>Kurier.at</title>
    <circle id="Oval" fill="#B60017" cx="21.3" cy="21.3" r="21.3"/>
    <path id="Path-Copy-2" fill="#ffffff" d="M17.6,15.8c0-3,0.3-3.1,1.7-3.2l0.5-0.1c0.2-0.2,0.2-0.6-0.1-0.8c0,0,0,0,0,0
	c-1.2,0.1-2.5,0.1-3.8,0.1s-2.7,0-4-0.1c-0.2,0.1-0.3,0.6-0.1,0.8l0.6,0.1c1.4,0.2,1.7,0.2,1.7,3.2v10.5c0,3-0.3,3.1-1.7,3.3h-0.4
	c-0.2,0.2-0.2,0.6,0.1,0.8c0,0,0,0,0,0c1.1-0.1,2.5-0.1,3.8-0.1s2.7,0,4.1,0.1c0.2-0.2,0.3-0.6,0.1-0.8c0,0,0,0,0,0l-0.8-0.1
	c-1.4-0.2-1.7-0.2-1.7-3.2v-3.5c0-0.8-0.1-1.5,0.7-1.5c0.3,0,0.5,0.2,0.7,0.4c1.1,1.1,4.7,5.6,5.9,7.1c0.3,0.4,0.3,0.7-0.2,0.8
	l-0.8,0.1c-0.2,0.2-0.3,0.5-0.1,0.7c0,0.1,0.1,0.1,0.2,0.1c1.3-0.1,2.7-0.1,4.1-0.1s2.8,0,3.8,0.1c0.2-0.1,0.3-0.7,0.1-0.8l-0.7-0.1
	c-0.9,0-1.7-0.4-2.2-1.1c-2.2-2.4-5-5.7-7.4-8.6c-0.3-0.4-0.3-0.6,0.1-0.9c1.2-1.1,2.4-2.2,3.7-3.4c2.1-1.9,2.9-2.6,5.7-2.9
	c0.2-0.2,0.3-0.5,0.1-0.7c0-0.1-0.1-0.1-0.2-0.1c-1.1,0-2,0.1-3.3,0.1c-1.1,0-2.1,0-3.4-0.1c-0.2,0.2-0.3,0.5-0.1,0.8c0,0,0,0,0,0
	l0.9,0.2c0.4,0.1,0.3,0.3-0.2,1c-0.5,0.6-1.1,1.2-1.6,1.8l-3.1,3.1c-1,1-1.7,1.6-2.2,1.6s-0.5-0.5-0.5-1.3L17.6,15.8z"/>
  </symbol>
  <symbol id="freizeit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233.07 386.2">
    <title>Freizeit.at</title>
    <path
      d="M85 386.4h32.6L162 178.5h-32.6l1.7-7.9h32.6c1.1-8.4 2.8-16.3 4.5-23.6C187.3 56 227.8 8.2 277.8 8.2c28.1 0 42.7 20.8 37.7 46.1-3.9 16.3-16.3 27-33.2 27-12.4 0-18.5-9-15.7-22.5 3.9-18.5 30.9-20.2 32.6-30.3 1.7-6.2-6.2-11.8-21.9-11.8-28.7 0-41 26.4-53.4 83.7l-14.6 70.3h49.4l-1.7 7.9h-49.5l-44.4 207.9h32l-1.7 7.9h-110z"
      transform="translate(-83.4 -8.2)" fill="#1d1d1b"/>
  </symbol>
  <symbol id="filmat" viewBox="0 0 397 397">
    <title>Film.at</title>
    <path
      d="M199.5,398.3C90.1,398.3,1,309.2,1,199.8S90.1,1.3,199.5,1.3,398,90.3,398,199.8,309,398.3,199.5,398.3m0-320.5c-67.2,0-122,54.7-122,122s54.7,122,122,122,122-54.7,122-122a122.1,122.1,0,0,0-122-122"
      transform="translate(-1 -1.3)" fill="#d3b480"/>
    <polygon points="156.8 120.4 156.8 276.5 276.6 198.5 156.8 120.4" fill="#222"/>
  </symbol>
  <symbol id="eventsat" viewBox="0 0 51 50">
    <path
      d="M25.579 34.7048C25.4408 34.7048 25.3025 34.7048 25.2334 34.7048C19.9102 34.7048 15.5548 30.3585 15.5548 25.0464C15.5548 19.7343 19.9793 15.3881 25.2334 15.3881C30.4183 15.3881 34.7737 19.6653 34.9119 24.8395C34.9119 25.1844 35.1885 25.3914 35.5341 25.3914H41.6178C41.9634 25.3914 42.24 25.1154 42.24 24.7705C42.1017 15.595 34.4971 8.07528 25.3025 8.07528C15.9005 8.14427 8.22677 15.802 8.22677 25.0464C8.22677 34.2219 15.6239 41.7416 24.7494 41.9486H43.4152C43.5535 41.9486 43.6226 42.0866 43.4844 42.1556C43.3461 42.2935 43.2078 42.4315 43.0696 42.5695C38.6451 47.1227 32.4232 49.8822 25.579 49.8822H0.89872C0.760455 49.8822 0.691322 49.8133 0.691322 49.6753V25.4604C0.691322 11.1798 12.4439 -0.410291 26.6852 0.210605C33.1145 0.486558 38.9216 3.17711 43.1387 7.45439C47.7014 12.0076 50.4667 18.2166 50.4667 25.0464C50.4667 28.1509 49.9137 31.1864 48.8076 33.9459C48.6002 34.4288 48.1854 34.7048 47.7014 34.7048H25.579Z"
      fill="url(#paint0_linear_5801_514)"/>
    <defs>
      <linearGradient id="paint0_linear_5801_514" x1="44.7698" y1="9.25353" x2="-1.24365" y2="47.4132"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#C2197B"/>
        <stop offset="0.578125" stop-color="#7C44A2"/>
        <stop offset="1" stop-color="#3470C9"/>
      </linearGradient>
    </defs>
  </symbol>
  <symbol id="immmo" viewBox="0 0 26.77 25.52">
    <title>Immmo</title>
    <polygon fill="#f33"
             points="15.37 25.52 20.39 13.44 20.39 25.52 26.77 25.52 26.77 0 19.81 0 13.39 14.79 6.96 0 0 0 0 25.52 6.38 25.52 6.38 13.44 11.41 25.52 15.37 25.52"/>
  </symbol>
  <symbol id="partnersuche" viewBox="0 0 100 100">
    <title>partnersuche</title>
    <circle id="Oval" cx="50" cy="50" r="50" fill="#b60017"/>
    <path id="heart"
          d="M50,79a1.91,1.91,0,0,1-1.38-.56L29.12,59.63a9.65,9.65,0,0,1-.85-.82q-.56-.56-1.74-2a28.89,28.89,0,0,1-2.13-3.05,19.16,19.16,0,0,1-1.67-3.78A13.68,13.68,0,0,1,22,45.63q0-6.89,4-10.76t11-3.87a12.38,12.38,0,0,1,4,.67,15.69,15.69,0,0,1,3.75,1.82,35.07,35.07,0,0,1,3,2.14A26.67,26.67,0,0,1,50,37.75a26.67,26.67,0,0,1,2.38-2.12,35.07,35.07,0,0,1,3-2.14,15.69,15.69,0,0,1,3.75-1.82,12.38,12.38,0,0,1,4-.67q7,0,11,3.87t4,10.76q0,6.9-7.16,14.06L51.38,78.44A1.91,1.91,0,0,1,50,79"
          fill="#ffffff"/>
  </symbol>
  <symbol id="partnersuche" viewBox="0 0 100 100"><title>partnersuche</title>
    <circle id="Oval" cx="50" cy="50" r="50" fill="#b60017"/>
    <path id="heart"
          d="M50,79a1.91,1.91,0,0,1-1.38-.56L29.12,59.63a9.65,9.65,0,0,1-.85-.82q-.56-.56-1.74-2a28.89,28.89,0,0,1-2.13-3.05,19.16,19.16,0,0,1-1.67-3.78A13.68,13.68,0,0,1,22,45.63q0-6.89,4-10.76t11-3.87a12.38,12.38,0,0,1,4,.67,15.69,15.69,0,0,1,3.75,1.82,35.07,35.07,0,0,1,3,2.14A26.67,26.67,0,0,1,50,37.75a26.67,26.67,0,0,1,2.38-2.12,35.07,35.07,0,0,1,3-2.14,15.69,15.69,0,0,1,3.75-1.82,12.38,12.38,0,0,1,4-.67q7,0,11,3.87t4,10.76q0,6.9-7.16,14.06L51.38,78.44A1.91,1.91,0,0,1,50,79"
          fill="#ffffff"/>
  </symbol>
  <symbol id="spiele" viewBox="-247 370.9 100 125">
    <title>Spiele</title>
    <switch xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M-147.3 445.9h-3.5c.3-7.2-2-13.4-6.7-17.5-2-1.7-5.3-4.6-5.4-19 4.1-2.6 6.8-7.2 6.8-12.5 0-8.2-6.7-14.8-14.8-14.8-1.4 0-2.7.2-4 .6v-10.8H-234v54.9h-12v35.6h31.6v8.5h35.6v-13.3h31.4l.1-11.7zm-12.9-14.5c3.8 3.3 5.6 8.4 5.3 14.5h-23.8v-10.6h-6.4c.8-1.3 2.1-2.9 3.3-3.9l.4-.3c2.4-2.1 6-5.2 6.4-19.9 1.3.4 2.6.6 4 .6s2.8-.2 4-.6c.5 14.6 4.4 18.1 6.8 20.2zm0-34.5c0 6-4.9 10.8-10.8 10.8s-10.8-4.8-10.8-10.8 4.8-10.8 10.8-10.8 10.8 4.8 10.8 10.8zm-69.8-21h51v8.5c-4.1 2.6-6.8 7.2-6.8 12.5 0 5.2 2.7 9.8 6.8 12.5-.1 14.4-3.1 17-5.1 18.7l-.4.3c-1.1 1-4 4-5.1 6.9h-20.8v-8.5H-230v-50.9zm-12 82.5v-27.6h27.6v27.6H-242zm59.3 8.5h-27.7v-27.6h27.6V466.9h.1zm31.4-13.3h-27.4v-3.7h27.4v3.7z"/>
        <path
          d="M-189.6 408.7l-14.9-20.9-14.9 20.9 14.9 20.9 14.9-20.9zM-233.9 435.3c-1.8 0-3.2 1.4-3.2 3.2 0 1.8 1.4 3.2 3.2 3.2 1.8 0 3.2-1.4 3.2-3.2 0-1.8-1.4-3.2-3.2-3.2zM-222 435.3c-1.8 0-3.2 1.4-3.2 3.2 0 1.8 1.4 3.2 3.2 3.2s3.2-1.4 3.2-3.2c0-1.8-1.5-3.2-3.2-3.2zM-233.9 447.1c-1.8 0-3.2 1.4-3.2 3.2 0 1.8 1.4 3.2 3.2 3.2 1.8 0 3.2-1.4 3.2-3.2 0-1.7-1.4-3.2-3.2-3.2zM-222 447.1c-1.8 0-3.2 1.4-3.2 3.2 0 1.8 1.4 3.2 3.2 3.2s3.2-1.4 3.2-3.2c0-1.7-1.5-3.2-3.2-3.2zM-201.3 445c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.4-3-3-3zM-191.5 454.9c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z"/>
      </g>
    </switch>
    <text xmlns="http://www.w3.org/2000/svg" x="-247" y="485.9" font-size="5" font-weight="bold"
          font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif">Created by Icons Producer
    </text>
    <text xmlns="http://www.w3.org/2000/svg" x="-247" y="490.9" font-size="5" font-weight="bold"
          font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif">from the Noun Project
    </text>
  </symbol>
  <symbol id="profilat" viewBox="0 0 151.72 151.72"><title>profil</title>
    <rect width="151.72" height="151.72" fill="#cd1417"/>
    <path
      d="M87.63,23.74c-8.17,0-14.62,3.05-21.1,10.3V24.79h-33V41.61H43v72H33.53v16.81h44V113.58h-11V90.31c6.63,6.47,12.77,9.12,20.79,9.12,18.34,0,31.33-15.59,31.33-37.78C118.65,40.07,105.2,23.74,87.63,23.74ZM80.68,81.38c-8.79,0-14.33-7.55-14.33-19.42s5.14-19.11,14.08-19.11,14.33,7.08,14.33,18.8C94.76,74,89.47,81.38,80.68,81.38Z"
      transform="translate(0 0)" fill="#fff"/>
  </symbol>
  <symbol id="kat" viewBox="0 0 55.05 85.53">
    <title>kat</title>
    <path fill="#782038" class="cls-1"
          d="M18.11.28a4.41,4.41,0,0,0-5,1.45L2.63,13.14A10.72,10.72,0,0,0,0,20V80.8a4.5,4.5,0,0,0,2.85,4.47,4.44,4.44,0,0,0,1.48.26A4.81,4.81,0,0,0,8,83.85L18.31,72.73A11,11,0,0,0,21,66V4.78A4.57,4.57,0,0,0,18.11.28ZM13.75,66.14A4.16,4.16,0,0,1,13,68.08L7.05,74.44V20a4,4,0,0,1,.77-2.08l6-6.47ZM55,19.64V4.73A4.34,4.34,0,0,0,52.42.42a4.26,4.26,0,0,0-4.88,1.4L30.09,20.66a10.61,10.61,0,0,0-2.59,6.59V60.07a10.09,10.09,0,0,0,2.85,6.59L47.71,84a4.84,4.84,0,0,0,3.37,1.51,4.52,4.52,0,0,0,1.39-.4A4.41,4.41,0,0,0,55,80.8V66.91a10.31,10.31,0,0,0-2.85-6.61L35.08,43.19,52.19,26.25A10.17,10.17,0,0,0,55,19.64ZM47.68,65a3.66,3.66,0,0,1,.8,1.91v8.33L35,61.75a3.64,3.64,0,0,1-.8-1.88V51.6Zm.8-45.39a3.74,3.74,0,0,1-.8,1.91L34.4,34.78V27.14a4.28,4.28,0,0,1,.82-2.06L48.48,10.83Z"/>
  </symbol>
  <symbol id="futurezoneat" viewBox="0 0 104.53 104.53">
    <rect fill="#ffffff" width="104.53" height="104.53"/>
    <path d="M3.68,89.76c-0.48,0-0.81-0.4-0.65-0.89l3.84-16.65c0.08-0.48,0.57-0.91,1.05-0.91l10.25,0.02
				c0.48,0,0.81,0.38,0.64,0.87l-2.08,9h73.9c0.48,0,0.81,0.4,0.73,0.89l-1.53,6.8c-0.08,0.48-0.57,0.89-1.05,0.89L3.68,89.76z"/>
    <path d="M47.39,53.69L43.74,71.2c-0.08,0.49-0.57,0.89-1.05,0.89H31.58c-0.41,0-0.81-0.4-0.65-0.89l4.38-17.51l3.49-14.75h-5.43
			c-0.41,0-0.81-0.4-0.73-0.89l1.62-6.81c0.08-0.49,0.57-0.89,1.05-0.89h5.43l0.65-2.76c2.19-9.48,9.65-13.05,16.62-13.05
			c2.43,0,5.27,0.32,7.21,0.65c0.49,0.08,0.81,0.57,0.73,1.05l-1.54,6.73c-0.16,0.49-0.65,0.81-1.13,0.65
			c-1.13-0.24-2.51-0.4-4.38-0.4c-2.68,0-4.86,1.3-5.84,5.51l-0.41,1.62h9.08c0.49,0,0.81,0.4,0.73,0.89l-1.62,6.81
			c-0.08,0.49-0.57,0.89-1.05,0.89h-9.08L47.39,53.69z"/>
    <path d="M93.66,71.2c-0.08,0.49-0.57,0.89-1.05,0.89H59.04c-0.49,0-0.81-0.4-0.65-0.89l1.54-6.81c0.16-0.57,0.49-1.14,0.89-1.54
			l24.24-23.91H68.44c-0.41,0-0.81-0.4-0.73-0.89l1.62-6.81c0.08-0.49,0.57-0.89,1.05-0.89h30.56c0.49,0,0.81,0.4,0.73,0.89
			l-1.7,6.97c-0.08,0.57-0.4,1.13-0.81,1.54L74.77,63.5h19.78c0.49,0,0.81,0.4,0.73,0.89L93.66,71.2z"/>
  </symbol>
  <symbol id="meetyourstar" viewBox="0 0 59.55 47.21">
    <path
      d="M59.41.09,59.55,0a1.17,1.17,0,0,1-.06.2L57.33,3.78Q55,7.65,52.67,11.52q-3.6,6-7.2,12a.39.39,0,0,0,0,.47Q49.13,30,52.77,36.1l6.38,10.66c.09.15.18.3.26.45H59.3l-.79-.87L55.43,43,53.35,40.8l-3.06-3.28L48.13,35.2l-2.25-2.42-2.14-2.3c-.77-.82-1.53-1.65-2.3-2.46L39.49,26c-.29-.31-.56-.63-.85-.93-.44-.47-.89-.93-1.34-1.4v-.11l1.19-1.27,3-3.14,1.86-2,3-3.12,1.88-2,2.93-3.1,2-2.07L56,3.78C56.84,2.86,57.72,2,58.6,1q.4-.42.78-.84S59.4.12,59.41.09Z"
      style="fill: #88dcf4"/>
    <path
      d="M.14,47.21c.66-1.1,1.3-2.2,2-3.29L8.67,33,14,24a.5.5,0,0,0,0-.61Q8.77,14.68,3.53,5.94C2.38,4,1.22,2.11.07.2A.68.68,0,0,1,0,0L.15.09s0,.09,0,.12C.75.82,1.32,1.43,1.89,2l1.92,2,3,3.14c.64.66,1.27,1.33,1.9,2,1,1.07,2,2.13,3,3.2.61.63,1.21,1.27,1.81,1.91l3,3.14,2.63,2.74c.35.36.68.72,1,1.08l2.12,2.25a.83.83,0,0,1-.09.16L20.76,25.2,18.6,27.53l-2.22,2.38-2.23,2.4L12,34.67,9.75,37,7.49,39.45,5.31,41.8c-.74.79-1.47,1.59-2.22,2.38s-1.34,1.41-2,2.12l-.83.91Z"
      style="fill: #88dcf4"/>
    <path
      d="M.25,47.21l.83-.91c.67-.71,1.35-1.41,2-2.12s1.48-1.59,2.22-2.38l2.18-2.35L9.75,37,12,34.67l2.2-2.36,2.23-2.4,2.22-2.38,2.16-2.33,1.41-1.52a.83.83,0,0,0,.09-.16c.15,0,.3.05.46.05.83,0,1.66-.05,2.5-.05h3.67a.8.8,0,0,1,.66.25.27.27,0,0,0,.45,0,.81.81,0,0,1,.66-.25H34.5c.37,0,.74,0,1.1,0,.57,0,1.13,0,1.7,0v.11c-.13.17-.07.29.06.43l.74.79,1.69,1.8L41,28l1.81,1.92c.08.08.18.13.25.21.33.37.64.76,1,1.12s.8.79,1.18,1.2.67.78,1,1.16.94,1,1.42,1.54L49.49,37c.33.35.68.68,1,1s.76.87,1.15,1.3c.08.08.19.14.27.23.33.38.65.77,1,1.14l1.5,1.61s.06.17,0,.19-.31.29-.38.26a5.3,5.3,0,0,1-.78-.56l-.92-.77-1.2-.93-1.51-1.18-1.13-.89c-.32-.25-.62-.53-.94-.79l-1.45-1.12-1.46-1.18c-.4-.32-.82-.62-1.22-.94L42,33.27c-.37-.29-.75-.57-1.12-.87s-.61-.54-.93-.8l-1.76-1.37L36.7,29.06l-1.53-1.22-.9-.66-1.13-.9-1-.79-.89-.68-1-.8a.22.22,0,0,0-.35,0c-.12-.09-.22-.06-.34,0L23.42,29l-5.69,4.5-6,4.73L5.54,43.12.91,46.8.64,47l-.06,0h0l0,.05-.11.11Z"
      style="fill: #5bd0f1"/>
    <path
      d="M29.93,24a.22.22,0,0,1,.35,0l1,.8.89.68,1,.79,1.13.9.9.66,1.53,1.22,1.45,1.17,1.76,1.37c.32.26.62.54.93.8s.75.58,1.12.87l1.49,1.17c.4.32.82.62,1.22.94l1.46,1.18,1.45,1.12c.32.26.62.54.94.79l1.13.89,1.51,1.18,1.2.93.92.77a5.3,5.3,0,0,0,.78.56c.07,0,.26-.16.38-.26s0-.15,0-.19l-1.5-1.61c-.34-.37-.66-.76-1-1.14-.08-.09-.19-.15-.27-.23-.39-.43-.76-.87-1.15-1.3s-.68-.68-1-1L47.7,35.15c-.48-.51-1-1-1.42-1.54s-.67-.78-1-1.16-.8-.79-1.18-1.2-.65-.75-1-1.12c-.07-.08-.17-.13-.25-.21L41,28l-1.25-1.36-1.69-1.8-.74-.79c-.13-.14-.19-.26-.06-.43.45.47.9.93,1.34,1.4.29.3.56.62.85.93L41.44,28c.77.81,1.53,1.64,2.3,2.46l2.14,2.3,2.25,2.42,2.16,2.32,3.06,3.28L55.43,43l3.08,3.3.79.87h-.16L59,47.1,59,47.05h0l-.06,0-4.79-3.79L48.93,39.1l-5.71-4.53-5.68-4.5L31.3,25.12Z"
      style="fill: #5bd0f1"/>
    <path
      d="M37.3,23.51c-.57,0-1.13,0-1.7,0-.36,0-.73,0-1.1,0H30.66a.81.81,0,0,0-.66.25.27.27,0,0,1-.45,0,.8.8,0,0,0-.66-.25H25.22c-.84,0-1.67,0-2.5.05-.16,0-.31,0-.46-.05l-2.12-2.25c-.34-.36-.67-.72-1-1.08l-2.63-2.74-3-3.14c-.6-.64-1.2-1.28-1.81-1.91-1-1.07-2-2.13-3-3.2-.63-.67-1.26-1.34-1.9-2l-3-3.14L1.89,2C1.32,1.43.75.82.19.21c0,0,0-.08,0-.12l6.46,5,6.23,4.83,7.05,5.48,8.27,6.41c.46.37.94.73,1.39,1.1a.3.3,0,0,0,.45,0q2.49-1.95,5-3.89l8.28-6.43,9.45-7.35L59.25.2l.06,0,.1-.07s0,.08,0,.11Q59,.62,58.6,1C57.72,2,56.84,2.86,56,3.78L53.05,6.86l-2,2.07L48.16,12l-1.88,2-3,3.12-1.86,2-3,3.14Z"
      style="fill: #45caf0"/>
    <path d="M59.31.16l-.06,0Z" style="fill: #88dcf4"/>
    <path d="M.58,47.05l.06,0Z" style="fill: #88dcf4"/>
    <path d="M58.91,47l.06,0Z" style="fill: #88dcf4"/>
    <path d="M.53,47.1l0-.05Z" style="fill: #88dcf4"/>
    <path d="M59,47.05l.06.05Z" style="fill: #88dcf4"/>
  </symbol>
  <symbol id="bazar" viewBox="0 0 1000 1000">
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1">
        <g id="vorlage">
          <path fill="#b32041"
                d="M0,0H1000V1000H0ZM300.62,906.57c72.92,0,143.86.18,214.8-.15a329.81,329.81,0,0,0,46.49-3.15c61.3-9,103.72-41.67,122.78-101.68C707.39,730.13,706,657.73,690,585.42c-9.66-43.67-37.12-72.82-79.49-87.69-3.35-1.18-6.56-2.79-10.92-4.67,2.45-1.72,3.51-2.86,4.81-3.32,44.87-15.81,69-49.44,79.45-94,13.22-56.64,14.34-113.86,2.7-170.84C678.11,183.77,660,147.7,624,123.29c-31-21-66.13-27.42-102.66-27.58-68.27-.31-136.54-.11-204.81,0-5.18,0-10.36.48-15.86.75Z"/>
          <path fill="#b42243"
                d="M448.11,761.11V567c20.58,1.51,40.81,1.73,60.58,4.86C522.82,574,530.76,585.9,532,599.52c2.71,29.77,4.27,59.67,5.37,89.56.44,11.68-.94,23.55-2.64,35.17-3.63,24.66-13.64,33.28-38.44,34.48C480.89,759.48,465.48,760.25,448.11,761.11Z"/>
          <path fill="#b42243"
                d="M448.43,424.63V246.2c20.55,0,41.3-1.24,61.82.41,14.79,1.2,22.29,13.29,23.42,27,2.18,26.63,3.45,53.39,3.65,80.11.09,13.24-2.72,26.55-4.79,39.73-3,19.07-14.94,29.63-33.71,30.91C482.39,425.52,465.82,424.63,448.43,424.63Z"/>
        </g>
      </g>
    </g>
  </symbol>
  <symbol id="phase6" viewBox="0 0 197 197">
    <path
      d="M196.6 98.3C196.6 152.6 152.6 196.6 98.3 196.6C44 196.6 0 152.6 0 98.3C0 44 44 0 98.3 0C103.5 0 108.7 0.4 113.7 1.2C98 22.9 73.1 57.8 67.2 69.2C62 79.2 59.4 89 59.4 98.7C59.4 113.6 64 125.5 73.3 134.4C82.6 143.3 95 147.7 110.7 147.7C126 147.7 138.6 142.8 148.4 133C158.2 123.2 163.1 110.7 163.1 95.4C163.1 82.8 159.5 72.7 152.4 65C145.3 57.3 135.9 53.5 124.1 53.5C122.5 53.5 121 53.6 119.6 53.7C118.1 53.9 116.7 54.1 115.3 54.3L145.9 12.3C176.1 29 196.6 61.3 196.6 98.3ZM96.5 113.2C100.6 117.3 105.5 119.4 111.2 119.4C116.9 119.4 121.8 117.4 125.8 113.4C129.8 109.4 131.8 104.4 131.8 98.6C131.8 92.7 129.8 87.7 125.9 83.8C121.9 79.8 117 77.9 111.2 77.9C105.5 77.9 100.6 79.9 96.5 84C92.4 88.1 90.4 93 90.4 98.6C90.4 104.2 92.4 109.1 96.5 113.2Z"
      fill="#FC7C00"/>
  </symbol>
</svg>
