import { RouteService } from './route.service';
import { filter, switchMap } from 'rxjs/operators';
import { StateService } from './state.service';
import { BrowserRef } from './browser.ref';
import { SCRIPT_MAP } from '@model/const/script';
import { ScriptId } from '@model/enum/scriptId';
import { PortalService } from '@shared/service/portal.service';
import { LoadExternalScriptService } from '@shared/service/loadExternalScript.service';
import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "./loadExternalScript.service";
import * as i2 from "./state.service";
import * as i3 from "./portal.service";
import * as i4 from "./route.service";
import * as i5 from "./browser.ref";
var OewaService = /** @class */ (function () {
    function OewaService(loadExternalScriptService, stateService, portalService, routeService, platformId, browserRef) {
        this.loadExternalScriptService = loadExternalScriptService;
        this.stateService = stateService;
        this.portalService = portalService;
        this.routeService = routeService;
        this.platformId = platformId;
        this.browserRef = browserRef;
    }
    Object.defineProperty(OewaService.prototype, "isOewaApp", {
        get: function () {
            return window && window.location.search.includes('trackingAllowed');
        },
        enumerable: true,
        configurable: true
    });
    OewaService.prototype.init = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId)) {
            if (!this.isOewaApp) {
                this.routeService.routeInfo.pipe(filter(Boolean), filter(function (route) { return route.analytics && route.analytics.oewa; }), switchMap(function (route) {
                    var dn;
                    switch (_this.portalService.currentPortal()) {
                        case 'kurierat':
                            dn = 'data-e0cdc680c2.kurier.at';
                            break;
                        case 'motorat':
                            dn = 'data-d9c82db6b2.motor.at';
                            break;
                        case 'filmat':
                            dn = 'data-366a248b14.film.at';
                            break;
                    }
                    SCRIPT_MAP[ScriptId.oewa] = '//' + dn + '/iomm/latest/bootstrap/stub.js';
                    SCRIPT_MAP[ScriptId.oewaBundle] = '//' + dn + '/iomm/latest/manager/base/es6/bundle.js';
                    _this.oewaData = {
                        cn: route.analytics.oewa.cn,
                        dn: dn,
                        cp: route.analytics.oewa.cp,
                        st: route.analytics.oewa.st,
                        mh: 5,
                    };
                    return _this.loadExternalScriptService.loadScript(ScriptId.oewa);
                }), switchMap(function (s) {
                    s.scriptVar('configure', { st: _this.oewaData.st, dn: _this.oewaData.dn, cn: _this.oewaData.cn, mh: 5 });
                    s.scriptVar('pageview', { cp: _this.oewaData.cp });
                    return _this.loadExternalScriptService.loadScript(ScriptId.oewaBundle);
                })).subscribe();
            }
        }
    };
    /**
     * Push new categorypath, reusing other current values.
     */
    OewaService.prototype.pushCp = function (oewaPageId) {
        // if (!!this.isOewaApp) {
        //   if (this.oewaData) {
        //     const oewaData: OewaData = Object.assign({}, this.oewaData);
        //
        //     const isMobile = this.browserRef.window.innerWidth < 960;
        //     oewaData.sv = isMobile ? 'mo' : 'in';
        //
        //     if (isMobile) {
        //       oewaData.cp += '/moewa';
        //     }
        //     if (oewaPageId) {
        //       oewaData.cp += '/' + oewaPageId;
        //     }
        //     this.pushOewa(oewaData);
        //   }
        // }
    };
    OewaService.prototype.pushOewa = function (oewaData) {
        if (!this.isOewaApp) {
            // tslint:disable-next-line:no-string-literal
            var iom = this.browserRef.window['iom'];
            if (iom) {
                iom.c(oewaData, OewaService.METHOD_APPEND_CHILD);
            }
        }
    };
    OewaService.METHOD_APPEND_CHILD = 1;
    OewaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OewaService_Factory() { return new OewaService(i0.ɵɵinject(i1.LoadExternalScriptService), i0.ɵɵinject(i2.StateService), i0.ɵɵinject(i3.PortalService), i0.ɵɵinject(i4.RouteService), i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i5.BrowserRef)); }, token: OewaService, providedIn: "root" });
    return OewaService;
}());
export { OewaService };
