import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {LoadExternalScriptService} from '../../../../shared/service/loadExternalScript.service';
import {ScriptId} from '../../../../model/enum/scriptId';
import {ITwitterTimeline} from '../../../../model/content/twitter/payload';
import {DidomiService} from '@shared/service/didomi.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {VendorTypes} from '@model/enum/vendorTypes';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'twitter-timeline',
  templateUrl: 'twitter-timeline.component.html'
})
export class TwitterTimelineComponent implements DynamicComponent<ITwitterTimeline> {

  showTwitter$: Observable<boolean>;
  vendorType = VendorTypes.twitter;
  width: number;
  height: number;
  href: string;

  constructor(
    @Inject(LoadExternalScriptService) private loadScript: LoadExternalScriptService,
    private didomiService: DidomiService,
  ) {
  }

  initModel(model: ITwitterTimeline): void {
    this.width = +model.data.width;
    this.height = +model.data.height;
    this.href = model.data.link;
    this.showTwitter$ = this.didomiService.checkVendor$(this.vendorType)
      .pipe(
        tap(consent => {
          if (consent) {
            this.loadScript.loadScript(ScriptId.twitter).subscribe(tw => {
              if (tw.loadedBefore) {
                setTimeout(() => {
                  tw.scriptVar.widgets.load();
                }, 0);
              }
            });
          }
        })
      );
  }
}
