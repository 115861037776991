import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { ScriptId } from '../../model/enum/scriptId';
import { BrowserRef } from './browser.ref';
import { SCRIPT_ID_MAP, SCRIPT_MAP } from '../../model/const/script';
import { filter, take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./browser.ref";
var LoadExternalScriptService = /** @class */ (function () {
    function LoadExternalScriptService(platformId, browserRef) {
        this.platformId = platformId;
        this.browserRef = browserRef;
        this.TYPE = 'text/javascript';
        this.LANG = 'Javascript';
        this.observers = new Map();
        this.haveLoaded = new Map();
    }
    LoadExternalScriptService.prototype.loadScript = function (sdk) {
        if (isPlatformBrowser(this.platformId)) {
            if (sdk === ScriptId.pinterest) {
                return this.getPinterest();
            }
            else {
                return this.getScript(sdk);
            }
        }
        return of(null).pipe(filter(Boolean));
    };
    LoadExternalScriptService.prototype.getScript = function (id) {
        var _this = this;
        var document = this.browserRef.document;
        var s = 'script';
        if (this.observers[id]) {
            if (this.haveLoaded[id]) {
                this.nextScriptLoaded(id, true);
            }
            return this.observers[id].asObservable().pipe(filter(Boolean), take(1));
        }
        this.observers[id] = new BehaviorSubject(null);
        var fjs = document.getElementsByTagName(s)[0];
        var js = document.createElement(s);
        js.id = id;
        js.src = SCRIPT_MAP[id];
        js.type = this.TYPE;
        js.language = this.LANG;
        js.defer = 'defer';
        js.onload = function () {
            _this.haveLoaded[id] = true;
            _this.nextScriptLoaded(id, false);
        };
        js.error = function (message) {
            _this.observers[id].error(message);
        };
        fjs.parentNode.insertBefore(js, fjs);
        // TODO macht das irgendeinen Unterschied?
        if (id === ScriptId.twitter) {
            var t_1 = this.browserRef.window[SCRIPT_ID_MAP[id]] || {};
            t_1._e = [];
            t_1.ready = function (f) {
                t_1._e.push(f);
            };
            this.browserRef.window[SCRIPT_ID_MAP[id]] = t_1;
        }
        return this.observers[id].asObservable().pipe(filter(Boolean), take(1));
    };
    LoadExternalScriptService.prototype.nextScriptLoaded = function (id, hasLoadedBefore) {
        var _this = this;
        if (!hasLoadedBefore && id === ScriptId.facebook) {
            // tslint:disable-next-line:no-string-literal
            this.browserRef.window['fbAsyncInit'] = function () {
                _this.observers[id].next({ loadedBefore: false, scriptVar: _this.browserRef.window[SCRIPT_ID_MAP[id]] });
            };
            return;
        }
        var nextScript = {
            loadedBefore: hasLoadedBefore,
            scriptVar: this.browserRef.window[SCRIPT_ID_MAP[id]] || this.browserRef.window
        };
        this.observers[id].next(nextScript);
    };
    LoadExternalScriptService.prototype.getPinterest = function () {
        var _this = this;
        var window = this.browserRef.window;
        var doc = this.browserRef.document;
        var p = ScriptId.pinterest.toString();
        if (this.observers[p]) {
            return this.observers[p].asObservable().filter(Boolean).take(1);
        }
        this.observers[p] = new ReplaySubject();
        // tslint:disable-next-line:one-variable-per-declaration
        var id, scriptsElms, script;
        //noinspection CommaExpressionJS
        id = 'PIN_' + Math.floor((new Date()).getTime() / 864e5);
        if (window[id]) {
            window[id] += 1;
        }
        else {
            window[id] = 1;
            scriptsElms = doc.getElementsByTagName('SCRIPT')[0];
            script = doc.createElement('SCRIPT');
            script.type = this.TYPE;
            script.language = this.LANG;
            //noinspection CommaExpressionJS
            script.defer = 'defer';
            script.src = SCRIPT_MAP[p] + '?' + Math.random();
            script.onload = function () {
                _this.observers[id].next(window);
            };
            scriptsElms.parentNode.insertBefore(script, scriptsElms);
        }
        return this.observers[p].asObservable().filter(Boolean).take(1);
    };
    LoadExternalScriptService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadExternalScriptService_Factory() { return new LoadExternalScriptService(i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i1.BrowserRef)); }, token: LoadExternalScriptService, providedIn: "root" });
    return LoadExternalScriptService;
}());
export { LoadExternalScriptService };
