import {ComponentFactoryResolver, Directive, Input, OnChanges, ViewContainerRef} from '@angular/core';
import {TeaserModel} from '@model/teaser/teaser';
import {typeMap} from '@model/teaser/teaserTypeMap';

@Directive({
  selector: '[teaser]'
})
export class TeaserDirective implements OnChanges {

  @Input() model: TeaserModel;
  componentRef;

  constructor(
    private vcRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
  ) {
  }

  ngOnChanges(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    const type = typeMap[this.model.type];
    const factory = this.resolver.resolveComponentFactory(type);
    this.componentRef = this.vcRef.createComponent(factory);
    const component = this.componentRef.instance;
    component.model = this.model;
  }

}
