import {Component} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import TextBoxModel from '../../../../model/channel/textBox/textBox';
import {ITextBox} from '../../../../model/channel/textBox/payload';

@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
})
export class TextBoxComponent implements DynamicComponent<ITextBox> {

  model: TextBoxModel;

  constructor() {
  }

  initModel(model: ITextBox): void {
    this.model = TextBoxModel.deserialize(model);
  }

}
