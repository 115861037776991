/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../common/ui/iconButton/iconButton.component.ngfactory";
import * as i3 from "../common/ui/iconButton/iconButton.component";
import * as i4 from "./required-consent-overlay.component";
import * as i5 from "../../shared/service/portal.service";
import * as i6 from "../../shared/service/didomi.service";
import * as i7 from "../../shared/service/piano/piano.service";
var styles_RequiredConsentOverlayComponent = [];
var RenderType_RequiredConsentOverlayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RequiredConsentOverlayComponent, data: {} });
export { RenderType_RequiredConsentOverlayComponent as RenderType_RequiredConsentOverlayComponent };
function View_RequiredConsentOverlayComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "p", [["class", "loginParagraph"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sie haben bereits ein Abo? "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Hier Anmelden"]))], null, null); }
function View_RequiredConsentOverlayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RequiredConsentOverlayComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.screenOffer && !_v.context.ngIf.user.valid); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RequiredConsentOverlayComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "adFreeOfferComponent"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { display: 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 9, "div", [["class", "adFreeOfferComponent-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "button", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openConsentOverlay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "iconbutton", [["icon", "times"]], null, null, null, i2.View_IconButtonComponent_0, i2.RenderType_IconButtonComponent)), i0.ɵdid(7, 114688, null, 0, i3.IconButtonComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 0, "div", [["id", "offer"]], [[2, "visible", null]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "div", [["id", "login"]], [[2, "visible", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_RequiredConsentOverlayComponent_1)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpod(13, { user: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.show ? "flex" : "none")); _ck(_v, 2, 0, currVal_0); var currVal_1 = "times"; _ck(_v, 7, 0, currVal_1); var currVal_4 = _ck(_v, 13, 0, i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform(_co.piano.user$))); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.screenOffer; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.screenOffer; _ck(_v, 9, 0, currVal_3); }); }
export function View_RequiredConsentOverlayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-required-consent-overlay", [], null, null, null, View_RequiredConsentOverlayComponent_0, RenderType_RequiredConsentOverlayComponent)), i0.ɵdid(1, 245760, null, 0, i4.RequiredConsentOverlayComponent, [i5.PortalService, i6.DidomiService, i7.PianoService, i0.Renderer2, i0.PLATFORM_ID, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RequiredConsentOverlayComponentNgFactory = i0.ɵccf("app-required-consent-overlay", i4.RequiredConsentOverlayComponent, View_RequiredConsentOverlayComponent_Host_0, {}, {}, []);
export { RequiredConsentOverlayComponentNgFactory as RequiredConsentOverlayComponentNgFactory };
