import * as tslib_1 from "tslib";
import { ErrorHandler, Injector } from '@angular/core';
import { BrowserRef } from './browser.ref';
import { TrackJS } from 'trackjs';
var TrackJsErrorHandler = /** @class */ (function (_super) {
    tslib_1.__extends(TrackJsErrorHandler, _super);
    function TrackJsErrorHandler(prodmode, browserRef, injector) {
        var _this = _super.call(this) || this;
        _this.browserRef = browserRef;
        _this.injector = injector;
        var ts = new Date().getTime();
        _this.active = prodmode && (ts % 10 === 0);
        if (!_this.active) {
            return _this;
        }
        TrackJS.install({
            token: '9e6170e5e6604002b5557225dadc3c10',
            application: 'hermes-cfs',
        });
        return _this;
    }
    TrackJsErrorHandler.prototype.handleError = function (error) {
        if (this.active) {
            var trackedError = error.originalError || error;
            TrackJS.track(trackedError);
        }
        else {
            _super.prototype.handleError.call(this, error);
        }
    };
    return TrackJsErrorHandler;
}(ErrorHandler));
export { TrackJsErrorHandler };
