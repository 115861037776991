import { PortalService } from './portal.service';
import { ENDPOINT_OPTIONS } from './tokens';
import { StateProvider } from './state.service';
import { IMG_SRCSET_MAP, IMG_W_MAP } from '../../model/const/image';
import * as i0 from "@angular/core";
import * as i1 from "./state.service";
import * as i2 from "./portal.service";
var ImageService = /** @class */ (function () {
    function ImageService(stateService, portalService) {
        this.stateService = stateService;
        this.portalService = portalService;
        var endpointOptions = stateService.get(ENDPOINT_OPTIONS);
        this.imageHost = this.createImageHost(endpointOptions);
    }
    ImageService.prototype.createImageHost = function (endpointOptions) {
        switch (endpointOptions.type) {
            case 'derived':
                return 'https://image.' + this.portalService.hostname();
            case 'fixed':
                return endpointOptions.endpoint;
            case 'staging':
                return 'https://image-stage.' + this.portalService.hostname();
            default:
                throw new Error('Unhandled endpointOptions.type ' + endpointOptions.type);
        }
    };
    ImageService.prototype.srcset = function (url, size) {
        var _this = this;
        if (!url || !size) {
            return '';
        }
        var srcset = '';
        var map = IMG_SRCSET_MAP[size];
        map.forEach(function (src, i) {
            srcset += _this.url(url, src) + " " + IMG_W_MAP[size][i] + ", ";
        });
        return srcset;
    };
    ImageService.prototype.videoUrl = function (url) {
        return this.imageHost + url;
    };
    ImageService.prototype.gifUrl = function (url) {
        return this.imageHost + url;
    };
    ImageService.prototype.url = function (url, src) {
        if (!url) {
            return '';
        }
        var teaserRegex = /^\/images\/(teaser|large|original)/;
        if (teaserRegex.test(url)) {
            var replaceValue = '/images/' + src;
            var urlN = url.replace(teaserRegex, replaceValue);
            return this.imageHost + urlN;
        }
        return url;
    };
    ImageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImageService_Factory() { return new ImageService(i0.ɵɵinject(i1.StateService), i0.ɵɵinject(i2.PortalService)); }, token: ImageService, providedIn: "root" });
    return ImageService;
}());
export { ImageService };
