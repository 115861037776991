<div class="navBar noOpacity" #navBar>
  <ul class="navBar-items">
    <li #navBarItem *ngFor="let m of model.main"
        [class.isActive]="m.isActive(model.currentUri)" [ngClass]="['navBar-item', m.cssClass]">
      <a [gtm]="gtmId" [portalRouterLink]="m.portalRoute" [class]="m.isParent ? 'navBar-parentLink' : ''">
        {{m.title}}
        <svgicon *ngIf="m.isParent" [icon]="'angle-down'"></svgicon>
      </a>
      <ul class="navBar-sub" *ngIf="m.isParent">
        <li *ngFor="let submenu of m.children" [class.isActive]="submenu.isActive(model.currentUri)">
          <a [gtm]="gtmId" [portalRouterLink]="submenu.portalRoute">{{submenu.title}}</a>
        </li>
      </ul>
    </li>
    <li #moreItem class="navBar-item navBar-item-last" *ngIf="!isTouch">
      <span class="navBar-moreButton">Mehr <svgicon [icon]="'angle-down'"></svgicon></span>
      <div class="navBar-overlay">
        <ul class="navBar-overlay-inner">
          <li #menuItem *ngFor="let m of model.main"
              [class.isActive]="m.isActive(model.currentUri)" [ngClass]="['navBar-overlayItem', m.cssClass]">
            <a [gtm]="gtmId" [class]="m.isParent ? 'navBar-parentLink' : ''" [portalRouterLink]="m.portalRoute">
              <svgicon *ngIf="m.isParent" [icon]="'angle-left'"></svgicon>
              {{m.title}}
            </a>
            <ul class="navBar-sub" *ngIf="m.isParent">
              <li *ngFor="let submenu of m.children" [class.isActive]="submenu.isActive(model.currentUri)">
                <a [gtm]="gtmId" [portalRouterLink]="submenu.portalRoute">{{submenu.title}}</a>
              </li>
            </ul>
          </li>
          <li class="navBar-portals">
            <portals [gtmId]="gtmId"></portals>
          </li>
          <li *ngIf="model.subMenu.length" class="navBar-secondaryMenu">
            <ul>
              <li *ngFor="let sub of model.subMenu"><a [portalRouterLink]="sub.portalRoute">{{sub.title}}</a></li>
              <li *ngIf="isKurier">
                <button class="navigation-search" (click)="toggleSearch()">
                  <iconbutton [icon]="'search'"></iconbutton>
                  Suchen
                </button>
              </li>
            </ul>
          </li>
          <li *ngIf="model.social.length > 0" class="navBar-social">
            <iconbutton
              *ngFor="let social of model.social"
              [icon]="shareMap[social.id]"
              [link]="social.portalRoute"></iconbutton>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
