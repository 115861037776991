import {InjectionToken} from '@angular/core';
import {StylesService} from './styles/styles.service';

export const CLEVERPUSH_CHANNELS = 'cleverpush_channels';
export const CSS_HASHES = 'css_hashes';
export const CURRENT_ROUTE_URI = 'current_portal_uri';
export const ENDPOINT_OPTIONS = 'endpoint_options';
export const GLOBAL_STYLES = new InjectionToken<StylesService>('global_styles');
export const PIANO_OPTIONS = 'piano_options';
export const PROD_MODE = 'prod_mode';
export const PUSHER_APP_KEY = 'pusher_app_key';
export const ROUTE_INFO = 'route_info';
export const TV_API = 'tv_api';
export const CORAL_PROD = 'coral_prod';
