import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {IFlourish} from '@model/content/flourish/paylaod';
import {DidomiService} from '@shared/service/didomi.service';
import {VendorTypes} from '@model/enum/vendorTypes';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {LoadExternalScriptService} from '@shared/service/loadExternalScript.service';
import {ScriptId} from '@model/enum/scriptId';
import {BrowserRef} from '@shared/service/browser.ref';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-flourish',
  templateUrl: './flourish.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlourishComponent implements DynamicComponent<IFlourish> {

  vendorType = VendorTypes.flourish;
  id: string;
  @ViewChild('flourish', {static: true}) flourish: ElementRef;
  consent$: Observable<boolean>;
  constructor(
    private didomiService: DidomiService,
    private http: HttpClient,
    private loadScript: LoadExternalScriptService,
    private cd: ChangeDetectorRef,
    private browserRef: BrowserRef,
  ) {
  }

  initModel(model: IFlourish): void {
    console.log('hase', model);
    let url: string;
    if (model.data.url) {
      url = model.data.url;
    }
    if (model.data.uri) {
      url = model.data.uri;
    }

    this.consent$ = this.didomiService.checkVendor$(this.vendorType).pipe(
      tap(res => {
        if (res) {
          if (!this.browserRef.window.Flourish) {
            this.browserRef.window.Flourish = {disable_autoload: true};
          }
          this.loadScript.loadScript(ScriptId.flourish).pipe(
            tap(() => {
              this.id = new URL(url).pathname.split('/').filter(Boolean).join('/');
              this.cd.detectChanges();
              this.browserRef.window.Flourish.loadEmbed(this.flourish.nativeElement);
            })
          ).subscribe();
        }
      })
    );
  }

}
