<ng-container *ngIf="showCleverPush$ | async; else noContent">
  <ng-container *ngIf="isBox">
    <div class="cleverpush-content-button" data-type="box-simple"
         data-text="Indem Sie unsere Benachrichtigungen zulassen, erklären Sie sich damit einverstanden, dass wir Ihre Browser ID nutzen, um Ihnen Push-Benachrichtigungen zukommen zu lassen. Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Weitere Informationen finden Sie in unserer Datenschutzerklärung."
         [attr.data-button-text]="buttonText" [attr.data-button-text-subscribed]="confirmedText"
         data-button-background-color="#000000" data-button-color="#ffffff"></div>
  </ng-container>

  <ng-container *ngIf="!isBox">
    <div class="cleverpush-content-button" data-type="button-simple" [attr.data-button-text]="buttonText"
         [attr.data-button-text-subscribed]="confirmedText" data-button-background-color="#000000"
         data-button-color="#ffffff"></div>
  </ng-container>
</ng-container>

<ng-template #noContent>
  <app-no-consent [vendor]="vendorType"></app-no-consent>
</ng-template>
