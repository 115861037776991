import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../common/dynamicComponent';
import {IAd, IDynamicComponent} from '@model/payload';
import {AdModel} from '@model/ad/ad-model';
import {AdDimensions, AdSize} from '@model/enum/ad';
import {AD_DIMENSIONS} from '@model/const/ad';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'adInContent',
  templateUrl: 'adInContent.component.html'
})
export class AdInContentComponent implements DynamicComponent<IAd> {

  model: AdModel;

  initModel(model: IAd): void {
    const size = model.idx ? AdSize['ad_inContent_' + model.idx] : AdSize.ad_inContent_1;
    const dimensions = [
      AD_DIMENSIONS[AdDimensions.dim_300x250],
      AD_DIMENSIONS[AdDimensions.dim_300x200],
      AD_DIMENSIONS[AdDimensions.dim_fluid]
    ];
    if (model.idx === 2) {
      dimensions.push(AD_DIMENSIONS[AdDimensions.dim_300x251]);
    }
    this.model = new AdModel(
      'adInContent',
      size,
      dimensions,
      false,
      () => window.innerWidth >= 767,
      false,
    );
  }

}
