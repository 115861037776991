import * as tslib_1 from "tslib";
import { BrowserRef } from '../browser.ref';
var BrowserRefBrowser = /** @class */ (function (_super) {
    tslib_1.__extends(BrowserRefBrowser, _super);
    function BrowserRefBrowser(internalDocument) {
        var _this = _super.call(this) || this;
        _this.internalDocument = internalDocument;
        return _this;
    }
    Object.defineProperty(BrowserRefBrowser.prototype, "document", {
        get: function () {
            return this.internalDocument;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BrowserRefBrowser.prototype, "navigator", {
        get: function () {
            return navigator;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BrowserRefBrowser.prototype, "window", {
        get: function () {
            return window;
        },
        enumerable: true,
        configurable: true
    });
    return BrowserRefBrowser;
}(BrowserRef));
export { BrowserRefBrowser };
