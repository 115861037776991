import { ComponentFactoryResolver, OnInit, Renderer2 } from '@angular/core';
import { PortalService } from '@shared/service/portal.service';
import { DeviceDetectionService } from '../../shared/service/device-detection.service';
import { RouteService } from '@shared/service/route.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { MenuService } from '@shared/service/menu.service';
import { BrowserRef } from '@shared/service/browser.ref';
import { GTMTriggerId } from '@model/enum/gtm';
import { NavigationEnd, Router } from '@angular/router';
import { TeasersService } from '@shared/service/teasers.service';
import { OverlayService } from '@shared/service/overlay.service';
import { SearchMenuComponent } from './searchMenu/searchMenu.component';
import { tag } from 'rxjs-spy/operators';
var HeaderContainerComponent = /** @class */ (function () {
    function HeaderContainerComponent(portalService, teasersService, router, browserRef, renderer, mobileDetection, menuService, overlayService, routeService, resolver) {
        var _this = this;
        this.portalService = portalService;
        this.teasersService = teasersService;
        this.router = router;
        this.browserRef = browserRef;
        this.renderer = renderer;
        this.mobileDetection = mobileDetection;
        this.menuService = menuService;
        this.overlayService = overlayService;
        this.routeService = routeService;
        this.resolver = resolver;
        this.showSearchField = false;
        this.logoGtmId = GTMTriggerId.logo;
        this.isKurier = false;
        this.isLustaufoesterreich = false;
        this.gtmId = GTMTriggerId.navbar;
        this.edition = new BehaviorSubject(false);
        this.isTouch = mobileDetection.isTouch;
        this.currentPortal = this.portalService.currentPortal();
        this.alt = this.portalService.hostname() + '';
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }), tap(function () {
            if (_this.router.url.startsWith('/edition')) {
                _this.edition.next(true);
            }
            else {
                _this.edition.next(false);
            }
        })).subscribe();
    }
    Object.defineProperty(HeaderContainerComponent.prototype, "showNavBar$", {
        get: function () {
            return this.edition.asObservable().pipe(map(function (edition) { return !edition; }));
        },
        enumerable: true,
        configurable: true
    });
    HeaderContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        // set Plus link if current portal === kurier.at
        if (this.portalService.currentPortal() === 'kurierat') {
            this.isKurier = true;
        }
        if (this.portalService.currentPortal() === 'lustaufoesterreichat') {
            this.isLustaufoesterreich = true;
        }
        this.model$ = combineLatest([
            this.routeService.routeInfo
                .pipe(filter(Boolean), map(function (route) { return route.channel.url; })),
            this.menuService.get('main'),
            this.menuService.get('social'),
            this.menuService.get('sub'),
        ]).pipe(tag('headerContainer::model$'), map(function (results) {
            return {
                currentUri: results[0],
                homeLink: '//' + _this.portalService.hostname(),
                main: results[1],
                social: results[2],
                subMenu: results[3],
            };
        }));
    };
    HeaderContainerComponent.prototype.closeSearchField = function () {
        if (this.showSearchField) {
            this.toggleSearchField();
        }
    };
    HeaderContainerComponent.prototype.toggleSearchField = function () {
        var _this = this;
        this.showSearchField = !this.showSearchField;
        if (this.showSearchField) {
            var comp = this.overlayService.addComponent(this.resolver, SearchMenuComponent);
            comp.instance.onSelectSearch.pipe(take(1)).subscribe(function () {
                _this.closeSearchField();
            });
        }
        else {
            this.overlayService.clearComponent();
        }
        return false;
    };
    return HeaderContainerComponent;
}());
export { HeaderContainerComponent };
