import {Component, Inject, Renderer2} from '@angular/core';
import {IDegrees23, IDegrees23Api} from '@model/content/degrees23/payload';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ApiService} from '@shared/service/api.service';
import {DidomiService} from '@shared/service/didomi.service';
import {VendorTypes} from '@model/enum/vendorTypes';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'degrees23',
  templateUrl: './degrees23.component.html'
})
export class Degrees23Component implements DynamicComponent<IDegrees23> {
  id: string;
  response$: Observable<SafeHtml>;
  showDegrees23$: Observable<boolean>;
  vendorType = VendorTypes.degrees23;
  imageUrl: string;

  constructor(
    private apiService: ApiService,
    private didomiService: DidomiService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(DOCUMENT) protected document,
  ) {
  }

  initModel(model: IDegrees23): void {
    if (model.data && model.data.id) {
      this.id = model.data.id;
    } else if (model['23degreesId']) {
      this.id = model['23degreesId'];
    }
    this.imageUrl = `https://app.23degrees.io/api/v2/content/${this.id}/image/raw.png`;
    this.showDegrees23$ = this.didomiService.checkVendor$(this.vendorType).pipe(
      tap(consent => {
        if (consent) {
          this.response$ = this.apiService.getCached<IDegrees23Api>(
            'https://app.23degrees.io/services/public/embed/' + this.id,
            false
          ).pipe(
            map(results => {
              const parts = results.data.split('<script src="');
              const html = parts[0];
              const scriptSrc = parts[1].replace('"></script>', '');
              const script = this.renderer.createElement('script');
              script.src = scriptSrc;
              this.renderer.appendChild(this.document.head, script);
              return this.sanitizer.bypassSecurityTrustHtml(html);
            }),
          );
        }
      })
    );
  }

}
