import {Pipe, PipeTransform} from '@angular/core';
import * as striptags from 'striptags';

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {

  transform(value?: string, allowedTags?: string[]): any {
    if (!value) {
      return '';
    }
    return striptags(value, allowedTags);
  }
}
