import {ChangeDetectionStrategy, Component, Inject, PLATFORM_ID, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {ImageType} from '@model/enum/image';
import {TeasersService} from '@shared/service/teasers.service';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {LinkBarModel} from '@model/component/linkBar';
import {ITeaser} from '@model/teaser/payload';
import {BoxModel} from '@model/component/box';
import {TeaserModel} from '@model/teaser/teaser';
import {ISingleTeaser} from '@model/channel/singleTeaser/payload';
import {DeviceDetectionService} from '@shared/service/device-detection.service';
import {PortalService} from '@shared/service/portal.service';
import {PositionType} from '@model/enum/position';
import {ImageModel} from '@model/component/image';
import {isPlatformBrowser} from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'singleteaser',
  templateUrl: 'singleTeaser.component.html'
})
export class SingleTeaserComponent implements DynamicComponent<ISingleTeaser> {

  cmModel: TeaserModel;
  linkbarModel: LinkBarModel;
  teaser$: Observable<ITeaser[]>;
  box: BoxModel;

  constructor(
    private teaserService: TeasersService,
    private mobileDetectionService: DeviceDetectionService,
    private portalService: PortalService,
    private vcRef: ViewContainerRef,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
  }

  initModel(model: ISingleTeaser): void {
    this.teaser$ = this.teaserService.getCollection(model.collectionName, 1).pipe(
      map(teasers => {
        const t = teasers[0];
        if (!t) {
          return;
        }
        this.box = new BoxModel(model, TeaserModel.channel(t));
        this.linkbarModel = LinkBarModel.fromSingleTeaser(model, t, this.box);
        this.cmModel = t.display_authors
          ? TeaserModel.fromMediaTeaser(t, model.teaserView, this.mobileDetectionService.isMobile, model)
          : TeaserModel.fromCardTeaser(t, model.teaserView, ImageType.LS_16_9, this.box);
        this.cmModel.box = this.box;
        if (model.position === PositionType.north && this.portalService.currentPortal() === 'filmat') {
          this.cmModel.image = new ImageModel(t.teaser_img, ImageType.SQ, this.cmModel.link, false, false);
          if (isPlatformBrowser(this.platformId)) {
            if (this.mobileDetectionService.isTablet) {
              this.cmModel.image = new ImageModel(t.teaser_img, ImageType.LS_16_9, this.cmModel.link, false, false);
            }
            requestAnimationFrame(() => {
              const el: Element = this.vcRef.element.nativeElement;
              const img: HTMLImageElement = el.querySelector('img');
              img.onload = () => {
                window.dispatchEvent(new Event('resize'));
              };
            });
          }
        }
        return teasers;
      }),
      filter(Boolean),
    );

  }
}
