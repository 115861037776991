<div>filmat program</div>
<!--<section class="section other-plattforms filmat f-program">-->
  <!--<article class="article list-element-img">-->
    <!--<div class="textParagraph-container-row">-->
      <!--<header class="header">-->
        <!--<h2 class="title">-->
          <!--Filmprogramm-->
          <!--<span>Wilde Maus</span>-->
        <!--</h2>-->
      <!--</header>-->
      <!--<div class="clear"></div>-->
      <!--<form id="filmat-custom-screenings-filter-form" class="cinema-programm" method="post" accept-charset="UTF-8">-->
        <!--<div class="edit-base">-->
          <!--<div class="form-select-box">-->
            <!--<div id="edit-region" class="inputfield cinema-land">-->
              <!--<a rel="nofollow" class="">Alle Bundesländer</a>-->
            <!--</div>-->
            <!--<div id="edit-region-options" class="dropdownmenu dropdownmenu-region" style="display: none;">-->
              <!--<ul>-->
                <!--<li class="clickable">Alle</li>-->
                <!--<li class="clickable">Wien</li>-->
                <!--<li class="clickable">Niederösterreich</li>-->
                <!--<li class="clickable">Burgenland</li>-->
                <!--<li class="clickable">Steiermark</li>-->
                <!--<li class="clickable">Oberösterreich</li>-->
                <!--<li class="clickable">Kärnten</li>-->
                <!--<li class="clickable">Salzburg</li>-->
                <!--<li class="clickable">Tirol</li>-->
                <!--<li class="clickable">Vorarlberg</li>-->
              <!--</ul>-->
            <!--</div>-->
          <!--</div>-->
          <!--<div class="form-select-box">-->
            <!--<div id="edit-date" class="inputfield cinema-day">-->
              <!--<a rel="nofollow" class="">Heute</a>-->
            <!--</div>-->
            <!--<div id="edit-date-options" class="dropdownmenu dropdownmenu-date" style="display: none;">-->
              <!--<ul>-->
                <!--<li class="clickable">Heute</li>-->
                <!--<li class="clickable">Morgen</li>-->
                <!--<li class="clickable">Do, 23.02</li>-->
                <!--<li class="clickable">Fr, 24.02</li>-->
                <!--<li class="clickable">Sa, 25.02</li>-->
              <!--</ul>-->
            <!--</div>-->
          <!--</div>-->
          <!--<div class="form-select-box">-->
            <!--<div id="edit-time" class="inputfield cinema-time">-->
              <!--<a rel="nofollow" class="">Ganzer Tag</a>-->
            <!--</div>-->
            <!--<div id="edit-time-options" class="dropdownmenu dropdownmenu-time" style="display: none;">-->
              <!--<ul>-->
                <!--<li class="clickable">Ganzer Tag</li>-->
                <!--<li class="clickable">bis 16h</li>-->
                <!--<li class="clickable">16 - 18h</li>-->
                <!--<li class="clickable">17 - 19h</li>-->
                <!--<li class="clickable">18 - 20h</li>-->
                <!--<li class="clickable">19 - 21h</li>-->
                <!--<li class="clickable">20 - 22h</li>-->
                <!--<li class="clickable">21 - 23h</li>-->
                <!--<li class="clickable">ab 22h</li>-->
              <!--</ul>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
        <!--<div class="edit-enhanced">-->
          <!--<div>-->
            <!--<input id="edit-3d" name="choice[]" type="checkbox">-->
            <!--<label class="choice" for="edit-3d">-->
                            <!--<span class="check">-->
                                <!--<span></span>-->
                            <!--</span>-->
              <!--<span>3D</span>-->
            <!--</label>-->
          <!--</div>-->
          <!--<div>-->
            <!--<input id="edit-ov" name="choice[]" type="checkbox">-->
            <!--<label class="choice" for="edit-ov">-->
                            <!--<span class="check">-->
                                <!--<span></span>-->
                            <!--</span>-->
              <!--<span>OV</span>-->
            <!--</label>-->
          <!--</div>-->
        <!--</div>-->
        <!--<div class="clear"></div>-->
      <!--</form>-->
      <!--<a class="isolating-link" href="#"></a>-->
      <!--<a-->
        <!--href="https://www.film.at/pia-hierzegger-ueber-josef-haders-wilde-maus-als-menschen-sind-wir-staendig-irgendwie-laecherlich"-->
        <!--title="Filmkritik Wilde Maus" class="link">Mehr zum Film</a>-->
    <!--</div>-->
    <!--<div class="clear"></div>-->
    <!--<div class="textParagraph-container-row">-->
      <!--<ul class="film-list">-->
        <!--<li>-->
          <!--<header>-->
            <!--<span class="cinema">Apollo</span>-->
          <!--</header>-->
          <!--<div>-->
            <!--<span class="playtime">15:45</span>-->
            <!--<span class="playtime">17:15</span>-->
            <!--<span class="playtime">18:00</span>-->
          <!--</div>-->
        <!--</li>-->
        <!--<li>-->
          <!--<header>-->
            <!--<span class="cinema">Apollo Stockerau</span>-->
          <!--</header>-->
          <!--<div>-->
            <!--<span class="playtime">18:20</span>-->
            <!--<span class="playtime">20:15</span>-->
          <!--</div>-->
        <!--</li>-->
        <!--<li>-->
          <!--<header>-->
            <!--<span class="cinema">Cine Center</span>-->
          <!--</header>-->
          <!--<div>-->
            <!--<span class="playtime">16:00</span>-->
          <!--</div>-->
        <!--</li>-->
      <!--</ul>-->
    <!--</div>-->
  <!--</article>-->
<!--</section>-->

