import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { RelatedService } from '@shared/service/related.service';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { TeaserModel } from '@model/teaser/teaser';
import { ShareModel } from '@model/component/share';
import { PortalService } from '@shared/service/portal.service';
import { LdJsonService } from '@shared/service/ldJson.service';
import { ArticleModel } from '@model/content/article/article';
import { TeasersService } from '@shared/service/teasers.service';
import { MovieSerieModel } from '@model/movieSerie/movieSerie';
import { ApiService } from '@shared/service/api.service';
import { TeaserView } from '@model/teaser/teaserView';
import { PianoService } from '@shared/service/piano/piano.service';
import { DeviceDetectionService } from '@shared/service/device-detection.service';
import { VendorTypes } from '@model/enum/vendorTypes';
import { DidomiService } from '@shared/service/didomi.service';
import { LinkService } from '@shared/service/link.service';
import { IMG_SRC } from '@model/const/image';
import { ImageService } from '@shared/service/image.service';
import { ImageSrc } from '@model/enum/image';
var ArticleComponent = /** @class */ (function () {
    function ArticleComponent(platformId, cd, portalService, ldJsonService, piano, teaserService, relatedService, apiService, mobileDetectionService, didomiService, link, imageService) {
        var _this = this;
        this.platformId = platformId;
        this.cd = cd;
        this.portalService = portalService;
        this.ldJsonService = ldJsonService;
        this.piano = piano;
        this.teaserService = teaserService;
        this.relatedService = relatedService;
        this.apiService = apiService;
        this.mobileDetectionService = mobileDetectionService;
        this.didomiService = didomiService;
        this.link = link;
        this.imageService = imageService;
        this.paragraphs = [];
        this.destroy$ = new Subject();
        this.paidVendor = VendorTypes.piano;
        this.paidVendorText = 'Um diesen Artikel lesen zu können, würden wir Ihnen gerne die Anmeldung für unser Plus Abo zeigen. Leider haben Sie' +
            ' uns hierfür keine Zustimmung gegeben. Wenn Sie diese anzeigen wollen, stimmen sie bitte Piano Software Inc. zu.';
        this.pianoOffer$ = this.didomiService.checkVendor$(VendorTypes.piano).pipe(map(function (consent) { return !consent && _this.portalService.currentPortal() === 'kurierat'; }));
        this.showComments = true;
    }
    Object.defineProperty(ArticleComponent.prototype, "hasOutbain", {
        get: function () {
            return !!this.paragraphs.find(function (p) { return p.type === 'outbrain'; });
        },
        enumerable: true,
        configurable: true
    });
    ArticleComponent.prototype.ngOnInit = function () {
        if (this.article) {
            this.initModel(this.article);
            this.articleType = 'story';
        }
    };
    ArticleComponent.prototype.initModel = function (model) {
        var _this = this;
        if (model.channel.url === '/kurierat/romy') {
            this.romyNavigation$ = this.apiService.collectionTeasersCached('romy_2023_nominierte_static', 0, 50)
                .pipe(takeUntil(this.destroy$), map(function (collection) { return collection.items.map(function (teaser) { return teaser; }).filter(function (teaser) { return teaser.url !== undefined; }); }), map(function (teasers) {
                var index = teasers.findIndex(function (teaser) { return teaser.url === model.url; });
                var nav = [];
                var pushToArr = function (i) {
                    var navItem = teasers[i];
                    if (navItem.title.includes('2023:')) {
                        navItem.title = navItem.title.split('2023:')[1].split(',')[0];
                    }
                    nav.push(navItem);
                };
                if (index > 0) {
                    pushToArr(index - 1);
                }
                else {
                    pushToArr(teasers.length - 1);
                }
                if (index < teasers.length - 1) {
                    pushToArr(index + 1);
                }
                else {
                    pushToArr(0);
                }
                return nav;
            }));
        }
        this.pianoConsent$ = this.didomiService.checkVendor$(this.paidVendor).pipe(map(function (consent) { return !consent; }));
        this.shareModel = ShareModel.fromArticle(model, 'https://' + this.portalService.hostname() + model.url);
        this.shareModelBottom = ShareModel.fromArticle(model, 'https://' + this.portalService.hostname() + model.url);
        this.articleModel = ArticleModel.fromTeaser(model, model);
        if (this.articleModel.imageModel && this.articleModel.imageModel.name.endsWith('.gif')) {
            this.articleModel.imageModel.src = this.imageService.gifUrl(model.teaser_img.url);
        }
        else if (this.articleModel.imageModel) {
            this.articleModel.imageModel.src = this.imageService
                .url(this.articleModel.imageModel.url, IMG_SRC[this.articleModel.imageModel.size]);
            this.articleModel.imageModel.srcset = this.imageService.srcset(this.articleModel.imageModel.url, this.articleModel.imageModel.size);
            if (model.teaser_img && model.teaser_img.url) {
                var linkTag = {
                    imagesizes: model.type === 'article' ?
                        '(min-width: 769px) calc(100vw - 332px), (min-width: 965px) 616px, calc(100vw - 32px)' :
                        '(min-width: 965px) 932px, calc(100vw - 32px)',
                    imagesrcset: this.articleModel.imageModel.srcset,
                    href: this.imageService.url(model.teaser_img.url, ImageSrc.V_1864),
                    as: 'image',
                    rel: 'preload',
                };
                this.link.addTag(linkTag);
            }
        }
        this.ldJsonService.injectArticleTags(model);
        this.articleType = model.type;
        if (model.sponsored || model.hide_comments) {
            this.showComments = false;
        }
        if (model.paid_content) {
            this.piano.checkPaywallAccess().pipe(takeUntil(this.destroy$), tap(function (access) {
                _this.articleModel.paid = !access;
                var googleNews = navigator.userAgent === 'Googlebot-News';
                if (googleNews && _this.articleModel.paid) {
                    _this.paragraphs = _this.paragraphs.slice(0, 3);
                }
                else {
                    var paywallParagraphPosition_1 = model.paragraphs.findIndex(function (p) { return p.type === 'paywall'; });
                    _this.paragraphs = tslib_1.__spread(model.paragraphs.map(function (p, i) {
                        p.displayNone = i > paywallParagraphPosition_1
                            && !access && p.type !== 'newsletter_signup'
                            && p.type !== 'adInContent'
                            && p.type !== 'outbrain';
                        return p;
                    }));
                }
                _this.cd.markForCheck();
            })).subscribe();
        }
        else {
            this.paragraphs = model.paragraphs;
        }
        if (model.filmat) {
            if (model.filmat.movie) {
                this.relatedMovie$ = this.teaserService.getTeaser(model.filmat.movie).pipe(map(function (teaser) {
                    return MovieSerieModel.deserialize(teaser);
                }), tap(function (teaser) {
                    var uri = '/' + teaser.link.portal + teaser.link.path;
                    _this.apiService.route(uri).pipe(map(function (res) {
                        return res.layout.center[0];
                    }), tap(function (res) {
                        _this.ldJsonService.injectFilmTags(res);
                    })).subscribe();
                }));
            }
            if (model.filmat.editor_review) {
                this.articleModel.setRating(model.filmat.editor_review);
                this.articleModel.editorRating = model.filmat.editor_review;
            }
        }
        if (model.show_relateds) {
            this.relatedArticles$ = this.relatedService.forArticle(model.id, 3).pipe(map(function (teasers) { return TeaserModel
                .fromMediaTeasers(teasers, new TeaserView(true, true, false, false), _this.mobileDetectionService.isMobile); }));
        }
        if (model.id === '400573673') {
            this.showOffer = true;
        }
    };
    Object.defineProperty(ArticleComponent.prototype, "hasCoral", {
        get: function () {
            return (this.portalService.currentPortal() === 'filmat' ||
                this.portalService.currentPortal() === 'motorat' ||
                this.portalService.currentPortal() === 'kurierat') && this.showComments;
        },
        enumerable: true,
        configurable: true
    });
    ArticleComponent.prototype.showAdFree = function () {
        this.piano.showAdFree();
    };
    ArticleComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
    };
    ArticleComponent.prototype.trackByFn = function (index, paragraph) {
        return JSON.stringify(paragraph);
    };
    return ArticleComponent;
}());
export { ArticleComponent };
