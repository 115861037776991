export var GameType;
(function (GameType) {
    GameType["overall"] = "overall";
    GameType["home"] = "home";
    GameType["away"] = "away";
})(GameType || (GameType = {}));
var FootballStandingRow = /** @class */ (function () {
    function FootballStandingRow() {
    }
    Object.defineProperty(FootballStandingRow.prototype, "diff", {
        get: function () {
            return this.goalsScored - this.goalsAgainst;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FootballStandingRow.prototype, "goals", {
        get: function () {
            return this.goalsScored + ':' + this.goalsAgainst;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FootballStandingRow.prototype, "points", {
        get: function () {
            if (this.manualPoints) {
                return this.manualPoints;
            }
            return this.s * 3 + this.u;
        },
        enumerable: true,
        configurable: true
    });
    FootballStandingRow.fromStandings = function (standings, teams, previousStandings, gameType) {
        if (gameType === void 0) { gameType = GameType.overall; }
        return standings.standings.data.map(function (st) {
            var standing = new FootballStandingRow();
            standing.pl = st.position;
            standing.status = 'equal';
            if (previousStandings) {
                var prevStanding = previousStandings.find(function (prevSt) { return prevSt.team_name === st.team_name; });
                if (prevStanding && prevStanding.position < st.position) {
                    standing.status = 'down';
                }
                if (prevStanding && prevStanding.position > st.position) {
                    standing.status = 'up';
                }
            }
            var team = teams.data.find(function (t) { return t.id === st.team_id; });
            if (team && team.logo_path) {
                FootballStandingRow.setLogo(standing, team.logo_path);
            }
            FootballStandingRow.setName(standing, st.team_name);
            standing.sp = st[gameType].games_played;
            standing.s = st[gameType].won;
            standing.u = st[gameType].draw;
            standing.n = st[gameType].lost;
            standing.goalsAgainst = st[gameType].goals_against;
            standing.goalsScored = st[gameType].goals_scored;
            return standing;
        });
    };
    FootballStandingRow.fromAustrianStandings = function (standings, teams, regularSeason, gameType) {
        if (gameType === void 0) { gameType = GameType.overall; }
        var regularSeasonStandings;
        if (regularSeason) {
            regularSeasonStandings = regularSeason.standings.data;
        }
        var rows = standings.standings.data.map(function (st) {
            var standing = new FootballStandingRow();
            standing.pl = st.position;
            standing.status = 'equal';
            var team = teams.data.find(function (t) { return t.id === st.team_id; });
            if (team && team.logo_path) {
                FootballStandingRow.setLogo(standing, team.logo_path);
            }
            FootballStandingRow.setName(standing, st.team_name);
            standing.sp = st[gameType].games_played;
            standing.s = st[gameType].won;
            standing.u = st[gameType].draw;
            standing.n = st[gameType].lost;
            standing.goalsAgainst = st[gameType].goals_against;
            standing.goalsScored = st[gameType].goals_scored;
            standing.manualPoints = st[gameType].points;
            // if (regularSeasonStandings) {
            //   const currRow = regularSeasonStandings.find(gst => gst.team_name === st.team_name);
            //   standing.sp = currRow[gameType].games_played + st[gameType].games_played;
            //   standing.s = currRow[gameType].won + st[gameType].won;
            //   standing.u = currRow[gameType].draw + st[gameType].draw;
            //   standing.n = currRow[gameType].lost + st[gameType].lost;
            //   standing.goalsAgainst = currRow[gameType].goals_against + st[gameType].goals_against;
            //   standing.goalsScored = currRow[gameType].goals_scored + st[gameType].goals_scored;
            // }
            return standing;
        });
        rows.map(function (row, i) {
            row.pl = i + 1;
        });
        return rows;
    };
    FootballStandingRow.fromDateStanding = function (standings, gameType, previousStandings) {
        if (gameType === void 0) { gameType = GameType.overall; }
        return standings.data.map(function (st) {
            var standing = new FootballStandingRow();
            standing.status = 'equal';
            if (previousStandings) {
                var lastStanding = previousStandings.data.find(function (s) { return s.team_name === st.team_name; });
                if (lastStanding && st.position < lastStanding.position) {
                    standing.status = 'up';
                }
                if (lastStanding && st.position > lastStanding.position) {
                    standing.status = 'down';
                }
            }
            standing.pl = st.position;
            FootballStandingRow.setLogo(standing, st.team_logo);
            FootballStandingRow.setName(standing, st.team_name);
            standing.sp = st[gameType].games_played;
            standing.s = st[gameType].won;
            standing.u = st[gameType].draw;
            standing.n = st[gameType].lost;
            standing.goalsAgainst = st[gameType].goals_against;
            standing.goalsScored = st[gameType].goals_scored;
            return standing;
        });
    };
    FootballStandingRow.fromGroupStanding = function (standings, teams, gameType, previousStandings) {
        if (gameType === void 0) { gameType = GameType.overall; }
        return standings.map(function (st) {
            var standing = new FootballStandingRow();
            standing.status = 'equal';
            if (previousStandings) {
                var lastStanding = previousStandings.data.find(function (s) { return s.team_name === st.team_name; });
                if (st.position < lastStanding.position) {
                    standing.status = 'up';
                }
                if (st.position > lastStanding.position) {
                    standing.status = 'down';
                }
            }
            standing.pl = st.position;
            var team = teams.data.find(function (t) { return t.id === st.team_id; });
            if (team && team.logo_path) {
                standing.logo = team.logo_path;
            }
            standing.name = st.team_name;
            standing.sp = st[gameType].games_played;
            standing.s = st[gameType].won;
            standing.u = st[gameType].draw;
            standing.n = st[gameType].lost;
            standing.goalsAgainst = st[gameType].goals_against;
            standing.goalsScored = st[gameType].goals_scored;
            return standing;
        });
    };
    FootballStandingRow.setName = function (standing, teamName) {
        if (teamName === 'Rapid Vienna') {
            standing.name = 'Rapid Wien';
        }
        else if (teamName === 'LASK Linz') {
            standing.name = 'LASK';
        }
        else {
            standing.name = teamName;
        }
    };
    FootballStandingRow.setLogo = function (standing, teamLogo) {
        standing.logo = teamLogo === 'https://cdn.sportmonks.com/images/soccer/teams/9/3369.png' ?
            '/assets/images/football/lask.png' : teamLogo;
    };
    return FootballStandingRow;
}());
export { FootballStandingRow };
