import {Inject, Injectable, RendererFactory2} from '@angular/core';
import {LinkService} from '../link.service';
import {StateService} from '../state.service';
import {PortalService} from '../portal.service';
import {StylesService} from './styles.service';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
import * as smoothscroll from 'smoothscroll-polyfill';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class StylesBrowserService implements StylesService {

  constructor(private linkService: LinkService,
              private stateService: StateService<any>,
              private portalService: PortalService,
              private rendererFactory: RendererFactory2,
              @Inject(DOCUMENT) private document,
  ) {
  }

  addStylesSync() {
    if (!this.stateService.hadUniversal) {
      const key = this.portalService.currentPortal();
      const href = '/' + key + '.css';
      const globalStyle = {
        rel: 'stylesheet',
        href
      };
      this.linkService.addTag(globalStyle);
      const renderer = this.rendererFactory.createRenderer(null, null);
      const commentsScript = renderer.createElement('script');
      renderer.setAttribute(commentsScript, 'commentsCss', this.portalService.currentPortal() + '-comments.css');
      renderer.appendChild(this.document.head, commentsScript);
    }
  }

  sliderPolyfill(): void {
    smoothscroll.polyfill();
  }
}
