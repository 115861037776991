import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {TvImageModel} from '@component/tv/kurier/tvImage';

@Component({
  selector: 'app-tv-image',
  template: `
    <img
      *ngIf="model.src"
      class="lazyload"
      data-sizes="auto"
      [srcset]="model.srcSet"
      [src]="model.src"
      [alt]="model.alt">
    <div class="placeholder" *ngIf="!model.src"></div>
  `,
  styleUrls: ['./tv-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TvImageComponent implements OnInit {

  @Input() model: TvImageModel;

  constructor() {
  }

  ngOnInit() {
  }

}
