import {Component, Input, OnInit} from '@angular/core';
import {ImageService} from '@shared/service/image.service';

@Component({
  selector: 'teaser-video',
  template: `
    <video controls [src]="hostUrl"></video>
  `,
})
export class TeaserVideoComponent implements OnInit {

  @Input() url: string;
  hostUrl: string;

  constructor(private imageService: ImageService) {
  }

  ngOnInit(): void {
    this.hostUrl = this.imageService.videoUrl(this.url);
  }

}
