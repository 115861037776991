import {Inject, Injectable} from '@angular/core';
import {IScriptValue, LoadExternalScriptService} from './loadExternalScript.service';
import {PROD_MODE, PUSHER_APP_KEY} from './tokens';
import {StateService} from './state.service';
import {BrowserRef} from './browser.ref';
import {ScriptId} from '../../model/enum/scriptId';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PusherService {

  protected pusher: any;

  constructor(@Inject(PROD_MODE) private prodmode: boolean,
              private browserRef: BrowserRef,
              private stateService: StateService<string>,
              private loadScript: LoadExternalScriptService) {
  }

  init(): Observable<IScriptValue> {
    const pusherAppKey = this.stateService.get(PUSHER_APP_KEY);
    return this.loadScript.loadScript(ScriptId.pusher).pipe(
      tap(v => {
        v.scriptVar.logToConsole = !this.prodmode;
        this.pusher = new v.scriptVar(pusherAppKey, {
            encrypted: true,
            cluster: 'eu'
          }
        );
        // tslint:disable-next-line:no-string-literal
        this.browserRef.window['pusher'] = this.pusher;
      }),
    );
  }

  onChannel(channelName: string, callback) {

    if (this.pusher) {
      const channel = this.pusher.subscribe(channelName);
      channel.bind_global(
        (event: string, data: any) => {
          callback.call(undefined, event, data);
        }
      );
      return channel;
    }
  }

  unsubscribe(channel) {
    channel.unbind();
  }
}
