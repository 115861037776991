import {Directive, ElementRef, HostBinding, HostListener, Input, OnChanges} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {BrowserRef} from '../service/browser.ref';
import {PortalRoute} from '../../model/teaser/portalRoute';
import {PortalService} from '../service/portal.service';

/**
 * see https://github.com/angular/angular/blob/2.2.x/modules/@angular/router/src/directives/router_link.ts
 */
@Directive({
  selector: 'a[portalRouterLink]'
})
export class PortalRouteDirective implements OnChanges {

  @HostBinding() target: string;

  // the url displayed on the anchor element.
  @HostBinding() href: string;

  private targetRoute: PortalRoute;

  constructor(private router: Router,
              protected el: ElementRef,
              protected portalService: PortalService,
              private browserRef: BrowserRef) {
  }

  @Input()
  set portalRouterLink(targetRoute: PortalRoute) {
    this.targetRoute = targetRoute;
  }

  get urlTree(): UrlTree {
    return this.router.createUrlTree([this.targetRoute.path], {fragment: this.targetRoute.hash});
  }

  ngOnChanges(changes: {}): any {
    this.updateTargetUrlAndHref();
  }

  @HostListener('click', ['$event.button', '$event.ctrlKey', '$event.metaKey'])
  onClick(button: number, ctrlKey: boolean, metaKey: boolean): boolean {
    if (this.el.nativeElement.preventPortalRouterLink === true) {
      return false;
    }

    if (button !== 0 || ctrlKey || metaKey) {
      return true;
    }

    if (typeof this.target === 'string' && this.target !== '_self') {
      return true;
    }

    if (!this.targetRoute) {
      return true;
    }

    this.router.navigate([this.targetRoute.path], {
      preserveQueryParams: true
    });

    this.browserRef.window.scrollTo(0, 0);
    return false;
  }

  private updateTargetUrlAndHref(): void {
    if (!this.targetRoute) {
      this.href = '#';
      return;
    }
    if (this.portalService.samePortal(this.targetRoute)) {
      this.href = this.router.serializeUrl(this.urlTree);
      this.target = '_self';
    } else {
      this.href = this.portalService.urlOfRoute(this.targetRoute);
      this.target = '_blank';
    }
  }
}
