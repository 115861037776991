/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i2 from "../../../common/ui/linkBar/linkBar.component";
import * as i3 from "@angular/common";
import * as i4 from "./resultTopics.component";
import * as i5 from "../../../../shared/service/channelSearch.service";
import * as i6 from "../../../../shared/service/portal.service";
var styles_ResultTopicsComponent = [];
var RenderType_ResultTopicsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResultTopicsComponent, data: {} });
export { RenderType_ResultTopicsComponent as RenderType_ResultTopicsComponent };
function View_ResultTopicsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "linkbar", [["class", "resultTopics-linkBar"]], null, null, null, i1.View_LinkBarComponent_0, i1.RenderType_LinkBarComponent)), i0.ɵdid(1, 49152, null, 0, i2.LinkBarComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ResultTopicsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "resultTopics"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "linkbar", [["class", "resultTopics-linkBar"]], null, null, null, i1.View_LinkBarComponent_0, i1.RenderType_LinkBarComponent)), i0.ɵdid(2, 49152, null, 0, i2.LinkBarComponent, [], { model: [0, "model"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResultTopicsComponent_3)), i0.ɵdid(4, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkBarModel; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.ngIf; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ResultTopicsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResultTopicsComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.ngIf.length > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ResultTopicsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_ResultTopicsComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.topics$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ResultTopicsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "searchResultTopics", [], null, null, null, View_ResultTopicsComponent_0, RenderType_ResultTopicsComponent)), i0.ɵdid(1, 49152, null, 0, i4.ResultTopicsComponent, [i5.ChannelSearchService, i6.PortalService], null, null)], null, null); }
var ResultTopicsComponentNgFactory = i0.ɵccf("searchResultTopics", i4.ResultTopicsComponent, View_ResultTopicsComponent_Host_0, {}, {}, []);
export { ResultTopicsComponentNgFactory as ResultTopicsComponentNgFactory };
