import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {IDataUrl} from '@model/content/payload';
import {DidomiService} from '@shared/service/didomi.service';
import {VendorTypes} from '@model/enum/vendorTypes';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'soundcloud',
  templateUrl: 'soundcloud.component.html'
})
export class SoundCloudComponent implements DynamicComponent<IDataUrl> {

  embedUrl: SafeResourceUrl;
  url: string;
  vendorType = VendorTypes.soundCloud;
  showSoundCloud$: Observable<boolean>;

  constructor(
    private sanitizer: DomSanitizer,
    private didomiService: DidomiService,
  ) {
  }

  initModel(model: IDataUrl): void {
    this.showSoundCloud$ = this.didomiService.checkVendor$(this.vendorType)
      .pipe(
        tap(consent => {
          if (consent) {
            this.url = encodeURIComponent(model.data.url);
            this.embedUrl = this.sanitizer
              .bypassSecurityTrustResourceUrl('https://w.soundcloud.com/player/?url=' + this.url + '&color=0066cc');
          }
        }),
      );
  }
}
