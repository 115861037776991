import { ShareType } from '../enum/share';
var ShareModel = /** @class */ (function () {
    function ShareModel() {
    }
    ShareModel.fromArticle = function (article, url) {
        var s = ShareModel.fromUrl(url);
        s.url = url;
        s.twitter = "https://twitter.com/share?size=large&url=" + s.url + "&text=" + article.title;
        s.mailto = "mailto:?subject=" + article.title + "-" + article.portal + "&body=Ihr Link auf " + article.portal + ": " + s.url;
        s.facebook = "http://www.facebook.com/share.php?u=" + s.url + "&title=" + article.title;
        s.facebookMessenger = "fb-messenger://share/?link=" + s.url + "&app_id=416108785115054";
        // s.flipboard = `https://share.flipboard.com/bookmarklet/popout?v=2&title=${article.title}&url=${s.url}`;
        s.pocket = "https://getpocket.com/edit?url=" + s.url;
        return s;
    };
    ShareModel.fromMovie = function (movie, url) {
        var s = ShareModel.fromUrl(url);
        s.twitter = "https://twitter.com/share?size=large&url=" + s.url + "&text=" + movie.title;
        s.mailto = "mailto:?subject=" + movie.title + "-filmat&body=Ihr Link auf Film.at: " + s.url;
        s.facebook = "http://www.facebook.com/share.php?u=" + s.url + "&title=" + movie.title;
        return s;
    };
    ShareModel.fromUrl = function (url) {
        var s = new ShareModel();
        s.url = url;
        s.xing = "https://www.xing.com/spi/shares/new?url=" + s.url;
        s.pinterest = "http://pinterest.com/pin/create/button/?url=" + s.url;
        s.linkedin = "http://www.linkedin.com/shareArticle?mini=true&url=" + s.url;
        s.whatsapp = "http://api.whatsapp.com/send?text=" + s.url;
        s.googleplus = "https://plus.google.com/share?url=" + s.url;
        s.flipboard = "https://share.flipboard.com/bookmarklet/popout?v=2&url=" + s.url;
        s.pocket = "https://getpocket.com/edit?url=" + s.url;
        return s;
    };
    ShareModel.fromAuthor = function (a) {
        var s = new ShareModel();
        if (a.twitter) {
            s.twitter = 'https://twitter.com/' + a.twitter;
        }
        if (a.facebook) {
            s.facebook = 'https://www.facebook.com/' + a.facebook;
        }
        return s;
    };
    ShareModel.prototype.addMetaInfo = function (metas) {
        if (this.twitter) {
            var sitemetas = metas.filter(function (meta) { return meta.name === 'twitter:site'; });
            if (sitemetas.length) {
                this.twitter += '&via=' + sitemetas[0].content.replace(/^@/, '');
            }
        }
        if (this.pinterest) {
            var imgMeta = metas.filter(function (meta) { return meta.property === 'og:image'; });
            var imageUrl = imgMeta && imgMeta.length > 0 ? '&media=' + imgMeta[0].content : '';
            this.pinterest += imageUrl;
        }
    };
    Object.defineProperty(ShareModel.prototype, "links", {
        get: function () {
            return Object.keys(this).filter(function (prop) { return prop !== undefined && prop !== 'url'; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShareModel.prototype, "allLinks", {
        get: function () {
            return [
                ShareType.facebook,
                // ShareType.facebookMessenger,
                ShareType.twitter,
                ShareType.whatsapp,
                ShareType.mailto,
            ];
        },
        enumerable: true,
        configurable: true
    });
    return ShareModel;
}());
export { ShareModel };
