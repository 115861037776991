import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Inject,
  Input, OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {GTMTriggerId} from '@model/enum/gtm';
import {SliderConfigModel} from '@model/component/sliderConfig';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {StylesService} from '@shared/service/styles/styles.service';
import {GLOBAL_STYLES} from '@shared/service/tokens';
import {BehaviorSubject, Subscription} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import {PortalService} from '@shared/service/portal.service';
import {SliderService} from '@shared/service/slider.service';

enum ButtonDisabledState {
  none,
  start,
  end,
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'slider',
  templateUrl: './slider.component.html',
  animations: [
    trigger('adaptiveHeightAnimation', [
      state('oldHeight', style({
        height: '{{oldHeight}}'
      }), {params: {oldHeight: '*'}}),
      state('newHeight', style({
        height: '{{newHeight}}'
      }), {params: {newHeight: '*'}}),
      transition('oldHeight <=> newHeight', animate('300ms ease-in-out'))
    ])
  ],
})

export class SliderComponent implements AfterContentInit, OnInit, AfterContentChecked, OnDestroy {

  @Input() sliderConfigModel: SliderConfigModel;
  @Output() currSlideNumber = new EventEmitter<number>();
  @ContentChildren('sliderItem') sliderItems: QueryList<ElementRef>;
  @ViewChild('slider', {static: true}) slider: ElementRef;
  sliderHeight: number;
  currSlide$ = new BehaviorSubject<number>(1);
  buttonDisabled = new BehaviorSubject<ButtonDisabledState>(ButtonDisabledState.none);
  buttonDisabledState = ButtonDisabledState;
  sliderTotalCount: number;
  animationChange = false;
  start = true;
  slideItemWidth: number;
  intersectionRatio = 0.75;
  observer: IntersectionObserver;
  gtmId = GTMTriggerId.teaser_headline;

  // Special shit for adaptiveHeight Slider
  initialX = 0;
  initialY = 0;
  interval: number;
  focus = new BehaviorSubject<boolean>(false);
  subscription = new Subscription();

  constructor(
    @Inject(GLOBAL_STYLES) private stylesService: StylesService,
    @Inject(PLATFORM_ID) private platformId: string,
    private ref: ChangeDetectorRef,
    private portalService: PortalService,
    private sliderService: SliderService,
  ) {
  }

  get transPos() {
    const currSlide = this.currSlide$.value - 1;
    return currSlide * -100 + '%';
  }

  ngOnInit() {
    this.stylesService.sliderPolyfill();

    // Special shit for adaptiveHeight Slider
    if (this.sliderConfigModel.adaptiveHeight) {
      const slider = this.slider.nativeElement;
      slider.addEventListener('touchstart', this.startTouch.bind(this), false);
      slider.addEventListener('touchmove', this.moveTouch.bind(this), false);
    }
    if (this.portalService.currentPortal() === 'lustaufoesterreichat') {
      this.setAutoSlide();
      this.setKeyBoardNavigation();
    }
  }

  setKeyBoardNavigation() {
    this.subscription.add(this.focus.asObservable().subscribe(focus => {
      if (focus) {
        window.addEventListener('keydown', (event) => {
          if (event.key === 'ArrowRight') {
            this.scrollTo(false);
          } else if (event.key === 'ArrowLeft') {
            this.scrollTo(true);
          }
          this.ref.markForCheck();
        });
      }
    }));
  }

  setAutoSlide() {
    if (isPlatformBrowser(this.platformId)) {
      this.interval = window.setInterval(() => {
        this.scrollTo(false);
        if (this.currSlide$.value === this.sliderItems.length) {
          this.currSlide$.next(1);
        }
        this.ref.markForCheck();
      }, 5000);
    }
  }

  ngAfterContentInit() {

    if (isPlatformBrowser(this.platformId)) {
      this.currSlide$.subscribe(val => {
        this.currSlideNumber.emit(val);
        this.setSliderHeight(val);
      });

      this.sliderTotalCount = this.sliderItems.length;

      const options = {
        root: this.slider.nativeElement,
        rootMargin: '0px',
        threshold: this.intersectionRatio
      };

      this.observer = new IntersectionObserver((entries) => {
        if (entries.length) {
          const intersecting = entries.filter(entry => entry.isIntersecting === true);
          if (intersecting[0]) {
            this.sliderItems.forEach((item, index) => {
              if (item.nativeElement === intersecting[0].target) {
                this.slideItemWidth = intersecting[0].target.clientWidth;
                if (intersecting[0].intersectionRatio >= this.intersectionRatio) {
                  this.currSlide$.next(index + 1);
                  this.setButtonDisabledState();
                }
              }
            });
          }
        }
      }, options);

      this.sliderItems.forEach(item => {
        this.observer.observe(item.nativeElement);
      });

      // scroll to starting Point if defined in model
      let start = this.sliderConfigModel.start;
      if (window.location.pathname === '/romy') {
        start = this.sliderService.previousSlideValue;
      }
      if (start) {
        const slider = this.slider.nativeElement;
        setTimeout(() => {
          slider.scroll({top: 0, left: start});
        }, 100);
      }

      const slideIndex = this.sliderConfigModel.slideIndexStart;
      if (slideIndex) {
        let iteration = 0;
        const interval = setInterval(() => {
          iteration++;
          this.scrollTo(false);
          if (iteration === slideIndex) {
            clearInterval(interval);
          } else if (iteration <= 0) {
            clearInterval(interval);
          }
        }, 1000);
      }
    }
  }

  ngAfterContentChecked() {
    this.setSliderHeight(this.currSlide$.value);
  }

  setSliderHeight(slideNumber): void {
    if (this.sliderConfigModel.adaptiveHeight === true) {
      const currentElement = this.sliderItems.find((el, i) => {
        return i === slideNumber - 1;
      });
      const height = currentElement.nativeElement.offsetHeight;
      if (this.sliderHeight !== height) {
        this.animationChange = !this.animationChange;
        this.sliderHeight = height;
        this.ref.detectChanges();
      }
    }
  }

  private setButtonDisabledState() {
    if (!this.sliderConfigModel.adaptiveHeight) {
      const slider = this.slider.nativeElement;
      setTimeout(() => {
        if (slider.scrollLeft === 0) {
          this.buttonDisabled.next(ButtonDisabledState.start);
        } else if (slider.scrollWidth <= Math.ceil(slider.scrollLeft + slider.offsetWidth)) {
          this.buttonDisabled.next(ButtonDisabledState.end);
        } else {
          this.buttonDisabled.next(ButtonDisabledState.none);
        }
        this.ref.markForCheck();
      }, 500);
    }
  }

  // Function for next prev click
  public scrollTo(direction) {
    const slider = this.slider.nativeElement;
    const stepSize = this.sliderConfigModel.stepsize;
    let amount = this.slideItemWidth;
    const currPos = slider.scrollLeft;
    let overage = currPos % amount;
    if (stepSize) {
      amount = slider.offsetWidth * (stepSize / 100);
      overage = 0;
    }
    const currSnapPos = currPos - overage;
    if (!this.sliderConfigModel.adaptiveHeight) {
      if (direction) {
        slider.scroll({top: 0, left: currSnapPos - amount, behavior: 'smooth'});
        this.sliderService.previousSlideValue = currSnapPos - amount;
      } else {
        slider.scroll({top: 0, left: currSnapPos + amount, behavior: 'smooth'});
        this.sliderService.previousSlideValue = currSnapPos + amount;
      }
    } else {
      if (direction) {
        if (this.currSlide$.value > 1) {
          this.currSlide$.next(this.currSlide$.value - 1);
        }
      } else {
        if (this.currSlide$.value < this.sliderTotalCount) {
          this.currSlide$.next(this.currSlide$.value + 1);
        }
      }
    }
  }

  startTouch(e) {
    this.initialX = e.touches[0].clientX;
    this.initialY = e.touches[0].clientY;
  }

  moveTouch(e) {
    if (this.initialX === null) {
      return;
    }

    if (this.initialY === null) {
      return;
    }

    const currentX = e.touches[0].clientX;
    const currentY = e.touches[0].clientY;

    const diffX = this.initialX - currentX;
    const diffY = this.initialY - currentY;

    if (Math.abs(diffX) > Math.abs(diffY)) {
      if (diffX > 0) {
        this.scrollTo(false);
      } else {
        this.scrollTo(true);
      }
      e.preventDefault();
    }

    this.initialX = null;
    this.initialY = null;
  }

  mouseEnter() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  mouseLeave() {
    if (this.portalService.currentPortal() === 'lustaufoesterreichat') {
      this.setAutoSlide();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  focusIn() {
    this.focus.next(true);
  }

  focusOut() {
    this.focus.next(false);
  }
}
