import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {Results} from '../../../../model/search/results';
import {ChannelSearchService} from '../../../../shared/service/channelSearch.service';
import {PaginatorModel} from '../../../../model/component/paginator';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TeaserView} from '../../../../model/teaser/teaserView';
import {IResultArticles} from '../../../../model/search/payload';
import {SearchOrder} from '../../../../model/enum/search';
import {TeaserModel} from '../../../../model/teaser/teaser';
import {Location} from '@angular/common';
import {MovieSerieModel} from '@model/movieSerie/movieSerie';
import {GTMTriggerId} from '../../../../model/enum/gtm';
import {LinkBarModel} from '../../../../model/component/linkBar';
import {Router} from '@angular/router';
import {PortalService} from '../../../../shared/service/portal.service';
import {PortalId} from '../../../../model/enum/portal';
import {DeviceDetectionService} from '../../../../shared/service/device-detection.service';
import {PortalRoute} from '@model/teaser/portalRoute';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'searchresults',
  templateUrl: 'resultArticles.component.html'
})
export class ResultArticlesComponent implements DynamicComponent<IResultArticles> {

  searchResult$: Observable<Results>;
  paginatorModel: PaginatorModel;
  mediaModels: TeaserModel[];
  movies: MovieSerieModel[];
  theaters: LinkBarModel[];
  gtmId = GTMTriggerId.more_button;
  movieTitle: LinkBarModel;
  theaterTitle: LinkBarModel;
  termName: string;

  private model: IResultArticles;

  constructor(private searchService: ChannelSearchService,
              private router: Router,
              private portalService: PortalService,
              private location: Location,
              private mobileDetectionService: DeviceDetectionService,
  ) {
  }

  initModel(model: IResultArticles): void {
    this.model = model;
    this.searchService.page_size = model.page_size;
    this.searchService.ordering = SearchOrder[model.order];
    if (model.term) {
      this.searchService.term = model.term;
      this.termName = model.term;
      this.searchService.baseUrl = model.base.slice(0, model.base.indexOf(model.term) - 1);
    } else {
      this.searchService.baseUrl = model.base;
    }

    this.searchResult$ = this.searchService.searchResult.pipe(
      tap(results => {
        this.termName = this.searchService.term;
        this.paginatorModel = PaginatorModel.fromResult(results);
        const tv = new TeaserView();
        this.mediaModels = results.articles ? results.articles.map(a => {
          return TeaserModel.fromMediaTeaser(a, tv, this.mobileDetectionService.isMobile);
        }) : [];

        if (this.portalService.hostname() === PortalId.filmat) {
          this.movies = results.movies && results.movies.map(m => {
            return MovieSerieModel.deserialize(m);
          });

          this.theaters = results.theaters && results.theaters.map(t => {
            return new LinkBarModel(t.title, PortalRoute.fromTeaser(t), false);
          });
        }
      })
    );

    this.selectPage(model.page, false);

    this.movieTitle = new LinkBarModel('Filme & Serien', null, true);
    this.theaterTitle = new LinkBarModel('Kinos', null, true);
  }

  selectPage(page: number, redirect = true) {
    this.searchService.clearSubject();
    this.searchService.page = page;
    if (this.model.term) {
      this.searchService.searchByTerm();
    } else if (this.model.query) {
      this.searchService.searchByQuery(this.model.query);
    } else if (this.model.topic) {
      this.searchService.searchByTopic(this.model.topic);
    } else if (this.model.channel) {
      this.searchService.searchByChannel(this.model.channel);
    }
    if (!redirect) {
      return;
    }
    this.location.go(this.searchService.getLink().path);
  }

  navigateToMovies() {
    this.router.navigateByUrl('/alle-filme?term=' + this.searchService.term);
    return false;
  }
}
