import {IFootballData, IFootballResult, IFootballTeam} from '@model/content/footballTable/payload';

export class FootballStageRowModel {
  team1: {
    logo?: string,
    name: string,
  };
  team2: {
    logo?: string,
    name: string,
  };
  results: string;
  min: string;
  time: string;

  static fromFootBallResult(results: IFootballResult[], teams: IFootballData<IFootballTeam[]>) {
    return results.map(result => {
      const r = new FootballStageRowModel();
      r.min = result.time.minute + ' min';
      r.results = result.scores.localteam_score + ' - ' + result.scores.visitorteam_score;
      const localTeam = teams.data.find(t => t.id === result.localteam_id);
      const visitorTeam = teams.data.find(t => t.id === result.visitorteam_id);
      r.team1 = {
        logo: localTeam.logo_path,
        name: localTeam.name,
      };
      r.team2 = {
        logo: visitorTeam.logo_path,
        name: visitorTeam.name,
      };
      return r;
    });
  }
}
