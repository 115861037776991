var Results = /** @class */ (function () {
    function Results() {
        this.totalHits = '';
    }
    Results.deserialize = function (json) {
        if (!json) {
            return;
        }
        var results = new Results();
        results.articles = json.articles;
        results.topics = json.topics;
        results.movies = json.movies;
        results.theaters = json.cinemas;
        results.series = json.series;
        results.totalHits = json.total_hits || 0;
        results.term = json.term || '';
        results.ordering = json.ordering || '';
        results.page = json.page || 0;
        results.pageSize = json.page_size || 0;
        results.numberOfPages = json.number_of_pages || 0;
        return results;
    };
    Object.defineProperty(Results.prototype, "hasResults", {
        get: function () {
            return this.hasArticles || this.hasTopics;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Results.prototype, "hasArticles", {
        get: function () {
            return this.articles && this.articles.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Results.prototype, "hasTopics", {
        get: function () {
            return this.topics && this.topics.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    return Results;
}());
export { Results };
