<div #paginator></div>
<div
  class="paginator"
  *ngIf="model.showPagination">
  <ng-container *ngTemplateOutlet="navbar"></ng-container>
  <ng-content></ng-content>
  <ng-container *ngTemplateOutlet="navbar"></ng-container>
</div>

<ng-template #navbar>
  <div
    *ngIf="pages.length"
    class="paginator-wrapper">
    <iconbutton
      oewaClickTrigger
      class="paginator-list-iconButton"
      (click)="onChangePage(model.currentPage - 1)"
      [icon]="'angle-left'"
      [class.isInactive]="model.currentPage === 1"></iconbutton>
    <ul class="paginator-list">
      <li *ngFor="let page of pages" [class.isActive]="page === model.currentPage">
        <button
          class="test"
          [name]="'gehe zu Seite ' + page"
          oewaClickTrigger
          (click)="onChangePage(page)">
          {{page}}
        </button>
      </li>
    </ul>
    <iconbutton
      oewaClickTrigger
      class="paginator-list-iconButton"
      (click)="onChangePage(model.currentPage + 1)"
      [icon]="'angle-right'"
      [class.isInactive]="model.currentPage === model.numberOfPages"></iconbutton>
  </div>
</ng-template>
