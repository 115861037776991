import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {ILoadingNotification, LoadingService} from '../../../shared/service/loading.service';
import {Observable} from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'animation',
  styleUrls: [],
  templateUrl: 'animation.component.html'
})
export class AnimationComponent {

  loading$: Observable<ILoadingNotification>;

  constructor(protected readonly loadingService: LoadingService) {
    this.loading$ = loadingService.loadingObs$;
  }

}
