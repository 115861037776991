/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../shared/directive/portalRoute.directive";
import * as i3 from "@angular/router";
import * as i4 from "../../../../shared/service/portal.service";
import * as i5 from "../../../../shared/service/browser.ref";
import * as i6 from "../../image/image.component.ngfactory";
import * as i7 from "../../image/image.component";
import * as i8 from "../../../../shared/service/image.service";
import * as i9 from "../../../../shared/service/overlay.service";
import * as i10 from "./tag.component";
var styles_TagComponent = [];
var RenderType_TagComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TagComponent, data: {} });
export { RenderType_TagComponent as RenderType_TagComponent };
function View_TagComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TagComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "tag"]], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(3, 540672, null, 0, i2.PortalRouteDirective, [i3.Router, i0.ElementRef, i4.PortalService, i5.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TagComponent_2)), i0.ɵdid(5, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "tag"; var currVal_3 = _co.model.className; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _co.model.link; _ck(_v, 3, 0, currVal_4); var currVal_5 = i0.ɵnov(_v.parent, 4); _ck(_v, 5, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TagComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TagComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "tag"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TagComponent_4)), i0.ɵdid(4, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tag"; var currVal_1 = _co.model.className; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵnov(_v.parent, 4); _ck(_v, 4, 0, currVal_2); }, null); }
function View_TagComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "picimage", [], null, null, null, i6.View_ImageComponent_0, i6.RenderType_ImageComponent)), i0.ɵdid(1, 638976, null, 0, i7.ImageComponent, [i8.ImageService, i9.OverlayService, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.image; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TagComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TagComponent_6)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(2, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.image; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.model.title; _ck(_v, 2, 0, currVal_1); }); }
export function View_TagComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TagComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TagComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, [["tagContent", 2]], null, 0, null, View_TagComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.link; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.model.link; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TagComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tag", [], null, null, null, View_TagComponent_0, RenderType_TagComponent)), i0.ɵdid(1, 49152, null, 0, i10.TagComponent, [], null, null)], null, null); }
var TagComponentNgFactory = i0.ɵccf("tag", i10.TagComponent, View_TagComponent_Host_0, { model: "model" }, {}, []);
export { TagComponentNgFactory as TagComponentNgFactory };
