import { ComponentFactoryResolver, ComponentRef, ElementRef, TemplateRef, Type, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { OverlayTemplateComponent } from '@component/common/overlay-template/overlay-template.component';
import * as i0 from "@angular/core";
var OverlayService = /** @class */ (function () {
    function OverlayService() {
        this.internalOverlayComp$ = new Subject();
        this.internalOverlayOffset$ = new Subject();
    }
    Object.defineProperty(OverlayService.prototype, "overlayComp$", {
        get: function () {
            return this.internalOverlayComp$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OverlayService.prototype, "overlayOffset$", {
        get: function () {
            return this.internalOverlayOffset$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    OverlayService.prototype.init = function (vcRef) {
        this.vcRef = vcRef;
    };
    OverlayService.prototype.initOffset = function (elm) {
        var _this = this;
        this.internalMutationObserver = new MutationObserver(function () {
            _this.internalOverlayOffset$.next(elm.nativeElement.scrollHeight);
        });
        this.internalMutationObserver.observe(elm.nativeElement, {
            subtree: true,
            childList: true,
        });
    };
    OverlayService.prototype.addComponent = function (resolver, type) {
        this.vcRef.clear();
        var factory = resolver.resolveComponentFactory(type);
        var compRef = this.vcRef.createComponent(factory);
        this.internalOverlayComp$.next(compRef);
        return compRef;
    };
    OverlayService.prototype.addTemplate = function (resolver, templateRef) {
        this.vcRef.clear();
        var factory = resolver.resolveComponentFactory(OverlayTemplateComponent);
        var compRef = this.vcRef.createComponent(factory);
        this.internalOverlayComp$.next(compRef);
        compRef.instance.templateRef = templateRef;
        return compRef;
    };
    OverlayService.prototype.clearComponent = function () {
        this.vcRef.clear();
        this.internalOverlayComp$.next(null);
    };
    OverlayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OverlayService_Factory() { return new OverlayService(); }, token: OverlayService, providedIn: "root" });
    return OverlayService;
}());
export { OverlayService };
