/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i2 from "../../../common/ui/linkBar/linkBar.component";
import * as i3 from "@angular/common";
import * as i4 from "./topicHeader.component";
var styles_TopicHeaderComponent = [];
var RenderType_TopicHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TopicHeaderComponent, data: {} });
export { RenderType_TopicHeaderComponent as RenderType_TopicHeaderComponent };
function View_TopicHeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "search-topicHeader-description"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.teaser; _ck(_v, 0, 0, currVal_0); }); }
export function View_TopicHeaderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "search-topicHeader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "linkbar", [], null, null, null, i1.View_LinkBarComponent_0, i1.RenderType_LinkBarComponent)), i0.ɵdid(2, 49152, null, 0, i2.LinkBarComponent, [], { model: [0, "model"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TopicHeaderComponent_1)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkBarModel; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.model.teaser; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TopicHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "topicHeader", [], null, null, null, View_TopicHeaderComponent_0, RenderType_TopicHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i4.TopicHeaderComponent, [], null, null)], null, null); }
var TopicHeaderComponentNgFactory = i0.ɵccf("topicHeader", i4.TopicHeaderComponent, View_TopicHeaderComponent_Host_0, {}, {}, []);
export { TopicHeaderComponentNgFactory as TopicHeaderComponentNgFactory };
