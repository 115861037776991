/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../image/image.component.ngfactory";
import * as i2 from "../../image/image.component";
import * as i3 from "../../../../shared/service/image.service";
import * as i4 from "../../../../shared/service/overlay.service";
import * as i5 from "../../ui/tag/tag.component.ngfactory";
import * as i6 from "../../ui/tag/tag.component";
import * as i7 from "../../../../shared/directive/portalRoute.directive";
import * as i8 from "@angular/router";
import * as i9 from "../../../../shared/service/portal.service";
import * as i10 from "../../../../shared/service/browser.ref";
import * as i11 from "../../gtmTrigger.directive";
import * as i12 from "@angular/common";
import * as i13 from "../../../../shared/pipe/stripHtml.pipe";
import * as i14 from "./card.component";
var styles_CardComponent = [];
var RenderType_CardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CardComponent, data: {} });
export { RenderType_CardComponent as RenderType_CardComponent };
function View_CardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "picimage", [["class", "teaser-imageContainer"]], null, null, null, i1.View_ImageComponent_0, i1.RenderType_ImageComponent)), i0.ɵdid(1, 638976, null, 0, i2.ImageComponent, [i3.ImageService, i4.OverlayService, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.image; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CardComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tag", [], null, null, null, i5.View_TagComponent_0, i5.RenderType_TagComponent)), i0.ɵdid(1, 49152, null, 0, i6.TagComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CardComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "teaser-tagLineLink"]], [[8, "innerHTML", 1], [8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 540672, null, 0, i7.PortalRouteDirective, [i8.Router, i0.ElementRef, i9.PortalService, i10.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), i0.ɵdid(2, 81920, null, 0, i11.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _v.context.$implicit.portalRoute; _ck(_v, 1, 0, currVal_3); var currVal_4 = _co.model.pretitleGtmId; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; var currVal_1 = i0.ɵnov(_v, 1).target; var currVal_2 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CardComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "p", [["class", "teaser-tagLine"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CardComponent_3)), i0.ɵdid(2, 278528, null, 0, i12.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CardComponent_4)), i0.ɵdid(4, 278528, null, 0, i12.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.tags; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.model.tagLine; _ck(_v, 4, 0, currVal_1); }, null); }
function View_CardComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "teaser-title"]], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 540672, null, 0, i7.PortalRouteDirective, [i8.Router, i0.ElementRef, i9.PortalService, i10.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.model.link; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.model.title; _ck(_v, 4, 0, currVal_3); }); }
function View_CardComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "teaser-text"]], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 540672, null, 0, i7.PortalRouteDirective, [i8.Router, i0.ElementRef, i9.PortalService, i10.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpad(3, 1), i0.ɵppd(4, 2)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.model.link; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), _co.model.content, _ck(_v, 3, 0, "a"))); _ck(_v, 2, 0, currVal_3); }); }
export function View_CardComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i13.StripHtmlPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "card"]], null, null, null, null, null)), i0.ɵprd(512, null, i12.ɵNgClassImpl, i12.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i12.NgClass, [i12.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(4, 2), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CardComponent_1)), i0.ɵdid(6, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CardComponent_2)), i0.ɵdid(8, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CardComponent_5)), i0.ɵdid(10, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CardComponent_6)), i0.ɵdid(12, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "card"; var currVal_1 = _ck(_v, 4, 0, _co.model.box.cssClass, _co.model.classes); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.model.image; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.model.tagLine.length || _co.model.tags.length); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.model.title && _co.model.title.length); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.model.content; _ck(_v, 12, 0, currVal_5); }, null); }
export function View_CardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-card", [], null, null, null, View_CardComponent_0, RenderType_CardComponent)), i0.ɵdid(1, 49152, null, 0, i14.CardComponent, [], null, null)], null, null); }
var CardComponentNgFactory = i0.ɵccf("app-card", i14.CardComponent, View_CardComponent_Host_0, {}, {}, []);
export { CardComponentNgFactory as CardComponentNgFactory };
