<ng-container *ngIf="showTwitter$ | async; else noContent">
  <div class="article-centerExternal">
    <blockquote class="twitter-tweet"
                [attr.data-conversation]="conversion"
                [attr.data-cards]="includeMedia">
      <a [href]="url"></a>
    </blockquote>
  </div>
</ng-container>

<ng-template #noContent>
  <app-no-consent [vendor]="vendorType"></app-no-consent>
</ng-template>
