import { TimeAgoUtil } from '@util/timeAgo';
import { PortalRoute } from './portalRoute';
import { Author } from '../author/author';
import { Channel } from './channel';
import { PathTree } from './pathTree';
import { BoxModel } from '../component/box';
import { ImageModel } from '../component/image';
import { BoxColor, BoxStyle } from '../enum/box';
import { GTMTriggerId } from '../enum/gtm';
import { ImageType } from '../enum/image';
import { TeaserView } from './teaserView';
import TagModel from './tag';
import { TeaserType } from '@model/teaser/type';
import { MovieSerieModel } from '@model/movieSerie/movieSerie';
var TeaserModel = /** @class */ (function () {
    function TeaserModel() {
        this.pretitleGtmId = GTMTriggerId.teaser_pretitle;
        this.classNames = ['teaser'];
        this.tags = [];
        this.tagLine = [];
        this.box = new BoxModel();
    }
    Object.defineProperty(TeaserModel.prototype, "classes", {
        get: function () {
            return this.classNames.join(' ');
        },
        enumerable: true,
        configurable: true
    });
    TeaserModel.fromCardTeasers = function (teasers, teaserView, imageSize, box) {
        var _this = this;
        return teasers.map(function (teaser) { return _this.fromCardTeaser(teaser, teaserView, imageSize, box); });
    };
    TeaserModel.fromCardTeaser = function (teaser, teaserView, imageSize, box) {
        if (teaser && teaser.channel && teaser.channel.url === '/kurierat/meinung') {
            return TeaserModel.opinion(teaser);
        }
        if (teaserView.hero) {
            return TeaserModel.hero(teaser, imageSize);
        }
        if (teaser.type === 'movie' || teaser.type === 'series' || teaserView.small_poster_view || teaserView.big_poster_view) {
            return TeaserModel.movie(teaser, teaserView);
        }
        var imageOnly = true;
        Object.keys(teaserView).forEach(function (key) {
            if (key !== 'img') {
                if (teaserView[key]) {
                    imageOnly = false;
                }
            }
        });
        if (imageOnly) {
            return TeaserModel.imageOnlyTeaser(teaser, imageSize);
        }
        return TeaserModel.card(teaser, teaserView, imageSize, box);
    };
    TeaserModel.fromMediaTeasers = function (teasers, teaserView, mobile, model) {
        var _this = this;
        return teasers.map(function (teaser) { return _this.fromMediaTeaser(teaser, teaserView, mobile, model); });
    };
    TeaserModel.movie = function (movie, teaserView) {
        var t = new TeaserModel();
        t.type = TeaserType.movie;
        t.movieSerie = MovieSerieModel.deserialize(movie);
        if (teaserView.big_poster_view) {
            t.movieSerie.classNames.push('bigPoster');
        }
        if (teaserView.small_poster_view) {
            t.movieSerie.classNames.push('smallPoster');
        }
        return t;
    };
    TeaserModel.imageOnlyTeaser = function (teaser, imageSize) {
        var t = new TeaserModel();
        t.type = TeaserType.imageOnly;
        t.link = PortalRoute.fromTeaser(teaser);
        if (teaser.teaser_img) {
            t.image = new ImageModel(teaser.teaser_img, imageSize, t.link, false, false, 'teaser-image');
            t.image.setVideo(teaser);
        }
        if (teaser.teaser_video && teaser.teaser_video.fallback_image) {
            t.image = new ImageModel(teaser.teaser_video.fallback_image, imageSize, t.link, false, false, 'teaser-image');
        }
        return t;
    };
    TeaserModel.card = function (teaser, teaserView, imageSize, box) {
        var t = TeaserModel.baseFromTeaser(teaser, teaserView, box);
        t.type = TeaserType.card;
        if (teaser.teaser_img) {
            t.image = new ImageModel(teaser.teaser_img, imageSize, t.link, false, false, 'teaser-image');
            t.image.setVideo(teaser);
        }
        if (teaser.teaser_video && teaser.teaser_video.fallback_image) {
            t.image = new ImageModel(teaser.teaser_video.fallback_image, imageSize, t.link, false, false, 'teaser-image');
        }
        t.type = TeaserType.card;
        TeaserModel.setBadge(t, teaser);
        return t;
    };
    TeaserModel.hero = function (teaser, imageSize) {
        var t = new TeaserModel();
        if (teaser.portal === 'freizeit.at') {
            t.classNames.push('pB-freizeitat-circle');
        }
        else if (teaser.portal) {
            t.classNames.push('pB-' + teaser.portal.replace('.', ''));
        }
        TeaserModel.setPaid(t, teaser);
        TeaserModel.setBadge(t, teaser);
        t.type = TeaserType.hero;
        t.title = teaser.title;
        t.link = PortalRoute.fromTeaser(teaser);
        if (teaser.pretitle) {
            t.tags.push(new TagModel(teaser.pretitle));
        }
        if (teaser.teaser_img) {
            t.image = new ImageModel(teaser.teaser_img, imageSize, t.link, false, false, 'teaser-image');
        }
        if (teaser.teaser_video && teaser.teaser_video.fallback_image) {
            t.image = new ImageModel(teaser.teaser_video.fallback_image, imageSize, t.link, false, false, 'teaser-image');
        }
        return t;
    };
    TeaserModel.fromTextTeaser = function (teaser) {
        var m = new TeaserModel();
        m.title = teaser.title;
        m.link = PortalRoute.fromTeaser(teaser);
        m.type = TeaserType.text;
        return m;
    };
    TeaserModel.fromMediaTeaser = function (teaser, teaserView, mobile, model) {
        var t = TeaserModel.baseFromTeaser(teaser, teaserView);
        t.type = TeaserType.media;
        teaserView.teaser_text ? t.classNames.push('hasContent') : t.classNames.push('noContent');
        if (teaser.display_authors) {
            t.classNames.push('smallImage isAuthor');
            t.author = TeaserModel.author(teaser);
        }
        if (teaserView.img && (teaser.teaser_img || teaser.display_authors || teaser.teaser_video)) {
            var image = teaser.display_authors ? t.author.picture : (teaser.teaser_img || teaser.teaser_video.fallback_image);
            var imageSize = void 0;
            if (teaser.display_authors || (model && model.position === 'left') || mobile) {
                imageSize = ImageType.SQ;
            }
            else {
                imageSize = ImageType.LS_16_9;
            }
            if (image) {
                t.image = new ImageModel(image, imageSize, t.link, false, teaser.display_authors, '');
            }
            t.classNames.push('hasImage');
        }
        else {
            t.classNames.push('noImage');
        }
        return t;
    };
    TeaserModel.fromChannelRelateds = function (t) {
        var tV = new TeaserView(true, true, true, false);
        var teaser = t.slice(0, 4);
        return TeaserModel.fromCardTeasers(teaser, tV, ImageType.LS_16_9);
    };
    TeaserModel.authors = function (teaser) {
        if (teaser.authors) {
            return Author.deserializeJson(teaser.authors, teaser.portal);
        }
    };
    TeaserModel.author = function (teaser) {
        if (teaser.authors && teaser.authors.length) {
            return Author.deserializeItem(teaser.authors[0], teaser.portal);
        }
    };
    TeaserModel.channel = function (teaser) {
        if (teaser.channel) {
            return Channel.deserializeJson(teaser.channel);
        }
    };
    TeaserModel.poweredByLogo = function (teaser) {
        return {
            url: teaser.powered_by.logo,
        };
    };
    TeaserModel.date = function (teaser) {
        return TimeAgoUtil.getDate(teaser.published_date) || '';
    };
    TeaserModel.setBadge = function (teaser, payload) {
        if (payload.badges && !payload.teaser_video && !payload.paid_content) {
            var pH = 'picimage-badge ';
            var badgesArr = ['live', 'video'];
            var diff = badgesArr.filter(function (badge) { return payload.badges.indexOf(badge) >= 0; });
            pH += diff[0] + '-badge';
            teaser.classNames.push(pH);
        }
    };
    TeaserModel.setPaid = function (m, teaser) {
        if (teaser.paid_content && teaser.portal === 'kurier.at') {
            m.tags.push(new TagModel(null, PortalRoute.fromUri('/kurierat/plus'), 'paid'));
        }
    };
    TeaserModel.baseFromTeaser = function (teaser, teaserView, box) {
        var m = new TeaserModel();
        m.title = teaser.title;
        if (teaserView.teaser_text && teaser.teaser_text) {
            m.content = teaser.teaser_text;
        }
        m.link = PortalRoute.fromTeaser(teaser);
        var channel = TeaserModel.channel(teaser);
        m.box = box ? box : BoxModel.fromChannel(channel);
        if (teaser.portal) {
            m.classNames.push('pB-' + teaser.portal.replace('.', ''));
        }
        TeaserModel.setPaid(m, teaser);
        if (teaser.sponsored) {
            m.box = new BoxModel({ style: BoxStyle.sponsored, color: BoxColor.standard });
        }
        if (teaser.pretitle) {
            m.tagLine = [new PathTree(teaser.url, teaser.pretitle, PortalRoute.fromTeaser(teaser))];
        }
        else {
            if (channel) {
                m.tagLine = channel.pathtree;
            }
        }
        return m;
    };
    TeaserModel.opinion = function (teaser) {
        var t = new TeaserModel();
        t.type = TeaserType.opinion;
        t.title = teaser.title;
        t.link = PortalRoute.fromTeaser(teaser);
        TeaserModel.setPaid(t, teaser);
        if (teaser.pretitle) {
            t.tags.push(new TagModel(teaser.pretitle));
        }
        else {
            var channel = TeaserModel.channel(teaser);
            if (channel) {
                t.tagLine = channel.pathtree;
            }
        }
        if (teaser.authors && teaser.authors.length) {
            t.author = TeaserModel.author(teaser);
        }
        if (t.author && t.author.picture) {
            t.image = new ImageModel(t.author.picture, ImageType.SQ, t.link, false, true);
        }
        return t;
    };
    return TeaserModel;
}());
export { TeaserModel };
