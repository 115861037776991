import { ElementRef, Renderer2, RendererFactory2 } from '@angular/core';
import * as i0 from "@angular/core";
var StickyService = /** @class */ (function () {
    function StickyService(rendererFactory) {
        this.observer = new Map();
        this.subscribers = new Map();
        this.targets = new Map();
        this.isVisible = new Map();
        this.renderer = rendererFactory.createRenderer(null, null);
    }
    StickyService.prototype.addTarget = function (id, elm) {
        var _this = this;
        this.targets.set(id, elm);
        this.isVisible.set(id, elm.nativeElement.offsetHeight > 0);
        var options = {
            rootMargin: '0px',
            threshold: 0
        };
        this.observer.set(id, new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                // NOTE entry.intersectionRatio > 0 is for edge 15 who doesn't set entry.isIntersecting
                _this.isVisible.set(id, entry.isIntersecting || entry.intersectionRatio > 0);
                if (_this.subscribers.get(id)) {
                    _this.subscribers.get(id).forEach(function (s) {
                        s.visibilityChanged(_this.isVisible.get(id));
                    });
                }
            });
        }, options));
        this.observer.get(id).observe(elm.nativeElement);
    };
    StickyService.prototype.subscribe = function (id, s) {
        var a = this.subscribers.get(id);
        if (!a) {
            a = [];
            this.subscribers.set(id, a);
        }
        else {
            if (a.indexOf(s) !== -1) {
                return;
            }
        }
        a.push(s);
        if (this.isVisible.get(id) === undefined) {
            this.isVisible.set(id, true);
        }
        s.visibilityChanged(this.isVisible.get(id));
    };
    StickyService.prototype.unsubscribe = function (id, us) {
        this.subscribers.set(id, this.subscribers.get(id).filter(function (s) { return s !== us; }));
    };
    StickyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StickyService_Factory() { return new StickyService(i0.ɵɵinject(i0.RendererFactory2)); }, token: StickyService, providedIn: "root" });
    return StickyService;
}());
export { StickyService };
