import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {LinkBarModel} from '../../../../model/component/linkBar';
import {PortalRoute} from '../../../../model/teaser/portalRoute';
import {IChannelLink} from '../../../../model/content/dividerLink/payload';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'channellink',
  templateUrl: 'channelLink.component.html'
})
export class ChannelLinkComponent implements DynamicComponent<IChannelLink> {
  linkBarModel: LinkBarModel;

  initModel(model: IChannelLink): void {
    this.linkBarModel = new LinkBarModel(model.data.text, PortalRoute.fromUri(model.data.url), false);
  }
}
