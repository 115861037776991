/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./overlay-template.component";
var styles_OverlayTemplateComponent = [];
var RenderType_OverlayTemplateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OverlayTemplateComponent, data: {} });
export { RenderType_OverlayTemplateComponent as RenderType_OverlayTemplateComponent };
export function View_OverlayTemplateComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_OverlayTemplateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-overlay-template", [], null, null, null, View_OverlayTemplateComponent_0, RenderType_OverlayTemplateComponent)), i0.ɵdid(1, 4243456, null, 0, i1.OverlayTemplateComponent, [i0.ViewContainerRef], null, null)], null, null); }
var OverlayTemplateComponentNgFactory = i0.ɵccf("app-overlay-template", i1.OverlayTemplateComponent, View_OverlayTemplateComponent_Host_0, {}, {}, []);
export { OverlayTemplateComponentNgFactory as OverlayTemplateComponentNgFactory };
