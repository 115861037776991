import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {TeasersService} from '../../../../shared/service/teasers.service';
import {ITeaser} from '../../../../model/teaser/payload';
import {Observable, of} from 'rxjs';
import {ImageType} from '../../../../model/enum/image';
import {ITopStories} from '../../../../model/channel/topStories/payload';
import {TeaserView} from '../../../../model/teaser/teaserView';
import {TeaserModel} from '../../../../model/teaser/teaser';
import {tap} from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'topstories',
  templateUrl: 'topStories.component.html'
})
export class TopStoriesComponent implements DynamicComponent<ITopStories>, OnChanges {

  @Input() teasers: ITeaser[];
  teasers$: Observable<ITeaser[]>;
  firstCard: TeaserModel;
  secondCard: TeaserModel;

  constructor(public teaserService: TeasersService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.teasers$ = of(this.teasers).pipe(tap(this.setModels));
  }

  initModel(model: ITopStories): void {
    this.teasers$ = this.teaserService.getCollection(model.collectionName, 2)
      .pipe(tap(this.setModels));
  }

  setModels = (teasers: ITeaser[]) => {
    this.firstCard = TeaserModel.fromCardTeaser(teasers[0], TeaserView.heroView, ImageType.LS_16_9);
    this.secondCard = TeaserModel.fromCardTeaser(teasers[1], TeaserView.heroView, ImageType.LS_16_9);
  }
}
