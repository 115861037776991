<header class="siteHeader">
  <div class="siteHeader-inner">
    <div
      *ngIf="model$ | async as model"
      class="headerContainer">
      <a
        href="/das-lust-auf-oesterreich-magazin/401995893"
        target="_blank"
        class="laoeLink"
        *ngIf="currentPortal === 'lustaufoesterreichat'"></a>
      <div class="headerContainer-top">
        <a [href]="model.homeLink"
           class="headerContainer-title"
           [gtm]="logoGtmId">
          <img *ngIf="!isLustaufoesterreich" [src]="'/assets/'+currentPortal+'/logos/header.svg'" alt="{{alt}}">
          <img *ngIf="isLustaufoesterreich" [src]="'/assets/'+currentPortal+'/logos/header.png'"
               [srcset]="'/assets/'+currentPortal+'/logos/header.png 1x, /assets/'+currentPortal+'/logos/header@2x.png 2x'"
               alt="{{alt}}">
        </a>
        <a
          *ngIf="!isKurier"
          href="#"
          class="headerContainer-toggle headerContainer-toggle-issearch"
          [class.isActive]="showSearchField"
          (click)="toggleSearchField()">
          <iconbutton [icon]="'search'"></iconbutton>
          <span>Suche</span>
        </a>

        <ng-container *ngIf="isKurier && !isTouch; then kurierLinks"></ng-container>

        <menu class="headerContainer-toggle-ismenu"
              (search)="toggleSearchField()"
              *ngIf="isTouch"
              [model]="model"></menu>
        <login class="headerContainer-toggle-isLogin"></login>
      </div>

      <navBar *ngIf="showNavBar$ | async" (search)="toggleSearchField()" [model]="model"
              [isKurier]="isKurier"></navBar>

      <div *ngIf="showNavBar$ | async" class="kurierlinks">
        <div class="kurierlinks-inner">
          <ng-container *ngIf="isKurier && isTouch; then kurierLinks">
            <ng-container *ngTemplateOutlet="kurierLinks"></ng-container>
          </ng-container>
        </div>
      </div>

    </div>

    <animation></animation>

    <overlay></overlay>
  </div>
</header>

<ng-template #kurierLinks>
  <a
    [gtm]="gtmId"
    class="k-special-link k-special-link-plus navBar-plus"
    [routerLink]="'plus'"
    title="KURIER Plus">
    Abo
  </a>
  <a
    target="_blank"
    href="https://epaper.kurier.at/dashboard.act"
    [gtm]="gtmId"
    class="k-special-link k-special-link-epaper navBar-epaper">
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
      <path fill-rule="evenodd" fill="currentColor"
            d="m678.1 345.3v140c0 11.1-9 20-20 20h-228c-11.1 0-20-8.9-20-20v-140c0-11 8.9-20 20-20h228c11 0 20 9 20 20zm-40 20h-188v100h188z"/>
      <path fill="currentColor"
            d="m678.1 573.3c0 11.1-9 20-20 20h-228c-11.1 0-20-8.9-20-20 0-11 8.9-20 20-20h228c11 0 20 9 20 20z"/>
      <path fill="currentColor"
            d="m678.1 661.3c0 11.1-9 20-20 20h-228c-11.1 0-20-8.9-20-20 0-11 8.9-20 20-20h228c11 0 20 9 20 20z"/>
      <path fill-rule="evenodd" fill="currentColor"
            d="m765.6 302v396c0 37.5-30.5 68-68 68h-386.5c-42.3 0-76.7-34.4-76.7-76.7v-268.6c0-11.1 8.9-20 20-20h68.1v-98.7c0-37.5 30.5-68 68-68h307.1c37.5 0 68 30.5 68 68zm-454.5 424h17.5c-3.9-8.5-6.1-18-6.1-28v-257.3h-48.1v248.6c0 20.2 16.4 36.7 36.7 36.7zm414.5-424c0-15.4-12.5-28-28-28h-307.1c-15.4 0-28 12.6-28 28v396c0 15.4 12.6 28 28 28h307.1c15.5 0 28-12.6 28-28z"/>
    </svg>
    E-Paper</a>
  <a [gtm]="gtmId" class="k-special-link k-special-link-video navBar-video"
     [routerLink]="'video'">
    <svgicon icon="play"></svgicon>
    Video</a>
  <a [gtm]="gtmId" class="k-special-link k-special-link-new"
     [routerLink]="'neues'">🕒 Neues</a>

</ng-template>
