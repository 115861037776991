/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/cardSlider/cardSlider.component.ngfactory";
import * as i2 from "../../../common/cardSlider/cardSlider.component";
import * as i3 from "../../../../shared/service/teasers.service";
import * as i4 from "../../../../shared/service/portal.service";
import * as i5 from "./multiSlider.component";
var styles_MultiSliderComponent = [];
var RenderType_MultiSliderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MultiSliderComponent, data: {} });
export { RenderType_MultiSliderComponent as RenderType_MultiSliderComponent };
export function View_MultiSliderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cardslider", [], null, null, null, i1.View_CardSliderComponent_0, i1.RenderType_CardSliderComponent)), i0.ɵdid(1, 114688, null, 0, i2.CardSliderComponent, [i3.TeasersService, i4.PortalService], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MultiSliderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "multislider", [], null, null, null, View_MultiSliderComponent_0, RenderType_MultiSliderComponent)), i0.ɵdid(1, 49152, null, 0, i5.MultiSliderComponent, [], null, null)], null, null); }
var MultiSliderComponentNgFactory = i0.ɵccf("multislider", i5.MultiSliderComponent, View_MultiSliderComponent_Host_0, {}, {}, []);
export { MultiSliderComponentNgFactory as MultiSliderComponentNgFactory };
