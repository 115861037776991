/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/teaser/teaser.directive";
import * as i2 from "@angular/common";
import * as i3 from "./topStories.component";
import * as i4 from "../../../../shared/service/teasers.service";
var styles_TopStoriesComponent = [];
var RenderType_TopStoriesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TopStoriesComponent, data: {} });
export { RenderType_TopStoriesComponent as RenderType_TopStoriesComponent };
function View_TopStoriesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "section", [["class", "topStories"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "topStories-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "topStories-primary"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(5, 540672, null, 0, i1.TeaserDirective, [i0.ViewContainerRef, i0.ComponentFactoryResolver], { model: [0, "model"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "topStories-secondary"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(8, 540672, null, 0, i1.TeaserDirective, [i0.ViewContainerRef, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.firstCard; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.secondCard; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_TopStoriesComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_TopStoriesComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.teasers$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TopStoriesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "topstories", [], null, null, null, View_TopStoriesComponent_0, RenderType_TopStoriesComponent)), i0.ɵdid(1, 573440, null, 0, i3.TopStoriesComponent, [i4.TeasersService], null, null)], null, null); }
var TopStoriesComponentNgFactory = i0.ɵccf("topstories", i3.TopStoriesComponent, View_TopStoriesComponent_Host_0, { teasers: "teasers" }, {}, []);
export { TopStoriesComponentNgFactory as TopStoriesComponentNgFactory };
