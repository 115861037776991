/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../shared/directive/oewaClickTrigger.directive";
import * as i3 from "../../../../shared/service/oewa.service";
import * as i4 from "../iconButton/iconButton.component.ngfactory";
import * as i5 from "../iconButton/iconButton.component";
import * as i6 from "./paginator.component";
var styles_PaginatorComponent = [];
var RenderType_PaginatorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PaginatorComponent, data: {} });
export { RenderType_PaginatorComponent as RenderType_PaginatorComponent };
function View_PaginatorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_PaginatorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_PaginatorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "paginator"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_2)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_3)), i0.ɵdid(5, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 4); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵnov(_v.parent, 4); _ck(_v, 5, 0, currVal_1); }, null); }
function View_PaginatorComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [], [[2, "isActive", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["class", "test"], ["oewaClickTrigger", ""]], [[8, "name", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onChangePage(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i2.OewaClickTriggerDirective, [i3.OewaService], { oewaPageId: [0, "oewaPageId"] }, null), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit === _co.model.currentPage); _ck(_v, 0, 0, currVal_0); var currVal_1 = ("gehe zu Seite " + _v.context.$implicit); _ck(_v, 1, 0, currVal_1); var currVal_3 = _v.context.$implicit; _ck(_v, 3, 0, currVal_3); }); }
function View_PaginatorComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "paginator-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "iconbutton", [["class", "paginator-list-iconButton"], ["oewaClickTrigger", ""]], [[2, "isInactive", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onChangePage((_co.model.currentPage - 1)) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_IconButtonComponent_0, i4.RenderType_IconButtonComponent)), i0.ɵdid(2, 16384, null, 0, i2.OewaClickTriggerDirective, [i3.OewaService], { oewaPageId: [0, "oewaPageId"] }, null), i0.ɵdid(3, 114688, null, 0, i5.IconButtonComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "ul", [["class", "paginator-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_6)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "iconbutton", [["class", "paginator-list-iconButton"], ["oewaClickTrigger", ""]], [[2, "isInactive", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onChangePage((_co.model.currentPage + 1)) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_IconButtonComponent_0, i4.RenderType_IconButtonComponent)), i0.ɵdid(8, 16384, null, 0, i2.OewaClickTriggerDirective, [i3.OewaService], { oewaPageId: [0, "oewaPageId"] }, null), i0.ɵdid(9, 114688, null, 0, i5.IconButtonComponent, [], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ""; _ck(_v, 2, 0, currVal_1); var currVal_2 = "angle-left"; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.pages; _ck(_v, 6, 0, currVal_3); var currVal_5 = ""; _ck(_v, 8, 0, currVal_5); var currVal_6 = "angle-right"; _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.model.currentPage === 1); _ck(_v, 1, 0, currVal_0); var currVal_4 = (_co.model.currentPage === _co.model.numberOfPages); _ck(_v, 7, 0, currVal_4); }); }
function View_PaginatorComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_5)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pages.length; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PaginatorComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { paginator: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["paginator", 1]], null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, [["navbar", 2]], null, 0, null, View_PaginatorComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.showPagination; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_PaginatorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "paginator", [], null, null, null, View_PaginatorComponent_0, RenderType_PaginatorComponent)), i0.ɵdid(1, 638976, null, 0, i6.PaginatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginatorComponentNgFactory = i0.ɵccf("paginator", i6.PaginatorComponent, View_PaginatorComponent_Host_0, { model: "model" }, { onPageChanged: "onPageChanged" }, ["*"]);
export { PaginatorComponentNgFactory as PaginatorComponentNgFactory };
