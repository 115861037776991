import {ImageSrc, ImageType, ImageW} from '../enum/image';

export const IMG_SRCSET_MAP = {
  [ImageType.SQ]: [ImageSrc.SQ_24, ImageSrc.SQ_48, ImageSrc.SQ_80, ImageSrc.SQ_160,
    ImageSrc.SQ_300, ImageSrc.SQ_616, ImageSrc.SQ_932, ImageSrc.SQ_1232, ImageSrc.SQ_1864],
  [ImageType.SQX]: [ImageSrc.SQ_616, ImageSrc.SQ_932, ImageSrc.SQ_1232, ImageSrc.SQ_1864],
  [ImageType.POSTER]: [ImageSrc.PO_198, ImageSrc.PO_396, ImageSrc.PO_339, ImageSrc.PO_678],
  [ImageType.LS_logo]: [ImageSrc.PR_32, ImageSrc.PR_64],
  [ImageType.LS_16_9]: [ImageSrc.LS_300, ImageSrc.LS_616, ImageSrc.LS_932, ImageSrc.LS_1232, ImageSrc.LS_1864],
  [ImageType.LS_16_9_X]: [ImageSrc.LS_616, ImageSrc.LS_932, ImageSrc.LS_1232, ImageSrc.LS_1864],
  [ImageType.VARIABLE]: [ImageSrc.V_300, ImageSrc.V_616, ImageSrc.V_932, ImageSrc.V_1232, ImageSrc.V_1864],
  [ImageType.FULLWIDTH]: [ImageSrc.V_300, ImageSrc.V_616, ImageSrc.V_932, ImageSrc.WH_1232_410, ImageSrc.WH_1440_480, ImageSrc.WH_1920_640],
};

export const IMG_W_MAP = {
  [ImageType.SQ]: [ImageW.w_24, ImageW.w_48, ImageW.w_80, ImageW.w_160,
    ImageW.w_300, ImageW.w_616, ImageW.w_932, ImageW.w_1232, ImageW.w_1864],
  [ImageType.SQX]: [ImageW.x1, ImageW.x1_5, ImageW.x2, ImageW.x3],
  [ImageType.POSTER]: [ImageW.p_198, ImageW.p_396, ImageW.p_339, ImageW.p_678],
  [ImageType.LS_logo]: [ImageW.w_80, ImageW.w_160],
  [ImageType.LS_16_9]: [ImageW.w_300, ImageW.w_616, ImageW.w_932, ImageW.w_1232, ImageW.w_1864],
  [ImageType.LS_16_9_X]: [ImageW.x1, ImageW.x1_5, ImageW.x2, ImageW.x3],
  [ImageType.VARIABLE]: [ImageW.w_300, ImageW.w_616, ImageW.w_932, ImageW.w_1232, ImageW.w_1864],
  [ImageType.FULLWIDTH]: [ImageW.w_300, ImageW.w_616, ImageW.w_932, ImageW.w_1232, ImageW.w_1440, ImageW.w_1920],
};

export const IMG_SRC = {
  [ImageType.SQ]: ImageSrc.SQ_616,
  [ImageType.SQX]: ImageSrc.SQ_616,
  [ImageType.POSTER]: ImageSrc.PO_198,
  [ImageType.LS_logo]: ImageSrc.PR_32,
  [ImageType.LS_16_9]: ImageSrc.LS_616,
  [ImageType.LS_16_9_X]: ImageSrc.LS_616,
  [ImageType.VARIABLE]: ImageSrc.V_616,
  [ImageType.FULLWIDTH]: ImageSrc.WH_1920_640,
};
