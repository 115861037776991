export enum AdSize {
  ad_sidebar = 'sidebar',
  ad_articlebottom = 'articlebottom',
  ad_responsive = 'responsive',
  ad_mobileBanner_1 = 'banner1',
  ad_mobileBanner_2 = 'banner2',
  ad_mobileBanner_3 = 'banner3',
  ad_mobileBanner_4 = 'banner4',
  ad_inContent_1 = 'incontent1',
  ad_inContent_2 = 'incontent2',
  ad_inContent_3 = 'incontent3',
  ad_inContent_4 = 'incontent4',
  ad_fullbanner = 'fullbanner',
  ad_superbanner = 'superbanner',
  ad_mediumRectangle_1 = 'mediumrectangle1',
  ad_mediumRectangle_2 = 'mediumrectangle2',
  ad_mediumRectangle_3 = 'mediumrectangle3',
  ad_mediumRectangle_4 = 'mediumrectangle4',
  ad_mediumRectangle_slider = 'slider',
  ad_1x1 = '1x1',
  ad_skyscraper = 'skyscraper',
  ad_articleLeft = 'articleleft',
  ad_mticker = 'mticker',
  ad_ticker = 'ticker',
}

export enum AdDimensions {
  dim_1x1,
  dim_1x2,
  dim_1x10,
  dim_100x600,
  dim_120x600,
  dim_160x600,
  dim_300x50,
  dim_300x169,
  dim_300x250,
  dim_300x251,
  dim_300x200,
  dim_300x600,
  dim_300x601,
  dim_320x50,
  dim_400x800,
  dim_468x60,
  dim_500x1000,
  dim_620x50,
  dim_620x100,
  dim_728x90,
  dim_960x100,
  dim_960x250,
  dim_480x270,
  dim_400x300,
  dim_fluid,
}

