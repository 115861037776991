import { PortalRoute } from '../teaser/portalRoute';
import { BoxStyle } from '../enum/box';
import { ImageModel } from './image';
import { TeaserModel } from '../teaser/teaser';
import { GTMTriggerId } from '../enum/gtm';
import { ImageType } from '../enum/image';
var LinkBarModel = /** @class */ (function () {
    function LinkBarModel(text, link, isTitle, gtmId) {
        this.text = text;
        this.isTitle = isTitle;
        this.gtmId = gtmId;
        this.link = link ? (link instanceof PortalRoute ? link : PortalRoute.fromUri(link)) : null;
    }
    LinkBarModel.titleFromTeaser = function (comp, teaser, box) {
        if (box.boxStyle === BoxStyle.poweredby || box.boxStyle === BoxStyle.sponsored) {
            var l = new LinkBarModel(comp ? comp.title : null, null, true);
            if (teaser.powered_by) {
                l.logo = new ImageModel(TeaserModel.poweredByLogo(teaser), ImageType.LS_logo);
                l.link = PortalRoute.fromUri(teaser.powered_by.link);
            }
            return l;
        }
        return new LinkBarModel(comp.title, PortalRoute.fromTeaser(teaser), true, GTMTriggerId.teaser_headline);
    };
    LinkBarModel.fromSingleTeaser = function (comp, teaser, box) {
        var link;
        comp.titleLink ? link = comp.titleLink : link = PortalRoute.fromTeaser(teaser);
        var linkBar = new LinkBarModel(comp.title, link, true, GTMTriggerId.teaser_headline);
        if (comp.image) {
            linkBar.image = new ImageModel(comp.image, ImageType.LS_logo);
        }
        return linkBar;
    };
    LinkBarModel.fromTopics = function (topics, portalService) {
        return topics ? topics.map(function (t) {
            // TODO this is copy and paste from SearchItemModel
            var topicRoute = PortalRoute.fromUri(t.uri);
            var topicRoutePath = topicRoute.path;
            var localTopicUri = portalService.uri(topicRoutePath);
            return new LinkBarModel(t.name, localTopicUri, false);
        }) : [];
    };
    LinkBarModel.fromTitleBox = function (model) {
        var l = new LinkBarModel(model.title, null, true);
        l.logo = model.teaserImage ? new ImageModel(model.teaserImage, ImageType.LS_logo) : null;
        l.link = PortalRoute.fromUri(model.teaserLink);
        return l;
    };
    Object.defineProperty(LinkBarModel.prototype, "showLink", {
        get: function () {
            return this.link !== null;
        },
        enumerable: true,
        configurable: true
    });
    return LinkBarModel;
}());
export { LinkBarModel };
