import {ChangeDetectionStrategy, Component} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {IMovieList} from '../../../../model/channel/movieList/payload';
import {MovieSerieModel} from '@model/movieSerie/movieSerie';
import {IMovieCollection} from '../../../../model/movieSerie/payload';
import {ApiService} from '../../../../shared/service/api.service';
import {MovieListOrder} from '../../../../model/enum/movie';
import {PaginatorModel} from '../../../../model/component/paginator';
import {debounceTime, distinctUntilChanged, map, skipWhile, switchMap, take, tap} from 'rxjs/operators';
import {BehaviorSubject, merge, Observable, ReplaySubject} from 'rxjs';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {GoogleTagManagerService} from '../../../../shared/service/gtm.service';
import {OewaService} from '../../../../shared/service/oewa.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'movielist',
  templateUrl: './movieList.component.html',
})
export class MovieListComponent implements DynamicComponent<IMovieList> {

  movies$: Observable<MovieSerieModel[]>;
  model: IMovieList;
  orderTypes = [
    {value: MovieListOrder.releaseDate, name: 'Filmstart'}];
  orderTypeSelected = 0;
  genres: { value: string, name: string }[];
  genreSelected = 0;
  paginatorModel: PaginatorModel;
  term = '';
  private fetchCollection$ = new BehaviorSubject(true);
  private searchTerm$ = new ReplaySubject<string>();
  listViewList = new BehaviorSubject<boolean>(true);

  constructor(private route: ActivatedRoute,
              private apiService: ApiService,
              protected gtm: GoogleTagManagerService,
              protected oewaService: OewaService) {
  }

  initModel(model: IMovieList): void {
    this.model = model;
    if (this.model.genre) {
      this.model.title = 'Genre: ' + this.model.genre;
    }
    this.paginatorModel = new PaginatorModel(1);
    this.movies$ = merge(this.fetchCollection$.pipe(switchMap(this.fetchCollection.bind(this))),
      this.searchTerm$.pipe(
        distinctUntilChanged(),
        skipWhile(term => term.length < 3),
        debounceTime(500),
        tap((term) => {
          const data = {
            ValueSelected: term,
          };
          this.gtm.pushEvent('DA - MovieListChange - Search', data);
          this.setOwaTrigger();
        }),
        tap(() => {
          this.genres = undefined;
          this.genreSelected = 0;
        }), switchMap(() => this.fetchCollection(true))));

    this.route.queryParamMap.pipe(take(1), tap((queryP: ParamMap) => {
      this.term = queryP.get('term') || '';
      this.searchTerm$.next(this.term);
    })).subscribe();
  }

  onGenreChange() {
    this.fetchCollection$.next(true);
    const data = {
      valueSelected: this.genres[this.genreSelected].name,
    };
    this.gtm.pushEvent('DA - MovieListChange - Genre', data);
    this.setOwaTrigger();
  }

  private setOwaTrigger() {
    this.oewaService.pushCp('filter');
  }

  onPageChanged() {
    this.fetchCollection$.next(false);
  }

  private fetchCollection(resetPagination: boolean) {
    return this.apiService.movieCollection(
      this.model.movieCollectionName,
      (this.paginatorModel.currentPage - 1) * +this.model.numberOfTeasers,
      +this.model.numberOfTeasers,
      this.genres && this.genres[this.genreSelected].value || this.model.genre,
      this.term,
      this.orderTypes[this.orderTypeSelected].value)
      .pipe(
        map((teasers: IMovieCollection) => {
          if (teasers.filter && !this.model.genre && !this.genres) {
            this.genres = teasers.filter.genres.map(g => {
              return {name: g, value: g};
            });
            this.genres.unshift({name: 'Alle Genres', value: null});
          }
          if (resetPagination) {
            this.paginatorModel = new PaginatorModel(1);
            this.paginatorModel.numberOfPages = Math.ceil(teasers.total / +this.model.numberOfTeasers);
          }

          return teasers.items.filter(t => t.type !== 'empty').map(teaser => MovieSerieModel.deserialize(teaser));
        })
      );
  }

  onTermChanged(value: string) {
    this.searchTerm$.next(value);
  }

  toggleListView(list: boolean) {
    this.listViewList.next(list);
  }
}
