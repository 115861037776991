import { ImageType } from '../enum/image';
var ImageModel = /** @class */ (function () {
    function ImageModel(image, size, link, hasCaption, isRounded, imgClass) {
        if (size === void 0) { size = null; }
        if (link === void 0) { link = null; }
        if (hasCaption === void 0) { hasCaption = false; }
        if (isRounded === void 0) { isRounded = false; }
        if (imgClass === void 0) { imgClass = ''; }
        this.image = image;
        this.size = size;
        this.link = link;
        this.hasCaption = hasCaption;
        this.isRounded = isRounded;
        this.imgClass = imgClass;
        this.alt = image.alt;
        this.url = image.url || image.uri;
        this.copyright = image.copyright || '';
        this.description = image.description || '';
        this.name = image.name || '';
        this.source = image.source || '';
        this.title = image.title || '';
    }
    ImageModel.fromMovie = function (movie) {
        var i = new ImageModel(movie.trailer.img, ImageType.LS_16_9);
        i.video = movie.trailer.video;
        i.imgClass = 'video-play picimage-badge';
        return i;
    };
    Object.defineProperty(ImageModel.prototype, "lightbox", {
        get: function () {
            if (this.hasLightbox && this.image.width && this.image.height) {
                return this.image.width < this.image.height;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImageModel.prototype, "focalY", {
        get: function () {
            return this.image.focal_point && this.image.focal_point.y ? this.image.focal_point.y * 100 + '%' : '50%';
        },
        enumerable: true,
        configurable: true
    });
    ImageModel.prototype.setVideo = function (teaser) {
        if (teaser.teaser_video) {
            this.imgClass += ' picimage-badge video-play';
            this.video = teaser.teaser_video;
        }
    };
    Object.defineProperty(ImageModel.prototype, "caption", {
        get: function () {
            var caption = '';
            if (this.description) {
                caption += this.description;
            }
            if (this.copyright) {
                caption += '<p>© ' + this.copyright + '</p>';
            }
            return caption;
        },
        enumerable: true,
        configurable: true
    });
    return ImageModel;
}());
export { ImageModel };
