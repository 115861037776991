/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/accordeon/accordeon.component.ngfactory";
import * as i2 from "../../../common/ui/accordeon/accordeon.component";
import * as i3 from "../../../../shared/service/oewa.service";
import * as i4 from "@angular/common";
import * as i5 from "./infoBox.component";
var styles_InfoBoxComponent = [];
var RenderType_InfoBoxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InfoBoxComponent, data: {} });
export { RenderType_InfoBoxComponent as RenderType_InfoBoxComponent };
function View_InfoBoxComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "accordeon", [], [[1, "class", 0]], null, null, i1.View_AccordeonComponent_0, i1.RenderType_AccordeonComponent)), i0.ɵdid(1, 49152, null, 0, i2.AccordeonComponent, [i0.ChangeDetectorRef, i3.OewaService], { title: [0, "title"], isOpen: [1, "isOpen"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "div", [["class", "infoBox-content"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; var currVal_2 = _co.open; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.content; _ck(_v, 2, 0, currVal_3); }); }
function View_InfoBoxComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "infoBox-content"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 0, 0, currVal_0); }); }
export function View_InfoBoxComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "infoBox"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InfoBoxComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InfoBoxComponent_2)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.title; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_InfoBoxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "infobox", [], null, null, null, View_InfoBoxComponent_0, RenderType_InfoBoxComponent)), i0.ɵdid(1, 49152, null, 0, i5.InfoBoxComponent, [], null, null)], null, null); }
var InfoBoxComponentNgFactory = i0.ɵccf("infobox", i5.InfoBoxComponent, View_InfoBoxComponent_Host_0, {}, {}, []);
export { InfoBoxComponentNgFactory as InfoBoxComponentNgFactory };
