import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {AdModel} from '../../../model/ad/ad-model';
import {AD_DIMENSIONS} from '../../../model/const/ad';
import {AdDimensions, AdSize} from '../../../model/enum/ad';
import {IStickySubscriber, StickyService} from '../../../shared/service/sticky.service';
import {STICKY_SUPERBANNER} from '../../../model/const/sticky';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'adSidebar',
  templateUrl: 'adSidebar.component.html'
})
export class AdSidebarComponent implements IStickySubscriber, OnInit {

  @ViewChild('container', { static: true }) elmRef: ElementRef;
  @Input() alwaysShow = false;

  model: AdModel;

  constructor(private renderer: Renderer2, private stickyService: StickyService) {
  }

  ngOnInit(): void {
    this.model = new AdModel(
      'adSidebar',
      AdSize.ad_sidebar,
      [AD_DIMENSIONS[AdDimensions.dim_120x600],
        AD_DIMENSIONS[AdDimensions.dim_160x600],
        AD_DIMENSIONS[AdDimensions.dim_300x600],
      ],
      true,
      (d) => {
        const w = (window.innerWidth - 964);
        // w < 420??
        if (w < 240) {
          return this.alwaysShow && d[0] <= w;
        }
        return d[0] <= w / 2;
      },
      false,
    );
    this.stickyService.subscribe(STICKY_SUPERBANNER, this);
  }

  visibilityChanged(isVisible: boolean) {
    if (isVisible) {
      this.renderer.removeClass(this.elmRef.nativeElement, 'isFixed');
    } else {
      this.renderer.addClass(this.elmRef.nativeElement, 'isFixed');
    }

  }
}
