import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {VendorTypes} from '@model/enum/vendorTypes';
import {DidomiService} from '@shared/service/didomi.service';
import {VENDOR_NAME_MAP, VENDOR_PARAGRAPH_IMAGE_MAP, VENDOR_PARAGRAPH_MAP} from '@model/const/didomi';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-no-consent',
  templateUrl: './no-consent.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class NoConsentComponent {
  @Input() vendor: VendorTypes;
  @Input() image: string;
  @Input() text: string;

  constructor(private didomiService: DidomiService) {
  }

  get embed(): string {
    return VENDOR_PARAGRAPH_MAP[this.vendor];
  }

  get img(): string {
    return VENDOR_PARAGRAPH_IMAGE_MAP[this.vendor];
  }

  get vendorName(): string {
    return VENDOR_NAME_MAP[this.vendor];
  }

  acceptVendor() {
    this.didomiService.enableVendor([this.vendor]);
  }
}
