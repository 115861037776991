import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {ITopicHeader} from '../../../../model/search/payload';
import {LinkBarModel} from '../../../../model/component/linkBar';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'topicHeader',
  templateUrl: './topicHeader.component.html'
})
export class TopicHeaderComponent implements DynamicComponent<ITopicHeader> {

  model: ITopicHeader;
  linkBarModel: LinkBarModel;

  initModel(model: ITopicHeader): void {
    this.model = model;
    this.linkBarModel = new LinkBarModel(`Alles zu: ${model.title}`, '', true);
  }
}
