import {Injectable} from '@angular/core';
import {BrowserRef} from './browser.ref';

@Injectable({
  providedIn: 'root',
})
export class CssClassService {


  constructor(private browserRef: BrowserRef) {
  }

  setBodyClass(cssClass: string, add: boolean) {
    add ? this.body.add(cssClass) : this.body.remove(cssClass);
  }

  private get body(): DOMTokenList {
    return this.browserRef.document.body.classList;
  }
}
