import {Component, OnInit} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {ITextLink} from '@model/channel/textLink/payload';
import {PortalRoute} from '@model/teaser/portalRoute';

@Component({
  selector: 'app-text-link',
  templateUrl: './text-link.component.html',
})
export class TextLinkComponent implements DynamicComponent<ITextLink> {

  model: ITextLink;

  constructor() {
  }

  initModel(model: ITextLink) {
    this.model = model;
  }

  get link() {
    return PortalRoute.fromUri(this.model.url);
  }

}
