import {Directive, ElementRef, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Directive({
  selector: '[appAdBlockBlock]'
})
export class AdBlockBlockDirective implements OnInit {

  blackListed: string[] = ['bs-c-pwdBy'];

  constructor(
    private hostElement: ElementRef,
    @Inject(PLATFORM_ID) protected platformId: string,
  ) {
  }

  get blocked(): boolean {
    return getComputedStyle(this.hostElement.nativeElement).display === 'none';
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        const el = this.hostElement.nativeElement;
        const classes: DOMTokenList = el.classList;
        if (this.blocked) {
          this.blackListed.forEach(cls => {
            if (classes.contains(cls)) {
              classes.remove(cls);
            }
          });
        }
      }, 1000);
    }
  }

}
