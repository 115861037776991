import {Component} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {IAdPiano} from '@model/content/ad-piano/payload';

@Component({
  selector: 'app-ad-piano',
  template: '<div [id]="placeholder"></div>',
})
export class AdPianoComponent implements DynamicComponent<IAdPiano> {

  placeholder: string;

  constructor() {
  }

  initModel(model: IAdPiano) {
    this.placeholder = 'placeholder_' + model.idx;
  }

}
