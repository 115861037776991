import {Component} from '@angular/core';
import {TabModel} from '@model/component/tab';
import {Observable, Subject} from 'rxjs';
import {DeviceDetectionService} from '../../../../shared/service/device-detection.service';
import {FootballService} from '@shared/service/football.service';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {IFootballStage} from '@model/content/footballTable/payload';
import {map, switchMap} from 'rxjs/operators';
import {SliderConfigModel} from '@model/component/sliderConfig';
import {FootballStageModel} from '@model/content/footballStages/footballStage';
import {IFootballStages} from '@model/content/footballStages/payload';

@Component({
  selector: 'app-football-stages-wrapper',
  templateUrl: './football-stages-wrapper.component.html',
})
export class FootballStagesWrapperComponent implements DynamicComponent<IFootballStages> {

  private activeTab = new Subject<IFootballStage>();
  tabs$: Observable<TabModel<IFootballStage>[]>;
  footBallStageModel$: Observable<FootballStageModel[]>;
  heading$: Observable<string>;
  sliderConfigModel = new SliderConfigModel(true, 'footballStageSlider', false);

  constructor(
    private mobileDetectionService: DeviceDetectionService,
    private footballService: FootballService,
  ) {
  }

  initModel(model: IFootballStages): void {
    const y = +model.data.footballSeason.split('/')[0];
    let year = y + '/' + (y + 1);
    if (
      model.data.footballLeague === 'world-cup' ||
      model.data.footballLeague === 'wc-qualification-europe' ||
      model.data.footballLeague === 'european-championship' ||
      model.data.footballLeague === 'uefa-women-euro'
    ) {
      year = year.split('/')[0];
    }
    this.tabs$ = this.footballService.getStageTabs(model.data.footballLeague, year);
    this.heading$ = this.footballService
      .leagueFromId$(this.footballService.footBallLeagueIds[model.data.footballLeague])
      .pipe(
        map(res => res.data.name),
      );
    this.footBallStageModel$ = this.activeTab$.pipe(
      switchMap(stage => {
        return this.footballService.getFootballStageModel(stage);
      }),
    );
  }

  get activeTab$() {
    return this.activeTab.asObservable();
  }

  switchTab(tab: TabModel<IFootballStage>) {
    this.activeTab.next(tab.info);
  }

  get isMobile() {
    return this.mobileDetectionService.isMobile;
  }
}
