import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {LinkBarModel} from '../../../../model/component/linkBar';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'linkbar',
  templateUrl: './linkBar.component.html',
})
export class LinkBarComponent {

  @Input() model: LinkBarModel;

}
