import { PortalRoute } from './model/teaser/portalRoute';
import { environment } from '../environments/environment';
export function getCurrentRouteUri() {
    var hostname = window.location.hostname;
    var path = window.location.pathname;
    var currentRoute = PortalRoute.fromHostname(hostname, path);
    return currentRoute.uri();
}
var ɵ0 = environment.cleverpush_channels, ɵ1 = environment.endpoint_options, ɵ2 = environment.piano_options, ɵ3 = environment.production, ɵ4 = environment.pusher_app_key, ɵ5 = environment.tv_api, ɵ6 = environment.coral_prod;
var AppBrowserModule = /** @class */ (function () {
    function AppBrowserModule() {
    }
    return AppBrowserModule;
}());
export { AppBrowserModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
