<div class="footballTable">
  <h2 class="footballTable-title"
      *ngIf="model.heading">{{model.heading}}</h2>
  <table class="footballTable-table">
    <tr class="footballTable-header">
      <th *ngFor="let heading of model.tableHeadings"
          [class.isSortable]="heading.sortBy"
          [class.hide-mobile]="heading.hideMobile"
          class="footballTable-header-cell">
        <a *ngIf="heading.sortBy; else headingName" (click)="model.sortByProp(heading.sortBy)">
          <ng-container [ngTemplateOutlet]="headingName"></ng-container>
          <svgicon *ngIf="model.currentSorting === heading.sortBy"
                   [icon]="model.getSortingState(heading.sortBy) ? 'angle-up' : 'angle-down'"></svgicon>
        </a>
        <ng-template #headingName>
          <ng-container *ngIf="heading.name">{{heading.name}}</ng-container>
        </ng-template>
      </th>
    </tr>
    <tr *ngFor="let team of model.rows" class="footballTable-row">
      <td class="footballTable-row-cell">{{team.pl}}</td>
      <td class="footballTable-row-cell hide-mobile">
        <ng-container [ngSwitch]="team.status">
          <svgicon *ngSwitchCase="'up'" [icon]="'angle-up'"></svgicon>
          <svgicon *ngSwitchCase="'down'" [icon]="'angle-down'"></svgicon>
          <ng-container *ngSwitchDefault>—</ng-container>
        </ng-container>
      </td>
      <td class="footballTable-row-cell hide-mobile">
        <div class="team-logo">
          <img *ngIf="team.logo"
               [src]="team.logo"
               alt="{{team.name}} Logo">
        </div>
      </td>
      <td class="footballTable-row-cell">{{team.name}}</td>
      <td class="footballTable-row-cell">{{team.sp}}</td>
      <td class="footballTable-row-cell hide-mobile">{{team.s}}</td>
      <td class="footballTable-row-cell hide-mobile">{{team.u}}</td>
      <td class="footballTable-row-cell hide-mobile">{{team.n}}</td>
      <td class="footballTable-row-cell hide-mobile">{{team.goals}}</td>
      <td class="footballTable-row-cell hide-mobile">{{team.diff}}</td>
      <td class="footballTable-row-cell">{{team.points}}</td>
    </tr>
  </table>
</div>
