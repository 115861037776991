import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {INewsletterSignUp} from '@model/content/newsletterSignUp/payload';
import {PortalService} from '@shared/service/portal.service';
import {Observable} from 'rxjs';
import {DidomiService} from '@shared/service/didomi.service';
import {tap} from 'rxjs/operators';
import {VendorTypes} from '@model/enum/vendorTypes';

@Component({
  selector: 'newslettersignup',
  templateUrl: './newsletter-sign-up.component.html',
})
export class NewsletterSignUpComponent implements OnInit, DynamicComponent<INewsletterSignUp> {

  @Input() acId: number;
  pianoContainerSelector: string;
  showNewsletter$: Observable<boolean>;
  scriptSet = false;
  vendorType: VendorTypes;

  constructor(
    private portalService: PortalService,
    private renderer: Renderer2,
    private hostElement: ElementRef,
    private didomiService: DidomiService,
  ) {
  }

  ngOnInit() {
    if (this.acId) {
      this.initModel({
        type: 'newsletter_signup',
        data: {
          value: this.acId + '',
        }
      });
    }
  }

  initModel(model: INewsletterSignUp): void {
    if (!(+model.data.value)) {
      this.vendorType = VendorTypes.piano;
      this.pianoContainerSelector = '_form_' + model.data.value;
    } else {
      this.vendorType = VendorTypes.activeCampaign;
      this.acId = +model.data.value;
    }
    this.showNewsletter$ = this.didomiService.checkVendor$(this.vendorType)
      .pipe(
        tap(consent => {
          if (consent && !this.scriptSet && this.acId) {
            this.scriptSet = true;
            const script = this.renderer.createElement('script');
            this.renderer.setAttribute(script, 'src', 'https://kurier.activehosted.com/f/embed.php?id=' + this.acId);
            this.renderer.setAttribute(script, 'type', 'text/javascript');
            this.renderer.setAttribute(script, 'charset', 'utf-8');
            this.renderer.appendChild(this.hostElement.nativeElement, script);
          }
        }),
      );
  }
}
