import { BrowserRef } from './browser.ref';
import * as i0 from "@angular/core";
import * as i1 from "./browser.ref";
var CssClassService = /** @class */ (function () {
    function CssClassService(browserRef) {
        this.browserRef = browserRef;
    }
    CssClassService.prototype.setBodyClass = function (cssClass, add) {
        add ? this.body.add(cssClass) : this.body.remove(cssClass);
    };
    Object.defineProperty(CssClassService.prototype, "body", {
        get: function () {
            return this.browserRef.document.body.classList;
        },
        enumerable: true,
        configurable: true
    });
    CssClassService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CssClassService_Factory() { return new CssClassService(i0.ɵɵinject(i1.BrowserRef)); }, token: CssClassService, providedIn: "root" });
    return CssClassService;
}());
export { CssClassService };
