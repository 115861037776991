<a
    class="tag"
    [ngClass]="model.className"
    *ngIf="model.link"
    [portalRouterLink]="model.link">
  <ng-container *ngTemplateOutlet="tagContent"></ng-container>
</a>

<span
    class="tag"
    [ngClass]="model.className"
    *ngIf="!model.link">
  <ng-container *ngTemplateOutlet="tagContent"></ng-container>
</span>


<ng-template #tagContent>
  <picimage
      *ngIf="model.image"
      [model]="model.image"></picimage>
  {{model.title}}
</ng-template>
