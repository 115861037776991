/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../common/ui/iconButton/iconButton.component.ngfactory";
import * as i2 from "../../common/ui/iconButton/iconButton.component";
import * as i3 from "./no-consent-piano.component";
import * as i4 from "../../../shared/service/overlay.service";
import * as i5 from "../../../shared/service/didomi.service";
var styles_NoConsentPianoComponent = [];
var RenderType_NoConsentPianoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NoConsentPianoComponent, data: {} });
export { RenderType_NoConsentPianoComponent as RenderType_NoConsentPianoComponent };
export function View_NoConsentPianoComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "section", [["class", "deactivatedAdBlock"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" Wir w\u00FCrden hier gerne ", " zeigen. Leider haben Sie uns hierf\u00FCr keine Zustimmung gegeben. Wenn Sie diesen anzeigen wollen, stimmen Sie bitte ", " zu"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "iconbutton", [["class", "login-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_IconButtonComponent_0, i1.RenderType_IconButtonComponent)), i0.ɵdid(4, 114688, null, 0, i2.IconButtonComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openConsents() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Cookie Einstellungen "]))], function (_ck, _v) { var currVal_2 = "times"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.embed; var currVal_1 = _co.vendorName; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_NoConsentPianoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent-piano", [], null, null, null, View_NoConsentPianoComponent_0, RenderType_NoConsentPianoComponent)), i0.ɵdid(1, 49152, null, 0, i3.NoConsentPianoComponent, [i4.OverlayService, i5.DidomiService], null, null)], null, null); }
var NoConsentPianoComponentNgFactory = i0.ɵccf("app-no-consent-piano", i3.NoConsentPianoComponent, View_NoConsentPianoComponent_Host_0, {}, {}, []);
export { NoConsentPianoComponentNgFactory as NoConsentPianoComponentNgFactory };
