import {ChangeDetectionStrategy, Component} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {IScreenings} from '@model/channel/screenings/payload';
import {FilterItem, ScreeningsModel} from '@model/channel/screenings/screenings';
import {ApiService} from '@shared/service/api.service';
import {Filter} from '@model/component/filter';
import {TimeAgoUtil} from '@util/timeAgo';
import {BehaviorSubject, merge, Observable, Subject} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {GoogleTagManagerService} from '@shared/service/gtm.service';
import {OewaService} from '@shared/service/oewa.service';
import {LinkBarModel} from '@model/component/linkBar';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'screenings',
  templateUrl: './screenings.component.html',
})
export class ScreeningsComponent implements DynamicComponent<IScreenings> {
  screeningsModel: ScreeningsModel;
  model$: Observable<ScreeningsModel>;
  private modelUpdate$ = new Subject<Filter<any, FilterItem>>();
  private fetchScreenings$ = new BehaviorSubject<string | number>(null);
  private model: IScreenings;
  linkBarModel: LinkBarModel;

  constructor(private apiService: ApiService, protected gtm: GoogleTagManagerService, protected oewaService: OewaService) {
  }

  initModel(model: IScreenings): void {
    this.model = model;
    this.linkBarModel = new LinkBarModel(model.title, '', true);
    this.model$ = merge(this.initModelNew(), this.modelUpdate$.pipe(
      map(() => {
        this.screeningsModel.buildList(this.model);
        return this.screeningsModel;
      })
    ));
    this.fetchScreenings$.next(TimeAgoUtil.getYYYYmmdd(new Date()));
  }

  initModelNew() {
    return this.fetchScreenings$.pipe(
      switchMap((date: string) => {
        let tags: string;
        if (this.model.tags) {
          tags = this.model.tags.map(t => t.name).join();
        }
        return this.apiService.screenings(this.model.category, date, this.model.id, this.model.city, tags).pipe(
          map(result => {
            if (!this.screeningsModel) {
              if (result.result.length === 0 && result.filter.days.length !== 0
                && this.fetchScreenings$.getValue() !== result.filter.days[0]) {
                this.fetchScreenings$.next(TimeAgoUtil.getYYYYmmdd(new Date(result.filter.days[0])));
                const screening = ScreeningsModel.fromScreenings(result, this.model);
                screening.emptyMessage = null;
                screening.filter.map(f => {
                  f.valueSelected = 0;
                });
                this.screeningsModel = screening;
              } else {
                this.screeningsModel = ScreeningsModel.fromScreenings(result, this.model);
              }
            } else {
              this.screeningsModel.data = result;
              this.screeningsModel.buildList(this.model);
            }
            return this.screeningsModel;
          })
        );
      })
    );
  }

  private setOwaTrigger() {
    this.oewaService.pushCp('filter');
  }

  onChangeSelect(filter: Filter<any, FilterItem>) {
    if (filter.id === 'day') {
      this.fetchScreenings$.next(filter.values[filter.valueSelected].value);
    } else {
      this.modelUpdate$.next(filter);
    }
    this.setOwaTrigger();
    const data = {
      valueSelected: filter.values[filter.valueSelected].value
    };
    this.gtm.pushEvent('DA - ScreeningsComponent Filter ' + filter.id, data);

  }

  onChangeCheckbox(filter: Filter<any, FilterItem>) {
    this.modelUpdate$.next(filter);
    this.setOwaTrigger();
    const data = {
      valueSelected: filter.id
    };
    this.gtm.pushEvent('DA - ScreeningsComponent Filter ' + filter.id, data);

  }
}
