import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {IPosterSlider} from '../../../../model/component/posterSlider/payload';
import {BoxModel} from '../../../../model/component/box';
import {TeasersService} from '../../../../shared/service/teasers.service';
import {IMovieSerie} from '../../../../model/movieSerie/payload';
import {PosterSliderItemModel} from '../../../../model/component/posterSlider/posterSlider';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SliderConfigModel} from '../../../../model/component/sliderConfig';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'posterslider',
  templateUrl: './posterSlider.component.html',
})
export class PosterSliderComponent implements DynamicComponent<IPosterSlider> {

  data$: Observable<PosterSliderItemModel[]>;
  boxModel: BoxModel;
  sliderConfigModel: SliderConfigModel;
  private model: IPosterSlider;

  constructor(private teaserService: TeasersService) {
  }

  initModel(model: IPosterSlider): void {
    this.model = model;
    if (this.model.items) {
      this.data$ = forkJoin(this.model.items.map(id => {
        return this.teaserService.getTeaser(id);
      })).pipe(map(this.setMovies));
    } else {
      this.data$ = this.teaserService.getCollection(model.collectionName, +model.numberOfTeasers)
        .pipe(map(this.setMovies));
    }
    this.sliderConfigModel = new SliderConfigModel(false, 'poster-slider', false, model.title);
  }

  setMovies = (teasers: IMovieSerie[]) => {
    const m = PosterSliderItemModel.fromTeasers(teasers);
    this.boxModel = new BoxModel(this.model);
    return m;
  }

}
