import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ITeaser} from '../../model/teaser/payload';

@Injectable({
  providedIn: 'root',
})
export class RelatedService {

  constructor(protected api: ApiService, protected router: Router) {
  }

  forArticle(docId: string, length: number): Observable<ITeaser[]> {
    return this.api.articleRelateds(docId, length).pipe(map(teaserC => teaserC.items));
  }

  forChannel(channelId: string, length: number): Observable<ITeaser[]> {
    return this.api.channelRelateds(channelId, length).pipe(map(teaserC => teaserC.items));
  }

  forSection(section: string, length: number): Observable<ITeaser[]> {
    return this.api.sectionRelateds(section, length).pipe(map(teaserC => teaserC.items));
  }
}
