<a
  *ngFor="let item of nav"
  [gtm]="gtmId"
  [name]="item.title"
  [ngClass]="item.slug ? 'portals-' + item.slug : item.title"
  [href]="item.link"
  [target]="item.target">
  <svg *ngIf="item.slug">
    <use [attr.xlink:href]="'#' + item.slug"></use>
  </svg>
  <span>{{item.title}}</span>
</a>
<a href="" (click)="openConsentOverlay($event)">Cookie Einstellungen</a>
