import {Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {LoadExternalScriptService} from '@shared/service/loadExternalScript.service';
import {ScriptId} from '@model/enum/scriptId';
import {IFacebook} from '@model/content/facebook/payload';
import {Observable} from 'rxjs';
import {DidomiService} from '@shared/service/didomi.service';
import {tap} from 'rxjs/operators';
import {VendorTypes} from '@model/enum/vendorTypes';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'facebook',
  templateUrl: 'facebook.component.html'
})
export class FacebookComponent implements DynamicComponent<IFacebook> {

  fbLink: string;
  divClass = '';
  showText = false;
  loadFacebook$: Observable<boolean>;
  vendorType = VendorTypes.facebook;
  tabs: string;
  height: string;
  header: boolean;
  cover: boolean;
  facepile: boolean;
  width: string;

  constructor(
    private loadScript: LoadExternalScriptService,
    private didomiService: DidomiService,
  ) {
  }

  initModel(model: IFacebook): void {

    this.loadFacebook$ = this.didomiService.checkVendor$(this.vendorType).pipe(
      tap(consent => {
        if (consent) {
          this.loadScript.loadScript(ScriptId.facebook).subscribe(fb => {
            fb.scriptVar.XFBML.parse();
          });
        }
      })
    );


    if (model.type === 'facebook_video') {
      this.divClass = 'fb-video';
      this.width = 'auto';
      this.fbLink = model.data.url;
    } else if (model.type === 'facebook_post') {
      this.showText = true;
      this.divClass = 'fb-post';
      this.width = 'auto';
      this.fbLink = model.data.url;
    } else if (model.type === 'facebook_page') {
      this.fbLink = model.data.link;
      this.divClass = 'fb-page';
      this.tabs = model.data.tabs;
      this.height = model.data.height;
      this.header = !!+model.data.small_header;
      this.cover = !!+model.data.hide_cover;
      this.facepile = !!+model.data.show_facepile;
    }
  }
}
