import {AfterContentChecked, ContentChildren, Directive, Input, OnDestroy, QueryList} from '@angular/core';
import {AccordeonComponent} from './accordeon.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[accordeonCtrl]'
})
export class AccordeonCtrlDirective implements AfterContentChecked, OnDestroy {

  @Input('accordeonCtrl') select = -1;
  @ContentChildren(AccordeonComponent) accComps: QueryList<AccordeonComponent>;

  private isInitialized = false;
  private activeAcc: AccordeonComponent;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  ngAfterContentChecked() {
    if (!this.isInitialized) {
      this.accComps.forEach(acc => {
        this.isInitialized = true;
        acc.selectedChanged
          .pipe(takeUntil(this.destroy$))
          .subscribe(comp => {
              if (this.activeAcc) {
                this.activeAcc.close();
              }
              this.activeAcc = this.activeAcc !== comp ? comp : null;
            }
          );
      });
      if (this.select && this.select > -1) {
        this.activeAcc = this.accComps.filter((elm, i) => this.select === i)[0];
        // Note this doesn't actually update, even with ChangeDetectionRef
        if (this.activeAcc) {
          this.activeAcc.open();
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
