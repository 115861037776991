import {Injectable} from '@angular/core';
import {PortalService} from './portal.service';
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TitleService {

  constructor(protected readonly portalService: PortalService,
              private readonly title: Title) {
  }

  public setDocumentTitle(title) {
    const documentTitle = this.createDocumentTitle(title);
    this.title.setTitle(documentTitle);
  }

  protected createDocumentTitle(title) {
    const hostname = this.portalService.hostname();
    if (!title || title === hostname) {
      return hostname;
    }
    return title + ' | ' + hostname;
  }
}
