import {ChangeDetectionStrategy, Component, Inject, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {LoadExternalScriptService} from '@shared/service/loadExternalScript.service';
import {IInstagram, IInstagramResponse} from '@model/content/instagram/payload';
import {DidomiService} from '@shared/service/didomi.service';
import {Observable} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {VendorTypes} from '@model/enum/vendorTypes';
import {HttpClient} from '@angular/common/http';
import {ScriptId} from '@model/enum/scriptId';
import {isPlatformBrowser} from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'instagram',
  templateUrl: 'instagram.component.html'
})
export class InstagramComponent implements DynamicComponent<IInstagram> {

  url: string;
  showInstagram$: Observable<string>;
  vendorType = VendorTypes.instagram;

  constructor(
    @Inject(PLATFORM_ID) protected platformId: string,
    private loadScript: LoadExternalScriptService,
    private didomiService: DidomiService,
    private http: HttpClient,
  ) {
  }

  initModel(model: IInstagram): void {
    if (isPlatformBrowser(this.platformId)) {
      this.url = model.data.url;
      this.showInstagram$ = this.didomiService.checkVendor$(this.vendorType).pipe(
        filter(Boolean),
        switchMap(() => {
          const apiUrl = model.data.url.split('?')[0];
          let url = 'https://graph.facebook.com/v8.0/instagram_oembed?url=' + apiUrl
            + '&access_token=3285646935099311|b75138b1e52191c1c9ebb8e7dabdecb5';
          if (!model.data.include_caption) {
            url += '&hidecaption=true';
          }
          return this.http.get<IInstagramResponse>(url);
        }),
        map(res => res.html.split('<script')[0]),
        tap(() => this.loadScript.loadScript(ScriptId.instagram)
          .subscribe(res => {
            setTimeout(() => {
              res.scriptVar.Embeds.process();
            }, 5000);
          }),
        )
      );
    }
  }

}
