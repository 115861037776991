import {BoxColor, BoxStyle} from '../enum/box';
import {ChannelId} from '../enum/channelId';
import {Channel} from '../teaser/channel';
import {channelIdFromChannel} from '../../util/channelUtil';
import {IBoxComponent} from '../payload';

export class BoxModel {

  boxStyle: BoxStyle;
  boxColor: BoxColor;
  channelId: ChannelId;
  cssClass: string;


  static fromChannel(channel?: Channel): BoxModel {
    return new BoxModel({color: 'standard'}, channel);
  }

  constructor(box: IBoxComponent = {color: 'standard'}, channel?: Channel) {
    this.boxColor = BoxColor[box.color];
    this.boxStyle = box.style ? BoxStyle[box.style] : BoxStyle.classic;
    this.channelId = channel && (this.boxColor === BoxColor.channel || this.boxColor === BoxColor.standard) ?
      channelIdFromChannel(channel) :
      ChannelId.none;
    this.cssClass = this.boxStyle;
    if (this.boxStyle === BoxStyle.classic) {
      if (this.channelId) {
        this.cssClass = (this.boxColor === BoxColor.channel ? 'c' : 's') + '-' + this.channelId;
      } else if (this.boxColor === BoxColor.schauTV) {
        this.cssClass = 's-' + this.boxColor;
      } else {
        this.cssClass = 'c-' + this.boxColor;
      }
    } else {
      this.cssClass = 'c-' + this.boxStyle;
    }
    this.cssClass = 'bs-' + this.cssClass;
  }
}
