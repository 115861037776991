/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../shared/directive/portalRoute.directive";
import * as i3 from "@angular/router";
import * as i4 from "../../../shared/service/portal.service";
import * as i5 from "../../../shared/service/browser.ref";
import * as i6 from "../../../shared/directive/videoTeaser.directive";
import * as i7 from "../../../shared/service/oewa.service";
import * as i8 from "../../../shared/service/dynamicComponents.service";
import * as i9 from "../../../shared/service/gtm.service";
import * as i10 from "../ui/svgIcon/svgIcon.component.ngfactory";
import * as i11 from "../ui/svgIcon/svgIcon.component";
import * as i12 from "../../../shared/pipe/stripHtml.pipe";
import * as i13 from "./image.component";
import * as i14 from "../../../shared/service/image.service";
import * as i15 from "../../../shared/service/overlay.service";
var styles_ImageComponent = [];
var RenderType_ImageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImageComponent, data: {} });
export { RenderType_ImageComponent as RenderType_ImageComponent };
function View_ImageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ImageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 7, "a", [["class", "image"]], [[2, "portrait", null], [2, "isRound", null], [8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(3, 1), i0.ɵdid(4, 540672, null, 0, i2.PortalRouteDirective, [i3.Router, i0.ElementRef, i4.PortalService, i5.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), i0.ɵdid(5, 4734976, null, 0, i6.VideoTeaserDirective, [i0.ElementRef, i0.Renderer2, i0.ViewContainerRef, i0.ChangeDetectorRef, i0.ComponentFactoryResolver, i7.OewaService, i8.DynamicComponentsService, i9.GoogleTagManagerService], { teaserVideo: [0, "teaserVideo"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageComponent_2)), i0.ɵdid(7, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_4 = "image"; var currVal_5 = _ck(_v, 3, 0, _co.model.imgClass); _ck(_v, 2, 0, currVal_4, currVal_5); var currVal_6 = _co.model.link; _ck(_v, 4, 0, currVal_6); var currVal_7 = _co.model.video; _ck(_v, 5, 0, currVal_7); var currVal_8 = i0.ɵnov(_v.parent, 6); _ck(_v, 7, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.lightbox; var currVal_1 = _co.model.isRounded; var currVal_2 = i0.ɵnov(_v, 4).target; var currVal_3 = i0.ɵnov(_v, 4).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_ImageComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ImageComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 6, "div", [["class", "image"]], [[2, "portrait", null], [2, "isRound", null]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(3, 1), i0.ɵdid(4, 4734976, null, 0, i6.VideoTeaserDirective, [i0.ElementRef, i0.Renderer2, i0.ViewContainerRef, i0.ChangeDetectorRef, i0.ComponentFactoryResolver, i7.OewaService, i8.DynamicComponentsService, i9.GoogleTagManagerService], { teaserVideo: [0, "teaserVideo"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageComponent_4)), i0.ɵdid(6, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "image"; var currVal_3 = _ck(_v, 3, 0, _co.model.imgClass); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _co.model.video; _ck(_v, 4, 0, currVal_4); var currVal_5 = i0.ɵnov(_v.parent, 6); _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.lightbox; var currVal_1 = _co.model.isRounded; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ImageComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "expand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openImageInModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "svgicon", [["icon", "expand"]], [[1, "class", 0]], null, null, i10.View_SvgIconComponent_0, i10.RenderType_SvgIconComponent)), i0.ɵdid(2, 49152, null, 0, i11.SvgIconComponent, [], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_1 = "expand"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).class; _ck(_v, 1, 0, currVal_0); }); }
function View_ImageComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "img", [["data-sizes", "auto"]], [[1, "data-src", 0], [1, "data-srcset", 0], [1, "width", 0], [8, "alt", 0], [8, "title", 0]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.sayHello() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(3, { "lazyload": 0 }), i0.ɵppd(4, 1), i0.ɵppd(5, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageComponent_6)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_5 = _ck(_v, 3, 0, true); _ck(_v, 2, 0, currVal_5); var currVal_6 = _co.model.lightbox; _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.src; var currVal_1 = _co.model.srcset; var currVal_2 = _co.model.width; var currVal_3 = i0.ɵunv(_v, 0, 3, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), _co.model.alt)); var currVal_4 = i0.ɵunv(_v, 0, 4, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), _co.model.title)); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_ImageComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.description; _ck(_v, 0, 0, currVal_0); }); }
function View_ImageComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\u00A9 Bild: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.copyright; _ck(_v, 1, 0, currVal_0); }); }
function View_ImageComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "caption"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "copyright"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageComponent_8)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageComponent_9)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.description; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.model.copyright; _ck(_v, 5, 0, currVal_1); }, null); }
function View_ImageComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "image-modal"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { width: 0, left: 1 }), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["class", "image-modal-close"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "svgicon", [["icon", "times"]], [[1, "class", 0]], null, null, i10.View_SvgIconComponent_0, i10.RenderType_SvgIconComponent)), i0.ɵdid(6, 49152, null, 0, i11.SvgIconComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "img", [["class", "lazyload"], ["data-sizes", "auto"]], [[1, "data-src", 0], [1, "data-srcset", 0], [1, "width", 0], [8, "alt", 0], [8, "title", 0]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.sayHello() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵppd(8, 1), i0.ɵppd(9, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.width + "px"), (_co.left + "px")); _ck(_v, 2, 0, currVal_0); var currVal_2 = "times"; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵnov(_v, 6).class; _ck(_v, 5, 0, currVal_1); var currVal_3 = _co.model.src; var currVal_4 = _co.model.srcset; var currVal_5 = _co.model.width; var currVal_6 = i0.ɵunv(_v, 7, 3, _ck(_v, 8, 0, i0.ɵnov(_v.parent, 0), _co.model.alt)); var currVal_7 = i0.ɵunv(_v, 7, 4, _ck(_v, 9, 0, i0.ɵnov(_v.parent, 0), _co.model.title)); _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_ImageComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i12.StripHtmlPipe, []), i0.ɵqud(402653184, 1, { overlay: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, [["img", 2]], null, 0, null, View_ImageComponent_5)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageComponent_7)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, [[1, 2], ["overlay", 2]], null, 0, null, View_ImageComponent_10))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.link; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.model.link; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.model.hasCaption; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_ImageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "picimage", [], null, null, null, View_ImageComponent_0, RenderType_ImageComponent)), i0.ɵdid(1, 638976, null, 0, i13.ImageComponent, [i14.ImageService, i15.OverlayService, i0.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageComponentNgFactory = i0.ɵccf("picimage", i13.ImageComponent, View_ImageComponent_Host_0, { model: "model" }, { loaded: "loaded" }, []);
export { ImageComponentNgFactory as ImageComponentNgFactory };
