import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {IDynamicComponent} from '../../../../model/payload';
import {BrowserRef} from '../../../../shared/service/browser.ref';
import {LinkBarModel} from '../../../../model/component/linkBar';
import {Observable} from 'rxjs';
import {DidomiService} from '@shared/service/didomi.service';
import {tap} from 'rxjs/operators';
import {VendorTypes} from '@model/enum/vendorTypes';

export interface IPinPoll extends IDynamicComponent {
  pinpollId?: string;
  pinpollType?: string;
  title?: string;
  data?: {
    id: string,
    type?: string,
  };
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'pinpoll',
  templateUrl: 'pinpoll.component.html'
})
export class PinPollComponent implements DynamicComponent<IPinPoll>, AfterViewInit {

  pinpollId: string;
  pinpollType: string;
  linkBar: LinkBarModel;
  showPinPoll$: Observable<boolean>;
  vendorType = VendorTypes.pinPoll;
  @ViewChild('pinpollDiv', {static: false}) pinpollDiv: ElementRef;

  constructor(
    private browserRef: BrowserRef,
    private didomiService: DidomiService,
  ) {
  }

  ngAfterViewInit() {
    // tslint:disable-next-line:no-string-literal
    const pinpoll = window['Pinpoll'];
    if (pinpoll) {
      pinpoll.init(this.pinpollDiv.nativeElement);
    }
  }

  initModel(model: IPinPoll) {
    this.showPinPoll$ = this.didomiService.checkVendor$(this.vendorType).pipe(
      tap(consent => {
        if (consent) {
          if (model.data) {
            this.pinpollId = model.data.id;
          } else if (model.pinpollId) {
            this.pinpollId = model.pinpollId;
          }

          if (model.data) {
            this.pinpollType = model.data.type || '';
          } else if (model.pinpollType) {
            this.pinpollType = model.pinpollType || '';
          }

          if (model.title) {
            this.linkBar = new LinkBarModel(model.title, '', true);
          }
        }
      })
    );
  }
}
