import * as tslib_1 from "tslib";
import { TeaserComponent } from '@component/common/teaser/teaser.component';
var ImageOnlyComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ImageOnlyComponent, _super);
    function ImageOnlyComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ImageOnlyComponent;
}(TeaserComponent));
export { ImageOnlyComponent };
