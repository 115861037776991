import {Component, ChangeDetectionStrategy, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {PortalRoute} from '@model/teaser/portalRoute';
import {ILinkField} from '@model/content/linkField/payload';
import {PortalService} from '@shared/service/portal.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'linkfield',
  templateUrl: 'linkfield.component.html'
})
export class LinkFieldComponent implements DynamicComponent<ILinkField> {

  link: PortalRoute;
  title: string;

  constructor(private portalService: PortalService) {
  }

  initModel(model: ILinkField): void {
    this.title = model.data.title;
    if (model.data.link.startsWith('/')) {
      this.link = PortalRoute.fromHostname(this.portalService.currentPortal(), model.data.link);
    } else {
      this.link = PortalRoute.fromUri(model.data.link);
    }
  }
}
