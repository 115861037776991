<div
  tabindex="0"
  (focusout)="focusOut()"
  (focusin)="focusIn()"
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
  [ngClass]="[sliderConfigModel.className, 'slider']">
  <nav class="slider-nav">
    <button
      name="vorheriger Slide"
      oewaClickTrigger
      class="slider-nav-prev"
      [disabled]="buttonDisabled.getValue() === buttonDisabledState.start"
      (click)="scrollTo(true)">
      <iconbutton [icon]="'angle-left'"></iconbutton>
    </button>
    <div
      *ngIf="sliderConfigModel.link || sliderConfigModel.title || sliderConfigModel.image"
      class="slider-title">
      <picimage class="slider-nav-image" *ngIf="sliderConfigModel.image" [model]="sliderConfigModel.image"></picimage>
      <a
        target="_blank"
        *ngIf="sliderConfigModel.link && sliderConfigModel.title"
        [portalRouterLink]="sliderConfigModel.link">
        {{ sliderConfigModel.title }}
      </a>
      <ng-container *ngIf="!sliderConfigModel.link && sliderConfigModel.title">
        {{ sliderConfigModel.title }}
      </ng-container>
    </div>
    <div
      *ngIf="sliderConfigModel.counter"
      class="slider-count">
      <span>
         {{currSlide$ | async }} / {{sliderTotalCount}}
      </span>
    </div>
    <button
      name="vorheriger Slide"
      oewaClickTrigger
      class="slider-nav-next"
      [disabled]="buttonDisabled.getValue() === buttonDisabledState.end"
      (click)="scrollTo(false)">
      <iconbutton [icon]="'angle-right'"></iconbutton>
    </button>
  </nav>

  <div
    class="slider-container"
    [@adaptiveHeightAnimation]="
      sliderConfigModel.adaptiveHeight && animationChange ?
      {value: 'oldHeight', params: {oldHeight: sliderHeight + 'px'}} :
      {value: 'newHeight', params: {newHeight: sliderHeight + 'px'}}">
    <div
      [class.adaptiveHeight]="sliderConfigModel.adaptiveHeight"
      [class.snapping]="!sliderConfigModel.stepsize && !sliderConfigModel.adaptiveHeight"
      class="slider-content"
      #slider>

      <ng-container *ngIf="!sliderConfigModel.adaptiveHeight">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </ng-container>

      <ng-container *ngIf="sliderConfigModel.adaptiveHeight">
        <div
          [ngStyle]="{'transform': 'translateX(' + transPos + ')'}"
          class="adaptiveHeightWrapper">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </ng-container>

    </div>
  </div>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

