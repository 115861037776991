export enum DataLayerType {
  AdSection = 'AdSection',
  Channel = 'Channel',
  Seitentyp = 'Seitentyp',
  Portal = 'Portal',
  Section = 'Section',
  Kampagne = 'Kampagne',
  ArtikelId = 'ArtikelId',
  Artikeltags = 'Artikeltags',
}
