/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i2 from "../../../common/ui/linkBar/linkBar.component";
import * as i3 from "@angular/common";
import * as i4 from "./channelLink.component";
var styles_ChannelLinkComponent = [];
var RenderType_ChannelLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChannelLinkComponent, data: {} });
export { RenderType_ChannelLinkComponent as RenderType_ChannelLinkComponent };
function View_ChannelLinkComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "linkbar", [["class", "article-linkBar"]], null, null, null, i1.View_LinkBarComponent_0, i1.RenderType_LinkBarComponent)), i0.ɵdid(1, 49152, null, 0, i2.LinkBarComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkBarModel; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ChannelLinkComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChannelLinkComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkBarModel; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ChannelLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "channellink", [], null, null, null, View_ChannelLinkComponent_0, RenderType_ChannelLinkComponent)), i0.ɵdid(1, 49152, null, 0, i4.ChannelLinkComponent, [], null, null)], null, null); }
var ChannelLinkComponentNgFactory = i0.ɵccf("channellink", i4.ChannelLinkComponent, View_ChannelLinkComponent_Host_0, {}, {}, []);
export { ChannelLinkComponentNgFactory as ChannelLinkComponentNgFactory };
