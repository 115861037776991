<footer class="siteFooter">
  <ul *ngIf="currentPortal === 'lustaufoesterreichat'" class="social-media-footer-links">
    <li>
      <a href="https://www.facebook.com/lustaufoesterreich" target="_blank"><img [src]="'/assets/'+currentPortal+'/FaceBook_Logo.svg'" alt=""/></a>
    </li>
    <li>
      <a href="https://www.instagram.com/lustaufoesterreich/" target="_blank"><img [src]="'/assets/'+currentPortal+'/Instagram_Logo.svg'" alt=""/></a>
    </li>
    <li>
      <a href="https://www.pinterest.at/LustaufOesterreich/" target="_blank"><img [src]="'/assets/'+currentPortal+'/Pintarest_Logo.svg'" alt=""/></a>
    </li>
    <li>
      <a href="https://www.youtube.com/@lustaufosterreich7739" target="_blank"><img [src]="'/assets/'+currentPortal+'/Youtube_Logo.svg'" alt=""/></a>
    </li>
  </ul>
  
  <p class="siteFooter-homeLink"><a [portalRouterLink]="homeLink" class="button">Zur Startseite</a>
  </p>
  <nav class="siteFooter-menu">
    <a class="siteFooter-menuItem" *ngFor="let menuPoint of (footerMenu$ | async) as footerMenu; last as isLast"
       [portalRouterLink]="menuPoint.portalRoute">
      {{menuPoint.title}}
      <span *ngIf="!isLast"> |</span>
    </a>
  </nav>
</footer>
