import * as tslib_1 from "tslib";
import { TeaserComponent } from '@component/common/teaser/teaser.component';
var TextTeaserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TextTeaserComponent, _super);
    function TextTeaserComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TextTeaserComponent;
}(TeaserComponent));
export { TextTeaserComponent };
