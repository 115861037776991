import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {TeasersService} from '../../../../shared/service/teasers.service';
import {ITeaser} from '../../../../model/teaser/payload';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, switchMap, tap} from 'rxjs/operators';
import {TabModel} from '../../../../model/component/tab';
import {ITabbedList} from '../../../../model/channel/tabbedList/payload';
import {TabbedListTabModel} from '../../../../model/channel/tabbedList/tab';
import {GTMTriggerId} from '../../../../model/enum/gtm';
import {TeaserModel} from '../../../../model/teaser/teaser';
import {DeviceDetectionService} from '../../../../shared/service/device-detection.service';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'tabbedlist',
  templateUrl: 'tabbedlist.component.html'
})
export class TabbedListComponent implements DynamicComponent<ITabbedList> {

  teasers$: Observable<ITeaser[]>;
  model: ITabbedList;
  tabModels: TabModel<TabbedListTabModel>[];
  mediaModels: TeaserModel[][] = [];
  gtmId = GTMTriggerId.more_button;

  private fetchTeasers$ = new BehaviorSubject<{ numTeasers: number }>(null);
  private activeTab: TabModel<TabbedListTabModel>;

  constructor(
    private teasers: TeasersService,
    private mobileDetectionService: DeviceDetectionService,
  ) {
  }

  get hasMoreEntries(): boolean {
    return !this.activeTab.info.collectionEmpty &&
      this.mediaModel.length < (this.activeTab.info.numberOfTeasers + this.activeTab.info.additionalTeaserStep);
  }

  get mediaModel(): TeaserModel[] {
    return this.mediaModels[this.activeTab.id];
  }

  initModel(model: ITabbedList): void {
    this.model = model;
    model.additionalTeaserStep = model.additionalTeaserStep || 7;
    model.cta = model.cta || 'Mehr Schlagzeilen';
    this.tabModels = model.tabs.map((tab, i) => {
      this.mediaModels[i] = [];
      return new TabModel(i, tab.title, new TabbedListTabModel(tab.collectionName, +model.numberOfTeasers || +tab.numberOfTeasers,
        model.additionalTeaserStep), i === 0);
    });

    this.teasers$ = this.fetchTeasers$.pipe(
      filter(Boolean),
      switchMap(next => {
        return this.teasers.getCollection(this.activeTab.info.collectionName, next.numTeasers).pipe(
          tap(teaser => {
            this.activeTab.info.collectionEmpty = teaser.length < next.numTeasers;
            const fromMediaTeasers = TeaserModel.fromMediaTeasers(teaser, model.teaserView, this.mobileDetectionService.isMobile, model);
            this.mediaModels[this.activeTab.id] = this.mediaModel.concat(fromMediaTeasers);
          }),
        );
      }),
    );
  }

  fetchMoreEntries() {
    this.fetchTeasers$.next({numTeasers: this.activeTab.info.additionalTeaserStep});
    return false;
  }

  switchTab(tab: TabModel<TabbedListTabModel>) {
    this.activeTab = tab;
    const m = this.mediaModels[tab.id];
    if (m.length === 0) {
      this.fetchTeasers$.next({numTeasers: this.activeTab.info.numberOfTeasers});
    }
    return false;
  }
}
