import { CLEVERPUSH_CHANNELS } from './tokens';
import { PortalService } from './portal.service';
import { LoadExternalScriptService } from './loadExternalScript.service';
import { ScriptId } from '../../model/enum/scriptId';
import { SCRIPT_MAP } from '../../model/const/script';
import { filter } from 'rxjs/operators';
import { StateService } from './state.service';
import { PianoService } from '@shared/service/piano/piano.service';
import * as i0 from "@angular/core";
import * as i1 from "./portal.service";
import * as i2 from "./loadExternalScript.service";
import * as i3 from "./piano/piano.service";
import * as i4 from "./state.service";
var CleverpushService = /** @class */ (function () {
    function CleverpushService(portalService, loadScript, piano, stateService) {
        this.loadScript = loadScript;
        this.piano = piano;
        var cleverpushChannels = stateService.get(CLEVERPUSH_CHANNELS);
        var currentPortal = portalService.currentPortal();
        this.projectId = cleverpushChannels[currentPortal] || null;
    }
    CleverpushService.prototype.init = function () {
        var _this = this;
        if (!this.projectId) {
            return;
        }
        SCRIPT_MAP[ScriptId.cleverpush] = "https://static.cleverpush.com/channel/loader/" + this.projectId + ".js";
        this.loadScript.loadScript(ScriptId.cleverpush).subscribe(function (value) {
            _this.cleverpush = value.scriptVar;
            _this.piano.user$.pipe(filter(function (user) { return user.valid; })).subscribe(function (user) {
                _this.setAttribute('user_id', user.uid);
                _this.setAttribute('name', user.firstName + '' + user.lastName);
            });
        });
    };
    CleverpushService.prototype.setAttribute = function (id, value) {
        this.cleverpush.push(['setAttribute', id, value]);
    };
    CleverpushService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CleverpushService_Factory() { return new CleverpushService(i0.ɵɵinject(i1.PortalService), i0.ɵɵinject(i2.LoadExternalScriptService), i0.ɵɵinject(i3.PianoService), i0.ɵɵinject(i4.StateService)); }, token: CleverpushService, providedIn: "root" });
    return CleverpushService;
}());
export { CleverpushService };
