import { take } from 'rxjs/operators';
import { StateService } from '@shared/service/state.service';
import { InjectScriptsService } from '@shared/service/injectScripts.service';
import { MenuService } from '@shared/service/menu.service';
import { ImageService } from '@shared/service/image.service';
import { ImageSrc } from '@model/enum/image';
import { PortalId } from '@model/enum/portal';
import { ROUTE_INFO } from '@shared/service/tokens';
import * as i0 from "@angular/core";
import * as i1 from "./menu.service";
import * as i2 from "./injectScripts.service";
import * as i3 from "./image.service";
import * as i4 from "./state.service";
var LdJsonService = /** @class */ (function () {
    function LdJsonService(menuService, injectScripts, imageService, stateService) {
        var _a;
        this.menuService = menuService;
        this.injectScripts = injectScripts;
        this.imageService = imageService;
        this.stateService = stateService;
        this.LOGO_DIM_MAP = (_a = {},
            _a[PortalId.kurierat] = 158,
            _a[PortalId.motorat] = 196,
            _a[PortalId.kuriertv] = 241,
            _a[PortalId.filmat] = 191,
            _a);
    }
    LdJsonService.personMap = function (persons) {
        return persons.map(function (person) {
            return {
                '@type': 'Person',
                name: person.name
            };
        });
    };
    LdJsonService.prototype.injectArticleTags = function (article) {
        var _this = this;
        if (this.stateService.isFirstApp) {
            var routeInfo_1 = this.stateService.get(ROUTE_INFO, null);
            this.menuService.get('social').pipe(take(1)).subscribe(function (socialMenu) {
                var url = 'https://' + (article.portal === 'lustaufoesterreich.at' ? 'lust-auf-oesterreich.at' : article.portal);
                var authors = article.authors && article.authors.length > 0 ? article.authors.map(function (a) {
                    return {
                        '@type': 'Person',
                        name: a.firstname + ' ' + a.lastname,
                        url: article.portal + '/author/' + a.username,
                    };
                }) : {
                    '@type': 'Organization',
                    name: article.portal,
                };
                var timeZoneOffset = new Date(article.published_date).getTimezoneOffset();
                var hours = Math.floor(timeZoneOffset / 60);
                var minutes = timeZoneOffset % 60;
                var timeZone = '+' + (hours > 9 ? hours : '0' + (hours * -1)) + ':' + (minutes > 9 ? minutes : '0' + minutes);
                var date = function (d) {
                    return new Date(d).toISOString().split('.')[0];
                };
                var tags = {
                    '@context': 'http://schema.org',
                    '@type': 'NewsArticle',
                    headline: article.title,
                    mainEntityOfPage: url + article.url,
                    datePublished: date(article.published_date) + timeZone,
                    dateModified: date(article.updated_date) + timeZone,
                    description: article.teaser_text,
                    author: authors,
                    keywords: routeInfo_1 && routeInfo_1.dataLayer.Artikeltags ? routeInfo_1.dataLayer.Artikeltags : '',
                    publisher: {
                        '@type': 'Organization',
                        name: article.portal,
                        url: url,
                        sameAs: socialMenu.map(function (m) {
                            return m.link;
                        }),
                        logo: {
                            '@type': 'ImageObject',
                            url: url + '/assets/' + article.portal.replace('.', '') + '/logos/logo.png',
                            width: article.portal === 'lustaufoesterreich.at' ? 165 : _this.LOGO_DIM_MAP[article.portal],
                            height: 40
                        }
                    },
                };
                if (article.teaser_img) {
                    var imgUrl16to9 = _this.imageService.url(article.teaser_img.url, ImageSrc.LS_1232);
                    var imgUrlSQ = _this.imageService.url(article.teaser_img.url, ImageSrc.SQ_1232);
                    tags.image = [
                        imgUrl16to9,
                        imgUrlSQ,
                    ];
                }
                tags.isAccessibleForFree = true;
                if (article.paid_content) {
                    tags.isAccessibleForFree = false;
                    tags.hasPart = {
                        '@type': 'WebPageElement',
                        isAccessibleForFree: false,
                        cssSelector: '.plusContent',
                    };
                }
                var scriptDef = {
                    attrs: {
                        type: 'application/ld+json'
                    },
                    innerHTML: JSON.stringify(tags),
                };
                _this.injectScripts.injectToHeadOnce(scriptDef);
            });
        }
    };
    LdJsonService.prototype.injectFilmTags = function (film) {
        var _this = this;
        var portal = 'https://film.at';
        if (this.stateService.isFirstApp) {
            this.menuService.get('social').pipe(take(1)).subscribe(function (socialMenu) {
                var url = portal;
                var directors = film.directors ? LdJsonService.personMap(film.directors) : '';
                var music = film.music ? LdJsonService.personMap(film.music) : '';
                var genre = film.genres.map(function (g) {
                    return g.name;
                });
                var tags = {
                    '@context': 'http://schema.org',
                    '@type': 'Movie',
                    headline: film.title,
                    duration: 'PT' + film.playtime + 'M',
                    mainEntityOfPage: _this.imageService.url(film.poster.url, ImageSrc.V_1864),
                    description: film.teaser_text,
                    directors: directors,
                    musicBy: music,
                    genre: genre,
                    dateCreated: film.release_date,
                    publisher: {
                        '@type': 'Organization',
                        name: portal,
                        url: url,
                        sameAs: socialMenu.map(function (m) {
                            return m.link;
                        }),
                        logo: {
                            '@type': 'ImageObject',
                            url: url + '/assets/filmat/logos/logo.png',
                            width: _this.LOGO_DIM_MAP[PortalId.filmat],
                            height: 40
                        }
                    },
                };
                if (film.poster.url) {
                    var imgUrl16to9 = _this.imageService.url(film.poster.url, ImageSrc.LS_1232);
                    var imgUrlSQ = _this.imageService.url(film.poster.url, ImageSrc.SQ_1232);
                    // tslint:disable-next-line:no-string-literal
                    tags['image'] = [
                        imgUrl16to9,
                        imgUrlSQ,
                    ];
                }
                var scriptDef = {
                    attrs: {
                        type: 'application/ld+json'
                    },
                    innerHTML: JSON.stringify(tags),
                };
                _this.injectScripts.injectToHeadOnce(scriptDef);
            });
        }
    };
    LdJsonService.prototype.injectTvTags = function (jsonLd) {
        if (this.stateService.isFirstApp) {
            var scriptDef = {
                attrs: {
                    type: 'application/ld+json'
                },
                innerHTML: JSON.stringify(jsonLd),
            };
            this.injectScripts.injectToHeadOnce(scriptDef);
        }
    };
    LdJsonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LdJsonService_Factory() { return new LdJsonService(i0.ɵɵinject(i1.MenuService), i0.ɵɵinject(i2.InjectScriptsService), i0.ɵɵinject(i3.ImageService), i0.ɵɵinject(i4.StateService)); }, token: LdJsonService, providedIn: "root" });
    return LdJsonService;
}());
export { LdJsonService };
