import {ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {BeforeAfterSliderModel} from '../../../../model/content/beforeAfterSlider/beforeAfterSlider';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {IBeforeAfterSlider} from '../../../../model/content/beforeAfterSlider/payload';

@Component({
  selector: 'beforeAfterSlider',
  templateUrl: './before-after-slider.component.html',
})
export class BeforeAfterSliderComponent implements DynamicComponent<IBeforeAfterSlider> {

  @Input()
  model: BeforeAfterSliderModel;
  @ViewChild('slideContainer', { static: true }) slideContainer: ElementRef;
  @ViewChild('dragger', { static: true }) dragger: ElementRef;
  dragStart: number;
  pos = 0;
  title: string;
  html: string;

  constructor(private ref: ChangeDetectorRef) {
  }

  initModel(model: IBeforeAfterSlider): void {

    this.model = BeforeAfterSliderModel.deserialize(model);

    const dragger = this.dragger.nativeElement;
    const container = this.slideContainer.nativeElement;
    dragger.addEventListener('touchstart', this.handleDragStart.bind(this), false);
    container.addEventListener('touchmove', this.handleDragMove.bind(this), false);
    dragger.addEventListener('mousedown', this.handleDragStart.bind(this), false);
    container.addEventListener('mousemove', this.handleClickDragMove.bind(this), false);
    this.title = model.data.title;
    this.html = model.data.html;
  }

  private handleDragStart(e: TouchEvent) {
    const rect = this.dragger.nativeElement.getBoundingClientRect();
    const pos = rect.x + (rect.width / 2);
    this.dragStart = pos;
  }

  private handleDragMove(e: TouchEvent) {
    this.calcPosition(e.touches[0].clientX);
  }

  private handleClickDragMove(e: MouseEvent) {
    if (e.buttons === 1) {
      this.calcPosition(e.clientX);
    }
  }

  private calcPosition(n: number) {
    const dragEnd = n;
    const distance = dragEnd - this.dragStart;
    const width = this.slideContainer.nativeElement.offsetWidth;
    if (distance <= width / 2 && distance >= width / -2) {
      const percent = (distance * 100) / width;
      const newPos = this.pos + percent;
      if (newPos >= 0 && newPos <= 100) {
        this.pos = newPos;
        this.dragStart = dragEnd;
      } else if (newPos < 0) {
        this.pos = 0;
      } else if (newPos > 100) {
        this.pos = 100;
      }
      this.ref.detectChanges();
    }
  }

  public imgLoaded(e) {
    this.pos = 50;
  }

}
