import {NgModule} from '@angular/core';
import {DepartmentBlockComponent} from './departmentBlock/departmentblock.component';
import {GallerySliderComponent} from './gallerySlider/gallerySlider.component';
import {LinkTeaserComponent} from './linkTeaser/linkTeaser.component';
import {MultiSliderComponent} from './multiSlider/multiSlider.component';
import {SingleSliderComponent} from './singleSlider/singleSlider.component';
import {SingleTeaserComponent} from './singleTeaser/singleTeaser.component';
import {TabbedListComponent} from './tabbedList/tabbedlist.component';
import {TopTopicsComponent} from './topTopics/toptopics.component';
import {VideoPlaylistComponent} from './videoPlaylist/videoPlaylist.component';
import {ContentGardenTeaserComponent} from './contentGarden/contentGardenTeaser.component';
import {SharedModule} from '@shared/shared.module';
import {HomeCommonModule} from '../common/homeCommon.module';
import {JobBoxComponent} from './jobBox/jobBox.component';
import {ImmoBoxComponent} from './immoBox/immoBox.component';
import {TitleBoxComponent} from './titleBox/titleBox.component';
import {MovieListComponent} from './movieList/movieList.component';
import {ScreeningsComponent} from './screenings/screenings.component';
import {RomyVotingComponent} from './romyVoting/romyVoting.component';
import {TextBoxComponent} from './text-box/text-box.component';
import {TextLinkComponent} from './text-link/text-link.component';


export const CHANNEL_COMPS = [
  DepartmentBlockComponent,
  GallerySliderComponent,
  LinkTeaserComponent,
  MultiSliderComponent,
  SingleSliderComponent,
  SingleTeaserComponent,
  TabbedListComponent,
  TopTopicsComponent,
  VideoPlaylistComponent,
  ContentGardenTeaserComponent,
  JobBoxComponent,
  ImmoBoxComponent,
  TitleBoxComponent,
  TextBoxComponent,
  TextLinkComponent,
  // TODO could there be a way to lazy load this?
  MovieListComponent,
  ScreeningsComponent,
  RomyVotingComponent,
];

@NgModule({
  imports: [
    SharedModule,
    HomeCommonModule,
  ],
  declarations: [
    ...CHANNEL_COMPS,
  ],
  exports: [
    ...CHANNEL_COMPS
  ],
})
export class ChannelModule {
}
