<div class="tabBar">
  <ul class="tabBar-list">
    <li *ngFor="let tab of model" class="tabBar-list-item" [class.isActive]="tab === activeTab">
      <button
        [name]="'wechsle tab zu ' + tab.name"
        [gtm]="gtmId"
        oewaClickTrigger
        (click)="selectTab(tab)">{{tab.name}}
      </button>
    </li>
  </ul>
</div>

