/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./ad-piano.component";
var styles_AdPianoComponent = [];
var RenderType_AdPianoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdPianoComponent, data: {} });
export { RenderType_AdPianoComponent as RenderType_AdPianoComponent };
export function View_AdPianoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], [[8, "id", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; _ck(_v, 0, 0, currVal_0); }); }
export function View_AdPianoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-ad-piano", [], null, null, null, View_AdPianoComponent_0, RenderType_AdPianoComponent)), i0.ɵdid(1, 49152, null, 0, i1.AdPianoComponent, [], null, null)], null, null); }
var AdPianoComponentNgFactory = i0.ɵccf("app-ad-piano", i1.AdPianoComponent, View_AdPianoComponent_Host_0, {}, {}, []);
export { AdPianoComponentNgFactory as AdPianoComponentNgFactory };
