import { LoadExternalScriptService } from './loadExternalScript.service';
import { PUSHER_APP_KEY } from './tokens';
import { StateService } from './state.service';
import { BrowserRef } from './browser.ref';
import { ScriptId } from '../../model/enum/scriptId';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./browser.ref";
import * as i2 from "./state.service";
import * as i3 from "./loadExternalScript.service";
var PusherService = /** @class */ (function () {
    function PusherService(prodmode, browserRef, stateService, loadScript) {
        this.prodmode = prodmode;
        this.browserRef = browserRef;
        this.stateService = stateService;
        this.loadScript = loadScript;
    }
    PusherService.prototype.init = function () {
        var _this = this;
        var pusherAppKey = this.stateService.get(PUSHER_APP_KEY);
        return this.loadScript.loadScript(ScriptId.pusher).pipe(tap(function (v) {
            v.scriptVar.logToConsole = !_this.prodmode;
            _this.pusher = new v.scriptVar(pusherAppKey, {
                encrypted: true,
                cluster: 'eu'
            });
            // tslint:disable-next-line:no-string-literal
            _this.browserRef.window['pusher'] = _this.pusher;
        }));
    };
    PusherService.prototype.onChannel = function (channelName, callback) {
        if (this.pusher) {
            var channel = this.pusher.subscribe(channelName);
            channel.bind_global(function (event, data) {
                callback.call(undefined, event, data);
            });
            return channel;
        }
    };
    PusherService.prototype.unsubscribe = function (channel) {
        channel.unbind();
    };
    PusherService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PusherService_Factory() { return new PusherService(i0.ɵɵinject("prod_mode"), i0.ɵɵinject(i1.BrowserRef), i0.ɵɵinject(i2.StateService), i0.ɵɵinject(i3.LoadExternalScriptService)); }, token: PusherService, providedIn: "root" });
    return PusherService;
}());
export { PusherService };
