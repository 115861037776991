import {AdDimensions} from '../enum/ad';

export const GPT_ID = '/39025507/';

export const AD_DIMENSIONS = {
  [AdDimensions.dim_1x1]: [1, 1],
  [AdDimensions.dim_1x2]: [1, 2],
  [AdDimensions.dim_1x10]: [1, 10],
  [AdDimensions.dim_100x600]: [100, 600],
  [AdDimensions.dim_120x600]: [120, 600],
  [AdDimensions.dim_160x600]: [160, 600],
  [AdDimensions.dim_300x50]: [300, 50],
  [AdDimensions.dim_300x169]: [300, 169],
  [AdDimensions.dim_300x250]: [300, 250],
  [AdDimensions.dim_300x251]: [300, 251],
  [AdDimensions.dim_300x200]: [300, 200],
  [AdDimensions.dim_300x600]: [300, 600],
  [AdDimensions.dim_300x601]: [300, 601],
  [AdDimensions.dim_320x50]: [320, 50],
  [AdDimensions.dim_400x800]: [400, 800],
  [AdDimensions.dim_468x60]: [468, 60],
  [AdDimensions.dim_500x1000]: [500, 1000],
  [AdDimensions.dim_620x50]: [620, 50],
  [AdDimensions.dim_620x100]: [620, 100],
  [AdDimensions.dim_728x90]: [728, 90],
  [AdDimensions.dim_960x250]: [960, 250],
  [AdDimensions.dim_960x100]: [960, 100],
  [AdDimensions.dim_480x270]: [480, 270],
  [AdDimensions.dim_400x300]: [400, 300],
  [AdDimensions.dim_fluid]: ['fluid'],
};
