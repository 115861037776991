import {ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation} from '@angular/core';
import {Results} from '@model/search/results';
import {ChannelSearchService} from '@shared/service/channelSearch.service';
import {PortalService} from '@shared/service/portal.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {PortalRoute} from '@model/teaser/portalRoute';
import {Router} from '@angular/router';
import {SearchItemModel} from '@model/search/searchItem';
import {SearchOrder} from '@model/enum/search';
import {PortalId} from '@model/enum/portal';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'searchmenu',
  templateUrl: 'searchMenu.component.html',
  providers: [ChannelSearchService]
})
export class SearchMenuComponent {

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSelectSearch = new EventEmitter();
  searchResult$: Observable<Results>;
  searchLink: PortalRoute;
  articles: SearchItemModel[];
  topics: SearchItemModel[];
  movies: SearchItemModel[];
  series: SearchItemModel[];
  theaters: SearchItemModel[];
  searchQuery = '';

  constructor(private searchService: ChannelSearchService,
              private portalService: PortalService,
              private router: Router) {
    this.searchService.page_size = 5;
    this.searchService.baseUrl = 'search';
    this.searchResult$ = this.searchService.searchResult.pipe(tap(results => {
      this.articles = SearchItemModel.fromTeasers(results.articles);
      this.topics = SearchItemModel.fromTopics(results.topics, portalService);
      if (this.portalService.hostname() === PortalId.filmat) {
        const series = results.series && results.series.length ?
          results.series : [];
        const movies = results.movies && results.movies.length ?
          results.movies : [];
        const arr = [...movies, ...series];
        this.movies = SearchItemModel.fromMovies(arr);
        this.theaters = SearchItemModel.fromTheaters(results.theaters);
      }
    }));
  }

  onSearch(searchInput: string) {
    if (searchInput === '') {
      this.searchService.clearSubject();
      return;
    }
    this.searchQuery = searchInput;
    this.searchService.searchByTerm(searchInput, SearchOrder.ORDER_DATE);
    this.searchLink = this.searchService.getLink();
  }

  onSelect(selected: number) {
    if (selected === -1) {
      this.router.navigateByUrl(this.portalService.urlOfRoute(this.searchLink));
      this.searchSelected();
      return;
    }
    let portalRoute: PortalRoute;
    if (selected < this.articles.length) {
      portalRoute = this.articles[selected].portalRoute;
    } else {
      portalRoute = this.topics[selected - this.articles.length + 1].portalRoute;
    }
    if (!portalRoute) {
      return;
    }

    this.searchService.clearSubject();
    this.router.navigateByUrl(this.portalService.urlOfRoute(portalRoute));
    this.searchSelected();
  }

  searchSelected() {
    this.onSelectSearch.emit();
  }

  clickSearchLink() {
    if (!this.searchLink) {
      return;
    }
    this.router.navigateByUrl(this.portalService.urlOfRoute(this.searchLink));
    this.onSelectSearch.emit();
  }

  navigateToMovies() {
    this.router.navigateByUrl('/alle-filme?term=' + this.searchService.term);
    return false;
  }
}
