<div
  *ngIf="loadFacebook$ | async; else noContent"
  data-adapt-container-width="true"
  [attr.data-width]="width"
  [ngClass]="divClass"
  [attr.data-href]="fbLink"
  [attr.data-tabs]="tabs"
  [attr.data-height]="height"
  [attr.data-small-header]="header"
  [attr.data-hide-cover]="cover"
  [attr.data-show-facepile]="facepile"
  [attr.data-show-textParagraph]="showText">
  <blockquote [attr.cite]='fbLink' class="fb-xfbml-parse-ignore"></blockquote>
</div>

<ng-template #noContent>
  <app-no-consent [vendor]="vendorType"></app-no-consent>
</ng-template>
