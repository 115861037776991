import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DynamicComponentDirective} from './directive/dynamicComponent.directive';
import {PortalRouteDirective} from './directive/portalRoute.directive';
import {OewaClickTriggerDirective} from './directive/oewaClickTrigger.directive';
import {VideoTeaserDirective} from './directive/videoTeaser.directive';
import {RouterModule} from '@angular/router';
import {PipeModule} from './pipe/pipe.module';
import {PaginatorComponent} from '@component/common/ui/paginator/paginator.component';
import {ImageComponent} from '@component/common/image/image.component';
import {IconButtonComponent} from '@component/common/ui/iconButton/iconButton.component';
import {SvgIconComponent} from '@component/common/ui/svgIcon/svgIcon.component';
import {LinkBarComponent} from '@component/common/ui/linkBar/linkBar.component';
import {TabBarComponent} from '@component/common/ui/tabBar/tabBar.component';
import {AccordeonComponent} from '@component/common/ui/accordeon/accordeon.component';
import {AccordeonCtrlDirective} from '@component/common/ui/accordeon/accordeonCtrl.directive';
import {SliderComponent} from '@component/common/slider/slider.component';
import {LinkCardComponent} from '@component/common/linkcard/linkCard.component';
import {SearchInputComponent} from '@component/common/searchInput/searchInput.component';
import {CardSliderComponent} from '@component/common/cardSlider/cardSlider.component';
import {SwipeDirective} from '@component/common/ui/swipe.directive';
import {VideoComponent} from '@component/common/video/video.component';
import {InteractionBarComponent} from '@component/common/interaction-bar/interaction-bar.component';
import {GtmTriggerDirective} from '@component/common/gtmTrigger.directive';
import {CalendarComponent} from '@component/common/ui/calendar/calendar.component';
import {InputComponent} from '@component/common/ui/formControls/input/input.component';
import {CheckboxComponent} from '@component/common/ui/formControls/checkbox/checkbox.component';
import {SelectComponent} from '@component/common/ui/formControls/select/select.component';
import {RadioComponent} from '@component/common/ui/formControls/radio/radio.component';
import {TagComponent} from '@component/common/ui/tag/tag.component';
import {AdBlockBlockDirective} from '@shared/directive/ad-block-block.directive';
import {TeaserDirective} from '@component/common/teaser/teaser.directive';
import {NoConsentComponent} from '@component/home/content/no-consent/no-consent.component';
import {TvImageComponent} from '@component/tv/tv-image/tv-image.component';
import {SocialBarComponent} from '@component/common/socialBar/socialBar.component';

const SHARED_MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  PipeModule,
];

const SHARED_DIRECTIVES = [
  DynamicComponentDirective,
  PortalRouteDirective,
  OewaClickTriggerDirective,
  VideoTeaserDirective,
  AdBlockBlockDirective,
  TeaserDirective,
];

export const SHARED_COMPONENTS = [
  PaginatorComponent,
  ImageComponent,
  IconButtonComponent,
  SvgIconComponent,
  LinkBarComponent,
  TabBarComponent,
  AccordeonComponent,
  AccordeonCtrlDirective,
  SliderComponent,
  LinkCardComponent,
  SearchInputComponent,
  CardSliderComponent,
  SwipeDirective,
  VideoComponent,
  InteractionBarComponent,
  SocialBarComponent,
  GtmTriggerDirective,
  CalendarComponent,
  InputComponent,
  CheckboxComponent,
  SelectComponent,
  RadioComponent,
  TagComponent,
  NoConsentComponent,
  TvImageComponent,
];

@NgModule({
  imports: [
    ...SHARED_MODULES,
  ],
  declarations: [
    ...SHARED_DIRECTIVES,
    ...SHARED_COMPONENTS,
  ],
  exports: [
    ...SHARED_MODULES,
    ...SHARED_DIRECTIVES,
    ...SHARED_COMPONENTS,
  ]
})
export class SharedModule {
}
