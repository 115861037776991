import {parse} from 'url';
import {ITeaser} from '@model/teaser/payload';

export type PortalRouteOrNull = PortalRoute | null;

export class PortalRoute {

  protected constructor(protected readonly internalPortal: string,
                        protected readonly internalPath: string,
                        protected readonly internalProtocol: string = 'https',
                        protected readonly internalHash?: string,
  ) {
  }

  static fromTeaser(teaser: ITeaser): PortalRoute {
    if (teaser.portal) {
      return PortalRoute.fromHostname(teaser.portal, teaser.url);
    } else {
      return PortalRoute.fromUri(teaser.url);
    }
  }

  public get portal() {
    return this.internalPortal;
  }

  public get path() {
    return this.internalPath;
  }

  public get protocol() {
    return this.internalProtocol;
  }

  public get hash() {
    return this.internalHash;
  }

  static fromHostname(hostname: string, path: string): PortalRoute {
    if (hostname === null || hostname === 'null' || path === null || path === 'null') {
      return PortalRoute.fromHostname('kurier.at', '/');
    }
    const tld = hostname.split('.').slice(-2);
    let portal = tld.join('');
    if (portal === 'platformsh') {
      portal = hostname.split('.').slice(0, 2).join('');
    }
    const realPath = path || '/';
    return new PortalRoute(portal.toLowerCase(), realPath);
  }

  static fromUri(uri: string): PortalRouteOrNull {
    if (!uri) {
      return null;
    }
    const matches = /^\/([^\/]+)(.*)/.exec(uri);
    if (matches !== null) {
      const portal = matches[1];
      const path = matches[2] || '/';
      return new PortalRoute(portal, path);
    }
    const url = parse(uri);
    const protocol = url.protocol ? url.protocol.replace(/:$/, '') : null;
    const mailtoStr = 'mailto:';
    if (url.protocol === mailtoStr) {
      let mailtoLink: string;
      mailtoLink = uri.substring(mailtoStr.length);
      return new PortalRoute(mailtoLink, '', protocol);
    }
    return new PortalRoute(url.hostname, url.path, protocol, url.hash);
  }

  public uri(): string {
    const path = this.internalPath === '/' ? '' : this.internalPath;
    return '/' + this.internalPortal + path;
  }

  public newLink(link: string): PortalRoute {
    return new PortalRoute(this.internalPortal, link);
  }

}
