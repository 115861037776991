import {ChangeDetectionStrategy, Component, Inject, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {LoadExternalScriptService} from '../../../../shared/service/loadExternalScript.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {IDynamicComponent} from '../../../../model/payload';
import {ScriptId} from '../../../../model/enum/scriptId';
import {Observable} from 'rxjs';
import {DidomiService} from '@shared/service/didomi.service';
import {tap} from 'rxjs/operators';
import {VendorTypes} from '@model/enum/vendorTypes';
import {isPlatformBrowser} from '@angular/common';

export interface IRiddle extends IDynamicComponent {
  data: { riddle_id: number };
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'riddle',
  templateUrl: './riddle.component.html'
})
export class RiddleComponent implements DynamicComponent<IRiddle> {

  riddleId: number;
  riddleUrl: SafeResourceUrl;
  showRiddle$: Observable<boolean>;
  vendorType = VendorTypes.riddle;

  constructor(protected readonly loadExternalScript: LoadExternalScriptService,
              protected readonly sanitizer: DomSanitizer,
              private didomiService: DidomiService,
              @Inject(PLATFORM_ID) protected platformId: string,
  ) {
  }

  initModel(model: IRiddle): void {
    this.showRiddle$ = this.didomiService.checkVendor$(this.vendorType)
      .pipe(
        tap(consent => {
            if (consent) {
              this.loadExternalScript.loadScript(ScriptId.riddle).subscribe(riddle => {
                if (isPlatformBrowser(this.platformId)) {
                  setTimeout(() => {
                    riddle.scriptVar.update();
                  }, 5000);
                }
              });
              this.riddleId = model.data.riddle_id;
              this.riddleUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.riddle.com/a/' + this.riddleId);
            }
          }
        ));
  }
}
