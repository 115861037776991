import { OverlayService } from '@shared/service/overlay.service';
import { VendorTypes } from '@model/enum/vendorTypes';
import { DidomiService } from '@shared/service/didomi.service';
import { VENDOR_NAME_MAP, VENDOR_PARAGRAPH_MAP } from '@model/const/didomi';
var NoConsentPianoComponent = /** @class */ (function () {
    function NoConsentPianoComponent(overlayService, didomiService) {
        this.overlayService = overlayService;
        this.didomiService = didomiService;
    }
    Object.defineProperty(NoConsentPianoComponent.prototype, "embed", {
        get: function () {
            return VENDOR_PARAGRAPH_MAP[VendorTypes.piano];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NoConsentPianoComponent.prototype, "vendorName", {
        get: function () {
            return VENDOR_NAME_MAP[VendorTypes.piano];
        },
        enumerable: true,
        configurable: true
    });
    NoConsentPianoComponent.prototype.close = function () {
        this.overlayService.clearComponent();
    };
    NoConsentPianoComponent.prototype.openConsents = function () {
        this.didomiService.showConsentOverlay();
    };
    return NoConsentPianoComponent;
}());
export { NoConsentPianoComponent };
