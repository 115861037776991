import {
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Injectable,
  TemplateRef,
  Type,
  ViewContainerRef
} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {OverlayTemplateComponent} from '@component/common/overlay-template/overlay-template.component';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {

  private internalOverlayComp$: Subject<ComponentRef<any>> = new Subject();
  private internalOverlayOffset$: Subject<number> = new Subject();
  private vcRef: ViewContainerRef;
  private internalMutationObserver: MutationObserver;

  get overlayComp$(): Observable<ComponentRef<any>> {
    return this.internalOverlayComp$.asObservable();
  }

  get overlayOffset$(): Observable<number> {
    return this.internalOverlayOffset$.asObservable();
  }

  init(vcRef: ViewContainerRef) {
    this.vcRef = vcRef;
  }

  initOffset(elm: ElementRef) {
    this.internalMutationObserver = new MutationObserver(() => {
      this.internalOverlayOffset$.next(elm.nativeElement.scrollHeight);
    });

    this.internalMutationObserver.observe(elm.nativeElement, {
      subtree: true,
      childList: true,
    });
  }

  addComponent<T>(resolver: ComponentFactoryResolver, type: Type<T>): ComponentRef<T> {
    this.vcRef.clear();
    const factory = resolver.resolveComponentFactory<T>(type);
    const compRef: ComponentRef<T> = this.vcRef.createComponent(factory);
    this.internalOverlayComp$.next(compRef);
    return compRef;
  }

  addTemplate<T>(resolver: ComponentFactoryResolver, templateRef: TemplateRef<any>): ComponentRef<OverlayTemplateComponent> {
    this.vcRef.clear();
    const factory = resolver.resolveComponentFactory(OverlayTemplateComponent);
    const compRef: ComponentRef<OverlayTemplateComponent> = this.vcRef.createComponent(factory);
    this.internalOverlayComp$.next(compRef);
    compRef.instance.templateRef = templateRef;
    return compRef;
  }

  clearComponent() {
    this.vcRef.clear();
    this.internalOverlayComp$.next(null);
  }
}
