import { VendorTypes } from '@model/enum/vendorTypes';
import { DidomiService } from '@shared/service/didomi.service';
import { VENDOR_NAME_MAP, VENDOR_PARAGRAPH_IMAGE_MAP, VENDOR_PARAGRAPH_MAP } from '@model/const/didomi';
var NoConsentComponent = /** @class */ (function () {
    function NoConsentComponent(didomiService) {
        this.didomiService = didomiService;
    }
    Object.defineProperty(NoConsentComponent.prototype, "embed", {
        get: function () {
            return VENDOR_PARAGRAPH_MAP[this.vendor];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NoConsentComponent.prototype, "img", {
        get: function () {
            return VENDOR_PARAGRAPH_IMAGE_MAP[this.vendor];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NoConsentComponent.prototype, "vendorName", {
        get: function () {
            return VENDOR_NAME_MAP[this.vendor];
        },
        enumerable: true,
        configurable: true
    });
    NoConsentComponent.prototype.acceptVendor = function () {
        this.didomiService.enableVendor([this.vendor]);
    };
    return NoConsentComponent;
}());
export { NoConsentComponent };
