import { ImageModel } from '../component/image';
import { TimeAgoUtil } from '../../util/timeAgo';
import { ImageType } from '../enum/image';
import { PortalRoute } from '../teaser/portalRoute';
import { GTMTriggerId } from '../enum/gtm';
var MovieSerieModel = /** @class */ (function () {
    function MovieSerieModel() {
        this.badges = [];
        this.classNames = [];
    }
    Object.defineProperty(MovieSerieModel.prototype, "typeBadge", {
        get: function () {
            return this.seasonEpisodes ? 'Serie' : 'Film';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MovieSerieModel.prototype, "classes", {
        get: function () {
            return this.classNames.join(' ');
        },
        enumerable: true,
        configurable: true
    });
    MovieSerieModel.deserialize = function (data) {
        var m = new MovieSerieModel();
        m.title = data.title;
        m.ovTitle = data.ov_title;
        if (data.poster) {
            m.poster = new ImageModel(data.poster, ImageType.POSTER);
        }
        m.playTime = data.playtime;
        m.releaseDate = TimeAgoUtil.getDate(data.release_date);
        m.classification = data.classification;
        m.synopsis = data.teaser_text;
        m.badges = data.genres && data.genres.map(function (g) {
            return { name: g.name, link: PortalRoute.fromUri(g.uri), gtmId: GTMTriggerId.badge };
        });
        m.link = PortalRoute.fromTeaser(data);
        if (data.seasons && data.seasons.length) {
            var episodeCount_1 = 0;
            data.seasons.forEach(function (s) {
                if (s.episodes) {
                    episodeCount_1 += s.episodes.length;
                }
            });
            m.seasonEpisodes = data.seasons.length + ' / ' + episodeCount_1;
            m.classNames.push('serie');
        }
        else {
            m.classNames.push('film');
        }
        return m;
    };
    return MovieSerieModel;
}());
export { MovieSerieModel };
