/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../no-consent/no-consent.component.ngfactory";
import * as i2 from "../no-consent/no-consent.component";
import * as i3 from "../../../../shared/service/didomi.service";
import * as i4 from "@angular/common";
import * as i5 from "./riddle.component";
import * as i6 from "../../../../shared/service/loadExternalScript.service";
import * as i7 from "@angular/platform-browser";
var styles_RiddleComponent = [];
var RenderType_RiddleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RiddleComponent, data: {} });
export { RenderType_RiddleComponent as RenderType_RiddleComponent };
function View_RiddleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "riddle_target"], ["data-auto-scroll", "true"], ["data-bg", "#FFFFFF"], ["data-fg", "#D82A2A"]], [[1, "data-rid-id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "iframe", [["style", "width:100%;border:1px solid #cfcfcf;"]], [[1, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.riddleId; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.riddleUrl; _ck(_v, 1, 0, currVal_1); }); }
function View_RiddleComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i1.View_NoConsentComponent_0, i1.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i2.NoConsentComponent, [i3.DidomiService], { vendor: [0, "vendor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RiddleComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_RiddleComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["noContent", 2]], null, 0, null, View_RiddleComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.showRiddle$)); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_RiddleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "riddle", [], null, null, null, View_RiddleComponent_0, RenderType_RiddleComponent)), i0.ɵdid(1, 49152, null, 0, i5.RiddleComponent, [i6.LoadExternalScriptService, i7.DomSanitizer, i3.DidomiService, i0.PLATFORM_ID], null, null)], null, null); }
var RiddleComponentNgFactory = i0.ɵccf("riddle", i5.RiddleComponent, View_RiddleComponent_Host_0, {}, {}, []);
export { RiddleComponentNgFactory as RiddleComponentNgFactory };
