<ng-container *ngIf="entries$ | async as entries">
  <h4 class="liveBlog-title">
    {{model.data.title}}
  </h4>
  <ul class="liveBlog-list">
    <li *ngFor="let entry of entries; let i = index;" class="liveBlog-listItem"
        [ngClass]="entry.highlight">
      <span class="liveBlog-position" [id]="'lb-' + (totalEntries - i)"></span>
      <div class="liveBlog-listItem-entry">
        <span *ngIf="entry.published" class="liveBlog-listItem-entry-time">{{entry.published}} </span>
        <span *ngIf="entry.author?.name" class="liveBlog-listItem-entry-author">| {{entry.author?.name}}</span>
        <button
          (click)="copyToClipboard($event, 'lb-' + (totalEntries - i))"
          class="liveBlog-share">
          <svgicon [icon]="'share'"></svgicon>
        </button>
      </div>
      <div class="liveBlog-listItem-main">
        <header class="liveBlog-listItem-main-header">
          <svgicon *ngIf="entry.highlight" [icon]="entry.highlight"></svgicon>
          <h2 class="liveBlog-listItem-main-title">{{entry.title}}</h2>
        </header>
        <section class="liveBlog-listItem-main-content" [innerHTML]="entry.html"></section>
        <ng-container dynamicComponent *ngFor="let paragraph of entry.paragraphs" [model]="paragraph"></ng-container>
      </div>
      <ad-ticker *ngIf="(i + 1) % 3 === 0"></ad-ticker>
    </li>
  </ul>
  <div *ngIf="showMoreEntriesButton" class="liveBlog-button-container">
    <button class="button" (click)="fetchLiveBlogEntries()">Weitere Liveblog Einträge</button>
  </div>
</ng-container>
