var Filter = /** @class */ (function () {
    function Filter(type, id, label, values, filter, valueSelected) {
        this.type = type;
        this.id = id;
        this.label = label;
        this.values = values;
        this.filter = filter;
        this.valueSelected = valueSelected;
    }
    Object.defineProperty(Filter.prototype, "isUnset", {
        get: function () {
            return this.valueSelected === undefined || !this.selectedValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Filter.prototype, "selectedValue", {
        get: function () {
            if (typeof this.valueSelected === 'number') {
                return this.values[this.valueSelected].value;
            }
            return this.valueSelected;
        },
        enumerable: true,
        configurable: true
    });
    return Filter;
}());
export { Filter };
