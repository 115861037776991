import {Environment} from '../app/model/environment';

export const environment: Environment = {
  production: true,
  endpoint_options: {
    type: 'derived',
  },
  cleverpush_channels: {},
  piano_options: {
    aid: 'r1QtomDmj6',
    mode: 'experience',
    rid: 'RVBLMMZ',
    adFreeOfferId: 'OF8GTKDL0VIV',
    adFreeResourceId: 'RKD8O4V',
    adReducedId: 'RUFI4I3',
    adFreeTemplateId: 'OTEQFH3I1LBH',
    cid: 'CR6296043228370a22cab809bf',
  },
  pusher_app_key: '20267b0474c112b6cff5',
  tv_api: 'https://tv-api.kurier.at',
  coral_prod: true
};
