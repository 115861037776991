import { GPT_ID } from '../const/ad';
var AdModel = /** @class */ (function () {
    function AdModel(baseId, size, 
    /**
     * @link https://developers.google.com/doubleclick-gpt/reference#googletag.GeneralSize
     */
    internalDimensions, refresh, filter, setForceSafeFrame) {
        this.baseId = baseId;
        this.size = size;
        this.internalDimensions = internalDimensions;
        this.refresh = refresh;
        this.filter = filter;
        this.setForceSafeFrame = setForceSafeFrame;
    }
    Object.defineProperty(AdModel.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdModel.prototype, "adUnitPath", {
        get: function () {
            return this._adUnitPath;
        },
        set: function (path) {
            this._adUnitPath = path;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdModel.prototype, "dimensions", {
        get: function () {
            return this.internalDimensions.filter(this.filter);
        },
        enumerable: true,
        configurable: true
    });
    AdModel.prototype.assignId = function () {
        this._id = this.baseId + Math.floor(Math.random() * Math.pow(10, 15));
    };
    AdModel.prototype.setAdUnitPath = function (route) {
        var portal = route.dataLayer.Portal.replace(/\./, '');
        var isSpecial = route.dataLayer.Kampagne !== null;
        this._adUnitPath = isSpecial ?
            GPT_ID + portal + '_special_' + this.size :
            GPT_ID + portal + '_' + this.size;
        return this._adUnitPath;
    };
    return AdModel;
}());
export { AdModel };
