import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {IGoogleMaps} from '../../../../model/content/googleMaps/payload';
import {Observable} from 'rxjs';
import {DidomiService} from '@shared/service/didomi.service';
import {tap} from 'rxjs/operators';
import {VendorTypes} from '@model/enum/vendorTypes';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'googlemaps',
  templateUrl: 'googlemaps.component.html'
})
export class GoogleMapsComponent implements DynamicComponent<IGoogleMaps> {

  address: string;
  url: SafeResourceUrl;
  showGoogleMaps$: Observable<boolean>;
  vendorType = VendorTypes.googleMaps;

  constructor(
    private sanitizer: DomSanitizer,
    private didomiService: DidomiService,
  ) {
  }

  initModel(model: IGoogleMaps) {
    this.showGoogleMaps$ = this.didomiService.checkVendor$(this.vendorType).pipe(
      tap(consent => {
        if (consent) {
          this.address = encodeURIComponent(model.data.address);
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://maps.google.com/maps?q=' + this.address + '&output=embed');
        }
      })
    );
  }
}
