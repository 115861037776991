/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./quoteContainer.component";
var styles_QuoteContainerComponent = [];
var RenderType_QuoteContainerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QuoteContainerComponent, data: {} });
export { RenderType_QuoteContainerComponent as RenderType_QuoteContainerComponent };
function View_QuoteContainerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" | ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.context; _ck(_v, 1, 0, currVal_0); }); }
export function View_QuoteContainerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "quote"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "blockquote", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "quote-caption"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QuoteContainerComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.context; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.html; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.quotee; _ck(_v, 4, 0, currVal_1); }); }
export function View_QuoteContainerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "quotecontainer", [], null, null, null, View_QuoteContainerComponent_0, RenderType_QuoteContainerComponent)), i0.ɵdid(1, 49152, null, 0, i2.QuoteContainerComponent, [], null, null)], null, null); }
var QuoteContainerComponentNgFactory = i0.ɵccf("quotecontainer", i2.QuoteContainerComponent, View_QuoteContainerComponent_Host_0, {}, {}, []);
export { QuoteContainerComponentNgFactory as QuoteContainerComponentNgFactory };
