import {Inject, Injectable} from '@angular/core';
import {
  ANONYMOUS_USER,
  IPianoConversion,
  IPianoLoginResponse,
  IPianoUser,
  ISubscriptionListResponse,
  ITinyPass,
  IUserSubscription, StartCheckoutResponse
} from '@model/ITinyPass';
import {PIANO_OPTIONS, PROD_MODE} from '@shared/service/tokens';
import {StateService} from '@shared/service/state.service';
import {SCRIPT_MAP} from '@model/const/script';
import {BrowserRef} from '@shared/service/browser.ref';
import {PianoOptions} from '@model/environment';
import {LoadExternalScriptService} from '@shared/service/loadExternalScript.service';
import {ScriptId} from '@model/enum/scriptId';
import {BehaviorSubject, bindCallback, combineLatest, forkJoin, from, Observable, of, Subject} from 'rxjs';
import {filter, map, skip, switchMap, take, tap} from 'rxjs/operators';
import {RouteService} from '@shared/service/route.service';
import {IRoute} from '@model/payload';
import {
  GoogleTagManagerService,
  GTM_SUBSCRIPTION_LEVEL_ANONYMOUS, GTM_SUBSCRIPTION_LEVEL_PAID,
  GTM_SUBSCRIPTION_LEVEL_REGISTERED, GTM_SUBSCRIPTION_LEVEL_TRIAL
} from '@shared/service/gtm.service';
import {ApiService} from '@shared/service/api.service';
import {HttpClient} from '@angular/common/http';
import {CookieService} from '@shared/service/cookie.service';
import {PortalService} from '@shared/service/portal.service';
import {ActivatedRoute} from '@angular/router';
import {VendorTypes} from '@model/enum/vendorTypes';
import {DidomiService} from '@shared/service/didomi.service';

@Injectable({
  providedIn: 'root',
})
export class PianoService {

  readonly opts: PianoOptions;
  private readonly _user$: BehaviorSubject<IPianoUser>;
  private readonly _tp$: BehaviorSubject<ITinyPass>;
  private loading = new BehaviorSubject<{ loading: boolean }>({loading: false});

  constructor(@Inject(PROD_MODE) prodmode: boolean,
              private browserRef: BrowserRef,
              private loadScript: LoadExternalScriptService,
              private routeService: RouteService,
              private gtm: GoogleTagManagerService,
              private apiService: ApiService,
              private http: HttpClient,
              private cookieService: CookieService,
              private portalService: PortalService,
              stateService: StateService<PianoOptions>,
              private activatedRoute: ActivatedRoute,
              private didomiService: DidomiService,
  ) {
    this.opts = stateService.get(PIANO_OPTIONS);

    SCRIPT_MAP[ScriptId.tinypass] = `https://${this.opts.mode}.tinypass.com/xbuilder/experience/load?aid=${this.opts.aid}`;

    this._tp$ = new BehaviorSubject(null);
    this._user$ = new BehaviorSubject(ANONYMOUS_USER);

    // https://docs.piano.io/piano-javascript/
    this.browserRef.window.tp = [
      ['setAid', this.opts.aid],
      ['setDebug', !prodmode],
      ['setSandbox', this.opts.mode === 'sandbox'],
      ['setUsePianoIdUserProvider', true],
      ['setUseTinypassAccounts', false],
      ['init', this.init.bind(this)],
    ];
    this.gtm.pushDataLayerOnBrowser({
      SubscriptionLevel: GTM_SUBSCRIPTION_LEVEL_ANONYMOUS
    });
  }

  private _logout$: Subject<boolean> = new Subject<boolean>();

  get tp$(): Observable<ITinyPass> {
    return this._tp$;
  }

  get user$(): Observable<IPianoUser> {
    return this._user$.asObservable();
  }

  protected get tp(): ITinyPass {
    return this.browserRef.window.tp;
  }

  load() {
    this.loadScript.loadScript(ScriptId.tinypass);
  }

  private cXenseTrack(conversion: IPianoConversion) {
    if (this.portalService.currentPortal() === 'kurierat') {
      // @ts-ignore
      const cX = window.cX;
      if (cX) {
        cX.callQueue.push(['setEventAttributes', {
          origin: 'brj-conversion',
          persistedQueryId: '157b92afc5307b6c70480a8cc708547514fe43cb'
        }]);
        cX.callQueue.push(['sendEvent', conversion.rid + '']);
      }
    }
  }

  pushRoute(route: IRoute) {
    this.tp.push(['setContentAuthor', route.dataLayer.Autor]);
    const contentCreated = route.dataLayer.ErscheinungsZeitpunkt ? new Date(route.dataLayer.ErscheinungsZeitpunkt).toUTCString() : null;
    this.tp.push(['addHandler', 'startCheckout', this.startCheckout.bind(this)]);
    this.tp.push(['setContentCreated', contentCreated]);
    this.tp.push(['setContentIsNative', route.type === 'article' ? route.dataLayer.SponsoredContent : null]);
    this.tp.push(['setContentSection', [route.dataLayer.Portal, route.dataLayer.Channel].join('/')]);
    this.tp.push(['addHandler', 'checkoutComplete', (conversion: IPianoConversion) => {
      this.cXenseTrack(conversion);
      this.setSubscriptionLevelPianoTerms().subscribe(() => {
        this.gtm.pushDataLayerOnBrowser({
          event: 'purchase',
          product: [
            {
              item_id: conversion.termId,
              currency: conversion.chargeCurrency,
              price: conversion.chargeAmount,
            }
          ],
        });
      });
    }]);

    const tags: string[] = [];
    tags.push('page-type-' + route.dataLayer.Seitentyp);
    if (route.type === 'article') {
      tags.push('article-age-' + route.dataLayer.Artikelalter);
      tags.push('article-agency-' + route.dataLayer.Agentur);
      tags.push('article-id-' + route.dataLayer.ArtikelId);
      tags.push('article-model-' + (route.dataLayer.PremiumContent ? 'premium' : 'free'));
      tags.push('article-type-' + route.dataLayer.Artikeltyp);
      if (route.dataLayer.SponsoredContent) {
        tags.push('article-sponsored');
      }
      if (route.dataLayer.Artikeltags) {
        route.dataLayer.Artikeltags.split(',').forEach(tag => tags.push('article-tag-' + tag));
      }
    } else if (route.type === 'channel') {
      tags.push('channel');
    }

    this.tp.push(['setTags', [tags]]);
  }

  doLogout() {
    this.tp.pianoId.logout();
    window.location.reload();
  }

  showLogin() {
    this.tp.pianoId.show({
      displayMode: 'modal',
      screen: 'login',
      // template: 'login',
      // templateVersion: (this.opts.mode === 'sandbox') ? '3' : '5',
      // preview: true,
    });
  }

  showRegister() {
    this.tp.pianoId.show({
      displayMode: 'modal',
      screen: 'register',
    });
  }

  checkPaywallAccess(): Observable<boolean> {
    const rid = this.opts.rid;
    return this.checkAccess(rid).pipe(
      switchMap(access => {
        if (!access) {
          return this.user$.pipe(
            switchMap(user => {
              if (user.valid) {
                return this.checkCredits().pipe(
                  map(credits => credits.redeemed),
                );
              } else {
                return of(false);
              }
            }),
          );
        } else {
          return of(access);
        }
      }),
    );
  }

  setLoading(loading: boolean): void {
    this.loading.next({loading});
  }

  get loading$() {
    return this.loading.asObservable();
  }

  showAdFree() {
    this.tp$.pipe(
      take(1),
    ).subscribe(tp => {
      tp.offer.show({
        offerId: this.opts.adFreeOfferId,
        templateId: this.opts.adFreeTemplateId,
        complete: (...params: any) => {
          this.loading.next({loading: false});
        },
        close: () => {
          this.loading.next({loading: false});
        }
      });
    });
  }

  private init() {
    this.routeService.routeInfo.pipe(
      filter(Boolean),
      tap((route: IRoute) => {
        this.tp.enableGACrossDomainLinking(route.analytics.ga360);
      })
    ).subscribe(route => {
      this.pushRoute(route);
    });

    this.loadAdBlockDetectionScript();

    this.routeService.routeInfo.pipe(
      filter(Boolean),
      skip(1),
    ).subscribe(route => {
      this.tp.experience.execute();
    });

    this.tp.pianoId.init({
      loggedIn: this.onLoggedIn.bind(this),
      loggedOut: this.onLoggedOut.bind(this),
      profileUpdate: this.onProfileUpdate.bind(this),
      registrationSuccess: this.onRegistrationSuccess.bind(this),
    });
    this._tp$.next(this.browserRef.window.tp);

    this.resetPasswordToken();
  }

  private onLoggedIn(response: IPianoLoginResponse) {
    if (response.user.valid) {
      if (response.token) {
        response.user.user_token = response.token;
      }
      this._user$.next(response.user);
      this.setSubscriptionLevelPianoTerms().subscribe(() => {
        this.gtm.pushDataLayerOnBrowser({
          event: 'login',
          loginMethod: 'Piano ID'
        });
      });
    }
  }

  private onProfileUpdate(response: IPianoLoginResponse) {
    const newUser = response.user;
    const currentUser = this._user$.getValue();
    response.user = {...currentUser, ...newUser};
    this._user$.next(response.user);
  }

  private onLoggedOut() {
    this._logout$.next(true);
  }

  /**
   * @see https://docs.piano.io/piano-id-overview/#passwordresetjs
   */
  private resetPasswordToken() {
    if (!this.tp.pianoId.isUserValid()) {
      // If URL has reset_token parameter
      const tokenMatch = this.browserRef.window.location.search.match(/reset_token=([A-Za-z0-9]+)/);
      if (tokenMatch) {
        // Get value of the token
        const token = tokenMatch[1];
        // Present password reset form with the found token
        this.tp.pianoId.show({
          resetPasswordToken: token
        });
      }
    }
  }

  checkAdFree() {
    return this.checkAccess(this.opts.adFreeResourceId);
  }

  checkAdReduced() {
    if (this.portalService.currentPortal() !== 'kurierat') {
      return of(false);
    }
    return this.user$.pipe(
      switchMap(user => {
        if (user.valid) {
          return this.checkAccess(this.opts.adReducedId);
        } else {
          return of(false);
        }
      })
    );
  }

  private checkAccess(rid): Observable<boolean> {
    return combineLatest([
      this._user$.pipe(filter(Boolean)),
      this._tp$.pipe(filter(Boolean)),
      this.activatedRoute.queryParams,
    ]).pipe(
      switchMap(([user, tp, params]) => {
        const accessCheck = (callback: any) => {
          const options = {rid};
          return tp.api.callApi('/access/check', options, callback);
        };
        if (params.utm_campaign === 'RaiffeisenInfinity') {
          return of({access: {granted: true}});
        }
        return bindCallback(accessCheck)();
      }),
      map((response: any) => response.access && response.access.granted),
    );
  }

  public listConversions(): Observable<IUserSubscription[]> {
    return combineLatest([
      this._user$.pipe(filter(Boolean)),
      this._tp$.pipe(filter(Boolean)),
    ]).pipe(
      switchMap(([user, tp]) => {
        const accessCheck = (callback: any) => {
          return tp.api.callApi('/conversion/list', {}, callback);
        };
        return bindCallback<ISubscriptionListResponse>(accessCheck)();
      }),
      filter(Boolean),
      map(response => {
        return response.conversions || [];
      }),
    );
  }

  private checkCredits() {
    return this.user$.pipe(
      take(1),
      switchMap(user => {
        return from(this.tp.credit.check({
          cid: this.opts.cid,
          uid: user.uid,
          itemId: window.location.pathname,
        }));
      }),
    );
  }

  private startCheckout(data: StartCheckoutResponse) {
    this.gtm.pushDataLayerOnBrowser({
      event: 'begin_checkout',
      startCheckoutSubscription: [
        {
          item_id: data.termId,
        }
      ],
    });
  }

  private setSubscriptionLevelPianoTerms(): Observable<string[]> {
    return this.listConversions().pipe(
      take(1),
      map(conversions => conversions
        .filter(sub => sub.term && sub.user_access && sub.user_access.granted)
        .map(sub => sub.term.term_id)),
      tap(termIds => {
        let subscriptionLevel = GTM_SUBSCRIPTION_LEVEL_REGISTERED;
        if (termIds.find(termId => termId === 'TMRZWJPTMBK6')) {
          subscriptionLevel = GTM_SUBSCRIPTION_LEVEL_TRIAL;
        }
        if (termIds.find(termId => termId !== 'TMRZWJPTMBK6') && termIds.length > 0) {
          subscriptionLevel = GTM_SUBSCRIPTION_LEVEL_PAID;
        }
        this.gtm.pushDataLayerOnBrowser({
          SubscriptionLevel: subscriptionLevel
        });
        this.gtm.pushDataLayerOnBrowser({
          PianoTerms: termIds,
        });
      }),
    );
  }

  private onRegistrationSuccess(data: any) {
    this.setSubscriptionLevelPianoTerms().subscribe(() => {
      this.gtm.pushDataLayerOnBrowser({
        event: 'sign_up',
        signUpMethod: data.source,
      });
    });
  }

  private loadAdBlockDetectionScript() {
    this.didomiService.checkVendor$(VendorTypes.piano)
      .pipe(
        filter(Boolean),
        take(1),
      )
      .subscribe(consent => {
        if (consent) {
          document.cookie = '__adblocker=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
          // @ts-ignore
          window.setNptTechAdblockerCookie = (adblocker: boolean) => {
            const d = new Date();
            d.setTime(d.getTime() + 60 * 5 * 1000);
            document.cookie = '__adblocker=' + (adblocker ? 'true' : 'false') + '; expires=' + d.toUTCString() + '; path=/';
          };
          const script = document.createElement('script');
          script.setAttribute('async', '');
          script.setAttribute('src', '//www.npttech.com/advertising.js');
          script.setAttribute('onerror', 'setNptTechAdblockerCookie(true);');
          document.getElementsByTagName('head')[0].appendChild(script);
        }
      });
  }
}
