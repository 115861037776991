/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../no-consent/no-consent.component.ngfactory";
import * as i2 from "../no-consent/no-consent.component";
import * as i3 from "../../../../shared/service/didomi.service";
import * as i4 from "@angular/common";
import * as i5 from "./twitter.component";
import * as i6 from "../../../../shared/service/loadExternalScript.service";
var styles_TwitterComponent = [];
var RenderType_TwitterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TwitterComponent, data: {} });
export { RenderType_TwitterComponent as RenderType_TwitterComponent };
function View_TwitterComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "article-centerExternal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "blockquote", [["class", "twitter-tweet"]], [[1, "data-conversation", 0], [1, "data-cards", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "a", [], [[8, "href", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.conversion; var currVal_1 = _co.includeMedia; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.url; _ck(_v, 3, 0, currVal_2); }); }
function View_TwitterComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i1.View_NoConsentComponent_0, i1.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i2.NoConsentComponent, [i3.DidomiService], { vendor: [0, "vendor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TwitterComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_TwitterComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["noContent", 2]], null, 0, null, View_TwitterComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.showTwitter$)); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TwitterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "twitter", [], null, null, null, View_TwitterComponent_0, RenderType_TwitterComponent)), i0.ɵdid(1, 49152, null, 0, i5.TwitterComponent, [i6.LoadExternalScriptService, i3.DidomiService], null, null)], null, null); }
var TwitterComponentNgFactory = i0.ɵccf("twitter", i5.TwitterComponent, View_TwitterComponent_Host_0, {}, {}, []);
export { TwitterComponentNgFactory as TwitterComponentNgFactory };
