import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {TeasersService} from '@shared/service/teasers.service';
import {ITeaser} from '@model/teaser/payload';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TeaserView} from '@model/teaser/teaserView';
import {ImageType} from '@model/enum/image';
import {LinkBarModel} from '@model/component/linkBar';
import {LonglistModel} from '@model/component/longlist/longlist';
import {BoxColor} from '@model/enum/box';
import {TeaserModel} from '@model/teaser/teaser';
import {IDepartmentBlock} from '@model/channel/departmentBlock/payload';
import {BoxModel} from '@model/component/box';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'departmentblock',
  templateUrl: 'departmentblock.component.html'
})
export class DepartmentBlockComponent implements DynamicComponent<IDepartmentBlock> {

  cardModel: TeaserModel;
  linkbarModel: LinkBarModel;
  longListModel: LonglistModel;
  teasers$: Observable<ITeaser[]>;

  constructor(protected teaserService: TeasersService) {
  }

  initModel(model: IDepartmentBlock): void {
    this.teasers$ = this.teaserService.getCollection(model.collectionName, +model.numberOfTeasers).pipe(
      tap(teasers => {
        this.linkbarModel = new LinkBarModel(model.title, model.titleLink, true);
        const tv = new TeaserView();
        tv.hero = model.teaserView.hero;
        this.cardModel = TeaserModel.fromCardTeaser(teasers[0], tv, ImageType.LS_16_9, new BoxModel({color: BoxColor.standard}));
        this.longListModel = new LonglistModel();
        this.longListModel.items = teasers.slice(1, teasers.length);
        this.longListModel.numberOfTeasers = +model.numberOfTeasers - 1;
        this.longListModel.cta = model.cta || this.longListModel.cta;
        this.longListModel.additionalTeaserStep = model.additionalTeaserStep;
        this.longListModel.color = BoxColor.standard;
        this.longListModel.collectionName = model.collectionName;
      }),
    );
  }
}
