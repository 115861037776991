<article class="article is-{{ articleType }} article-ad-marker" [ngClass]="articleModel.box.cssClass">

  <header class="article-header">

    <div class="article-header-container">

      <div class="article-header-image"
           [ngClass]="articleModel.badge"
           *ngIf="articleModel.imageModel && !articleModel.videoUrl && !articleModel.videoModel">
        <img class="gif" *ngIf="articleModel.imageModel.name.endsWith('.gif'); else image" [src]="articleModel.imageModel.src"
             [alt]="articleModel.imageModel.alt">
        <ng-template #image>
          <div
            class="image"
            *ngIf="articleModel.imageModel"
            [videoTeaser]="articleModel.imageModel.video"
            [ngClass]="[articleModel.imageModel.imgClass]">
            <img
              [src]="articleModel.imageModel.src"
              [srcset]="articleModel.imageModel.srcset"
              data-size="auto"
              [attr.width]="articleModel.imageModel.width"
              [alt]="articleModel.imageModel.alt | stripHtml"
              [title]="articleModel.imageModel.title | stripHtml"/>
          </div>
        </ng-template>

        <accordeon
          *ngIf="articleModel.imageModel.copyright || articleModel.imageModel.description"
          [title]="'info'">
          <div
            class="caption"
            [innerHTML]="articleModel.imageModel.caption"></div>
        </accordeon>
      </div>

      <div
        class="article-header-video"
        *ngIf="articleModel.videoUrl">
        <teaser-video [url]="articleModel.videoUrl"></teaser-video>
      </div>

      <apavideo *ngIf="articleModel.videoModel" [videoModel]="articleModel.videoModel"></apavideo>

    </div>

    <div class="article-header-intro">
      <div class="article-header-intro-left">

        <tag
          [model]="tag"
          *ngFor="let tag of articleModel.tags"></tag>
      </div>
    </div>
    <h1 class="article-header-title"><span>{{articleModel.title}}</span></h1>

    <p class="article-header-leadText">{{articleModel.leadText}}</p>

    <ng-container *ngIf="portalService.currentPortal() === 'filmat'; else meta">
      <div class="article-film-meta">
        <socialbar *ngIf="portalService.currentPortal() !== 'kurierat'"
                   [model]="shareModel"></socialbar>
        <ng-container *ngTemplateOutlet="meta"></ng-container>
      </div>
    </ng-container>

    <socialbar *ngIf="portalService.currentPortal() !== 'kurierat' && portalService.currentPortal() !== 'filmat'"
               [model]="shareModel"></socialbar>

  </header>

  <interaction-bar
    *ngIf="portalService.currentPortal() === 'kurierat'"
    [hasCoral]="hasCoral"
    [model]="shareModel"></interaction-bar>


  <div class="article-paragraphs" [class.plusContent]="articleModel.paid">
    <ng-container *ngFor="let paragraph of paragraphs; trackBy: trackByFn">
      <p *ngIf="paragraph.type === 'outbrain'" class="article-meta">
        <span class="article-meta-agencys" *ngIf="articleModel.agencies">
          (
            <span
              class="article-meta-agency"
              *ngFor="let agency of articleModel.agencies; last as isLast">
              <ng-container *ngIf="agency === 'Agenturen'; then source_agenturen else source_others"></ng-container>
              <ng-template #source_agenturen><a routerLink="/info/nachrichtenagenturen">{{agency}}</a></ng-template>
              <ng-template #source_others>{{agency}}</ng-template>
              <ng-container *ngIf="!isLast">, </ng-container>
            </span>
          )
        </span>
        <ng-container *ngIf="!articleModel.hidePublishedDate">
          <span>|</span>
          <span class="article-meta-date">
            {{ articleModel.publishedDate }}
            <ng-container *ngIf="articleModel.updatedDate">| {{ articleModel.updatedDate }}</ng-container>
          </span>
        </ng-container>
      </p>
      <ng-container dynamicComponent [model]="paragraph"></ng-container>
    </ng-container>
    <ng-container *ngIf="articleModel.paid">
      <app-no-consent *ngIf="pianoConsent$ | async" [vendor]="paidVendor" [text]="paidVendorText"></app-no-consent>
    </ng-container>

    <div *ngIf="!articleModel.paid" id="plus_inline_message"></div>

    <div *ngIf="pianoOffer$ | async" class="kurier-abo-box">
      <h2>Jederzeit und überall top-informiert</h2>
      <p>Uneingeschränkten Zugang zu allen digitalen Inhalten von KURIER sichern: Plus Inhalte, ePaper,
        Online-Magazine und mehr.
        <a href="https://angebote.kurier.at/digitalabo"><strong>Jetzt KURIER Digital-Abo testen.</strong></a></p>
    </div>
  </div>

  <div class="article-adFreeButtonWrapper" *ngIf="showOffer">
    <button (click)="showAdFree()" class="button">Zum KURIER Ad-Free Angebot</button>
  </div>

  <div *ngIf="romyNavigation$ | async as nav" class="article-romyButtonContainer">
    <a *ngFor="let teaser of nav; let i = index" class="button" [routerLink]="teaser.url">
      <svgicon *ngIf="i === 0" icon="angle-left"></svgicon>
      {{i === 0 ? 'Zurück zu:' : 'Weiter zu:'}} {{teaser.title}}
      <svgicon *ngIf="i === 1" icon="angle-right"></svgicon>
    </a>
  </div>

  <footer class="article-footer">

    <div class="article-footer-main">
      <div *ngIf="articleModel.rating" class="rating">
        <p class="rating-stars">
          <span *ngFor="let star of articleModel.rating" [class]="star.cssClass">
          <svgicon [icon]="star.icon"></svgicon>
        </span>
        </p>
        <p>{{articleModel.editorRating}}/5 Sterne</p>
      </div>
      <p *ngIf="!hasOutbain" class="article-meta">
        <span class="article-meta-agencys" *ngIf="articleModel.agencies">
          (
            <span
              class="article-meta-agency"
              *ngFor="let agency of articleModel.agencies; last as isLast">
              <ng-container *ngIf="agency === 'Agenturen'; then source_agenturen else source_others"></ng-container>
              <ng-template #source_agenturen><a routerLink="/info/nachrichtenagenturen">{{agency}}</a></ng-template>
              <ng-template #source_others>{{agency}}</ng-template>
              <ng-container *ngIf="!isLast">, </ng-container>
            </span>
          )
        </span>
        <ng-container *ngIf="!articleModel.hidePublishedDate">
          <span>|</span>
          <span class="article-meta-date">
            {{ articleModel.publishedDate }}
            <ng-container *ngIf="articleModel.updatedDate">| {{ articleModel.updatedDate }}</ng-container>
          </span>
        </ng-container>
      </p>
    </div>

  </footer>

  <ng-content></ng-content>

  <ng-container *ngIf="relatedMovie$ | async as movie">
    <movieteaser [model]="movie"></movieteaser>
  </ng-container>

</article>

<ng-container *ngIf="relatedArticles$ | async as relatedArticles">
  <div *ngIf="relatedArticles.length" class="article-relateds">
    <ng-container *ngFor="let model of relatedArticles" teaser [model]="model"></ng-container>
  </div>
</ng-container>


<app-coral-comments *ngIf="hasCoral"></app-coral-comments>


<ng-template #meta>
  <div class="article-header-meta">
    <p
      *ngIf="articleModel.authors.length"
      class="article-header-meta-authors">
      von
      <ng-container
        *ngFor="let author of articleModel.authors; let isLast=last">
        <a [portalRouterLink]="author.link">
          <picimage *ngIf="portalService.currentPortal() === 'filmat' && author.image"
                    [model]="author.image"></picimage>
          {{author.title}}</a>{{isLast ? '' : ', '}}
      </ng-container>
    </p>
    <p *ngIf="!articleModel.hidePublishedDate" class="article-header-meta-date">
      {{articleModel.publishedDate}}
      <ng-container *ngIf="articleModel.updatedDate">| {{ articleModel.updatedDate }}</ng-container>
    </p>
  </div>
</ng-template>
