import {Component, Input} from '@angular/core';
import {IFootballEvent, IFootballTeam, IFootballVenue} from '@model/content/footballTable/payload';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-football-live-scores',
  templateUrl: './football-live-scores.component.html',
})
export class FootballLiveScoresComponent {
  @Input() liveStandings: {
    isLive: boolean,
    events: IFootballEvent[],
    localTeam: IFootballTeam,
    visitorTeam: IFootballTeam,
    scores: string;
    min: string;
    venue: IFootballVenue
  };
  accordeonOpen = new BehaviorSubject<boolean>(true);
  iconMap = {
    substitution: ['football-angle-up'],
    yellowcard: ['football-card'],
    redcard: ['football-redcard'],
    goal: ['football-ball'],
    penalty: ['verwarnung', 'football-ball'],
    missed_penalty: ['verwarnung'],
    'own-goal': ['football-ball-red'],
    yellowred: ['football-card', 'football-redcard'],
    pen_shootout_goal: ['verwarnung', 'football-ball'],
    pen_shootout_miss: ['pen_shootout_miss'],
  };
}
