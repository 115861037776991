import { OnChanges, SimpleChanges } from '@angular/core';
import { TeasersService } from '@shared/service/teasers.service';
import { BehaviorSubject, merge, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { LinkBarModel } from '@model/component/linkBar';
import { TeaserView } from '@model/teaser/teaserView';
import { BoxModel } from '@model/component/box';
import { GTMTriggerId } from '@model/enum/gtm';
import { TeaserModel } from '@model/teaser/teaser';
import { DeviceDetectionService } from '@shared/service/device-detection.service';
import { PortalService } from '@shared/service/portal.service';
import { ImageType } from '@model/enum/image';
import { ImageModel } from '@model/component/image';
var LongListComponent = /** @class */ (function () {
    function LongListComponent(teaserService, mobileDetectionService, portalService) {
        this.teaserService = teaserService;
        this.mobileDetectionService = mobileDetectionService;
        this.portalService = portalService;
        this.mediaModels = [];
        this.gtmId = GTMTriggerId.more_button;
        this.fetchTeasers$ = new BehaviorSubject(false);
        this.teasers = [];
        this.collectionEmpty = false;
    }
    Object.defineProperty(LongListComponent.prototype, "showMore", {
        get: function () {
            return !this.collectionEmpty && this.teasers.length < +this.model.numberOfTeasers + +this.model.additionalTeaserStep;
        },
        enumerable: true,
        configurable: true
    });
    LongListComponent.prototype.ngOnChanges = function (changes) {
        this.initModel(this.model);
    };
    LongListComponent.prototype.initModel = function (model) {
        this.model = model;
        this.model.cta = this.model.cta || 'Mehr Schlagzeilen';
        if (model.items) {
            this.teasers = model.items;
            this.initFetchTeaser(of(this.teasers));
        }
        else if (this.model.collectionName && +this.model.numberOfTeasers) {
            this.initFetchTeaser(this.getCollection(+this.model.numberOfTeasers));
        }
    };
    LongListComponent.prototype.initFetchTeaser = function (initial) {
        var _this = this;
        this.teasers$ = merge(initial, this.fetchTeasers$.pipe(filter(Boolean), switchMap(function () { return _this.getCollection(+_this.model.additionalTeaserStep); }))).pipe(tap(this.setModels.bind(this)));
    };
    LongListComponent.prototype.getCollection = function (numberOfTeasers) {
        var _this = this;
        return this.teaserService.getCollection(this.model.collectionName, numberOfTeasers).pipe(map(function (teasers) {
            _this.collectionEmpty = teasers.length < numberOfTeasers;
            _this.teasers = _this.teasers.concat(teasers);
            return _this.teasers;
        }));
    };
    LongListComponent.prototype.fetchMoreEntries = function () {
        this.fetchTeasers$.next(true);
        return false;
    };
    LongListComponent.prototype.setModels = function (teasers) {
        var _this = this;
        this.mediaModels = TeaserModel.fromMediaTeasers(teasers, this.model.teaserView || new TeaserView(), this.mobileDetectionService.isMobile, this.model)
            .map(function (m) {
            m.gtmId = _this.model.gtmId;
            return m;
        });
        this.linkBarModel = this.model.title || this.model.image ?
            new LinkBarModel(this.model.title, this.model.titleLink, true, GTMTriggerId.teaser_headline) :
            null;
        if (this.linkBarModel && this.model.image) {
            this.linkBarModel.image = new ImageModel(this.model.image, ImageType.LS_logo);
        }
        var stBox = new BoxModel();
        if (this.mediaModels.length === 0) {
            this.box = stBox;
            return;
        }
        var box = this.mediaModels[0].box;
        if (this.mediaModels.every(function (m) { return m.box.channelId === box.channelId; })) {
            this.box = box;
        }
        else {
            this.box = stBox;
        }
    };
    LongListComponent.prototype.trackMedia = function (index, item) {
        return index;
    };
    return LongListComponent;
}());
export { LongListComponent };
