import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {VideoModel} from '../../../../model/component/video';
import {IYoutube} from '../../../../model/content/youtube/payload';
import {Observable} from 'rxjs';
import {DidomiService} from '@shared/service/didomi.service';
import {tap} from 'rxjs/operators';
import {VendorTypes} from '@model/enum/vendorTypes';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'youtube',
  templateUrl: 'youtube.component.html'
})
export class YouTubeComponent implements DynamicComponent<IYoutube> {

  videoModel: VideoModel;
  showYouTube$: Observable<boolean>;
  vendorType = VendorTypes.youTube;

  constructor(
    private didomiService: DidomiService,
  ) {
  }

  initModel(model: IYoutube): void {
    this.showYouTube$ = this.didomiService.checkVendor$(this.vendorType).pipe(
      tap(consent => {
        if (consent) {
          this.videoModel = new VideoModel('https://www.youtube.com/embed/' + model.data.videoid, model.data.autoplay);
          if (model.data.videoshift) {
            this.videoModel.url += '?start=' + model.data.videoshift;
          }
        }
      })
    );
  }

}
