export enum PositionType {
  top_north = 'top_north',
  top_south = 'top_south',
  top_west = 'top_west',
  top_east = 'top_east',
  center = 'center',
  west = 'west',
  east = 'east',
  north = 'north',
  south = 'south',
  left = 'left'
}
