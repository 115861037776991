<div class="card" [ngClass]="[model.box.cssClass, model.classes]">

  <picimage *ngIf="model.image"
            class="teaser-imageContainer"
            [model]="model.image">
  </picimage>

  <p *ngIf="model.tagLine.length || model.tags.length" class="teaser-tagLine">
    <tag
      [model]="tag"
      *ngFor="let tag of model.tags"></tag>
    <a
      *ngFor="let tag of model.tagLine"
      class="teaser-tagLineLink"
      [portalRouterLink]="tag.portalRoute"
      [gtm]="model.pretitleGtmId" [innerHTML]="tag.title"></a>
  </p>

  <a
    *ngIf="model.title && model.title.length"
    class="teaser-title"
    [portalRouterLink]="model.link">
    <h2><span>{{model.title}}</span></h2>
  </a>

  <a
    *ngIf="model.content"
    class="teaser-text"
    [portalRouterLink]="model.link">
    <p [innerHTML]="model.content | stripHtml:['a']"></p>
  </a>

</div>
