import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {DynamicComponent} from '../../common/dynamicComponent';
import {IAd, IDynamicComponent} from '../../../model/payload';
import {AdModel} from '../../../model/ad/ad-model';
import {AdDimensions, AdSize} from '../../../model/enum/ad';
import {AD_DIMENSIONS} from '../../../model/const/ad';
import {BrowserRef} from '../../../shared/service/browser.ref';
import {IStickySubscriber, StickyService} from '../../../shared/service/sticky.service';
import {STICKY_SUPERBANNER} from '../../../model/const/sticky';
import {DynamicType} from '../../../model/enum/dynamic';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'admediumrectangle',
  templateUrl: 'adMediumRectangle.component.html'
})
export class AdMediumRectangleComponent implements DynamicComponent<IDynamicComponent>, OnChanges, IStickySubscriber {

  @ViewChild('container', {static: true}) elmRef: ElementRef;
  @Input() adId: string;
  @Input() isSticky = true;
  model: AdModel;

  constructor(private browserRef: BrowserRef, private renderer: Renderer2, private stickyService: StickyService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initModel({
      idx: this.adId,
      type: this.isSticky ? DynamicType.adMediumRectangleSticky : DynamicType.adMediumRectangle
    });
  }

  initModel(model: IAd): void {
    const dim = [];
    let size: AdSize;
    if (model.type === DynamicType.adMediumRectangleSticky) {
      this.stickyService.subscribe(STICKY_SUPERBANNER, this);
      dim.push(AD_DIMENSIONS[AdDimensions.dim_300x600]);
      size = AdSize.ad_articleLeft;
    } else {
      dim.push(AD_DIMENSIONS[AdDimensions.dim_300x250]);
      size = model.idx === '0' ? AdSize.ad_mobileBanner_1 : AdSize['ad_mediumRectangle_' + model.idx];
    }
    this.model = new AdModel(
      'adMediumRectangle',
      size,
      dim,
      true,
      () => true,
      false);
  }

  visibilityChanged(isVisible: boolean) {
    if (isVisible) {
      this.renderer.removeClass(this.elmRef.nativeElement, 'isFixed');
    } else {
      this.renderer.addClass(this.elmRef.nativeElement, 'isFixed');
    }

  }

}
