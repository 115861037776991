import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {PortalService} from '@shared/service/portal.service';
import {GTMTriggerId} from '@model/enum/gtm';
import {PortalMenuItem} from '@model/menu/portalMenu';
import {DidomiService} from '@shared/service/didomi.service';

@Component({
  selector: 'portals',
  templateUrl: './portals.component.html'
})

export class PortalsComponent implements OnInit {

  @HostBinding('attr.class') class = 'portals';
  @Input() gtmId: GTMTriggerId;

  nav: PortalMenuItem[];

  constructor(
    private portalService: PortalService,
    private didomiService: DidomiService,
  ) {
  }

  ngOnInit() {
    this.nav = PortalMenuItem.portalNav(this.portalService);
  }

  openConsentOverlay(event: Event) {
    event.preventDefault();
    this.didomiService.showConsentOverlay();
  }
}
