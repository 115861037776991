import {Component, Input, OnInit} from '@angular/core';
import {VideoModel} from '../../../model/component/video';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'videoComp',
  templateUrl: './video.component.html',
})
export class VideoComponent implements OnInit {

  @Input() model: VideoModel;
  safeUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    // TODO is platform brower
    if (this.model.autoplay) {
      this.model.url += '?autoplay=1';
    }
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.model.url);
  }

}
