import {Input, OnInit} from '@angular/core';

export class BaseFormControlComponent {

  private static COUNTER = 0;

  @Input() label: string;
  id: string;

  constructor() {
    this.id = 'input' + BaseFormControlComponent.COUNTER;
    BaseFormControlComponent.COUNTER++;
  }
}
