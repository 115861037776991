/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./overlay.component";
import * as i2 from "@angular/router";
import * as i3 from "../../../shared/service/overlay.service";
import * as i4 from "../../../shared/service/cssClass.service";
import * as i5 from "../../../shared/service/browser.ref";
var styles_OverlayComponent = [];
var RenderType_OverlayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OverlayComponent, data: {} });
export { RenderType_OverlayComponent as RenderType_OverlayComponent };
export function View_OverlayComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { entryPoint: 0 }), (_l()(), i0.ɵeld(1, 16777216, [[1, 3], ["entryPoint", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_OverlayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "overlay", [], null, null, null, View_OverlayComponent_0, RenderType_OverlayComponent)), i0.ɵdid(1, 114688, null, 0, i1.OverlayComponent, [i2.Router, i3.OverlayService, i4.CssClassService, i0.ElementRef, i0.Renderer2, i5.BrowserRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OverlayComponentNgFactory = i0.ɵccf("overlay", i1.OverlayComponent, View_OverlayComponent_Host_0, {}, {}, []);
export { OverlayComponentNgFactory as OverlayComponentNgFactory };
