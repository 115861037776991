import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {TabModel} from '../../../../model/component/tab';
import {GTMTriggerId} from '../../../../model/enum/gtm';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'tabbar',
  templateUrl: './tabBar.component.html',
})
export class TabBarComponent<T> implements OnInit {

  @Input() model: TabModel<T>[];
  @Output() tabChanged = new EventEmitter<TabModel<T>>();
  activeTab: TabModel<T>;
  gtmId = GTMTriggerId.tab_change;

  constructor() {
  }

  ngOnInit() {
    this.selectTab(this.model.filter(m => m.isSelected)[0]);
  }

  selectTab(tab: TabModel<T>) {
    this.activeTab = tab;
    this.tabChanged.emit(this.activeTab);
    return false;
  }

}
