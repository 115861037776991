<ng-container *ngIf="(data$ | async) as data">
  <slider *ngIf="cardModels && cardModels.length || linkCardModels && linkCardModels.length" appAdBlockBlock
          [sliderConfigModel]="sliderConfigModel"
          [ngClass]="boxModel.cssClass">
    <ng-container *ngIf="cardModels">
      <div
        class="slider-item"
        #sliderItem
        *ngFor="let card of cardModels">
        <ng-container teaser [model]="card"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="linkCardModels">
      <div
        class="slider-item"
        #sliderItem
        *ngFor="let card of linkCardModels">
        <linkcard
          [model]="card">
        </linkcard>
      </div>
    </ng-container>
  </slider>
</ng-container>
