<div
  *ngIf="{tabs: tabs$ | async,  model: footBallStageModel$ | async, heading: heading$ | async } as data"
  class="footballStagesWrapper">

  <h2 class="footballStagesWrapper-heading" *ngIf="data.heading">{{data.heading}}</h2>

  <ng-container *ngIf="!isMobile; else slider">
    <tabbar
      *ngIf="data.tabs"
      [model]="data.tabs"
      (tabChanged)="switchTab($event)"></tabbar>
  </ng-container>

  <ng-template #slider>
    <slider
      (currSlideNumber)="switchTab(data.tabs[$event - 1])"
      *ngIf="data.tabs"
      [sliderConfigModel]="sliderConfigModel">
      <div
        #sliderItem
        class="slider-item"
        *ngFor="let item of data.tabs">
        <h2 class="slider-item-title">{{item.name}}</h2>
      </div>
    </slider>
  </ng-template>

  <app-football-stages
    [model]="data.model">
  </app-football-stages>
</div>

<ng-template #loading>
  <div class="loading-indicator">
    <svgicon [icon]="'spinner'"></svgicon>
    <p>Loading</p>
  </div>
</ng-template>
