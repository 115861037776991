import * as tslib_1 from "tslib";
import { ANONYMOUS_USER } from '@model/ITinyPass';
import { PIANO_OPTIONS } from '@shared/service/tokens';
import { StateService } from '@shared/service/state.service';
import { SCRIPT_MAP } from '@model/const/script';
import { BrowserRef } from '@shared/service/browser.ref';
import { LoadExternalScriptService } from '@shared/service/loadExternalScript.service';
import { ScriptId } from '@model/enum/scriptId';
import { BehaviorSubject, bindCallback, combineLatest, from, Observable, of, Subject } from 'rxjs';
import { filter, map, skip, switchMap, take, tap } from 'rxjs/operators';
import { RouteService } from '@shared/service/route.service';
import { GoogleTagManagerService, GTM_SUBSCRIPTION_LEVEL_ANONYMOUS, GTM_SUBSCRIPTION_LEVEL_PAID, GTM_SUBSCRIPTION_LEVEL_REGISTERED, GTM_SUBSCRIPTION_LEVEL_TRIAL } from '@shared/service/gtm.service';
import { ApiService } from '@shared/service/api.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from '@shared/service/cookie.service';
import { PortalService } from '@shared/service/portal.service';
import { ActivatedRoute } from '@angular/router';
import { VendorTypes } from '@model/enum/vendorTypes';
import { DidomiService } from '@shared/service/didomi.service';
import * as i0 from "@angular/core";
import * as i1 from "../browser.ref";
import * as i2 from "../loadExternalScript.service";
import * as i3 from "../route.service";
import * as i4 from "../gtm.service";
import * as i5 from "../api.service";
import * as i6 from "@angular/common/http";
import * as i7 from "../cookie.service";
import * as i8 from "../portal.service";
import * as i9 from "../state.service";
import * as i10 from "@angular/router";
import * as i11 from "../didomi.service";
var PianoService = /** @class */ (function () {
    function PianoService(prodmode, browserRef, loadScript, routeService, gtm, apiService, http, cookieService, portalService, stateService, activatedRoute, didomiService) {
        this.browserRef = browserRef;
        this.loadScript = loadScript;
        this.routeService = routeService;
        this.gtm = gtm;
        this.apiService = apiService;
        this.http = http;
        this.cookieService = cookieService;
        this.portalService = portalService;
        this.activatedRoute = activatedRoute;
        this.didomiService = didomiService;
        this.loading = new BehaviorSubject({ loading: false });
        this._logout$ = new Subject();
        this.opts = stateService.get(PIANO_OPTIONS);
        SCRIPT_MAP[ScriptId.tinypass] = "https://" + this.opts.mode + ".tinypass.com/xbuilder/experience/load?aid=" + this.opts.aid;
        this._tp$ = new BehaviorSubject(null);
        this._user$ = new BehaviorSubject(ANONYMOUS_USER);
        // https://docs.piano.io/piano-javascript/
        this.browserRef.window.tp = [
            ['setAid', this.opts.aid],
            ['setDebug', !prodmode],
            ['setSandbox', this.opts.mode === 'sandbox'],
            ['setUsePianoIdUserProvider', true],
            ['setUseTinypassAccounts', false],
            ['init', this.init.bind(this)],
        ];
        this.gtm.pushDataLayerOnBrowser({
            SubscriptionLevel: GTM_SUBSCRIPTION_LEVEL_ANONYMOUS
        });
    }
    Object.defineProperty(PianoService.prototype, "tp$", {
        get: function () {
            return this._tp$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PianoService.prototype, "user$", {
        get: function () {
            return this._user$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PianoService.prototype, "tp", {
        get: function () {
            return this.browserRef.window.tp;
        },
        enumerable: true,
        configurable: true
    });
    PianoService.prototype.load = function () {
        this.loadScript.loadScript(ScriptId.tinypass);
    };
    PianoService.prototype.cXenseTrack = function (conversion) {
        if (this.portalService.currentPortal() === 'kurierat') {
            // @ts-ignore
            var cX = window.cX;
            if (cX) {
                cX.callQueue.push(['setEventAttributes', {
                        origin: 'brj-conversion',
                        persistedQueryId: '157b92afc5307b6c70480a8cc708547514fe43cb'
                    }]);
                cX.callQueue.push(['sendEvent', conversion.rid + '']);
            }
        }
    };
    PianoService.prototype.pushRoute = function (route) {
        var _this = this;
        this.tp.push(['setContentAuthor', route.dataLayer.Autor]);
        var contentCreated = route.dataLayer.ErscheinungsZeitpunkt ? new Date(route.dataLayer.ErscheinungsZeitpunkt).toUTCString() : null;
        this.tp.push(['addHandler', 'startCheckout', this.startCheckout.bind(this)]);
        this.tp.push(['setContentCreated', contentCreated]);
        this.tp.push(['setContentIsNative', route.type === 'article' ? route.dataLayer.SponsoredContent : null]);
        this.tp.push(['setContentSection', [route.dataLayer.Portal, route.dataLayer.Channel].join('/')]);
        this.tp.push(['addHandler', 'checkoutComplete', function (conversion) {
                _this.cXenseTrack(conversion);
                _this.setSubscriptionLevelPianoTerms().subscribe(function () {
                    _this.gtm.pushDataLayerOnBrowser({
                        event: 'purchase',
                        product: [
                            {
                                item_id: conversion.termId,
                                currency: conversion.chargeCurrency,
                                price: conversion.chargeAmount,
                            }
                        ],
                    });
                });
            }]);
        var tags = [];
        tags.push('page-type-' + route.dataLayer.Seitentyp);
        if (route.type === 'article') {
            tags.push('article-age-' + route.dataLayer.Artikelalter);
            tags.push('article-agency-' + route.dataLayer.Agentur);
            tags.push('article-id-' + route.dataLayer.ArtikelId);
            tags.push('article-model-' + (route.dataLayer.PremiumContent ? 'premium' : 'free'));
            tags.push('article-type-' + route.dataLayer.Artikeltyp);
            if (route.dataLayer.SponsoredContent) {
                tags.push('article-sponsored');
            }
            if (route.dataLayer.Artikeltags) {
                route.dataLayer.Artikeltags.split(',').forEach(function (tag) { return tags.push('article-tag-' + tag); });
            }
        }
        else if (route.type === 'channel') {
            tags.push('channel');
        }
        this.tp.push(['setTags', [tags]]);
    };
    PianoService.prototype.doLogout = function () {
        this.tp.pianoId.logout();
        window.location.reload();
    };
    PianoService.prototype.showLogin = function () {
        this.tp.pianoId.show({
            displayMode: 'modal',
            screen: 'login',
        });
    };
    PianoService.prototype.showRegister = function () {
        this.tp.pianoId.show({
            displayMode: 'modal',
            screen: 'register',
        });
    };
    PianoService.prototype.checkPaywallAccess = function () {
        var _this = this;
        var rid = this.opts.rid;
        return this.checkAccess(rid).pipe(switchMap(function (access) {
            if (!access) {
                return _this.user$.pipe(switchMap(function (user) {
                    if (user.valid) {
                        return _this.checkCredits().pipe(map(function (credits) { return credits.redeemed; }));
                    }
                    else {
                        return of(false);
                    }
                }));
            }
            else {
                return of(access);
            }
        }));
    };
    PianoService.prototype.setLoading = function (loading) {
        this.loading.next({ loading: loading });
    };
    Object.defineProperty(PianoService.prototype, "loading$", {
        get: function () {
            return this.loading.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    PianoService.prototype.showAdFree = function () {
        var _this = this;
        this.tp$.pipe(take(1)).subscribe(function (tp) {
            tp.offer.show({
                offerId: _this.opts.adFreeOfferId,
                templateId: _this.opts.adFreeTemplateId,
                complete: function () {
                    var params = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        params[_i] = arguments[_i];
                    }
                    _this.loading.next({ loading: false });
                },
                close: function () {
                    _this.loading.next({ loading: false });
                }
            });
        });
    };
    PianoService.prototype.init = function () {
        var _this = this;
        this.routeService.routeInfo.pipe(filter(Boolean), tap(function (route) {
            _this.tp.enableGACrossDomainLinking(route.analytics.ga360);
        })).subscribe(function (route) {
            _this.pushRoute(route);
        });
        this.loadAdBlockDetectionScript();
        this.routeService.routeInfo.pipe(filter(Boolean), skip(1)).subscribe(function (route) {
            _this.tp.experience.execute();
        });
        this.tp.pianoId.init({
            loggedIn: this.onLoggedIn.bind(this),
            loggedOut: this.onLoggedOut.bind(this),
            profileUpdate: this.onProfileUpdate.bind(this),
            registrationSuccess: this.onRegistrationSuccess.bind(this),
        });
        this._tp$.next(this.browserRef.window.tp);
        this.resetPasswordToken();
    };
    PianoService.prototype.onLoggedIn = function (response) {
        var _this = this;
        if (response.user.valid) {
            if (response.token) {
                response.user.user_token = response.token;
            }
            this._user$.next(response.user);
            this.setSubscriptionLevelPianoTerms().subscribe(function () {
                _this.gtm.pushDataLayerOnBrowser({
                    event: 'login',
                    loginMethod: 'Piano ID'
                });
            });
        }
    };
    PianoService.prototype.onProfileUpdate = function (response) {
        var newUser = response.user;
        var currentUser = this._user$.getValue();
        response.user = tslib_1.__assign({}, currentUser, newUser);
        this._user$.next(response.user);
    };
    PianoService.prototype.onLoggedOut = function () {
        this._logout$.next(true);
    };
    /**
     * @see https://docs.piano.io/piano-id-overview/#passwordresetjs
     */
    PianoService.prototype.resetPasswordToken = function () {
        if (!this.tp.pianoId.isUserValid()) {
            // If URL has reset_token parameter
            var tokenMatch = this.browserRef.window.location.search.match(/reset_token=([A-Za-z0-9]+)/);
            if (tokenMatch) {
                // Get value of the token
                var token = tokenMatch[1];
                // Present password reset form with the found token
                this.tp.pianoId.show({
                    resetPasswordToken: token
                });
            }
        }
    };
    PianoService.prototype.checkAdFree = function () {
        return this.checkAccess(this.opts.adFreeResourceId);
    };
    PianoService.prototype.checkAdReduced = function () {
        var _this = this;
        if (this.portalService.currentPortal() !== 'kurierat') {
            return of(false);
        }
        return this.user$.pipe(switchMap(function (user) {
            if (user.valid) {
                return _this.checkAccess(_this.opts.adReducedId);
            }
            else {
                return of(false);
            }
        }));
    };
    PianoService.prototype.checkAccess = function (rid) {
        return combineLatest([
            this._user$.pipe(filter(Boolean)),
            this._tp$.pipe(filter(Boolean)),
            this.activatedRoute.queryParams,
        ]).pipe(switchMap(function (_a) {
            var _b = tslib_1.__read(_a, 3), user = _b[0], tp = _b[1], params = _b[2];
            var accessCheck = function (callback) {
                var options = { rid: rid };
                return tp.api.callApi('/access/check', options, callback);
            };
            if (params.utm_campaign === 'RaiffeisenInfinity') {
                return of({ access: { granted: true } });
            }
            return bindCallback(accessCheck)();
        }), map(function (response) { return response.access && response.access.granted; }));
    };
    PianoService.prototype.listConversions = function () {
        return combineLatest([
            this._user$.pipe(filter(Boolean)),
            this._tp$.pipe(filter(Boolean)),
        ]).pipe(switchMap(function (_a) {
            var _b = tslib_1.__read(_a, 2), user = _b[0], tp = _b[1];
            var accessCheck = function (callback) {
                return tp.api.callApi('/conversion/list', {}, callback);
            };
            return bindCallback(accessCheck)();
        }), filter(Boolean), map(function (response) {
            return response.conversions || [];
        }));
    };
    PianoService.prototype.checkCredits = function () {
        var _this = this;
        return this.user$.pipe(take(1), switchMap(function (user) {
            return from(_this.tp.credit.check({
                cid: _this.opts.cid,
                uid: user.uid,
                itemId: window.location.pathname,
            }));
        }));
    };
    PianoService.prototype.startCheckout = function (data) {
        this.gtm.pushDataLayerOnBrowser({
            event: 'begin_checkout',
            startCheckoutSubscription: [
                {
                    item_id: data.termId,
                }
            ],
        });
    };
    PianoService.prototype.setSubscriptionLevelPianoTerms = function () {
        var _this = this;
        return this.listConversions().pipe(take(1), map(function (conversions) { return conversions
            .filter(function (sub) { return sub.term && sub.user_access && sub.user_access.granted; })
            .map(function (sub) { return sub.term.term_id; }); }), tap(function (termIds) {
            var subscriptionLevel = GTM_SUBSCRIPTION_LEVEL_REGISTERED;
            if (termIds.find(function (termId) { return termId === 'TMRZWJPTMBK6'; })) {
                subscriptionLevel = GTM_SUBSCRIPTION_LEVEL_TRIAL;
            }
            if (termIds.find(function (termId) { return termId !== 'TMRZWJPTMBK6'; }) && termIds.length > 0) {
                subscriptionLevel = GTM_SUBSCRIPTION_LEVEL_PAID;
            }
            _this.gtm.pushDataLayerOnBrowser({
                SubscriptionLevel: subscriptionLevel
            });
            _this.gtm.pushDataLayerOnBrowser({
                PianoTerms: termIds,
            });
        }));
    };
    PianoService.prototype.onRegistrationSuccess = function (data) {
        var _this = this;
        this.setSubscriptionLevelPianoTerms().subscribe(function () {
            _this.gtm.pushDataLayerOnBrowser({
                event: 'sign_up',
                signUpMethod: data.source,
            });
        });
    };
    PianoService.prototype.loadAdBlockDetectionScript = function () {
        this.didomiService.checkVendor$(VendorTypes.piano)
            .pipe(filter(Boolean), take(1))
            .subscribe(function (consent) {
            if (consent) {
                document.cookie = '__adblocker=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
                // @ts-ignore
                window.setNptTechAdblockerCookie = function (adblocker) {
                    var d = new Date();
                    d.setTime(d.getTime() + 60 * 5 * 1000);
                    document.cookie = '__adblocker=' + (adblocker ? 'true' : 'false') + '; expires=' + d.toUTCString() + '; path=/';
                };
                var script = document.createElement('script');
                script.setAttribute('async', '');
                script.setAttribute('src', '//www.npttech.com/advertising.js');
                script.setAttribute('onerror', 'setNptTechAdblockerCookie(true);');
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    };
    PianoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PianoService_Factory() { return new PianoService(i0.ɵɵinject("prod_mode"), i0.ɵɵinject(i1.BrowserRef), i0.ɵɵinject(i2.LoadExternalScriptService), i0.ɵɵinject(i3.RouteService), i0.ɵɵinject(i4.GoogleTagManagerService), i0.ɵɵinject(i5.ApiService), i0.ɵɵinject(i6.HttpClient), i0.ɵɵinject(i7.CookieService), i0.ɵɵinject(i8.PortalService), i0.ɵɵinject(i9.StateService), i0.ɵɵinject(i10.ActivatedRoute), i0.ɵɵinject(i11.DidomiService)); }, token: PianoService, providedIn: "root" });
    return PianoService;
}());
export { PianoService };
