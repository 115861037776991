import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {DeviceDetectionService} from '@shared/service/device-detection.service';
import {SHARE_CLASS_MAP} from '@model/const/share';
import {isPlatformBrowser} from '@angular/common';
import {HeaderContainerModel} from '../headerContainer.component';
import {GTMTriggerId} from '@model/enum/gtm';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'navBar',
  templateUrl: './navBar.component.html',
})

export class NavBarComponent implements AfterViewChecked {

  @Input() model: HeaderContainerModel;
  @Input() isKurier: boolean;

  @ViewChild('navBar', { static: true }) navBar: ElementRef;
  @ViewChildren('navBarItem') navBarItems: QueryList<ElementRef>;
  @ViewChildren('menuItem') menuItems: QueryList<ElementRef>;
  @ViewChild('moreItem', { static: false }) moreItem: ElementRef;

  @Output() search = new EventEmitter();

  gtmId = GTMTriggerId.navbar;

  isTouch: boolean;
  shareMap = SHARE_CLASS_MAP;

  private isInitialized = false;

  constructor(@Inject(PLATFORM_ID) private platformId: string,
              private renderer: Renderer2,
              private mobileDec: DeviceDetectionService,
  ) {
    this.isTouch = this.mobileDec.isTouch;
  }

  ngAfterViewChecked(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (!this.isTouch && !this.isInitialized && this.navBarItems && this.navBarItems.length > 0) {
      this.isInitialized = true;
      const maxW = this.navBar.nativeElement.offsetWidth - this.moreItem.nativeElement.offsetWidth;
      const startX = this.navBar.nativeElement.getBoundingClientRect().left;
      let startI = -1;
      this.navBarItems.forEach((elm, i) => {
        if (startI !== -1) {
          this.renderer.addClass(elm.nativeElement, 'isHidden');
          return;
        }
        const rect = elm.nativeElement.getBoundingClientRect();
        const elmRightX = rect.left + rect.width - startX;
        if (elmRightX > maxW) {
          startI = i;
          this.renderer.addClass(elm.nativeElement, 'isHidden');
        }
      });
      if (startI !== -1) {
        this.menuItems.forEach((elm, i) => {
          if (i >= startI) {
            this.renderer.addClass(elm.nativeElement, 'isVisible');
          }
        });
      }
    }

    this.renderer.removeClass(this.navBar.nativeElement, 'noOpacity');
  }

  toggleSearch() {
    this.search.emit();
  }
}
