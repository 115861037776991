export enum BoxStyle {
  special = 'special',
  classic = 'classic',
  poweredby = 'pwdBy',
  sponsored = 'sponsored'
}

export enum BoxColor {
  channel = 'channel',
  standard = 'standard',
  special = 'special',
  schauTV = 'schauTV'
}
