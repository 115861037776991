import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {ImageType} from '../../../../model/enum/image';
import {PortalRoute} from '../../../../model/teaser/portalRoute';
import {ImageModel} from '../../../../model/component/image';
import {LinkBarModel} from '../../../../model/component/linkBar';
import {BoxModel} from '../../../../model/component/box';
import {ILinkTeaser, ILinkTeaser2, ILinkTeaserParagraph} from '../../../../model/channel/linkTeaser/payload';
import {LinkCardModel} from '../../../../model/component/linkCard';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'linkTeaser',
  templateUrl: 'linkTeaser.component.html'
})
export class LinkTeaserComponent implements DynamicComponent<ILinkTeaser | ILinkTeaserParagraph> {

  linkCardModel: LinkCardModel;
  imgClass = '';

  constructor() {
  }

  initModel(model: ILinkTeaser | ILinkTeaserParagraph | ILinkTeaser2): void {
    let data;
    if (!('data' in model)) {
      data = model;
    } else {
      data = model.data;
    }
    this.linkCardModel = new LinkCardModel();
    this.linkCardModel.box = new BoxModel(data);

    if (data.teaserView && data.teaserView.img && data.teaserImage) {
      this.linkCardModel.image = new ImageModel(data.teaserImage, ImageType.VARIABLE, null, false, false, 'teaser-image');
    } else if (data.teaserImage) {
      this.linkCardModel.image = new ImageModel(data.teaserImage, ImageType.VARIABLE, null, false, false, 'teaser-image');
    }

    if (data.teaserView && data.teaserView.title && data.teaserTitle) {
      this.imgClass = 'withText';
      this.linkCardModel.title = data.teaserTitle;
    }

    if (data.teaserView && data.teaserView.teaser_text && data.teaserText) {
      this.imgClass = 'withText';
      this.linkCardModel.content = data.teaserText;
    } else if (data.teaserText) {
      this.linkCardModel.content = data.teaserText;
    }

    if (data.teaserView && data.teaserView.hero) {
      this.linkCardModel.classNames.push('hero');
    }

    this.linkCardModel.link = PortalRoute.fromUri(data.teaserLink);
    this.linkCardModel.pretitle = data.preTitle;


    this.linkCardModel.linkbar = data.teaserView && data.teaserView.title && data.title ?
      new LinkBarModel(data.title, data.sponsoredLink ? null : this.linkCardModel.link, true) : null;

    if (this.linkCardModel.linkbar && data.sponsoredLink && data.sponsoredImage) {
      this.linkCardModel.linkbar.logo = new ImageModel(data.sponsoredImage, ImageType.LS_logo);
      this.linkCardModel.linkbar.link = PortalRoute.fromUri(data.sponsoredLink);
    }
  }
}
