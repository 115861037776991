import { TeasersService } from '../../../../shared/service/teasers.service';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { TabModel } from '../../../../model/component/tab';
import { TabbedListTabModel } from '../../../../model/channel/tabbedList/tab';
import { GTMTriggerId } from '../../../../model/enum/gtm';
import { TeaserModel } from '../../../../model/teaser/teaser';
import { DeviceDetectionService } from '../../../../shared/service/device-detection.service';
var TabbedListComponent = /** @class */ (function () {
    function TabbedListComponent(teasers, mobileDetectionService) {
        this.teasers = teasers;
        this.mobileDetectionService = mobileDetectionService;
        this.mediaModels = [];
        this.gtmId = GTMTriggerId.more_button;
        this.fetchTeasers$ = new BehaviorSubject(null);
    }
    Object.defineProperty(TabbedListComponent.prototype, "hasMoreEntries", {
        get: function () {
            return !this.activeTab.info.collectionEmpty &&
                this.mediaModel.length < (this.activeTab.info.numberOfTeasers + this.activeTab.info.additionalTeaserStep);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabbedListComponent.prototype, "mediaModel", {
        get: function () {
            return this.mediaModels[this.activeTab.id];
        },
        enumerable: true,
        configurable: true
    });
    TabbedListComponent.prototype.initModel = function (model) {
        var _this = this;
        this.model = model;
        model.additionalTeaserStep = model.additionalTeaserStep || 7;
        model.cta = model.cta || 'Mehr Schlagzeilen';
        this.tabModels = model.tabs.map(function (tab, i) {
            _this.mediaModels[i] = [];
            return new TabModel(i, tab.title, new TabbedListTabModel(tab.collectionName, +model.numberOfTeasers || +tab.numberOfTeasers, model.additionalTeaserStep), i === 0);
        });
        this.teasers$ = this.fetchTeasers$.pipe(filter(Boolean), switchMap(function (next) {
            return _this.teasers.getCollection(_this.activeTab.info.collectionName, next.numTeasers).pipe(tap(function (teaser) {
                _this.activeTab.info.collectionEmpty = teaser.length < next.numTeasers;
                var fromMediaTeasers = TeaserModel.fromMediaTeasers(teaser, model.teaserView, _this.mobileDetectionService.isMobile, model);
                _this.mediaModels[_this.activeTab.id] = _this.mediaModel.concat(fromMediaTeasers);
            }));
        }));
    };
    TabbedListComponent.prototype.fetchMoreEntries = function () {
        this.fetchTeasers$.next({ numTeasers: this.activeTab.info.additionalTeaserStep });
        return false;
    };
    TabbedListComponent.prototype.switchTab = function (tab) {
        this.activeTab = tab;
        var m = this.mediaModels[tab.id];
        if (m.length === 0) {
            this.fetchTeasers$.next({ numTeasers: this.activeTab.info.numberOfTeasers });
        }
        return false;
    };
    return TabbedListComponent;
}());
export { TabbedListComponent };
