<div class="theater">
  <div class="theater-header">
    <h1 class="theater-title">{{model.name}}</h1>
    <div
      class="theater-tags"
      *ngIf="model.tags?.length">
      <span
        class="theater-tag"
        *ngFor="let tag of model.tags">{{tag}}
      </span>
    </div>

    <div class="theater-intro">
      <div class="theater-table">
        <div *ngIf="model.address" class="theater-table-row">
          <p><span class="theater-table-key">Adresse:</span> <span class="theater-table-value">{{model.address}}</span>
          </p>
        </div>
        <div *ngIf="model.phone" class="theater-table-row">
          <p><span class="theater-table-key">Tel.:</span> <a class="theater-table-value"
                                                             href="tel:{{model.phone}}">{{model.phone}}</a></p>
        </div>
        <div *ngIf="model.website" class="theater-table-row">
          <p><span class="theater-table-key">Web:</span> <a class="theater-table-value" [href]="model.website"
                                                            target="_blank">{{model.website}}</a></p>
        </div>
        <div *ngIf="model.email" class="theater-table-row">
          <p><span class="theater-table-key">E-mail:</span> <a class="theater-table-value"
                                                               href="mailto:{{model.email}}">{{model.email}}</a></p>
        </div>
      </div>
      <div *ngIf="model.image" class="theater-intro-image">
        <picimage *ngIf="model.image" class="theater-poster" [model]="model.image"></picimage>
      </div>
    </div>
  </div>


  <div accordeonCtrl>
    <accordeon
      *ngIf="model.description"
      [title]="'Über das ' + model.name"
      [isOpen]="true">
      <p>{{model.description}}</p>
      <ng-container dynamicComponent *ngFor="let paragraph of model.paragraphs" [model]="paragraph"></ng-container>
    </accordeon>
    <accordeon *ngIf="model.gmapsUrl" [title]="'Standort'">
      <iframe width="100%" height="300" frameborder="0" style="border:0" [attr.src]="model.gmapsUrl"></iframe>
    </accordeon>
    <accordeon [title]="'Kinoprogramm'" [isOpen]="true">
      <ng-container dynamicComponent [model]="model.screenings"></ng-container>
      <!--<div class="theater-programm-header">-->
      <!--<div class="select">-->
      <!--<select>-->
      <!--<option>test1</option>-->
      <!--<option>test2</option>-->
      <!--<option>test3</option>-->
      <!--</select>-->
      <!--<svgicon [icon]="'angle-down'"></svgicon>-->
      <!--</div>-->
      <!--<div class="select">-->
      <!--<select>-->
      <!--<option>test1</option>-->
      <!--<option>test2</option>-->
      <!--<option>test3</option>-->
      <!--</select>-->
      <!--<svgicon [icon]="'angle-down'"></svgicon>-->
      <!--</div>-->
      <!--<p class="checkbox">-->
      <!--<input type="checkbox" value="yes" id="test">-->
      <!--<label for="test">-->
      <!--3D-->
      <!--<span class="checkbox-icon">-->
      <!--<svgicon [icon]="'check'"></svgicon>-->
      <!--</span>-->
      <!--</label>-->
      <!--</p>-->
      <!--<p class="checkbox">-->
      <!--<input type="checkbox" value="yes" id="test2">-->
      <!--<label for="test2">-->
      <!--OV-->
      <!--<span class="checkbox-icon">-->
      <!--<svgicon [icon]="'check'"></svgicon>-->
      <!--</span>-->
      <!--</label>-->
      <!--</p>-->
      <!--</div>-->

      <!--<div class="theater-programm-list">-->

      <!--<div *ngFor="let i of [1, 2, 3, 4, 5]" class="theater-table">-->
      <!--<div class="theater-table-row title">-->
      <!--<p>Titel {{i}}</p>-->
      <!--</div>-->
      <!--<div class="theater-table-row">-->
      <!--<p>Conent {{i}}</p>-->
      <!--</div>-->
      <!--</div>-->

      <!--</div>-->

    </accordeon>
    <outbrain *ngIf="outbrainModel" [model]="outbrainModel"></outbrain>
  </div>
</div>
