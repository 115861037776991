<slider
  (currSlideNumber)="loadAd($event)"
  *ngIf="sliderItems$ | async as sliderItems"
  [sliderConfigModel]="sliderConfigModel">
  <div
    class="slider-item"
    #sliderItem
    *ngFor="let item of sliderItems, let i = index">
    <linkcard *ngIf="item !== 'adSlide'" [model]="item"></linkcard>
    <div *ngIf="item === 'adSlide'" class="slider-item-ad">
      <admediumrectangle
        *ngIf="isActiveSlide(i)"
        [adId]="'slider'"
        [isSticky]="false"></admediumrectangle>
    </div>
  </div>
</slider>
