export class TimeAgoUtil {

  static timeDifferenceUpdatedDate(date: string) {

    if (!date) {
      return null;
    }

    const current = Date.now();
    const previous = Date.parse(date);
    const currentD = new Date(current);
    const previousD = new Date(previous);

    if (currentD.getMonth() === previousD.getMonth() && currentD.getFullYear() === previousD.getFullYear()) {
      if (currentD.getDate() === previousD.getDate()) {
        let time = 'Aktualisiert um ' + this.getTime(date);
        const minuitesDifference = Math.round(((+currentD) - (+previousD)) / 60000);
        if (minuitesDifference < 60) {
          time = 'Aktualisiert vor ' + minuitesDifference + (minuitesDifference === 1 ? ' Minute' : ' Minuten');
        }
        return time;
      } else {
        return 'Aktualisiert am ' + this.getDate(date) + ', ' + this.getTime(date);
      }
    } else {
      return 'Aktualisiert am ' + this.getDate(date) + ', ' + this.getTime(date);
    }
  }

  static timeDifferencePublishedDate(date: string) {
    if (!date) {
      return null;
    }
    const current = Date.now();
    const previous = Date.parse(date);
    const currentD = new Date(current);
    const previousD = new Date(previous);

    if (currentD.getMonth() === previousD.getMonth() && currentD.getFullYear() === previousD.getFullYear()) {

      if (currentD.getDate() === previousD.getDate()) {
        let time = this.getTime(date);
        const minuitesDifference = Math.round(((+currentD) - (+previousD)) / 60000);
        if (minuitesDifference < 60) {
          time = 'vor ' + minuitesDifference + (minuitesDifference === 1 ? ' Minute' : ' Minuten');
        }
        return 'Heute, ' + time;
      } else {
        return this.getDate(date) + ', ' + this.getTime(date);
      }
    } else {
      return this.getDate(date) + ', ' + this.getTime(date);
    }
  }

  static dateDifference(date: string) {

    if (!date) {
      return null;
    }

    const current = Date.now();
    const previous = Date.parse(date);
    const currentD = new Date(current);
    const previousD = new Date(previous);

    if (currentD.getMonth() === previousD.getMonth() && currentD.getFullYear() >= previousD.getFullYear()) {
      if (currentD.getDate() === previousD.getDate()) {
        return 'Heute, ' + this.getDate(date);
      } else {
        return this.getDate(date);
      }
    } else {
      return this.getDate(date);
    }
  }

  static getDate(value: string): string {
    if (value === null || value === 'null') {
      return '';
    } else {
      const date = new Date(value);
      const newDate = date.toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});
      const splittedDate = newDate.split('-') || [];
      if (splittedDate.length > 1) {
        return splittedDate[2] + '.' + splittedDate[1] + '.' + splittedDate[0];
      } else {
        return newDate;
      }
    }
  }

  static getFilmDate(value: string) {
    const date = new Date(value);
    return date.toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit'});
  }

  static getYYYYmmdd(d: Date) {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  static getddmmYYYY(d: Date) {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [day, month, year].join('.');
  }

  static getWeekday(dateNum: number): string {
    const date = new Date();
    date.setDate(dateNum);
    return date.toLocaleDateString('de-DE', {weekday: 'short'});
  }

  static getTime(dateTime: string): string {
    return new Date(dateTime).toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'});
  }

  static topTopicsTime(date: string): string {
    const msPerMinute = 60 * 1000;
    const current = Date.now();
    const previous = Date.parse(date);
    const currentD = new Date(current);
    const previousD = new Date(previous);
    const timezoneDiff = (currentD.getTimezoneOffset() - previousD.getTimezoneOffset()) * msPerMinute;

    const elapsed = current - previous + timezoneDiff;

    if (elapsed < 60 * 60 * 1000) {
      let minutes = Math.round(elapsed / msPerMinute);
      if (minutes <= 0) {
        minutes = 1;
      }
      return `vor ${minutes} min`;
    }

    if (currentD.getMonth() === previousD.getMonth() && currentD.getFullYear() >= previousD.getFullYear()) {
      if (currentD.getDate() === previousD.getDate()) {
        return 'Heute, ' + this.getTime(date);
      } else {
        return this.getDate(date) + ', ' + this.getTime(date);
      }
    } else {
      return this.getDate(date) + ', ' + this.getTime(date);
    }
  }
}
