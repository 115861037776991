<section class="topTopics" [class.numbered]="numbered" *ngIf="teasers$ | async">
  <linkbar *ngIf="linkBar.text" [model]="linkBar"></linkbar>
  <ng-container *ngIf="!datesTemplate; else date">
    <ng-container teaser
                  *ngFor="let teaser of teasers"
                  [model]="teaser"></ng-container>
  </ng-container>
</section>

<ng-template #date>
  <div
    *ngFor="let teaser of teasers; let i = index"
    class="topTopics-item topTopics-item-date">
    <span class="topTopics-date">{{ time(i) }}</span>
    <a [portalRouterLink]="teaser.link">
      <h2>{{teaser.title}}</h2>
    </a>
  </div>
</ng-template>
