<div *ngIf="showRiddle$ | async; else noContent"
     class="riddle_target"
     [attr.data-rid-id]="riddleId"
     data-fg="#D82A2A"
     data-bg="#FFFFFF"
     data-auto-scroll="true">
  <iframe style="width:100%;border:1px solid #cfcfcf;" [attr.src]="riddleUrl"></iframe>
</div>

<ng-template #noContent>
  <app-no-consent [vendor]="vendorType"></app-no-consent>
</ng-template>
