import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {BaseFormControlComponent} from '../../baseFormControl.component';

@Component({
  selector: 'forminput',
  templateUrl: './input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InputComponent extends BaseFormControlComponent implements OnInit {

  @Input() type: string;
  @Input() value: string;
  @Input() placeholder: string;
  @Output() valueChange = new EventEmitter<string>();
  @Output() inputChanged = new EventEmitter<string>();

  ngOnInit() {
    if (!this.placeholder) {
      this.placeholder = this.label;
    }
  }

  onChange($event) {
    this.valueChange.emit(($event.target as HTMLInputElement).value);
  }

  onKeyUp($event: KeyboardEvent) {
    this.valueChange.emit(($event.target as HTMLInputElement).value);
    this.inputChanged.emit(($event.target as HTMLInputElement).value);
  }

}
