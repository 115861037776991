import { PortalRoute } from '@model/teaser/portalRoute';
var TextLinkComponent = /** @class */ (function () {
    function TextLinkComponent() {
    }
    TextLinkComponent.prototype.initModel = function (model) {
        this.model = model;
    };
    Object.defineProperty(TextLinkComponent.prototype, "link", {
        get: function () {
            return PortalRoute.fromUri(this.model.url);
        },
        enumerable: true,
        configurable: true
    });
    return TextLinkComponent;
}());
export { TextLinkComponent };
