import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {OverlayService} from '@shared/service/overlay.service';
import {VendorTypes} from '@model/enum/vendorTypes';
import {DidomiService} from '@shared/service/didomi.service';
import {VENDOR_NAME_MAP, VENDOR_PARAGRAPH_MAP} from '@model/const/didomi';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-no-consent-piano',
  templateUrl: './no-consent-piano.component.html',
})
export class NoConsentPianoComponent {

  constructor(
    private overlayService: OverlayService,
    private didomiService: DidomiService,
  ) {
  }

  get embed(): string {
    return VENDOR_PARAGRAPH_MAP[VendorTypes.piano];
  }

  get vendorName(): string {
    return VENDOR_NAME_MAP[VendorTypes.piano];
  }

  close() {
    this.overlayService.clearComponent();
  }

  openConsents() {
    this.didomiService.showConsentOverlay();
  }
}
