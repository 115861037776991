<ng-container *ngIf="data$ | async">
  <slider [sliderConfigModel]="sliderConfigModel">
    <div
        class="slider-item"
        #sliderItem
        *ngFor="let card of cardModels">
      <picimage [model]="card.image"></picimage>
      <div
          *ngIf="card.caption"
          class="caption"
          [innerHTML]="card.caption"></div>
    </div>
  </slider>
</ng-container>
