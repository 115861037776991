import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {IQuote} from '../../../../model/content/quote/payload';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'quotecontainer',
  templateUrl: 'quoteContainer.component.html'
})
export class QuoteContainerComponent implements DynamicComponent<IQuote> {

  quotee: string;
  context: string;
  html: string;

  initModel(model: IQuote): void {
    this.html = model.data.html;
    this.quotee = model.data.quotee;
    this.context = model.data.context;
  }
}
