/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./input.component";
var styles_InputComponent = [];
var RenderType_InputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InputComponent, data: {} });
export { RenderType_InputComponent as RenderType_InputComponent };
function View_InputComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 1, 0, currVal_1); }); }
export function View_InputComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "input"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "input", [], [[8, "type", 0], [8, "placeholder", 0], [8, "name", 0], [8, "id", 0], [8, "value", 0]], [[null, "input"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.onChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.label; _ck(_v, 3, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = _co.placeholder; var currVal_2 = _co.label; var currVal_3 = _co.id; var currVal_4 = _co.value; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_InputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "forminput", [], null, null, null, View_InputComponent_0, RenderType_InputComponent)), i0.ɵdid(1, 114688, null, 0, i2.InputComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputComponentNgFactory = i0.ɵccf("forminput", i2.InputComponent, View_InputComponent_Host_0, { label: "label", type: "type", value: "value", placeholder: "placeholder" }, { valueChange: "valueChange", inputChanged: "inputChanged" }, ["*"]);
export { InputComponentNgFactory as InputComponentNgFactory };
