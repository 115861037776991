/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/tabBar/tabBar.component.ngfactory";
import * as i2 from "../../../common/ui/tabBar/tabBar.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../common/slider/slider.component.ngfactory";
import * as i5 from "../../../common/slider/slider.component";
import * as i6 from "../../../../shared/service/tokens";
import * as i7 from "../../../../shared/service/portal.service";
import * as i8 from "../../../../shared/service/slider.service";
import * as i9 from "./football-stages/football-stages.component.ngfactory";
import * as i10 from "./football-stages/football-stages.component";
import * as i11 from "../../../common/ui/svgIcon/svgIcon.component.ngfactory";
import * as i12 from "../../../common/ui/svgIcon/svgIcon.component";
import * as i13 from "./football-stages-wrapper.component";
import * as i14 from "../../../../shared/service/device-detection.service";
import * as i15 from "../../../../shared/service/football.service";
var styles_FootballStagesWrapperComponent = [];
var RenderType_FootballStagesWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FootballStagesWrapperComponent, data: {} });
export { RenderType_FootballStagesWrapperComponent as RenderType_FootballStagesWrapperComponent };
function View_FootballStagesWrapperComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [["class", "footballStagesWrapper-heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.heading; _ck(_v, 1, 0, currVal_0); }); }
function View_FootballStagesWrapperComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tabbar", [], null, [[null, "tabChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tabChanged" === en)) {
        var pd_0 = (_co.switchTab($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_TabBarComponent_0, i1.RenderType_TabBarComponent)), i0.ɵdid(1, 114688, null, 0, i2.TabBarComponent, [], { model: [0, "model"] }, { tabChanged: "tabChanged" })], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf.tabs; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FootballStagesWrapperComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FootballStagesWrapperComponent_4)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.tabs; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FootballStagesWrapperComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["sliderItem", 1]], null, 2, "div", [["class", "slider-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [["class", "slider-item-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_FootballStagesWrapperComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "slider", [], null, [[null, "currSlideNumber"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("currSlideNumber" === en)) {
        var pd_0 = (_co.switchTab(_v.parent.parent.context.ngIf.tabs[($event - 1)]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SliderComponent_0, i4.RenderType_SliderComponent)), i0.ɵdid(1, 3391488, null, 1, i5.SliderComponent, [i6.GLOBAL_STYLES, i0.PLATFORM_ID, i0.ChangeDetectorRef, i7.PortalService, i8.SliderService], { sliderConfigModel: [0, "sliderConfigModel"] }, { currSlideNumber: "currSlideNumber" }), i0.ɵqud(603979776, 1, { sliderItems: 1 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_FootballStagesWrapperComponent_7)), i0.ɵdid(4, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sliderConfigModel; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.parent.context.ngIf.tabs; _ck(_v, 4, 0, currVal_1); }, null); }
function View_FootballStagesWrapperComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FootballStagesWrapperComponent_6)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.tabs; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FootballStagesWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "footballStagesWrapper"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FootballStagesWrapperComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FootballStagesWrapperComponent_3)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["slider", 2]], null, 0, null, View_FootballStagesWrapperComponent_5)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-football-stages", [], null, null, null, i9.View_FootballStagesComponent_0, i9.RenderType_FootballStagesComponent)), i0.ɵdid(7, 49152, null, 0, i10.FootballStagesComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf.heading; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isMobile; var currVal_2 = i0.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = _v.context.ngIf.model; _ck(_v, 7, 0, currVal_3); }, null); }
function View_FootballStagesWrapperComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "loading-indicator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "svgicon", [], [[1, "class", 0]], null, null, i11.View_SvgIconComponent_0, i11.RenderType_SvgIconComponent)), i0.ɵdid(2, 49152, null, 0, i12.SvgIconComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Loading"]))], function (_ck, _v) { var currVal_1 = "spinner"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).class; _ck(_v, 1, 0, currVal_0); }); }
export function View_FootballStagesWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 5, null, View_FootballStagesWrapperComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpod(5, { tabs: 0, model: 1, heading: 2 }), (_l()(), i0.ɵand(0, [["loading", 2]], null, 0, null, View_FootballStagesWrapperComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.tabs$)), i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 3).transform(_co.footBallStageModel$)), i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 4).transform(_co.heading$))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FootballStagesWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-football-stages-wrapper", [], null, null, null, View_FootballStagesWrapperComponent_0, RenderType_FootballStagesWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i13.FootballStagesWrapperComponent, [i14.DeviceDetectionService, i15.FootballService], null, null)], null, null); }
var FootballStagesWrapperComponentNgFactory = i0.ɵccf("app-football-stages-wrapper", i13.FootballStagesWrapperComponent, View_FootballStagesWrapperComponent_Host_0, {}, {}, []);
export { FootballStagesWrapperComponentNgFactory as FootballStagesWrapperComponentNgFactory };
