import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import {PortalService} from '@shared/service/portal.service';
import {DidomiService} from '@shared/service/didomi.service';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {filter, map, take, tap} from 'rxjs/operators';
import {PianoService} from '@shared/service/piano/piano.service';
import {isPlatformBrowser} from '@angular/common';
import {VendorTypes} from '@model/enum/vendorTypes';

@Component({
  selector: 'app-required-consent-overlay',
  templateUrl: './required-consent-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RequiredConsentOverlayComponent implements OnInit, OnDestroy {

  protected readonly adFreeTemplateIds = {
    motorat: 'OTRCQBWMX511',
    filmat: 'OTWA4GJC5642',
    kuriertv: 'OTL7D7RM594G',
    lustaufoesterreichat: 'OTEQFH3I1LBH',
  };
  showOverlaySubscription: Subscription;
  adFreeSubscription: Subscription;
  loading = false;
  show = false;
  screenOffer = true;
  private adFree = new BehaviorSubject<boolean>(false);

  constructor(
    private portalService: PortalService,
    private didomiService: DidomiService,
    public piano: PianoService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) protected platformId: string,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.adFreeSubscription = this.piano.checkAdFree().subscribe(adFree => {
        this.adFree.next(adFree);
      });

      this.piano.tp$
        .pipe(
          filter(Boolean),
          take(1),
        )
        .subscribe(tp => {
          tp.offer.show({
            offerId: this.piano.opts.adFreeOfferId,
            templateId: this.adFreeTemplateIds[this.portalService.currentPortal()],
            displayMode: 'inline',
            containerSelector: '#offer',
          });
        });

      this.showOverlaySubscription = combineLatest([
        this.didomiService.checkVendor$(VendorTypes.googleAds),
        this.didomiService.tcfOverlay$,
        this.adFree$,
      ])
        .pipe(
          map(res => {
            const adConsent = res[0];
            const noticeActive = res[1];
            const adFree = res[2];
            return !adConsent && !noticeActive && !adFree;
          }),
          tap(there => {
            there ?
              this.renderer.addClass(document.getElementsByTagName('body')[0], 'no-scroll') :
              this.renderer.removeClass(document.getElementsByTagName('body')[0], 'no-scroll');
            this.show = there;
            this.showOffer();
            this.cd.detectChanges();
            // weird bugfix necessary for piano offer template rendering on FF
            window.dispatchEvent(new Event('resize'));
          })
        ).subscribe();
    }
  }

  private get adFree$() {
    return this.adFree.asObservable();
  }

  get currentPortal() {
    return this.portalService.currentPortal();
  }

  ngOnDestroy(): void {
    if (this.adFreeSubscription) {
      this.adFreeSubscription.unsubscribe();
    }
    if (this.showOverlaySubscription) {
      this.showOverlaySubscription.unsubscribe();
    }
  }

  openConsentOverlay() {
    this.didomiService.showConsentOverlay();
  }

  showOffer() {
    this.screenOffer = true;
  }

  showLogin() {
    this.screenOffer = false;
    this.piano.tp$.pipe(take(1)).subscribe(tp => {
      tp.pianoId.show({
        displayMode: 'inline',
        screen: 'login',
        containerSelector: '#login',
        loginSuccess: () => {
          this.showOffer();
        }
      });
    });
  }
}
