import { PortalService } from './portal.service';
import { Title } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "./portal.service";
import * as i2 from "@angular/platform-browser";
var TitleService = /** @class */ (function () {
    function TitleService(portalService, title) {
        this.portalService = portalService;
        this.title = title;
    }
    TitleService.prototype.setDocumentTitle = function (title) {
        var documentTitle = this.createDocumentTitle(title);
        this.title.setTitle(documentTitle);
    };
    TitleService.prototype.createDocumentTitle = function (title) {
        var hostname = this.portalService.hostname();
        if (!title || title === hostname) {
            return hostname;
        }
        return title + ' | ' + hostname;
    };
    TitleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TitleService_Factory() { return new TitleService(i0.ɵɵinject(i1.PortalService), i0.ɵɵinject(i2.Title)); }, token: TitleService, providedIn: "root" });
    return TitleService;
}());
export { TitleService };
