export enum ShareType {
  linkedin = 'linkedin',
  xing = 'xing',
  facebook = 'facebook',
  facebookMessenger = 'facebookMessenger',
  twitter = 'twitter',
  pinterest = 'pinterest',
  googleplus = 'googleplus',
  whatsapp = 'whatsapp',
  instagram = 'instagram',
  mailto = 'mail',
  flipboard = 'flipboard',
  pocket = 'pocket'
}
