import {Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {IPodigee} from '@model/content/podigee/payload';
import {DidomiService} from '@shared/service/didomi.service';
import {Observable} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {VendorTypes} from '@model/enum/vendorTypes';
import {LoadExternalScriptService} from '@shared/service/loadExternalScript.service';
import {ScriptId} from '@model/enum/scriptId';
import {ActivatedRoute} from '@angular/router';
import {IRoute} from '@model/payload';

@Component({
  selector: 'app-podigee',
  templateUrl: './podigee.component.html',
})
export class PodigeeComponent implements DynamicComponent<IPodigee> {

  @ViewChild('player', {static: true}) div: ElementRef;
  showPodigee$: Observable<boolean>;
  vendorType = VendorTypes.podigee;

  constructor(
    private renderer: Renderer2,
    private didomiService: DidomiService,
    private loadExternalScriptService: LoadExternalScriptService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  initModel(model: IPodigee): void {
    this.showPodigee$ = this.didomiService.checkVendor$(this.vendorType).pipe(
      tap(consent => {
        if (consent) {
          this.loadExternalScriptService.loadScript(ScriptId.playerjs)
            .pipe(
              switchMap(s => {
                return this.activatedRoute.data.pipe(
                  tap((data: { route: IRoute }) => {
                    const script: HTMLScriptElement = this.renderer.createElement('script');
                    const div: HTMLDivElement = this.div.nativeElement;
                    this.renderer.addClass(script, 'podigee-podcast-player');
                    this.renderer.setProperty(
                      script,
                      'src',
                      'https://player.podigee-cdn.net/podcast-player/javascripts/podigee-podcast-player.js'
                    );
                    this.renderer.setAttribute(script, 'data-configuration', model.data.uri);
                    this.renderer.appendChild(div, script);
                    script.onload = () => {
                      const iframe = div.querySelector<HTMLIFrameElement>('iframe');
                      iframe.onload = () => {
                        const player = new s.scriptVar.Player(iframe);
                        // @ts-ignore
                        window.ga('create', data.route.analytics.ga360, 'auto');
                        player.on('ready', (arg) => {
                          player.on('play', () => {
                            // @ts-ignore
                            window.ga('send', 'event', 'Podigee', 'play', model.data.uri);
                          });
                          player.on('pause', () => {
                            // @ts-ignore
                            window.ga('send', 'event', 'Podigee', 'pause', model.data.uri);
                          });
                          player.on('ended', () => {
                            // @ts-ignore
                            window.ga('send', 'event', 'Podigee', 'ended', model.data.uri);
                          });
                        });
                      };
                    };
                  })
                );
              })
            )
            .subscribe();
        }
      })
    );
  }

}
