/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./pinterestprofileorboard.component";
import * as i2 from "../../../../shared/service/loadExternalScript.service";
var styles_PinterestBoardOrProfilComponent = [];
var RenderType_PinterestBoardOrProfilComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PinterestBoardOrProfilComponent, data: {} });
export { RenderType_PinterestBoardOrProfilComponent as RenderType_PinterestBoardOrProfilComponent };
export function View_PinterestBoardOrProfilComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "article-centerExternal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "a", [["data-pin-board-width", "400"], ["data-pin-lang", "de"], ["data-pin-scale-height", "240"], ["data-pin-scale-width", "80"]], [[1, "data-pin-do", 0], [8, "href", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataPinDo; var currVal_1 = _co.url; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_PinterestBoardOrProfilComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pinterestprofileorboard", [], null, null, null, View_PinterestBoardOrProfilComponent_0, RenderType_PinterestBoardOrProfilComponent)), i0.ɵdid(1, 49152, null, 0, i1.PinterestBoardOrProfilComponent, [i2.LoadExternalScriptService], null, null)], null, null); }
var PinterestBoardOrProfilComponentNgFactory = i0.ɵccf("pinterestprofileorboard", i1.PinterestBoardOrProfilComponent, View_PinterestBoardOrProfilComponent_Host_0, {}, {}, []);
export { PinterestBoardOrProfilComponentNgFactory as PinterestBoardOrProfilComponentNgFactory };
