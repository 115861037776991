import {FootballStandingRow} from '@model/content/footballTable/footballStandingRow';
import {FootballTableHeading} from '@model/content/footballTable/footballTableHeading';

export class FootballTable {

  tableHeadings = FootballTableHeading.forFootballTable();
  currentSorting = 'pl';

  constructor(
    public rows: FootballStandingRow[],
    public heading?: string,
    sortByCriteria?: boolean,
  ) {
    if (sortByCriteria) {
      this.rows.sort((a, b) => {
        if (a.points < b.points) {
          return 1;
        } else if (a.points > b.points) {
          return -1;
        } else if (a.diff < b.diff) {
          return 1;
        } else if (a.diff > b.diff) {
          return -1;
        } else if (a.goalsScored < b.goalsScored) {
          return 1;
        } else if (a.goalsScored > b.goalsScored) {
          return -1;
        }
        return 0;
      });
      this.rows.map((r, i) => {
        r.pl = i + 1;
        return r;
      });
    }
  }

  static subtractTable(t1: FootballTable, t2: FootballTable): FootballTable {
    const rows = t1.rows.map((r, i) => {
      ['sp', 's', 'u', 'n', 'goalsAgainst', 'goalsScored'].forEach(p => {
        const related = t2.rows.find(row => row.name === r.name);
        r[p] = r[p] - related[p];
      });
      r.status = 'equal';
      return r;
    });
    return new FootballTable(rows, undefined, true);
  }

  sortByProp(prop: string, descending?: boolean) {
    this.currentSorting = prop;
    let dir = this.rows[0][prop] > this.rows[this.rows.length - 1][prop];
    if (descending !== undefined) {
      dir = descending;
    }
    this.rows.sort((a, b) => {
      if (a[prop] > b[prop]) {
        return dir ? 1 : -1;
      } else if (a[prop] < b[prop]) {
        return dir ? -1 : 1;
      }
      return 0;
    });
  }

  getSortingState(prop: string): boolean {
    const propRows = this.rows.map(r => r[prop]);
    return propRows[0] < propRows[propRows.length - 1];
  }
}
