<div class="iconButton">
  <a [gtm]="gtmId" *ngIf="link" [portalRouterLink]="link">
    <ng-container *ngTemplateOutlet="templateIcon"></ng-container>
  </a>
  <button [gtm]="gtmId" *ngIf="href" (click)="linkTo()">
    <ng-container *ngTemplateOutlet="templateIcon"></ng-container>
  </button>
  <span *ngIf="!link && !href">
    <ng-container *ngTemplateOutlet="templateIcon"></ng-container>
  </span>
</div>

<ng-template #templateIcon >
  <svgicon [icon]="icon"></svgicon>
</ng-template>
