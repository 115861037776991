<div class="longList" [ngClass]="[box.cssClass]" *ngIf="(teasers$ | async) as teasers">
  <linkbar *ngIf="linkBarModel" [model]="linkBarModel"></linkbar>
  <ng-container teaser
                *ngFor="let model of mediaModels; trackBy: trackMedia"
                [model]="model"></ng-container>
  <p *ngIf="showMore" class="longList-showMore">
    <a class="button" href="#" (click)="fetchMoreEntries()" oewaClickTrigger [gtm]="gtmId">
      {{model.cta}}
    </a>
  </p>
</div>
