<ng-container *ngIf="teasers$ | async">
  <section class="topStories">
    <div class="topStories-main">
      <div class="topStories-primary">
        <ng-container teaser [model]="firstCard"></ng-container>
      </div>
      <div class="topStories-secondary">
        <ng-container teaser [model]="secondCard"></ng-container>
      </div>
    </div>
  </section>
</ng-container>
