import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {TeasersService} from '../../../../shared/service/teasers.service';
import {ITeaser} from '../../../../model/teaser/payload';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TeaserView} from '../../../../model/teaser/teaserView';
import {TeaserModel} from '../../../../model/teaser/teaser';
import {ITeaserParagraph} from '../../../../model/content/teaserParagraph/payload';
import {DeviceDetectionService} from '../../../../shared/service/device-detection.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'teaserParagraph',
  templateUrl: 'teaserParagraph.component.html'
})
export class TeaserParagraphComponent implements DynamicComponent<ITeaserParagraph> {

  teaser$: Observable<ITeaser>;
  teaserModel: TeaserModel;

  constructor(
    protected teasers: TeasersService,
    private mobileDetectionsService: DeviceDetectionService,
  ) {
  }

  initModel(model: ITeaserParagraph): void {
    if (model.data.target_id) {
      this.teaser$ = this.teasers.getTeaser(model.data.target_id).pipe(tap(teaser => {
        if (model.teaser_view === 'text_teaser') {
          this.teaserModel = TeaserModel.fromTextTeaser(teaser);
        } else {
          this.teaserModel = TeaserModel.fromMediaTeaser(
            teaser,
            new TeaserView(true, true, false),
            this.mobileDetectionsService.isMobile,
          );
        }
      }));
    }
  }
}
