import {Component} from '@angular/core';
import {DynamicComponent} from '@component/common/dynamicComponent';
import {IRomyVoting} from '@model/content/romyVoting/payload';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'romyvoting',
  templateUrl: './romyVoting.component.html',
})
export class RomyVotingComponent implements DynamicComponent<IRomyVoting> {

  url: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  initModel(model: IRomyVoting): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(model.url);
  }

}
