import {MediaComponent} from '@component/common/teaser/media/media.component';
import {CardComponent} from '@component/common/teaser/card/card.component';
import {HeroComponent} from '@component/common/teaser/hero/hero.component';
import {MovieSerieComponent} from '@component/common/teaser/movieSerie/movie-serie.component';
import {OpinionComponent} from '@component/common/teaser/opinion/opinion.component';
import {ImageOnlyComponent} from '@component/common/teaser/image-only/image-only.component';
import {TextTeaserComponent} from '@component/common/teaser/text-teaser/text-teaser.component';

export const typeMap = {
  media: MediaComponent,
  card: CardComponent,
  hero: HeroComponent,
  movie: MovieSerieComponent,
  opinion: OpinionComponent,
  imageOnly: ImageOnlyComponent,
  text: TextTeaserComponent,
};
