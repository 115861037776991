/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../gpt.ad.directive";
import * as i2 from "../../../shared/service/route.service";
import * as i3 from "../../../shared/ads/gpt.service";
import * as i4 from "../../../shared/service/portal.service";
import * as i5 from "../../../shared/service/didomi.service";
import * as i6 from "../../../shared/service/piano/piano.service";
import * as i7 from "../../../shared/service/device-detection.service";
import * as i8 from "./ad-ticker.component";
var styles_AdTickerComponent = [];
var RenderType_AdTickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdTickerComponent, data: {} });
export { RenderType_AdTickerComponent as RenderType_AdTickerComponent };
export function View_AdTickerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "adContainer wb wb-ticker"]], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.GptAdDirective, [i0.PLATFORM_ID, i2.RouteService, i0.ElementRef, i0.Renderer2, i3.GooglePublisherTagService, i4.PortalService, i5.DidomiService, i6.PianoService, i7.DeviceDetectionService], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AdTickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ad-ticker", [], null, null, null, View_AdTickerComponent_0, RenderType_AdTickerComponent)), i0.ɵdid(1, 49152, null, 0, i8.AdTickerComponent, [i7.DeviceDetectionService], null, null)], null, null); }
var AdTickerComponentNgFactory = i0.ɵccf("ad-ticker", i8.AdTickerComponent, View_AdTickerComponent_Host_0, {}, {}, []);
export { AdTickerComponentNgFactory as AdTickerComponentNgFactory };
