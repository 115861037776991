/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./error.component";
var styles_ErrorComponent = [];
var RenderType_ErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ErrorComponent, data: {} });
export { RenderType_ErrorComponent as RenderType_ErrorComponent };
export function View_ErrorComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "errorComponent"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "p", [["class", "errorComponent-text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }); }
export function View_ErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "error", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i0.ɵdid(1, 49152, null, 0, i1.ErrorComponent, [], null, null)], null, null); }
var ErrorComponentNgFactory = i0.ɵccf("error", i1.ErrorComponent, View_ErrorComponent_Host_0, {}, {}, []);
export { ErrorComponentNgFactory as ErrorComponentNgFactory };
