import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {IIframe} from '@model/channel/iframe/payload';
import {LinkBarModel} from '@model/component/linkBar';
import {DeviceDetectionService} from '@shared/service/device-detection.service';
import {PortalService} from '@shared/service/portal.service';
import {DidomiService} from '@shared/service/didomi.service';
import {VendorTypes} from '@model/enum/vendorTypes';
import {tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {bind} from 'get-options';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'iframecontainer',
  templateUrl: 'iframecontainer.component.html'
})
export class IframeContainerComponent implements DynamicComponent<IIframe>, OnDestroy {

  safeUrl: SafeResourceUrl;
  model: IIframe;
  linkBarModel: LinkBarModel;
  iframeRatio: number;
  private height: number;
  private width: number;
  vendorType: VendorTypes;
  showIframe$: Observable<boolean>;
  iframeHeight: string;
  iframeWidth: string;
  eventListenerFunction: (event: any) => void;

  constructor(
    private sanitizer: DomSanitizer,
    private viewPort: DeviceDetectionService,
    private portalService: PortalService,
    private cd: ChangeDetectorRef,
    private didomiService: DidomiService,
  ) {
  }

  private setViewPortProp(): void {
    if (this.model.data.iframe_type !== 'APA Grafiken') {
      const data = this.model.data;
      let height = 'height';
      let width = 'width';
      if (this.viewPort.isMobile && data.height_mobile && data.width_mobile) {
        height = 'height_mobile';
        width = 'width_mobile';
      }
      if (this.viewPort.isTabletOnly && data.height_tablet && data.width_tablet) {
        height = 'height_tablet';
        width = 'width_tablet';
      }
      this.height = +data[height];
      this.width = +data[width];
    }
  }

  private setIFrame(model: IIframe): void {
    this.model = model;
    this.setViewPortProp();
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(model.data.url);
    this.linkBarModel = new LinkBarModel(model.title, null, true);
    if (model.data.iframe_type === 'APA Grafiken') {
      this.iframeWidth = '100%';
      this.eventListenerFunction = (event) => {
        const id = new URL(this.model.data.url).searchParams.get('id');
        if (event.data[id]) {
          this.iframeHeight = event.data[id] ? event.data[id] + 'px' : '';
          this.cd.markForCheck();
        }
      };
      window.addEventListener('message', this.eventListenerFunction, true);
    } else {
      this.iframeRatio = Number((this.height ? this.height : '150')) / this.width * 100;
    }
    this.cd.markForCheck();
  }

  initModel(model: IIframe): void {
    switch (model.data.iframe_type) {
      case 'APA Grafiken':
        this.vendorType = VendorTypes.apaGrafiken;
        break;
      case 'Podigee':
        this.vendorType = VendorTypes.podigee;
        break;
      case 'APA Video':
        this.vendorType = VendorTypes.apaVideo;
        break;
      case 'Riddle':
        this.vendorType = VendorTypes.riddle;
        break;
      case 'Infogram':
        this.vendorType = VendorTypes.infogram;
        break;
      case 'YouTube':
        this.vendorType = VendorTypes.youTube;
        break;
      case 'Datawrapper':
        this.vendorType = VendorTypes.datawrapper;
        break;
      case '23Degrees':
        this.vendorType = VendorTypes.degrees23;
        break;
      case 'spotify':
        this.vendorType = VendorTypes.spotify;
        break;
      case 'amazon_affiliate':
        this.vendorType = VendorTypes.amazon;
        break;
      case 'art19':
        this.vendorType = VendorTypes.art19;
        break;
      case 'flourish':
        this.vendorType = VendorTypes.flourish;
        break;
      case 'Walls io':
        this.showFrameWithoutConsent(model);
        break;
      case 'Invitario':
        this.showFrameWithoutConsent(model);
        break;
      case 'Google Datastudio':
        this.showFrameWithoutConsent(model);
        break;
      case 'Rotes Kreuz':
        this.showFrameWithoutConsent(model);
        break;
      case 'github':
        this.showFrameWithoutConsent(model);
        break;
      case 'Tickaroo':
        this.showFrameWithoutConsent(model);
        break;
      case 'Kurier Spiele':
        this.showFrameWithoutConsent(model);
        break;
      case 'Knightlab':
        this.vendorType = VendorTypes.googleAnalytics;
        break;
      case 'krone_staatspreis':
        this.vendorType = VendorTypes.googleAnalytics;
        break;
      case 'LinkedIn':
        this.vendorType = VendorTypes.linkedIn;
        break;
      case 'Google Maps':
        this.vendorType = VendorTypes.googleMaps;
        break;
      case 'Acast':
        this.vendorType = VendorTypes.acast;
        break;
    }
    this.cd.markForCheck();
    if (this.vendorType) {
      this.showIframe$ = this.didomiService.checkVendor$(this.vendorType)
        .pipe(
          tap(consent => {
            if (consent) {
              this.setIFrame(model);
            }
          })
        );
    }
  }

  showFrameWithoutConsent(model: IIframe): void {
    this.showIframe$ = of(true);
    this.setIFrame(model);
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.eventListenerFunction, true);
  }
}

