import {Inject, Injectable, RendererFactory2, ViewEncapsulation} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LinkService {

  constructor(private rendererFactory: RendererFactory2,
              @Inject(DOCUMENT) private document) {
  }

  addTags(tags: LinkDefinition[], forceCreation?: boolean) {
    if (!tags) {
      return;
    }
    for (const tag of tags) {
      this.addTag(tag, forceCreation);
    }
  }

  /**
   * Inject the State into the bottom of the <head>
   */
  addTag(tag: LinkDefinition, forceCreation?: boolean) {

    const head = this.document.head;
    if (head === null) {
      console.warn('<head> not found within DOCUMENT.');
      return;
    }

    try {
      const renderer = this.rendererFactory.createRenderer(this.document, {
        id: '-1',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      });

      const link = renderer.createElement('link');

      Object.keys(tag).forEach((prop: string) => {
        return renderer.setAttribute(link, prop, tag[prop]);
      });

      if (tag.rel !== 'stylesheet') {
        this.removeLink(tag.rel);
      }
      renderer.appendChild(head, link);

    } catch (e) {
      console.error('Error within linkService : ', e);
    }
  }

  protected _parseSelector(tag: LinkDefinition): string {
    // Possibly re-work this
    const attr: string = tag.rel ? 'rel' : 'hreflang';
    return `${attr}="${tag[attr]}"`;
  }

  removeLink(rel: string) {
    try {
      const renderer = this.rendererFactory.createRenderer(this.document, {
        id: '-1',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      });
      const canonical = this.document.querySelector('link[rel="' + rel + '"]');
      const head = this.document.head;

      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.');
      }
      if (!!canonical) {
        renderer.removeChild(head, canonical);
      }
    } catch (e) {
      console.error('Error within linkService : ', e);
    }
  }
}

export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};
