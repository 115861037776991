import {PortalRoute} from '../../teaser/portalRoute';
import {ImageModel} from '../../component/image';
import {ITeaser} from '../../teaser/payload';
import {ImageType} from '../../enum/image';
import {IMovieSerie} from '../../movieSerie/payload';

export class VideoPlaylistItem {
  title: string;
  link: PortalRoute;
  image: ImageModel;
  teaser: ITeaser;

  static deserialize(res: IMovieSerie[]): VideoPlaylistItem[] {
    const arr = res.map((item: IMovieSerie) => {
      const videoItem = new VideoPlaylistItem();
      videoItem.title = item.title;
      videoItem.link = PortalRoute.fromTeaser(item);
      if (item.poster) {
        videoItem.image = new ImageModel(item.poster, ImageType.POSTER);
      } else {
        videoItem.image = new ImageModel(item.teaser_img, ImageType.SQ);
      }
      videoItem.teaser = item;
      return videoItem;
    });
    return arr;
  }

  public setActiveVideo(): VideoPlaylistItem {
    const videoItem = new VideoPlaylistItem();
    videoItem.title = this.title;
    videoItem.link = this.link;
    videoItem.image = new ImageModel(this.teaser.teaser_img, ImageType.LS_16_9);
    videoItem.image.setVideo(this.teaser);
    videoItem.teaser = this.teaser;
    return videoItem;
  }
}
