import {Directive, ElementRef, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {combineLatest, interval, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, filter, share, skip, skipWhile, take, takeUntil, tap} from 'rxjs/operators';
import {GooglePublisherTagService} from '@shared/ads/gpt.service';
import {AdModel} from '@model/ad/ad-model';
import {IRoute} from '@model/payload';
import {RouteService} from '@shared/service/route.service';
import {PortalService} from '@shared/service/portal.service';
import {DidomiService} from '@shared/service/didomi.service';
import {VendorTypes} from '@model/enum/vendorTypes';
import {PianoService} from '@shared/service/piano/piano.service';
import {DeviceDetectionService} from '@shared/service/device-detection.service';

@Directive({
  selector: '[adGpt]'
})
export class GptAdDirective implements OnInit, OnDestroy {

  @Input('adGpt') model: AdModel;

  private route$: Observable<IRoute>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private initialized = false;

  constructor(@Inject(PLATFORM_ID) protected platformId: string,
              routeService: RouteService,
              private element: ElementRef,
              private renderer: Renderer2,
              private gpt: GooglePublisherTagService,
              private portalService: PortalService,
              private didomiService: DidomiService,
              private piano: PianoService,
              private deviceDetectionService: DeviceDetectionService,
  ) {
    this.route$ = routeService.routeInfo.pipe(
      filter(Boolean),
      takeUntil(this.destroy$),
      skipWhile(route => !route.dataLayer.AdsEnabled),
      share(),
    );
  }

  get isFixedCampaign(): boolean {
    return this.element.nativeElement.querySelectorAll('[data-kam-is-fix-campaign]').length !== 0;
  }

  ngOnInit(): void {
    combineLatest([
      this.didomiService.checkVendor$(VendorTypes.googleAds),
      this.piano.checkAdFree(),
    ])
      .pipe(
        takeUntil(this.destroy$),
        tap(res => {
          const adConsent = res[0];
          const adFree = res[1];
          if (adConsent && !adFree) {
            this.initialize();
          } else {
            this.gpt.destroySlot(this.model);
            this.initialized = false;
          }
        })
      ).subscribe();

    this.piano.checkAdReduced().pipe(
      takeUntil(this.destroy$),
      tap(access => {
        if (access) {
          this.gpt.destroySlot(this.model);
          const allowedAds = ['adMobileBanner', 'adInContent', 'adFullBanner'];
          if (this.deviceDetectionService.maxWidth('1123px')) {
            allowedAds.push('adMediumRectangle');
          } else {
            allowedAds.push('adSkyscraper');
          }
          if ((allowedAds.includes(this.model.baseId) && this.model.adUnitPath) ||
            (this.model.adUnitPath && this.model.adUnitPath.includes('kurierat_mediumrectangle1'))) {
            if (!this.model.adUnitPath.includes('_abo_')) {
              this.model.adUnitPath = this.model.adUnitPath.replace('kurierat', 'kurierat_abo');
              this.setId();
              this.gpt.addSlot(this.model);
              this.gpt.refreshSlot(this.model);
            }
          }
        }
      }),
    ).subscribe();
  }

  private initialize() {
    if (!this.initialized) {
      this.initAddSlot();
      this.initToggleAdsEnabled();
      this.initToggleSpecial();
      this.initRefreshTimeout();
      this.gpt.refreshSlot(this.model);
      this.initialized = true;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.gpt.destroySlot(this.model);
  }

  /**
   * Once ads enabled, add slot
   */
  private initAddSlot() {
    this.route$.pipe(
      take(1),
    ).subscribe(route => {
      this.model.setAdUnitPath(route);
      this.setId();
      this.gpt.addSlot(this.model);
    });
  }

  /**
   * Toggle AdsEnabled
   */
  private initToggleAdsEnabled() {
    // disable ads
    this.route$.pipe(
      distinctUntilChanged(null, route => route.dataLayer.AdsEnabled),
      filter(route => !route.dataLayer.AdsEnabled),
    ).subscribe(() => {
      this.gpt.clearSlot(this.model);
    });
    // re-enable regular ads
    this.route$.pipe(
      skipWhile(route => route.dataLayer.AdsEnabled),
      distinctUntilChanged(null, route => route.dataLayer.AdsEnabled),
      filter(route => route.dataLayer.AdsEnabled && !route.dataLayer.Kampagne),
    ).subscribe(() => {
      this.gpt.refreshSlot(this.model);
    });
  }

  /**
   * Toggle special ad unit paths
   */
  private initToggleSpecial() {
    this.route$.pipe(
      filter(route => route.dataLayer.AdsEnabled),
      distinctUntilChanged(null, route => route.dataLayer.Kampagne),
      skip(1),
    ).subscribe(route => {
      this.gpt.destroySlot(this.model);
      this.model.setAdUnitPath(route);
      this.setId();
      this.gpt.addSlot(this.model);
    });
  }

  /**
   * Refresh ads on navigation
   */

  private initRefreshTimeout() {
    if (this.model.refresh) {
      this.route$.pipe(
        takeUntil(this.destroy$),
      ).subscribe(() => {
        this.gpt.refreshSlot(this.model);
      });
      interval(30 * 1000)
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          const documentVisible = this.gpt.documentVisible.getValue();
          if (!this.isFixedCampaign && documentVisible) {
            this.gpt.refreshSlot(this.model);
          }
        });
    }
  }

  private setId() {
    this.model.assignId();
    this.renderer.setAttribute(this.element.nativeElement, 'id', this.model.id);
  }

}
