/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../common/longList/longlist.component.ngfactory";
import * as i2 from "../../common/longList/longlist.component";
import * as i3 from "../../../../shared/service/teasers.service";
import * as i4 from "../../../../shared/service/device-detection.service";
import * as i5 from "../../../../shared/service/portal.service";
import * as i6 from "@angular/common";
import * as i7 from "./sectionRelateds.component";
import * as i8 from "../../../../shared/service/related.service";
var styles_SectionRelatedsComponent = [];
var RenderType_SectionRelatedsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SectionRelatedsComponent, data: {} });
export { RenderType_SectionRelatedsComponent as RenderType_SectionRelatedsComponent };
function View_SectionRelatedsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "longlist", [], null, null, null, i1.View_LongListComponent_0, i1.RenderType_LongListComponent)), i0.ɵdid(2, 573440, null, 0, i2.LongListComponent, [i3.TeasersService, i4.DeviceDetectionService, i5.PortalService], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.longlistModel; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SectionRelatedsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_SectionRelatedsComponent_1)), i0.ɵdid(1, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i6.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.teasers$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SectionRelatedsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sectionRelateds", [], null, null, null, View_SectionRelatedsComponent_0, RenderType_SectionRelatedsComponent)), i0.ɵdid(1, 49152, null, 0, i7.SectionRelatedsComponent, [i8.RelatedService], null, null)], null, null); }
var SectionRelatedsComponentNgFactory = i0.ɵccf("sectionRelateds", i7.SectionRelatedsComponent, View_SectionRelatedsComponent_Host_0, {}, {}, []);
export { SectionRelatedsComponentNgFactory as SectionRelatedsComponentNgFactory };
