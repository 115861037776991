import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {PortalService} from '../../../../shared/service/portal.service';
import {ChannelSearchService} from '../../../../shared/service/channelSearch.service';
import {TitleService} from '../../../../shared/service/title.service';
import {Observable} from 'rxjs';
import {TabModel} from '../../../../model/component/tab';
import {SearchOrder} from '../../../../model/enum/search';
import {ISearchHeader} from '../../../../model/search/payload';
import {LinkBarModel} from '../../../../model/component/linkBar';
import {Location} from '@angular/common';
import {map} from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'searchheader',
  templateUrl: 'resultHeader.component.html'
})
export class SearchHeaderComponent implements DynamicComponent<ISearchHeader> {

  term: string;
  linkBarModel$: Observable<LinkBarModel>;
  tabModels: TabModel<SearchOrder>[];

  private model: ISearchHeader;
  private currentTab: TabModel<SearchOrder>;
  private orderingDropdown = [
    {
      label: 'Aktualität',
      ordering: SearchOrder.ORDER_DATE
    },
    {
      label: 'Relevanz',
      ordering: SearchOrder.ORDER_RELEVANCE
    }
  ];

  constructor(private portalService: PortalService,
              private searchService: ChannelSearchService,
              private titleService: TitleService,
              private location: Location) {
  }

  initModel(model: ISearchHeader): void {
    this.model = model;
    this.term = model.term;

    let index = -1;
    this.tabModels = this.orderingDropdown.map((o, i) => {
      if (o.ordering === model.ordering) {
        index = i;
      }
      return new TabModel(i, o.label, o.ordering, index === i);
    });
    this.currentTab = this.tabModels[index];
    this.linkBarModel$ = this.searchService.searchResult.pipe(map(result => {
      return new LinkBarModel(result.totalHits + ' Treffer zu ' + this.term, '', true);
    }));
  }

  switchTab(tab: TabModel<SearchOrder>) {
    if (this.currentTab === tab) {
      return;
    }
    this.currentTab = tab;
    this.searchService.page = 1;
    this.searchService.ordering = tab.info;
    this.searchService.searchByTerm(this.term, tab.info);
    this.location.go(this.searchService.getLink().path);
  }

  onSearch(term: string) {
    if (term === this.term) {
      return;
    }
    this.term = term;
    if (term.length >= 2) {
      this.searchService.page = 1;
      this.searchService.searchByTerm(term, this.currentTab.info);
      this.titleService.setDocumentTitle(term + ' - Suche');
      this.location.go(this.searchService.getLink().path);
    } else if (term === '') {
      this.titleService.setDocumentTitle('Suche');
      this.searchService.clearSubject();
    }
  }
}
