<div class="footballTableLeague">
  <ng-container *ngIf="footballStandingTables$ | async as wrapper">
    <h1 class="footballTableLeague-title">{{wrapper.heading}}</h1>
    <tabbar
      [model]="tabModel"
      (tabChanged)="changeTab($event)"></tabbar>
    <div *ngFor="let table of wrapper.tables">
      <app-football-table [model]="table"></app-football-table>
    </div>
  </ng-container>
</div>

