import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[swipe]'
})
export class SwipeDirective {

  @Output() swipedLeft = new EventEmitter<boolean>();
  @Output() swipedRight = new EventEmitter<boolean>();
  private touchStartX: number;

  constructor() {
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart($ev: any) {
    this.touchStartX = $ev.changedTouches[0].screenX;
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd($ev: any) {
    const touchendX = $ev.changedTouches[0].screenX;
    if (touchendX + 100 < this.touchStartX) {
      this.swipedLeft.emit(true);
    }
    if (touchendX - 100 > this.touchStartX) {
      this.swipedRight.emit(true);
    }
  }

}
