import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ConsentFreeService {

  protected userAgents = [
    // Google Bots
    'APIs-Google',
    'Mediapartners-Google',
    'AdsBot-Google-Mobile',
    'AdsBot-Google-Mobile',
    'AdsBot-Google',
    'Googlebot-Image',
    'Googlebot-News',
    'Googlebot',
    'Googlebot-Video',
    'Mediapartners-Google',
    'AdsBot-Google-Mobile-Apps',
    'FeedFetcher-Google',
    'Google-Read-Aloud',
    'DuplexWeb-Google',
    'Google Favicon',
    'googleweblight',
    'Storebot-Google',
    'cXensebot',
    'bottalk/extractor.1.1.',
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
  }

  private get containsUa(): boolean {
    let value = 0;
    this.userAgents.forEach(ua => {
      value = value + (+navigator.userAgent.includes(ua));
    });
    return (value > 0);
  }

  get isTrackingFree(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return window.location.search.includes('trackingAllowed');
    }
    return false;
  }

  get isConsentFree(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      // check if site opens in an iframe important for editor preview
      if (this.containsUa || this.isTrackingFree) {
        return true;
      }
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
    return false;
  }
}
