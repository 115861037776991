<ng-container *ngIf="showApaVideo$ | async; else noContent">
  <div class="apaVideo">
    <h6 *ngIf="title !== ''">{{title}}</h6>
    <div class="article-videoContainer">
      <videoComp [model]="videoModel"></videoComp>
    </div>
  </div>
</ng-container>

<ng-template #noContent>
  <app-no-consent [vendor]="vendorType"></app-no-consent>
</ng-template>
