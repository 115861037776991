/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i2 from "../../../common/ui/linkBar/linkBar.component";
import * as i3 from "../../common/movieSerieTeaser/movie-serie-teaser.component.ngfactory";
import * as i4 from "../../common/movieSerieTeaser/movie-serie-teaser.component";
import * as i5 from "@angular/common";
import * as i6 from "./person-movie-list.component";
var styles_PersonMovieListComponent = [];
var RenderType_PersonMovieListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PersonMovieListComponent, data: {} });
export { RenderType_PersonMovieListComponent as RenderType_PersonMovieListComponent };
function View_PersonMovieListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_PersonMovieListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 1, 0, currVal_0); }); }
function View_PersonMovieListComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "linkbar", [], null, null, null, i1.View_LinkBarComponent_0, i1.RenderType_LinkBarComponent)), i0.ɵdid(1, 49152, null, 0, i2.LinkBarComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.linkBarMovies; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PersonMovieListComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "movieteaser", [], null, null, null, i3.View_MovieSerieTeaserComponent_0, i3.RenderType_MovieSerieTeaserComponent)), i0.ɵdid(1, 573440, null, 0, i4.MovieSerieTeaserComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PersonMovieListComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "linkbar", [], null, null, null, i1.View_LinkBarComponent_0, i1.RenderType_LinkBarComponent)), i0.ɵdid(1, 49152, null, 0, i2.LinkBarComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.linkBarSeries; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PersonMovieListComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "movieteaser", [], null, null, null, i3.View_MovieSerieTeaserComponent_0, i3.RenderType_MovieSerieTeaserComponent)), i0.ɵdid(1, 573440, null, 0, i4.MovieSerieTeaserComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PersonMovieListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PersonMovieListComponent_4)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PersonMovieListComponent_5)), i0.ɵdid(4, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PersonMovieListComponent_6)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PersonMovieListComponent_7)), i0.ɵdid(8, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.movies && _v.context.$implicit.movies.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.movies; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.series && _v.context.$implicit.series.length); _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.series; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_PersonMovieListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "section", [["class", "personMovieList"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "header", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PersonMovieListComponent_1)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PersonMovieListComponent_2)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PersonMovieListComponent_3)), i0.ɵdid(7, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.description; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.data; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_PersonMovieListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-person-movie-list", [], null, null, null, View_PersonMovieListComponent_0, RenderType_PersonMovieListComponent)), i0.ɵdid(1, 49152, null, 0, i6.PersonMovieListComponent, [], null, null)], null, null); }
var PersonMovieListComponentNgFactory = i0.ɵccf("app-person-movie-list", i6.PersonMovieListComponent, View_PersonMovieListComponent_Host_0, {}, {}, []);
export { PersonMovieListComponentNgFactory as PersonMovieListComponentNgFactory };
