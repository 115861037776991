import { ChangeDetectorRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { PortalService } from '@shared/service/portal.service';
import { DidomiService } from '@shared/service/didomi.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { PianoService } from '@shared/service/piano/piano.service';
import { isPlatformBrowser } from '@angular/common';
import { VendorTypes } from '@model/enum/vendorTypes';
var RequiredConsentOverlayComponent = /** @class */ (function () {
    function RequiredConsentOverlayComponent(portalService, didomiService, piano, renderer, platformId, cd) {
        this.portalService = portalService;
        this.didomiService = didomiService;
        this.piano = piano;
        this.renderer = renderer;
        this.platformId = platformId;
        this.cd = cd;
        this.adFreeTemplateIds = {
            motorat: 'OTRCQBWMX511',
            filmat: 'OTWA4GJC5642',
            kuriertv: 'OTL7D7RM594G',
            lustaufoesterreichat: 'OTEQFH3I1LBH',
        };
        this.loading = false;
        this.show = false;
        this.screenOffer = true;
        this.adFree = new BehaviorSubject(false);
    }
    RequiredConsentOverlayComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId)) {
            this.adFreeSubscription = this.piano.checkAdFree().subscribe(function (adFree) {
                _this.adFree.next(adFree);
            });
            this.piano.tp$
                .pipe(filter(Boolean), take(1))
                .subscribe(function (tp) {
                tp.offer.show({
                    offerId: _this.piano.opts.adFreeOfferId,
                    templateId: _this.adFreeTemplateIds[_this.portalService.currentPortal()],
                    displayMode: 'inline',
                    containerSelector: '#offer',
                });
            });
            this.showOverlaySubscription = combineLatest([
                this.didomiService.checkVendor$(VendorTypes.googleAds),
                this.didomiService.tcfOverlay$,
                this.adFree$,
            ])
                .pipe(map(function (res) {
                var adConsent = res[0];
                var noticeActive = res[1];
                var adFree = res[2];
                return !adConsent && !noticeActive && !adFree;
            }), tap(function (there) {
                there ?
                    _this.renderer.addClass(document.getElementsByTagName('body')[0], 'no-scroll') :
                    _this.renderer.removeClass(document.getElementsByTagName('body')[0], 'no-scroll');
                _this.show = there;
                _this.showOffer();
                _this.cd.detectChanges();
                // weird bugfix necessary for piano offer template rendering on FF
                window.dispatchEvent(new Event('resize'));
            })).subscribe();
        }
    };
    Object.defineProperty(RequiredConsentOverlayComponent.prototype, "adFree$", {
        get: function () {
            return this.adFree.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RequiredConsentOverlayComponent.prototype, "currentPortal", {
        get: function () {
            return this.portalService.currentPortal();
        },
        enumerable: true,
        configurable: true
    });
    RequiredConsentOverlayComponent.prototype.ngOnDestroy = function () {
        if (this.adFreeSubscription) {
            this.adFreeSubscription.unsubscribe();
        }
        if (this.showOverlaySubscription) {
            this.showOverlaySubscription.unsubscribe();
        }
    };
    RequiredConsentOverlayComponent.prototype.openConsentOverlay = function () {
        this.didomiService.showConsentOverlay();
    };
    RequiredConsentOverlayComponent.prototype.showOffer = function () {
        this.screenOffer = true;
    };
    RequiredConsentOverlayComponent.prototype.showLogin = function () {
        var _this = this;
        this.screenOffer = false;
        this.piano.tp$.pipe(take(1)).subscribe(function (tp) {
            tp.pianoId.show({
                displayMode: 'inline',
                screen: 'login',
                containerSelector: '#login',
                loginSuccess: function () {
                    _this.showOffer();
                }
            });
        });
    };
    return RequiredConsentOverlayComponent;
}());
export { RequiredConsentOverlayComponent };
