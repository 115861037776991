import {IFootballData, IFootballResult, IFootballTeam} from '@model/content/footballTable/payload';
import {FootballStageRowModel} from '@model/content/footballStages/footballStageRow';

export class FootballStageModel {
  heading: string;
  isLive: boolean;
  games: FootballStageRowModel[];

  static fromFootBallResult(results: IFootballResult[], teams: IFootballData<IFootballTeam[]>) {
    const groupByTime: { [key: string]: IFootballResult[] } = results.reduce((rv, x) => {
      (rv[x.time.starting_at.date] = rv[x.time.starting_at.date] || []).push(x);
      return rv;
    }, {});
    const ordered = Object.keys(groupByTime).sort().reduce(
      (obj, key) => {
        obj[key] = groupByTime[key];
        return obj;
      },
      {}
    );
    return Object.keys(ordered).map(key => {
      const week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const month = ['January', 'February', 'March',
        'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const resultsByTime = groupByTime[key];
      const date = new Date(key);
      const m = new FootballStageModel();
      m.heading = week[date.getDay()] + ', ' + date.getDate() + ' ' + month[date.getMonth()] + ' ' + date.getFullYear();
      const in90min = new Date(new Date().getTime() + 90 * 60000);
      m.isLive = in90min < new Date();
      m.games = FootballStageRowModel.fromFootBallResult(resultsByTime, teams);
      return m;
    });
  }
}
