<section class="immoJobBox">
  <h2 class="immoJobBox-title">
    <a href="https://immo.kurier.at/" target="_blank">
      <iconbutton class="immoJobBox-title-first" [icon]="'home'"></iconbutton>
      <img src="/assets/images/immoJobBox/immo.svg" height="48" alt="immo.kurier.at Logo">
      <iconbutton class="immoJobBox-title-last" [icon]="'angle-right'"></iconbutton>
    </a>
  </h2>
  <figure class="immoJobBox-image">
    <img [src]="'/assets/images/immoJobBox/immo-header-image.jpg'"
         alt="Der schnellste Weg in die eigenen vier Wände."/>
    <h3>Der schnellste Weg in die eigenen vier Wände.</h3>
  </figure>
  <form action="#" (ngSubmit)="onSubmit()" class="immoJobBox-form" autocomplete="off">
    <div class="immoJobBox-form-item">
      <forminput [(value)]="location" [label]="'Wo?'" [type]="'text'" (inputChanged)="onInputChanged($event)"
                 [placeholder]="'Stadt, PLZ oder Projekt-ID'">
        <ul *ngIf="searchActive && searchResult$ | async as results" class="immoJobBox-search-results">
          <li (click)="selectInput(result)" *ngFor="let result of results" class="immoJobBox-search-item">
            {{result.name}}
          </li>
        </ul>
      </forminput>
    </div>
    <div class="immoJobBox-form-item">
      <formselect [label]="'Was?'" [values]="rentType" [(valueSelected)]="rentTypeSelected"></formselect>
    </div>
    <div class="immoJobBox-form-item">
      <formselect [label]="'Was?'" [values]="immoType" [(valueSelected)]="immoTypeSelected"></formselect>
    </div>
    <button type="submit" class="immoJobBox-form-button button">
      <iconbutton [icon]="'search'"></iconbutton>
      <span>Suche starten</span>
    </button>
  </form>
</section>
