var TeaserView = /** @class */ (function () {
    function TeaserView(img, title, 
    // tslint:disable-next-line:variable-name
    teaser_text, hero, 
    // tslint:disable-next-line:variable-name
    big_poster_view, 
    // tslint:disable-next-line:variable-name
    small_poster_view, headlines) {
        if (img === void 0) { img = true; }
        if (title === void 0) { title = true; }
        if (teaser_text === void 0) { teaser_text = true; }
        if (hero === void 0) { hero = false; }
        if (big_poster_view === void 0) { big_poster_view = false; }
        if (small_poster_view === void 0) { small_poster_view = false; }
        if (headlines === void 0) { headlines = false; }
        this.img = img;
        this.title = title;
        this.teaser_text = teaser_text;
        this.hero = hero;
        this.big_poster_view = big_poster_view;
        this.small_poster_view = small_poster_view;
        this.headlines = headlines;
    }
    Object.defineProperty(TeaserView, "heroView", {
        get: function () {
            return new TeaserView(true, true, false, true);
        },
        enumerable: true,
        configurable: true
    });
    return TeaserView;
}());
export { TeaserView };
