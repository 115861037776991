<iframe
  *ngIf="showTeaser$ | async; else noContent"
  class="contentGarden-teaser"
  width="100%"
  height="0"
  scrolling="no"
  #contentRef></iframe>

<ng-template #noContent>
  <app-no-consent [vendor]="vendorType"></app-no-consent>
</ng-template>
