<div class="footballLiveScoresWrapper">
  <tabbar [model]="tabModels" (tabChanged)="switchTab($event)"></tabbar>

  <ng-container [ngSwitch]="activeTab$ | async">
    <ng-container *ngSwitchCase="1">
      <app-football-live-scores
        *ngIf="liveStandings$ | async as liveStandings; else loading"
        [liveStandings]="liveStandings"></app-football-live-scores>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <app-football-live-games
        *ngIf="liveGames$ | async as liveGames; else loading"
        [model]="liveGames"></app-football-live-games>
    </ng-container>
  </ng-container>
</div>

<ng-template #loading>
  <div class="loading-indicator">
    <svgicon [icon]="'spinner'"></svgicon>
    <p>Loading</p>
  </div>
</ng-template>
