/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../common/video/video.component.ngfactory";
import * as i3 from "../../../common/video/video.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../no-consent/no-consent.component.ngfactory";
import * as i6 from "../no-consent/no-consent.component";
import * as i7 from "../../../../shared/service/didomi.service";
import * as i8 from "./apavideo.component";
var styles_ApaVideoComponent = [];
var RenderType_ApaVideoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ApaVideoComponent, data: {} });
export { RenderType_ApaVideoComponent as RenderType_ApaVideoComponent };
function View_ApaVideoComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ApaVideoComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "apaVideo"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ApaVideoComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "article-videoContainer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "videoComp", [], null, null, null, i2.View_VideoComponent_0, i2.RenderType_VideoComponent)), i0.ɵdid(6, 114688, null, 0, i3.VideoComponent, [i4.DomSanitizer], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.title !== ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.videoModel; _ck(_v, 6, 0, currVal_1); }, null); }
function View_ApaVideoComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i5.View_NoConsentComponent_0, i5.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i6.NoConsentComponent, [i7.DidomiService], { vendor: [0, "vendor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ApaVideoComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_ApaVideoComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["noContent", 2]], null, 0, null, View_ApaVideoComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.showApaVideo$)); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ApaVideoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "apavideo", [], null, null, null, View_ApaVideoComponent_0, RenderType_ApaVideoComponent)), i0.ɵdid(1, 245760, null, 0, i8.ApaVideoComponent, [i7.DidomiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApaVideoComponentNgFactory = i0.ɵccf("apavideo", i8.ApaVideoComponent, View_ApaVideoComponent_Host_0, { videoModel: "videoModel" }, {}, []);
export { ApaVideoComponentNgFactory as ApaVideoComponentNgFactory };
