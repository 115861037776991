import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {TogglePopupComponent} from '../../common/togglePopup.component';
import {CssClassService} from '../../../shared/service/cssClass.service';
import {SHARE_CLASS_MAP} from '../../../model/const/share';
import {GTMTriggerId} from '../../../model/enum/gtm';
import {HeaderContainerModel} from '../headerContainer.component';
import {PortalService} from '../../../shared/service/portal.service';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'menu',
  templateUrl: 'menu.component.html',
})
export class MenuComponent extends TogglePopupComponent implements OnInit {

  @Input() model: HeaderContainerModel;
  @Output() search = new EventEmitter();

  shareMap = SHARE_CLASS_MAP;
  gtmId = GTMTriggerId.menu;
  isKurier: boolean;

  constructor(private cd: ChangeDetectorRef,
              private cssService: CssClassService,
              private router: Router,
              public portalService: PortalService) {
    super();
    this.isKurier = this.portalService.currentPortal() === 'kurierat';
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      if (this.showPopUp) {
        this.toggleScrolling(false);
        this.showPopUp = false;
        this.cd.markForCheck();
      }
    });
  }

  toggleScrolling(show: boolean) {
    this.cssService.setBodyClass('no-scroll', show);
  }

  togglePopUp(): boolean {
    this.toggleScrolling(!this.showPopUp);
    return super.togglePopUp();
  }

  clickedInside($event) {
    if ($event.target.classList.contains('navigation-bg')) {
      return;
    }
    super.clickedInside($event);
  }

  toggleSearch() {
    this.search.emit();
    this.togglePopUp();
  }
}
