import { BehaviorSubject, Subject } from 'rxjs';
import { FootballService } from '@shared/service/football.service';
import { map, switchMap, tap } from 'rxjs/operators';
var FootballTableCupComponent = /** @class */ (function () {
    function FootballTableCupComponent(footballService) {
        this.footballService = footballService;
        this.currentRound = new Subject();
        this.heading = new BehaviorSubject('');
    }
    Object.defineProperty(FootballTableCupComponent.prototype, "currentRound$", {
        get: function () {
            return this.currentRound.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    FootballTableCupComponent.prototype.initModel = function (model) {
        var _this = this;
        var y = +model.data.footballSeason.split('/')[0];
        var year = y + '/' + (y + 1);
        if (model.data.footballLeague === 'world-cup' ||
            model.data.footballLeague === 'wc-qualification-europe' ||
            model.data.footballLeague === 'european-championship' ||
            model.data.footballLeague === 'uefa-women-euro') {
            year = year.split('/')[0];
        }
        if (model.data.footballLeague === 'world-cup') {
            this.table$ = this.footballService
                .fromWorldCupStandings$(year, model.data.footballGroup ? 'Group ' + model.data.footballGroup.toLocaleUpperCase() : undefined);
        }
        else if (model.data.footballCup || model.data.footballLeague === 'em_quali') {
            if (model.title) {
                this.heading.next(model.title);
            }
            this.table$ = this.footballService.getEmQualification(model.data.footballSeason, model.data.footballGroup);
        }
        else {
            this.rounds$ = this.footballService.getRoundsForSelection(model.data.footballLeague, year)
                .pipe(map(function (rounds) {
                if (model.data.footballLeague === 'uefa-nations-league') {
                    var firstStageId_1 = rounds[0].value.stage_id;
                    return rounds.filter(function (r) { return r.value.stage_id === firstStageId_1; }).reverse();
                }
                if (model.data.footballLeague === 'uefa-women-euro') {
                    var firstStageId_2 = rounds[0].value.stage_id;
                    return rounds.filter(function (r) { return r.value.stage_id === firstStageId_2; });
                }
                return rounds;
            }), tap(function (rounds) { return _this.currentRound.next(rounds[0].value); }));
            this.table$ = this.currentRound$.pipe(switchMap(function (round) {
                if (model.data.footballLeague === 'uefa-nations-league') {
                    return _this.footballService.fromNationsLeagueStandings$(year, round);
                }
                if (model.data.footballLeague === 'uefa-women-euro' && model.data.footballGroup) {
                    return _this.footballService.fromWomanCupStandings$(model.data.footballGroup, round, year);
                }
                return _this.footballService.fromCupStandings$(model.data.footballLeague, round, year);
            }), tap(function (table) { return _this.heading.next(table.heading); }), map(function (table) {
                if (model.data.footballGroup) {
                    if (model.data.footballLeague === 'uefa-nations-league') {
                        table.tables = table.tables.filter(function (t) {
                            var league = t.heading.split(' ')[1].toLocaleLowerCase();
                            var group = t.heading.split(' ')[3];
                            return model.data.footballGroup === league + group;
                        });
                    }
                    else {
                        if (model.data.footballLeague !== 'uefa-women-euro') {
                            table.tables = table.tables.filter(function (t) {
                                var group = t.heading.split(' ')[1];
                                return model.data.footballGroup.includes(group.toLocaleLowerCase());
                            });
                        }
                    }
                }
                return table;
            }));
        }
    };
    FootballTableCupComponent.prototype.changeRound = function (round) {
        this.currentRound.next(round.value);
    };
    return FootballTableCupComponent;
}());
export { FootballTableCupComponent };
