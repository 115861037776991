/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../common/ui/linkBar/linkBar.component.ngfactory";
import * as i3 from "../../../common/ui/linkBar/linkBar.component";
import * as i4 from "./titleBox.component";
var styles_TitleBoxComponent = [];
var RenderType_TitleBoxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TitleBoxComponent, data: {} });
export { RenderType_TitleBoxComponent as RenderType_TitleBoxComponent };
export function View_TitleBoxComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(3, 2), (_l()(), i0.ɵeld(4, 0, null, null, 1, "linkbar", [], null, null, null, i2.View_LinkBarComponent_0, i2.RenderType_LinkBarComponent)), i0.ɵdid(5, 49152, null, 0, i3.LinkBarComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.box.cssClass, "titleBox"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.linkbarModel; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_TitleBoxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "channelbranding", [], null, null, null, View_TitleBoxComponent_0, RenderType_TitleBoxComponent)), i0.ɵdid(1, 49152, null, 0, i4.TitleBoxComponent, [], null, null)], null, null); }
var TitleBoxComponentNgFactory = i0.ɵccf("channelbranding", i4.TitleBoxComponent, View_TitleBoxComponent_Host_0, {}, {}, []);
export { TitleBoxComponentNgFactory as TitleBoxComponentNgFactory };
