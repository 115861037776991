/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./football-live-games.component";
import * as i3 from "../../../../../shared/service/football.service";
var styles_FootballLiveGamesComponent = [];
var RenderType_FootballLiveGamesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FootballLiveGamesComponent, data: {} });
export { RenderType_FootballLiveGamesComponent as RenderType_FootballLiveGamesComponent };
function View_FootballLiveGamesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "footballLiveScores-game-info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "footballLiveScores-team local-team"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["class", "team-logo"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [["class", "team-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "footballLiveScores-versus"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["vs"])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "footballLiveScores-team"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "img", [["class", "team-logo"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "p", [["class", "team-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.context.ngIf[0].logo_path, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _v.context.ngIf[0].name, " logo"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.ngIf[0].name; _ck(_v, 4, 0, currVal_2); var currVal_3 = i0.ɵinlineInterpolate(1, "", _v.context.ngIf[1].logo_path, ""); var currVal_4 = i0.ɵinlineInterpolate(1, "", _v.context.ngIf[1].name, " logo"); _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_5 = _v.context.ngIf[1].name; _ck(_v, 11, 0, currVal_5); }); }
function View_FootballLiveGamesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "footballLiveScores"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "header", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "h2", [["class", "footballLiveScores-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "game-date"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["Today, ", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [["class", "game-location"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FootballLiveGamesComponent_3)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform(_co.getTeamsById(_v.context.$implicit.localteam_id, _v.context.$implicit.visitorteam_id))); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTime(_v.context.$implicit.time.starting_at.time); _ck(_v, 4, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.getVenueById(_v.context.$implicit.venue_id))); _ck(_v, 6, 0, currVal_1); }); }
function View_FootballLiveGamesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FootballLiveGamesComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.data; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FootballLiveGamesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FootballLiveGamesComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.model && _co.model.data); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FootballLiveGamesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-football-live-games", [], null, null, null, View_FootballLiveGamesComponent_0, RenderType_FootballLiveGamesComponent)), i0.ɵdid(1, 49152, null, 0, i2.FootballLiveGamesComponent, [i3.FootballService], null, null)], null, null); }
var FootballLiveGamesComponentNgFactory = i0.ɵccf("app-football-live-games", i2.FootballLiveGamesComponent, View_FootballLiveGamesComponent_Host_0, { model: "model" }, {}, []);
export { FootballLiveGamesComponentNgFactory as FootballLiveGamesComponentNgFactory };
