var _a;
import { AdDimensions } from '../enum/ad';
export var GPT_ID = '/39025507/';
export var AD_DIMENSIONS = (_a = {},
    _a[AdDimensions.dim_1x1] = [1, 1],
    _a[AdDimensions.dim_1x2] = [1, 2],
    _a[AdDimensions.dim_1x10] = [1, 10],
    _a[AdDimensions.dim_100x600] = [100, 600],
    _a[AdDimensions.dim_120x600] = [120, 600],
    _a[AdDimensions.dim_160x600] = [160, 600],
    _a[AdDimensions.dim_300x50] = [300, 50],
    _a[AdDimensions.dim_300x169] = [300, 169],
    _a[AdDimensions.dim_300x250] = [300, 250],
    _a[AdDimensions.dim_300x251] = [300, 251],
    _a[AdDimensions.dim_300x200] = [300, 200],
    _a[AdDimensions.dim_300x600] = [300, 600],
    _a[AdDimensions.dim_300x601] = [300, 601],
    _a[AdDimensions.dim_320x50] = [320, 50],
    _a[AdDimensions.dim_400x800] = [400, 800],
    _a[AdDimensions.dim_468x60] = [468, 60],
    _a[AdDimensions.dim_500x1000] = [500, 1000],
    _a[AdDimensions.dim_620x50] = [620, 50],
    _a[AdDimensions.dim_620x100] = [620, 100],
    _a[AdDimensions.dim_728x90] = [728, 90],
    _a[AdDimensions.dim_960x250] = [960, 250],
    _a[AdDimensions.dim_960x100] = [960, 100],
    _a[AdDimensions.dim_480x270] = [480, 270],
    _a[AdDimensions.dim_400x300] = [400, 300],
    _a[AdDimensions.dim_fluid] = ['fluid'],
    _a);
