<div class="noConsent">
  <img
    *ngIf="image || img"
    [class.isBlurred]="image"
    [src]="image ? image : '/assets/images/placeholder_img/paragraphs/' + img" alt="{{embed}} Platzhalter.">
  <div class="noConsent-content">
    <p *ngIf="!text">
      Wir würden hier gerne {{embed}} zeigen. Leider haben Sie uns hierfür keine Zustimmung gegeben. Wenn Sie diesen
      anzeigen wollen, stimmen sie bitte {{vendorName}} zu.
    </p>
    <p *ngIf="text" [innerText]="text"></p>
    <button class="button hasBackground" (click)="acceptVendor()">
      {{vendorName}} akzeptieren
    </button>
  </div>
</div>

