export enum ImageType {
  // Don't remove Placeholder since 0 = false
  PLACEHOLDER,
  LS_logo,
  POSTER,
  SQ,
  SQX,
  LS_16_9,
  LS_16_9_X,
  VARIABLE,
  FULLWIDTH,
}

export enum ImageW {
  w_24 = '24w',
  w_48 = '48w',
  w_80 = '80w',
  w_160 = '160w',
  w_300 = '300w',
  w_616 = '616w',
  w_932 = '932w',
  w_1232 = '1232w',
  w_1440 = '1440w',
  w_1864 = '1864w',
  w_1920 = '1920w',
  // Portrait
  p_198 = '140w',
  p_396 = '280w',
  p_339 = '240w',
  p_678 = '480w',
  // Height
  h_24 = '24h',
  h_48 = '48h',
  // x
  x1 = '1x',
  x1_5 = '1.5x',
  x2 = '2x',
  x3 = '3x',
}

export enum ImageSrc {
  // LANDSCAPE 16:9
  LS_300 = 'cfs_landscape_300w_169h',
  LS_616 = 'cfs_landscape_616w_347h',
  LS_932 = 'cfs_landscape_932w_524h',
  LS_1232 = 'cfs_landscape_1232w_693h',
  LS_1864 = 'cfs_landscape_1864w_1049h',
  // VARIABLE
  V_300 = 'cfs_300w',
  V_616 = 'cfs_616w',
  V_932 = 'cfs_932w',
  V_1232 = 'cfs_1232w',
  V_1864 = 'cfs_1864w',
  // FULLWIDTH SLIDER
  WH_1920_640 = 'cfs_landscape_3_1_1920w_640h',
  WH_1440_480 = 'cfs_landscape_3_1_1440w_480h',
  WH_1232_410 = 'cfs_landscape_3_1_1232w_410h',
  // SQUARE
  SQ_24 = 'cfs_square_24',
  SQ_48 = 'cfs_square_48',
  SQ_80 = 'cfs_square_80',
  SQ_160 = 'cfs_square_160',
  SQ_300 = 'cfs_square_300',
  SQ_616 = 'cfs_square_616',
  SQ_932 = 'cfs_square_932',
  SQ_1232 = 'cfs_square_1232',
  SQ_1864 = 'cfs_square_1864',
  // PORTRAIT
  PR_32 = 'cfs_portrait_32h',
  PR_64 = 'cfs_portrait_64h',
  // POSTER
  PO_198 = 'cfs_poster_140w_198h',
  PO_396 = 'cfs_poster_280w_396h',
  PO_339 = 'cfs_poster_240w_339h',
  PO_678 = 'cfs_poster_480w_678h',
}
