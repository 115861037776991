/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/image/image.component.ngfactory";
import * as i2 from "../../../common/image/image.component";
import * as i3 from "../../../../shared/service/image.service";
import * as i4 from "../../../../shared/service/overlay.service";
import * as i5 from "../../../../shared/directive/portalRoute.directive";
import * as i6 from "@angular/router";
import * as i7 from "../../../../shared/service/portal.service";
import * as i8 from "../../../../shared/service/browser.ref";
import * as i9 from "../../../common/gtmTrigger.directive";
import * as i10 from "@angular/common";
import * as i11 from "../../../common/slider/slider.component.ngfactory";
import * as i12 from "../../../common/slider/slider.component";
import * as i13 from "../../../../shared/service/tokens";
import * as i14 from "../../../../shared/service/slider.service";
import * as i15 from "./posterSlider.component";
import * as i16 from "../../../../shared/service/teasers.service";
var styles_PosterSliderComponent = [];
var RenderType_PosterSliderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PosterSliderComponent, data: {} });
export { RenderType_PosterSliderComponent as RenderType_PosterSliderComponent };
function View_PosterSliderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "picimage", [], null, null, null, i1.View_ImageComponent_0, i1.RenderType_ImageComponent)), i0.ɵdid(1, 638976, null, 0, i2.ImageComponent, [i3.ImageService, i4.OverlayService, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.image; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PosterSliderComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "image"]], null, null, null, null, null))], null, null); }
function View_PosterSliderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["sliderItem", 1]], null, 9, "div", [["class", "slider-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "a", [], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 540672, null, 0, i5.PortalRouteDirective, [i6.Router, i0.ElementRef, i7.PortalService, i8.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), i0.ɵdid(3, 81920, null, 0, i9.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PosterSliderComponent_3)), i0.ɵdid(5, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PosterSliderComponent_4)), i0.ɵdid(7, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "h2", [["class", "slider-title"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.link; _ck(_v, 2, 0, currVal_2); var currVal_3 = _v.context.$implicit.gtmId; _ck(_v, 3, 0, currVal_3); var currVal_4 = _v.context.$implicit.image; _ck(_v, 5, 0, currVal_4); var currVal_5 = !_v.context.$implicit.image; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_6 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.title, ""); _ck(_v, 8, 0, currVal_6); var currVal_7 = _v.context.$implicit.title; _ck(_v, 9, 0, currVal_7); }); }
function View_PosterSliderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "slider", [], null, null, null, i11.View_SliderComponent_0, i11.RenderType_SliderComponent)), i0.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(4, 3391488, null, 1, i12.SliderComponent, [i13.GLOBAL_STYLES, i0.PLATFORM_ID, i0.ChangeDetectorRef, i7.PortalService, i14.SliderService], { sliderConfigModel: [0, "sliderConfigModel"] }, null), i0.ɵqud(603979776, 1, { sliderItems: 1 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_PosterSliderComponent_2)), i0.ɵdid(7, 278528, null, 0, i10.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.boxModel.cssClass; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.sliderConfigModel; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.ngIf; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_PosterSliderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_PosterSliderComponent_1)), i0.ɵdid(1, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i10.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.data$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PosterSliderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "posterslider", [], null, null, null, View_PosterSliderComponent_0, RenderType_PosterSliderComponent)), i0.ɵdid(1, 49152, null, 0, i15.PosterSliderComponent, [i16.TeasersService], null, null)], null, null); }
var PosterSliderComponentNgFactory = i0.ɵccf("posterslider", i15.PosterSliderComponent, View_PosterSliderComponent_Host_0, {}, {}, []);
export { PosterSliderComponentNgFactory as PosterSliderComponentNgFactory };
