import {ErrorHandler, Inject, Injector} from '@angular/core';
import {BrowserRef} from './browser.ref';
import {PROD_MODE} from './tokens';
import {TrackJS} from 'trackjs';

export class TrackJsErrorHandler extends ErrorHandler {

  protected active: boolean;

  constructor(@Inject(PROD_MODE) prodmode: boolean,
              protected browserRef: BrowserRef,
              protected injector: Injector) {
    super();

    const ts = new Date().getTime();
    this.active = prodmode && (ts % 10 === 0);

    if (!this.active) {
      return;
    }

    TrackJS.install({
      token: '9e6170e5e6604002b5557225dadc3c10',
      application: 'hermes-cfs',
    });
  }

  handleError(error: any) {
    if (this.active) {
      const trackedError = error.originalError || error;
      TrackJS.track(trackedError);
    } else {
      super.handleError(error);
    }
  }

}
