import {IWindow} from './window/window';
import {INavigator} from './window/navigator';
import {IDocument} from './window/document';

export abstract class BrowserRef {

  abstract get document(): IDocument;

  abstract get navigator(): INavigator;

  abstract get window(): IWindow;
}
