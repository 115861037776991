/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/teaser/teaser.directive";
import * as i2 from "@angular/common";
import * as i3 from "./multi-teaser.component";
import * as i4 from "../../../../shared/service/portal.service";
import * as i5 from "../../../../shared/service/teasers.service";
var styles_MultiTeaserComponent = [];
var RenderType_MultiTeaserComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MultiTeaserComponent, data: {} });
export { RenderType_MultiTeaserComponent as RenderType_MultiTeaserComponent };
function View_MultiTeaserComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(1, 540672, null, 0, i1.TeaserDirective, [i0.ViewContainerRef, i0.ComponentFactoryResolver], { model: [0, "model"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_MultiTeaserComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "section", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MultiTeaserComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.model; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("multiTeaser teasers-" + _co.model.length); _ck(_v, 0, 0, currVal_0); }); }
export function View_MultiTeaserComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_MultiTeaserComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.length; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MultiTeaserComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "multiteaser", [], null, null, null, View_MultiTeaserComponent_0, RenderType_MultiTeaserComponent)), i0.ɵdid(1, 180224, null, 0, i3.MultiTeaserComponent, [i4.PortalService, i5.TeasersService, i0.ChangeDetectorRef], null, null)], null, null); }
var MultiTeaserComponentNgFactory = i0.ɵccf("multiteaser", i3.MultiTeaserComponent, View_MultiTeaserComponent_Host_0, { model: "model" }, {}, []);
export { MultiTeaserComponentNgFactory as MultiTeaserComponentNgFactory };
