import { Router } from '@angular/router';
import { PortalService } from '../../shared/service/portal.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/service/portal.service";
import * as i2 from "@angular/router";
var KurieratGuard = /** @class */ (function () {
    function KurieratGuard(portalService, router) {
        this.portalService = portalService;
        this.router = router;
    }
    KurieratGuard.prototype.canActivate = function () {
        if (this.portalService.hostname() === 'kurier.at') {
            return true;
        }
        else {
            this.router.navigate(['/']);
            return false;
        }
    };
    KurieratGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KurieratGuard_Factory() { return new KurieratGuard(i0.ɵɵinject(i1.PortalService), i0.ɵɵinject(i2.Router)); }, token: KurieratGuard, providedIn: "root" });
    return KurieratGuard;
}());
export { KurieratGuard };
