import {NgModule} from '@angular/core';
import {HomeComponent} from './home.component';
import {CHANNEL_COMPS, ChannelModule} from './channel/channel.module';
import {SEARCH_COMPS, SearchModule} from './search/search.module';
import {ErrorComponent} from './error/error.component';
import {EmptyComponent} from './empty.component';
import {ContentGardenComponent} from './content/contentGarden/contentGarden.component';
import {SharedModule} from '../../shared/shared.module';
import {AD_COMPS, AdModule} from '../ads/ad.module';
import {HOME_COMMON_COMPS} from './common/homeCommon.module';
import {ARTICLE_COMPS, ArticleModule} from './content/content.module';

const HOME_COMPS = [
  HomeComponent,
  EmptyComponent,
  ErrorComponent,
  ContentGardenComponent,
];

@NgModule({
  imports: [
    AdModule,
    ChannelModule,
    ArticleModule,
    SearchModule,
    SharedModule,
  ],
  declarations: [
    ...HOME_COMPS
  ],
  exports: [
    ...HOME_COMPS
  ],
  entryComponents: [
    ...CHANNEL_COMPS,
    ...AD_COMPS,
    ...ARTICLE_COMPS,
    ...SEARCH_COMPS,
    ...HOME_COMPS,
    ...HOME_COMMON_COMPS,
  ]
})
export class HomeModule {
}
