export enum DynamicType {
  twitterTimeline = 'twitter_timeline',
  adArticlebottom = 'adArticlebottom',
  adFullbanner = 'adFullbanner',
  adInContent = 'adInContent',
  adMediumRectangle = 'adMediumRectangle',
  adMediumRectangleSticky = 'adMediumRectangleSticky',
  adMobileBanner = 'adMobileBanner',
  adResponsive = 'adResponsive',
  fakeAdArticlebottom = 'fakeAdArticlebottom',
  fakeAdFullbanner = 'fakeAdFullbanner',
  fakeAdInContent = 'fakeAdInContent',
  fakeAdMediumRectangle = 'fakeAdMediumRectangle',
  fakeAdMediumRectangleSticky = 'fakeAdMediumRectangleSticky',
  fakeAdMobileBanner = 'fakeAdMobileBanner',
  fakeAdResponsive = 'fakeAdResponsive',
  apa_video = 'apa_video',
  article = 'article',
  articleChannelRelateds = 'articleChannelRelateds',
  articleSectionRelateds = 'articleSectionRelateds',
  authorHeader = 'authorHeader',
  titleBox = 'titleBox',
  contentGardenArticle = 'contentGardenArticle',
  contentGardenTeaser = 'contentGardenTeaser',
  doubleTeaser = 'doubleTeaser',
  dividerLink = 'dividerLink',
  departmentblock = 'departmentblock',
  divider = 'divider',
  error = 'error',
  facebook_post = 'facebook_post',
  facebook_video = 'facebook_video',
  facebook_page = 'facebook_page',
  filmatProgram = 'filmatProgram',
  filmatReview = 'filmatReview',
  gallery = 'gallery',
  gallerySlider = 'gallerySlider',
  google_map = 'google_map',
  iframe = 'iframe',
  image = 'image',
  immoSearch = 'immoSearch',
  instagram = 'instagram',
  jobSearch = 'jobSearch',
  link = 'link',
  linkTeaser = 'linkTeaser',
  link_teaser = 'link_teaser',
  liveblog = 'liveblog',
  longList = 'longList',
  movieDetail = 'movieDetail',
  seriesDetail = 'seriesDetail',
  movieList = 'movieList',
  multiSlider = 'multiSlider',
  outbrain = 'outbrain',
  optaSport = 'optaSport',
  pinpoll = 'pinpoll',
  pinterest_board = 'pinterest_board',
  pinterest_pin = 'pinterest_pin',
  pinterest_profile = 'pinterest_profile',
  posterSlider = 'posterSlider',
  quiz = 'quiz',
  quote = 'quote',
  riddle = 'riddle',
  screeningsBlock = 'screeningsBlock',
  scrollList = 'scrollList',
  searchHeader = 'searchHeader',
  searchResults = 'searchResults',
  searchResultTopics = 'searchResultTopics',
  singleSlider = 'singleSlider',
  singleTeaser = 'singleTeaser',
  soundcloud = 'soundcloud',
  storify = 'storify',
  story = 'story',
  teaser = 'teaser',
  text = 'text',
  theaterDetail = 'theaterDetail',
  topicPageHeader = 'topicPageHeader',
  topStories = 'topStories',
  toptopics = 'toptopics',
  tripleArticleBox = 'tripleArticleBox',
  twitter = 'twitter',
  videoPlaylist = 'videoPlaylist',
  vimeo = 'vimeo',
  youtube = 'youtube',
  infobox = 'infobox',
  beforeAfterSlider = 'before_after_slider',
  romyVoting = 'romyVoting',
  textBox = 'textBox',
  newsletterSignUp = 'newsletter_signup',
  cleverpushbutton = 'push_button',
  degrees23 = '23degrees',
  reddit = 'reddit_post',
  podigee = 'podigee',
  tikTok = 'tiktok',
  flourish = 'flourish',
  paywall = 'paywall',
  footballTable = 'football_table',
  footballLiveStanding = 'football_live_standing',
  footballStages = 'football_cup_playoff',
  footballCupTable = 'football_cup_table',
  personMovieList = 'personMovieList',
  typedEmbed = 'embed',
  adPiano = 'adPiano',
  textLink = 'textLink',
  teaserSlider = 'teaserSlider',
  singleSlider2 = 'singleSlider2',
  gallerySlider2 = 'gallerySlider2',
  linkTeaser2 = 'linkTeaser2',
  title = 'title',
}
