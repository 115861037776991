import {ImageModel} from '../../component/image';
import {IBeforeAfterSlider} from './payload';
import {ImageType} from '../../enum/image';

export class BeforeAfterSliderModel {
  aspectRatio: number;
  img1?: ImageModel;
  img2?: ImageModel;
  caption: string;

  static deserialize(data: IBeforeAfterSlider): BeforeAfterSliderModel {
    const slider = new BeforeAfterSliderModel();
    const ratio1: [number, number] = [+data.data.images[0].width, +data.data.images[0].height];
    const ratio2: [number, number] = [+data.data.images[1].width, +data.data.images[1].height];
    const smallerRatio = this.setRatio(ratio1) > this.setRatio(ratio2) ? this.setRatio(ratio2) : this.setRatio(ratio1);
    const img1 = new ImageModel(data.data.images[0], ImageType.VARIABLE);
    const img2 = new ImageModel(data.data.images[1], ImageType.VARIABLE);
    img1.description = null;
    img2.description = null;
    slider.aspectRatio = smallerRatio;
    slider.img1 = img1;
    slider.img2 = img2;
    const copyrightImg1 = img1.copyright ? '© ' + img1.copyright : '';
    const copyrightImg2 = img2.copyright ? '© ' + img2.copyright : '';
    const caption = `
      <div>
        <p>Bild links: ${copyrightImg1}</p>
        ${img1.description ? img1.description : ''}
      </div>
      <div>
        <p>Bild rechts: ${copyrightImg2}</p>
        ${img2.description ? img2.description : ''}
      </div>
    `;
    if (copyrightImg1 || copyrightImg2 ) {
      slider.caption = caption;
    }
    return slider;
  }

  static setRatio(ratio: [number, number]): number {
    const height = ratio[1];
    const width = ratio[0];
    return (height / width) * 100;
  }
}
