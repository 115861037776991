import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CookieService {

  constructor(@Inject(DOCUMENT) protected document) {
  }

  public getCookie(cname): string {
    const re = new RegExp('[; ]' + cname + '=([^\\s;]*)');
    const sMatch = (' ' + this.document.cookie).match(re);
    if (cname && sMatch) {
      return decodeURIComponent(sMatch[1]);
    }
    return '';
  }

  public setCookie(cname: string, value: string, days: number): void {
    const date = new Date();
    date.setHours(date.getHours() + (24 * days));
    // tslint:disable-next-line:no-string-literal
    const gmt = date['toGMTString']();
    this.document.cookie = cname + '=' + value + '; expires=' + gmt + '; path=/';
  }

  public deleteCookie(cname: string): void {
    document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

}
