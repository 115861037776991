import {DynamicComponent} from '../../../common/dynamicComponent';
import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {TeasersService} from '../../../../shared/service/teasers.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IVideoPlaylist} from '../../../../model/channel/videoPlayList/payload';
import {VideoPlaylistItem} from '../../../../model/channel/videoPlayList/videoPlaylist';
import {IMovieSerie} from '../../../../model/movieSerie/payload';
import {LinkBarModel} from '../../../../model/component/linkBar';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'videoPlaylist',
  templateUrl: 'videoPlaylist.component.html'
})
export class VideoPlaylistComponent implements DynamicComponent<IVideoPlaylist> {

  teasers$: Observable<VideoPlaylistItem[]>;
  activeVideo: VideoPlaylistItem;
  linkBar: LinkBarModel;
  cssClass: string;

  constructor(public teaserService: TeasersService) {
  }

  initModel(model: IVideoPlaylist): void {
    this.linkBar = new LinkBarModel(model.title, null, true);
    this.cssClass = 'bs-c-' + model.color;
    this.teasers$ = this.teaserService.getCollection(model.collectionName, +model.numberOfTeasers)
      .pipe(
        map((res: IMovieSerie[]) => {
          // TODO remove filter when backend is not broken anymore
          const videoList = VideoPlaylistItem.deserialize(res.filter(teaser => {
            return teaser.teaser_img || teaser.poster;
          }));
          this.activeVideo = videoList[0].setActiveVideo();
          return videoList;
        })
      );
  }

  setVideo(teaser: VideoPlaylistItem) {
    this.activeVideo = teaser.setActiveVideo();
  }

}
