<div class="movieDetail">
  <div class="movieDetail-header">
    <h1 class="movieDetail-title">{{model.title}}</h1>
    <p class="movieDetail-ovTitle">{{model.ovTitle}}</p>
    <p class="movieDetail-country">
      {{model.releaseCountry}}
      {{model.releaseYear}}</p>
    <p class="movieDetail-tags">
      <a class="movieDetail-tag type">{{model.typeBadge}}</a>
      <span>
        <a class="movieDetail-tag" *ngFor="let badge of model.badges" [gtm]="badge.gtmId"
           [portalRouterLink]="badge.link">{{badge.name}}</a>
      </span>
    </p>
  </div>

  <picimage *ngIf="model.teaserImage" class="movieDetail-headerImg" [model]="model.teaserImage"></picimage>

  <div class="movieDetail-main">

    <div class="movieDetail-main-header">

      <p>{{model.text}}</p>

      <socialbar [model]="model.shareModel"></socialbar>

      <div>

        <div class="movieDetail-card">
          <picimage *ngIf="model.poster" class="movieDetail-poster" [model]="model.poster"></picimage>
          <div class="movieDetail-baseInfo">
            <p *ngIf="model.seasonEpisodes"><span>Staffeln / Episoden</span> <span>{{model.seasonEpisodes}}</span></p>
            <p *ngIf="model.playTime"><span>Min.</span> <span>{{model.playTime}}</span></p>
            <p *ngIf="model.releaseDate"><span>Start</span> <span>{{model.releaseDate}}</span></p>
            <p *ngIf="model.classification"><span>ab</span> <span>{{model.classification}}+</span></p>
          </div>
        </div>

        <div class="movieDetail-desc">
          <ng-container dynamicComponent *ngFor="let paragraph of model.paragraphs"
                        [model]="paragraph"></ng-container>
        </div>

        <div class="movieDetail-rating">
          <p *ngIf="model.predicate" class="movieDetail-predicate">Filmprädikat: <span>{{model.predicate}}</span></p>
          <p *ngIf="model.imdbRating" class="movieDetail-imdbID">IMDb: <span>{{model.imdbRating}}</span></p>
          <!--TODO-->
          <!--<p class="movieDetail-stars">Sterne</p>-->
        </div>

      </div>
    </div>

    <div class="movieProviders">
      <h2 class="movieProviders-title">Kaufen & Leihen</h2>
      <table *ngIf="model.providers && model.providers.length; else noproviders"
             class="movieProviders-table">
        <tr class="movieProviders-table-header">
          <th class="movieProviders-table-header-cell">Anbieter</th>
          <th class="movieProviders-table-header-cell">Resolution</th>
          <th class="movieProviders-table-header-cell">Preis</th>
          <th class="movieProviders-table-header-cell"></th>
        </tr>
        <ng-container *ngFor="let provider of model.providers">
          <tr class="movieProviders-table-body">
            <td class="movieProviders-table-cell">
              <img class="provider-logo {{provider.class}}" src="{{provider.logo}}" alt="logo">
              <p class="provider-name">{{provider.name}}</p>
            </td>
            <td class="movieProviders-table-cell">{{provider.resolution}}</td>
            <td class="movieProviders-table-cell">€ {{provider.price}}</td>
            <td class="movieProviders-table-cell provider-url">
              <a class="link-tag" href="{{provider.url}}" target="_blank">
                <span class="desktop">Zum Angebot</span>
                <svgicon [icon]="'angle-right'"></svgicon>
              </a>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>

    <ng-template #noproviders>
      <p class="no-providers">Leider konnten wir keine Streaming-Angebote für {{model.title}} finden.</p>
    </ng-template>

    <accordeon [title]="'Details'">
      <ul class="movieDetail-details">
        <li *ngFor="let detail of model.details">
          <p>
            <span class="movieDetail-details-key">
              {{detail.key}}:</span>
            <span class="movieDetail-details-value">
              <ng-container *ngFor="let actor of detail.value; let last = last;">
                <a [portalRouterLink]="actor.link">
                {{actor.name}}</a><ng-container *ngIf="!last">, </ng-container>
              </ng-container>
            </span>
          </p>
        </li>
        <li *ngIf="model.website">
          <p>
            <span class="movieDetail-details-key">
              Webseite:</span>
            <span class="movieDetail-details-value">
              <a [href]="model.website" target="_blank">{{model.website}}</a>
            </span>
          </p>
        </li>
      </ul>
    </accordeon>

    <accordeon
      [isOpen]="true"
      *ngIf="model.seasons"
      [title]="'Episodenguide'"
      [contentClass]="'episodeGuide'">
      <tabbar [model]="model.seasons" (tabChanged)="changeSeason($event)"></tabbar>
      <div class="episodeGuide-details">
        <div class="episodeGuide-summery" [innerHTML]="currentSeason.text"></div>
        <accordeon
          *ngFor="let episode of currentSeason.episodes, let i = index"
          [title]="'Folge ' + (i + 1) + ' - ' + episode.title"
          [contentClass]="'accordeon-episode'">
          <div [innerHTML]="episode.text"></div>
        </accordeon>
      </div>
    </accordeon>

    <accordeon *ngIf="reviews$ | async as reviews"
               [isOpen]="true"
               [title]="'Filmkritik und News'" [contentClass]="'critiques'">
      <ng-container *ngFor="let model of reviews">
        <ng-container teaser
                      *ngIf="model.title"
                      [model]="model"></ng-container>
      </ng-container>
    </accordeon>
    <accordeon
      *ngIf="!model.seasons"
      [title]="'Vorstellungen'">
      <ng-container dynamicComponent [model]="model.screenings"></ng-container>
    </accordeon>
    <accordeon *ngIf="model.gallery" [contentClass]="'imgGallery'" [title]="'Bilder'">
      <ng-container dynamicComponent [model]="model.gallery" [position]="'center'"></ng-container>
    </accordeon>

    <outbrain *ngIf="outbrainModel" [model]="outbrainModel"></outbrain>

    <accordeon
      [isOpen]="true"
      [title]="'Kommentare'">
      <app-coral-comments></app-coral-comments>
    </accordeon>

    <ng-container *ngIf="model.movieSlider">
      <ng-container dynamicComponent [model]="model.movieSlider"></ng-container>
    </ng-container>

    <ng-container *ngIf="relatedArticles$ | async as related">
      <div class="movieDetail-relateds">
        <h2>Artikel hierzu</h2>
        <ng-container teaser
                      *ngFor="let model of related"
                      [model]="model"></ng-container>
      </div>
    </ng-container>

    <socialbar [model]="model.shareModel"></socialbar>

  </div>
</div>
