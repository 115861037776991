import { AfterContentChecked, AfterContentInit, ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit, QueryList } from '@angular/core';
import { GTMTriggerId } from '@model/enum/gtm';
import { SliderConfigModel } from '@model/component/sliderConfig';
import { BehaviorSubject, Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { PortalService } from '@shared/service/portal.service';
import { SliderService } from '@shared/service/slider.service';
var ButtonDisabledState;
(function (ButtonDisabledState) {
    ButtonDisabledState[ButtonDisabledState["none"] = 0] = "none";
    ButtonDisabledState[ButtonDisabledState["start"] = 1] = "start";
    ButtonDisabledState[ButtonDisabledState["end"] = 2] = "end";
})(ButtonDisabledState || (ButtonDisabledState = {}));
var SliderComponent = /** @class */ (function () {
    function SliderComponent(stylesService, platformId, ref, portalService, sliderService) {
        this.stylesService = stylesService;
        this.platformId = platformId;
        this.ref = ref;
        this.portalService = portalService;
        this.sliderService = sliderService;
        this.currSlideNumber = new EventEmitter();
        this.currSlide$ = new BehaviorSubject(1);
        this.buttonDisabled = new BehaviorSubject(ButtonDisabledState.none);
        this.buttonDisabledState = ButtonDisabledState;
        this.animationChange = false;
        this.start = true;
        this.intersectionRatio = 0.75;
        this.gtmId = GTMTriggerId.teaser_headline;
        // Special shit for adaptiveHeight Slider
        this.initialX = 0;
        this.initialY = 0;
        this.focus = new BehaviorSubject(false);
        this.subscription = new Subscription();
    }
    Object.defineProperty(SliderComponent.prototype, "transPos", {
        get: function () {
            var currSlide = this.currSlide$.value - 1;
            return currSlide * -100 + '%';
        },
        enumerable: true,
        configurable: true
    });
    SliderComponent.prototype.ngOnInit = function () {
        this.stylesService.sliderPolyfill();
        // Special shit for adaptiveHeight Slider
        if (this.sliderConfigModel.adaptiveHeight) {
            var slider = this.slider.nativeElement;
            slider.addEventListener('touchstart', this.startTouch.bind(this), false);
            slider.addEventListener('touchmove', this.moveTouch.bind(this), false);
        }
        if (this.portalService.currentPortal() === 'lustaufoesterreichat') {
            this.setAutoSlide();
            this.setKeyBoardNavigation();
        }
    };
    SliderComponent.prototype.setKeyBoardNavigation = function () {
        var _this = this;
        this.subscription.add(this.focus.asObservable().subscribe(function (focus) {
            if (focus) {
                window.addEventListener('keydown', function (event) {
                    if (event.key === 'ArrowRight') {
                        _this.scrollTo(false);
                    }
                    else if (event.key === 'ArrowLeft') {
                        _this.scrollTo(true);
                    }
                    _this.ref.markForCheck();
                });
            }
        }));
    };
    SliderComponent.prototype.setAutoSlide = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId)) {
            this.interval = window.setInterval(function () {
                _this.scrollTo(false);
                if (_this.currSlide$.value === _this.sliderItems.length) {
                    _this.currSlide$.next(1);
                }
                _this.ref.markForCheck();
            }, 5000);
        }
    };
    SliderComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId)) {
            this.currSlide$.subscribe(function (val) {
                _this.currSlideNumber.emit(val);
                _this.setSliderHeight(val);
            });
            this.sliderTotalCount = this.sliderItems.length;
            var options = {
                root: this.slider.nativeElement,
                rootMargin: '0px',
                threshold: this.intersectionRatio
            };
            this.observer = new IntersectionObserver(function (entries) {
                if (entries.length) {
                    var intersecting_1 = entries.filter(function (entry) { return entry.isIntersecting === true; });
                    if (intersecting_1[0]) {
                        _this.sliderItems.forEach(function (item, index) {
                            if (item.nativeElement === intersecting_1[0].target) {
                                _this.slideItemWidth = intersecting_1[0].target.clientWidth;
                                if (intersecting_1[0].intersectionRatio >= _this.intersectionRatio) {
                                    _this.currSlide$.next(index + 1);
                                    _this.setButtonDisabledState();
                                }
                            }
                        });
                    }
                }
            }, options);
            this.sliderItems.forEach(function (item) {
                _this.observer.observe(item.nativeElement);
            });
            // scroll to starting Point if defined in model
            var start_1 = this.sliderConfigModel.start;
            if (window.location.pathname === '/romy') {
                start_1 = this.sliderService.previousSlideValue;
            }
            if (start_1) {
                var slider_1 = this.slider.nativeElement;
                setTimeout(function () {
                    slider_1.scroll({ top: 0, left: start_1 });
                }, 100);
            }
            var slideIndex_1 = this.sliderConfigModel.slideIndexStart;
            if (slideIndex_1) {
                var iteration_1 = 0;
                var interval_1 = setInterval(function () {
                    iteration_1++;
                    _this.scrollTo(false);
                    if (iteration_1 === slideIndex_1) {
                        clearInterval(interval_1);
                    }
                    else if (iteration_1 <= 0) {
                        clearInterval(interval_1);
                    }
                }, 1000);
            }
        }
    };
    SliderComponent.prototype.ngAfterContentChecked = function () {
        this.setSliderHeight(this.currSlide$.value);
    };
    SliderComponent.prototype.setSliderHeight = function (slideNumber) {
        if (this.sliderConfigModel.adaptiveHeight === true) {
            var currentElement = this.sliderItems.find(function (el, i) {
                return i === slideNumber - 1;
            });
            var height = currentElement.nativeElement.offsetHeight;
            if (this.sliderHeight !== height) {
                this.animationChange = !this.animationChange;
                this.sliderHeight = height;
                this.ref.detectChanges();
            }
        }
    };
    SliderComponent.prototype.setButtonDisabledState = function () {
        var _this = this;
        if (!this.sliderConfigModel.adaptiveHeight) {
            var slider_2 = this.slider.nativeElement;
            setTimeout(function () {
                if (slider_2.scrollLeft === 0) {
                    _this.buttonDisabled.next(ButtonDisabledState.start);
                }
                else if (slider_2.scrollWidth <= Math.ceil(slider_2.scrollLeft + slider_2.offsetWidth)) {
                    _this.buttonDisabled.next(ButtonDisabledState.end);
                }
                else {
                    _this.buttonDisabled.next(ButtonDisabledState.none);
                }
                _this.ref.markForCheck();
            }, 500);
        }
    };
    // Function for next prev click
    SliderComponent.prototype.scrollTo = function (direction) {
        var slider = this.slider.nativeElement;
        var stepSize = this.sliderConfigModel.stepsize;
        var amount = this.slideItemWidth;
        var currPos = slider.scrollLeft;
        var overage = currPos % amount;
        if (stepSize) {
            amount = slider.offsetWidth * (stepSize / 100);
            overage = 0;
        }
        var currSnapPos = currPos - overage;
        if (!this.sliderConfigModel.adaptiveHeight) {
            if (direction) {
                slider.scroll({ top: 0, left: currSnapPos - amount, behavior: 'smooth' });
                this.sliderService.previousSlideValue = currSnapPos - amount;
            }
            else {
                slider.scroll({ top: 0, left: currSnapPos + amount, behavior: 'smooth' });
                this.sliderService.previousSlideValue = currSnapPos + amount;
            }
        }
        else {
            if (direction) {
                if (this.currSlide$.value > 1) {
                    this.currSlide$.next(this.currSlide$.value - 1);
                }
            }
            else {
                if (this.currSlide$.value < this.sliderTotalCount) {
                    this.currSlide$.next(this.currSlide$.value + 1);
                }
            }
        }
    };
    SliderComponent.prototype.startTouch = function (e) {
        this.initialX = e.touches[0].clientX;
        this.initialY = e.touches[0].clientY;
    };
    SliderComponent.prototype.moveTouch = function (e) {
        if (this.initialX === null) {
            return;
        }
        if (this.initialY === null) {
            return;
        }
        var currentX = e.touches[0].clientX;
        var currentY = e.touches[0].clientY;
        var diffX = this.initialX - currentX;
        var diffY = this.initialY - currentY;
        if (Math.abs(diffX) > Math.abs(diffY)) {
            if (diffX > 0) {
                this.scrollTo(false);
            }
            else {
                this.scrollTo(true);
            }
            e.preventDefault();
        }
        this.initialX = null;
        this.initialY = null;
    };
    SliderComponent.prototype.mouseEnter = function () {
        if (this.interval) {
            clearInterval(this.interval);
        }
    };
    SliderComponent.prototype.mouseLeave = function () {
        if (this.portalService.currentPortal() === 'lustaufoesterreichat') {
            this.setAutoSlide();
        }
    };
    SliderComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
        if (this.interval) {
            clearInterval(this.interval);
        }
    };
    SliderComponent.prototype.focusIn = function () {
        this.focus.next(true);
    };
    SliderComponent.prototype.focusOut = function () {
        this.focus.next(false);
    };
    return SliderComponent;
}());
export { SliderComponent };
