import {TimeAgoUtil} from '../../../util/timeAgo';
import {Author} from '../../author/author';
import {ILiveblogEntry} from './payload';
import {IGoogleMaps} from '../googleMaps/payload';
import {IDynamicComponent} from '../../payload';

export class LiveblogEntry {

  author: Author;
  html: string;
  id: string;
  paragraphs: IDynamicComponent[];
  published: string;
  title: string;
  description: string;
  highlight: string;

  static deserializeJson(json: ILiveblogEntry[]): LiveblogEntry[] {
    if (!json) {
      return [];
    }

    return json.map(item => {
      return LiveblogEntry.deserializeItem(item);
    });
  }

  static deserializeItem(item: ILiveblogEntry): LiveblogEntry {
    const liveblogEntry = new LiveblogEntry();
    liveblogEntry.html = item.html;
    liveblogEntry.id = item.id;
    liveblogEntry.title = item.title;
    if (item.highlight) {
      liveblogEntry.highlight = item.highlight;
    }

    const timeago = TimeAgoUtil.timeDifferencePublishedDate(item.date);
    const date = TimeAgoUtil.getDate(item.date);
    if (timeago === date) {
      liveblogEntry.published = TimeAgoUtil.getDate(item.date) + ', ' + TimeAgoUtil.getTime(item.date);
    } else {
      liveblogEntry.published = timeago;
    }

    liveblogEntry.paragraphs = !item.paragraphs ? [] : item.paragraphs.slice();
    if (item.location != null) {
      const gmaps: IGoogleMaps = {
        data: {
          address: item.location
        },
        type: 'google_map'
      };
      liveblogEntry.paragraphs.push(gmaps);
    }

    if (item.author) {
      liveblogEntry.author = Author.deserializeItem(item.author, '');
    }
    return liveblogEntry;
  }
}
