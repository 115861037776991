import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "@angular/router";
var RelatedService = /** @class */ (function () {
    function RelatedService(api, router) {
        this.api = api;
        this.router = router;
    }
    RelatedService.prototype.forArticle = function (docId, length) {
        return this.api.articleRelateds(docId, length).pipe(map(function (teaserC) { return teaserC.items; }));
    };
    RelatedService.prototype.forChannel = function (channelId, length) {
        return this.api.channelRelateds(channelId, length).pipe(map(function (teaserC) { return teaserC.items; }));
    };
    RelatedService.prototype.forSection = function (section, length) {
        return this.api.sectionRelateds(section, length).pipe(map(function (teaserC) { return teaserC.items; }));
    };
    RelatedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RelatedService_Factory() { return new RelatedService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.Router)); }, token: RelatedService, providedIn: "root" });
    return RelatedService;
}());
export { RelatedService };
