/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../common/image/image.component.ngfactory";
import * as i2 from "../../../common/image/image.component";
import * as i3 from "../../../../shared/service/image.service";
import * as i4 from "../../../../shared/service/overlay.service";
import * as i5 from "./inlineImg.component";
var styles_InlineImgComponent = [];
var RenderType_InlineImgComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InlineImgComponent, data: {} });
export { RenderType_InlineImgComponent as RenderType_InlineImgComponent };
export function View_InlineImgComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "picimage", [["class", "inlineImg"]], null, null, null, i1.View_ImageComponent_0, i1.RenderType_ImageComponent)), i0.ɵdid(1, 638976, null, 0, i2.ImageComponent, [i3.ImageService, i4.OverlayService, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageModel; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InlineImgComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "inlineImg", [], null, null, null, View_InlineImgComponent_0, RenderType_InlineImgComponent)), i0.ɵdid(1, 49152, null, 0, i5.InlineImgComponent, [], null, null)], null, null); }
var InlineImgComponentNgFactory = i0.ɵccf("inlineImg", i5.InlineImgComponent, View_InlineImgComponent_Host_0, {}, {}, []);
export { InlineImgComponentNgFactory as InlineImgComponentNgFactory };
