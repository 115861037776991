/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/pipe/safeHtml.pipe";
import * as i2 from "@angular/platform-browser";
import * as i3 from "./textParagraph.component";
var styles_TextParagraphComponent = [];
var RenderType_TextParagraphComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextParagraphComponent, data: {} });
export { RenderType_TextParagraphComponent as RenderType_TextParagraphComponent };
export function View_TextParagraphComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.SafeHtmlPipe, [i2.DomSanitizer]), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "paragraph"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v, 0), _co.html)); _ck(_v, 1, 0, currVal_0); }); }
export function View_TextParagraphComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "paragraph", [], null, null, null, View_TextParagraphComponent_0, RenderType_TextParagraphComponent)), i0.ɵdid(1, 49152, null, 0, i3.TextParagraphComponent, [], null, null)], null, null); }
var TextParagraphComponentNgFactory = i0.ɵccf("paragraph", i3.TextParagraphComponent, View_TextParagraphComponent_Host_0, {}, {}, []);
export { TextParagraphComponentNgFactory as TextParagraphComponentNgFactory };
