import {
  AfterViewInit,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {DynamicComponent} from '../../component/common/dynamicComponent';
import {DynamicComponentsService} from '../service/dynamicComponents.service';
import {GoogleTagManagerService} from '../service/gtm.service';
import {ITeaserVideo} from '../../model/teaser/payload';
import {OewaService} from '../service/oewa.service';
import {IDynamicComponent} from '../../model/payload';

@Directive({
  selector: '[videoTeaser]'
})
export class VideoTeaserDirective implements AfterViewInit, OnChanges {

  @Input('videoTeaser') teaserVideo: ITeaserVideo;
  @Input() autoplay: boolean;

  protected videoComponentRef: ComponentRef<DynamicComponent<IDynamicComponent>>;

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private vcRef: ViewContainerRef,
              private cd: ChangeDetectorRef,
              protected resolver: ComponentFactoryResolver,
              private oewaService: OewaService,
              protected dynamicComponentsService: DynamicComponentsService,
              protected gtm: GoogleTagManagerService) {
    this.autoplay = true;
  }

  ngAfterViewInit(): void {
    if (this.teaserVideo) {
      this.el.nativeElement.preventPortalRouterLink = true;

      this.renderer.listen(this.el.nativeElement, 'click', () => {
        this.createComp();
        return false;
      });
    }
  }

  private createComp() {
    const videoModel = {
      type: this.teaserVideo.bundle,
      data: this.teaserVideo
    };

    videoModel.data.autoplay = this.autoplay;
    this.vcRef.clear();
    this.videoComponentRef = this.dynamicComponentsService.create(this.resolver, this.vcRef, videoModel);
    // NOTE we can't remove this element because otherwise angular gets confused and doesn't display the new video either
    this.el.nativeElement.setAttribute('style', 'display: none;');

    const data = {
      video_type: this.teaserVideo.bundle,
      video_id: this.teaserVideo.videoid || this.teaserVideo.guid || 'undefined'
    };
    this.gtm.pushEvent('DA - click videoteaser', data);
    this.oewaService.pushCp('ui');
    this.cd.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.videoComponentRef) {
      this.createComp();
    }
  }

}
