import {Injectable} from '@angular/core';
import {BrowserRef} from './browser.ref';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectionService {

  constructor(private browserRef: BrowserRef) {
  }

  get isTablet(): boolean {
    return this.browserRef.window.matchMedia('(max-width: 768px)').matches;
  }

  get isTabletOnly(): boolean {
    return this.browserRef.window.matchMedia('(max-width: 768px) and (min-width: 640px)').matches;
  }

  get isMobile(): boolean {
    return this.browserRef.window.matchMedia('(max-width: 640px)').matches;
  }

  maxWidth(px: string): boolean {
    return this.browserRef.window.matchMedia(`(max-width: ${px})`).matches;
  }

  get isDesktop(): boolean {
    return this.browserRef.window.matchMedia('(min-width: 1025px)').matches;
  }

  get isTouch(): boolean {
    return 'ontouchstart' in this.browserRef.window // works on most browsers
      // || window.DocumentTouch && document instanceof DocumentTouch seems phased out
      || this.browserRef.navigator.maxTouchPoints > 0; // works on IE10 with some false positives
  }

  shouldRender(mobileOnly?: boolean, tabletDesktopOnly?: boolean, desktopOnly?: boolean): boolean {
    if (mobileOnly) {
      return this.isTablet;
    }
    if (tabletDesktopOnly || desktopOnly) {
      return !this.isTablet;
    }
    return true;
  }
}
