import {HostListener} from '@angular/core';

export class TogglePopupComponent {

  // tslint:disable-next-line:no-output-on-prefix
  showPopUp = false;
  private toggleActive = false;

  togglePopUp() {
    this.showPopUp = !this.showPopUp;
    this.toggleActive = true;
    return false;
  }

  @HostListener('document:click', ['$event'])
  clickedOutside($event) {
    if (this.showPopUp === true) {
      if ($event.button === 2) {
        return;
      }
      if (!this.toggleActive) {
        this.showPopUp = false;
      }
      this.toggleActive = false;
    }
  }

  clickedInside($event) {
    this.toggleActive = false;
    $event.stopPropagation();
  }
}
