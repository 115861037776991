<ng-container *ngIf="model$ | async">
<div *ngIf="screeningsModel.emptyMessage">
  {{screeningsModel.emptyMessage}}
</div>
<div class="filterList" *ngIf="!screeningsModel.emptyMessage">
  <linkbar [model]="linkBarModel"></linkbar>
  <div class="filterList-filters">
    <div class="filterList-filter"
         [ngClass]="['type-'+filter.type, 'filter-' + filter.label]"
         *ngFor="let filter of screeningsModel.filter"
         [ngSwitch]="filter.type">
      <div *ngSwitchCase="'select'">
        <formselect [label]="filter.label" [values]="filter.values" [(valueSelected)]="filter.valueSelected"
                    (valueSelectedChange)="onChangeSelect(filter)"></formselect>
      </div>
      <p class="checkbox" *ngSwitchCase="'checkbox'">
        <formcheckbox [label]="filter.label" [(value)]="filter.valueSelected" (valueChange)="onChangeCheckbox(filter)"></formcheckbox>
      </p>
      <ng-container *ngSwitchCase="'toggle'"></ng-container>
    </div>
  </div>

  <div *ngIf="screeningsModel.accordeon.length > 0; else simple">
    <accordeon [link]="acc.link" [title]="acc.text" *ngFor="let acc of screeningsModel.accordeon; let i = index">
      <div class="filterList-results">
        <ng-container *ngFor="let list of screeningsModel.list[i]">
          <div class="filterList-results-item">
            <div class="filterList-results-key"><a [portalRouterLink]="list.link">{{list.title}}</a></div>
            <div class="filterList-results-value" [innerHTML]="list.name"></div>
          </div>
        </ng-container>
      </div>
    </accordeon>
  </div>

  <ng-template #simple>
    <div class="filterList-results">
      <ng-container *ngFor="let list of screeningsModel.list[0]">
        <div class="filterList-results-item">
          <div class="filterList-results-key"><a [portalRouterLink]="list.link">{{list.title}}</a></div>
          <div class="filterList-results-value" [innerHTML]="list.name"></div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
</ng-container>
