import {Component} from '@angular/core';
import {AdModel} from '@model/ad/ad-model';
import {AdDimensions, AdSize} from '@model/enum/ad';
import {AD_DIMENSIONS} from '@model/const/ad';
import {DeviceDetectionService} from '@shared/service/device-detection.service';

@Component({
  selector: 'ad-ticker',
  templateUrl: './ad-ticker.component.html',
})
export class AdTickerComponent {

  model: AdModel;

  constructor(
    deviceDetectionsService: DeviceDetectionService,
  ) {
    const isMobile = deviceDetectionsService.isMobile;
    this.model = new AdModel(
      'adTicker',
      isMobile ? AdSize.ad_mticker : AdSize.ad_ticker,
      isMobile ? [
        AD_DIMENSIONS[AdDimensions.dim_300x50],
        AD_DIMENSIONS[AdDimensions.dim_300x600],
        AD_DIMENSIONS[AdDimensions.dim_300x250],
        AD_DIMENSIONS[AdDimensions.dim_320x50],
      ] : [
        AD_DIMENSIONS[AdDimensions.dim_480x270],
        AD_DIMENSIONS[AdDimensions.dim_400x300],
        AD_DIMENSIONS[AdDimensions.dim_300x250],
      ],
      false,
      () => true,
      false,
    );
  }
}
