export enum VendorTypes {
  PLACEHOLDER,
  datawrapper,
  infogram,
  adition,
  apaGrafiken,
  outbrain,
  soundCloud,
  vimeo,
  apaVideo,
  competition,
  newsletter,
  facebook,
  instagram,
  degrees23,
  pinPoll,
  podigee,
  simplecast,
  infonline,
  cleverPush,
  riddle,
  pinterest,
  tikTok,
  twitter,
  youTube,
  piano,
  googleAds,
  googleAnalytics,
  googleMaps,
  googleTagManager,
  flourish,
  spotify,
  activeCampaign,
  paypal,
  amazon,
  art19,
  linkedIn,
  acast,
  snowplow,
  assertiveYield,
  kDigital,
}
