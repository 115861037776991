import {PortalRoute} from '../teaser/portalRoute';
import {ImageModel} from '@model/component/image';

export class SliderConfigModel {
  constructor(
    public adaptiveHeight: boolean,
    public className: string,
    public counter: boolean,
    public title?: string,
    public link?: PortalRoute,
    public stepsize?: number,
    public start?: number,
    public slideIndexStart?: number,
    public image?: ImageModel,
  ) {
  }
}
