import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {IDivider} from '../../../../model/content/divider/payload';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'divider',
  templateUrl: 'divider.component.html'
})
export class DividerComponent implements DynamicComponent<IDivider> {

  title: string;
  preTitle: string;

  initModel(model: IDivider): void {
    this.title = model.data.title;
    this.preTitle = model.data.name;
  }
}
