import { Subject } from 'rxjs';
import { DeviceDetectionService } from '../../../../shared/service/device-detection.service';
import { FootballService } from '@shared/service/football.service';
import { map, switchMap } from 'rxjs/operators';
import { SliderConfigModel } from '@model/component/sliderConfig';
var FootballStagesWrapperComponent = /** @class */ (function () {
    function FootballStagesWrapperComponent(mobileDetectionService, footballService) {
        this.mobileDetectionService = mobileDetectionService;
        this.footballService = footballService;
        this.activeTab = new Subject();
        this.sliderConfigModel = new SliderConfigModel(true, 'footballStageSlider', false);
    }
    FootballStagesWrapperComponent.prototype.initModel = function (model) {
        var _this = this;
        var y = +model.data.footballSeason.split('/')[0];
        var year = y + '/' + (y + 1);
        if (model.data.footballLeague === 'world-cup' ||
            model.data.footballLeague === 'wc-qualification-europe' ||
            model.data.footballLeague === 'european-championship' ||
            model.data.footballLeague === 'uefa-women-euro') {
            year = year.split('/')[0];
        }
        this.tabs$ = this.footballService.getStageTabs(model.data.footballLeague, year);
        this.heading$ = this.footballService
            .leagueFromId$(this.footballService.footBallLeagueIds[model.data.footballLeague])
            .pipe(map(function (res) { return res.data.name; }));
        this.footBallStageModel$ = this.activeTab$.pipe(switchMap(function (stage) {
            return _this.footballService.getFootballStageModel(stage);
        }));
    };
    Object.defineProperty(FootballStagesWrapperComponent.prototype, "activeTab$", {
        get: function () {
            return this.activeTab.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    FootballStagesWrapperComponent.prototype.switchTab = function (tab) {
        this.activeTab.next(tab.info);
    };
    Object.defineProperty(FootballStagesWrapperComponent.prototype, "isMobile", {
        get: function () {
            return this.mobileDetectionService.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    return FootballStagesWrapperComponent;
}());
export { FootballStagesWrapperComponent };
