/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../ui/linkBar/linkBar.component.ngfactory";
import * as i2 from "../ui/linkBar/linkBar.component";
import * as i3 from "../../../shared/directive/portalRoute.directive";
import * as i4 from "@angular/router";
import * as i5 from "../../../shared/service/portal.service";
import * as i6 from "../../../shared/service/browser.ref";
import * as i7 from "@angular/common";
import * as i8 from "../image/image.component.ngfactory";
import * as i9 from "../image/image.component";
import * as i10 from "../../../shared/service/image.service";
import * as i11 from "../../../shared/service/overlay.service";
import * as i12 from "./linkCard.component";
var styles_LinkCardComponent = [];
var RenderType_LinkCardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LinkCardComponent, data: {} });
export { RenderType_LinkCardComponent as RenderType_LinkCardComponent };
function View_LinkCardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "linkbar", [["class", "linkCard-linkBar"]], null, null, null, i1.View_LinkBarComponent_0, i1.RenderType_LinkBarComponent)), i0.ɵdid(1, 49152, null, 0, i2.LinkBarComponent, [], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.linkbar; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LinkCardComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LinkCardComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "linkCard-link"]], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 540672, null, 0, i3.PortalRouteDirective, [i4.Router, i0.ElementRef, i5.PortalService, i6.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkCardComponent_3)), i0.ɵdid(3, 540672, null, 0, i7.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.model.link; _ck(_v, 1, 0, currVal_2); var currVal_3 = i0.ɵnov(_v.parent, 7); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_LinkCardComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "picimage", [["class", "linkCard-image"]], null, null, null, i8.View_ImageComponent_0, i8.RenderType_ImageComponent)), i0.ɵdid(1, 638976, null, 0, i9.ImageComponent, [i10.ImageService, i11.OverlayService, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.image; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LinkCardComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [["class", "linkCard-tagLine"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.pretitle; _ck(_v, 1, 0, currVal_0); }); }
function View_LinkCardComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [["class", "linkCard-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.title; _ck(_v, 1, 0, currVal_0); }); }
function View_LinkCardComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "linkCard-text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.content; _ck(_v, 0, 0, currVal_0); }); }
function View_LinkCardComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "linkCard-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkCardComponent_7)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkCardComponent_8)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkCardComponent_9)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.pretitle; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.model.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.model.content; _ck(_v, 6, 0, currVal_2); }, null); }
function View_LinkCardComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkCardComponent_5)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkCardComponent_6)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.image; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.model.title || _co.model.content); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LinkCardComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "linkCard"]], null, null, null, null, null)), i0.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkCardComponent_1)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LinkCardComponent_2)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["content", 2]], null, 0, null, View_LinkCardComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "linkCard"; var currVal_1 = _co.model.classNames; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.model.linkbar; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.model.link; var currVal_4 = i0.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_3, currVal_4); }, null); }
export function View_LinkCardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "linkcard", [], null, null, null, View_LinkCardComponent_0, RenderType_LinkCardComponent)), i0.ɵdid(1, 49152, null, 0, i12.LinkCardComponent, [], null, null)], null, null); }
var LinkCardComponentNgFactory = i0.ɵccf("linkcard", i12.LinkCardComponent, View_LinkCardComponent_Host_0, { model: "model" }, {}, []);
export { LinkCardComponentNgFactory as LinkCardComponentNgFactory };
