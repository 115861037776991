import { BrowserRef } from '@shared/service/browser.ref';
import { PianoService } from '@shared/service/piano/piano.service';
import { ReplaySubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { StateService } from '@shared/service/state.service';
import { CORAL_PROD } from '@shared/service/tokens';
import { LoadExternalScriptService } from '@shared/service/loadExternalScript.service';
import { SCRIPT_MAP } from '@model/const/script';
import { ScriptId } from '@model/enum/scriptId';
import { take, tap } from 'rxjs/operators';
import { PortalRoute } from '@model/teaser/portalRoute';
import { PortalService } from '@shared/service/portal.service';
import * as i0 from "@angular/core";
import * as i1 from "./browser.ref";
import * as i2 from "./piano/piano.service";
import * as i3 from "./loadExternalScript.service";
import * as i4 from "@angular/common";
import * as i5 from "./state.service";
import * as i6 from "./portal.service";
var CoralService = /** @class */ (function () {
    function CoralService(browserRef, piano, loadScript, document, stateService, portalService, platformId) {
        this.browserRef = browserRef;
        this.piano = piano;
        this.loadScript = loadScript;
        this.document = document;
        this.portalService = portalService;
        this.platformId = platformId;
        this.coral = new ReplaySubject();
        if (isPlatformBrowser(this.platformId)) {
            window.addEventListener('message', function (event) {
                if (event.data && event.data.event === 'reportContent') {
                    var url = window.location.href.split('#')[0] + '#comment-' + event.data.commentId;
                    window.location.href = 'https://kurier.at/inhalt-melden?report_url=' + url;
                }
            });
        }
        var isProd = stateService.get(CORAL_PROD);
        var arr = this.portalService.hostname().split('.');
        if (!isProd) {
            arr.splice(1, 0, 'telekurier');
        }
        arr.unshift('https://community');
        this.rootURL = arr.join('.');
        SCRIPT_MAP[ScriptId.comments] = this.rootURL + "/assets/js/embed.js?qwertzuiop";
    }
    Object.defineProperty(CoralService.prototype, "coral$", {
        get: function () {
            return this.coral.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CoralService.prototype.sendToGa = function (gaId, eventType, event) {
        // @ts-ignore
        window.ga('create', gaId, 'auto');
        // @ts-ignore
        window.ga('send', 'event', 'Coral', eventType, 'test');
    };
    CoralService.prototype.init = function (route) {
        var _this = this;
        this.loadScript.loadScript(ScriptId.comments)
            .pipe(take(1), tap(function (script) {
            var commentsCssSrc = _this.document.querySelector('[commentsCss]').getAttribute('commentsCss');
            var storyUrl = _this.portalService.absoluteUrlOf(PortalRoute.fromUri(route.uri));
            if (_this.portalService.currentPortal() === 'filmat') {
                storyUrl = storyUrl.replace('https://', 'https://www.');
            }
            var coral = script.scriptVar.createStreamEmbed({
                id: 'coral_thread',
                autoRender: true,
                rootURL: _this.rootURL,
                bodyClassName: 'coralComments-kurier',
                // Uncomment these lines and replace with the ID of the
                // story's ID and URL from your CMS to provide the
                // tightest integration. Refer to our documentation at
                // https://docs.coralproject.net for all the configuration
                // options.
                // storyID: route.dataLayer.ArtikelId -> this leads to duplicate story id error,
                // storyID: '${storyID}',
                storyURL: storyUrl,
                customCSSURL: window.location.protocol + '//' + window.location.host + '/' + commentsCssSrc,
                enableDeprecatedEvents: true,
            });
            coral.on('showAuthPopup', function () {
                _this.piano.showLogin();
            });
            coral.on('mutation.signOut', function () {
                _this.piano.doLogout();
            });
            coral.on('createComment.success', function (e) {
                _this.sendToGa(route.analytics.ga360, 'createComment', e.success.id);
            });
            coral.on('createCommentReply.success', function (e) {
                _this.sendToGa(route.analytics.ga360, 'createCommentReply', e.success.id);
            });
            coral.on('createCommentReaction.success', function (e) {
                _this.sendToGa(route.analytics.ga360, 'createCommentReaction', e.commentID);
            });
            coral.on('setCommentsOrderBy', function (e) {
                _this.sendToGa(route.analytics.ga360, 'setCommentsOrderBy', e.orderBy);
            });
            coral.on('showReplyForm', function (e) {
                _this.sendToGa(route.analytics.ga360, 'showReplyForm', e.commentID);
            });
            _this.coral.next(coral);
        })).subscribe();
    };
    CoralService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CoralService_Factory() { return new CoralService(i0.ɵɵinject(i1.BrowserRef), i0.ɵɵinject(i2.PianoService), i0.ɵɵinject(i3.LoadExternalScriptService), i0.ɵɵinject(i4.DOCUMENT), i0.ɵɵinject(i5.StateService), i0.ɵɵinject(i6.PortalService), i0.ɵɵinject(i0.PLATFORM_ID)); }, token: CoralService, providedIn: "root" });
    return CoralService;
}());
export { CoralService };
