/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../shared/directive/oewaClickTrigger.directive";
import * as i3 from "../../../shared/service/oewa.service";
import * as i4 from "../ui/svgIcon/svgIcon.component.ngfactory";
import * as i5 from "../ui/svgIcon/svgIcon.component";
import * as i6 from "./interaction-bar.component";
import * as i7 from "@angular/router";
import * as i8 from "../../../shared/service/gtm.service";
import * as i9 from "../../../shared/service/browser.ref";
import * as i10 from "../../../shared/service/device-detection.service";
var styles_InteractionBarComponent = [];
var RenderType_InteractionBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InteractionBarComponent, data: {} });
export { RenderType_InteractionBarComponent as RenderType_InteractionBarComponent };
function View_InteractionBarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [["oewaClickTrigger", ""], ["title", "Zu den Kommentaren"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.scrollToComments() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(3, 16384, null, 0, i2.OewaClickTriggerDirective, [i3.OewaService], { oewaPageId: [0, "oewaPageId"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "svgicon", [["icon", "message"]], [[1, "class", 0]], null, null, i4.View_SvgIconComponent_0, i4.RenderType_SvgIconComponent)), i0.ɵdid(5, 49152, null, 0, i5.SvgIconComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵted(-1, null, [" Kommentare "]))], function (_ck, _v) { var currVal_0 = "interactionBar-item"; _ck(_v, 2, 0, currVal_0); var currVal_1 = ""; _ck(_v, 3, 0, currVal_1); var currVal_3 = "message"; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = i0.ɵnov(_v, 5).class; _ck(_v, 4, 0, currVal_2); }); }
function View_InteractionBarComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "button", [["class", "interactionBar-share-button"], ["oewaClickTrigger", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.openPopup(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i2.OewaClickTriggerDirective, [i3.OewaService], { oewaPageId: [0, "oewaPageId"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [], [[8, "title", 0]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_2 = ("interactionBar-share-icon interactionBar-" + _v.context.$implicit); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i0.ɵinlineInterpolate(1, "Teilen per ", _v.context.$implicit, ""); _ck(_v, 2, 0, currVal_1); var currVal_3 = _v.context.$implicit; _ck(_v, 5, 0, currVal_3); }); }
function View_InteractionBarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "interactionBar-share"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InteractionBarComponent_3)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.allLinks; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InteractionBarComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "interactionBar"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InteractionBarComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 9, "a", [["oewaClickTrigger", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(6, 16384, null, 0, i2.OewaClickTriggerDirective, [i3.OewaService], { oewaPageId: [0, "oewaPageId"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "interactionBar-item-trigger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openSharePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "svgicon", [], [[1, "class", 0]], null, null, i4.View_SvgIconComponent_0, i4.RenderType_SvgIconComponent)), i0.ɵdid(9, 49152, null, 0, i5.SvgIconComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵted(-1, null, [" Teilen "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InteractionBarComponent_2)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasCoral; _ck(_v, 2, 0, currVal_0); var currVal_1 = "interactionBar-item"; _ck(_v, 5, 0, currVal_1); var currVal_2 = ""; _ck(_v, 6, 0, currVal_2); var currVal_4 = (_co.open ? "times" : "share"); _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.open; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_3 = i0.ɵnov(_v, 9).class; _ck(_v, 8, 0, currVal_3); }); }
export function View_InteractionBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "interaction-bar", [], null, null, null, View_InteractionBarComponent_0, RenderType_InteractionBarComponent)), i0.ɵdid(1, 114688, null, 0, i6.InteractionBarComponent, [i7.ActivatedRoute, i8.GoogleTagManagerService, i9.BrowserRef, i10.DeviceDetectionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InteractionBarComponentNgFactory = i0.ɵccf("interaction-bar", i6.InteractionBarComponent, View_InteractionBarComponent_Host_0, { model: "model", hasCoral: "hasCoral" }, {}, []);
export { InteractionBarComponentNgFactory as InteractionBarComponentNgFactory };
