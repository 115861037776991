import {ChangeDetectionStrategy, Component, Inject, PLATFORM_ID} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {ITheaterDetail} from '@model/content/theaterDetail/payload';
import {TheaterDetailModel} from '@model/content/theaterDetail/theaterDetail';
import {DomSanitizer} from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';
import {IOutBrain} from '@component/ads/outbrain/outbrain.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'theaterdetail',
  templateUrl: './theaterDetail.component.html',
})
export class TheaterDetailComponent implements DynamicComponent<ITheaterDetail> {

  model: TheaterDetailModel;
  outbrainModel: IOutBrain;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
  }

  initModel(model: ITheaterDetail): void {
    if (isPlatformBrowser(this.platformId)) {
      this.outbrainModel = {
        type: 'outbrain',
        src: window.location.href,
        template: 'AT_film.at',
        widget: 'AR_2'
      };
    }
    this.model = TheaterDetailModel.deserialize(model);
    if (this.model.gmapsUrl) {
      this.model.gmapsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.model.gmapsUrl as string);
    }
  }

}
