import { ActivationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { StateService } from './state.service';
import { ROUTE_INFO } from './tokens';
import { distinctUntilChanged, filter, map, shareReplay, skip } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./state.service";
var RouteService = /** @class */ (function () {
    function RouteService(router, stateService) {
        this.router = router;
        this.stateService = stateService;
        var routeInfo = stateService.get(ROUTE_INFO, null);
        var routeInfo$ = new BehaviorSubject(routeInfo);
        this.routeInfo$ = routeInfo$.pipe(filter(Boolean), shareReplay(1));
        router.events.pipe(filter(function (e) { return e instanceof ActivationEnd; }), skip(routeInfo ? 1 : 0), map(function (e) { return e.snapshot.data.route; }), distinctUntilChanged()).subscribe(routeInfo$);
    }
    Object.defineProperty(RouteService.prototype, "routeInfo", {
        get: function () {
            return this.routeInfo$;
        },
        enumerable: true,
        configurable: true
    });
    RouteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RouteService_Factory() { return new RouteService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.StateService)); }, token: RouteService, providedIn: "root" });
    return RouteService;
}());
export { RouteService };
