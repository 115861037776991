/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../common/gtmTrigger.directive";
import * as i3 from "./portals.component";
import * as i4 from "../../../shared/service/portal.service";
import * as i5 from "../../../shared/service/didomi.service";
var styles_PortalsComponent = [];
var RenderType_PortalsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PortalsComponent, data: {} });
export { RenderType_PortalsComponent as RenderType_PortalsComponent };
function View_PortalsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = ("#" + _v.parent.context.$implicit.slug); _ck(_v, 1, 0, currVal_0); }); }
function View_PortalsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "a", [], [[8, "name", 0], [8, "href", 4], [8, "target", 0]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(3, 81920, null, 0, i2.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PortalsComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_v.context.$implicit.slug ? ("portals-" + _v.context.$implicit.slug) : _v.context.$implicit.title); _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.gtmId; _ck(_v, 3, 0, currVal_4); var currVal_5 = _v.context.$implicit.slug; _ck(_v, 5, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; var currVal_1 = _v.context.$implicit.link; var currVal_2 = _v.context.$implicit.target; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = _v.context.$implicit.title; _ck(_v, 7, 0, currVal_6); }); }
export function View_PortalsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PortalsComponent_1)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["href", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openConsentOverlay($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cookie Einstellungen"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nav; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PortalsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "portals", [], [[1, "class", 0]], null, null, View_PortalsComponent_0, RenderType_PortalsComponent)), i0.ɵdid(1, 114688, null, 0, i3.PortalsComponent, [i4.PortalService, i5.DidomiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var PortalsComponentNgFactory = i0.ɵccf("portals", i3.PortalsComponent, View_PortalsComponent_Host_0, { gtmId: "gtmId" }, {}, []);
export { PortalsComponentNgFactory as PortalsComponentNgFactory };
