import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {TeasersService} from '@shared/service/teasers.service';
import {Subscription} from 'rxjs';
import {IMultiTeaser} from '@model/channel/multiTeaser/payload';
import {TeaserModel} from '@model/teaser/teaser';
import {ImageType} from '@model/enum/image';
import {PortalService} from '@shared/service/portal.service';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'multiteaser',
  templateUrl: 'multi-teaser.component.html'
})
export class MultiTeaserComponent implements DynamicComponent<IMultiTeaser>, OnDestroy {

  @Input() model: TeaserModel[] = [];
  teaserSubscription: Subscription;

  constructor(
    private portalService: PortalService,
    private teaserService: TeasersService,
    private cd: ChangeDetectorRef) {
  }

  initModel(model: IMultiTeaser) {
    this.teaserSubscription = this.teaserService.getCollection(model.collectionName, model.numberOfTeasers)
      .subscribe(teasers => {
        if (this.portalService.currentPortal() === 'lustaufoesterreichat') {
          this.model = TeaserModel.fromCardTeasers(teasers, model.teaserView, ImageType.SQ);
        } else {
          this.model = TeaserModel.fromCardTeasers(teasers, model.teaserView, ImageType.LS_16_9);
        }
        this.cd.detectChanges();
      });
  }

  ngOnDestroy() {
    if (this.teaserSubscription) {
      this.teaserSubscription.unsubscribe();
    }
  }
}
