import {ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {ScriptId} from '../../../../model/enum/scriptId';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {IContentGarden} from '../../../../model/channel/payload';
import {BrowserRef} from '../../../../shared/service/browser.ref';
import {SCRIPT_ID_MAP, SCRIPT_MAP} from '../../../../model/const/script';
import {CssClassService} from '../../../../shared/service/cssClass.service';
import {VendorTypes} from '@model/enum/vendorTypes';
import {Observable} from 'rxjs';
import {DidomiService} from '@shared/service/didomi.service';
import {tap} from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'contentgarden',
  templateUrl: './contentGarden.component.html',
})
export class ContentGardenComponent implements DynamicComponent<IContentGarden>, OnDestroy {

  @ViewChild('content', {static: true}) contentRef: ElementRef;
  private iframeObserver: MutationObserver;
  private cssClass: string;
  vendorType = VendorTypes.adition;
  showTeaser$: Observable<boolean>;

  constructor(
    private browserRef: BrowserRef,
    private cssService: CssClassService,
    private didomiService: DidomiService,
  ) {
  }

  initModel(model: IContentGarden) {
    this.showTeaser$ = this.didomiService.checkVendor$(this.vendorType)
      .pipe(
        tap(consent => {
          if (consent) {
            const doc = this.contentRef.nativeElement.contentDocument || this.contentRef.nativeElement.contentWindow;
            if (!doc) {
              return;
            }
            const content = `
      <div>
        <script type="text/javascript" src="${SCRIPT_MAP[ScriptId.contentGarden]}"
              onload="window.${SCRIPT_ID_MAP[ScriptId.contentGarden]}.deliverArticle('${model.id}')">
        </script>
      </div>;`;
            doc.open();
            doc.write(content);
            doc.close();

            this.cssClass = model.layout === 'story' ? 'layout-contentGardenStory' : 'layout-contentGarden';

            // tslint:disable-next-line:no-string-literal
            if (this.browserRef.window['MutationObserver']) {
              this.iframeObserver = new MutationObserver((ev) => {
                this.contentRef.nativeElement.height = this.contentRef.nativeElement.contentWindow.document.body.scrollHeight;
              });

              this.iframeObserver.observe(this.contentRef.nativeElement.contentDocument, {
                attributes: true,
                subtree: true,
                childList: true,
                characterData: true
              });
            }

            this.cssService.setBodyClass(this.cssClass, true);
          }
        }),
      );
  }

  ngOnDestroy(): void {
    if (this.iframeObserver) {
      this.iframeObserver.disconnect();
    }
    this.cssService.setBodyClass(this.cssClass, false);
  }

}
