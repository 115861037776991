<div class="infoBox">
  <accordeon *ngIf="title" [title]="title" [isOpen]="open">
    <div
      class="infoBox-content"
      [innerHtml]="content"></div>
  </accordeon>
  <div
    class="infoBox-content"
    *ngIf="!title"
    [innerHtml]="content"></div>
</div>
