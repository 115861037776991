/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/directive/oewaClickTrigger.directive";
import * as i2 from "../../../shared/service/oewa.service";
import * as i3 from "../../common/gtmTrigger.directive";
import * as i4 from "@angular/common";
import * as i5 from "../../common/ui/iconButton/iconButton.component.ngfactory";
import * as i6 from "../../common/ui/iconButton/iconButton.component";
import * as i7 from "./login.component";
import * as i8 from "../../../shared/service/state.service";
import * as i9 from "../../../shared/service/piano/piano.service";
import * as i10 from "../../../shared/service/overlay.service";
import * as i11 from "../../../shared/service/didomi.service";
var styles_LoginComponent = [];
var RenderType_LoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
function View_LoginComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["id", "piano-myaccount-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "a", [["class", "button"], ["href", "#"], ["oewaClickTrigger", ""], ["title", "Abmelden"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.logout() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i1.OewaClickTriggerDirective, [i2.OewaService], { oewaPageId: [0, "oewaPageId"] }, null), i0.ɵdid(4, 81920, null, 0, i3.GtmTriggerDirective, [i0.ElementRef, i0.Renderer2], { gtmId: [0, "gtmId"] }, null), (_l()(), i0.ɵted(-1, null, ["Abmelden"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.gtmId; _ck(_v, 4, 0, currVal_1); }, null); }
function View_LoginComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "login isLoggedIn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickedInside($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginComponent_3)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "iconbutton", [["class", "login-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.togglePopUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_IconButtonComponent_0, i5.RenderType_IconButtonComponent)), i0.ɵdid(4, 114688, null, 0, i6.IconButtonComponent, [], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.valid; _ck(_v, 2, 0, currVal_0); var currVal_1 = "times"; _ck(_v, 4, 0, currVal_1); }, null); }
function View_LoginComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "a", [["class", "headerContainer-toggle headerContainer-toggle-isLogin"], ["href", "#"]], [[2, "isActive", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.togglePopUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "iconbutton", [], null, null, null, i5.View_IconButtonComponent_0, i5.RenderType_IconButtonComponent)), i0.ɵdid(3, 114688, null, 0, i6.IconButtonComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginComponent_2)), i0.ɵdid(7, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "user-alt"; _ck(_v, 3, 0, currVal_1); var currVal_3 = _co.showPopUp; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showPopUp; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.buttonText; _ck(_v, 5, 0, currVal_2); }); }
export function View_LoginComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_LoginComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.user$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "login", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).clickedOutside($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LoginComponent_0, RenderType_LoginComponent)), i0.ɵdid(1, 114688, null, 0, i7.LoginComponent, [i8.StateService, i9.PianoService, i0.ChangeDetectorRef, i10.OverlayService, i0.ComponentFactoryResolver, i11.DidomiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i0.ɵccf("login", i7.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
