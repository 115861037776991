import {IResult, ITopic} from './payload';
import {ITeaser} from '../teaser/payload';
import {IMovieSerie, ITheater} from '../movieSerie/payload';

export class Results {
  articles: ITeaser[];
  topics: ITopic[];
  theaters: ITheater[];
  movies: IMovieSerie[];
  series: IMovieSerie[];
  totalHits: number | string = '';
  term: string;
  ordering: string;
  page: number;
  pageSize: number;
  numberOfPages: number;

  static deserialize(json: IResult): Results {
    if (!json) {
      return;
    }
    const results: Results = new Results();
    results.articles = json.articles;
    results.topics = json.topics;
    results.movies = json.movies;
    results.theaters = json.cinemas;
    results.series = json.series;
    results.totalHits = json.total_hits || 0;
    results.term = json.term || '';
    results.ordering = json.ordering || '';
    results.page = json.page || 0;
    results.pageSize = json.page_size || 0;
    results.numberOfPages = json.number_of_pages || 0;
    return results;
  }

  get hasResults(): boolean {
    return this.hasArticles || this.hasTopics;
  }

  get hasArticles(): boolean {
    return this.articles && this.articles.length > 0;
  }

  get hasTopics(): boolean {
    return this.topics && this.topics.length > 0;
  }
}
