/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/directive/portalRoute.directive";
import * as i2 from "@angular/router";
import * as i3 from "../../../../shared/service/portal.service";
import * as i4 from "../../../../shared/service/browser.ref";
import * as i5 from "./text-teaser.component";
var styles_TextTeaserComponent = [];
var RenderType_TextTeaserComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextTeaserComponent, data: {} });
export { RenderType_TextTeaserComponent as RenderType_TextTeaserComponent };
export function View_TextTeaserComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u27A4 Lesen Sie hier mehr: "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "a", [], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 540672, null, 0, i1.PortalRouteDirective, [i2.Router, i0.ElementRef, i3.PortalService, i4.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.model.link; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 4).target; var currVal_1 = i0.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _co.model.title; _ck(_v, 5, 0, currVal_3); }); }
export function View_TextTeaserComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-text-teaser", [], null, null, null, View_TextTeaserComponent_0, RenderType_TextTeaserComponent)), i0.ɵdid(1, 49152, null, 0, i5.TextTeaserComponent, [], null, null)], null, null); }
var TextTeaserComponentNgFactory = i0.ɵccf("app-text-teaser", i5.TextTeaserComponent, View_TextTeaserComponent_Host_0, {}, {}, []);
export { TextTeaserComponentNgFactory as TextTeaserComponentNgFactory };
