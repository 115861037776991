import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {ITitleComponent} from '../../../../model/payload';
import {DynamicComponent} from '../../../common/dynamicComponent';
import {Observable, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {BrowserRef} from '../../../../shared/service/browser.ref';
import {ExternalApiService} from '../../../../shared/service/externalApi.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'jobbox',
  templateUrl: './jobBox.component.html',
})
export class JobBoxComponent implements DynamicComponent<ITitleComponent> {

  radius = [
    {name: '5 km', value: '5'},
    {name: '25 km', value: '25'},
    {name: '50 km', value: '50'},
    {name: '100 km', value: '100'},
    {name: '200 km', value: '200'}];
  radiusSelected = 2;
  model: ITitleComponent;

  location = '';
  profession = '';
  searchActive = true;

  searchResult$: Observable<any>;
  private searchTerm$ = new ReplaySubject<string>();

  constructor(private browserRef: BrowserRef, private apiService: ExternalApiService) {
  }

  onSubmit() {
    const link = `https://job.kurier.at/jobs?utm_source=job-search-box&utm_medium=web&utm_content=form&utm_campaign=
    classified-search-boxes&search=${this.profession}&job_geo_location=${this.location}&radius=${this.radius[this.radiusSelected].value}`;
    this.browserRef.window.open(link, '_blank');
    return false;
  }

  initModel(model: ITitleComponent): void {
    this.model = model;
    this.searchResult$ = this.searchTerm$.pipe(
      distinctUntilChanged(),
      switchMap(term => {
        return this.apiService.jobSearch(term).pipe(map(results => {
          return Object.keys(results);
        }));
      }),
    );
  }

  onInputChanged(value: string) {
    this.searchTerm$.next(value);
    this.searchActive = this.profession !== '';
  }

  selectInput(result: string) {
    this.profession = result;
    this.searchActive = false;
  }
}
