import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {PortalService} from './portal.service';
import {MenuModel} from '../../model/menu/menu';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MenuService {

  constructor(protected api: ApiService,
              protected portalService: PortalService) {
  }

  get(name: string): Observable<MenuModel[]> {
    const currentPortal = this.portalService.currentPortal();
    return this.api.menu(currentPortal, name).pipe(
      map(MenuModel.deserializeJson),
    );
  }
}
