<article class="movieTeaser" [ngClass]="model.classes">

  <div class="movieTeaser-content">
    <h2 class="movieTeaser-title"><a [portalRouterLink]="model.link">{{model.title}}</a></h2>
    <p *ngIf="model.ovTitle" class="movieTeaser-tagline-ovTitle">— {{model.ovTitle}}</p>
    <div class="movieTeaser-tagline">
      <div class="movieTeaser-tags">
        <a class="movieTeaser-tag type">{{model.typeBadge}}</a>
        <a *ngFor="let badge of model.badges" [gtm]="badge.gtmId" [portalRouterLink]="badge.link"
           class="movieTeaser-tag">
          {{badge.name}}
        </a>
      </div>
    </div>
  </div>

  <p class="movieTeaser-text">{{model.synopsis}}</p>

  <a *ngIf="model.poster" [portalRouterLink]="model.link" class="movieTeaser-image">
    <picimage [model]="model.poster"></picimage>
  </a>

  <div *ngIf="!model.poster" class="movieTeaser-image">
    <div class="noImg"></div>
  </div>

  <footer class="movieTeaser-footer">
    <p *ngIf="model.seasonEpisodes" class="movieTeaser-footer-paragraph">
      <span>Staffeln / Episoden</span> <strong>{{model.seasonEpisodes}}</strong>
    </p>
    <ng-container *ngIf="!model.seasonEpisodes">
      <p *ngIf="model.playTime" class="movieTeaser-footer-paragraph"><span>Min.</span>
        <strong>{{model.playTime}}</strong>
      </p>
      <p *ngIf="model.releaseDate && model.releaseDate !== 'Invalid Date'" class="movieTeaser-footer-paragraph"><span>Start</span>
        <strong>{{model.releaseDate}}</strong></p>
    </ng-container>

    <p *ngIf="model.classification" class="movieTeaser-footer-paragraph"><span>ab</span>
      <strong>{{model.classification}}+</strong></p>
  </footer>

</article>
