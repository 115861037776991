/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../gpt.ad.directive";
import * as i2 from "../../../shared/service/route.service";
import * as i3 from "../../../shared/ads/gpt.service";
import * as i4 from "../../../shared/service/portal.service";
import * as i5 from "../../../shared/service/didomi.service";
import * as i6 from "../../../shared/service/piano/piano.service";
import * as i7 from "../../../shared/service/device-detection.service";
import * as i8 from "./adArticlebottom.component";
var styles_AdArticlebottomComponent = [];
var RenderType_AdArticlebottomComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdArticlebottomComponent, data: {} });
export { RenderType_AdArticlebottomComponent as RenderType_AdArticlebottomComponent };
export function View_AdArticlebottomComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "adContainer adArticlebottom"]], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.GptAdDirective, [i0.PLATFORM_ID, i2.RouteService, i0.ElementRef, i0.Renderer2, i3.GooglePublisherTagService, i4.PortalService, i5.DidomiService, i6.PianoService, i7.DeviceDetectionService], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AdArticlebottomComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "adarticlebottom", [], null, null, null, View_AdArticlebottomComponent_0, RenderType_AdArticlebottomComponent)), i0.ɵdid(1, 49152, null, 0, i8.AdArticlebottomComponent, [], null, null)], null, null); }
var AdArticlebottomComponentNgFactory = i0.ɵccf("adarticlebottom", i8.AdArticlebottomComponent, View_AdArticlebottomComponent_Host_0, {}, {}, []);
export { AdArticlebottomComponentNgFactory as AdArticlebottomComponentNgFactory };
