import { ElementRef, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
var AdBlockBlockDirective = /** @class */ (function () {
    function AdBlockBlockDirective(hostElement, platformId) {
        this.hostElement = hostElement;
        this.platformId = platformId;
        this.blackListed = ['bs-c-pwdBy'];
    }
    Object.defineProperty(AdBlockBlockDirective.prototype, "blocked", {
        get: function () {
            return getComputedStyle(this.hostElement.nativeElement).display === 'none';
        },
        enumerable: true,
        configurable: true
    });
    AdBlockBlockDirective.prototype.ngOnInit = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(function () {
                var el = _this.hostElement.nativeElement;
                var classes = el.classList;
                if (_this.blocked) {
                    _this.blackListed.forEach(function (cls) {
                        if (classes.contains(cls)) {
                            classes.remove(cls);
                        }
                    });
                }
            }, 1000);
        }
    };
    return AdBlockBlockDirective;
}());
export { AdBlockBlockDirective };
