/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./divider.component";
var styles_DividerComponent = [];
var RenderType_DividerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DividerComponent, data: {} });
export { RenderType_DividerComponent as RenderType_DividerComponent };
export function View_DividerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "divider"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_DividerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "divider", [], null, null, null, View_DividerComponent_0, RenderType_DividerComponent)), i0.ɵdid(1, 49152, null, 0, i1.DividerComponent, [], null, null)], null, null); }
var DividerComponentNgFactory = i0.ɵccf("divider", i1.DividerComponent, View_DividerComponent_Host_0, {}, {}, []);
export { DividerComponentNgFactory as DividerComponentNgFactory };
