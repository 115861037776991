/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../common/image/image.component.ngfactory";
import * as i2 from "../../common/image/image.component";
import * as i3 from "../../../shared/service/image.service";
import * as i4 from "../../../shared/service/overlay.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../shared/directive/portalRoute.directive";
import * as i7 from "@angular/router";
import * as i8 from "../../../shared/service/portal.service";
import * as i9 from "../../../shared/service/browser.ref";
import * as i10 from "../../common/ui/iconButton/iconButton.component.ngfactory";
import * as i11 from "../../common/ui/iconButton/iconButton.component";
import * as i12 from "../../common/searchInput/searchInput.component.ngfactory";
import * as i13 from "../../common/searchInput/searchInput.component";
import * as i14 from "../../../shared/service/channelSearch.service";
import * as i15 from "../../../shared/service/api.service";
import * as i16 from "./searchMenu.component";
var styles_SearchMenuComponent = [];
var RenderType_SearchMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SearchMenuComponent, data: {} });
export { RenderType_SearchMenuComponent as RenderType_SearchMenuComponent };
function View_SearchMenuComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "picimage", [], null, null, null, i1.View_ImageComponent_0, i1.RenderType_ImageComponent)), i0.ɵdid(1, 638976, null, 0, i2.ImageComponent, [i3.ImageService, i4.OverlayService, i0.ComponentFactoryResolver], { model: [0, "model"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.image; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SearchMenuComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["aria-hidden", "true"], ["class", "plhimg"]], null, null, null, null, null))], null, null); }
function View_SearchMenuComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "li", [["class", "search-result-item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchMenuComponent_4)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchMenuComponent_5)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 3, "a", [], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.searchSelected() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 540672, null, 0, i6.PortalRouteDirective, [i7.Router, i0.ElementRef, i8.PortalService, i9.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "search-item-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.image; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.image; _ck(_v, 4, 0, currVal_1); var currVal_4 = _v.context.$implicit.portalRoute; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i0.ɵnov(_v, 6).target; var currVal_3 = i0.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = _v.context.$implicit.title; _ck(_v, 8, 0, currVal_5); }); }
function View_SearchMenuComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [["class", "search-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Filme & Serien"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "ul", [["class", "search-result-movie"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchMenuComponent_3)), i0.ɵdid(5, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "p", [["class", "search-results-more"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "a", [["class", "button hasBackground isRounded"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToMovies() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(8, null, [" Alle Filme zu \"", "\" "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.movies; _ck(_v, 5, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.searchQuery; _ck(_v, 8, 0, currVal_1); }); }
function View_SearchMenuComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [["class", "search-result-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.searchSelected() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 540672, null, 0, i6.PortalRouteDirective, [i7.Router, i0.ElementRef, i8.PortalService, i9.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "search-item-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.portalRoute; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_3); }); }
function View_SearchMenuComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [["class", "search-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Kinos"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchMenuComponent_7)), i0.ɵdid(5, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.theaters; _ck(_v, 5, 0, currVal_0); }, null); }
function View_SearchMenuComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [["class", "search-result-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.searchSelected() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 540672, null, 0, i6.PortalRouteDirective, [i7.Router, i0.ElementRef, i8.PortalService, i9.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "search-item-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.portalRoute; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_3); }); }
function View_SearchMenuComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [["class", "search-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Thema"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchMenuComponent_10)), i0.ɵdid(5, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.topics; _ck(_v, 5, 0, currVal_0); }, null); }
function View_SearchMenuComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "li", [["class", "search-result-item"]], null, null, null, null, null)), i0.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 6, "a", [], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.searchSelected() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 540672, null, 0, i6.PortalRouteDirective, [i7.Router, i0.ElementRef, i8.PortalService, i9.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "search-tagLine"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "search-category"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "search-item-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "search-result-item"; var currVal_1 = (" pB-" + _v.context.$implicit.portalRoute.portal); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.portalRoute; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i0.ɵnov(_v, 4).target; var currVal_3 = i0.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_5 = _v.context.$implicit.preTitle; _ck(_v, 7, 0, currVal_5); var currVal_6 = _v.context.$implicit.title; _ck(_v, 9, 0, currVal_6); }); }
function View_SearchMenuComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [["class", "search-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Artikel"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchMenuComponent_12)), i0.ɵdid(5, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.articles; _ck(_v, 5, 0, currVal_0); }, null); }
function View_SearchMenuComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchMenuComponent_9)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchMenuComponent_11)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 3, "p", [["class", "search-results-more"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "a", [["class", "button hasBackground isRounded"]], [[8, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.searchSelected() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 540672, null, 0, i6.PortalRouteDirective, [i7.Router, i0.ElementRef, i8.PortalService, i9.BrowserRef], { portalRouterLink: [0, "portalRouterLink"] }, null), (_l()(), i0.ɵted(-1, null, [" Alle Suchergebnisse "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.ngIf.hasTopics; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.ngIf.hasArticles; _ck(_v, 4, 0, currVal_1); var currVal_4 = _co.searchLink; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i0.ɵnov(_v, 7).target; var currVal_3 = i0.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_2, currVal_3); }); }
function View_SearchMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchMenuComponent_2)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchMenuComponent_6)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchMenuComponent_8)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.movies == null) ? null : _co.movies.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.theaters == null) ? null : _co.theaters.length); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.ngIf.hasResults; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_SearchMenuComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "headerSearch"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "iconbutton", [["class", "headerSearch-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.searchSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_IconButtonComponent_0, i10.RenderType_IconButtonComponent)), i0.ɵdid(2, 114688, null, 0, i11.IconButtonComponent, [], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "searchinput", [], null, [[null, "onSearch"], [null, "onSelected"], ["document", "click"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).clickedOutside() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).clickedInside($event) !== false);
        ad = (pd_1 && ad);
    } if (("onSearch" === en)) {
        var pd_2 = (_co.onSearch($event) !== false);
        ad = (pd_2 && ad);
    } if (("onSelected" === en)) {
        var pd_3 = (_co.onSelect($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i12.View_SearchInputComponent_0, i12.RenderType_SearchInputComponent)), i0.ɵdid(4, 114688, null, 0, i13.SearchInputComponent, [i0.Renderer2], { placeholder: [0, "placeholder"], label: [1, "label"], elmClass: [2, "elmClass"], clearOnClick: [3, "clearOnClick"], setFocus: [4, "setFocus"], onSearchButton: [5, "onSearchButton"] }, { onSelected: "onSelected", onSearch: "onSearch" }), (_l()(), i0.ɵand(16777216, null, 0, 2, null, View_SearchMenuComponent_1)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "times"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "Suchbegriff ..."; var currVal_2 = "Suchbegriff ..."; var currVal_3 = "search-result-item"; var currVal_4 = true; var currVal_5 = true; var currVal_6 = _co.clickSearchLink; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.searchResult$)); _ck(_v, 6, 0, currVal_7); }, null); }
export function View_SearchMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "searchmenu", [], null, null, null, View_SearchMenuComponent_0, RenderType_SearchMenuComponent)), i0.ɵprd(512, null, i14.ChannelSearchService, i14.ChannelSearchService, [i15.ApiService, i8.PortalService]), i0.ɵdid(2, 49152, null, 0, i16.SearchMenuComponent, [i14.ChannelSearchService, i8.PortalService, i7.Router], null, null)], null, null); }
var SearchMenuComponentNgFactory = i0.ɵccf("searchmenu", i16.SearchMenuComponent, View_SearchMenuComponent_Host_0, {}, { onSelectSearch: "onSelectSearch" }, []);
export { SearchMenuComponentNgFactory as SearchMenuComponentNgFactory };
