/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../no-consent/no-consent.component.ngfactory";
import * as i2 from "../no-consent/no-consent.component";
import * as i3 from "../../../../shared/service/didomi.service";
import * as i4 from "@angular/common";
import * as i5 from "./flourish.component";
import * as i6 from "@angular/common/http";
import * as i7 from "../../../../shared/service/loadExternalScript.service";
import * as i8 from "../../../../shared/service/browser.ref";
var styles_FlourishComponent = [];
var RenderType_FlourishComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FlourishComponent, data: {} });
export { RenderType_FlourishComponent as RenderType_FlourishComponent };
function View_FlourishComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-consent", [], null, null, null, i1.View_NoConsentComponent_0, i1.RenderType_NoConsentComponent)), i0.ɵdid(1, 49152, null, 0, i2.NoConsentComponent, [i3.DidomiService], { vendor: [0, "vendor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vendorType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FlourishComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { flourish: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["flourish", 1]], null, 0, "div", [["aria-label", ""], ["class", "flourish-embed flourish-chart"]], [[1, "data-src", 0], [1, "data-url", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FlourishComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = !i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.consent$)); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; var currVal_1 = (("https://flo.uri.sh/" + _co.id) + "/embed"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_FlourishComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-flourish", [], null, null, null, View_FlourishComponent_0, RenderType_FlourishComponent)), i0.ɵdid(1, 49152, null, 0, i5.FlourishComponent, [i3.DidomiService, i6.HttpClient, i7.LoadExternalScriptService, i0.ChangeDetectorRef, i8.BrowserRef], null, null)], null, null); }
var FlourishComponentNgFactory = i0.ɵccf("app-flourish", i5.FlourishComponent, View_FlourishComponent_Host_0, {}, {}, []);
export { FlourishComponentNgFactory as FlourishComponentNgFactory };
