import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {AdModel} from '../../../model/ad/ad-model';
import {AD_DIMENSIONS} from '../../../model/const/ad';
import {AdDimensions, AdSize} from '../../../model/enum/ad';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'adSuperbanner',
  templateUrl: 'adsuperbanner.component.html'
})
export class AdSuperbannerComponent {
  model: AdModel;

  constructor() {
    this.model = new AdModel('adSuperbanner',
      AdSize.ad_superbanner,
      [AD_DIMENSIONS[AdDimensions.dim_728x90], AD_DIMENSIONS[AdDimensions.dim_960x250], AD_DIMENSIONS[AdDimensions.dim_960x100]],
      false,
      (d) => d[0] <= window.innerWidth,
      false,
    );
  }
}
