/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../gpt.ad.directive";
import * as i2 from "../../../shared/service/route.service";
import * as i3 from "../../../shared/ads/gpt.service";
import * as i4 from "../../../shared/service/portal.service";
import * as i5 from "../../../shared/service/didomi.service";
import * as i6 from "../../../shared/service/piano/piano.service";
import * as i7 from "../../../shared/service/device-detection.service";
import * as i8 from "./adSidebar.component";
import * as i9 from "../../../shared/service/sticky.service";
var styles_AdSidebarComponent = [];
var RenderType_AdSidebarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdSidebarComponent, data: {} });
export { RenderType_AdSidebarComponent as RenderType_AdSidebarComponent };
export function View_AdSidebarComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { elmRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 2, "div", [["class", "adContainer adSkyscraper wb wb-right isFixed"]], null, null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.GptAdDirective, [i0.PLATFORM_ID, i2.RouteService, i0.ElementRef, i0.Renderer2, i3.GooglePublisherTagService, i4.PortalService, i5.DidomiService, i6.PianoService, i7.DeviceDetectionService], { model: [0, "model"] }, null), (_l()(), i0.ɵted(3, null, ["Sidebar ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.alwaysShow ? "2" : "1"); _ck(_v, 3, 0, currVal_1); }); }
export function View_AdSidebarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "adSidebar", [], null, null, null, View_AdSidebarComponent_0, RenderType_AdSidebarComponent)), i0.ɵdid(1, 114688, null, 0, i8.AdSidebarComponent, [i0.Renderer2, i9.StickyService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdSidebarComponentNgFactory = i0.ɵccf("adSidebar", i8.AdSidebarComponent, View_AdSidebarComponent_Host_0, { alwaysShow: "alwaysShow" }, {}, []);
export { AdSidebarComponentNgFactory as AdSidebarComponentNgFactory };
